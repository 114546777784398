import { useRef, type FC, useEffect, forwardRef } from "react";
import {
  FiChevronDown,
  FiChevronUp,
  FiLayout,
  FiMoreVertical,
  FiSettings,
  FiTrash,
} from "react-icons/fi";
import cn from "classnames";
import { DirectionEnum } from "types";
import { createResizableColumn } from "utils";
import { Button } from "ui-atoms";
import { Menu, Transition } from "@headlessui/react";
import { RxDragHandleDots2 } from "react-icons/rx";

export interface ThProps {
  className?: string;
  children?: any;
  onClick?: (id: string, direction?: DirectionEnum) => void;
  // TODO: add typing for filters
  filters?: any;
  filterId?: string;
  sortFields?: string[];
  isEdit?: boolean;
  itemClassName?: string;
  draggableProps?: any;
  dragHandleProps?: any;
  handleColumnSubmit?: any;
  currentColumns?: any;
  width?: number | null;
  editable?: boolean;
  handleColumn?: any;
}

interface IOption {
  id: "edit" | "remove";
  label: string;
  icon: any;
  value: string;
  class: string;
}

const MORE_OPTIONS: IOption[][] = [
  [
    {
      id: "edit",
      label: "Column settings",
      icon: FiSettings,
      value: "column",
      class: "text-jll-color-coldGray-9",
    },
    {
      id: "remove",
      label: "Remove Column",
      icon: FiTrash,
      value: "trash",
      class: "text-jll-color-text-danger",
    },
  ],
];

const More = ({ handleColumn, removeColumn }: any) => {
  return (
    <Menu>
      <Menu.Button className="h-full flex items-center">
        <Button
          variant="neutral"
          className="!w-4 !h-4"
          leadingIcon={FiMoreVertical}
        />
      </Menu.Button>
      <Transition
        className="z-30"
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Menu.Items className="absolute right-0 mt-2 w-fit py-1 origin-top-right divide-y divide-jll-color-coldGray-3 rounded-lg bg-white shadow-drop ring-1 ring-black ring-opacity-5 focus:outline-none">
          {MORE_OPTIONS?.map((options: IOption[], idx) => (
            <div className="py-1" key={idx}>
              {options?.map((option: IOption, idx1) => {
                const Icon = option?.icon;
                return (
                  <Menu.Item key={idx1}>
                    <div
                      className="space-x-2 flex flex-row items-center cursor-pointer px-6 py-2 hover:bg-jll-color-surface-info-subdued"
                      onClick={
                        option?.id === "edit" ? handleColumn : removeColumn
                      }
                    >
                      <Icon className={cn("w-4 h-4", option?.class)} />
                      <span
                        className={cn(
                          "whitespace-nowrap leading-6 normal-case",
                          option?.class
                        )}
                      >
                        {option?.label}
                      </span>
                    </div>
                  </Menu.Item>
                );
              })}
            </div>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

const Th = forwardRef<any, ThProps>(
  (
    {
      children,
      className,
      filterId = "",
      onClick,
      sortFields,
      filters,
      isEdit,
      itemClassName,
      handleColumnSubmit,
      currentColumns,
      width,
      editable,
      handleColumn,
      ...props
    }: ThProps,
    ref
  ) => {
    const thRef = useRef(null);
    const resizerRef = useRef(null);
    let direction: DirectionEnum | undefined = undefined;
    const isSortable = sortFields?.includes(filterId);
    const columnRef = useRef<any>([]);

    if (isSortable) {
      if (
        filters?.sort === filterId &&
        filters.direction === DirectionEnum.asc
      ) {
        direction = DirectionEnum.desc;
      } else if (filters?.sort === filterId) {
        direction = DirectionEnum.asc;
      }
    }
    columnRef.current = currentColumns;

    const updateSizeColumn = (width: number) => {
      const index = columnRef.current?.findIndex(
        (column: any) => column?.id === filterId
      );
      if (index > -1) {
        columnRef.current[index].width = width;
        handleColumnSubmit(columnRef.current);
      }
    };

    const removeColumn = () => {
      const index = columnRef.current?.findIndex(
        (column: any) => column?.id === filterId
      );
      if (index > -1) {
        const newColumns = [...columnRef.current];
        newColumns?.splice(index, 1);
        handleColumnSubmit(newColumns);
      }
    };

    useEffect(() => {
      if (!thRef?.current || !resizerRef?.current || !isEdit) return;
      createResizableColumn(
        thRef?.current,
        resizerRef?.current,
        updateSizeColumn
      );
    }, [thRef?.current, resizerRef?.current, isEdit]);

    return (
      <th
        scope="col"
        className={cn(
          "p-0 text-left text-xs tracking-wider font-semibold text-jll-color-coldGray-6 uppercase",
          {
            "cursor-pointer transition-colors hover:text-jll-color-coldGray-9":
              isSortable,
          },
          className
        )}
        style={{ minWidth: width ? `${width}px` : 0 }}
        ref={thRef}
      >
        <div
          className={cn("px-3 py-4 h-full relative", itemClassName, {
            // "!translate-x-0": !props?.draggableProps?.style?.width,
          })}
          ref={ref}
          {...props?.draggableProps}
          style={{
            ...props?.draggableProps?.style,
          }}
        >
          <div className="flex items-center justify-between">
            <div
              className="flex items-center space-x-2"
              onClick={() => isSortable && onClick?.(filterId, direction)}
            >
              {editable && isEdit && (
                <div className="h-full" {...props?.dragHandleProps}>
                  <RxDragHandleDots2 className="text-xl" />
                </div>
              )}
              <div>{children}</div>
              {isSortable && (
                <>
                  {direction === DirectionEnum.asc && (
                    <FiChevronDown
                      size={15}
                      className="text-jll-color-coldGray-9"
                    />
                  )}
                  {direction === DirectionEnum.desc && (
                    <FiChevronUp
                      className="text-jll-color-coldGray-9"
                      size={15}
                    />
                  )}
                  {direction !== DirectionEnum.asc &&
                    direction !== DirectionEnum.desc && (
                      <FiChevronDown
                        className="text-jll-color-coldGray-4"
                        size={15}
                      />
                    )}
                </>
              )}
            </div>
            {editable && isEdit && (
              <div className="z-20">
                <More handleColumn={handleColumn} removeColumn={removeColumn} />
              </div>
            )}
          </div>
          {editable && isEdit && (
            <div
              className="absolute top-0 right-[-5px] z-20 w-[11px] cursor-col-resize select-none h-full flex justify-center group"
              ref={resizerRef}
            >
              <div className="relative flex items-center h-full">
                <div className="flex justify-center items-center gap-[3px] h-5">
                  <div className="w-px bg-jll-color-coldGray-3 h-5" />
                  <div className="w-px bg-jll-color-coldGray-3 h-5" />
                </div>
                <div className="absolute h-full w-px group-hover:bg-jll-color-icon-info left-[2px]" />
              </div>
            </div>
          )}
        </div>
      </th>
    );
  }
);

export default Th;
