import { FiX } from "react-icons/fi";
import {
  Button,
  Input,
  Label,
  Loading,
  MultiEmailInput,
  Toast,
} from "ui-atoms";
import { Modal } from "ui-molecules";
import EmailEditor from "react-email-editor";
import { useContext, useEffect, useRef, useState } from "react";
import { AvailabilityProps, PropertyProps } from "types";
import { convertImageUrl, postEmailAPI } from "services";
import {
  convertExtension,
  getAvailabilityItem,
  getPropertyItem,
  getVideoThumb,
  deepArrayClone,
  capitalize,
} from "utils";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useApiCall } from "hooks";
import { GlobalContext } from "context";
import { SET_LOADING_MODAL } from "constant";
import cn from "classnames";
import {
  emailContactInfoJSON,
  emailContactTitleJSON,
  emailHeaderJSON,
  emailHomeJSON,
  emailJSON,
  emailPropertyBrochuresJSON,
  emailPropertyDetailJSON,
  emailPropertyFloorplansJSON,
  emailPropertyImagesJSON,
  emailPropertyVideosJSON,
  emailPropertyVirtualToursJSON,
  emailSuiteDetailJSON,
  emailSuiteDivideJSON,
  emailSuiteTitleJSON,
} from "./email_json";

interface IEmailEditorModal {
  isOpen: boolean;
  setIsOpen: any;
  property?: PropertyProps;
  suites?: AvailabilityProps[];
}

const validationSchema = Yup.object().shape({
  emails: Yup.array().of(Yup.string().email("Invalid Email")),
});

const EmailEditorModal: React.FC<IEmailEditorModal> = ({
  isOpen,
  setIsOpen,
  property,
  suites,
}) => {
  const { dispatch, state } = useContext(GlobalContext);
  const { meta } = state;
  const session = JSON.parse(sessionStorage.getItem("session") || "null");
  const [postEmail] = useApiCall(postEmailAPI);
  const emailEditorRef = useRef<any>(null);
  const [emailTemplate, setEmailTemplate] = useState(emailJSON);
  const [isReady, setIsReady] = useState(false);

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    values,
    errors,
    touched,
    setValues,
    isValid,
    dirty,
  } = useFormik({
    initialValues: {
      emails: [],
      subject: "",
    },
    validationSchema,
    onSubmit: async () => {
      try {
        dispatch({
          type: SET_LOADING_MODAL,
          payload: {
            open: true,
            label: `Sending Email`,
          },
        });
        emailEditorRef.current.editor.exportHtml(async (data: any) => {
          try {
            const emails: any = [...values.emails];
            if (!emails.find((email: string) => email === session?.email)) {
              emails.push(session?.email);
            }
            const payload = {
              to: emails,
              subject: values.subject,
              body: data?.html,
            };
            const res = await postEmail(payload);
            if (!res) return;
            setIsOpen(false);
            setTimeout(() => {
              dispatch({
                type: SET_LOADING_MODAL,
                payload: {
                  open: false,
                  label: null,
                },
              });
            }, 100);
          } catch (err1) {
            setTimeout(() => {
              dispatch({
                type: SET_LOADING_MODAL,
                payload: {
                  open: false,
                  label: null,
                },
              });
            }, 100);
          }
        });
      } catch (err: any) {
        Toast.error(err?.message);
        setTimeout(() => {
          dispatch({
            type: SET_LOADING_MODAL,
            payload: {
              open: false,
              label: null,
            },
          });
        }, 100);
      }
    },
  });

  const setTemplate = async () => {
    try {
      const templateJson: any = { ...emailJSON };

      const emailHeader: any[] = deepArrayClone(emailHeaderJSON);
      //
      const emailHome: any[] = deepArrayClone(emailHomeJSON);
      emailHome[0].columns[0].contents[1].values.text = property?.title;
      emailHome[0].columns[0].contents[2].values.text = `<p style=\"line-height: 140%;\"><span style=\"line-height: 21px; color: #60666e;\">${
        property?.address
      }</span></p>\n<p style=\"line-height: 140%;\"><span style=\"line-height: 21px; color: #60666e;\">${[
        property?.city,
        property?.state,
        property?.post_code,
      ].join(", ")}</span></p>`;
      emailHome[0].columns[0].contents[3].values.text = `<p style=\"line-height: 140%;\">${
        capitalize(property?.property_type) || "-"
      } | ${property?.tenure_types?.map((type, index) => {
        return type?.toLowerCase() === "rent"
          ? " Lease"
          : ` ${capitalize(type)}`;
      })}</p>`;
      emailHome[0].columns[0].contents[4].values.href.values.href = `${
        window.location.origin
      }/property/${property?.pk || property?.id}/form`;

      emailHome[0].columns[1].contents[0].values.src.url = convertImageUrl(
        convertExtension(property?.images?.[0] || "")
      );

      const emailPropertyDetail: any[] = deepArrayClone(
        emailPropertyDetailJSON
      );
      emailPropertyDetail[1].columns[1].contents[0].values.text = `<p style=\"line-height: 140%;\">${getPropertyItem(
        property,
        "building_size"
      )}</p>`;
      emailPropertyDetail[1].columns[3].contents[0].values.text = `<p style=\"line-height: 140%;\">${getPropertyItem(
        property,
        "floors"
      )}</p>`;

      emailPropertyDetail[3].columns[1].contents[0].values.text = `<p style=\"line-height: 140%;\">${getPropertyItem(
        property,
        "year_built"
      )}</p>`;
      emailPropertyDetail[3].columns[3].contents[0].values.text = `<p style=\"line-height: 140%;\">${getPropertyItem(
        property,
        "year_renovated"
      )}</p>`;

      emailPropertyDetail[5].columns[1].contents[0].values.text = `<p style=\"line-height: 140%;\">${getPropertyItem(
        property,
        "rent_price"
      )}</p>`;
      emailPropertyDetail[5].columns[3].contents[0].values.text = `<p style=\"line-height: 140%;\">${getPropertyItem(
        property,
        "sale_price"
      )}</p>`;

      if (!!property?.opex?.length) {
        emailPropertyDetail[7].columns[1].contents[0].values.text = `<p>${getPropertyItem(
          property,
          "opex",
          meta
        )}</p>`;
      } else {
        emailPropertyDetail.splice(6, 2);
        emailPropertyDetail[5].values.padding = "0px 0px 20px";
      }

      let emailPropertyImages: any[] = [];
      if (!!property?.images?.length) {
        emailPropertyImages = deepArrayClone(emailPropertyImagesJSON);
        emailPropertyImages[1].columns[0].contents[0].values.src.url =
          convertImageUrl(convertExtension(property?.images[0]));
        emailPropertyImages[1].columns[0].contents[0].values.action.values.href =
          property?.images[0];
        if (!!property?.images?.[1]) {
          emailPropertyImages[1].columns[1].contents[0].values.src.url =
            convertImageUrl(convertExtension(property?.images[1]));
          emailPropertyImages[1].columns[1].contents[0].values.action.values.href =
            property?.images[1];
        }
        if (!!property?.images?.[2]) {
          emailPropertyImages[1].columns[2].contents[0].values.src.url =
            convertImageUrl(convertExtension(property?.images[2]));
          emailPropertyImages[1].columns[2].contents[0].values.action.values.href =
            property?.images[2];
        }
        if (!!property?.images?.[3]) {
          emailPropertyImages[1].columns[3].contents[0].values.src.url =
            convertImageUrl(convertExtension(property?.images[3]));
          emailPropertyImages[1].columns[3].contents[0].values.action.values.href =
            property?.images[3];
        }
      }

      let emailPropertyFloorplans: any[] = [];
      if (!!property?.floorplans?.length) {
        emailPropertyFloorplans = deepArrayClone(emailPropertyFloorplansJSON);
        emailPropertyFloorplans[1].columns[0].contents[0].values.src.url =
          convertImageUrl(convertExtension(property?.floorplans[0]));
        emailPropertyFloorplans[1].columns[0].contents[0].values.action.values.href =
          property?.floorplans[0];
        if (!!property?.images?.[1]) {
          emailPropertyFloorplans[1].columns[1].contents[0].values.src.url =
            convertImageUrl(convertExtension(property?.floorplans[1]));
          emailPropertyFloorplans[1].columns[1].contents[0].values.action.values.href =
            property?.floorplans[1];
        }
        if (!!property?.images?.[2]) {
          emailPropertyFloorplans[1].columns[2].contents[0].values.src.url =
            convertImageUrl(convertExtension(property?.floorplans[2]));
          emailPropertyFloorplans[1].columns[2].contents[0].values.action.values.href =
            property?.floorplans[2];
        }
        if (!!property?.images?.[3]) {
          emailPropertyFloorplans[1].columns[3].contents[0].values.src.url =
            convertImageUrl(convertExtension(property?.floorplans[3]));
          emailPropertyFloorplans[1].columns[3].contents[0].values.action.values.href =
            property?.floorplans[3];
        }
      }

      let emailPropertyBrochures: any[] = [];
      if (!!property?.brochures?.length) {
        emailPropertyBrochures = deepArrayClone(emailPropertyBrochuresJSON);
        emailPropertyBrochures[1].columns[0].contents[0].values.src.url =
          convertImageUrl(convertExtension(property?.brochures[0]));
        emailPropertyBrochures[1].columns[0].contents[0].values.action.values.href =
          property?.brochures[0];
        if (!!property?.brochures?.[1]) {
          emailPropertyBrochures[1].columns[1].contents[0].values.src.url =
            convertImageUrl(convertExtension(property?.brochures[1]));
          emailPropertyBrochures[1].columns[1].contents[0].values.action.values.href =
            property?.brochures[1];
        }
        if (!!property?.brochures?.[2]) {
          emailPropertyBrochures[1].columns[2].contents[0].values.src.url =
            convertImageUrl(convertExtension(property?.brochures[2]));
          emailPropertyBrochures[1].columns[2].contents[0].values.action.values.href =
            property?.brochures[2];
        }
        if (!!property?.brochures?.[3]) {
          emailPropertyBrochures[1].columns[3].contents[0].values.src.url =
            convertImageUrl(convertExtension(property?.brochures[3]));
          emailPropertyBrochures[1].columns[3].contents[0].values.action.values.href =
            property?.brochures[3];
        }
      }

      let emailPropertyVideos: any[] = [];
      if (!!property?.videos?.length) {
        emailPropertyVideos = deepArrayClone(emailPropertyVideosJSON);
        emailPropertyVideos[1].columns[0].contents[0].values.src.url =
          getVideoThumb(property?.videos[0]);
        emailPropertyVideos[1].columns[0].contents[0].values.action.values.href =
          property?.videos[0];

        if (!!property?.images?.[1]) {
          emailPropertyVideos[1].columns[1].contents[0].values.src.url =
            getVideoThumb(property?.videos[1]);
          emailPropertyVideos[1].columns[1].contents[0].values.action.values.href =
            property?.videos[1];
        }

        if (!!property?.images?.[2]) {
          emailPropertyVideos[1].columns[2].contents[0].values.src.url =
            getVideoThumb(property?.videos[2]);
          emailPropertyVideos[1].columns[2].contents[0].values.action.values.href =
            property?.videos[2];
        }

        if (!!property?.images?.[3]) {
          emailPropertyVideos[1].columns[3].contents[0].values.src.url =
            getVideoThumb(property?.videos[3]);
          emailPropertyVideos[1].columns[3].contents[0].values.action.values.href =
            property?.videos[3];
        }
      }

      let emailPropertyVirtualTours: any[] = [];
      if (!!property?.virtual_tours?.length) {
        emailPropertyVirtualTours = deepArrayClone(
          emailPropertyVirtualToursJSON
        );
        emailPropertyVirtualTours[1].columns[0].contents[0].values.src.url =
          getVideoThumb(property?.virtual_tours[0]);
        emailPropertyVirtualTours[1].columns[0].contents[0].values.action.values.href =
          property?.virtual_tours[0];

        if (!!property?.images?.[1]) {
          emailPropertyVirtualTours[1].columns[1].contents[0].values.src.url =
            getVideoThumb(property?.virtual_tours[1]);
          emailPropertyVirtualTours[1].columns[1].contents[0].values.action.values.href =
            property?.virtual_tours[1];
        }

        if (!!property?.images?.[2]) {
          emailPropertyVirtualTours[1].columns[2].contents[0].values.src.url =
            getVideoThumb(property?.virtual_tours[2]);
          emailPropertyVirtualTours[1].columns[2].contents[0].values.action.values.href =
            property?.virtual_tours[2];
        }

        if (!!property?.images?.[3]) {
          emailPropertyVirtualTours[1].columns[3].contents[0].values.src.url =
            getVideoThumb(property?.virtual_tours[3]);
          emailPropertyVirtualTours[1].columns[3].contents[0].values.action.values.href =
            property?.virtual_tours[3];
        }
      }

      let suiteTemplate: any = [];
      if (!!suites?.length) {
        suiteTemplate = deepArrayClone(emailSuiteTitleJSON);

        let suiteData: any = [];
        const suiteDivide: any = deepArrayClone(emailSuiteDivideJSON);
        suites?.forEach((suite: AvailabilityProps, idx: number) => {
          if (idx >= 1) suiteData = [...suiteData, ...suiteDivide];
          const emailSuiteDetail: any[] = deepArrayClone(emailSuiteDetailJSON);
          emailSuiteDetail[0].columns[1].contents[0].values.text = `<p style=\"line-height: 140%;\">${getAvailabilityItem(
            suite,
            "floor"
          )}</p>`;
          emailSuiteDetail[0].columns[3].contents[0].values.text = `<p style=\"line-height: 140%;\">${getAvailabilityItem(
            suite,
            "name"
          )}</p>`;

          emailSuiteDetail[2].columns[1].contents[0].values.text = `<p style=\"line-height: 140%;\">${getAvailabilityItem(
            suite,
            "size"
          )}</p>`;
          emailSuiteDetail[2].columns[3].contents[0].values.text = `<p style=\"line-height: 140%;\">${getAvailabilityItem(
            suite,
            "max_price"
          )}</p>`;

          emailSuiteDetail[4].columns[1].contents[0].values.text = `<p style=\"line-height: 140%;\">${getAvailabilityItem(
            suite,
            "tenure_types"
          )}</p>`;
          emailSuiteDetail[4].columns[3].contents[0].values.text = `<p style=\"line-height: 140%;\">${getAvailabilityItem(
            suite,
            "date_available"
          )}</p>`;

          emailSuiteDetail[6].columns[1].contents[0].values.text = `<p style=\"line-height: 140%;\">${getAvailabilityItem(
            suite,
            "rent_type",
            meta
          )}</p>`;

          emailSuiteDetail[6].columns[2].contents[0].values.html =
            getAvailabilityItem(suite, "media");

          suiteData = [...suiteData, ...emailSuiteDetail];
        });
        suiteTemplate = [...suiteTemplate, ...suiteData];
      }

      let brokerTemplate: any = [];
      if (!!property?.brokers?.length) {
        brokerTemplate = deepArrayClone(emailContactTitleJSON);

        let brokerData: any = [];
        const divide: any = deepArrayClone(emailSuiteDivideJSON);
        for (let i = 0; i < property?.brokers.length; i += 2) {
          if (i >= 2) brokerData = [...brokerData, ...divide];
          const emailBrokerDetail: any[] = deepArrayClone(emailContactInfoJSON);
          emailBrokerDetail[0].columns[0].contents[0].values.src.url =
            property?.brokers[i].photo;
          emailBrokerDetail[0].columns[1].contents[0].values.text = `<p style=\"line-height: 140%;\"><strong>${
            property?.brokers[i]?.name || "-"
          }</strong></p>\n<p style=\"line-height: 140%;\">${
            property?.brokers[i]?.role || property?.brokers[i]?.job_title || "-"
          }</p>\n<p style=\"line-height: 140%;\">${
            property?.brokers[i]?.email || "-"
          }</p>\n<p style=\"line-height: 140%;\">${
            property?.brokers[i]?.telephone || "-"
          }</p>`;
          if (!!property?.brokers?.[i + 1]) {
            emailBrokerDetail[0].columns[2].contents[0].values.src.url =
              property?.brokers[i + 1].photo;
            emailBrokerDetail[0].columns[3].contents[0].values.text = `<p style=\"line-height: 140%;\"><strong>${
              property?.brokers[i + 1]?.name || "-"
            }</strong></p>\n<p style=\"line-height: 140%;\">${
              property?.brokers[i + 1]?.role ||
              property?.brokers[i + 1]?.job_title ||
              "-"
            }</p>\n<p style=\"line-height: 140%;\">${
              property?.brokers[i + 1]?.email || "-"
            }</p>\n<p style=\"line-height: 140%;\">${
              property?.brokers[i + 1]?.telephone || "-"
            }</p>`;
          }
          brokerData = [...brokerData, ...emailBrokerDetail];
        }
        brokerTemplate = [...brokerTemplate, ...brokerData];
      }

      templateJson.body.rows = [
        ...emailHeader,
        ...emailHome,
        ...emailPropertyDetail,
        ...emailPropertyImages,
        ...emailPropertyFloorplans,
        ...emailPropertyBrochures,
        ...emailPropertyVideos,
        ...emailPropertyVirtualTours,
        ...suiteTemplate,
        ...brokerTemplate,
      ];
      setEmailTemplate(templateJson);
      setIsReady(false);
    } catch (err) {
      setIsReady(false);
    }
  };

  useEffect(() => {
    if (!property) return;
    setValues({ ...values, subject: `Update on ${property?.title}` });
    setTemplate();
  }, [property, suites]);

  useEffect(() => {
    if (!emailTemplate?.body?.rows?.length || !isReady) return;
    emailEditorRef?.current?.editor?.loadDesign(emailTemplate);
  }, [emailTemplate, isReady]);

  useEffect(() => {
    if (isOpen) setIsReady(false);
  }, [isOpen]);

  const onReady = () => {
    if (isOpen) {
      setIsReady(true);
    } else setIsReady(false);
  };

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen} size="extra">
      <Modal.Header className="relative">
        <Button
          variant="neutral"
          leadingIcon={FiX}
          className="absolute top-0 right-0 w-5 !h-5"
          leadingIconClass="w-5 h-5 text-jll-color-text-base-subdued"
          onClick={() => setIsOpen(false)}
        />
        <h5 className="text-xl font-semibold text-jll-color-text-base-default">
          Email Editor
        </h5>
      </Modal.Header>
      <form onSubmit={handleSubmit}>
        <Modal.Body className="mt-4">
          <div className="flex flex-row mb-4">
            <Label className="w-[80px]">From:</Label>
            <Label className="text-jll-color-text-base-default ml-1">
              {session?.email}
            </Label>
          </div>
          <div className="flex flex-row">
            <Label className="mb-4 w-[80px]">To: </Label>
            <MultiEmailInput
              emails={values.emails}
              setEmails={(e: string[]) => {
                setFieldValue("emails", e);
              }}
              className="!mb-4"
            />
          </div>
          <div className="flex flex-row mb-4">
            <Label className="w-[80px]">CC:</Label>
            <Label className="text-jll-color-text-base-default ml-1">
              {session?.email}
            </Label>
          </div>
          <div className="flex flex-row">
            <Label className="mb-6 w-[86px]">Subject: </Label>
            <Input
              name="subject"
              onChange={handleChange}
              value={values.subject}
              placeholder="Please input the subject"
              error={touched.subject ? errors.subject : ""}
              onBlur={handleBlur}
              className="w-full"
            />
          </div>

          <div
            className={cn(
              "min-h-[500px] w-full flex justify-center items-center",
              {
                visible: !isReady,
                hidden: isReady,
              }
            )}
          >
            <Loading />
          </div>
          <div
            className={cn(
              "min-h-[500px] w-full flex justify-center items-center",
              {
                visible: isReady,
                hidden: !isReady,
              }
            )}
          >
            <EmailEditor ref={emailEditorRef} onReady={onReady} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant={"primary"}
            type="submit"
            disabled={!dirty || !isValid || !isReady}
          >
            Send Email
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default EmailEditorModal;
