import { useEffect, useState, useContext, useMemo } from "react";
import type { FC } from "react";
import cn from "classnames";
import debounce from "lodash.debounce";
import { useNavigate, useLocation } from "react-router-dom";
import {
  FiSearch,
  FiRotateCcw,
  FiEdit3,
  FiGrid,
  FiDownload,
} from "react-icons/fi";
import {
  EmptyResult,
  Loading,
  Button,
  Input,
  Toast,
  SearchableMultipleSelect,
} from "ui-atoms";
import {
  Table,
  ShowMoreItems,
  StyledAvailabilityValue,
  AvailabilityPreviewModal,
  RemoveDescriptionItem,
  Facets,
} from "ui-molecules";
import queryString from "query-string";
import { LayoutListing } from "ui-organisms";
import { SEARCH_RESULT_LIMIT } from "./constants";
import type { FacetsProps, AvailabilityProps, FilteredFacetProps } from "types";
import { DirectionEnum } from "types";
import {
  deleteAvailabilityAPI,
  getAvailabilitiesAPI,
  getAvailabilitiesExcelAPI,
  getBrokersAPI,
  getUsersAPI,
  patchAvailabilityAPI,
  postTableSchemaAPI,
} from "services";
import { useApiCall } from "hooks";
import {
  AVAILABILITIES_TABLE_COLUMNS,
  URLS,
  META_TAGS,
  STATUS_ACTIVE,
  STATUS_REMOVED,
  SET_LOADING_MODAL,
  PUBLISH_STATUS_PUBLISHED,
  SET_EVENT_MODAL,
  EVENT_REMOVED_INACTIVE_AVAILABILITY,
  EventProps,
  ALL_AVAILABILITIES_COLUMNS,
  SET_INLINE_EDIT_SELECT_MODAL,
  SET_META,
  EVENT_REINSTATED_AVAILABILITY,
} from "constant";
import { Helmet } from "react-helmet-async";
import { GlobalContext } from "context";
import {
  getContactTypeId,
  getFilteredFacets,
  getFormatedDateTime,
  reorder,
} from "utils";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import ReactDOM from "react-dom";
import React from "react";

const Availabilities: FC = () => {
  const { dispatch, state } = useContext(GlobalContext);
  const { meta, portalRef } = state;
  const [getAvailabilities, isLoadingAvailabilities] =
    useApiCall(getAvailabilitiesAPI);
  const [getAvailabilitiesExcel] = useApiCall(getAvailabilitiesExcelAPI);
  const [deleteAvailabilities] = useApiCall(deleteAvailabilityAPI);
  const [patchAvailability] = useApiCall(patchAvailabilityAPI);
  const [getUsers] = useApiCall(getUsersAPI);
  const [getBrokers] = useApiCall(getBrokersAPI);
  const [postTableSchema] = useApiCall(postTableSchemaAPI);
  const location = useLocation();
  const [openedAvailability, setOpenedAvailability] = useState(-1);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const [columns, setColumns] = useState<any>([]);
  const [isInlineEditMode, setIsInlineEditMode] = useState(false);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState<AvailabilityProps[]>();
  const [filterFacets, setFilterFacets] = useState<FilteredFacetProps[]>([]);
  const [sortFields, setSortFields] = useState<string[]>([]);
  const [keyword, setKeyword] = useState<string>();
  const [paramFilters, setParamFilters] = useState<any>(null);
  const [isExport, setIsExport] = useState(false);

  useEffect(() => {
    const filters = queryString.parse(location?.search, {
      arrayFormat: "bracket",
    });
    setParamFilters(filters);
  }, [location]);

  useEffect(() => {
    if (paramFilters === null) return;
    setKeyword(paramFilters?.keyword || "");
    setPage(1);
    setData([]);
    // Get the first set of Availabilities
    getAvailabilities({
      page: 1,
      limit: SEARCH_RESULT_LIMIT,
      ...paramFilters,
    }).then((res: any) => {
      setData(res?.docs);
      setPage(res?.page);
      setTotal(res?.total);
      setFilterFacets(getFilteredFacets(res?.facets as FacetsProps));
      setSortFields(res?.sort_fields);
    });
  }, [paramFilters]);

  useEffect(() => {
    if (!meta) return;
    const content_type = getContactTypeId(meta, "suite")?.[0];
    setColumns(
      !!meta?.table_schema?.length &&
        !!meta?.table_schema?.find(
          (schema: any) => schema?.content_type === content_type
        )
        ? JSON.parse(
            meta?.table_schema?.find(
              (schema: any) => schema?.content_type === content_type
            )?.json
          )
        : AVAILABILITIES_TABLE_COLUMNS
    );
  }, [meta]);

  const reloadAvailabilities = () => {
    getAvailabilities({
      page: 1,
      limit: SEARCH_RESULT_LIMIT,
      ...paramFilters,
    }).then((res: any) => {
      setData(res?.docs);
      setPage(res?.page);
      setTotal(res?.total);
      setFilterFacets(getFilteredFacets(res?.facets as FacetsProps));
      setSortFields(res?.sort_fields);
    });
  };

  const onClickSorting = (sort: string) => {
    const updatedFilters = {
      ...paramFilters,
      sort,
      direction:
        paramFilters?.direction === DirectionEnum.asc
          ? DirectionEnum?.desc
          : DirectionEnum.asc,
    };
    navigate(
      `${location.pathname}?${queryString.stringify(updatedFilters, {
        arrayFormat: "bracket",
      })}`
    );
  };

  const onClickPreview = (e: any, pk: AvailabilityProps["pk"]) => {
    e.stopPropagation();
    setOpenedAvailability(pk);
    setIsOpen(true);
  };

  const onClickEdit = (availability: AvailabilityProps) => () => {
    navigate(
      `/property/${
        availability?.building || availability?.building_id
      }/availability/${availability?.pk}/${URLS.AVAILABILITY.FORM}`
    );
  };

  const onClickShowMore = () => {
    const nextPage = page + 1;
    setPage(nextPage);

    getAvailabilities({
      page: nextPage,
      limit: SEARCH_RESULT_LIMIT,
      ...paramFilters,
    }).then((res: any) => {
      setData((prevData: any) => [...prevData, ...res?.docs]);
      setPage(res?.page);
      setTotal(res?.total);
      setFilterFacets(getFilteredFacets(res?.facets as FacetsProps));
      setSortFields(res?.sort_fields);
    });
  };

  const updateKeywordFilter = (e: any) => {
    const updatedFilters = { ...paramFilters };
    if (e.target.value) {
      updatedFilters["keyword"] = e.target.value;
    } else if (updatedFilters.hasOwnProperty("keyword")) {
      delete updatedFilters.keyword;
    }
    navigate(
      `${location.pathname}?${queryString.stringify(updatedFilters, {
        arrayFormat: "bracket",
      })}`
    );
  };

  const debounceUpdateKeyword = useMemo(
    () => debounce(updateKeywordFilter, 300),
    [paramFilters]
  );

  const onChangeInput = (e: any) => {
    setKeyword(e.target.value);
    debounceUpdateKeyword(e);
  };

  const onClickReset = () => {
    navigate(location.pathname);
  };

  const onClickRemove = (e: any, availability: AvailabilityProps) => {
    e.stopPropagation();
    const action = (event: EventProps) => {
      dispatch({
        type: SET_LOADING_MODAL,
        payload: {
          open: true,
          label: "Moving Availability to Inactive",
        },
      });
      deleteAvailabilities({
        id: availability?.pk,
        payload: { ...{ ...event } },
      })
        .then((res: any) => {
          Toast.success("Availability moved with success!");
          reloadAvailabilities();
        })
        .finally(() => {
          setTimeout(() => {
            dispatch({
              type: SET_LOADING_MODAL,
              payload: {
                open: false,
                label: null,
              },
            });
          }, 100);
        });
    };

    dispatch({
      type: SET_EVENT_MODAL,
      payload: {
        open: true,
        content: {
          title: "Move Availability to Inactive",
          label: `${
            availability?.publish_status === PUBLISH_STATUS_PUBLISHED
              ? "This will unpublish the availability and move the availability to the inactive tab.  What is the reason for unpublishing and moving this availability to inactive?"
              : "This will move the availability to the inactive tab. "
          }`,
          eventType: EVENT_REMOVED_INACTIVE_AVAILABILITY,
          buttonLabel: "OK",
          action,
        },
      },
    });
  };

  const onActiveUpdate = async (
    id: any,
    active_status: number,
    event: any = {}
  ) => {
    const response = await patchAvailability({
      id,
      payload: {
        active_status,
        ...{ ...event },
      },
    });
    if (!response) return;
    Toast.success("Updated successfully");
    reloadAvailabilities();
  };

  const onClickReinstate = async (e: any, availability: any) => {
    e.stopPropagation();
    const action = async (event: EventProps) => {
      try {
        dispatch({
          type: SET_LOADING_MODAL,
          payload: {
            open: true,
            label: `Reinstating the availability`,
          },
        });
        await onActiveUpdate(availability?.pk, STATUS_ACTIVE, event);
        setTimeout(() => {
          dispatch({
            type: SET_LOADING_MODAL,
            payload: {
              open: false,
              label: null,
            },
          });
        }, 100);
      } catch (err) {
        setTimeout(() => {
          dispatch({
            type: SET_LOADING_MODAL,
            payload: {
              open: false,
              label: null,
            },
          });
        }, 100);
      }
    };
    dispatch({
      type: SET_EVENT_MODAL,
      payload: {
        open: true,
        content: {
          title: "Reinstate Availability",
          label: `What is reason for reinstate this availability?`,
          eventType: EVENT_REINSTATED_AVAILABILITY,
          buttonLabel: "Reinstate Availability",
          action,
        },
      },
    });
  };

  const updateItem = (newValues: any, pk: string | number) => {
    const index = data?.findIndex(
      (item: AvailabilityProps) => item?.pk === pk || item?.id === pk
    );
    if (index !== undefined && index > -1) {
      const newData = [...(data || [])];
      newData[index] = {
        ...newData[index],
        ...newValues,
      };
      setData(newData);
    }
  };

  const handleColumnSubmit = (data: any) => {
    try {
      const content_type = getContactTypeId(meta, "suite")?.[0];
      const payload = {
        content_type,
        json: JSON.stringify(data),
      };
      postTableSchema(payload).then(() => {
        let table_schema = meta?.table_schema;
        const index = table_schema?.findIndex(
          (schema: any) => schema?.content_type === content_type
        );
        if (!table_schema) table_schema = [];
        if (index > -1) table_schema[index] = payload;
        else table_schema.push(payload);
        dispatch({
          type: SET_META,
          payload: {
            ...meta,
            table_schema,
          },
        });
      });
    } catch (err) {}
  };

  const handleColumn = () => {
    let totalColumns = ALL_AVAILABILITIES_COLUMNS;

    dispatch({
      type: SET_INLINE_EDIT_SELECT_MODAL,
      payload: {
        open: true,
        content: {
          line: 2,
          list: totalColumns,
          label: "Add Column",
          current: columns,
          func: handleColumnSubmit,
        },
      },
    });
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    const newOrder = reorder(
      [...columns],
      result.source.index,
      result.destination.index
    );
    setColumns(newOrder);
    handleColumnSubmit(newOrder);
  };

  const handleExport = async () => {
    try {
      setIsExport(true);
      const response = await getAvailabilitiesExcel({
        export: true,
        ...paramFilters,
      });
      const fileData = new Blob([response], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const fileUrl = URL.createObjectURL(fileData);
      const link = document.createElement("a");
      link.href = fileUrl;
      link.download = `Export Availability - ${getFormatedDateTime(
        new Date().toString()
      )}`;
      link.click();
      URL.revokeObjectURL(fileUrl);
      setIsExport(false);
    } catch (err: any) {
      if (err?.message) Toast.error(err?.message);
      setIsExport(false);
    }
  };

  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>{META_TAGS?.availabilities?.title}</title>
        <meta property="og:title" content={META_TAGS?.availabilities?.title} />
      </Helmet>
      <LayoutListing>
        <LayoutListing.Header>
          <h1 className="text-heading1">Availabilities</h1>
          <div className="flex space-x-4 items-center">
            <div className="flex flex-row items-center space-x-3">
              {(() => {
                const data = filterFacets?.find(
                  (facet) => facet?.id === "coordinator"
                );
                if (!data) return;
                return (
                  <SearchableMultipleSelect
                    name={data?.id}
                    title={data?.label}
                    serviceAPI={getUsers}
                  />
                );
              })()}
              {(() => {
                const data = filterFacets?.find(
                  (facet) => facet?.id === "broker"
                );
                if (!data) return;
                return (
                  <SearchableMultipleSelect
                    name={data?.id}
                    title={data?.label}
                    serviceAPI={getBrokers}
                  />
                );
              })()}
            </div>
            <Input
              className="w-[400px] !mb-0"
              name="search"
              leadingIcon={FiSearch}
              onChange={onChangeInput}
              value={keyword}
              isClearable
              placeholder="Search by building name, listing ID, address, brokers name"
            />
          </div>
        </LayoutListing.Header>
        <LayoutListing.Container>
          <Facets filterFacets={filterFacets} />
          <LayoutListing.Content>
            <div className="p-6">
              <div className="flex space-x-4 pb-6 justify-between items-center">
                <div className="font-semibold text-jll-color-coldGray-7 text-sm">
                  {total} result{total > 1 ? "s" : ""}
                </div>
                <div className="flex flex-row items-center space-x-2">
                  <Button
                    variant="primary"
                    leadingIcon={FiDownload}
                    leadingIconClass="font-bold"
                    onClick={handleExport}
                    disabled={isExport}
                  >
                    Export to Excel
                  </Button>
                  {!isInlineEditMode ? (
                    <Button
                      variant="secondary"
                      leadingIcon={FiEdit3}
                      onClick={() => setIsInlineEditMode(true)}
                    >
                      Edit mode
                    </Button>
                  ) : (
                    <Button
                      variant="primary"
                      leadingIcon={FiGrid}
                      onClick={() => setIsInlineEditMode(false)}
                    >
                      View mode
                    </Button>
                  )}
                  {paramFilters && !!Object.keys(paramFilters)?.length && (
                    <Button
                      variant="ghost"
                      size="small"
                      leadingIcon={FiRotateCcw}
                      onClick={onClickReset}
                    >
                      Reset
                    </Button>
                  )}
                </div>
              </div>
              {!!isLoadingAvailabilities && !data?.length && <Loading />}
              {!isLoadingAvailabilities && !data?.length && (
                <EmptyResult>
                  <h3 className="text-heading3 text-jll-color-coldGray-5">
                    Sorry, no availabilities found.
                  </h3>
                </EmptyResult>
              )}
              {!!data?.length && (
                <>
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable" direction="horizontal">
                      {(provided, snapshot) => {
                        return (
                          <Table
                            isEdit={isInlineEditMode}
                            handleColumn={handleColumn}
                          >
                            <div className="hidden">{provided.placeholder}</div>
                            <Table.Thead>
                              <Table.Tr
                                className="z-[3]"
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                              >
                                {columns.map((column: any, index: number) => {
                                  return (
                                    <Draggable
                                      key={column.id}
                                      draggableId={`${column?.id}`}
                                      index={index}
                                      isDragDisabled={!isInlineEditMode}
                                    >
                                      {(provided, snapshot) => {
                                        const usePortal: boolean =
                                          snapshot.isDragging;
                                        const child = (
                                          <Table.Th
                                            key={index}
                                            className={column?.th?.className}
                                            filterId={column.id}
                                            sortFields={sortFields}
                                            filters={paramFilters}
                                            onClick={onClickSorting}
                                            width={column?.width || null}
                                            isEdit={isInlineEditMode}
                                            editable={true}
                                            ref={provided.innerRef}
                                            draggableProps={
                                              provided?.draggableProps
                                            }
                                            dragHandleProps={
                                              provided?.dragHandleProps
                                            }
                                            itemClassName={cn({
                                              "bg-jll-color-coldGray-1 ":
                                                snapshot.isDragging,
                                            })}
                                            handleColumnSubmit={
                                              handleColumnSubmit
                                            }
                                            currentColumns={[...columns]}
                                            handleColumn={handleColumn}
                                          >
                                            {column.label}
                                          </Table.Th>
                                        );
                                        if (!usePortal) {
                                          return child;
                                        }
                                        return ReactDOM.createPortal(
                                          child,
                                          portalRef?.current
                                        );
                                      }}
                                    </Draggable>
                                  );
                                })}
                                <Table.Th />
                              </Table.Tr>
                            </Table.Thead>
                            <Table.Tbody>
                              {data?.map(
                                (
                                  availability: AvailabilityProps,
                                  index: number
                                ) => {
                                  return (
                                    <Table.Tr
                                      key={index}
                                      onClick={onClickEdit(availability)}
                                      isEdit={isInlineEditMode}
                                    >
                                      {columns.map(
                                        (column: any, index: number) => {
                                          return (
                                            <Table.Td
                                              key={index}
                                              className={column?.td?.className}
                                              isEdit={isInlineEditMode}
                                            >
                                              <StyledAvailabilityValue
                                                availability={availability}
                                                valueKey={column.id}
                                                isEditMode={isInlineEditMode}
                                                updateItem={updateItem}
                                              />
                                            </Table.Td>
                                          );
                                        }
                                      )}

                                      <Table.Td className="space-x-2 text-right">
                                        {availability?.active_status ===
                                          STATUS_ACTIVE && (
                                          <>
                                            <Button
                                              variant="secondary"
                                              size="small"
                                              onClick={(e) =>
                                                onClickPreview(
                                                  e,
                                                  availability?.pk
                                                )
                                              }
                                            >
                                              Preview
                                            </Button>
                                            <Button
                                              variant="secondary"
                                              size="small"
                                              onClick={(e) =>
                                                onClickRemove(e, availability)
                                              }
                                            >
                                              Move to Inactive
                                            </Button>
                                          </>
                                        )}
                                        {availability?.active_status ===
                                          STATUS_REMOVED && (
                                          <Button
                                            variant="secondary"
                                            size="small"
                                            onClick={(e) =>
                                              onClickReinstate(e, availability)
                                            }
                                          >
                                            Reinstate
                                          </Button>
                                        )}
                                      </Table.Td>
                                    </Table.Tr>
                                  );
                                }
                              )}
                            </Table.Tbody>
                          </Table>
                        );
                      }}
                    </Droppable>
                  </DragDropContext>
                  <ShowMoreItems
                    onClick={onClickShowMore}
                    total={total}
                    isLoading={isLoadingAvailabilities}
                    itemsCount={data.length}
                  />
                </>
              )}
            </div>
          </LayoutListing.Content>
        </LayoutListing.Container>
      </LayoutListing>
      <AvailabilityPreviewModal
        pk={openedAvailability}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </>
  );
};

export default React.memo(Availabilities);
