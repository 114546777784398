import queryString from "query-string";
import axios from "../axiosConfig";
import { API_PREFIX_URL } from "constant";

export const getOpexAPI = async (props: any) => {
  const param_str = queryString.stringify(props, {
    arrayFormat: "bracket",
  });
  const res = await axios.get(`${API_PREFIX_URL}/opex/?${param_str}`);
  return res;
};

export const postOpexAPI = async (data: any) => {
  const res = await axios.post(`${API_PREFIX_URL}/opex/`, data);
  return res;
};

export const patchOpexAPI = async ({
  id,
  payload,
}: {
  id: number;
  payload: any;
}) => {
  const res = await axios.patch(`${API_PREFIX_URL}/opex/${id}/`, payload);
  return res;
};

export const deleteOpexAPI = async (id: number) => {
  const res = await axios.delete(`${API_PREFIX_URL}/opex/${id}/`);
  return res;
};
