export const emailContactInfoJSON = [
  {
    id: "FC7uP76bn3",
    cells: [1, 2, 1, 2],
    columns: [
      {
        id: "RYty9k05CB",
        contents: [
          {
            id: "2ozJoaSsFQ",
            type: "image",
            values: {
              containerPadding: "10px",
              anchor: "",
              src: {
                url: "",
                width: 800,
                height: 200,
              },
              textAlign: "center",
              altText: "",
              action: {
                name: "web",
                values: { href: "", target: "_blank" },
              },
              hideDesktop: false,
              displayCondition: null,
              _meta: {
                htmlID: "u_content_image_31",
                htmlClassNames: "u_content_image",
              },
              selectable: true,
              draggable: true,
              duplicatable: true,
              deletable: true,
              hideable: true,
            },
          },
        ],
        values: {
          backgroundColor: "#f6f7f7",
          padding: "0px",
          border: {},
          borderRadius: "0px",
          _meta: { htmlID: "u_column_83", htmlClassNames: "u_column" },
        },
      },
      {
        id: "Y9z8S8rHVe",
        contents: [
          {
            id: "CnbWnG4uP5",
            type: "text",
            values: {
              containerPadding: "10px",
              anchor: "",
              fontSize: "14px",
              textAlign: "left",
              lineHeight: "140%",
              linkStyle: {
                inherit: true,
                linkColor: "#0000ee",
                linkHoverColor: "#0000ee",
                linkUnderline: true,
                linkHoverUnderline: true,
              },
              hideDesktop: false,
              displayCondition: null,
              _meta: {
                htmlID: "u_content_text_16",
                htmlClassNames: "u_content_text",
              },
              selectable: true,
              draggable: true,
              duplicatable: true,
              deletable: true,
              hideable: true,
              text: "",
            },
          },
        ],
        values: {
          backgroundColor: "#f6f7f7",
          padding: "0px",
          border: {},
          borderRadius: "0px",
          _meta: { htmlID: "u_column_84", htmlClassNames: "u_column" },
        },
      },
      {
        id: "Dehyi20bDG",
        contents: [
          {
            id: "UyDib3DWvt",
            type: "image",
            values: {
              containerPadding: "10px",
              anchor: "",
              src: {
                url: "",
                width: 800,
                height: 200,
              },
              textAlign: "center",
              altText: "",
              action: {
                name: "web",
                values: { href: "", target: "_blank" },
              },
              hideDesktop: false,
              displayCondition: null,
              _meta: {
                htmlID: "u_content_image_32",
                htmlClassNames: "u_content_image",
              },
              selectable: true,
              draggable: true,
              duplicatable: true,
              deletable: true,
              hideable: true,
            },
          },
        ],
        values: {
          backgroundColor: "#f6f7f7",
          padding: "0px",
          border: {},
          borderRadius: "0px",
          _meta: { htmlID: "u_column_85", htmlClassNames: "u_column" },
        },
      },
      {
        id: "S_oCNUrYtQ",
        contents: [
          {
            id: "61ymE0ffge",
            type: "text",
            values: {
              containerPadding: "10px",
              anchor: "",
              fontSize: "14px",
              textAlign: "left",
              lineHeight: "140%",
              linkStyle: {
                inherit: true,
                linkColor: "#0000ee",
                linkHoverColor: "#0000ee",
                linkUnderline: true,
                linkHoverUnderline: true,
              },
              hideDesktop: false,
              displayCondition: null,
              _meta: {
                htmlID: "u_content_text_17",
                htmlClassNames: "u_content_text",
              },
              selectable: true,
              draggable: true,
              duplicatable: true,
              deletable: true,
              hideable: true,
              text: "",
            },
          },
        ],
        values: {
          backgroundColor: "#f6f7f7",
          padding: "0px",
          border: {},
          borderRadius: "0px",
          _meta: { htmlID: "u_column_86", htmlClassNames: "u_column" },
        },
      },
    ],
    values: {
      displayCondition: null,
      columns: false,
      backgroundColor: "",
      columnsBackgroundColor: "",
      backgroundImage: {
        url: "",
        fullWidth: true,
        repeat: "no-repeat",
        size: "custom",
        position: "center",
      },
      padding: "0px",
      anchor: "",
      hideDesktop: false,
      _meta: { htmlID: "u_row_38", htmlClassNames: "u_row" },
      selectable: true,
      draggable: true,
      duplicatable: true,
      deletable: true,
      hideable: true,
    },
  },
];
