import { FiAlertTriangle, FiMail } from "react-icons/fi";
import { Button } from "ui-atoms";
import { Modal } from "ui-molecules";
import { useContext } from "react";
import { GlobalContext } from "context";
import { SET_PERMISSION_MODAL } from "constant";

const PermissionModal = () => {
  const { state, dispatch } = useContext(GlobalContext);
  const { permissionModalOpen } = state;

  const closeModal = () => {
    dispatch({
      type: SET_PERMISSION_MODAL,
      payload: {
        open: false,
        content: null,
      },
    });
  };

  const handleContact = () => {
    const recipient = "listingsupport@jll.com";
    const subject = "Listing Admin Request";

    const mailtoLink = `mailto:${recipient}?subject=${encodeURIComponent(
      subject
    )}`;

    window.open(mailtoLink);
  };

  return (
    <Modal isOpen={permissionModalOpen} setIsOpen={closeModal} size="medium">
      <Modal.Header>
        <div className="flex justify-center items-center flex-col">
          <FiAlertTriangle className="h-16 w-16 text-jll-color-coldGray-4" />
        </div>
      </Modal.Header>
      <Modal.Body>
        <p className="text-center text-jll-color-text-base-default p-4 pt-6">
          You don’t have access to manage this property. Click here to request
          Listing Admin access
        </p>
      </Modal.Body>
      <Modal.Footer>
        <div className="flex justify-center items-center w-full flex-row space-x-2">
          <Button
            variant="secondary"
            leadingIcon={FiMail}
            onClick={handleContact}
          >
            Contact Support
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default PermissionModal;
