import { Page, View, Image, Text } from "@react-pdf/renderer";
import { PAGE_ORIENTATION, PAGE_SIZE } from "constant";
import styles from "./styles";
import HomepageBg from "assets/homepage_bg.jpg";
import LightLogo from "assets/pdf/light-logo.png";
import BlurImg from "assets/pdf/blur.png";
import { PropertyProps, TeamListingLinkProps } from "types";
import { formatPhoneNumber, getImageFromURL, removeNewlines } from "utils";

interface IPageCover {
  bgImage?: string;
  teamListingLink?: TeamListingLinkProps;
}

const PageCover: React.FC<IPageCover> = ({ bgImage, teamListingLink }) => {
  let filteredBrokers: any = [];
  if (!!teamListingLink?.authors?.length || !!teamListingLink?.author?.length) {
    filteredBrokers = (
      teamListingLink?.authors || teamListingLink?.author
    )?.slice(0, 2);
  }
  return (
    <Page
      size={PAGE_SIZE}
      orientation={PAGE_ORIENTATION.LANDSCAPE}
      style={styles.page}
    >
      <View style={styles.view}>
        {!bgImage?.length ? (
          <Image src={HomepageBg} fixed style={styles.image} />
        ) : (
          <Image src={getImageFromURL(bgImage)} fixed style={styles.image} />
        )}
      </View>
      <View style={styles.blurImg}>
        <Image src={BlurImg} fixed />
      </View>
      <View style={styles.coverImg}>
        <Image
          src={LightLogo}
          style={{ width: "100%", height: "100%" }}
          fixed
        />
      </View>

      <View style={styles.txtView}>
        <Text style={styles.surveyType}>
          {teamListingLink?.title || teamListingLink?.team_listing_name || ""}
        </Text>
        <Text style={styles.address}>{teamListingLink?.description || ""}</Text>
        <Text style={styles.tenantName}>Team Listing</Text>
      </View>

      <View style={styles.brokerView}>
        <Text style={styles.representTxt}>Contact</Text>
        {!!filteredBrokers?.length &&
          filteredBrokers?.map((author: any) => (
            <View style={styles.brokerItem}>
              <Image
                src={getImageFromURL(author?.broker?.photo || "")}
                style={styles.brokerImg}
              />
              <View style={styles.brokerContent}>
                <Text style={styles.brokerName}>
                  {removeNewlines(
                    [author?.user_first_name, author?.user_last_name]?.join(" ")
                  )}
                </Text>
                <Text style={styles.brokerEmail}>
                  {author?.user_email || ""}
                </Text>
                <Text style={styles.brokerEmail}>
                  {formatPhoneNumber(author?.broker?.telephone || "")}
                </Text>
              </View>
            </View>
          ))}
      </View>
    </Page>
  );
};

export default PageCover;
