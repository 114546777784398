import { forwardRef } from "react";
import cn from "classnames";

export interface TrProps {
  className?: string;
  children?: any;
  onClick?: () => void;
  isEdit?: boolean;
}

const Tr = forwardRef<any, TrProps>(
  ({ children, className, onClick, isEdit, ...props }, ref) => {
    return (
      <tr
        className={cn(
          "border-b divide-jll-color-stroke-base-default relative",
          {
            "transition-colors cursor-pointer hover:bg-jll-color-coldGray-1":
              !isEdit && !!onClick,
          },
          className
        )}
        onClick={!isEdit ? onClick : undefined}
        ref={ref}
        {...props}
      >
        {children}
      </tr>
    );
  }
);

export default Tr;
