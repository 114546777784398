export const ACTIVITY_TYPE_CHOICES = {
  ACTIVITY_CREATE: "Created",
  ACTIVITY_UPDATE: "Updated",
  ACTIVITY_DELETE: "Deleted",
  ACTIVITY_PUBLISH: "Published",
  ACTIVITY_UNPUBLISH: "Un-published",
  ACTIVITY_REPUBLISH: "Re-published",
};

export const CHART_BAR_COLORS = {
  [ACTIVITY_TYPE_CHOICES.ACTIVITY_CREATE]: "#11C169",
  [ACTIVITY_TYPE_CHOICES.ACTIVITY_UPDATE]: "#2B59E2",
  [ACTIVITY_TYPE_CHOICES.ACTIVITY_DELETE]: "#C81542",
  [ACTIVITY_TYPE_CHOICES.ACTIVITY_PUBLISH]: "#FFC003",
  [ACTIVITY_TYPE_CHOICES.ACTIVITY_UNPUBLISH]: "#077272",
  [ACTIVITY_TYPE_CHOICES.ACTIVITY_REPUBLISH]: "#E37692",
};

interface TableColumnProps {
  id: string;
  label: string;
  sort?: string;
  td?: any;
}

export const REPORT_ACTIVITY_COLUMNES: TableColumnProps[] = [
  {
    id: "activity_type",
    label: "Activity Type",
  },
  {
    id: "activity_content",
    label: "Content",
  },
  {
    id: "activity_updated",
    label: "Updated",
  },
];

export const REPORT_MARKET_COLUMNS: TableColumnProps[] = [
  {
    id: "name",
    label: "Market",
  },
  {
    id: "total_count",
    label: "Total Properties",
  },
  {
    id: "line",
    label: "",
    td: {
      className: "w-8/12",
    },
  },
];

export const REPORT_PROPERTY_STALENESS_COLUMNS: TableColumnProps[] = [
  {
    id: "property_staleness",
    label: "Property",
  },
  {
    id: "address",
    label: "Address",
  },
  {
    id: "publish_status",
    label: "Status",
  },
  {
    id: "tenure_types",
    label: "Type",
    td: {
      className: "space-x-2",
    },
  },
  {
    id: "brokers",
    label: "Broker",
  },
  {
    id: "update_timestamp",
    label: "Updated",
  },
];

export const REPORT_SUITE_STALENESS_COLUMNS: TableColumnProps[] = [
  {
    id: "building_staleness",
    label: "Building",
  },
  {
    id: "info",
    label: "Suite",
  },
  {
    id: "floor",
    label: "Floor",
  },
  {
    id: "max_floor_area",
    label: "Size",
  },
  {
    id: "max_price",
    label: "Rate",
  },
  {
    id: "broker",
    label: "Brokers",
  },
  {
    id: "tenure_types",
    label: "Type",
    td: {
      className: "space-x-2 !z-auto",
    },
  },
  {
    id: "brokers",
    label: "Broker",
  },
  {
    id: "updated_info",
    label: "Updated",
  },
];
