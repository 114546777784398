import type { FC } from "react";
import cn from "classnames";
import { FiChevronLeft, FiExternalLink } from "react-icons/fi";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useLocation, Link, useParams } from "react-router-dom";
import { Button, Chip, Switch } from "ui-atoms";
import { useContext } from "react";
import { GlobalContext } from "context";
import { convertExtension, lazyLoadImageOnError } from "utils";
import { convertImageUrl } from "services";
import { URLS } from "constant";

interface menuItem {
  label: string;
  icon: any;
  id?: string;
  count?: boolean;
  suffixUrl: string;
  isOLMToggle?: boolean;
}

interface LayoutEditPropertySidebarProps {
  menuItems: menuItem[];
  backToUrl: string;
  olmToggle?: { isOLM: boolean; setIsOLM: any };
}

const LayoutEditPropertySidebar: FC<LayoutEditPropertySidebarProps> = ({
  menuItems,
  backToUrl,
  olmToggle,
}) => {
  const { state } = useContext(GlobalContext);
  const { property, meta } = state;
  const { showTooltip, hideTooltip } = state;
  const { propertyId } = useParams();
  const location = useLocation();
  const isNewProperty = propertyId === "new";

  const hasPropertyLink = () => {
    return property?.page_url ? true : false;
  };

  const openPropertyLink = () => {
    let url = `${meta?.PROPERTY_STATIC_URL}${property?.page_url}`
    if (property?.global_market == 'ca' && meta?.PROPERTY_STATIC_URL_CA) {
      url = `${meta?.PROPERTY_STATIC_URL_CA}/en-ca${property?.page_url}`
    } else if(property?.wls && property?.wls != null) {
      url = `${meta?.PROPERTY_STATIC_URL}/${property?.wls}${property?.page_url}`
    }

    window.open(url, "_blank");
  };

  return (
    <div
      className={cn(
        "fixed top-[64px] bottom-0 left-0 w-[360px] pt-8 overflow-y-auto flex flex-col justify-between bg-jll-color-surface-base-subdued"
      )}
    >
      <div>
        <div className="border-b border-black border-opacity-10 px-8 pb-9">
          <div className="flex flex-row justify-between items-center">
            <Link to={backToUrl}>
              <Button
                variant="neutral"
                leadingIconClass="w-5 h-5"
                leadingIcon={FiChevronLeft}
              >
                Back
              </Button>
            </Link>
            {hasPropertyLink() && (
              <Button
                variant="neutral"
                className="text-jll-color-icon-info"
                onClick={openPropertyLink}
                trailingIcon={FiExternalLink}
                trailingIconClass="w-5 h-5"
              >
                Property Link
              </Button>
            )}
          </div>
          {!isNewProperty && (
            <>
              <div className="flex flex-row pt-9">
                {property?.images?.[0] && (
                  <LazyLoadImage
                    src={convertImageUrl(
                      convertExtension(property?.images?.[0]) || ""
                    )}
                    className="w-[100px] h-[100px] rounded mr-4"
                    onError={lazyLoadImageOnError}
                  />
                )}
                <div className="flex flex-col overflow-hidden">
                  <p
                    className="text-3xl font-semibold text-jll-color-coldGray-9 whitespace-normal line-clamp-2"
                    id="building-name"
                    data-tooltip-content={property?.title}
                    onMouseOver={() => showTooltip("building-name")}
                    onMouseLeave={() => hideTooltip()}
                  >
                    {property?.title}
                  </p>
                </div>
              </div>
              <div className="mt-8 text-jll-color-coldGray-9">
                <Link
                  to={`/property/${propertyId}/${URLS.PROPERTY.FORM}`}
                  className="text-jll-color-icon-info"
                >
                  {property?.address && <p>{property?.address}</p>}
                  {property?.city && (
                    <p>
                      {[
                        property?.city,
                        [property?.state, property?.post_code].join(" "),
                      ].join(", ")}
                    </p>
                  )}
                </Link>
              </div>
              <div className="mt-6 space-x-2">
                <Chip variant="primary">{property?.property_type}</Chip>
              </div>
            </>
          )}
        </div>

        <div className="mt-8 px-8">
          {!isNewProperty &&
            menuItems?.map((item: any, idx) => {
              const active = location.pathname.includes(
                item.tag || item.suffixUrl
              );
              const Icon = item.icon;
              let to = `/property/${propertyId}`;
              to += `/${item.suffixUrl}`;

              let count = "";
              if (item?.count) {
                if (property?.[item.id]?.length > 0) {
                  count = property?.[item.id]?.length;
                }
              }

              return (
                <Link
                  key={idx}
                  className={cn(
                    "flex justify-between items-center cursor-pointer mb-6 hover:text-jll-color-coldGray-11",
                    {
                      "text-jll-color-coldGray-11": active,
                      "text-jll-color-coldGray-4": !active,
                    }
                  )}
                  to={to}
                >
                  <div className="flex flex-row items-center">
                    <Icon className="w-5 h-5 mr-2" />
                    <span className={cn({ "font-semibold": active })}>
                      {item.label}
                    </span>
                  </div>
                  {count && (
                    <span className={cn({ "font-semibold": active })}>
                      {count}
                    </span>
                  )}
                  {item?.isOLMToggle && (
                    <Switch
                      enabled={olmToggle?.isOLM || false}
                      onChange={(flag: boolean) => olmToggle?.setIsOLM(flag)}
                      label_1="All"
                      label_2="Only OLM"
                      className="!mb-0"
                    />
                  )}
                </Link>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default LayoutEditPropertySidebar;
