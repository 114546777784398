interface IAuthHomeNavbar {
  children: any;
  className?: string;
}
const AuthHomeNavbar = ({ children, className }: IAuthHomeNavbar) => {
  return (
    <div
      className={`w-full flex justify-between items-center h-[80px] z-10 ${className}`}
    >
      {children}
    </div>
  );
};

export default AuthHomeNavbar;
