import axios from "../axiosConfig";
import { API_PREFIX_URL } from "constant";

export const postCompanyAPI = async (data: { name: string }) => {
  const res = await axios.post(`${API_PREFIX_URL}/company/`, data);
  return res;
};

export const postBuildingCompanyAPI = async (data: {
  role: string[];
  company: number;
  building: number;
}) => {
  const res = await axios.post(`${API_PREFIX_URL}/building/company/`, data);
  return res;
};

export const putBuildingCompanyAPI = async ({ id, payload }: any) => {
  const res = await axios.put(
    `${API_PREFIX_URL}/building/company/${id}/`,
    payload
  );
  return res;
};

export const deleteBuildingCompanyAPI = async (id: number) => {
  const res = await axios.delete(`${API_PREFIX_URL}/building/company/${id}/`);
  return res;
};
