import { useApiCall, useComponentVisible } from "hooks";
import { SyntheticEvent, useContext, useEffect, useState } from "react";
import { FiCheck, FiEdit3, FiX } from "react-icons/fi";
import EditableIconBtn from "./EditableIconBtn";
import { patchAvailabilityAPI, patchPropertyAPI } from "services";
import { GlobalContext } from "context";
import {
  capitalize,
  getApiDate,
  getApiPayloadFromForm,
  getFormatedDate,
  getMetaOptions,
  getUTCDate,
  isCommonItem,
} from "utils";
import { Checkbox, DatePicker, SelectAutoComplete, Switch } from "ui-atoms";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  AVAILABILITY_VALIDATION,
  OLM_AVAILABILITY_FIELDS,
  OLM_PROPERTY_FIELDS,
  PROPERTY_VALIDATION,
  STATUS_ACTIVE,
} from "constant";

interface IEditableMultiField {
  initValues?: any;
  valueKeys: string[];
  labelKeys: string[];
  options?: any;
  type?: "text" | "number";
  pk?: string | number;
  children?: any;
  kind: "property" | "availability";
  updateItem?: any;
  isEditMode?: boolean;
  isLand?: boolean;
}

const EditableMultiField: React.FC<IEditableMultiField> = ({
  children,
  initValues,
  valueKeys,
  labelKeys,
  pk,
  type = "text",
  kind = "property",
  updateItem,
  isEditMode,
  isLand,
  options,
}) => {
  const { state } = useContext(GlobalContext);
  const { meta } = state;
  const [patchProperty] = useApiCall(patchPropertyAPI);
  const [patchAvailability] = useApiCall(patchAvailabilityAPI);
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(true);
  const [isEditing, setIsEditing] = useState(false);
  const [isAcresRange, setIsAcresRange] = useState(false);
  const [isFloorRange, setIsFloorRange] = useState(false);
  const [isPriceRange, setIsPriceRange] = useState(false);
  const [validationSchema, setValidationSchema] = useState(
    Yup.object().shape({})
  );
  const [isAvailable, setIsAvailable] = useState(false);

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    values,
    errors,
    touched,
    setValues,
    dirty,
    isValid,
  }: any = useFormik({
    initialValues: {},
    validationSchema,
    onSubmit: () => {
      try {
        let payload: any = getApiPayloadFromForm(initValues, values);
        if (payload?.hasOwnProperty("contract_start")) {
          payload["contract_start"] = getApiDate(payload["contract_start"]);
        }
        if (payload?.hasOwnProperty("contract_end")) {
          payload["contract_end"] = getApiDate(payload["contract_end"]);
        }
        let service = null;
        if (kind === "property") {
          service = patchProperty;
          if (
            payload &&
            initValues?.publish_status === STATUS_ACTIVE &&
            isCommonItem(Object.keys(payload), OLM_PROPERTY_FIELDS)
          ) {
            payload["publish_status"] = STATUS_ACTIVE;
          }
        } else if (kind === "availability") {
          service = patchAvailability;
          if (
            payload &&
            initValues?.publish_status === STATUS_ACTIVE &&
            isCommonItem(Object.keys(payload), OLM_AVAILABILITY_FIELDS)
          ) {
            payload["publish_status"] = STATUS_ACTIVE;
          }
        }
        if (!!payload)
          service({
            id: pk,
            payload,
          }).then((res: any) => {
            updateItem(values, pk);
          });
        setIsEditing(false);
        setIsComponentVisible(false);
      } catch (err) {}
    },
  });

  useEffect(() => {
    if (!initValues || !valueKeys) return;
    let init: any = {};
    valueKeys?.forEach((key: string) => (init[key] = initValues?.[key]));
    setValues(init);

    let validation_fields: any = {};
    if (kind === "property") {
      validation_fields = PROPERTY_VALIDATION?.fields;
    } else if (kind === "availability") {
      validation_fields = AVAILABILITY_VALIDATION?.fields;
    }

    const newValidations: any = Object.keys(validation_fields)
      .filter((key) => valueKeys?.includes(key))
      .reduce((obj: any, key) => {
        obj[key] = validation_fields?.[key];
        return obj;
      }, {});
    setValidationSchema(Yup.object().shape({ ...newValidations }));

    if (!!initValues?.min_surface_area) {
      setIsAcresRange(true);
    }

    if (!!initValues?.min_floor_area) {
      setIsFloorRange(true);
    }

    if (!!initValues?.min_price) {
      setIsPriceRange(true);
    }
  }, [initValues, valueKeys]);

  useEffect(() => {
    if (!!getApiPayloadFromForm(initValues, values)) setIsAvailable(true);
    else setIsAvailable(false);
  }, [initValues, values]);

  return (
    <>
      {isEditMode ? (
        <div
          onClick={(e: any) => {
            e.stopPropagation();
          }}
        >
          {isComponentVisible && isEditing ? (
            <div ref={ref}>
              <form onSubmit={handleSubmit}>
                <div className="table mb-2.5 w-full">
                  {valueKeys?.map((valueKey: string, idx: number) => (
                    <div className="table-row" key={idx}>
                      {valueKey === "hide_sales_price" ? (
                        <>
                          <Checkbox
                            id={valueKey}
                            name={valueKey}
                            label={labelKeys[idx]}
                            checked={values?.[valueKey]}
                            onChange={(e: any) => {
                              setFieldValue(valueKey, e.target.checked);
                            }}
                            onBlur={handleBlur}
                          />
                        </>
                      ) : (
                        <>
                          <div className="table-cell">
                            <div className="flex items-center w-full ">
                              <span className="text-sm text-jll-color-text-base-subdued  pr-2">
                                {(() => {
                                  switch (valueKey) {
                                    case "max_surface_area":
                                      return (
                                        <>{`${
                                          values?.surface_area_unit ===
                                            "acres" && isAcresRange
                                            ? "Max "
                                            : ""
                                        }${labelKeys[idx]}`}</>
                                      );
                                    case "min_surface_area":
                                      if (
                                        values?.surface_area_unit !== "acres" ||
                                        !isAcresRange
                                      )
                                        return <></>;
                                      return (
                                        <>{`${
                                          values?.surface_area_unit ===
                                            "acres" && isAcresRange
                                            ? "Min "
                                            : ""
                                        }${labelKeys[idx]}`}</>
                                      );

                                    case "max_floor_area":
                                      return (
                                        <>{`${isFloorRange ? "Max " : ""}${
                                          labelKeys[idx]
                                        }`}</>
                                      );

                                    case "min_floor_area":
                                      if (!isFloorRange) return <></>;
                                      return (
                                        <>{`${isFloorRange ? "Min " : ""}${
                                          labelKeys[idx]
                                        }`}</>
                                      );

                                    case "max_price":
                                      return (
                                        <>{`Lease ${
                                          isPriceRange ? "Max " : ""
                                        }Price / ${labelKeys[idx]}`}</>
                                      );

                                    case "min_price":
                                      if (!isPriceRange) return <></>;
                                      return (
                                        <>{`Lease Min Price / ${labelKeys[idx]}`}</>
                                      );

                                    default:
                                      return (
                                        <>
                                          {labelKeys[idx] ||
                                            capitalize(valueKey)}
                                        </>
                                      );
                                  }
                                })()}
                              </span>
                            </div>
                          </div>
                          <div className="table-cell py-1">
                            {(() => {
                              if (
                                valueKey === "min_surface_area" &&
                                (values?.surface_area_unit !== "acres" ||
                                  !isAcresRange)
                              )
                                return <></>;

                              if (
                                valueKey === "min_floor_area" &&
                                !isFloorRange
                              )
                                return <></>;

                              if (valueKey === "min_price" && !isPriceRange)
                                return <></>;

                              switch (valueKey) {
                                case "state":
                                  return (
                                    <>
                                      <SelectAutoComplete
                                        name={valueKey}
                                        className="!mb-0 "
                                        onChange={(option) => {
                                          if (!option)
                                            setFieldValue(valueKey, [null]);
                                          else
                                            setFieldValue(
                                              valueKey,
                                              option?.value
                                            );
                                        }}
                                        value={{
                                          value: values?.[valueKey],
                                          label: values?.[valueKey],
                                        }}
                                        options={options?.[valueKey]}
                                        onBlur={handleBlur}
                                        error={
                                          touched?.[valueKey]
                                            ? errors?.[valueKey]
                                            : ""
                                        }
                                      />
                                      {!!errors?.[valueKey] && (
                                        <p className="text-xs text-jll-color-surface-accent-default whitespace-normal">
                                          {errors?.[valueKey]}
                                        </p>
                                      )}
                                    </>
                                  );

                                case "surface_area_unit":
                                  return (
                                    <>
                                      <SelectAutoComplete
                                        className="!mb-0 min-w-[140px]"
                                        name="surface_area_unit"
                                        onChange={(option) => {
                                          if (!option) {
                                            setFieldValue(
                                              "surface_area_unit",
                                              null
                                            );
                                          } else {
                                            setFieldValue(
                                              "surface_area_unit",
                                              option.value
                                            );
                                          }

                                          if (option?.value === "acres") {
                                            setFieldValue(
                                              "min_surface_area",
                                              values.min_surface_area
                                            );
                                          } else {
                                            setFieldValue(
                                              "min_surface_area",
                                              null
                                            );
                                          }
                                        }}
                                        value={{
                                          value: values?.surface_area_unit
                                            ? values?.surface_area_unit
                                            : isLand
                                            ? "acres"
                                            : "feet",
                                          label: values?.surface_area_unit
                                            ? meta?.building?.surface_area_unit[
                                                values?.surface_area_unit
                                              ].value
                                            : isLand
                                            ? meta?.building?.surface_area_unit[
                                                "acres"
                                              ]?.value
                                            : meta?.building?.surface_area_unit[
                                                "feet"
                                              ]?.value,
                                        }}
                                        onBlur={handleBlur}
                                        error={
                                          touched.surface_area_unit
                                            ? errors.surface_area_unit
                                            : ""
                                        }
                                        options={[
                                          ...getMetaOptions(
                                            meta?.building?.surface_area_unit
                                          ),
                                        ]}
                                      />
                                      {values?.surface_area_unit ===
                                        "acres" && (
                                        <Switch
                                          enabled={isAcresRange}
                                          onChange={(flag: boolean) => {
                                            setIsAcresRange(flag);
                                            if (!flag)
                                              setFieldValue(
                                                "min_surface_area",
                                                null
                                              );
                                            else
                                              setFieldValue(
                                                "min_surface_area",
                                                values?.min_surface_area
                                              );
                                          }}
                                          className="!mb-0 mt-1"
                                          switchClassName="flex items-center"
                                        />
                                      )}
                                      {!!errors?.[valueKey] && (
                                        <p className="text-xs text-jll-color-surface-accent-default whitespace-normal">
                                          {errors?.[valueKey]}
                                        </p>
                                      )}
                                    </>
                                  );

                                case "floor_range":
                                  return (
                                    <Switch
                                      enabled={isFloorRange}
                                      onChange={(flag: boolean) => {
                                        setIsFloorRange(flag);
                                        if (!flag)
                                          setFieldValue("min_floor_area", null);
                                        else
                                          setFieldValue(
                                            "min_floor_area",
                                            values?.min_floor_area
                                          );
                                      }}
                                      className="!mb-0 mt-1"
                                      switchClassName="flex items-center"
                                    />
                                  );

                                case "price_range":
                                  return (
                                    <Switch
                                      enabled={isPriceRange}
                                      onChange={(flag: boolean) => {
                                        setIsPriceRange(flag);
                                        if (!flag)
                                          setFieldValue("min_price", null);
                                        else
                                          setFieldValue(
                                            "min_price",
                                            values?.min_price
                                          );
                                      }}
                                      className="!mb-0 mt-1"
                                      switchClassName="flex items-center"
                                    />
                                  );

                                case "contract_start":
                                case "contract_end":
                                  return (
                                    <DatePicker
                                      selected={
                                        values?.[valueKey]
                                          ? getUTCDate(values?.[valueKey])
                                          : getUTCDate()
                                      }
                                      onSelect={(date: any) =>
                                        setFieldValue(valueKey, date)
                                      }
                                      error={
                                        touched?.[valueKey]
                                          ? errors?.[valueKey]
                                          : ""
                                      }
                                      value={
                                        values?.[valueKey]
                                          ? getFormatedDate(
                                              getUTCDate(
                                                values?.[valueKey]
                                              )?.toString()
                                            )
                                          : ""
                                      }
                                      popperProps={{ strategy: "fixed" }}
                                      onChange={(value: Date) =>
                                        setFieldValue(valueKey, value)
                                      }
                                      className="!mb-0"
                                    />
                                  );

                                default:
                                  return (
                                    <>
                                      <div className="px-2 py-1 border-jll-color-coldGray-3 border rounded flex">
                                        <input
                                          type={type}
                                          name={valueKey}
                                          className="bg-transparent border-0 w-full min-w-[80px] text-sm font-base focus:outline-0 focus:bg-trasparent hover:bg-trasparent focus:border-transparent focus:ring-0 p-0"
                                          min={
                                            type === "number" ? 0 : undefined
                                          }
                                          value={values?.[valueKey]}
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                        />
                                      </div>
                                      {!!errors?.[valueKey] && (
                                        <p className="text-xs text-jll-color-surface-accent-default whitespace-normal">
                                          {errors?.[valueKey]}
                                        </p>
                                      )}
                                    </>
                                  );
                              }
                            })()}
                          </div>
                        </>
                      )}
                    </div>
                  ))}
                </div>
                <div className="flex flex-row items-center space-x-0.5">
                  <EditableIconBtn
                    icon={FiCheck}
                    type="submit"
                    variant="success"
                    className="w-full"
                    disabled={!isValid || !dirty || !isAvailable}
                    onClick={handleSubmit}
                  />
                  <EditableIconBtn
                    icon={FiX}
                    variant="grey"
                    className="w-full"
                    onClick={() => {
                      setIsEditing(false);
                      setIsComponentVisible(false);
                    }}
                  />
                </div>
              </form>
            </div>
          ) : (
            <div
              className="flex flex-row items-center space-x-2 justify-between space-x-2"
              onDoubleClick={() => {
                setIsEditing(true);
                setIsComponentVisible(true);
              }}
            >
              <div>{children}</div>
              <FiEdit3
                className="w-4 h-4 text-jll-color-coldGray-4 cursor-pointer"
                onClick={() => {
                  setIsEditing(true);
                  setIsComponentVisible(true);
                }}
              />
            </div>
          )}
        </div>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

export default EditableMultiField;
