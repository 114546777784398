import * as Yup from "yup";

export const PROPERTY_VALIDATION = Yup.object().shape({
  address: Yup.string().required("This field is required"),
  city: Yup.string().required("This field is required"),
  post_code: Yup.string().required("This field is required"),
  state: Yup.string().required("This field is required"),
  latitude: Yup.string().required("This field is required"),
  longitude: Yup.string().required("This field is required"),

  tenure_types: Yup.array().min(1, "Need at least one listing type"),
  building_class: Yup.string().nullable().optional(),
  max_surface_area: Yup.number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .required("This field is required.")
    .moreThan(0, "Must be over 0"),
  min_surface_area: Yup.number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .optional()
    .moreThan(0, "Must be over 0")
    .test(
      "min-surface-validation",
      "Min value must be less than max value",
      // @ts-ignore
      (value: number | undefined, context: any) => {
        return value && context.parent.max_surface_area
          ? value < context.parent.max_surface_area
          : true;
      }
    ),
  min_rent: Yup.number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .optional()
    .moreThan(0, "Must be over 0")
    .test(
      "min-floor-validation",
      "Min rent value must be less than max rent value",
      // @ts-ignore
      (value: number | undefined, context: any) => {
        return value && context.parent.max_rent
          ? value < context.parent.max_rent
          : true;
      }
    ),
  max_rent: Yup.number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .optional()
    .moreThan(0, "Must be over 0"),
  parking_cost_reserved: Yup.string().nullable().optional(),
  parking_cost_unreserved: Yup.string().nullable().optional(),
});

export const AVAILABILITY_VALIDATION = Yup.object().shape(
  {
    tenure_types: Yup.array().min(1, "Need at least one listing type"),
    building: Yup.string().nullable().required("This field is required"),
    floor: Yup.string().nullable().required("This field is required"),
    lease_term: Yup.string().nullable().required("This field is required"),
    date_available: Yup.string().required("This field is required"),
    min_floor_area: Yup.number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .nullable()
      .optional()
      .moreThan(0, "Must be over 0")
      .test(
        "min-floor-validation",
        "Min value will be less or same than max value",
        // @ts-ignore
        (value: number | undefined, context: any) => {
          return value && context.parent.max_floor_area
            ? value <= context.parent.max_floor_area
            : true;
        }
      ),
    max_floor_area: Yup.number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .nullable()
      .required("This field is required")
      .moreThan(0, "Must be over 0"),
    min_price: Yup.number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .nullable()
      .optional()
      .moreThan(0, "Must be over 0")
      .test(
        "min-floor-validation",
        "Min price must be less than max price",
        // @ts-ignore
        (value: number | undefined, context: any) => {
          return value && context.parent.max_price
            ? value < context.parent.max_price
            : true;
        }
      ),
    max_price: Yup.number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .nullable()
      .optional()
      .moreThan(0, "Must be over 0"),
    highlights: Yup.array()
      .of(Yup.string().required("This field is required"))
      .nullable(),
    rent_type: Yup.number()
      .optional()
      .nullable()
      .transform((_, val) => (val === Number(val) ? val : null)),
  },
  [["min_floor_area", "min_floor_area"]]
);
