import { MAX_ONCE_FILE_LIMIT, SET_RIGHT_CONTEXT } from "constant";
import { useDropzone } from "react-dropzone";
import { FiImage, FiUpload } from "react-icons/fi";
import { ContextMenuTrigger } from "react-contextmenu";
import { useContext, useState } from "react";
import { GlobalContext } from "context";
import "./dropzone.css";
import { Input } from "ui-atoms";
import { capitalize } from "lodash";

interface IDropzone {
  acceptType?: any;
  dropFiles?: any[];
  setDropFiles?: any;
  setFileUrl?: any;
  className?: string;
  title?: string;
  name?: string;
  submitImg?: any;
  isImg?: boolean;
  isInteractive?: boolean;
  icon?: any;
}

const Dropzone: React.FC<IDropzone> = ({
  acceptType,
  setDropFiles,
  className,
  title = "Add images here,",
  name,
  submitImg,
  isImg = true,
  isInteractive = false,
  setFileUrl,
  icon = FiImage,
}) => {
  const { state, dispatch } = useContext(GlobalContext);
  const { rightContextId } = state;
  const [url, setUrl] = useState("");
  const onDrop = (acceptedFiles: any[]) => {
    setDropFiles([...acceptedFiles]);
  };

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: MAX_ONCE_FILE_LIMIT,
    onDrop,
    multiple: true,
    ...acceptType,
  });

  const handleContextMenu = () => {
    dispatch({
      type: SET_RIGHT_CONTEXT,
      payload: {
        func: submitImg,
        id: name,
      },
    });
  };

  const handleFileUrl = () => {
    setFileUrl(url);
    setUrl("");
  };

  const Icon = icon;

  return (
    <div className={className}>
      {isImg ? (
        // @ts-ignore
        isInteractive ? (
          <div className="w-full flex h-full cursor-pointer bg-jll-color-surface-info-subdued border border-dashed border-jll-color-surface-info-secondary rounded justify-center items-center">
            <div className="flex items-center flex-col justify-center h-full">
              {/* @ts-ignore */}
              <ContextMenuTrigger
                id={rightContextId}
                collect={handleContextMenu}
              >
                <div
                  {...getRootProps({
                    className:
                      "flex flex-col items-center h-full justify-center",
                  })}
                >
                  <input {...getInputProps()} />
                  <Icon className="w-5 h-5 mb-[10px] text-jll-color-coldGray-5" />
                  <div className="font-semibold text-xs text-jll-color-coldGray-5 text-center flex flex-col">
                    <span>{title}</span>
                    <span>
                      or{" "}
                      <span className="text-jll-color-text-base-default">
                        Browse
                      </span>
                    </span>
                  </div>
                </div>
              </ContextMenuTrigger>
              <div className="border-dashed border-t border-jll-color-surface-info-secondary px-3 pt-1.5 pb-3">
                <p className="font-semibold text-xs text-jll-color-coldGray-5 text-center mb-1.5 whitespace-normal">
                  or{" "}
                  <span className="text-jll-color-text-base-default">
                    Paste {capitalize(name?.replaceAll("_", " "))}
                  </span>
                </p>
                <Input
                  name={"link"}
                  placeholder="Enter link here..."
                  className="!mb-0"
                  value={url}
                  onChange={(e: any) => setUrl(e?.target?.value)}
                  trailingIcon={!!url?.length ? FiUpload : undefined}
                  onTrailingClick={!!url?.length ? handleFileUrl : undefined}
                />
              </div>
            </div>
          </div>
        ) : (
          // @ts-ignore
          <ContextMenuTrigger id={rightContextId} collect={handleContextMenu}>
            <div
              {...getRootProps({
                className:
                  "w-full flex h-full cursor-pointer bg-jll-color-surface-info-subdued border border-dashed border-jll-color-surface-info-secondary rounded justify-center items-center",
              })}
            >
              <input {...getInputProps()} />
              <div className="flex items-center flex-col justify-center h-full">
                <div className="flex flex-col items-center pb-1.5">
                  <FiImage className="w-5 h-5 mb-[10px] text-jll-color-coldGray-5" />
                  <div className="font-semibold text-sm text-jll-color-coldGray-5 text-center flex flex-col">
                    <span>{title}</span>
                    <span>
                      or{" "}
                      <span className="text-jll-color-text-base-default">
                        Browse
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </ContextMenuTrigger>
        )
      ) : (
        <div
          {...getRootProps({
            className:
              "w-full flex h-full cursor-pointer bg-jll-color-surface-info-subdued border border-dashed border-jll-color-surface-info-secondary rounded justify-center items-center",
          })}
        >
          <input {...getInputProps()} />
          <div className="flex items-center flex-col">
            <div className=" flex flex-row font-semibold text-sm text-jll-color-coldGray-5 text-center">
              <span>{title}</span>
              <span>
                or{" "}
                <span className="text-jll-color-text-base-default">Browse</span>
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dropzone;
