import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";
import { convertImageUrl } from "services";
import type { CommentProps } from "types";
import { Avatar } from "ui-atoms";
import {
  convertExtension,
  getFormatedDateTime,
  lazyLoadImageOnError,
} from "utils";

export interface StyledCommentValueProps {
  comment: CommentProps;
  valueKey: keyof CommentProps | "user" | "building" | "address";
}

const StyledCommentValue: React.FC<StyledCommentValueProps> = ({
  comment,
  valueKey,
}) => {
  return (
    <>
      {(() => {
        switch (valueKey) {
          case "user":
            return (
              <div className="flex space-x-2 items-center">
                <Avatar
                  alt=""
                  size="default"
                  src={comment?.photo || ""}
                  name={
                    [comment?.first_name, comment?.last_name].join(" ") || ""
                  }
                />

                <div className="truncate cursor-pointer">
                  <p className="font-bold text-jll-color-text-base-default text-ellipsis overflow-hidden">
                    {[comment?.first_name, comment?.last_name].join(" ") || ""}
                  </p>
                  <p>
                    {[comment?.building_city, comment?.building_state].join(
                      ", "
                    )}
                  </p>
                </div>
              </div>
            );
          case "building":
            return (
              <Link
                className="flex space-x-2"
                to={`/property/${comment?.building_id}/admin`}
              >
                <LazyLoadImage
                  className="w-10 h-10 rounded"
                  src={convertImageUrl(
                    convertExtension(comment?.building_images?.[0] || "")
                  )}
                  onError={lazyLoadImageOnError}
                />
                <div className="flex flex-col truncate">
                  {comment?.building_title && (
                    <p className="font-bold text-jll-color-text-base-default text-ellipsis overflow-hidden">
                      {comment?.building_title}
                    </p>
                  )}
                  <p>ID {comment?.pk}</p>
                </div>
              </Link>
            );
          case "address":
            return (
              <p className="text-jll-color-coldGray-9 whitespace-normal">
                {[
                  comment?.building_address,
                  [comment?.building_state, comment?.building_post_code].join(
                    " "
                  ),
                ].join(", ")}
              </p>
            );
          case "update_timestamp":
            return <>{getFormatedDateTime(comment?.update_timestamp)}</>;
          case "comment":
            return (
              <p className="whitespace-normal line-clamp-2">
                {comment?.comment}
              </p>
            );
          default:
            if (comment?.hasOwnProperty(valueKey)) {
              return <>{comment[valueKey]}</>;
            }
            return <>-</>;
        }
      })()}
    </>
  );
};

export default StyledCommentValue;
