import { useEffect, useContext } from "react";
import { Loading } from "ui-atoms";
import { Navbar } from "ui-molecules";
import { FiLink } from "react-icons/fi";
import { LuBuilding2 } from "react-icons/lu";
import Sidebar from "../LayoutEditSidebar";
import Section from "../LayoutEditSection";
import { useApiCall } from "hooks";
import { SET_TEAM_LISTING, URLS } from "constant";
import { GlobalContext } from "context";
import { useParams } from "react-router-dom";
import { getTeamListingAPI } from "services";
import LayoutBase from "../LayoutBase";
import cn from "classnames";

interface LayoutEditTeamListingProps {
  children: any;
  className?: string;
}

function LayoutEditTeamListing<T extends object>({
  children,
  className,
}: LayoutEditTeamListingProps) {
  const { dispatch, state } = useContext(GlobalContext);
  const [getTeamListing, isLoading] = useApiCall(getTeamListingAPI);
  const { teamId } = useParams();
  const { property } = state;
  const backToUrl = `/${URLS.TEAM_LISTINGS}`;
  const menuItems = [
    {
      label: "Properties",
      icon: LuBuilding2,
      count: true,
      id: "listings",
      suffixUrl: URLS.TEAM_LISTING.PROPERTIES,
    },
    {
      label: "Pages",
      icon: FiLink,
      //   count: true,
      //   id: "suites",
      suffixUrl: `${URLS.TEAM_LISTING.PAGES}?active_status[]=1`,
      tag: URLS.TEAM_LISTING.PAGES
    },
  ];

  useEffect(() => {
    if (!teamId) {
      dispatch({
        type: SET_TEAM_LISTING,
        payload: null,
      });
      return;
    }
    getTeamListing({ pk: teamId }).then((data: any) => {
      dispatch({
        type: SET_TEAM_LISTING,
        payload: data,
      });
    });
  }, [teamId]);

  if (isLoading && !property) {
    return (
      <div className="h-[200px]">
        <Loading />
      </div>
    );
  }

  return (
    <LayoutBase>
      <Navbar />
      <Sidebar.TeamListing backToUrl={backToUrl} menuItems={menuItems} />
      <div
        className={cn(
          "fixed top-[64px] left-[360px] bottom-0 right-0 overflow-x-auto",
          className
        )}
      >
        {isLoading && !!property && <Loading />}
        {children}
      </div>
    </LayoutBase>
  );
}

LayoutEditTeamListing.Section = Section;

export default LayoutEditTeamListing;
