import { SET_LOADING_MODAL } from "constant";
import { GlobalContext } from "context";
import { useFormik } from "formik";
import { useApiCall } from "hooks";
import { useContext, useEffect } from "react";
import { FiX } from "react-icons/fi";
import { useParams } from "react-router-dom";
import { patchOpexAPI, postOpexAPI } from "services";
import { Button, Input, Label, SelectAutoComplete, Toast } from "ui-atoms";
import { Modal } from "ui-molecules";
import { getMetaOptions, getUTCDate } from "utils";
import * as Yup from "yup";

interface IOpexModal {
  isOpen: boolean;
  setIsOpen: any;
  opexData?: any;
  handleOpex?: any;
}

const validationSchema = Yup.object().shape({
  operating_expenses: Yup.number().required("This field is required"),
  year: Yup.number().required("This field is required"),
  utility_type: Yup.number()
    .optional()
    .nullable()
    .transform((_, val) => (val === Number(val) ? val : null)),
  utility_rate: Yup.number()
    .optional()
    .nullable()
    .transform((_, val) => (val === Number(val) ? val : null)),
});

const OpexModal: React.FC<IOpexModal> = ({
  isOpen,
  setIsOpen,
  opexData,
  handleOpex,
}) => {
  const { state, dispatch } = useContext(GlobalContext);
  const { meta } = state;
  const { propertyId } = useParams();
  const [postOpex] = useApiCall(postOpexAPI);
  const [patchOpex] = useApiCall(patchOpexAPI);

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    values,
    errors,
    touched,
    setValues,
    isValid,
    dirty,
  } = useFormik({
    initialValues: {
      operating_expenses: "",
      year: Number(getUTCDate().getFullYear()),
      utility_type: undefined,
      utility_rate: undefined,
    },
    validationSchema,
    onSubmit: async () => {
      try {
        dispatch({
          type: SET_LOADING_MODAL,
          payload: {
            open: true,
            label: `Adding a new OPEX`,
          },
        });
        const payload = {
          ...values,
          building: propertyId,
        };
        if (!opexData) {
          const res = await postOpex(payload);
          if (!!res) {
            handleOpex(res);
          }
        } else {
          const editPayload = {
            id: opexData?.pk || opexData?.id,
            payload,
          };
          const res = await patchOpex(editPayload);
          if (!!res) {
            handleOpex(res, res?.pk || res?.id);
          }
        }
        setIsOpen(false);
        setTimeout(() => {
          dispatch({
            type: SET_LOADING_MODAL,
            payload: {
              open: false,
              label: null,
            },
          });
        }, 100);
      } catch (err: any) {
        if (err?.message) Toast.error(err?.message);
        setIsOpen(false);
        setTimeout(() => {
          dispatch({
            type: SET_LOADING_MODAL,
            payload: {
              open: false,
              label: null,
            },
          });
        }, 100);
      }
    },
  });

  useEffect(() => {
    if (opexData) {
      setValues({
        ...opexData,
      });
    } else {
      setValues({
        operating_expenses: "",
        year: Number(getUTCDate().getFullYear()),
        utility_type: undefined,
        utility_rate: undefined,
      });
    }
  }, [opexData]);

  useEffect(() => {
    if (!values?.utility_type) {
      setFieldValue("utility_rate", undefined);
    }
  }, [values?.utility_type]);

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen} size="default">
      <Modal.Header className="relative">
        <Button
          variant="neutral"
          leadingIcon={FiX}
          className="absolute top-0 right-0 w-5 !h-5"
          leadingIconClass="w-5 h-5 text-jll-color-text-base-subdued"
          onClick={() => setIsOpen(false)}
        />
        <h5 className="text-xl font-semibold text-jll-color-text-base-default">
          {!!opexData ? "Edit OPEX" : "Add OPEX"}
        </h5>
      </Modal.Header>
      <form className="mt-8" onSubmit={handleSubmit}>
        <Modal.Body>
          <div className="flex flex-row items-center space-x-2 py-1">
            <Label className="w-5/12 mb-6">OPEX</Label>
            <Input
              className="w-full"
              name="operating_expenses"
              type="number"
              currency="USD"
              placeholder="Enter cost..."
              onChange={handleChange}
              onBlur={handleBlur}
              value={values?.operating_expenses}
              error={
                touched.operating_expenses ? errors.operating_expenses : ""
              }
            />
          </div>

          <div className="flex flex-row items-center space-x-2">
            <Label className="w-5/12 mb-6">Year</Label>
            <SelectAutoComplete
              name="year"
              className="w-full"
              onChange={(option) => {
                if (!option) setFieldValue("year", null);
                else setFieldValue("year", Number(option?.value));
              }}
              onBlur={handleBlur}
              value={{
                value: values?.year,
                label: values?.year.toString(),
              }}
              error={touched.year ? errors.year : ""}
              options={[...getMetaOptions(meta?.opex?.year)]}
            />
          </div>

          <div className="flex flex-row items-center space-x-2">
            <Label className="w-5/12 mb-6">Component Type</Label>
            <SelectAutoComplete
              name="utility_type"
              className="w-full"
              onChange={(option) => {
                if (!option) setFieldValue("utility_type", null);
                else setFieldValue("utility_type", Number(option?.value));
              }}
              onBlur={handleBlur}
              value={{
                value: values?.utility_type,
                label:
                  (values?.utility_type &&
                    meta?.opex?.utility_type?.[values?.utility_type]?.value) ||
                  "",
              }}
              error={touched.utility_type ? errors.utility_type : ""}
              options={[...getMetaOptions(meta?.opex?.utility_type)]}
            />
          </div>

          <div className="flex flex-row items-center space-x-2">
            <Label className="w-5/12 mb-6">Component Fee</Label>
            <Input
              className="w-full"
              name="utility_rate"
              type="number"
              currency="USD"
              placeholder="Enter cost..."
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={!values?.utility_type}
              value={values?.utility_rate}
              error={touched.utility_rate ? errors.utility_rate : ""}
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="space-x-2">
          <Button
            variant="primary"
            type="submit"
            className="w-full justify-center"
            disabled={!isValid || !dirty}
          >
            {!!opexData ? "Update OPEX" : "Add OPEX"}
          </Button>
          <Button
            variant="secondary"
            className="w-full justify-center"
            onClick={() => setIsOpen(false)}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default OpexModal;
