export const createResizableColumn = function (col: any, resizer: any, updateSizeColumn: any) {
  // Track the current position of mouse
  let x = 0;
  let w = 0;
  let minWidth = 0;

  const mouseDownHandler = function (e: any) {
    // Get the current mouse position
    x = e.clientX;

    // Calculate the current width of column
    const styles = window.getComputedStyle(col);
    w = parseInt(styles.width, 10);

    // Attach listeners for document's events
    document.addEventListener("mousemove", mouseMoveHandler);
    document.addEventListener("mouseup", mouseUpHandler);
  };

  const mouseMoveHandler = function (e: any) {
    // Determine how far the mouse has been moved
    const dx = e.clientX - x;
    minWidth = w + dx
    // Update the width of column
    col.style.minWidth = `${minWidth}px`;
  };

  // When user releases the mouse, remove the existing event listeners
  const mouseUpHandler = function () {
    updateSizeColumn(minWidth);
    document.removeEventListener("mousemove", mouseMoveHandler);
    document.removeEventListener("mouseup", mouseUpHandler);
  };

  resizer.addEventListener("mousedown", mouseDownHandler);
  return minWidth
};
