import type { FC } from "react";
import Label from "../Label";
import cn from "classnames";
import Chip from "ui-atoms/Chip";

interface ITextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  error?: any;
  className?: string;
  optional?: boolean;
}

const Textarea: FC<ITextareaProps> = ({
  label = "",
  rows = 4,
  error,
  className,
  optional,
  ...props
}) => {
  return (
    <div className={cn("relative mb-6", className)}>
      {(label || optional) && (
        <div className="flex flex-row items-center">
          {label ? <Label htmlFor={props.id}>{label}</Label> : <span></span>}
          {optional && (
            <Chip
              variant="secondary"
              size="small"
              className="py-1 text-jll-color-coldGray-7"
            >
              Optional
            </Chip>
          )}
        </div>
      )}
      <div className={cn({ "mt-1": !!label })}>
        <textarea
          rows={rows}
          className={cn(
            "block w-full rounded shadow-sm focus:border-jll-color-coldGray-7 focus:ring-jll-color-coldGray-7 sm:text-sm px-3 py-2",
            {
              "!border-jll-color-surface-accent-default": !!error,
              "border-jll-color-coldGray-4": !error,
            }
          )}
          {...props}
        />
        {error && (
          <p className="absolute -bottom-5 text-xs text-jll-color-surface-accent-default">
            {error}
          </p>
        )}
      </div>
    </div>
  );
};

export default Textarea;
