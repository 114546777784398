import { default as ReactSelect, StylesConfig, components } from "react-select";
import CreatableSelect from "react-select/creatable";
import AsyncSelect from "react-select/async";
import AsyncCreatableSelect from "react-select/async-creatable";
import cn from "classnames";
import Chip from "ui-atoms/Chip";
import Label from "ui-atoms/Label";
import { OptionProps } from "types";
import { Fragment, useEffect, useRef, useState } from "react";

// TODO better typing from react-select
interface SelectAutoCompleteProps {
  options?: OptionProps[];
  isMulti?: boolean;
  label?: string;
  labelClassName?: string;
  error?: any;
  optional?: boolean;
  placeholder?: string;
  className?: string;
  name: string;
  value?: OptionProps[] | OptionProps | string | number;
  onChange?: (e: any) => void;
  onBlur?: (e: any) => void;
  isDisabled?: boolean;
  loadOptions?: any;
  cacheOptions?: any;
  defaultOptions?: any;
  isClearable?: boolean;
  isCreatable?: boolean;
  isCustomCreatable?: boolean;
  isMenuPortalTarget?: boolean;
}

const colourStyles: any = {
  control: (base: any) => ({
    ...base,
    "&:hover": { borderColor: "gray" }, // border style on hover
    border: "1px solid lightgray", // default border color
    boxShadow: "none", // no box-shadow
  }),
  menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
};

const SelectAutoComplete: React.FC<SelectAutoCompleteProps> = ({
  label,
  error,
  optional,
  options,
  cacheOptions,
  defaultOptions,
  className,
  name,
  isCreatable,
  isCustomCreatable = false,
  onBlur,
  labelClassName,
  isMenuPortalTarget = true,
  loadOptions,
  ...props
}) => {
  const createRef = useRef(null);
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const openMenu = () => {
    if (!createRef?.current) return;
    // @ts-ignore
    createRef?.current?.focus();
    setMenuIsOpen(true);
  };

  const onInputChange = (options: any, { action }: any) => {
    if (action === "menu-close") {
      setMenuIsOpen(false);
    }
  };

  const Menu = (props: any) => {
    return (
      <Fragment>
        <components.Menu {...props}>
          <div className="overflow-y-auto">
            {!!props?.selectProps?.value?.value && !!props.children && (
              <div
                className="block w-full text-jll-color-text-base-default hover:bg-jll-color-surface-info-subdued py-2 px-3 cursor-pointer"
                onClick={() => {
                  props.setValue({ label: "", value: "" }, "set-value");
                  setTimeout(() => {
                    // @ts-ignore
                    createRef?.current?.focus();
                  }, 50);
                }}
              >
                Type Custom Text
              </div>
            )}
            <div>{props.children}</div>
          </div>
        </components.Menu>
      </Fragment>
    );
  };

  const Control = ({ children, ...props }: any) => {
    return (
      <div onClick={() => setMenuIsOpen(!menuIsOpen)}>
        <components.Control {...props}>{children}</components.Control>
      </div>
    );
  };

  useEffect(() => {
    const handleError = (e: any) => {
      if (e.message === "ResizeObserver loop limit exceeded") {
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div"
        );
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute("style", "display: none");
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute("style", "display: none");
        }
      }
    };
    window.addEventListener("error", handleError);
    return () => window.removeEventListener("error", handleError);
  }, []);

  return (
    <div className={cn("relative mb-6", className)}>
      {(label || optional) && (
        <div className="flex flex-row items-center">
          {label ? (
            <Label className={cn("mr-2", labelClassName)}>{label}</Label>
          ) : (
            <span></span>
          )}
          {optional && (
            <Chip
              variant="secondary"
              size="small"
              className="py-1 text-jll-color-coldGray-7"
            >
              Optional
            </Chip>
          )}
        </div>
      )}
      <div className={cn({ "mt-2": !!label })}>
        {isCustomCreatable && isCreatable && (
          <CreatableSelect
            options={options}
            className={cn({
              error: !!error,
            })}
            isClearable
            inputId={name}
            styles={colourStyles}
            onBlur={onBlur}
            backspaceRemovesValue={true}
            components={{ Menu, Control }}
            ref={createRef}
            onFocus={openMenu}
            onInputChange={onInputChange}
            menuIsOpen={menuIsOpen}
            menuPortalTarget={isMenuPortalTarget ? document.body : undefined}
            {...props}
          />
        )}
        {!isCustomCreatable && isCreatable && !loadOptions && (
          <CreatableSelect
            options={options}
            className={cn({
              error: !!error,
            })}
            isClearable
            inputId={name}
            styles={colourStyles}
            onBlur={onBlur}
            backspaceRemovesValue={true}
            menuPortalTarget={isMenuPortalTarget ? document.body : undefined}
            {...props}
          />
        )}
        {!isCustomCreatable && isCreatable && !!loadOptions && (
          <AsyncCreatableSelect
            cacheOptions
            defaultOptions
            loadOptions={loadOptions}
            className={cn({
              error: !!error,
            })}
            isClearable
            inputId={name}
            styles={colourStyles}
            onBlur={onBlur}
            backspaceRemovesValue={true}
            menuPortalTarget={isMenuPortalTarget ? document.body : undefined}
            {...props}
          />
        )}
        {!isCreatable && options && (
          <ReactSelect
            options={options}
            className={cn({
              error: !!error,
            })}
            isClearable
            inputId={name}
            styles={colourStyles}
            onBlur={onBlur}
            backspaceRemovesValue={true}
            menuPortalTarget={isMenuPortalTarget ? document.body : undefined}
            {...props}
          />
        )}
        {!isCreatable && !options && (
          <AsyncSelect
            cacheOptions
            defaultOptions
            className={cn({
              error: !!error,
            })}
            isClearable
            styles={colourStyles}
            backspaceRemovesValue={true}
            menuPortalTarget={isMenuPortalTarget ? document.body : undefined}
            loadOptions={loadOptions}
            {...props}
          />
        )}
      </div>
      {error && (
        <p
          className="absolute -bottom-5 text-xs text-jll-color-surface-accent-default whitespace-nowrap truncate"
          title={error}
        >
          {error}
        </p>
      )}
    </div>
  );
};

export default SelectAutoComplete;
