import { GlobalContext } from "context";
import { useContext } from "react";
import { OpexProps } from "types";
import {
  getDecimalFormating,
  getFormatedDateTime,
} from "utils";

export interface StyledOpexValueProps {
  opex: OpexProps;
  valueKey: keyof OpexProps | "log_date";
}

const StyledOpexValue: React.FC<StyledOpexValueProps> = ({
  opex,
  valueKey,
}) => {
  const { state } = useContext(GlobalContext);
  const { meta } = state;

  return (
    <>
      {(() => {
        switch (valueKey) {
          case "operating_expenses":
          case "utility_rate":
            if (!opex?.[valueKey]) {
              return <>-</>;
            }
            return <>${getDecimalFormating(opex?.[valueKey])} </>;
          case "utility_type":
            if (!opex?.[valueKey]) {
              return <>-</>;
            }
            return <>{meta?.opex?.utility_type?.[opex?.utility_type]?.value}</>;
          case "log_date":
            return (
              <div className="flex flex-col space-y-0.5">
                <p>
                  {getFormatedDateTime(opex?.update_timestamp, {
                    dateStyle: "long",
                  }) || "-"}
                </p>
                <p className="text-xs text-jll-color-text-base-default">
                  {opex?.updated_user_name || "-"}
                </p>
              </div>
            );
          default:
            if (opex?.hasOwnProperty(valueKey)) {
              return <>{opex[valueKey]}</>;
            }
            return <>-</>;
        }
      })()}
    </>
  );
};

export default StyledOpexValue;
