import { useEffect, useState } from "react";

declare global {
  interface Window {
    pendo: any;
  }
}

interface IPendo {
  sessionInfo: any;
}

const Pendo: React.FC<IPendo> = ({ sessionInfo }) => {
  const [isPendoInitialized, setIsPendoInitialized] = useState(false);
  const [isPendoAgentReady, setIsPendoAgentReady] = useState(false);

  useEffect(() => {
    if (!sessionInfo) return;
    (function (apiKey: string) {
      if (isPendoInitialized) {
        let shouldIdentify = true;

        if (isPendoAgentReady) {
          const currentVisitorId = window.pendo.getVisitorId();

          if (currentVisitorId === sessionInfo?.email) {
            shouldIdentify = false;
          }
        }
        if (shouldIdentify) {
          let pendoVisitor = {
            id: sessionInfo?.email,
            role: sessionInfo?.roles,
            email: sessionInfo?.email,
            full_name: sessionInfo?.get_full_name,
          };
          window.pendo.identify({ visitor: pendoVisitor, account: {} });
        }
      } else {
        (function (p, e, n, d, o) {
          let v: any, w, x, y, z;
          // @ts-ignore
          o = p[d] = p[d] || {};
          // @ts-ignore
          o._q = o._q || [];
          v = ["initialize", "identify", "updateOptions", "pageLoad", "track"];
          for (w = 0, x = v.length; w < x; ++w)
            (function (m) {
              // @ts-ignore
              o[m] =
                o?.[m] ||
                function () {
                  // @ts-ignore
                  o._q[m === v[0] ? "unshift" : "push"](
                    [m].concat([].slice.call(arguments, 0))
                  );
                };
            })(v[w]);
          y = e.createElement(n);
          // @ts-ignore
          y.async = !0;
          // @ts-ignore
          y.src = "https://cdn.pendo.io/agent/static/" + apiKey + "/pendo.js";
          z = e.getElementsByTagName(n)[0];
          // @ts-ignore
          z.parentNode.insertBefore(y, z);
        })(window, document, "script", "pendo");
        let pendoVisitor = {
          id: sessionInfo?.email,
          role: sessionInfo?.roles,
          email: sessionInfo?.email,
          full_name: sessionInfo?.get_full_name,
        };
        // @ts-ignore
        window?.pendo.initialize({
          visitor: pendoVisitor,
          account: {},
          events: {
            ready: function () {
              setIsPendoAgentReady(true);
            },
          },
        });
        setIsPendoInitialized(true);
      }
    })(process.env.REACT_APP_PENDO_KEY || "");
  }, [sessionInfo, isPendoInitialized, isPendoAgentReady]);

  return <></>;
};

export default Pendo;
