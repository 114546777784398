import {
  InitialValuesProps,
  META_TAGS,
  OLM_PROPERTY_FIELDS,
  PROPERTY_TABS,
  SET_CONFIRM_MODAL,
  SET_LOADING_MODAL,
  SET_PROPERTY,
  STATUS_ACTIVE,
  URLS,
} from "constant";
import { GlobalContext } from "context";
import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { LayoutEditProperty as Layout } from "ui-organisms";
import PropertyDescriptions from "./PropertyDescriptions";
import {
  AccordionItem,
  Highlight,
  OLMFilterItem,
  RemoveDescriptionItem,
  WarningModal,
} from "ui-molecules";
import PropertyAmenities from "./PropertyAmenities";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getApiPayloadFromForm, isCommonItem } from "utils";
import { patchPropertyAPI } from "services";
import { useApiCall, useCallbackPrompt } from "hooks";
import { Button, Toast } from "ui-atoms";
import { Link, useParams } from "react-router-dom";
import { FiChevronRight } from "react-icons/fi";
import { AmenityProps } from "types";
import React from "react";
import { renderToString } from "react-dom/server";

interface IPropertyAboutV2 {
  onDiffFields?: any;
  diffFields?: any;
  isOLM?: boolean;
}

const validationSchema = Yup.object().shape({
  highlights: Yup.array()
    .of(Yup.string().required("This field is required"))
    .nullable(),
  amenities: Yup.array()
    .of(
      Yup.object({
        amenity_name: Yup.string().test(
          "amenity-name-validation",
          "This field must not be empty",
          // @ts-ignore
          (value: number | undefined, context: any) => {
            return context.parent.amenity_name || context.parent.custom_amenity;
          }
        ),
      })
    )
    .nullable(),
});

const PropertyAboutV2: React.FC<IPropertyAboutV2> = ({
  onDiffFields,
  diffFields,
  isOLM,
}) => {
  const { dispatch, state } = useContext(GlobalContext);
  const { property, showTooltip, hideTooltip } = state;
  const [patchProperty] = useApiCall(patchPropertyAPI);
  const { propertyId } = useParams();
  const [isWarningModal, setIsWarningModal] = useState(false);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(showDialog);

  const {
    handleSubmit,
    handleBlur,
    setFieldValue,
    setValues,
    values,
    errors,
    touched,
  } = useFormik({
    initialValues: {
      highlights: [],
      amenities: [],
    },
    validationSchema,
    onSubmit: async () => {
      try {
        dispatch({
          type: SET_LOADING_MODAL,
          payload: {
            open: true,
            label: "Updating Property",
          },
        });
        let payload: InitialValuesProps | undefined = getApiPayloadFromForm(
          property,
          values
        );
        if (payload && Object.keys(payload)?.length) {
          if (payload.hasOwnProperty("amenities")) {
            const amenity = [...payload.amenities].filter(
              (x: AmenityProps) => typeof x === "number"
            );
            if (amenity?.length) {
              payload["amenity"] = amenity;
            } else {
              payload["amenity"] = [];
            }
            const custom_amenity = [...payload.amenities].filter(
              (x: AmenityProps) => typeof x === "string"
            );
            if (custom_amenity?.length) {
              payload["custom_amenity"] = custom_amenity;
            } else {
              payload["custom_amenity"] = [];
            }
            delete payload.amenities;
          }
          if (
            payload &&
            property?.publish_status === STATUS_ACTIVE &&
            isCommonItem(Object.keys(payload), OLM_PROPERTY_FIELDS)
          ) {
            payload["publish_status"] = STATUS_ACTIVE;
          }
          const response = await patchProperty({
            id: property.id,
            payload,
          });
          setTimeout(() => {
            dispatch({
              type: SET_LOADING_MODAL,
              payload: {
                open: false,
                label: null,
              },
            });
          }, 100);
          if (!response) return;
          Toast.success("Changes saved with success");
          dispatch({
            type: SET_PROPERTY,
            payload: response,
          });
        } else {
          Toast.warn("No changes to be saved");
          setTimeout(() => {
            dispatch({
              type: SET_LOADING_MODAL,
              payload: {
                open: false,
                label: null,
              },
            });
          }, 100);
        }
      } catch (err) {
        Toast.warn("No changes to be saved");
        setTimeout(() => {
          dispatch({
            type: SET_LOADING_MODAL,
            payload: {
              open: false,
              label: null,
            },
          });
        }, 100);
      }
    },
  });

  useEffect(() => {
    if (!property || !values) return;
    const diff = getApiPayloadFromForm(property, values);
    onDiffFields(diff);
  }, [property, values]);

  useEffect(() => {
    if (!property || property?.id?.toString() !== propertyId?.toString())
      return;

    setValues({
      highlights: property?.highlights,
      amenities: property?.amenities,
    });
  }, [property, propertyId]);

  useEffect(() => {
    if (!diffFields || !Object.keys(diffFields)?.length) setShowDialog(false);
    else setShowDialog(true);
  }, [diffFields]);

  useEffect(() => {
    if (!showPrompt) return;
    const action = async () => {
      try {
        if (errors && !!Object.keys(errors)?.length) {
          setIsWarningModal(true);
          // @ts-ignore
          cancelNavigation();
          return;
        }
        await handleSubmit();
        // @ts-ignore
        confirmNavigation();
      } catch (err) {
        // @ts-ignore
        cancelNavigation();
      }
    };
    let isPublish = false;
    if (
      diffFields &&
      property?.publish_status === STATUS_ACTIVE &&
      isCommonItem(Object.keys(diffFields), OLM_PROPERTY_FIELDS)
    ) {
      isPublish = true;
    }

    dispatch({
      type: SET_CONFIRM_MODAL,
      payload: {
        open: true,
        content: {
          description: renderToString(
            <RemoveDescriptionItem description="You have un-saved changes, would you like to save changes before proceeding" />
          ),
          btnTitle: `${isPublish ? "Save and Publish" : "Save"}`,
          cancelTitle: "Proceed without Saving",
          action: action,
          cancel: confirmNavigation,
          additionalCancel: cancelNavigation,
        },
      },
    });
  }, [showPrompt]);

  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>{`${META_TAGS?.default?.title} ${
          property?.title || property?.address
        } About`}</title>
        <meta
          property="og:title"
          content={`${META_TAGS?.default?.title} ${
            property?.title || property?.address
          } About`}
        />
      </Helmet>
      <form onSubmit={handleSubmit}>
        <OLMFilterItem
          fieldKey="descriptions"
          olmFields={OLM_PROPERTY_FIELDS}
          isOLM={isOLM}
        >
          <Layout.Section>
            <AccordionItem id="descriptions" label="Descriptions">
              <div className="flex flex-col w-full pt-4">
                <PropertyDescriptions />
              </div>
            </AccordionItem>
          </Layout.Section>
        </OLMFilterItem>

        <OLMFilterItem
          fieldKey="highlights"
          olmFields={OLM_PROPERTY_FIELDS}
          isOLM={isOLM}
        >
          <Layout.Section>
            <AccordionItem id="highlights" label="Highlights">
              <div className="flex flex-col w-full pt-8">
                <Highlight
                  label="Highlights"
                  name="highlights"
                  touched={touched}
                  errors={errors}
                  onChange={(value: any) => {
                    setFieldValue("highlights", value);
                  }}
                  onBlur={(e: any) => {
                    handleBlur(e);
                  }}
                  value={values?.highlights}
                />
              </div>
            </AccordionItem>
          </Layout.Section>
        </OLMFilterItem>

        <OLMFilterItem
          fieldKey="amenities"
          olmFields={OLM_PROPERTY_FIELDS}
          isOLM={isOLM}
        >
          <Layout.Section>
            <AccordionItem id="amenities" label="Amenities">
              <div className="flex flex-col w-full pt-4">
                <PropertyAmenities
                  label="Amenities"
                  name="amenities"
                  touched={touched}
                  errors={errors}
                  onChange={(value) => {
                    setFieldValue("amenities", value);
                  }}
                  onBlur={(e) => {
                    handleBlur(e);
                  }}
                  value={values?.amenities}
                />
              </div>
            </AccordionItem>
          </Layout.Section>
        </OLMFilterItem>

        <div className="px-8 fixed bottom-0 left-[360px] right-0 flex justify-end bg-white shadow-detail-status-nav h-[80px] items-center">
          <div className="flex space-x-4">
            <Link
              to={`/property/${propertyId}/${URLS.PROPERTY.FORM}?tab=${PROPERTY_TABS.CONTACTS}`}
            >
              <Button variant="secondary" trailingIcon={FiChevronRight}>
                Next
              </Button>
            </Link>
            <Button
              disabled={!diffFields || !Object.keys(diffFields)?.length}
              variant="primary"
              type="submit"
              id="save-tooltip"
              onMouseOver={() => showTooltip("save-tooltip")}
              onMouseLeave={() => hideTooltip()}
              data-tooltip-content={
                property?.publish_status === STATUS_ACTIVE
                  ? "This saves the property in MyListings and publishes to OLM"
                  : "This saves the property, but does not publish to OLM"
              }
            >
              {property?.publish_status === STATUS_ACTIVE
                ? "Save and Publish"
                : "Save"}
            </Button>
          </div>
        </div>
      </form>

      <WarningModal
        isOpen={isWarningModal}
        setIsOpen={setIsWarningModal}
        errors={errors}
      />
    </>
  );
};

export default React.memo(PropertyAboutV2);
