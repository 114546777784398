interface IListTooltip {
  list: string[];
}

const ListTooltip: React.FC<IListTooltip> = ({ list }) => {
  return (
    <div className="shadow-drop rounded-lg max-h-[50vh]">
      {!!list?.length && (
        <ul className="space-y-2">
          {list?.map((item, idx) => (
            <li
              key={idx}
              className="text-white list-inside list-disc marker:text-white"
            >
              {item}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default ListTooltip;
