import {
  SET_INLINE_EDIT_HIGHLIGHT_MODAL,
} from "constant";
import { GlobalContext } from "context";
import { useFormik } from "formik";
import { useApiCall } from "hooks";
import { useContext, useEffect, useRef } from "react";
import { FiPlus, FiX } from "react-icons/fi";
import { patchAvailabilityAPI, patchPropertyAPI } from "services";
import { Button } from "ui-atoms";
import { Highlight, Modal } from "ui-molecules";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  highlights: Yup.array()
    .of(Yup.string().required("This field is required"))
    .nullable(),
});

const InlineHighlightModal = () => {
  const highlightRef = useRef<any>(null);
  const { state, dispatch } = useContext(GlobalContext);
  const { inlineHighlightModalOpen, inlineHighlightModalContent } = state;
  const [patchProperty] = useApiCall(patchPropertyAPI);
  const [patchAvailability] = useApiCall(patchAvailabilityAPI);

  const {
    handleSubmit,
    handleBlur,
    setFieldValue,
    values,
    errors,
    touched,
    isValid,
    setValues,
  }: any = useFormik({
    initialValues: {
      highlights: [],
    },
    validationSchema,
    onSubmit: () => {
      let service = null;
      if (inlineHighlightModalContent?.kind === "property")
        service = patchProperty;
      else if (inlineHighlightModalContent?.kind === "availability")
        service = patchAvailability;
      service({
        id: inlineHighlightModalContent?.pk,
        payload: values,
      }).then((res: any) => {
        inlineHighlightModalContent?.updateItem(
          values,
          inlineHighlightModalContent?.pk
        );
      });
      handleClose();
    },
  });

  useEffect(() => {
    setValues({ highlights: inlineHighlightModalContent?.values || [] });
  }, [inlineHighlightModalContent]);

  const handleClose = () => {
    dispatch({
      type: SET_INLINE_EDIT_HIGHLIGHT_MODAL,
      payload: {
        open: false,
        content: null,
      },
    });
  };

  return (
    <Modal
      isOpen={inlineHighlightModalOpen}
      setIsOpen={handleClose}
      size="default"
    >
      <Modal.Header className="relative">
        <Button
          variant="neutral"
          leadingIcon={FiX}
          className="absolute top-0 right-0 w-5 !h-5"
          leadingIconClass="w-5 h-5 text-jll-color-text-base-subdued"
          onClick={handleClose}
        />
        <h5 className="text-xl font-semibold text-jll-color-text-base-default">
          Highlights
          <span className="text-sm text-jll-color-text-base-subdued font-normal ml-[10px]">
            {values?.highlights?.length || 0} Highlights
          </span>
        </h5>
      </Modal.Header>
      <Modal.Body className="mt-8">
        <Highlight
          label="Highlights"
          name="highlights"
          touched={touched}
          errors={errors}
          onChange={(value: any) => {
            setFieldValue("highlights", value);
          }}
          onBlur={(e: any) => {
            handleBlur(e);
          }}
          value={values?.highlights}
          ref={highlightRef}
          isModal
        />
      </Modal.Body>
      <Modal.Footer>
        <div className="flex flex-row space-x-4 w-full">
          <Button
            variant="secondary"
            leadingIcon={FiPlus}
            onClick={() => highlightRef?.current?.handleAdd()}
            className="w-full justify-center"
          >
            Add Highlight
          </Button>
          <Button
            variant="primary"
            onClick={handleSubmit}
            className="w-full justify-center"
            disabled={!isValid}
          >
            Done
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default InlineHighlightModal;
