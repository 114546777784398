import { InitialValuesProps, SET_LOADING_MODAL } from "constant";
import { GlobalContext } from "context";
import { useFormik } from "formik";
import { useApiCall } from "hooks";
import {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { FiPlusCircle } from "react-icons/fi";
import { getMarketsAPI, postTeamListingAPI, putTeamListingAPI } from "services";
import { Button, Input, SelectAutoComplete } from "ui-atoms";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("This field is required"),
  market: Yup.string().nullable().optional(),
  url_title: Yup.string().required("This field is required"),
});

const initialValues = {
  name: "",
  market: null,
  url_title: "",
};

interface ITeamListingForm {
  selectedListing?: any;
  isOpen?: boolean;
  setIsOpen?: any;
  isAdd?: boolean;
  setIsDisabled?: any;
}

const TeamListingForm = forwardRef(
  (
    {
      selectedListing,
      isOpen,
      setIsOpen,
      isAdd,
      setIsDisabled,
    }: ITeamListingForm,
    ref
  ) => {
    const { dispatch } = useContext(GlobalContext);
    const [postTeamListing] = useApiCall(postTeamListingAPI);
    const [putTeamListing] = useApiCall(putTeamListingAPI);
    const [getMarkets] = useApiCall(getMarketsAPI);
    const [selectedMarketOption, setSelectedMarketOption] =
      useState<any>(undefined);
    const resRef = useRef<any>(null);

    const {
      handleSubmit,
      submitForm,
      handleChange,
      handleBlur,
      setFieldValue,
      values,
      errors,
      touched,
      setValues,
      isValid,
      dirty,
    } = useFormik({
      initialValues: initialValues,
      validationSchema,
      onSubmit: async () => {
        try {
          dispatch({
            type: SET_LOADING_MODAL,
            payload: {
              open: true,
              label: "Updating Team Listing",
            },
          });
          let payload: InitialValuesProps | undefined = { ...values };
          if (payload && Object.keys(payload)?.length) {
            let res;
            if (selectedListing) {
              res = await putTeamListing(payload);
            } else {
              res = await postTeamListing(payload);
            }
            setTimeout(() => {
              dispatch({
                type: SET_LOADING_MODAL,
                payload: {
                  open: false,
                  label: null,
                },
              });
            }, 100);
            if (!res) return;
            if (selectedListing) {
              resRef.current = selectedListing?.pk;
            } else {
              resRef.current = res?.pk || res?.id;
            }
            setIsOpen(false);
          }
        } catch (err) {
          setTimeout(() => {
            dispatch({
              type: SET_LOADING_MODAL,
              payload: {
                open: false,
                label: null,
              },
            });
          }, 100);
        }
      },
    });

    useEffect(() => {
      if (!isOpen) return;
      if (selectedListing) {
        let formValues: any = {
          pk: selectedListing?.pk || selectedListing?.id,
        };
        Object.keys(initialValues).forEach((key: string) => {
          if (key === "market") {
            formValues = {
              ...formValues,
              [key]: selectedListing?.market_id || null,
            };
            return;
          }
          formValues = {
            ...formValues,
            [key]: selectedListing[key] || [],
          };
        });
        setValues(formValues);
        if (selectedListing?.market_id && selectedListing?.market)
          setSelectedMarketOption({
            value: selectedListing?.market_id,
            label: selectedListing?.market,
          });
      } else {
        setValues(initialValues);
        setSelectedMarketOption(undefined);
      }
    }, [selectedListing, isOpen]);

    useEffect(() => {
      if (!setIsDisabled) return;
      setIsDisabled(!isValid || !dirty);
    }, [isValid, dirty]);

    useImperativeHandle(ref, () => ({
      submitForm,
      getTeamId() {
        return resRef.current;
      },
    }));

    const loadMarketOptions = async (keyword: string) => {
      return getMarkets({ keyword: keyword }).then((res: any) => {
        return res.docs.map((market: any) => {
          return {
            value: market?.pk || market?.id,
            label: market?.name,
          };
        });
      });
    };

    return (
      <form onSubmit={handleSubmit}>
        <div className="flex flex-col space-y-4 mb-6">
          <div className="flex flex-row space-x-2">
            <div className="w-3/12 flex flex-row items-center">
              <span className="text-sm font-semibold text-jll-color-text-base-subdued">
                Name
              </span>
            </div>
            <div className="w-full p-1">
              <Input
                name="name"
                placeholder="Internal name to describe this group of listings"
                className="w-full !mb-0"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.name ? errors.name : ""}
              />
            </div>
          </div>

          <div className="flex flex-row space-x-2">
            <div className="w-3/12 flex flex-row items-center">
              <span className="text-sm font-semibold text-jll-color-text-base-subdued">
                Market
              </span>
            </div>
            <div className="w-full p-1">
              <SelectAutoComplete
                name="market"
                placeholder="Please Choose Market"
                className="w-full !mb-0"
                loadOptions={loadMarketOptions}
                onChange={(option) => {
                  if (!option) {
                    setFieldValue("market", null);
                    setSelectedMarketOption(undefined);
                  } else {
                    setFieldValue("market", option.value);
                    setSelectedMarketOption(option);
                  }
                }}
                onBlur={handleBlur}
                value={selectedMarketOption}
              />
            </div>
          </div>

          <div className="flex flex-row space-x-2">
            <div className="w-3/12 flex flex-row items-center">
              <span className="text-sm font-semibold text-jll-color-text-base-subdued">
                URL
              </span>
            </div>
            <div className="w-full p-1">
              <Input
                name="url_title"
                placeholder='Ex: "Austin Market Overview"'
                className="w-full !mb-0"
                value={values.url_title}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.url_title ? errors.url_title : ""}
              />
            </div>
          </div>
        </div>
        {!isAdd && (
          <Button
            type="submit"
            variant="primary"
            leadingIcon={FiPlusCircle}
            className="w-full justify-center"
            disabled={!isValid || !dirty}
          >
            {`${selectedListing ? "Update" : "Create"} Team Listing`}
          </Button>
        )}
      </form>
    );
  }
);

export default TeamListingForm;
