import React, { useEffect, useContext, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { LayoutEditProperty as Layout } from "ui-organisms";
import { renderToString } from "react-dom/server";
import { convertExtension, lazyLoadImageOnError, sliceIntoChunks } from "utils";
import { GlobalContext } from "context";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Chip, Toast } from "ui-atoms";
import cn from "classnames";
import {
  FiChevronLeft,
  FiChevronRight,
  FiDownload,
  FiExternalLink,
  FiLink,
} from "react-icons/fi";
import {
  AccordionItem,
  AvailabilityPreviewModal,
  GoogleMap,
  PreviewHighlight,
  PropertyPDF,
  ShowMoreItems,
  StyledAvailabilityValue,
  StyledPropertyValue,
  Table,
} from "ui-molecules";
import { StyledBrokerValue } from "ui-molecules";
import {
  AVAILABILITIES_TABLE_COLUMNS,
  EVENT_PUBLISHED,
  EVENT_REMOVED_INACTIVE_AVAILABILITY,
  EVENT_UNPUBLISHED_AVAILABILITY,
  EventProps,
  META_TAGS,
  PROPERTY_DETAILS,
  PROPERTY_TABS,
  PUBLISH_STATUS_PUBLISHED,
  PUBLISH_STATUS_REMOVED,
  SEARCH_RESULT_LIMIT,
  SET_EVENT_MODAL,
  SET_LOADING_MODAL,
  UPDATE_LIGHTGALLERY_DATA,
  URLS,
} from "constant";
import { AvailabilityProps, DescriptionProps } from "types";
import { useApiCall } from "hooks";
import {
  convertImageUrl,
  deleteAvailabilityAPI,
  getAvailabilitiesAPI,
  getPropertyMapAPI,
  patchAvailabilityAPI,
} from "services";
import { Helmet } from "react-helmet-async";
import ReactPDF, { PDFDownloadLink } from "@react-pdf/renderer";

const PropertyDetails = () => {
  const { propertyId } = useParams();
  const { state, dispatch } = useContext(GlobalContext);
  const { property, lightGalleryRef, meta } = state;
  const [getAvailabilities, isLoadingAvailabilities] =
    useApiCall(getAvailabilitiesAPI);
  const [getPropertyMap] = useApiCall(getPropertyMapAPI);
  const [patchAvailability, isLoadingPatch] = useApiCall(patchAvailabilityAPI);
  const [deleteAvailabilities] = useApiCall(deleteAvailabilityAPI);
  const [updatedAvailabilityTableColumns, setUpdatedAvailabilityTableColumns] =
    useState<any[]>([]);
  const [openedAvailability, setOpenedAvailability] = useState(-1);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [availabilities, setAvailabilities] = useState<AvailabilityProps[]>();
  const [pdfTemplate, setPdfTemplate] = useState<any>(null);
  const [propertyMap, setPropertyMap] = useState<any>(null);

  useEffect(() => {
    const newColumns = [...AVAILABILITIES_TABLE_COLUMNS].filter(
      (column) => column?.id !== "building"
    );
    setUpdatedAvailabilityTableColumns(newColumns);
  }, []);

  useEffect(() => {
    if (!propertyId) return;
    setPage(1);
    // Get the first set of Availabilities
    getAvailabilities({
      page: 1,
      limit: SEARCH_RESULT_LIMIT,
      buildings: [propertyId],
    }).then((data: any) => {
      setAvailabilities(data?.docs);
      setPage(data?.page);
      setTotal(data?.total);
    });

    getPropertyMap({
      id: propertyId,
      zoom: 13,
      width: 300,
      height: 300,
    }).then((res: any) => {
      if (!res?.image) return;
      setPropertyMap(res?.image);
    });
  }, [propertyId]);

  useEffect(() => {
    if (!property || !availabilities || !propertyMap) return;
    const layout: any = PropertyPDF({
      property,
      availabilities,
      propertyMap,
      meta,
    });
    setPdfTemplate(layout);
  }, [property, availabilities, propertyMap]);

  const onClickPreview = (e: any, id: any) => {
    e.stopPropagation();
    setOpenedAvailability(id);
    setIsOpen(true);
  };

  const onClickEdit =
    (buildingId: AvailabilityProps["building"], pk: any) => () => {
      navigate(
        `/property/${buildingId}/availability/${pk}/${URLS.AVAILABILITY.FORM}`
      );
    };

  const onClickRemove = (e: any, suite: AvailabilityProps) => {
    e.stopPropagation();
    const action = (event: EventProps) => {
      dispatch({
        type: SET_LOADING_MODAL,
        payload: {
          open: true,
          label: "Moving Availability to Inactive",
        },
      });
      const filteredSuiteData = availabilities?.filter(
        (x: any) => x?.pk !== suite?.pk
      );
      deleteAvailabilities({ id: suite?.pk, payload: { ...{ ...event } } })
        .then((data: any) => {
          Toast.success("Availability moved with success!");
          setAvailabilities(filteredSuiteData);
        })
        .finally(() => {
          setTimeout(() => {
            dispatch({
              type: SET_LOADING_MODAL,
              payload: {
                open: false,
                label: null,
              },
            });
          }, 100);
        });
    };

    dispatch({
      type: SET_EVENT_MODAL,
      payload: {
        open: true,
        content: {
          title: "Move Availability to Inactive",
          label: `${
            suite?.publish_status === PUBLISH_STATUS_PUBLISHED
              ? "This will unpublish the availability and move the availability to the inactive tab.  What is the reason for unpublishing and moving this availability to inactive?"
              : "This will move the availability to the inactive tab. "
          }`,
          eventType: EVENT_REMOVED_INACTIVE_AVAILABILITY,
          buttonLabel: "OK",
          action,
        },
      },
    });
  };

  const onStatusUpdate = async (
    id: any,
    publish_status: number,
    event: any = {}
  ) => {
    const response = await patchAvailability({
      id,
      payload: {
        publish_status,
        ...{ ...event },
      },
    });
    if (!response) return;
    Toast.success("Updated successfully");
    const updatedAvailabilities = [...(availabilities || [])];
    const index = availabilities?.findIndex(
      (item) => Number(item?.pk) === Number(id)
    );
    if (index !== undefined && index > -1) {
      updatedAvailabilities[index]["publish_status"] = response?.publish_status;
      setAvailabilities(updatedAvailabilities);
    }
  };

  const onClickPublish = async (e: any, availability: any) => {
    e.stopPropagation();
    const action = async (event: EventProps) => {
      try {
        dispatch({
          type: SET_LOADING_MODAL,
          payload: {
            open: true,
            label: `${
              availability?.publish_status === PUBLISH_STATUS_PUBLISHED
                ? "Unpublishing"
                : "Publishing"
            } the availability`,
          },
        });
        if (availability?.publish_status === PUBLISH_STATUS_PUBLISHED)
          await onStatusUpdate(availability?.pk, PUBLISH_STATUS_REMOVED, event);
        else
          await onStatusUpdate(
            availability?.pk,
            PUBLISH_STATUS_PUBLISHED,
            event
          );
        setTimeout(() => {
          dispatch({
            type: SET_LOADING_MODAL,
            payload: {
              open: false,
              label: null,
            },
          });
        }, 100);
      } catch (err) {
        setTimeout(() => {
          dispatch({
            type: SET_LOADING_MODAL,
            payload: {
              open: false,
              label: null,
            },
          });
        }, 100);
      }
    };

    dispatch({
      type: SET_EVENT_MODAL,
      payload: {
        open: true,
        content: {
          title: `${
            availability?.publish_status === PUBLISH_STATUS_PUBLISHED
              ? "Unpublish"
              : "Publish"
          } Availability`,
          label: `What is reason for ${
            availability?.publish_status === PUBLISH_STATUS_PUBLISHED
              ? "unpublishing"
              : "publishing"
          } this availability?`,
          eventType:
            availability?.publish_status === PUBLISH_STATUS_PUBLISHED
              ? EVENT_UNPUBLISHED_AVAILABILITY
              : EVENT_PUBLISHED,
          buttonLabel: `${
            availability?.publish_status === PUBLISH_STATUS_PUBLISHED
              ? "Unpublish"
              : "Publish"
          } Availablity`,
          action,
        },
      },
    });
  };

  const handleOpenGallery = async (name: string, index: number) => {
    let arr: any = [];
    property?.[name]?.forEach((url: string, idx: number) => {
      arr.push({
        id: idx,
        src: convertImageUrl(convertExtension(url)),
        thumb: convertImageUrl(convertExtension(url)),
        subHtml: renderToString(<span>{`${name} ${idx + 1}`}</span>),
      });
    });
    await dispatch({
      type: UPDATE_LIGHTGALLERY_DATA,
      payload: arr,
    });
    setTimeout(function () {
      lightGalleryRef.current.openGallery(index);
    }, 100);
  };

  const onClickShowMore = () => {
    const nextPage = page + 1;
    setPage(nextPage);

    getAvailabilities({
      page: nextPage,
      limit: SEARCH_RESULT_LIMIT,
      buildings: [propertyId],
    }).then((data: any) => {
      setAvailabilities((prevData: any) => [...prevData, ...data?.docs]);
      setPage(data?.page);
      setTotal(data?.total);
    });
  };

  const handleDownloadPDF = async () => {
    try {
      dispatch({
        type: SET_LOADING_MODAL,
        payload: {
          open: true,
          label: "Generating PDF",
        },
      });
      const blob = await ReactPDF.pdf(
        <PropertyPDF
          property={property}
          availabilities={availabilities}
          propertyMap={propertyMap}
          meta={meta}
        />
      ).toBlob();
      const fileUrl = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = fileUrl;
      link.download = `${new Date().getTime()}.pdf`;
      link.click();
      URL.revokeObjectURL(fileUrl);
      dispatch({
        type: SET_LOADING_MODAL,
        payload: {
          open: false,
          label: "Generating PDF",
        },
      });
    } catch (err) {
      dispatch({
        type: SET_LOADING_MODAL,
        payload: {
          open: false,
          label: "Generating PDF",
        },
      });
    }
  };

  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>{`${META_TAGS?.default?.title} ${
          property?.title || property?.address
        } Details`}</title>
        <meta
          property="og:title"
          content={`${META_TAGS?.default?.title} ${
            property?.title || property?.address
          } Details`}
        />
      </Helmet>
      <div>
        <div className="px-8 flex flex-row justify-end pt-10 mb-[-30px]">
          {property && propertyMap && (
            <Button
              variant="primary"
              leadingIcon={FiDownload}
              onClick={handleDownloadPDF}
            >
              Download PDF
            </Button>
          )}
        </div>
        {!!property?.images?.length && (
          <Layout.Section>
            <AccordionItem id="images" label="Property Images">
              <div className="flex flex-col w-full pt-8">
                <div className="flex flex-wrap mb-8 overflow-x-auto pb-1">
                  {property?.images?.map((src: string, idx: number) => (
                    <LazyLoadImage
                      className="w-44 h-44 object-cover rounded mr-4 mb-4 cursor-pointer"
                      src={convertImageUrl(convertExtension(src))}
                      onError={lazyLoadImageOnError}
                      onClick={() => handleOpenGallery("images", idx)}
                      key={idx}
                    />
                  ))}
                </div>
              </div>
            </AccordionItem>
          </Layout.Section>
        )}
        {!!property?.floorplans?.length && (
          <Layout.Section title="Floorplans">
            <AccordionItem id="floorplans" label="Floorplans">
              <div className="flex flex-col w-full pt-8">
                <div className="flex flex-wrap mb-8 pb-1">
                  {property?.floorplans?.map((src: string, idx: number) => (
                    <LazyLoadImage
                      className="w-44 h-44 object-cover rounded mr-4 mb-4 cursor-pointer"
                      src={convertImageUrl(convertExtension(src))}
                      onError={lazyLoadImageOnError}
                      onClick={() => handleOpenGallery("floorplans", idx)}
                      key={idx}
                    />
                  ))}
                </div>
              </div>
            </AccordionItem>
          </Layout.Section>
        )}

        <Layout.Section>
          <AccordionItem id="details" label="Details">
            <div className="flex flex-col w-full pt-8">
              <div className="mb-8 bg-jll-color-coldGray-1 px-8 py-6 flex flex-row justify-between items-center rounded-lg">
                <div>
                  <p className="font-semibold text-jll-color-text-base-default text-2xl">
                    {property?.title || "-"}
                  </p>
                  <p className="text-jll-color-coldGray-7 text-base">
                    {`${property?.address || ""} ${
                      property?.address_area || ""
                    } | ${property?.city || ""}, ${property?.state || ""} ${
                      property?.post_code || ""
                    }`}
                  </p>
                </div>
                <div className="flex flex-row items-center">
                  <span className="text-jll-color-coldGray-7 text-base mr-8">{`ID ${propertyId}`}</span>
                  <div className="space-x-2">
                    <Chip variant="primary">{property?.property_type}</Chip>
                  </div>
                </div>
              </div>
              <div className="mb-8 divide-jll-color-coldGray-3 divide-y">
                {sliceIntoChunks(PROPERTY_DETAILS, 3).map((detail, idx) => (
                  <div
                    className="flex divide-jll-color-coldGray-3 divide-x"
                    key={idx}
                  >
                    {detail?.map((item, idx1) => (
                      <div
                        className={cn(
                          "flex flex-row items-center justify-between w-full py-3",
                          {
                            "pr-6": idx1 === 0,
                            "px-6": idx1 === 1,
                            "pl-6": idx1 === 2,
                          }
                        )}
                        key={idx1}
                      >
                        <span className="text-jll-color-coldGray-7 text-base">
                          {item?.label}
                        </span>
                        {item?.key && (
                          <span className="text-jll-color-coldGray-7 text-base">
                            <StyledPropertyValue
                              property={property}
                              valueKey={item?.key}
                            />
                          </span>
                        )}
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </AccordionItem>
        </Layout.Section>

        {!!property?.descriptions?.length && (
          <Layout.Section>
            <AccordionItem id="descriptions" label="Descriptions">
              <div className="flex flex-col w-full pt-8">
                <div className="flex flex-col bg-jll-color-coldGray-1 px-8 py-4 divide-jll-color-coldGray-3 divide-y rounded-lg">
                  {property?.descriptions?.map(
                    (description: DescriptionProps, idx: number) => (
                      <div className="py-2" key={idx}>
                        <h4 className="text-jll-color-text-base-default font-semibold text-lg">
                          {description?.title || ""}
                        </h4>
                        <div
                          className="text-jll-color-coldGray-7"
                          dangerouslySetInnerHTML={{
                            __html: description?.content,
                          }}
                        ></div>
                      </div>
                    )
                  )}
                </div>
              </div>
            </AccordionItem>
          </Layout.Section>
        )}

        {!!availabilities?.length && (
          <Layout.Section>
            <AccordionItem id="space-details" label="Space Details">
              <div className="flex flex-col w-full pt-8">
                <div className="flex flex-col overflow-x-auto">
                  <Table>
                    <Table.Thead>
                      <Table.Tr>
                        {updatedAvailabilityTableColumns.map(
                          (column, index) => {
                            return (
                              <Table.Th
                                key={index}
                                className={column?.th?.className}
                                filterId={column.id}
                              >
                                {column.label}
                              </Table.Th>
                            );
                          }
                        )}
                        <Table.Th />
                      </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody>
                      {availabilities?.map(
                        (suite: AvailabilityProps, index: number) => {
                          return (
                            <Table.Tr
                              key={index}
                              onClick={onClickEdit(
                                Number(propertyId),
                                suite?.pk
                              )}
                            >
                              {updatedAvailabilityTableColumns.map(
                                (column, index) => {
                                  return (
                                    <Table.Td
                                      key={index}
                                      className={column?.td?.className}
                                    >
                                      <StyledAvailabilityValue
                                        availability={suite}
                                        valueKey={column.id}
                                      />
                                    </Table.Td>
                                  );
                                }
                              )}
                              <Table.Td className="space-x-2 text-right">
                                <Button
                                  variant={
                                    isLoadingPatch
                                      ? "secondary"
                                      : suite?.publish_status ===
                                        PUBLISH_STATUS_PUBLISHED
                                      ? "danger"
                                      : "primary"
                                  }
                                  size="small"
                                  disabled={isLoadingPatch}
                                  onClick={(e) => onClickPublish(e, suite)}
                                >
                                  {suite?.publish_status ===
                                  PUBLISH_STATUS_PUBLISHED
                                    ? "Unpublish"
                                    : "Publish"}
                                </Button>
                                <Button
                                  variant="secondary"
                                  size="small"
                                  onClick={(e) => onClickPreview(e, suite?.pk)}
                                >
                                  Preview
                                </Button>
                                <Button
                                  variant="secondary"
                                  size="small"
                                  onClick={(e) => onClickRemove(e, suite)}
                                >
                                  Move to Inactive
                                </Button>
                              </Table.Td>
                            </Table.Tr>
                          );
                        }
                      )}
                    </Table.Tbody>
                  </Table>
                  <ShowMoreItems
                    onClick={onClickShowMore}
                    total={total}
                    isLoading={isLoadingAvailabilities}
                    itemsCount={availabilities.length}
                  />
                </div>
              </div>
            </AccordionItem>
          </Layout.Section>
        )}

        <Layout.Section>
          <AccordionItem id="location" label="Location">
            <div className="flex flex-col w-full pt-8">
              <div>
                {property && (
                  <GoogleMap
                    position={{
                      lat: Number(property?.latitude),
                      lng: Number(property?.longitude),
                    }}
                  />
                )}
              </div>
            </div>
          </AccordionItem>
        </Layout.Section>

        {(!!property?.amenities?.length || !!property?.highlights?.length) && (
          <Layout.Section>
            <AccordionItem
              id="amenity"
              label={
                !!property?.amenities?.length && !!property?.highlights?.length
                  ? "Amenity and Highlight"
                  : !!property?.amenities?.length
                  ? "Amenity"
                  : "Highlight"
              }
            >
              <div className="flex flex-col w-full pt-8">
                <div>
                  {!!property?.amenities?.length && (
                    <div className="mt-6 divide-jll-color-coldGray-3 divide-y">
                      {sliceIntoChunks(property?.amenities || [], 5).map(
                        (detail, idx) => (
                          <div
                            className="flex divide-jll-color-coldGray-3 divide-x"
                            key={idx}
                          >
                            {detail?.map((item, idx1) => (
                              <div
                                className={cn(
                                  "flex flex-row items-center justify-between w-full py-3 truncate",
                                  {
                                    "pr-6": idx1 === 0,
                                    "pl-6 pr-6":
                                      idx1 !== 0 && idx1 !== detail?.length - 1,
                                    "pl-6": idx1 === detail?.length - 1,
                                  }
                                )}
                                key={idx1}
                              >
                                {(item?.amenity_name ||
                                  item?.custom_amenity) && (
                                  <span
                                    className="text-jll-color-coldGray-7 text-base truncate"
                                    title={
                                      item?.amenity_name || item?.custom_amenity
                                    }
                                  >
                                    {item?.amenity_name || item?.custom_amenity}
                                  </span>
                                )}
                              </div>
                            ))}
                          </div>
                        )
                      )}
                    </div>
                  )}
                  {!!property?.highlights?.length && (
                    <PreviewHighlight
                      className="mt-6"
                      highlights={property?.highlights}
                    />
                  )}
                </div>
              </div>
            </AccordionItem>
          </Layout.Section>
        )}

        {!!property?.brochures?.length && (
          <Layout.Section>
            <AccordionItem id="brochures" label="Brochures">
              <div className="flex flex-col w-full pt-8">
                <div className="flex flex-row flex-wrap">
                  {property?.brochures.map((src: string, idx: number) => {
                    const isCloudaryFile = src?.includes("cloudinary")
                      ? true
                      : false;

                    return (
                      <div className="relative mb-4" key={idx}>
                        {isCloudaryFile ? (
                          <LazyLoadImage
                            className="w-44 h-44 object-cover rounded mr-4"
                            src={convertImageUrl(convertExtension(src))}
                            onError={lazyLoadImageOnError}
                          />
                        ) : (
                          <div className="h-44 group w-44 flex justify-center items-center cursor-pointer relative mr-4 border border-jll-color-coldGray-3 rounded">
                            <FiLink className="w-10 h-10 text-jll-color-text-base-default" />
                          </div>
                        )}
                        <Button
                          variant="secondary"
                          className="absolute bottom-[16px] right-[32px]"
                          leadingIcon={
                            isCloudaryFile ? FiDownload : FiExternalLink
                          }
                          onClick={() => window.open(src)}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </AccordionItem>
          </Layout.Section>
        )}

        {!!property?.brokers?.length && (
          <Layout.Section>
            <AccordionItem id="brokers" label="Brokers">
              <div className="flex flex-col w-full pt-8">
                <div className="flex flex-row flex-wrap">
                  {property?.brokers?.map((broker: any, idx: number) => (
                    <div className="w-[190px] mr-4 mb-4" key={idx}>
                      <StyledBrokerValue broker={broker} valueKey="name" />
                    </div>
                  ))}
                </div>
              </div>
            </AccordionItem>
          </Layout.Section>
        )}
      </div>

      <div className="px-8 fixed bottom-0 left-[360px] right-0 flex justify-end bg-white shadow-detail-status-nav h-[80px] items-center">
        <div className="flex space-x-2">
          <Link
            to={`/property/${propertyId}/${URLS.PROPERTY.FORM}?tab=${PROPERTY_TABS.MEDIA}`}
          >
            <Button variant="secondary" leadingIcon={FiChevronLeft}>
              Previous
            </Button>
          </Link>
          <Link
            to={`/property/${propertyId}/${URLS.PROPERTY.FORM}?tab=${PROPERTY_TABS.PUBLISH}`}
          >
            <Button variant="secondary" trailingIcon={FiChevronRight}>
              Next
            </Button>
          </Link>
        </div>
      </div>
      <AvailabilityPreviewModal
        pk={openedAvailability}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </>
  );
};

export default React.memo(PropertyDetails);
