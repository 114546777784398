import * as Yup from "yup";
import { REGEX_VIDEO } from "constant";

export default Yup.object().shape({
  videos: Yup.array().of(
    Yup.string()
      .required("This field is required")
      // .trim()
      // .matches(REGEX_VIDEO, "Need to be a valid Youtube or Vimeo url")
  ),
  virtual_tours: Yup.array().of(
    Yup.string()
      .required("This field is required")
      // .trim()
      // .matches(REGEX_VIDEO, "Need to be a valid Youtube or Vimeo url")
  ),
});
