import React from "react";
import { Popover, Transition } from "@headlessui/react";
import { FiChevronDown } from "react-icons/fi";
import { DateRange } from "ui-atoms";
import { getFormatedDateTime } from "utils";
import cn from "classnames";

interface IMultipleSelectProps {
  start_date?: any;
  end_date?: any;
  name?: string;
  className?: string;
  onChange?: any;
  title?: string;
  isRight?: boolean;
}

const DateRangeSelect = ({
  start_date = "",
  end_date = "",
  name = "",
  className,
  onChange,
  title = "",
  isRight = false,
}: IMultipleSelectProps) => {
  const handleDate = ({ start_date, end_date }: any) => {
    onChange({ start_date, end_date });
  };

  const selectClasses =
    !!start_date?.length || !!end_date.length
      ? "bg-jll-color-surface-info-subdued text-jll-color-surface-info-default"
      : "text-jll-color-text-base-subdued bg-jll-color-coldGray-1";

  return (
    <div className="z-20">
      <Popover className="relative">
        {({ open }) => (
          <>
            <div className="relative">
              <span className="inline-block w-full">
                <Popover.Button
                  className={`flex flex-row items-center w-full py-1.5 px-3 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm cursor-pointer rounded-[48px] border-0 font-semibold hover:bg-jll-color-surface-info-subdued hover:text-jll-color-surface-info-default ${selectClasses} ${className} ${
                    open && "!text-white !bg-jll-color-surface-info-default"
                  }`}
                >
                  <span className="block truncate">
                    {start_date || end_date
                      ? `${getFormatedDateTime(start_date, {
                          dateStyle: "long",
                        })} ~ ${getFormatedDateTime(end_date, {
                          dateStyle: "long",
                        })}`
                      : title}
                  </span>
                  <span className="flex items-center ml-2">
                    <FiChevronDown className="h-5 w-5" aria-hidden="true" />
                  </span>
                </Popover.Button>
              </span>

              <Transition
                unmount={false}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                className="absolute mt-1 w-full rounded-md bg-white shadow-lg"
              >
                <Popover.Panel
                  static
                  className={cn(
                    'origin-top-right absolute mt-2 rounded-lg bg-white shadow-drop z-20 after:content-[""] after:absolute after:right-[10px] after:-top-[10px] after:border-[10px] after:border-solid after:border-x-transparent after:border-t-transparent after:border-t-0 after:border-b-white overflow-y-auto',
                    {
                      "left-0": !isRight,
                      "right-0": isRight,
                    }
                  )}
                >
                  <DateRange
                    onChange={handleDate}
                    value={{ start_date, end_date }}
                  />
                </Popover.Panel>
              </Transition>
            </div>
          </>
        )}
      </Popover>
    </div>
  );
};

export default DateRangeSelect;
