import { useState, useEffect } from "react";
import cn from "classnames";
import { useLocation } from "react-router-dom";
import type { FC } from "react";
import { FiChevronUp, FiChevronDown } from "react-icons/fi";
import { Label } from "ui-atoms";
import Facet from "./Facet";
import { FACET_MAX } from "./constants";
import type { FacetsProps, FilteredFacetProps } from "types";
import { getFilteredFacets } from "utils";

interface IFacets {
  className?: string;
  facets?: FacetsProps;
  filterFacets?: FilteredFacetProps[];
}

const Facets: FC<IFacets> = ({ className, facets, filterFacets }) => {
  const [filteredFacets, setFilteredFacets] = useState<FilteredFacetProps[]>(
    []
  );
  const location = useLocation();

  useEffect(() => {
    if (facets) {
      setFilteredFacets(getFilteredFacets(facets));
    } else if (filterFacets) {
      setFilteredFacets(filterFacets);
    }
  }, [facets, filterFacets]);

  const onClickViewMore = (id: string) => {
    const updatedFacetsProps = filteredFacets?.map((facet) => {
      if (facet.id === id) {
        return {
          ...facet,
          isOpen: !facet.isOpen,
        };
      }
      return facet;
    });
    setFilteredFacets(updatedFacetsProps);
  };

  return (
    <div
      className={cn(
        "absolute top-0 left-0 bottom-0 w-[300px] p-6 bg-jll-color-coldGray-1 col-span-2 h-full overflow-y-auto",
        className
      )}
    >
      <div className="space-y-4">
        {filteredFacets?.map((facetCategory, facet_index) => {
          if (!facetCategory.facets?.length) {
            return null;
          }

          return (
            <div key={facet_index}>
              <Label className="text-jll-color-coldGray-7 capitalize">
                {facetCategory.label}
              </Label>
              <div className="space-y-1 mt-2 transition-all">
                {facetCategory.facets
                  .slice(0, FACET_MAX)
                  .map((facet, index) => {
                    return (
                      <Facet
                        key={index}
                        pageId={location.pathname.replace("/", "")}
                        parentId={facetCategory.id}
                        {...facet}
                      />
                    );
                  })}
                <div
                  className={cn(
                    "transition-all duration-500 ease max-h-[2000px] overflow-hidden space-y-1",
                    { "!max-h-0": !facetCategory.isOpen }
                  )}
                >
                  {facetCategory.facets.slice(FACET_MAX).map((facet, index) => {
                    return (
                      <Facet
                        key={index}
                        pageId={location.pathname.replace("/", "")}
                        parentId={facetCategory.id}
                        {...facet}
                      />
                    );
                  })}
                </div>
                {facetCategory.facets.length > FACET_MAX && (
                  <button
                    className="text-jll-color-surface-info-default flex items-center space-x-2"
                    onClick={() => onClickViewMore(facetCategory.id)}
                  >
                    {facetCategory.isOpen ? <FiChevronUp /> : <FiChevronDown />}
                    {facetCategory.isOpen && (
                      <span className="text-sm">View less</span>
                    )}
                    {!facetCategory.isOpen && (
                      <span className="text-sm">
                        View more ({facetCategory.facets.length - FACET_MAX})
                      </span>
                    )}
                  </button>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Facets;
