import {
  AVAILABILITY_TABS,
  EVENT_PUBLISHED,
  EVENT_UNPUBLISHED_AVAILABILITY,
  EventProps,
  META_TAGS,
  PUBLISH_STATUS_DRAFT,
  PUBLISH_STATUS_PUBLISHED,
  PUBLISH_STATUS_REMOVED,
  SET_AVAILABILITY,
  SET_EVENT_MODAL,
  SET_LOADING_MODAL,
  URLS,
} from "constant";
import { GlobalContext } from "context";
import { useApiCall } from "hooks";
import React from "react";
import { useContext, useRef } from "react";
import { Helmet } from "react-helmet-async";
import { FiChevronLeft } from "react-icons/fi";
import { Link, useParams } from "react-router-dom";
import { getAvailabilityAPI, patchAvailabilityAPI } from "services";
import { Button, Label, Toast } from "ui-atoms";
import { PublishLogList } from "ui-molecules";
import { LayoutEditAvailability as Layout } from "ui-organisms";
import { joinTwoStr } from "utils";

interface IAvailabilityPublish {
  diffFields?: any;
  onSave?: any;
}

const AvailabilityPublish: React.FC<IAvailabilityPublish> = ({
  diffFields,
  onSave,
}) => {
  const { state, dispatch } = useContext(GlobalContext);
  const { availability } = state;
  const { availabilityId, propertyId } = useParams();
  const logRef = useRef<any>(null);
  const [getAvailability] = useApiCall(getAvailabilityAPI);
  const [patchAvailability] = useApiCall(patchAvailabilityAPI);

  const reloadAvailability = () => {
    getAvailability(availabilityId).then((data: any) => {
      dispatch({
        type: SET_AVAILABILITY,
        payload: data,
      });
    });
  };

  const onStatusUpdate = async (publish_status: number, event: any = {}) => {
    const response = await patchAvailability({
      id: availability.id,
      payload: {
        publish_status,
        ...{ ...event },
      },
    });
    if (!response) return;
    Toast.success("Updated successfully");
    dispatch({
      type: SET_AVAILABILITY,
      payload: {
        ...availability,
        publish_status,
      },
    });
    reloadAvailability();
    logRef?.current?.loadData();
  };

  const handlePublish = async () => {
    const action = async (event: EventProps) => {
      try {
        if (!!diffFields && !!Object.keys(diffFields)?.length && onSave) {
          await onSave(PUBLISH_STATUS_PUBLISHED);
        } else {
          dispatch({
            type: SET_LOADING_MODAL,
            payload: {
              open: true,
              label: "Publishing the availability",
            },
          });
          await onStatusUpdate(PUBLISH_STATUS_PUBLISHED, event);
          setTimeout(() => {
            dispatch({
              type: SET_LOADING_MODAL,
              payload: {
                open: false,
                label: null,
              },
            });
          }, 100);
        }
      } catch (err) {
        setTimeout(() => {
          dispatch({
            type: SET_LOADING_MODAL,
            payload: {
              open: false,
              label: null,
            },
          });
        }, 100);
      }
    };

    dispatch({
      type: SET_EVENT_MODAL,
      payload: {
        open: true,
        content: {
          title: "Publish Availability",
          label: `What is reason for publishing this availability?`,
          eventType: EVENT_PUBLISHED,
          buttonLabel: "Publish Availablity",
          action,
        },
      },
    });
  };

  const handleUnpublish = async () => {
    const action = async (event: EventProps) => {
      try {
        dispatch({
          type: SET_LOADING_MODAL,
          payload: {
            open: true,
            label: "Unpublishing the availability",
          },
        });
        await onStatusUpdate(PUBLISH_STATUS_REMOVED, event);
        setTimeout(() => {
          dispatch({
            type: SET_LOADING_MODAL,
            payload: {
              open: false,
              label: null,
            },
          });
        }, 100);
      } catch (err) {
        setTimeout(() => {
          dispatch({
            type: SET_LOADING_MODAL,
            payload: {
              open: false,
              label: null,
            },
          });
        }, 100);
      }
    };

    dispatch({
      type: SET_EVENT_MODAL,
      payload: {
        open: true,
        content: {
          title: "Unpublish Availability",
          label: `What is reason for unpublishing this availability?`,
          eventType: EVENT_UNPUBLISHED_AVAILABILITY,
          buttonLabel: "Unpublish Availablity",
          action,
        },
      },
    });
  };

  const handleSync = async () => {
    try {
      let publish_status = availability?.publish_status;
      if (
        publish_status !== PUBLISH_STATUS_PUBLISHED &&
        publish_status !== PUBLISH_STATUS_REMOVED
      ) {
        publish_status = PUBLISH_STATUS_DRAFT;
      }
      if (!!diffFields && !!Object.keys(diffFields)?.length && onSave) {
        await onSave(publish_status);
      } else {
        dispatch({
          type: SET_LOADING_MODAL,
          payload: {
            open: true,
            label: "Publishing the availability",
          },
        });
        await onStatusUpdate(publish_status, {});
        setTimeout(() => {
          dispatch({
            type: SET_LOADING_MODAL,
            payload: {
              open: false,
              label: null,
            },
          });
        }, 100);
      }
    } catch (err) {
      setTimeout(() => {
        dispatch({
          type: SET_LOADING_MODAL,
          payload: {
            open: false,
            label: null,
          },
        });
      }, 100);
    }
  };

  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>{`${META_TAGS?.default?.title} ${joinTwoStr(
          availability?.building_title,
          availability?.name,
          " | "
        )} Publish`}</title>
        <meta
          property="og:title"
          content={`${META_TAGS?.default?.title} ${joinTwoStr(
            availability?.building_title,
            availability?.name,
            " | "
          )} Publish`}
        />
      </Helmet>

      <div>
        <Layout.Section title="Publish to OLM" titleClassName="!mb-2">
          <div className="flex flex-row items-center">
            <Label>
              Write your listing to OLM. You publishing this availability in OLM
              will also update the property data.{" "}
            </Label>
          </div>
          <div>
            <div className="flex flex-col space-y-2 py-4">
              <Button
                variant="primary"
                onClick={
                  availability?.publish_status === PUBLISH_STATUS_PUBLISHED
                    ? handleSync
                    : handlePublish
                }
                className="whitespace-nowrap w-fit"
              >
                Publish Availability
              </Button>

              <p className="text-jll-color-coldGray-7 text-sm">
                Clicking here will publish the availability. It will replace any
                active data in OLM for this availability and this property.
              </p>
            </div>
            {availability?.publish_status === PUBLISH_STATUS_PUBLISHED && (
              <div className="flex flex-col space-y-2 py-4">
                <Button
                  variant="danger"
                  onClick={handleUnpublish}
                  className="whitespace-nowrap w-fit"
                >
                  Un-publish Availability
                </Button>
                <p className="text-jll-color-coldGray-7 text-sm">
                  This will remove this availability from OLM. It will not
                  modify property data.
                </p>
              </div>
            )}
          </div>
        </Layout.Section>

        <PublishLogList type="suite" objectId={availabilityId} ref={logRef} />
      </div>

      <div className="px-8 fixed bottom-0 left-[360px] right-0 flex justify-end bg-white shadow-detail-status-nav h-[80px] items-center">
        <Link
          to={`/property/${propertyId}/availability/${availabilityId}/${URLS.AVAILABILITY.FORM}?tab=${AVAILABILITY_TABS.MEDIA}`}
        >
          <Button variant="secondary" leadingIcon={FiChevronLeft}>
            Previous
          </Button>
        </Link>
      </div>
    </>
  );
};

export default React.memo(AvailabilityPublish);
