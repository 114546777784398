import { SET_EVENT_MODAL } from "constant";
import { GlobalContext } from "context";
import { useContext, useEffect, useState } from "react";
import { FiX } from "react-icons/fi";
import { Button, Radio } from "ui-atoms";
import { Modal } from "ui-molecules";

interface IOption {
  label: string;
  value: number;
}

const EventModal = () => {
  const { dispatch, state } = useContext(GlobalContext);
  const { eventModalOpen, eventModalContent, meta } = state;
  const [reasonOptions, setReasonOptions] = useState<IOption[]>([]);
  const [selectedReason, setSelectedReason] = useState<any>(null);

  useEffect(() => {
    if (!meta || !eventModalContent?.eventType) return;
    const { REASON_DESCRIPTION_MAP, REASON_MAP } = meta;
    const currentReasonMap = REASON_MAP?.[eventModalContent?.eventType] || [];
    const descriptions = Object.keys(REASON_DESCRIPTION_MAP)
      .filter((key) => currentReasonMap?.includes(Number(key)))
      ?.map((key) => ({
        value: Number(key),
        label: REASON_DESCRIPTION_MAP[key],
      }));
    setReasonOptions(descriptions);
    setSelectedReason(null);
  }, [meta, eventModalContent]);

  const handleClose = () => {
    dispatch({
      type: SET_EVENT_MODAL,
      payload: {
        open: false,
        content: null,
      },
    });
  };

  const handleChange = (e: any) => {
    setSelectedReason(Number(e?.currentTarget?.value));
  };

  const handleSubmit = () => {
    eventModalContent?.action({
      event_type: eventModalContent?.eventType,
      event_reason: selectedReason,
    });
    handleClose();
  };

  return (
    <Modal isOpen={eventModalOpen} setIsOpen={handleClose} size="medium">
      <Modal.Header className="relative">
        <Button
          variant="neutral"
          leadingIcon={FiX}
          className="absolute top-0 right-0 w-5 !h-5"
          leadingIconClass="w-5 h-5 text-jll-color-text-base-subdued"
          onClick={handleClose}
        />
        <h4 className="text-xl font-semibold text-jll-color-text-base-default">
          {eventModalContent?.title || ""}
        </h4>
      </Modal.Header>
      <Modal.Body className="py-4">
        <div>
          <p className="text-jll-color-text-base-default mb-4">
            {eventModalContent?.label || ""}
          </p>
          <div className="flex flex-col space-y-2 pl-2">
            {!!reasonOptions?.length &&
              reasonOptions?.map((option: IOption, idx: number) => (
                <Radio
                  key={idx}
                  id={option?.value?.toString()}
                  name={option?.value?.toString()}
                  label={option?.label}
                  value={option.value}
                  checked={selectedReason === option.value}
                  onChange={handleChange}
                />
              ))}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          onClick={handleSubmit}
          disabled={!selectedReason}
        >
          {eventModalContent?.buttonLabel || ''}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EventModal;
