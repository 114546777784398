import { PUBLISH_STATUS_REMOVED, PUBLISH_STATUS_PUBLISHED } from "constant";
import { GlobalContext } from "context";
import { useContext } from "react";
import { Chip } from "ui-atoms";
import { getDecimalFormating, getFormatedDateTime } from "utils";

interface IStyledActivityLogValue {
  activity: any;
  valueKey: string;
}

const StyledActivityLogValue: React.FC<IStyledActivityLogValue> = ({
  activity,
  valueKey,
}) => {
  const { state } = useContext(GlobalContext);
  const { meta } = state;

  switch (valueKey) {
    case "change__update_timestamp":
    case "update_timestamp":
      if (!activity?.change__update_timestamp) {
        return <>-</>;
      }
      return <>{getFormatedDateTime(activity?.change__update_timestamp)}</>;
    case "change__create_timestamp":
    case "create_timestamp":
      if (!activity?.change__create_timestamp) {
        return <>-</>;
      }
      return <>{getFormatedDateTime(activity?.change__create_timestamp)}</>;
    case "change__broker":
    case "broker":
      return <>{activity?.object?.broker_name || activity?.change__broker}</>;
    case "change__publish_status":
    case "publish_status":
      if (activity?.change__publish_status === PUBLISH_STATUS_PUBLISHED)
        return <>Published</>;
      else if (activity?.change__publish_status === PUBLISH_STATUS_REMOVED)
        return <>Removed</>;
      else return <>{activity?.change__publish_status}</>;
    case "change__highlights":
    case "highlights":
      return (
        <div>
          {activity[valueKey]?.map((item: any, idx: number) => (
            <p key={idx}>{item}</p>
          ))}
        </div>
      );
    case "change__min_surface_area":
    case "change__max_surface_area":
    case "change__min_floor_area":
    case "change__max_floor_area":
    case "min_surface_area":
    case "max_surface_area":
    case "min_floor_area":
    case "max_floor_area":
      return <>{`${getDecimalFormating(activity[valueKey])} Sq ft`}</>;
    case "change__min_rent":
    case "change__max_rent":
    case "change__min_sale":
    case "change__max_sale":
    case "change__min_price":
    case "change__max_price":
    case "change__operating_expenses":
    case "change__utility_rate":
    case "change__parking_cost_reserved":
    case "change__parking_cost_unreserved":
    case "min_rent":
    case "max_rent":
    case "min_sale":
    case "max_sale":
    case "min_price":
    case "max_price":
    case "operating_expenses":
    case "utility_rate":
    case "parking_cost_reserved":
    case "parking_cost_unreserved":
      return <>{`$${getDecimalFormating(activity[valueKey])}`}</>;
    case "change__event_reason":
    case "event_reason":
      return <>{meta?.REASON_DESCRIPTION_MAP?.[activity?.[valueKey]] || "-"}</>;
    case "change__event_type":
    case "event_type":
      return <>{meta?.EVENT_LOG_TYPE_MAP?.[activity?.[valueKey]] || "-"}</>;
    default:
      if (activity.hasOwnProperty(valueKey)) {
        if (typeof activity[valueKey] === "boolean")
          return <>{activity[valueKey] ? "Yes" : "No"}</>;
        if (valueKey?.includes("types"))
          return (
            <div>
              {activity[valueKey]?.map((item: any, idx: number) => (
                <Chip
                  key={idx}
                  variant="secondary"
                  className="mr-1"
                  size="small"
                >
                  {item?.toLowerCase() === "rent" ? "Lease" : item}
                </Chip>
              ))}
            </div>
          );
        if (Array.isArray(activity[valueKey]))
          return (
            <div className="flex flex-col max-w-[80%]">
              {activity[valueKey]?.map((item: string, idx: number) => (
                <span className="text-end truncate">
                  {typeof item === "string" ? item : ""}
                </span>
              ))}
            </div>
          );

        const newKey = valueKey.replace("change__", "");
        const parentKey = Object.keys(meta).find((key: string) => {
          if (typeof meta?.[key] === "object") {
            if (!!meta?.[key]?.[newKey]) return true;
            else return false;
          }
          return false;
        });
        if (!!parentKey)
          return (
            <>
              {meta?.[parentKey]?.[newKey]?.[activity[valueKey]]?.value || "-"}
            </>
          );
        return <>{activity[valueKey]}</>;
      }
      return <>-</>;
  }
};

export default StyledActivityLogValue;
