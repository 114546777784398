import * as React from "react";
import { UNSAFE_NavigationContext } from "react-router-dom";
import type { History } from "history";
import { useEffect } from "react";

const initBeforeUnLoad = (showExitPrompt: boolean) => {
  window.onbeforeunload = (event) => {
    if (showExitPrompt) {
      const e = event || window.event;
      e.preventDefault();
      if (e) {
        e.returnValue = "You have un-saved changes, would you like to save changes before proceeding?";
      }
      return "You have un-saved changes, would you like to save changes before proceeding?";
    }
  };
};

export function useBlocker(blocker: any, when = true): void {
  const navigator = React.useContext(UNSAFE_NavigationContext)
    .navigator as History;

  window.onload = function () {
    initBeforeUnLoad(false);
  };

  useEffect(() => {
    if (!when) {
      return;
    }
    initBeforeUnLoad(when);
    const push = navigator.push;

    navigator.push = (...args: Parameters<typeof push>) => {
      const result = blocker(...args);
      if (result !== false) {
        push(...args);
      }
    };

    return () => {
      navigator.push = push;
      window.onbeforeunload = null;
    };
  }, [navigator, blocker, when]);
}
