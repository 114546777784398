import { useApiCall } from "hooks";
import queryString from "query-string";
import { useContext, useEffect, useState } from "react";
import { FiPlusCircle, FiSearch } from "react-icons/fi";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  deleteTeamListingLinkAPI,
  getTeamListingLinkAPI,
  patchTeamListingLinkAPI,
} from "services";
import { Button, EmptyResult, Input, Loading, Toast } from "ui-atoms";
import {
  StyledTeamListingValue,
  Table,
  TeamListingLinkModal,
} from "ui-molecules";
import { LayoutEditTeamListing as Layout } from "ui-organisms";
import { LINL_TABLE_COLUMNS } from "./constants";
import { GlobalContext } from "context";
import useInfiniteScroll from "react-infinite-scroll-hook";
import {
  SEARCH_RESULT_LIMIT,
  SET_LOADING_MODAL,
  STATUS_ACTIVE,
  STATUS_REMOVED,
} from "constant";
import cn from "classnames";

const TAB_MENU = [
  { label: "Active", value: STATUS_ACTIVE },
  { label: "Inactive", value: STATUS_REMOVED },
];

const TeamListingLink = () => {
  const { state, dispatch } = useContext(GlobalContext);
  const { meta, teamListing } = state;
  const [getTeamListingLink, loading] = useApiCall(getTeamListingLinkAPI);
  const [deleteTeamListingLink] = useApiCall(deleteTeamListingLinkAPI);
  const [patchTeamListingLink] = useApiCall(patchTeamListingLinkAPI);
  const { teamId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [keyword, setKeyword] = useState<string>();
  const [paramFilters, setParamFilters] = useState<any>(null);
  const [data, setData] = useState<any>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [selectedLink, setSelectedLink] = useState(null);
  const [activeTab, setActiveTab] = useState<any>(STATUS_ACTIVE);

  useEffect(() => {
    let filters = queryString.parse(location?.search, {
      arrayFormat: "bracket",
    });
    setKeyword(filters?.keyword?.toString() || "");
    if (filters?.active_status) {
      setActiveTab(filters?.active_status);
    }
    setParamFilters(filters);
  }, [location]);

  useEffect(() => {
    if (!paramFilters || !teamId || isOpen) return;
    if (!isOpen) {
      setTimeout(() => {
        loadTeamListingLink();
      }, 100);
      return;
    } else loadTeamListingLink();
  }, [paramFilters, teamId, isOpen]);

  const loadTeamListingLink = () => {
    setKeyword(paramFilters?.keyword || "");
    setPage(1);
    getTeamListingLink({
      page: 1,
      limit: SEARCH_RESULT_LIMIT,
      team_listing: [teamId],
      ...paramFilters,
    }).then((res: any) => {
      setData([...res?.docs]);
      setPage(res?.page);
      setTotal(res?.total);
    });
  };

  const loadMore = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    getTeamListingLink({
      page: 1,
      limit: SEARCH_RESULT_LIMIT,
      team_listing: [teamId],
      ...paramFilters,
    }).then((res: any) => {
      setData([...res?.docs]);
      setPage(res?.page);
      setTotal(res?.total);
    });
  };

  const [sentryRef] = useInfiniteScroll({
    loading: false,
    hasNextPage: total > data?.length,
    onLoadMore: loadMore,
  });

  const removeLink = async (pk: string | number) => {
    try {
      dispatch({
        type: SET_LOADING_MODAL,
        payload: {
          open: true,
          label: `Removing the link from Set`,
        },
      });
      deleteTeamListingLink(pk)
        .then((res: any) => {
          const index: number | undefined = data?.findIndex(
            (item: any) => item?.pk === pk || item?.id === pk
          );
          if (index !== undefined && index > -1) {
            const new_data = [...(data || [])];
            new_data?.splice(index, 1);
            setData(new_data);
            Toast.success("Removed the link successfully");
          }
        })
        .finally(() => {
          setTimeout(() => {
            dispatch({
              type: SET_LOADING_MODAL,
              payload: {
                open: false,
                label: null,
              },
            });
          }, 100);
        });
    } catch (err: any) {
      if (err?.message) Toast.error(err?.message);
      setTimeout(() => {
        dispatch({
          type: SET_LOADING_MODAL,
          payload: {
            open: false,
            label: null,
          },
        });
      }, 100);
    }
  };

  const reinstateLink = async (pk: string | number) => {
    try {
      dispatch({
        type: SET_LOADING_MODAL,
        payload: {
          open: true,
          label: `Reinstating the link from Set`,
        },
      });
      patchTeamListingLink({ pk, active_status: STATUS_ACTIVE })
        .then((res: any) => {
          const index: number | undefined = data?.findIndex(
            (item: any) => item?.pk === pk || item?.id === pk
          );
          if (index !== undefined && index > -1) {
            const new_data = [...(data || [])];
            new_data.splice(index, 1);
            // new_data[index]["active_status"] = STATUS_ACTIVE;
            setData(new_data);
            Toast.success("Reinstated the link successfully");
          }
        })
        .finally(() => {
          setTimeout(() => {
            dispatch({
              type: SET_LOADING_MODAL,
              payload: {
                open: false,
                label: null,
              },
            });
          }, 100);
        });
    } catch (err: any) {
      if (err?.message) Toast.error(err?.message);
      setTimeout(() => {
        dispatch({
          type: SET_LOADING_MODAL,
          payload: {
            open: false,
            label: null,
          },
        });
      }, 100);
    }
  };

  const handleTab = (value: string) => () => {
    const updatedFilters = { active_status: [value] };
    navigate(
      `${location.pathname}?${queryString.stringify(updatedFilters, {
        arrayFormat: "bracket",
      })}`
    );
  };

  return (
    <Layout>
      <div className="relative w-full h-full flex flex-col">
        <div className="px-8 py-6 flex flex-row items-center justify-between shadow-detail-status-nav bg-white">
          <div className="flex flex-row items-center space-x-6">
            <span className="font-semibold text-jll-color-text-base-default">
              Find a property to add to listing
            </span>
            <Input
              name="property"
              placeholder="Search by building name, listing ID"
              leadingIcon={FiSearch}
              className="w-[400px] !mb-0"
              isClearable
              value={keyword}
            />
          </div>
          <div className="flex flex-row items-center space-x-4">
            {/* <Button variant="secondary" leadingIcon={FiExternalLink}>
              Link to Listings
            </Button>
            <Button variant="secondary" leadingIcon={FiUpload}>
              Digital Export
            </Button> */}
          </div>
        </div>
        <div className="mt-4 flex justify-end flex-row w-full pr-8">
          <Button
            variant="primary"
            leadingIcon={FiPlusCircle}
            onClick={() => {
              setIsOpen(true);
              setSelectedLink(null);
            }}
          >
            Add Page
          </Button>
        </div>
        <div className="flex justify-between items-center h-[50px] z-10 border-b border-jll-color-coldGray-3 mx-8 mb-6">
          <div className="flex flex-row space-x-8 h-full">
            {TAB_MENU?.map((tab: any, idx: number) => (
              <div
                className={cn(
                  "cursor-pointer font-semibold text-sm flex items-center",
                  {
                    "text-jll-color-text-base-default border-b border-jll-color-coldGray-7":
                      activeTab?.toString() === tab.value?.toString(),
                    "text-jll-color-coldGray-5":
                      activeTab?.toString() !== tab.value?.toString(),
                  }
                )}
                key={idx}
                onClick={handleTab(tab?.value)}
              >
                {tab?.label}
              </div>
            ))}
          </div>
        </div>
        <div className="flex flex-col h-full overflow-y-auto pl-8">
          {loading && !data?.length && <Loading />}
          {!loading && !data?.length && (
            <EmptyResult>
              <h3 className="text-heading3 text-jll-color-coldGray-5">
                Sorry, we didn't find any team listings.
              </h3>
            </EmptyResult>
          )}
          {!loading && !!data?.length && (
            <>
              <div className="flex space-x-4 pb-4">
                <div className="font-semibold text-jll-color-coldGray-7 text-sm">
                  {total} result{total > 1 ? "s" : ""}
                </div>
              </div>
              <div className="pr-8">
                {!!data?.length && (
                  <Table>
                    <Table.Thead>
                      <Table.Tr>
                        {LINL_TABLE_COLUMNS?.map((column, idx) => (
                          <Table.Th key={idx}>{column.label}</Table.Th>
                        ))}
                        <Table.Th />
                      </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody>
                      {data?.map((item: any, idx1: number) => (
                        <Table.Tr key={idx1}>
                          {LINL_TABLE_COLUMNS?.map((column, idx) => (
                            <Table.Td key={idx}>
                              <StyledTeamListingValue
                                listing={item}
                                valueKey={column.id}
                                meta={meta}
                              />
                            </Table.Td>
                          ))}
                          <Table.Td className="flex flex-row space-x-2">
                            <Button
                              variant="secondary"
                              size="small"
                              onClick={() => {
                                window.open(
                                  `${window.location.origin}/pub/tl/${teamListing?.slug}/${item?.uuid}`,
                                  "_blank"
                                );
                              }}
                            >
                              Link
                            </Button>
                            <Button
                              variant="secondary"
                              size="small"
                              onClick={() => {
                                setIsOpen(true);
                                setSelectedLink(item);
                              }}
                            >
                              Edit
                            </Button>
                            {item?.active_status === STATUS_ACTIVE ? (
                              <Button
                                variant="danger"
                                size="small"
                                leadingIconClass="text-jll-color-text-danger"
                                onClick={() => removeLink(item?.pk)}
                              >
                                Remove
                              </Button>
                            ) : (
                              <Button
                                variant="secondary"
                                size="small"
                                leadingIconClass="text-jll-color-text-danger"
                                onClick={() => reinstateLink(item?.pk)}
                              >
                                Reinstate
                              </Button>
                            )}
                          </Table.Td>
                        </Table.Tr>
                      ))}
                    </Table.Tbody>
                  </Table>
                )}

                {!!(total > data?.length) && (
                  <div
                    className="w-full py-2 flex justify-center items-center"
                    ref={sentryRef}
                  >
                    <Loading />
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>

      <TeamListingLinkModal
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        selectedLink={selectedLink}
      />
    </Layout>
  );
};

export default TeamListingLink;
