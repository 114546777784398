import { StyledCommentValueProps } from "ui-molecules";
export const FACET_MAX = 5;
export const SEARCH_RESULT_LIMIT = 10;

interface TdProps {
  className: string;
}

interface TableColumnProps {
  id: StyledCommentValueProps["valueKey"];
  label: string;
  sort?: string;
  td?: TdProps;
}

export const COMMENT_TABLE_COLUMNS: TableColumnProps[] = [
  {
    id: "building",
    label: "Property",
    sort: "asc",
  },
  {
    id: "address",
    label: "Address",
  },
  {
    id: "user",
    label: "User",
  },
  {
    id: "comment",
    label: "Comment",
  },
  {
    id: "update_timestamp",
    label: "Updated",
  },
];

export const MODAL_TABLE_COLUMNS = [
  {
    id: "property",
    label: "Property",
    sort: "asc",
  },
  {
    id: "address",
    label: "Address",
  },
  {
    id: "updated",
    label: "Updated",
  },
];
