import { SET_LOADING_MODAL, UPDATE_LIGHTGALLERY_DATA } from "constant";
import { GlobalContext } from "context";
import { useFormik } from "formik";
import { useApiCall } from "hooks";
import {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { renderToString } from "react-dom/server";
import { useDropzone } from "react-dropzone";
import { FiMaximize2, FiPlusCircle, FiTrash2, FiUpload } from "react-icons/fi";
import { useParams } from "react-router-dom";
import {
  convertImageUrl,
  getUsersAPI,
  postImageAPI,
  postTeamListingLinkAPI,
  putTeamListingLinkAPI,
} from "services";
import { Button, Input, Label, SelectAutoComplete, Toast } from "ui-atoms";
import { Card } from "ui-molecules";
import { convertExtension, getMetaOptions } from "utils";
import * as Yup from "yup";

interface ITeamListingLinkForm {
  isOpen: boolean;
  setIsOpen: any;
  selectedLink?: any;
  isAdd?: boolean;
  team_id?: string | number;
  setIsDisabled?: any;
}

const validationSchema = Yup.object().shape({
  title: Yup.string().required("This field is required"),
  description: Yup.string().optional().nullable(),
});

const TeamListingLinkForm = forwardRef(
  (
    {
      isOpen,
      setIsOpen,
      selectedLink,
      isAdd,
      team_id,
      setIsDisabled,
    }: ITeamListingLinkForm,
    ref
  ) => {
    const { state, dispatch } = useContext(GlobalContext);
    const { meta, lightGalleryRef } = state;
    const param = useParams();
    let teamId: string | number | undefined = undefined;
    if (isAdd) {
      teamId = team_id;
    } else {
      teamId = param?.teamId;
    }
    const [getUsers] = useApiCall(getUsersAPI);
    const [postTeamListingLink] = useApiCall(postTeamListingLinkAPI);
    const [putTeamListingLink] = useApiCall(putTeamListingLinkAPI);
    const [imageFiles, setImageFiles] = useState<any>([]);
    const resRef = useRef<any>(null);

    const {
      handleSubmit,
      handleChange,
      submitForm,
      handleBlur,
      setFieldValue,
      values,
      errors,
      touched,
      setValues,
      isValid,
      dirty,
    } = useFormik({
      initialValues: {
        title: "",
        description: "",
        authors: [],
        breakdown: undefined,
        images: [],
        breakdown_sort: undefined
      },
      validationSchema,
      onSubmit: async () => {
        try {
          dispatch({
            type: SET_LOADING_MODAL,
            payload: {
              open: true,
              label: "Updating the link",
            },
          });
          let results: any[] = [];
          if (!!imageFiles?.length) {
            await Promise.all(
              imageFiles?.map(async (file: any) => {
                const result = await postImageAPI(file);
                if (result) {
                  results.push(result);
                }
              })
            );
            setImageFiles([]);
          }
          const images = [...(values.images || []), ...results];
          setFieldValue("images", images);
          const author_users = [...(values.authors || [])]?.map(
            (item: any) => item?.value
          );
          let payload: any = {
            title: values?.title,
            author_users,
            description: values?.description,
            images,
            breakdown: values?.breakdown,
            breakdown_sort: values?.breakdown_sort,
            team_listing: teamId,
          };
          let res: any;
          if (selectedLink) {
            payload = {
              ...payload,
              pk: selectedLink?.pk || selectedLink?.id,
            };
            res = await putTeamListingLink(payload);
          } else res = await postTeamListingLink(payload);
          resRef.current = res;
          setTimeout(() => {
            dispatch({
              type: SET_LOADING_MODAL,
              payload: {
                open: false,
                label: null,
              },
            });
          }, 100);
          if (!res) return;
          Toast.success("Updated the link successfully");
          setIsOpen(false);
        } catch (err) {
          setTimeout(() => {
            dispatch({
              type: SET_LOADING_MODAL,
              payload: {
                open: false,
                label: null,
              },
            });
          }, 100);
          setIsOpen(false);
        }
      },
    });

    useEffect(() => {
      if (!isOpen) return;
      if (selectedLink) {
        let authors = [];
        if (!!selectedLink?.authors?.length || selectedLink?.author?.length)
          authors = (selectedLink?.authors || selectedLink?.author)?.map(
            (author: any) => {
              return {
                value: author?.pk || author?.user || author?.id,
                label: author?.get_full_name
                  ? author?.get_full_name
                  : author?.user_first_name
                  ? [author?.user_first_name, author?.user_last_name]?.join(" ")
                  : author?.updated_user_first_name
                  ? [
                      author?.user_updated_first_name,
                      author?.user_updated_last_name,
                    ]?.join(" ")
                  : author?.email,
              };
            }
          );
        setValues({
          ...selectedLink,
          authors,
          team_listing_name:
            selectedLink?.name || selectedLink?.team_listing_name,
        });
      } else {
        setValues({
          title: "",
          description: "",
          authors: [],
          breakdown: undefined,
          images: [],
          breakdown_sort: undefined
        });
      }
    }, [selectedLink, isOpen]);

    useEffect(() => {
      if (!setIsDisabled) return;
      setIsDisabled(!isValid || !dirty);
    }, [isValid, dirty]);

    useImperativeHandle(ref, () => ({
      submitForm,
      getLinkData() {
        return resRef.current;
      },
    }));

    const onDrop = (acceptedFiles: any[]) => {
      setImageFiles([...imageFiles, ...acceptedFiles]);
    };

    const { getRootProps, getInputProps } = useDropzone({
      maxFiles: 5,
      onDrop,
      multiple: true,
      accept: {
        "image/*": [],
      },
    });

    const loadUserOptions = async (keyword: string) => {
      let authors: any[] = [];
      if (!!selectedLink?.authors?.length)
        authors = selectedLink?.authors?.map((author: any) => author?.id);
      return getUsers({ keyword: keyword, user: authors }).then((res: any) => {
        return res.docs.map((user: any) => {
          return {
            value: user.pk,
            label:
              user?.first_name || user?.last_name
                ? [user?.first_name, user?.last_name].join(" ")
                : user?.email,
          };
        });
      });
    };

    const handleOpenGallery = async (name: string, index: number) => {
      let arr: any = [];
      values?.images?.forEach((url: string, idx: number) => {
        arr.push({
          id: idx,
          src: convertImageUrl(convertExtension(url)),
          thumb: convertImageUrl(convertExtension(url)),
          subHtml: renderToString(<span>{idx + 1}</span>),
        });
      });
      imageFiles?.forEach((file: any, idx: number) => {
        arr.push({
          id: idx,
          src: URL.createObjectURL(file),
          thumb: URL.createObjectURL(file),
          subHtml: renderToString(
            <span>{Number(values?.images?.length) + idx + 1}</span>
          ),
        });
      });
      await dispatch({
        type: UPDATE_LIGHTGALLERY_DATA,
        payload: arr,
      });
      setTimeout(function () {
        lightGalleryRef.current.openGallery(index);
      }, 100);
    };

    const removeValue = async (name: string, index: number) => {
      if (!!name?.length) {
        const new_images = [...values.images];
        new_images.splice(index, 1);
        setFieldValue("images", new_images);
      } else {
        const new_images = [...imageFiles];
        new_images.splice(index, 1);
        setImageFiles(new_images);
      }
    };

    return (
      <form onSubmit={handleSubmit}>
        <div className="flex flex-col space-y-4 mb-6">
          <div className="flex flex-row space-x-2">
            <Label className="w-5/12 flex flex-row items-center">Header</Label>
            <div className="w-full p-1">
              <Input
                name="title"
                className="w-full !mb-0"
                placeholder='Title of the deliverable. Ex: "Austin Market Overview"'
                value={values.title}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.title ? errors.title : ""}
              />
            </div>
          </div>

          <div className="flex flex-row space-x-2">
            <Label className="w-5/12 flex flex-row items-center" optional>
              Description
            </Label>
            <div className="w-full p-1">
              <Input
                name="description"
                className="w-full !mb-0"
                placeholder='Ex: "Q4 2023 JLL Listings by submarket in Austin, TX"'
                value={values.description}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.description ? errors.description : ""}
              />
            </div>
          </div>

          <div className="flex flex-row space-x-2">
            <Label className="w-5/12 flex flex-row items-center">
              Contacts
            </Label>
            <div className="w-full p-1">
              <SelectAutoComplete
                name="authors"
                placeholder="Brokers listed on the deliverable"
                className="w-full !mb-0"
                isMulti
                onChange={(value) => {
                  setFieldValue("authors", value);
                }}
                onBlur={handleBlur}
                value={values?.authors || []}
                error={touched.authors ? errors.authors : ""}
                loadOptions={loadUserOptions}
              />
            </div>
          </div>

          <div className="flex flex-row space-x-2 mb-8">
            <Label className="w-5/12 flex flex-row items-center">
              Property Breakdown
            </Label>
            <div className="w-full p-1">
              <SelectAutoComplete
                name="breakdown"
                placeholder="Group listings by Submarket, Type, etc"
                className="w-full !mb-0"
                onChange={(option) => {
                  if (!option) setFieldValue("breakdown", null);
                  else setFieldValue("breakdown", option.value);
                }}
                onBlur={handleBlur}
                value={
                  values?.breakdown
                    ? {
                        value: values?.breakdown,
                        label:
                          meta?.teamlistinglink?.breakdown[values?.breakdown]
                            ?.value,
                      }
                    : undefined
                }
                error={touched.breakdown ? errors.breakdown : ""}
                options={getMetaOptions(meta?.teamlistinglink?.breakdown)}
              />
            </div>
          </div>

          <div className="flex flex-row space-x-2 mb-8">
            <Label className="w-5/12 flex flex-row items-center">
              Property Breakdown Sort
            </Label>
            <div className="w-full p-1">
              <SelectAutoComplete
                name="breakdown_sort"
                placeholder="Property Breakdown Sort"
                className="w-full !mb-0"
                onChange={(option) => {
                  if (!option) setFieldValue("breakdown_sort", null);
                  else setFieldValue("breakdown_sort", option.value);
                }}
                onBlur={handleBlur}
                value={
                  values?.breakdown_sort
                    ? {
                        value: values?.breakdown_sort,
                        label:
                          meta?.teamlistinglink?.breakdown_sort[values?.breakdown_sort]
                            ?.value,
                      }
                    : undefined
                }
                error={touched.breakdown_sort ? errors.breakdown_sort : ""}
                options={getMetaOptions(meta?.teamlistinglink?.breakdown_sort)}
              />
            </div>
          </div>

          <div>
            <div
              {...getRootProps({
                className:
                  "w-full flex h-full cursor-pointer bg-white border border-dashed border-jll-color-coldGray-3 rounded justify-center items-center py-2 px-10",
              })}
            >
              <input {...getInputProps()} />
              <div className="flex items-center flex-col">
                <div className="flex flex-row items-center text-jll-color-text-base-default text-xs font-semibold">
                  <FiUpload className="w-3 h-3 mr-1" />
                  Drag and drop files here or upload
                </div>
                <p className="text-jll-color-coldGray-4 text-xs">
                  Accepted file types: JPEG, Doc, PDF, PNG
                </p>
              </div>
            </div>
          </div>

          <div className="flex flex-row flex-wrap">
            {!!values.images?.length &&
              values.images?.map((img, idx) => (
                <Card
                  className="w-36 h-36 mb-2 mr-2"
                  key={idx}
                  src={img}
                  actions={[
                    <Button
                      variant="secondary"
                      leadingIcon={FiMaximize2}
                      onClick={() => handleOpenGallery("images", idx)}
                    />,
                    <Button
                      variant="secondary"
                      leadingIcon={FiTrash2}
                      onClick={() => removeValue("images", idx)}
                    />,
                  ]}
                />
              ))}
            {!!imageFiles?.length &&
              imageFiles?.map((img: any, idx: number) => (
                <Card
                  className="w-36 h-36 mb-2 mr-2"
                  key={idx}
                  src={URL.createObjectURL(img)}
                  actions={[
                    <Button
                      variant="secondary"
                      leadingIcon={FiMaximize2}
                      onClick={() => handleOpenGallery("", idx)}
                    />,
                    <Button
                      variant="secondary"
                      leadingIcon={FiTrash2}
                      onClick={() => removeValue("", idx)}
                    />,
                  ]}
                />
              ))}
          </div>
        </div>

        {!isAdd && (
          <Button
            variant="primary"
            leadingIcon={FiPlusCircle}
            className="w-full justify-center"
            type="submit"
            disabled={!isValid || !dirty}
          >
            {`${selectedLink ? "Update" : "Add"} Link`}
          </Button>
        )}
      </form>
    );
  }
);

export default TeamListingLinkForm;
