import { Route, Routes, useLocation } from "react-router-dom";
import {
  Activity,
  Availabilities,
  Brokers,
  Comments,
  ManageUsers,
  Properties,
  PropertyAvailabilities,
  PropertyActivity,
  PropertyAdmin,
  TeamListings,
  AvailabilityActivity,
  AuthHomepage,
  PropertyTask,
  Tasks,
  Reports,
  Property,
  Availability,
  TeamListingProperty,
  TeamListingLink,
  DeliverablePage,
} from "pages";
import { URLS } from "constant";
import UI from "pages/UI";
import { LoginCallback } from "@okta/okta-react";
import { StatusError } from "pages";
import { useContext, useEffect } from "react";
import { GlobalContext } from "context";
import { RequiredAuth } from "./RequiredAuth";

const RootRoutes = () => {
  const { state } = useContext(GlobalContext);
  const { hideTooltip } = state;

  const location = useLocation();

  useEffect(() => {
    if (hideTooltip) hideTooltip();
  }, [location]);

  return (
    <Routes>
      <Route path="login/callback" element={<LoginCallback />} />
      <Route path="/ui" element={<UI />} />
      <Route path="/pub/tl/:title/:uuid" element={<DeliverablePage />} />
      <Route path="/" element={<RequiredAuth />}>
        <Route path="/" element={<AuthHomepage />} />
        <Route path={`/${URLS.ACTIVITY}`} element={<Activity />} />
        <Route path={`/${URLS.COMMENTS}`} element={<Comments />} />
        <Route path={`/${URLS.PROPERTIES}`} element={<Properties />} />
        <Route path={`/${URLS.MANAGE_USERS}`} element={<ManageUsers />} />
        <Route path={`/${URLS.BROKERS}`} element={<Brokers />} />
        <Route path={`/${URLS.TEAM_LISTINGS}`} element={<TeamListings />} />
        <Route path={`/${URLS.TASKS}`} element={<Tasks />} />
        <Route
          path={`/property/:propertyId?/${URLS.PROPERTY.AVAILABILITIES}`}
          element={<PropertyAvailabilities />}
        />
        <Route
          path={`/property/:propertyId?/${URLS.PROPERTY.FORM}`}
          element={<Property />}
        />
        <Route
          path={`/property/:propertyId?/${URLS.PROPERTY.ACTIVITY}`}
          element={<PropertyActivity />}
        />
        <Route
          path={`/property/:propertyId?/${URLS.PROPERTY.TASKS}`}
          element={<PropertyTask />}
        />
        <Route
          path={`/property/:propertyId?/${URLS.PROPERTY.ADMIN}`}
          element={<PropertyAdmin />}
        />
        <Route path={`/${URLS.AVAILABILITIES}`} element={<Availabilities />} />
        <Route
          path={`/property/:propertyId?/availability/:availabilityId?/${URLS.AVAILABILITY.FORM}`}
          element={<Availability />}
        />
        <Route
          path={`/property/:propertyId?/availability/:availabilityId?/${URLS.AVAILABILITY.ACTIVITY}`}
          element={<AvailabilityActivity />}
        />
        <Route path={`/${URLS.BROKERS}`} element={<Brokers />} />
        <Route path={`/${URLS.TEAM_LISTINGS}`} element={<TeamListings />} />
        <Route path={`/${URLS.REPORTS}`} element={<Reports />} />
        <Route
          path={`/team-listing/:teamId/${URLS.TEAM_LISTING.PROPERTIES}`}
          element={<TeamListingProperty />}
        />
        <Route
          path={`/team-listing/:teamId/${URLS.TEAM_LISTING.PAGES}`}
          element={<TeamListingLink />}
        />
      </Route>
      <Route path={`/${URLS.ERROR}`} element={<StatusError />} />
      <Route path="*" element={<StatusError statusCode={404} />} />
    </Routes>
  );
};

export default RootRoutes;
