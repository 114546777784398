import queryString from "query-string";
import axios from "../axiosConfig";
import { APIProps } from "types";
import { API_PREFIX_URL } from "constant";

export const getTeamListingsAPI = async (props: APIProps) => {
  const param_str = queryString.stringify(props, {
    arrayFormat: "bracket",
  });
  const res = await axios.get(`${API_PREFIX_URL}/team-listing/?${param_str}`);
  return res;
};

export const getTeamListingAPI = async (props: any) => {
  const pk = props?.pk;
  delete props?.pk;
  const param_str = queryString.stringify(props, {
    arrayFormat: "bracket",
  });
  const res = await axios.get(`${API_PREFIX_URL}/team-listing/${pk}/?${param_str}`);
  return res;
};

export const postTeamListingAPI = async (payload: any) => {
  const res = await axios.post(`${API_PREFIX_URL}/team-listing/`, payload);
  return res;
};

export const putTeamListingAPI = async (payload: any) => {
  const pk = payload?.pk;
  delete payload?.pk;
  const res = await axios.put(`${API_PREFIX_URL}/team-listing/${pk}/`, payload);
  return res;
};

export const patchTeamListingAPI = async (payload: any) => {
  const pk = payload?.pk;
  delete payload?.pk;
  const res = await axios.patch(`${API_PREFIX_URL}/team-listing/${pk}/`, payload);
  return res;
};

export const patchTeamListingOrderAPI = async (payload: any) => {
  const pk = payload?.pk;
  delete payload?.pk;
  const res = await axios.patch(`${API_PREFIX_URL}/team-listing/${pk}/`, payload);
  return res;
};

export const postTeamListingBuildingAPI = async (payload: any) => {
  const res = await axios.post(`${API_PREFIX_URL}/team-listing/building/`, payload);
  return res;
};

export const postTeamListingBuildingBulkAPI = async (payload: any) => {
  const res = await axios.post(`${API_PREFIX_URL}/team-listing/building-bulk/`, payload);
  return res;
};

export const deleteTeamListingBuildingAPI = async (pk: string | number) => {
  const res = await axios.delete(`${API_PREFIX_URL}/team-listing/building/${pk}/`);
  return res;
};

export const getTeamListingLinkAPI = async (props: APIProps) => {
  const param_str = queryString.stringify(props, {
    arrayFormat: "bracket",
  });
  const res = await axios.get(`${API_PREFIX_URL}/team-listing/link/?${param_str}`);
  return res;
};

export const postTeamListingLinkAPI = async (payload: any) => {
  const res = await axios.post(`${API_PREFIX_URL}/team-listing/link/`, payload);
  return res;
};

export const putTeamListingLinkAPI = async (payload: any) => {
  const pk = payload?.pk;
  delete payload?.pk;
  const res = await axios.put(`${API_PREFIX_URL}/team-listing/link/${pk}/`, payload);
  return res;
};

export const deleteTeamListingLinkAPI = async (pk: string | number) => {
  const res = await axios.delete(`${API_PREFIX_URL}/team-listing/link/${pk}/`);
  return res;
};

export const patchTeamListingLinkAPI = async (payload: any) => {
  const pk = payload?.pk;
  delete payload?.pk;
  const res = await axios.patch(`${API_PREFIX_URL}/team-listing/link/${pk}/`, payload);
  return res;
};
