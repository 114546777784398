import { useEffect, useState } from "react";
import {
  FiAlertTriangle,
  FiChevronLeft,
  FiXCircle,
  FiZapOff,
} from "react-icons/fi";
import queryString from "query-string";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "ui-atoms";
import { Navbar } from "ui-molecules";
import { LayoutBase } from "ui-organisms";

interface IStatusError {
  statusCode?: number;
}

const StatusError: React.FC<IStatusError> = ({ statusCode }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [code, setCode] = useState(Number(statusCode) || null);

  useEffect(() => {
    const query = queryString.parse(location?.search, {
      arrayFormat: "bracket",
    });
    if (query?.status) {
      setCode(Number(query?.status));
    }
  }, [location]);

  let ErrorIcon;
  let title = "";
  let description = "";
  switch (code) {
    case 403:
      ErrorIcon = FiXCircle;
      title = "Permission Denied";
      description = "Check your access permissions and try again";
      break;
    case 404:
      ErrorIcon = FiAlertTriangle;
      title = "Not Found";
      description = "Try to change your link or go back";
      break;
    default:
      ErrorIcon = FiZapOff;
      title = "Server Error";
      description = "Try to reload page or try bit later";
  }
  return (
    <LayoutBase>
      <Navbar />
      <div className="fixed top-[64px] left-0 bottom-0 right-0 flex justify-center items-center flex-col">
        <ErrorIcon className="w-8 h-8 text-jll-color-coldGray-4 mb-3" />
        <h2 className="font-black text-[76px] leading-[84px] text-jll-color-coldGray-3">
          {code}
        </h2>
        <div className="flex items-center justify-center flex-col mb-[26px]">
          <p className="font-bold text-base text-jll-color-coldGray-5">
            {title}
          </p>
          <p className="font-bold text-sm text-jll-color-text-base-subdued">
            {description}
          </p>
        </div>
        <Button
          variant="primary"
          leadingIcon={FiChevronLeft}
          onClick={() => navigate("/")}
        >
          Back Home
        </Button>
      </div>
    </LayoutBase>
  );
};

export default StatusError;
