import type { StyledActivityValueProps } from "ui-molecules";
export const FACET_MAX = 5;
export const SEARCH_RESULT_LIMIT = 10;

interface TdProps {
  className: string;
}

interface TableColumnProps {
  id: StyledActivityValueProps["valueKey"];
  label: string;
  sort?: string;
  td?: TdProps;
}

export const TABLE_COLUMNS: TableColumnProps[] = [
  {
    id: "type",
    label: "Type",
  },
  {
    id: "update_timestamp",
    label: "Updated",
  },
];

export const MODAL_TABLE_COLUMNS = [
  {
    id: "property",
    label: "Property",
    sort: "asc",
  },
  {
    id: "address",
    label: "Address",
  },
  {
    id: "update_timestamp",
    label: "Updated",
  },
];
