import { LazyLoadImage } from "react-lazy-load-image-component";
import { AvailabilityProps, PropertyProps } from "types";
import { Chip, Pointer, ZoomButton } from "ui-atoms";
import { AccordionItem, GoogleMap, ImageHoverEffect } from "ui-molecules";
import { convertExtension, lazyLoadImageOnError } from "utils";
import "./style.css";
import BuildingInformation from "./BuildingInformation";
import SuiteInformation from "./SuiteInformation";
import { useContext } from "react";
import { GlobalContext } from "context";
import { convertImageUrl } from "services";
import { renderToString } from "react-dom/server";
import { UPDATE_LIGHTGALLERY_DATA } from "constant";
import cn from "classnames";

interface IDeliverableDetailPane {
  building?: PropertyProps;
  suites?: AvailabilityProps[] | undefined;
  index?: number;
}

const DeliverableDetailPane: React.FC<IDeliverableDetailPane> = ({
  building,
  suites,
  index,
}) => {
  const { state, dispatch } = useContext(GlobalContext);
  const { lightGalleryRef } = state;

  const handleOpenGallery = async (
    name: keyof PropertyProps,
    index: number
  ) => {
    if (building?.[name]?.[index]?.includes(".pdf")) {
      window.open(building?.[name]?.[index], "_blank");
      return;
    }
    let arr: any = [];
    building?.[name]?.forEach((url: string, idx: number) => {
      arr.push({
        id: idx,
        src: convertImageUrl(convertExtension(url)),
        thumb: convertImageUrl(convertExtension(url)),
        subHtml: renderToString(<span>{idx + 1}</span>),
      });
    });
    await dispatch({
      type: UPDATE_LIGHTGALLERY_DATA,
      payload: arr,
    });
    setTimeout(function () {
      lightGalleryRef.current.openGallery(index);
    }, 100);
  };

  return (
    <div className="flex flex-row h-full">
      <div className="flex flex-col h-full w-full md:overflow-hidden overflow-y-auto px-5 md:px-0">
        <div className="flex md:flex-row flex-col justify-between md:items-center h-fit my-4 w-full md:pl-2 md:pr-4">
          <div className="flex flex-row items-center space-x-4 md:mb-0 mb-4">
            <Pointer index={index} />
            <div>
              <p className="font-semibold md:text-3xl text-2xl text-jll-color-text-base-default">
                {building?.title}
              </p>
              <p className="text-jll-color-text-base-subdued">
                {[
                  building?.address,
                  building?.city,
                  building?.state,
                  building?.post_code,
                ].join(", ")}
              </p>
            </div>
          </div>
          <div className="flex h-fit items-center">
            <Chip variant="primary">{building?.property_type}</Chip>
          </div>
        </div>

        {!!building?.images?.length && (
          <div className="md:hidden flex w-full h-[300px] space-x-2 mb-6">
            {building?.images?.length > 1 && (
              <div
                className={cn("h-[300px] flex flex-col space-y-2", {
                  "w-6/12":
                    building?.images?.length === 2 ||
                    building?.images?.length === 3,
                  "w-4/12": building?.images?.length === 4,
                  "w-3/12": building?.images?.length >= 5,
                })}
              >
                {building?.images?.slice(1, 5)?.map((image, idx) => (
                  <div className="flex w-full h-full overflow-hidden" key={idx}>
                    <a
                      href="#"
                      className="w-full h-full"
                      onClick={() => handleOpenGallery("images", idx + 1)}
                    >
                      <ImageHoverEffect className="w-full h-full rounded">
                        <LazyLoadImage
                          src={convertImageUrl(
                            convertExtension(building?.images[idx + 1])
                          )}
                          className="w-full h-full object-cover rounded"
                          onError={lazyLoadImageOnError}
                        />
                      </ImageHoverEffect>
                    </a>
                  </div>
                ))}
              </div>
            )}
            <div
              className={cn("h-[300px]", {
                "w-full": building?.images?.length === 1,
                "w-6/12":
                  building?.images?.length === 2 ||
                  building?.images?.length === 3,
                "w-8/12": building?.images?.length === 4,
                "w-9/12": building?.images?.length >= 5,
              })}
            >
              <div className="rounded cursor-pointer h-full">
                <div
                  className="relative rounded h-full"
                  onClick={() => handleOpenGallery("images", 0)}
                >
                  <ImageHoverEffect className={"w-full h-full rounded"}>
                    <LazyLoadImage
                      src={convertImageUrl(
                        convertExtension(building?.images?.[0])
                      )}
                      className={"w-full h-full object-cover rounded"}
                      onError={lazyLoadImageOnError}
                    />
                  </ImageHoverEffect>
                  <div className="absolute top-[16px] right-[16px]">
                    <ZoomButton
                      className="w-[44px] h-[44px] bg-white shadow-lg text-gray-606"
                      onClick={() => {}}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="flex flex-col h-full md:overflow-hidden relative">
          <div className="md:overflow-y-auto md:pr-2 absolute top-0 bottom-0 left-0 right-0">
            <AccordionItem
              id="building-info"
              label="Building Information"
              labelChildClass="border-none md:bg-jll-color-coldGray-1 py-4 md:px-4 text-xl text-jll-color-text-base-default"
              className="mb-2"
              tabContentChildClass="md:px-4 md:!bg-jll-color-coldGray-1"
            >
              <BuildingInformation building={building} />
            </AccordionItem>

            {!!suites?.length &&
              suites?.map((suite, idx) => (
                <AccordionItem
                  id={`suite-info-${idx}`}
                  label={`Suite ${suite?.name || ""}`}
                  key={idx}
                  labelChildClass="border-none md:bg-jll-color-coldGray-1 py-4 md:px-4 text-xl text-jll-color-text-base-default"
                  className="mb-2"
                  tabContentChildClass="md:px-4 md:!bg-jll-color-coldGray-1"
                >
                  <SuiteInformation suite={suite} />
                </AccordionItem>
              ))}
          </div>
        </div>
      </div>

      <div className="md:w-[450px] w-full pl-2 md:flex hidden">
        <div
          className={`md:grid md:gap-2 ${
            building?.images?.length === 1 ? "md:grid-rows-3" : "md:grid-rows-2"
          }`}
        >
          <div
            className={`${
              building?.images?.length === 1 ? "md:row-span-2" : "md:row-span-1"
            }`}
          >
            {building?.images && building?.images?.length !== 0 && (
              <div className="rounded cursor-pointer h-full">
                <div
                  className="relative rounded h-full"
                  onClick={() => handleOpenGallery("images", 0)}
                >
                  <ImageHoverEffect className={"w-full h-full rounded"}>
                    <LazyLoadImage
                      src={convertImageUrl(
                        convertExtension(building?.images?.[0])
                      )}
                      className={"w-full h-full object-cover rounded"}
                      onError={lazyLoadImageOnError}
                    />
                  </ImageHoverEffect>
                  <div className="absolute top-[16px] right-[16px]">
                    <ZoomButton
                      className="w-[44px] h-[44px] bg-white shadow-lg text-gray-606"
                      onClick={() => {}}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="md:row-span-1">
            <div className="md:grid md:grid-rows-3 md:gap-2 h-full">
              <div className="md:row-span-2 rounded truncate relative">
                <GoogleMap
                  className="h-full"
                  position={{
                    lat: Number(building?.latitude) || 0,
                    lng: Number(building?.longitude) || 0,
                  }}
                />
              </div>
              <div className="md:row-span-1">
                {!!building?.images && building?.images?.length >= 3 && (
                  <div className="flex h-full">
                    {building?.images?.map((buildingImage, idx) => {
                      if (idx > 0 && idx < 3)
                        return (
                          <div
                            className={`w-6/12 h-full ${idx === 1 && "pr-1"} ${
                              idx === 2 && "pl-1"
                            }`}
                            key={idx}
                          >
                            <a
                              href="#"
                              onClick={() => handleOpenGallery("images", idx)}
                            >
                              <ImageHoverEffect className="w-full h-full rounded">
                                <LazyLoadImage
                                  src={convertImageUrl(
                                    convertExtension(buildingImage)
                                  )}
                                  className="w-full h-full object-cover rounded"
                                  onError={lazyLoadImageOnError}
                                />
                              </ImageHoverEffect>
                            </a>
                          </div>
                        );
                    })}
                  </div>
                )}
                {building?.images?.length === 2 && (
                  <div className="w-full h-full mb-2">
                    <a href="#" onClick={() => handleOpenGallery("images", 1)}>
                      <ImageHoverEffect className="w-full h-full rounded">
                        <LazyLoadImage
                          src={convertImageUrl(
                            convertExtension(building?.images[1])
                          )}
                          className="w-full h-full object-cover rounded"
                          onError={lazyLoadImageOnError}
                        />
                      </ImageHoverEffect>
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeliverableDetailPane;
