export const NEW_ID = "new";
export const SET_META = "SET_META";
export const SET_PAGE_ACTIVITIES_FILTERS = "SET_PAGE_ACTIVITIES_FILTERS";
export const SET_PAGE_AVAILABILITIES_FILTERS =
  "SET_PAGE_AVAILABILITIES_FILTERS";
export const SET_PAGE_PROPERTIES_FILTERS = "SET_PAGE_PROPERTIES_FILTERS";
export const SET_PAGE_BROKERS_FILTERS = "SET_PAGE_BROKERS_FILTERS";
export const SET_PAGE_USERS_FILTERS = "SET_PAGE_USERS_FILTERS";
export const SET_TOOLTIP = "SET_TOOLTIP";
export const SET_PROPERTIES = "SET_PROPERTIES";
export const SET_PROPERTY = "SET_PROPERTY";
export const SET_AVAILABILITIES = "SET_AVAILABILITIES";
export const SET_AVAILABILITY = "SET_AVAILABILITY";
export const SET_BROKERS = "SET_BROKERS";
export const CREATE_LIGHTGALLERY = "CREATE_LIGHTGALLERY";
export const UPDATE_LIGHTGALLERY_DATA = "UPDATE_LIGHTGALLERY_DATA";
export const SET_ERROR_MODAL = "SET_ERROR_MODAL";
export const SET_CONFIRM_MODAL = "SET_CONFIRM_MODAL";
export const SET_LOADING_MODAL = "SET_LOADING_MODAL";
export const SET_PERMISSION_MODAL = "SET_PERMISSION_MODAL";
export const SET_RIGHT_CONTEXT = "SET_RIGHT_CONTEXT";
export const SET_EVENT_MODAL = "SET_EVENT_MODAL";
export const SET_PROPERTY_REMOVE_CONFIRM_MODAL = "SET_PROPERTY_REMOVE_CONFIRM_MODAL";
export const SET_INLINE_EDIT_SELECT_MODAL="SET_INLINE_EDIT_SELECT_MODAL";
export const SET_INLINE_EDIT_HIGHLIGHT_MODAL="SET_INLINE_EDIT_HIGHLIGHT_MODAL";
export const SET_INLINE_EDIT_AMENITY_MODAL="SET_INLINE_EDIT_AMENITY_MODAL";
export const SET_PORTAL="SET_PORTAL";
export const SET_PROPERTY_VERSION="SET_PROPERTY_VERSION";
export const SET_TEAM_LISTING = "SET_TEAM_LISTING";
export const SET_TEAM_LISTING_LINK = "SET_TEAM_LISTING_LINK";

export const PUBLISH_STATUS_PUBLISHED = 1;
export const PUBLISH_STATUS_DRAFT = 2;
export const PUBLISH_STATUS_REMOVED = 4;

export const STATUS_ACTIVE = 1;
export const STATUS_REMOVED = 2;

export const HTTP_ERROR_UNAUTHORIZED = 401;
export const HTTP_ERROR_BAD_REQUEST = 400;
export const HTTP_ERROR_WRITE_ACCESS = 403;

export const TASK_NOT_STARTED = 1;
export const TASK_IN_PROGRESS = 2;
export const TASK_COMPLETED = 3;
export const TASK_NOT_NEEDED = 4;

export const MAX_ONCE_FILE_LIMIT = 10;

// Event
export const EVENT_PUBLISHED = 1;
export const EVENT_UNPUBLISHED_PROPERTY = 4;
export const EVENT_IMPORT_FROM_MARKETSPHERE = 5;
export const EVENT_ADD_TO_MYLISTINGS = 6;
export const EVENT_REMOVED_INACTIVE_PROPERTY = 7;
export const EVENT_REMOVED_INACTIVE_AVAILABILITY = 8;
export const EVENT_UNPUBLISHED_AVAILABILITY = 9;
export const EVENT_REINSTATED_AVAILABILITY = 10;

export const URLS = {
  HOMEPAGE: "home",
  PROPERTIES: "properties",
  AVAILABILITIES: "availabilities",
  BROKERS: "brokers",
  ACTIVITY: "activity",
  COMMENTS: "comments",
  TASKS: "tasks",
  TEAM_LISTINGS: "team-listings",
  MANAGE_USERS: "manage-users",
  ERROR: "error",
  REPORTS: "reports",
  PROPERTY: {
    FORM: "form",
    AVAILABILITIES: "availabilities",
    DETAILS: "details",
    ACTIVITY: "activity",
    ADMIN: "admin",
    TASKS: "task",
  },
  AVAILABILITY: {
    FORM: "form",
    ACTIVITY: "activity",
  },
  TEAM_LISTING: {
    INDEX: 'team-listing',
    PROPERTIES: 'properties',
    PAGES: 'pages'
  }
};

export const STATUS_CONDITIONS = {
  [`${PUBLISH_STATUS_PUBLISHED}`]: {
    variant: "success",
    label: "Published",
  },
  [`${PUBLISH_STATUS_DRAFT}`]: {
    variant: "secondary",
    label: "Unpublished",
  },
  [`${PUBLISH_STATUS_REMOVED}`]: {
    variant: "secondary",
    label: "Unpublished",
  },
};

export const USER_GROUP_LEADERSHIP = 4;

export * from "./facets";
export * from "./api";
export * from "./availabilities";
export * from "./brokers";
export * from "./properties";
export * from "./users";
export * from "./activity";
export * from "./regex";
export * from "./helmet";
export * from "./editableTableDataType";
export * from "./editor";
export * from "./tasks";
export * from "./report";
export * from "./pdf";
export * from "./contracts";
export * from "./home";
export * from "./form";
export * from "./event";
export * from "./validationSchema"
