const styles: any = {
  page: {
    backgroundColor: 'white',
    fontFamily: 'Source Sans Pro',
    color: 'white',
    display: 'block',
    position: 'relative',
    width: 842,
    height: 595
  },
  view: {
    width: '100%',
    height: '100%',
    padding: 0,
  },
  image: {
    objectFit: 'cover',
    width: '100%',
    height: '100%',
  },
  blurImg: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0
  },
  coverImg: {
    position: 'absolute',
    top: 77,
    left: 77,
    height: 35,
    width: 78
  },
  txtView: {
    fontWeight: 600,
    position: 'absolute',
    color: 'white',
    top: 156,
    left: 77,
    maxWidth: 400
  },
  surveyType: {
    fontSize: 58,
    fontWeight: 800,
    color: 'white',
    marginBottom: 16,
    fontFamily: 'Montserrat',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    lineHeight: '96%'
  },
  address: {
    fontSize: 14,
    marginBottom: 22,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    lineHeight: '96%'
  },
  tenantName: {
    fontSize: 22,
  },
  brokerView: {
    position: 'absolute',
    left: 77,
    top: 400,
    display: 'flex',
    flexDirection: 'column'
  },
  representTxt: {
    fontSize: 14,
    fontWeight: 600,
    color: '#FFFFFF',
    opacity: 0.5,
    marginBottom: 12
  },
  brokerItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 14
  },
  brokerImg: {
    width: 40,
    height: 40,
    marginRight: 10,
    backgroundColor: 'white',
    objectFit: 'contain',
    borderRadius: 20
  },
  brokerContent: {
    display: 'flex',
    flexDirection: 'column',
    color: '#FFFFFF'
  },
  brokerName: {
    fontSize: 14,
    fontWeight: 600,
  },
  brokerEmail: {
    fontSize: 10,
  }
};

export default styles;
