import { Helmet } from "react-helmet-async";
import { META_TAGS } from "constant";
import { FaChartBar } from "react-icons/fa";
import { FiMail, FiCalendar, FiMaximize2, FiTrash2 } from "react-icons/fi";
import {
  Avatar,
  Button,
  Chip,
  Input,
  Textarea,
  Checkbox,
  Radio,
  Select,
  Switch,
  Toast,
  SelectAutoComplete,
} from "ui-atoms";
import { AvatarGroup, Table, Card } from "ui-molecules";

const Designsystem = () => {
  const selectData = [
    {
      value: 1234,
      label: "Ryond Ma",
    },
    {
      value: 345624,
      label: "Jerome D.Soucy",
    },
    {
      value: 23452,
      label: "Briggs Thompson",
    },
  ];

  const tableData = [
    {
      firstname: "Ryong",
      lastname: "Ma",
      role: "Dev",
    },
    {
      firstname: "Jerome",
      lastname: "D.Soucy",
      role: "Dev",
    },
    {
      firstname: "Briggs",
      lastname: "Thompson",
      role: "Dev",
    },
  ];

  return (
    <div className="bg-jll-color-coldGray-1 py-8">
      <Helmet prioritizeSeoTags>
        <title>{META_TAGS?.ui?.title}</title>
        <meta property="og:title" content={META_TAGS?.ui?.title} />
      </Helmet>
      <div className="bg-jll-color-surface-base-default rounded max-w-[900px] mx-auto p-4">
        <h1 className="text-heading1">Design system</h1>
        <section className="mt-6">
          <h3 className="text-heading2 mb-2 font-bold">Avatar</h3>
          <div className="space-x-2">
            <Avatar
              alt=""
              size="small"
              src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
            />
            <Avatar
              alt=""
              size="default"
              src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
            />
            <Avatar
              alt=""
              size="large"
              src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
            />
          </div>
        </section>
        <section className="mt-6">
          <h3 className="text-heading2 mb-2 font-bold">Avatar Group</h3>
          <div className="space-x-2">
            <AvatarGroup
              max={3}
              brokers={[
                {
                  id: "Broker994792",
                  update_timestamp: "2023-02-15T16:07:16.837000Z",
                  create_timestamp: "2023-02-14T23:11:08.932000Z",
                  document_type: "Broker",
                  pk: 994792,
                  timestamp: "2023-02-15T17:14:07.520000Z",
                  _version_: 1757918000603725828,
                  city: "Birmingham",
                  department: ["Retail"],
                  email: "clint.isom@jll.com",
                  job_title: "Associate- Retail",
                  linkedin: "https://www.linkedin.com/in/clint-isom/",
                  market: "us",
                  name: "Clint Isom",
                  office: "Birmingham",
                  page_url: "clint-isom",
                  photo:
                    "https://res.cloudinary.com/jll-global-property-3-0/image/upload/v1668598216/p/us/11355834.jpg",
                  service_line: "Leasing; Tenant Representation",
                  telephone: "+12057915927",
                },
                {
                  id: "Broker994772",
                  update_timestamp: "2023-02-15T16:07:16.834000Z",
                  create_timestamp: "2023-02-14T23:11:08.928000Z",
                  document_type: "Broker",
                  pk: 994772,
                  timestamp: "2023-02-15T17:14:07.520000Z",
                  _version_: 1757918000603725827,
                  city: "Charlotte",
                  department: ["Office"],
                  email: "patrick.byrnes@jll.com",
                  job_title: "Vice President",
                  linkedin:
                    "https://www.linkedin.com/in/patrickbyrnescharlotte/",
                  market: "us",
                  name: "Patrick Byrnes",
                  office: "Charlotte",
                  page_url: "patrick-byrnes",
                  photo:
                    "https://res.cloudinary.com/jll-global-property-3-0/image/upload/v1667813416/p/us/11351568.jpg",
                  service_line: "Tenant Representation",
                  telephone: "+12012749827",
                },
                {
                  id: "Broker994739",
                  update_timestamp: "2023-02-15T16:07:16.831000Z",
                  create_timestamp: "2023-02-14T23:11:08.925000Z",
                  document_type: "Broker",
                  pk: 994739,
                  timestamp: "2023-02-15T17:14:07.520000Z",
                  _version_: 1757918000603725826,
                  city: "Atlanta",
                  department: ["Retail"],
                  email: "alec.volle@jll.com",
                  job_title: "Associate",
                  linkedin: "www.linkedin.com/in/alec-volle",
                  market: "us",
                  name: "Alec Volle",
                  office: "Atlanta",
                  page_url: "alec-volle",
                  photo:
                    "https://res.cloudinary.com/jll-global-property-3-0/image/upload/v1667302216/p/us/11296707.jpg",
                  service_line: "Leasing; Tenant Representation",
                  telephone: "+16782265032",
                },
                {
                  id: "Broker994710",
                  update_timestamp: "2023-02-15T16:07:16.827000Z",
                  create_timestamp: "2023-02-14T23:11:08.923000Z",
                  document_type: "Broker",
                  pk: 994710,
                  timestamp: "2023-02-15T17:14:07.520000Z",
                  _version_: 1757918000603725825,
                  city: "Mobile",
                  department: ["Retail"],
                  email: "caroline.luscher@jll.com",
                  job_title: "Retail Associate",
                  linkedin: "https://www.linkedin.com/in/cluscher/",
                  market: "us",
                  name: "Caroline Luscher",
                  office: "Mobile",
                  page_url: "caroline-luscher",
                  photo:
                    "https://res.cloudinary.com/jll-global-property-3-0/image/upload/v1667010616/p/us/11296098.jpg",
                  service_line: "Agency Lending",
                  telephone: "+12513017249",
                },
              ]}
            />
          </div>
        </section>
        <section className="mt-6">
          <h3 className="text-heading2 mb-2 font-bold">Button</h3>
          <h4 className="text-heading3">Variants</h4>
          <div className="flex space-x-2 mb-2">
            <Button variant="primary">Primary</Button>
            <Button variant="secondary">Secondary</Button>
            <Button variant="ghost">Ghost</Button>
            <Button variant="neutral">Neutral</Button>
          </div>
          <h4 className="text-heading3  mb-2">Size</h4>
          <div className="flex space-x-2 mb-2">
            <Button variant="primary" size="small">
              Small
            </Button>
            <Button variant="primary" size="default">
              Default
            </Button>
          </div>
          <h4 className="text-heading3  mb-2">Leading & trailing icon</h4>
          <div className="flex space-x-2 mb-2">
            <Button variant="primary" leadingIcon={FaChartBar}>
              Leading Icon
            </Button>
            <Button variant="primary" trailingIcon={FaChartBar}>
              Trailing Icon
            </Button>
          </div>
          <h4 className="text-heading3  mb-2">Only icon</h4>
          <div className="flex space-x-2 mb-2">
            <Button variant="primary" size="small" leadingIcon={FaChartBar} />
            <Button variant="primary" leadingIcon={FaChartBar} />
          </div>
        </section>
        <section className="mt-6">
          <h3 className="text-heading2 mb-2 font-bold">Card</h3>
          <div className="space-x-2 mb-2">
            <Card
              chip={
                <Chip variant="primary" className="absolute top-2 left-2">
                  Cover image
                </Chip>
              }
              actions={[
                <Button
                  variant="secondary"
                  leadingIcon={FiMaximize2}
                  onClick={() => alert("Expand")}
                />,
                <Button
                  variant="secondary"
                  leadingIcon={FiTrash2}
                  onClick={() => alert("Delete")}
                />,
              ]}
            />
          </div>
        </section>
        <section className="mt-6">
          <h3 className="text-heading2 mb-2 font-bold">Chip</h3>
          <h4 className="text-heading3 mb-2">Variants</h4>
          <div className="space-x-2 mb-2">
            <Chip variant="primary">Primary</Chip>
            <Chip variant="secondary">Secondary</Chip>
            <Chip variant="success">Success</Chip>
            <Chip variant="error">Error</Chip>
          </div>
          <h4 className="text-heading3 mb-2">Size</h4>
          <div className="space-x-2 mb-2">
            <Chip variant="primary" size="small">
              Small
            </Chip>
            <Chip variant="primary" size="default">
              Default
            </Chip>
          </div>
        </section>
        <section className="mt-6">
          <h3 className="text-heading2 mb-2 font-bold">Input</h3>
          <h4 className="text-heading3 mb-2">Default</h4>
          <div className="space-x-2 mb-2">
            <Input label="Firstname" name="firstname" />
          </div>
          <h4 className="text-heading3 mb-2">Error</h4>
          <div className="space-x-2 mb-2">
            <Input label="Firstname" name="firstname" error="Required" />
          </div>
          <h4 className="text-heading3 mb-2">Optional</h4>
          <div className="space-x-2 mb-2">
            <Input label="Firstname" name="firstname" optional />
          </div>
          <h4 className="text-heading3 mb-2">Leading & trailing icon</h4>
          <div className="space-y-2 mb-2">
            <Input label="Email" name="email" leadingIcon={FiMail} />
            <Input label="Calendar" name="calendar" trailingIcon={FiCalendar} />
          </div>
          <h4 className="text-heading3 mb-2">Price</h4>
          <div className="space-y-2 mb-2">
            <Input label="Price" type="number" name="price" currency="USD" />
          </div>
        </section>
        <section className="mt-6">
          <h3 className="text-heading2 mb-2 font-bold">Textarea</h3>
          <h4 className="text-heading3  mb-2">Default</h4>
          <div className="space-x-2 mb-2">
            <Textarea label="Description" />
          </div>
          <h4 className="text-heading3  mb-2">Error</h4>
          <div className="space-x-2 mb-2">
            <Textarea label="Description" error="Required" />
          </div>
          <h4 className="text-heading3 mb-2">Optional</h4>
          <div className="space-x-2 mb-2">
            <Textarea label="Description" optional />
          </div>
        </section>
        <section className="mt-6">
          <h3 className="text-heading2 mb-2 font-bold">Checkbox</h3>
          <h4 className="text-heading3 mb-2">Default</h4>
          <div className="space-y-2 mb-2">
            <Checkbox label="Email" name="email" id="email" />
            <Checkbox label="Phone" name="phone" id="phone" />
          </div>
          <h4 className="text-heading3  mb-2">Error</h4>
          <div className="space-x-2 mb-2">
            <Checkbox label="Email" name="email2" id="email2" error />
          </div>
        </section>
        <section className="mt-6">
          <h3 className="text-heading2 mb-2 font-bold">Radio</h3>
          <h4 className="text-heading3 mb-2">Default</h4>
          <div className="space-y-2 mb-2">
            <Radio id="email3" name="com1" label="Email" />
            <Radio id="phone3" name="com1" label="Phone" />
          </div>
          <h4 className="text-heading3  mb-2">Error</h4>
          <div className="space-x-2 mb-2">
            <Radio id="email4" name="com2" label="Email" error />
          </div>
        </section>

        <section className="mt-6">
          <h3 className="text-heading2 mb-2 font-bold">Select</h3>
          <h4 className="text-heading3 mb-2">Default</h4>
          <div className="space-y-2 mb-2">
            <Select options={selectData} />
          </div>
          <h4 className="text-heading3 mb-2">Error</h4>
          <div className="space-y-2 mb-2">
            <Select
              options={selectData}
              label="Error Select"
              error="This field is required"
            />
          </div>
        </section>

        <section className="mt-6">
          <h3 className="text-heading2 mb-2 font-bold">Auto Complete</h3>
          <h4 className="text-heading3 mb-2">Multi Select</h4>
          <div className="space-y-2 mb-2">
            <SelectAutoComplete name="select" options={selectData} isMulti />
          </div>
          <h4 className="text-heading3 mb-2">Optional</h4>
          <div className="space-y-2 mb-2">
            <SelectAutoComplete
              name="select"
              options={selectData}
              isMulti
              optional
              label="Optional Select"
            />
          </div>
        </section>

        <section className="mt-6">
          <h3 className="text-heading2 mb-2 font-bold">Switch</h3>
          <div className="space-y-2 mb-2">
            <Switch enabled={true} onChange={() => {}}/>
          </div>
        </section>
        <section className="mt-6">
          <h3 className="text-heading2 mb-2 font-bold">Table</h3>
          <div className="space-y-2 mb-2">
            <Table>
              <Table.Thead>
                <Table.Tr>
                  <Table.Th>First Name</Table.Th>
                  <Table.Th>Last Name</Table.Th>
                  <Table.Th>Role</Table.Th>
                  <Table.Th className="text-right">Action</Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                {tableData.map((data) => (
                  <Table.Tr>
                    <Table.Td>{data.firstname}</Table.Td>
                    <Table.Td>{data.lastname}</Table.Td>
                    <Table.Td>{data.role}</Table.Td>
                    <Table.Td className="text-right space-x-2">
                      <Button variant="secondary" size="small">
                        😎
                      </Button>
                      <Button variant="primary" size="small">
                        Edit
                      </Button>
                    </Table.Td>
                  </Table.Tr>
                ))}
              </Table.Tbody>
            </Table>
          </div>
        </section>
        <section className="mt-6">
          <h3 className="text-heading2 mb-2 font-bold">Toast</h3>
          <div className="space-y-2 mb-2">
            <Button
              variant="primary"
              className="mr-4"
              onClick={() => Toast.success("Your account has been saved")}
            >
              Success Toast
            </Button>
            <Button
              variant="primary"
              className="mr-4"
              onClick={() => Toast.warn("Your email address is invalid")}
            >
              Warning Toast
            </Button>
            <Button
              variant="primary"
              onClick={() => Toast.error("Warning message text sample")}
            >
              Error Toast
            </Button>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Designsystem;
