import { useContext, useEffect, useMemo, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { FiMinusCircle, FiPlusCircle, FiSearch, FiTrash } from "react-icons/fi";
import { Button, EmptyResult, Input, Loading, Toast } from "ui-atoms";
import { StyledPropertyValue, Table } from "ui-molecules";
import { LayoutEditTeamListing as Layout } from "ui-organisms";
import TeamListingPropertyPanel from "./TeamListingPropertyPanel";
import SlidingPane from "react-sliding-pane";
import { useApiCall } from "hooks";
import {
  deleteTeamListingBuildingAPI,
  getTeamListingAPI,
  patchTeamListingOrderAPI,
  postTeamListingBuildingBulkAPI,
} from "services";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { PROPERTY_TABLE_COLUMNS } from "./constants";
import { RxDragHandleHorizontal } from "react-icons/rx";
import { reorder } from "utils";
import { SET_LOADING_MODAL, SET_TEAM_LISTING } from "constant";
import { GlobalContext } from "context";
import debounce from "lodash.debounce";
import queryString from "query-string";

const TeamListingProperty = () => {
  const { dispatch, state } = useContext(GlobalContext);
  const { teamListing } = state;
  const { teamId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [isSlidePane, setIsSlidePane] = useState(false);
  const [getTeamListing, loading] = useApiCall(getTeamListingAPI);
  const [patchTeamListingOrder] = useApiCall(patchTeamListingOrderAPI);
  const [deleteTeamListingBuilding] = useApiCall(deleteTeamListingBuildingAPI);
  const [postTeamListingBuildingBulk] = useApiCall(
    postTeamListingBuildingBulkAPI
  );
  const [keyword, setKeyword] = useState<string>();
  const [paramFilters, setParamFilters] = useState<any>(null);

  useEffect(() => {
    let filters = queryString.parse(location?.search, {
      arrayFormat: "bracket",
    });
    setKeyword(filters?.keyword?.toString() || "");
    setParamFilters(filters);
  }, [location]);

  useEffect(() => {
    if (isSlidePane || !teamId || !paramFilters) return;
    dispatch({
      type: SET_TEAM_LISTING,
      action: [],
    });
    getTeamListing({ pk: teamId, keyword }).then((data: any) => {
      dispatch({
        type: SET_TEAM_LISTING,
        payload: data,
      });
    });
  }, [teamId, isSlidePane, paramFilters]);

  const removeGroup = async (pk: string | number) => {
    try {
      dispatch({
        type: SET_LOADING_MODAL,
        payload: {
          open: true,
          label: `Removing this buiding from Set`,
        },
      });
      deleteTeamListingBuilding(pk)
        .then((res: any) => {
          const index: number | undefined = teamListing?.listings?.findIndex(
            (item: any) => item?.team_listing_building?.id === pk
          );
          if (index !== undefined && index > -1) {
            const new_data = [...(teamListing?.listings || [])];
            new_data?.splice(index, 1);
            dispatch({
              type: SET_TEAM_LISTING,
              payload: {
                ...teamListing,
                listings: new_data,
              },
            });
            Toast.success("Removed this building successfully");
          }
        })
        .finally(() => {
          setTimeout(() => {
            dispatch({
              type: SET_LOADING_MODAL,
              payload: {
                open: false,
                label: null,
              },
            });
          }, 100);
        });
    } catch (err: any) {
      if (err?.message) Toast.error(err?.message);
      setTimeout(() => {
        dispatch({
          type: SET_LOADING_MODAL,
          payload: {
            open: false,
            label: null,
          },
        });
      }, 100);
    }
  };

  const removeGroupAll = async () => {
    try {
      dispatch({
        type: SET_LOADING_MODAL,
        payload: {
          open: true,
          label: "Removing the buidings to Set",
        },
      });

      let building_ids: number[] = [];
      teamListing?.listings?.forEach((listing: any) => {
        building_ids.push(listing?.pk || listing?.building);
      });
      if (!building_ids?.length) {
        setTimeout(() => {
          dispatch({
            type: SET_LOADING_MODAL,
            payload: {
              open: false,
              label: null,
            },
          });
        }, 100);
        return;
      }
      let payload: any = {
        team_listing: teamId,
        buildings: building_ids,
        delete: true,
      };
      const res = await postTeamListingBuildingBulk(payload);
      setTimeout(() => {
        dispatch({
          type: SET_LOADING_MODAL,
          payload: {
            open: false,
            label: null,
          },
        });
      }, 100);

      if (res) {
        dispatch({
          type: SET_TEAM_LISTING,
          payload: {
            ...teamListing,
            listings: [],
          },
        });
        Toast.success(`Removed all buildings successfully`);
      }
    } catch (err: any) {
      if (err?.message) Toast.error(err?.message);
      setTimeout(() => {
        dispatch({
          type: SET_LOADING_MODAL,
          payload: {
            open: false,
            label: null,
          },
        });
      }, 100);
    }
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    const new_listings = [...teamListing?.listings];
    const newOrder = reorder(
      [...new_listings],
      result.source.index,
      result.destination.index
    );
    dispatch({
      type: SET_TEAM_LISTING,
      payload: {
        ...teamListing,
        listings: newOrder,
      },
    });
    try {
      const listing_order = newOrder.map(
        (order) => order?.team_listing_building?.id
      );
      patchTeamListingOrder({ pk: teamId, listing_order })
        .then((res: any) => {})
        .catch((err: any) => {
          dispatch({
            type: SET_TEAM_LISTING,
            payload: {
              ...teamListing,
              listings: new_listings,
            },
          });
        });
    } catch (err) {
      dispatch({
        type: SET_TEAM_LISTING,
        payload: {
          ...teamListing,
          listings: new_listings,
        },
      });
    }
  };

  const updateKeywordFilter = (e: any) => {
    const updatedFilters = { ...paramFilters };
    if (e.target.value) {
      updatedFilters["keyword"] = e.target.value;
    } else if (updatedFilters.hasOwnProperty("keyword")) {
      delete updatedFilters.keyword;
    }
    navigate(
      `${location.pathname}?${queryString.stringify(updatedFilters, {
        arrayFormat: "bracket",
      })}`
    );
  };

  const debounceUpdateKeyword = useMemo(
    () => debounce(updateKeywordFilter, 300),
    [paramFilters]
  );

  const onChangeInput = (e: any) => {
    setKeyword(e.target.value);
    debounceUpdateKeyword(e);
  };

  return (
    <Layout>
      <div className="relative w-full h-full flex flex-col">
        <div className="px-8 py-6 flex flex-row items-center justify-between shadow-detail-status-nav bg-white">
          <div className="flex flex-row items-center space-x-6">
            <span className="font-semibold text-jll-color-text-base-default">
              Find a property to add to listing
            </span>
            <Input
              name="property"
              placeholder="Search by building name, listing ID"
              leadingIcon={FiSearch}
              className="w-[400px] !mb-0"
              onChange={onChangeInput}
              value={keyword}
              isClearable
            />
          </div>
          <div className="flex flex-row items-center space-x-4">
            {/* <Button variant="secondary" leadingIcon={FiExternalLink}>
              Link to Listings
            </Button>
            <Button variant="secondary" leadingIcon={FiUpload}>
              Digital Export
            </Button> */}
          </div>
        </div>
        <div className="my-4 flex justify-end flex-row w-full pr-8">
          <Button
            variant="primary"
            leadingIcon={FiPlusCircle}
            onClick={() => setIsSlidePane(true)}
          >
            Add Property
          </Button>
        </div>
        <div className="flex h-full overflow-y-auto pl-8">
          {loading && !teamListing?.listings?.length && <Loading />}
          {!loading && teamListing && !teamListing?.listings?.length && (
            <EmptyResult>
              <p className="mt-4 text-body1 text-jll-color-coldGray-7 mb-2">
                No properties - add listings by clicking Add Property
              </p>
            </EmptyResult>
          )}
          {!!teamListing?.listings?.length && (
            <div className="w-full pr-8">
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable
                  droppableId="droppable-property"
                  direction="vertical"
                >
                  {(provided, snapshot) => {
                    return (
                      <Table>
                        <Table.Thead>
                          <Table.Tr>
                            <Table.Th />
                            {PROPERTY_TABLE_COLUMNS.map(
                              (column: any, index: number) => (
                                <Table.Th key={index} filterId={column.id}>
                                  {column.label}
                                </Table.Th>
                              )
                            )}
                            <Table.Th>
                              <Button
                                variant="ghost"
                                leadingIcon={FiMinusCircle}
                                onClick={() => removeGroupAll()}
                              >
                                Remove All
                              </Button>
                            </Table.Th>
                          </Table.Tr>
                        </Table.Thead>
                        <Table.Tbody
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {!!teamListing?.listings?.length &&
                            teamListing?.listings?.map(
                              (item: any, idx: number) => (
                                <Draggable
                                  key={idx}
                                  draggableId={item?.pk.toString()}
                                  index={idx}
                                >
                                  {(provided, snapshot) => (
                                    <Table.Tr
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                    >
                                      <Table.Td>
                                        <div className="flex flex-row items-center space-x-2">
                                          <div
                                            {...provided.dragHandleProps}
                                            className="flex justify-center items-center w-8 h-9"
                                          >
                                            <RxDragHandleHorizontal className="text-xl mr-2" />
                                          </div>
                                          <span className="text-jll-color-text-base-subdued">
                                            {idx + 1}
                                          </span>
                                        </div>
                                      </Table.Td>
                                      {PROPERTY_TABLE_COLUMNS.map(
                                        (column: any, index: number) => (
                                          <Table.Td key={index}>
                                            <StyledPropertyValue
                                              property={item}
                                              valueKey={column.id}
                                            />
                                          </Table.Td>
                                        )
                                      )}
                                      <Table.Td>
                                        <Button
                                          variant="neutral"
                                          className="text-jll-color-text-danger hover:text-jll-color-text-danger"
                                          leadingIcon={FiTrash}
                                          leadingIconClass="text-jll-color-text-danger"
                                          onClick={() =>
                                            removeGroup(
                                              item?.team_listing_building?.id
                                            )
                                          }
                                        >
                                          Remove from Group
                                        </Button>
                                      </Table.Td>
                                    </Table.Tr>
                                  )}
                                </Draggable>
                              )
                            )}
                        </Table.Tbody>
                      </Table>
                    );
                  }}
                </Droppable>
              </DragDropContext>
            </div>
          )}
        </div>
      </div>

      <SlidingPane
        width="80%"
        from="right"
        isOpen={isSlidePane}
        onRequestClose={() => setIsSlidePane(false)}
      >
        <TeamListingPropertyPanel setIsSlidePane={setIsSlidePane} />
      </SlidingPane>
    </Layout>
  );
};

export default TeamListingProperty;
