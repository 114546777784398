import { BrokerProps, TeamListingLinkProps, TeamListingProps } from "types";
import { AvatarGroup } from "ui-molecules";
import { getFormatedDate, joinTwoStr } from "utils";

export interface StyledTeamListingValueProps {
  listing: TeamListingProps | TeamListingLinkProps;
  valueKey:
    | keyof TeamListingProps
    | keyof TeamListingLinkProps
    | "createdBy"
    | "link_info"
    | "authors";
  meta?: any;
}

const StyledTeamListingValue: React.FC<StyledTeamListingValueProps> = ({
  listing,
  valueKey,
  meta,
}) => {
  return (
    <>
      {(() => {
        switch (valueKey) {
          case "name":
            return (
              <p className="font-semibold text-jll-color-text-base-default">
                {listing?.name || "-"}
              </p>
            );

          case "title":
            return (
              <p className="font-semibold text-jll-color-text-base-default">
                {(listing as TeamListingLinkProps)?.title ||
                  (listing as TeamListingLinkProps)?.name ||
                  (listing as TeamListingLinkProps)?.team_listing_name ||
                  "-"}
              </p>
            );

          case "description":
            return (
              <p className="text-jll-color-text-base-default">
                {(listing as TeamListingLinkProps)?.description || "-"}
              </p>
            );

          case "breakdown":
            if (!(listing as TeamListingLinkProps)?.breakdown) return <>-</>;
            return (
              <>
                {
                  meta?.teamlistinglink?.breakdown[
                    (listing as TeamListingLinkProps)?.breakdown
                  ]?.value
                }
              </>
            );
          case "authors":
            const authors =
              (listing as TeamListingLinkProps)?.authors ||
              (listing as TeamListingLinkProps)?.author;
            if (!authors?.length) {
              return <>-</>;
            }
            return (
              <AvatarGroup
                uniqueId={listing?.pk}
                brokers={authors as BrokerProps[]}
                max={3}
              />
            );
          case "createdBy":
            return (
              <div>
                <p>
                  {listing?.update_timestamp &&
                    getFormatedDate(listing?.update_timestamp)}
                </p>
                {(!!listing?.updated_user_first_name ||
                  !!listing?.updated_user_last_name) && (
                  <p className="text-sm text-jll-color-text-base-default">
                    <span className="text-jll-color-coldGray-7">
                      updated by:{" "}
                    </span>
                    {joinTwoStr(
                      listing?.updated_user_first_name,
                      listing?.updated_user_last_name,
                      " "
                    )}
                  </p>
                )}
              </div>
            );
          default:
            if (listing?.hasOwnProperty(valueKey)) {
              // @ts-ignore
              return <>{listing?.[valueKey]}</>;
            }
            return <>-</>;
        }
      })()}
    </>
  );
};

export default StyledTeamListingValue;
