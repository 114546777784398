import {
  EVENT_PUBLISHED,
  EVENT_UNPUBLISHED_PROPERTY,
  EventProps,
  META_TAGS,
  PROPERTY_TABS,
  PUBLISH_STATUS_PUBLISHED,
  PUBLISH_STATUS_REMOVED,
  SET_EVENT_MODAL,
  SET_LOADING_MODAL,
  SET_PROPERTY,
  URLS,
} from "constant";
import { GlobalContext } from "context";
import { useApiCall } from "hooks";
import React from "react";
import { useContext, useRef } from "react";
import { Helmet } from "react-helmet-async";
import { FiChevronLeft } from "react-icons/fi";
import { Link, useParams } from "react-router-dom";
import { getPropertyAPI, patchPropertyAPI } from "services";
import { Button, Label, Toast } from "ui-atoms";
import { PublishLogList } from "ui-molecules";
import { LayoutEditProperty as Layout } from "ui-organisms";

interface IPropertyPublish {
  diffFields?: any;
  onSave?: any;
}

const PropertyPublish: React.FC<IPropertyPublish> = ({
  diffFields,
  onSave,
}) => {
  const { state, dispatch } = useContext(GlobalContext);
  const { property } = state;
  const { propertyId } = useParams();
  const logRef = useRef<any>(null);
  const [getProperty] = useApiCall(getPropertyAPI);
  const [patchProperty] = useApiCall(patchPropertyAPI);

  const reloadProperty = () => {
    getProperty(propertyId).then((data: any) => {
      dispatch({
        type: SET_PROPERTY,
        payload: data,
      });
    });
  };

  const onStatusUpdate = async (
    publish_status: number,
    publish_availabilities: boolean = false,
    event: any = {}
  ) => {
    let payload: any = {
      publish_status,
      ...{ ...event },
    };
    if (publish_availabilities) {
      payload = { ...payload, publish_availabilities };
    }
    const response = await patchProperty({
      id: property.id,
      payload: payload,
    });
    if (!response) return;
    Toast.success("Updated successfully");
    dispatch({
      type: SET_PROPERTY,
      payload: {
        ...property,
        publish_status,
      },
    });
    reloadProperty();
    logRef?.current?.loadData();
  };

  const handlePublish = async (availabiltyFlag: boolean = false) => {
    const action = async (event: any) => {
      try {
        if (!!diffFields && !!Object.keys(diffFields)?.length && onSave) {
          await onSave(PUBLISH_STATUS_PUBLISHED, availabiltyFlag);
        } else {
          dispatch({
            type: SET_LOADING_MODAL,
            payload: {
              open: true,
              label: "Publishing the property",
            },
          });
          await onStatusUpdate(
            PUBLISH_STATUS_PUBLISHED,
            availabiltyFlag,
            event
          );
          setTimeout(() => {
            dispatch({
              type: SET_LOADING_MODAL,
              payload: {
                open: false,
                label: null,
              },
            });
          }, 100);
        }
      } catch (err) {
        setTimeout(() => {
          dispatch({
            type: SET_LOADING_MODAL,
            payload: {
              open: false,
              label: null,
            },
          });
        }, 100);
        return;
      }
    };

    if (property?.publish_status !== PUBLISH_STATUS_PUBLISHED)
      dispatch({
        type: SET_EVENT_MODAL,
        payload: {
          open: true,
          content: {
            title: `Publish Property${
              availabiltyFlag ? " and Availability" : ""
            }`,
            label: `What is reason for publishing this property?`,
            eventType: EVENT_PUBLISHED,
            buttonLabel: `Publish Property${
              availabiltyFlag ? " + Availablity" : ""
            }`,
            action,
          },
        },
      });
    else action({});
  };

  const handleUnpublish = async () => {
    const action = async (event: EventProps) => {
      try {
        dispatch({
          type: SET_LOADING_MODAL,
          payload: {
            open: true,
            label: "Unpublishing the property",
          },
        });
        await onStatusUpdate(PUBLISH_STATUS_REMOVED, false, event);
        setTimeout(() => {
          dispatch({
            type: SET_LOADING_MODAL,
            payload: {
              open: false,
              label: null,
            },
          });
        }, 100);
      } catch (err) {
        setTimeout(() => {
          dispatch({
            type: SET_LOADING_MODAL,
            payload: {
              open: false,
              label: null,
            },
          });
        }, 100);
      }
    };

    dispatch({
      type: SET_EVENT_MODAL,
      payload: {
        open: true,
        content: {
          title: "Unpublish Property",
          label: `What is reason for un-publishing this property?`,
          eventType: EVENT_UNPUBLISHED_PROPERTY,
          buttonLabel: "Unpublish Property",
          action,
        },
      },
    });
  };

  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>{`${META_TAGS?.default?.title} ${
          property?.title || property?.address
        } Publish`}</title>
        <meta
          property="og:title"
          content={`${META_TAGS?.default?.title} ${
            property?.title || property?.address
          } Publish`}
        />
      </Helmet>

      <div>
        <Layout.Section title="Publish to OLM" titleClassName="!mb-2">
          <div className="flex flex-row items-center">
            <Label>
              Write your listing to OLM. You can publish the property data
              alone, or the property data as well as each active availability
              within this property.
            </Label>
          </div>
          <div>
            <div className="flex flex-col space-y-2 py-4">
              <Button
                variant="primary"
                onClick={() => handlePublish()}
                className="whitespace-nowrap w-fit"
              >
                Publish Property
              </Button>

              <p className="text-jll-color-coldGray-7 text-sm">
                This will publish the listing to OLM and over-write any existing
                data for this property.
              </p>
            </div>
            <div className="flex flex-col space-y-2 py-4">
              <Button
                variant="primary"
                onClick={() => handlePublish(true)}
                className="whitespace-nowrap w-fit"
              >
                Publish Property + Availability
              </Button>

              <p className="text-jll-color-coldGray-7 text-sm">
                This will publish the listing to OLM and over-write any existing
                data for this property and each of its active availabilities.
              </p>
            </div>
            {property?.publish_status === PUBLISH_STATUS_PUBLISHED && (
              <div className="flex flex-col space-y-2 py-4">
                <Button
                  variant="danger"
                  onClick={handleUnpublish}
                  className="whitespace-nowrap w-fit"
                >
                  Un-publish Property
                </Button>
                <p className="text-jll-color-coldGray-7 text-sm">
                  This will remove this property from OLM. Any availabilities
                  previously published to OLM will be removed.
                </p>
              </div>
            )}
          </div>
        </Layout.Section>

        <PublishLogList type="building" objectId={propertyId} ref={logRef} />
      </div>

      <div className="px-8 fixed bottom-0 left-[360px] right-0 flex justify-end bg-white shadow-detail-status-nav h-[80px] items-center">
        <Link
          to={`/property/${propertyId}/${URLS.PROPERTY.FORM}?tab=${PROPERTY_TABS.PREVIEW}`}
        >
          <Button variant="secondary" leadingIcon={FiChevronLeft}>
            Previous
          </Button>
        </Link>
      </div>
    </>
  );
};

export default React.memo(PropertyPublish);
