import { useEffect, useContext } from "react";
import { Loading } from "ui-atoms";
import cn from "classnames";
import { Navbar } from "ui-molecules";
import { FiActivity, FiFileText } from "react-icons/fi";
import { URLS, NEW_ID } from "constant";
import { useApiCall } from "hooks";
import { GlobalContext } from "context";
import { SET_AVAILABILITY } from "constant";
import { useParams } from "react-router-dom";
import { getAvailabilityAPI } from "services";
import Sidebar from "../LayoutEditSidebar";
import Section from "../LayoutEditSection";
import LayoutBase from "../LayoutBase";

interface LayoutEditAvailabilityProps {
  children: any;
  className?: string;
  olmToggle?: { isOLM: boolean; setIsOLM: any };
}

function LayoutEditAvailability<T extends object>({
  children,
  className,
  olmToggle,
}: LayoutEditAvailabilityProps) {
  const { dispatch, state } = useContext(GlobalContext);
  const [getAvailability, isLoading] = useApiCall(getAvailabilityAPI);
  const { propertyId, availabilityId } = useParams();
  const isNew = availabilityId === NEW_ID;
  const { availability } = state;
  const backToUrl = `/property/${propertyId}/availabilities`;
  const menuItems = [
    {
      label: "Availability",
      icon: FiFileText,
      suffixUrl: URLS.AVAILABILITY.FORM,
      isOLMToggle: true,
    },
    {
      label: "Availability Activity",
      icon: FiActivity,
      suffixUrl: URLS.AVAILABILITY.ACTIVITY,
    },
  ];

  const reloadAvailability = () => {
    getAvailability(availabilityId).then((data: any) => {
      dispatch({
        type: SET_AVAILABILITY,
        payload: data,
      });
    });
  };

  useEffect(() => {
    if (!availabilityId) return;
    if (isNew) {
      dispatch({
        type: SET_AVAILABILITY,
        payload: {
          id: NEW_ID,
        },
      });
    } else {
      reloadAvailability();
    }
  }, [availabilityId]);

  if (isLoading && !availability) {
    return (
      <div className="h-[200px]">
        <Loading />
      </div>
    );
  }

  return (
    <LayoutBase>
      <Navbar />
      <Sidebar
        backToUrl={backToUrl}
        menuItems={menuItems}
        olmToggle={olmToggle}
      />
      <div
        className={cn(
          "fixed top-[64px] left-[360px] bottom-0 right-0 overflow-x-auto",
          className
        )}
      >
        {isLoading && !!availability && <Loading />}
        {!isLoading && !!availability && children}
      </div>
    </LayoutBase>
  );
}

LayoutEditAvailability.Section = Section;

export default LayoutEditAvailability;
