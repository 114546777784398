import { SET_CONFIRM_MODAL, SET_LOADING_MODAL } from "constant";
import { GlobalContext } from "context";
import { useApiCall } from "hooks";
import { useContext, useEffect, useState } from "react";
import { renderToString } from "react-dom/server";
import { useDropzone } from "react-dropzone";
import { FiCheck, FiFileText, FiTrash, FiUpload } from "react-icons/fi";
import { patchPropertyAPI, postImageAPI } from "services";
import { RemoveDescriptionItem } from "ui-molecules";
import {
  capitalizeWords,
  convertBytesTo,
  getUTCDate,
  ordinalSuffixOf,
} from "utils";

interface IEditableFileField {
  value?: string;
  valueKey: string;
  pk?: string | number;
}

interface IFileItem {
  value?: any;
  valueKey: string;
  handleRemove?: any;
  index: number;
}

const FileItem: React.FC<IFileItem> = ({
  value,
  handleRemove,
  valueKey,
  index,
}) => {
  return (
    <div className="flex flex-row items-center justify-between py-[5px] space-x-[30px]">
      <div className="flex flex-row items-center truncate">
        <FiFileText className="w-4 h-4 text-jll-color-coldGray-4 mr-[10px]" />
        <a
          href={value?.url}
          className="text-jll-color-surface-info-default max-w-[130px] truncate mr-2"
          target="_blank"
          rel="noreferrer"
        >
          {value?.name}
        </a>
        {value?.size && (
          <span className="text-jll-color-coldGray-4">
            {convertBytesTo(value?.size)}
          </span>
        )}
      </div>
      <div className="flex flex-row items-center w-fit">
        <div className="flex flex-row space-x-1 pr-2 border-r border-black border-opacity-10">
          <FiCheck className="w-4 h-4 text-jll-color-icon-success" />
        </div>
        <FiTrash
          className="ml-2 w-4 h-4 text-jll-color-text-danger cursor-pointer"
          onClick={() => handleRemove(valueKey, index)}
        />
      </div>
    </div>
  );
};

const EditableFileField: React.FC<IEditableFileField> = ({
  value,
  valueKey,
  pk,
}) => {
  const { dispatch } = useContext(GlobalContext);
  const [parsedValue, setParsedValue] = useState([]);
  const [patchProperty] = useApiCall(patchPropertyAPI);

  useEffect(() => {
    if (!value?.length) return;
    setParsedValue(JSON.parse(value));
  }, [value]);

  const handleSubmitFile = async (name: string, files: any) => {
    if (files?.length <= 0) return;
    dispatch({
      type: SET_LOADING_MODAL,
      payload: {
        open: true,
        label: "Uploading files",
      },
    });
    try {
      let results: any[] = [];
      await Promise.all(
        files?.map(async (file: any) => {
          const result = await postImageAPI(file, name === "listing_contracts");
          results.push({
            name: file?.name,
            url: result,
            size: Number(file?.size),
            create_timestamp: getUTCDate(),
          });
        })
      );
      if (!!results?.length) {
        const payload: any = [
          ...(parsedValue
            ? !Array.isArray(parsedValue)
              ? []
              : parsedValue
            : []),
          ...results,
        ];
        const response = await patchProperty({
          id: Number(pk),
          payload: {
            [name]: payload,
          },
        });
        if (!!response) {
          setParsedValue(payload);
        }
      }
      setTimeout(() => {
        dispatch({
          type: SET_LOADING_MODAL,
          payload: {
            open: false,
            label: null,
          },
        });
      }, 100);
    } catch (err) {
      setTimeout(() => {
        dispatch({
          type: SET_LOADING_MODAL,
          payload: {
            open: false,
            label: null,
          },
        });
      }, 100);
    }
  };

  const onDrop = (acceptedFiles: any[]) => {
    handleSubmitFile(valueKey, [...acceptedFiles]);
  };

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 5,
    onDrop,
    multiple: true,
    // ...acceptType,
  });

  const removeValue = async (name: string, index: number) => {
    const action = () => {
      const newValue = [...parsedValue];
      newValue.splice(index, 1);
      patchProperty({
        id: Number(pk),
        payload: {
          [name]: newValue,
        },
      }).then(() => {
        setParsedValue(newValue);
      });
    };

    dispatch({
      type: SET_CONFIRM_MODAL,
      payload: {
        open: true,
        content: {
          description: renderToString(
            <RemoveDescriptionItem
              target={`${ordinalSuffixOf(index + 1)} Item in ${capitalizeWords(
                name,
                "_"
              )}`}
            />
          ),
          action,
        },
      },
    });
  };

  return (
    <div
      onClick={(e: any) => {
        e.stopPropagation();
      }}
    >
      <div
        {...getRootProps({
          className:
            "w-full flex h-full cursor-pointer bg-white border border-dashed border-jll-color-coldGray-3 rounded justify-center items-center py-2 px-10",
        })}
      >
        <input {...getInputProps()} />
        <div className="flex items-center flex-col">
          <div className="flex flex-row items-center text-jll-color-text-base-default text-xs font-semibold">
            <FiUpload className="w-3 h-3 mr-1" />
            Drag and drop files here or upload
          </div>
          <p className="text-jll-color-coldGray-4 text-xs">
            Accepted file types: JPEG, Doc, PDF, PNG
          </p>
        </div>
      </div>

      {!!parsedValue?.length &&
        parsedValue?.map((item: any, index: number) => (
          <FileItem
            key={index}
            value={item}
            handleRemove={removeValue}
            valueKey={valueKey}
            index={index}
          />
        ))}
    </div>
  );
};

export default EditableFileField;
