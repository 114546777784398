import type { StyledAvailabilityValueProps } from "ui-molecules";

interface TdProps {
  className: string;
}

interface TableColumnProps {
  th?: any;
  id: StyledAvailabilityValueProps["valueKey"];
  label: string;
  sort?: string;
  td?: TdProps;
}

export const LEASE_TERM_NEGOTIABLE = "Negotiable";
export const DATE_AVAILABLE_IMMEDIATE = "Immediate";

export const ALL_AVAILABILITIES_COLUMNS: TableColumnProps[] = [
  {
    id: "building",
    label: "Building",
  },
  {
    id: "info",
    label: "Suite",
    sort: "asc",
  },
  {
    id: "floor",
    label: "Floor",
  },
  {
    id: "max_floor_area",
    label: "Size",
  },
  {
    id: "broker",
    label: "Brokers",
  },
  {
    id: "tenure_types",
    label: "Type",
    td: {
      className: "space-x-2 !z-auto",
    },
  },
  {
    id: "date_available",
    label: "Availability",
  },
  {
    id: "date_on_market",
    label: "Date on Market",
  },
  {
    id: "max_contiguous",
    label: "Maximum Contiguous Available",
  },
  {
    id: "space_use_type",
    label: "Space Use",
  },

  {
    id: "suite_condition",
    label: "Suite Condition",
  },
  {
    id: "is_full_floor",
    label: "Is Full Floor",
  },
  {
    id: "is_vacant",
    label: "Is Vacant",
  },
  {
    id: "lease_term",
    label: "Lease Term",
  },
  {
    id: "highlights",
    label: "Highlights",
  },
  {
    id: "images",
    label: "Images",
  },
  {
    id: "floorplans",
    label: "Floor plans",
  },
  {
    id: "brochures",
    label: "Brochures",
  },
  {
    id: "max_price",
    label: "Lease Price",
  },
  {
    id: "rent_type",
    label: "Rent Type",
  },
  {
    id: "status",
    label: "Publish Status",
  },
  {
    id: "updated_info",
    label: "Updated",
  },
];

export const AVAILABILITIES_TABLE_COLUMNS: TableColumnProps[] = [
  {
    id: "building",
    label: "Building",
  },
  {
    id: "info",
    label: "Suite",
    sort: "asc",
  },
  {
    id: "floor",
    label: "Floor",
  },
  {
    id: "max_floor_area",
    label: "Size",
  },
  {
    id: "max_price",
    label: "Rate",
  },
  {
    id: "broker",
    label: "Brokers",
  },
  {
    id: "tenure_types",
    label: "Type",
    td: {
      className: "space-x-2 !z-auto",
    },
  },
  {
    id: "date_available",
    label: "Availability",
  },
  {
    id: "status",
    label: "Publish Status",
  },
  {
    id: "updated_info",
    label: "Updated",
  },
];

export const AVAILABILITY_TABS = {
  EDIT: "edit",
  CONTACTS: "contacts",
  MEDIA: "media",
  PUBLISH: "publish",
};

export const AVAILABILITIES_STEPS = [
  {
    index: 1,
    label: "Edit",
    tag: AVAILABILITY_TABS.EDIT,
  },
  {
    index: 2,
    label: "Brokerage Team",
    tag: AVAILABILITY_TABS.CONTACTS,
  },
  {
    index: 3,
    label: "Media",
    tag: AVAILABILITY_TABS.MEDIA,
  },
  {
    index: 4,
    label: "Publish",
    tag: AVAILABILITY_TABS.PUBLISH,
  },
];

export interface AvailabilityListProps {
  label: string;
  key: string;
}

export const AVAILABILITY_DETAILS: AvailabilityListProps[] = [
  { label: "Size", key: "size" },
  { label: "Rate", key: "rate" },
  { label: "Availability Type", key: "tenure_types" },
  { label: "Date available", key: "date_available" },
  { label: "Lease Term", key: "lease_term" },
  { label: "", key: "space" },
];

export const OLM_AVAILABILITY_FIELDS = [
  "name",
  "floor",
  "brochures",
  "images",
  "highlights",
  "floorplans",
  "videos",
  "virtual_tours",
  "hide_price",
  "lease_term",
  "marketing_description",
  "max_contiguous",
  "max_floor_area",
  "min_floor_area",
  "max_price",
  "min_price",
  "space_use_type",
  "tenure_types",
  "date_available",
];
