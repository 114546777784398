import React, { useEffect, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { FiChevronDown, FiCheck, FiXCircle, FiRotateCcw } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import { Button, Input } from "ui-atoms";
import cn from "classnames";

interface IMultipleSelectProps {
  label?: string;
  options: any;
  selectedOptions?: any;
  name?: string;
  className?: string;
  onChange?: any;
  title?: string;
  onChangeInput?: any;
  searchWord?: string;
  align?: string;
  zIdx?: string;
  divClassName?: string;
  filters?: any;
  setFilters?: any;
}

const MultipleSelect = ({
  label = "",
  options = [],
  selectedOptions = [],
  name = "",
  className,
  onChange,
  title = "",
  onChangeInput,
  searchWord,
  align = "left",
  zIdx,
  divClassName,
  filters,
  setFilters,
}: IMultipleSelectProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [paramFilters, setParamFilters] = useState<any>(null);

  useEffect(() => {
    if (filters) {
      setParamFilters(filters);
    } else {
      const newFilters = queryString.parse(location?.search, {
        arrayFormat: "bracket",
      });
      setParamFilters(newFilters);
    }
  }, [location, filters]);

  const isSelected = (value: any) => {
    if (paramFilters?.[value]) return true;
    return selectedOptions?.find((el: any) => el.value === value)
      ? true
      : false;
  };

  const handleFilter = (selectedOptions: any, name: string) => {
    let additional: any = {};
    const values =
      selectedOptions?.map(({ value }: any) => {
        if (value?.toString()?.includes("_missing")) {
          additional[value] = true;
          return;
        }
        return value;
      }) || [];
    let updatedFilters = {
      ...paramFilters,
      [name]: values,
    };
    if (name === "coordinator" || name === "broker") {
      delete updatedFilters[`${name}_missing`];
    }
    updatedFilters = {
      ...updatedFilters,
      ...additional,
    };
    if (setFilters) {
      setFilters(updatedFilters);
    } else
      navigate(
        `${location.pathname}?${queryString.stringify(updatedFilters, {
          arrayFormat: "bracket",
        })}`
      );
  };

  const handleSelect = (value: any) => {
    if (!value) return;
    if (!isSelected(value)) {
      const selectedItemsUpdated = [
        ...selectedOptions,
        options.find((el: any) => el.value === value),
      ];
      if (onChange) onChange(selectedItemsUpdated, name);
      else handleFilter(selectedItemsUpdated, name);
    } else {
      handleDeselect(value);
    }
  };

  const handleDeselect = (value: any) => {
    const selectedItemsUpdated = selectedOptions.filter(
      (el: any) => el.value !== value
    );
    if (onChange) onChange(selectedItemsUpdated, name);
    else handleFilter(selectedItemsUpdated, name);
  };

  const selectClasses =
    selectedOptions && selectedOptions.length > 0
      ? "bg-jll-color-surface-info-subdued text-jll-color-surface-info-default"
      : "text-jll-color-text-base-subdued bg-jll-color-coldGray-1";

  return (
    <div
      className={cn("z-30", divClassName)}
      style={{ zIndex: zIdx ? zIdx : 30 }}
    >
      <Listbox
        as="div"
        className="space-y-1"
        value={selectedOptions}
        onChange={handleSelect}
      >
        {({ open }) => {
          return (
            <>
              {label && (
                <Listbox.Label className="block text-sm leading-5 font-medium text-jll-color-text-base-subdued">
                  {label}
                </Listbox.Label>
              )}
              <div className="relative">
                <span className="inline-block w-full">
                  <Listbox.Button
                    className={`flex flex-row items-center w-full py-1.5 px-3 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm cursor-pointer rounded-[48px] border-0 font-semibold hover:bg-jll-color-surface-info-subdued hover:text-jll-color-surface-info-default ${selectClasses} ${className} ${
                      open && "!text-white !bg-jll-color-surface-info-default"
                    }`}
                  >
                    <span className="block truncate">
                      {selectedOptions.length < 1
                        ? title
                        : `${title} (${selectedOptions.length})`}
                    </span>
                    <span className="flex items-center ml-2">
                      <FiChevronDown className="h-5 w-5" aria-hidden="true" />
                    </span>
                  </Listbox.Button>
                </span>

                <Transition
                  enter="transition duration-100 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                  className="absolute mt-1 w-full rounded-md bg-white shadow-lg z-30"
                >
                  <Listbox.Options
                    static
                    className={cn(
                      'origin-top-right absolute max-h-60 min-w-[230px] w-fit rounded-lg bg-white shadow-drop z-20 after:content-[""] after:absolute after:right-[10px] after:-top-[10px] after:border-[10px] after:border-solid after:border-x-transparent after:border-t-transparent after:border-t-0 after:border-b-white overflow-y-auto',
                      {
                        "right-0": align === "right",
                        "left-0": align !== "right",
                      }
                    )}
                  >
                    <div className="select-none relative px-5 pt-4 pb-2.5 flex flex-row items-center justify-between space-x-2">
                      <span className="text-jll-color-coldGray-12 font-semibold text-base">
                        {title}
                      </span>
                      {!!selectedOptions?.length && (
                        <Button
                          variant="neutral"
                          leadingIcon={FiRotateCcw}
                          leadingIconClass="w-5 h-5 text-jll-color-text-base-subdued"
                          onClick={() => handleFilter([], name)}
                        />
                      )}
                    </div>
                    {onChangeInput && (
                      <div className="select-none relative px-4">
                        <Input
                          className="!mb-3"
                          name={name}
                          onChange={onChangeInput}
                          value={searchWord}
                          placeholder={`Search ${name}`}
                          isClearable
                          onKeyDown={(e) => {
                            e.stopPropagation();
                          }}
                        />
                      </div>
                    )}
                    {options.map((option: any) => {
                      const selected = isSelected(option.value);
                      const isMissing = option?.value
                        ?.toString()
                        ?.includes("_missing");
                      return (
                        <Listbox.Option key={option.value} value={option.value}>
                          {({ active }) => (
                            <div
                              className={`${
                                active
                                  ? "bg-jll-color-surface-info-subdued text-jll-color-surface-info-default"
                                  : "text-jll-color-text-base-subdued"
                              } 
                            ${selected && "bg-jll-color-surface-info-subdued"}
                            select-none flex justify-between items-center px-5 py-2.5`}
                            >
                              <div
                                className={`${
                                  selected || active
                                    ? "text-jll-color-surface-info-default"
                                    : "text-jll-color-text-base-subdued"
                                } flex items-center font-medium whitespace-nowrap ${
                                  isMissing &&
                                  "!text-jll-color-surface-accent-default"
                                }`}
                              >
                                {isMissing && <FiXCircle className="mr-1.5" />}
                                {option.label}
                              </div>
                              {selected && (
                                <span className="flex items-center pl-3">
                                  <FiCheck
                                    className="h-5 w-5 text-jll-color-surface-info-default"
                                    aria-hidden="true"
                                  />
                                </span>
                              )}
                            </div>
                          )}
                        </Listbox.Option>
                      );
                    })}
                  </Listbox.Options>
                </Transition>
              </div>
            </>
          );
        }}
      </Listbox>
    </div>
  );
};

export default MultipleSelect;
