import { Page, View, Image, Text, Link } from "@react-pdf/renderer";
import { PAGE_ORIENTATION, PAGE_SIZE } from "constant";
import styles from "./styles";
import LogoImg from "assets/pdf/logo.png";
import {
  capitalize,
  formatPhoneNumber,
  getDecimalFormating,
  getImageFromURL,
  ordinalSuffixOf,
  removeNewlines,
} from "utils";
import { AvailabilityProps } from "types";
import LeftIcon from "assets/pdf/icon-left.png";
import FileIcon from "assets/pdf/file.png";
import PlanIcon from "assets/pdf/plan.png";
import ImageIcon from "assets/pdf/image.png";

interface IPageSuiteList {
  property?: any;
  propertyMap?: any;
  availabilities?: AvailabilityProps[];
  meta?: any;
}

const SUITE_LIST_COLUMNS = [
  { key: "floor", label: "Floor", width: "10%" },
  { key: "name", label: "Suite", width: "15%" },
  { key: "size", label: "Size (sqft)", width: "12%" },
  { key: "rate", label: "Rate/SF", width: "12%" },
  { key: "type", label: "Availability Type", width: "18%" },
  { key: "date_available", label: "Date Availability", width: "18%" },
  { key: "media", label: "Media", width: "15%" },
];

const getSuiteValue = (
  availability: AvailabilityProps,
  key: string,
  meta: any
) => {
  if (!key) return;
  switch (key) {
    case "floor":
      return (
        <Text style={styles.right.col.contentTxt}>
          {ordinalSuffixOf(availability?.floor) || ""}
        </Text>
      );
    case "name":
      return (
        <View style={{ display: "flex", flexDirection: "row" }}>
          <Link
            src={`#Suite_${availability?.pk}`}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              height: 15,
            }}
          >
            <Text
              style={{
                ...styles.right.col.contentTxt,
                color: "#0064C8",
                marginRight: 4,
              }}
            >
              {`Suite ${availability?.name || ""}`}
            </Text>
            <Image src={LeftIcon} style={{ width: 10, height: 10 }} />
          </Link>
        </View>
      );

    case "size":
      let size;
      if (!availability?.min_floor_area && !availability?.max_floor_area)
        size = "N/A";
      else if (availability?.min_floor_area && !availability?.max_floor_area) {
        size = getDecimalFormating(availability?.min_floor_area);
      } else if (
        !availability?.min_floor_area &&
        availability?.max_floor_area
      ) {
        size = getDecimalFormating(availability?.max_floor_area);
      } else if (
        availability?.min_floor_area === availability?.max_floor_area
      ) {
        size = getDecimalFormating(availability?.max_floor_area);
      } else if (availability?.min_floor_area && availability?.max_floor_area) {
        size = `${getDecimalFormating(
          availability?.min_floor_area
        )}-${getDecimalFormating(availability?.max_floor_area)}`;
      }
      return <Text style={styles.right.col.contentTxt}>{size || ""}</Text>;

    case "rate":
      let price;
      if (!availability?.min_price && !availability?.max_price) {
        price = "Negotiable";
      } else if (availability?.min_price && !availability?.max_price) {
        price = `$${getDecimalFormating(Number(availability?.min_price))} ${
          availability?.rent_type
            ? `(${
                meta?.SUITE_RENT_TYPE_ABBREVIATED_MAP?.[availability?.rent_type]
              })`
            : ""
        }`;
      } else if (!availability?.min_price && availability?.max_price) {
        price = `$${getDecimalFormating(Number(availability?.max_price))} ${
          availability?.rent_type
            ? `(${
                meta?.SUITE_RENT_TYPE_ABBREVIATED_MAP?.[availability?.rent_type]
              })`
            : ""
        }`;
      } else if (availability?.min_price === availability?.max_price) {
        price = `$${getDecimalFormating(Number(availability?.max_price))} ${
          availability?.rent_type
            ? `(${
                meta?.SUITE_RENT_TYPE_ABBREVIATED_MAP?.[availability?.rent_type]
              })`
            : ""
        }`;
      } else if (!!availability?.min_price && !!availability?.max_price) {
        price = `$${getDecimalFormating(Number(availability?.min_price))}-
                $${getDecimalFormating(Number(availability?.max_price))} ${
          availability?.rent_type
            ? `(${
                meta?.SUITE_RENT_TYPE_ABBREVIATED_MAP?.[availability?.rent_type]
              })`
            : ""
        }`;
      }
      return <Text style={styles.right.col.contentTxt}>{price || ""}</Text>;

    case "type":
      return (
        <>
          {!availability?.tenure_types?.length ? (
            <Text style={styles.right.col.contentTxt}>N/A</Text>
          ) : (
            availability?.tenure_types?.map((type, index) => (
              <Text
                style={{
                  ...styles.typeTxt,
                  backgroundColor: "#F6F7F7",
                  color: "#60666E",
                }}
                key={index}
              >
                {type?.toLowerCase() === "rent" ? "Lease" : type}
              </Text>
            ))
          )}
        </>
      );

    case "date_available":
      return (
        <Text style={styles.right.col.contentTxt}>
          {availability?.date_available || "N/A"}
        </Text>
      );

    case "media":
      return (
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {!!availability?.images?.length && (
            <Link
              src={availability?.images?.[0] || ""}
              style={{ width: 14, height: 14, marginRight: 8 }}
            >
              <Image
                src={ImageIcon}
                style={{ width: "100%", height: "100%" }}
              />
            </Link>
          )}
          {!!availability?.floorplans?.length && (
            <Link
              src={availability?.floorplans[0]}
              style={{ width: 14, height: 14, marginRight: 8 }}
            >
              <Image src={PlanIcon} style={{ width: "100%", height: "100%" }} />
            </Link>
          )}

          {!!availability?.brochures?.length && (
            <Link
              src={availability?.brochures[0]}
              style={{ width: 14, height: 14 }}
            >
              <Image src={FileIcon} style={{ width: "100%", height: "100%" }} />
            </Link>
          )}
        </View>
      );

    default:
      if (availability?.hasOwnProperty(key)) {
        // @ts-ignore
        return (
          <Text style={styles.right.col.contentTxt}>
            {/* @ts-ignore */}
            {availability?.[key] || "N/A"}
          </Text>
        );
      }
      return <Text style={styles.right.col.contentTxt}>N/A</Text>;
  }
};

const PageSuiteList: React.FC<IPageSuiteList> = ({
  property,
  propertyMap,
  availabilities,
  meta,
}) => {
  return (
    <Page
      size={PAGE_SIZE}
      orientation={PAGE_ORIENTATION.LANDSCAPE}
      style={styles.page}
      wrap={false}
    >
      {/* Left Side */}
      <View style={styles.leftSideView}>
        {/* Info Section */}
        <View style={styles.infoView}>
          {!!property?.images?.length && (
            <Image
              src={getImageFromURL(property?.images[0])}
              style={styles.leftImg}
            />
          )}
          <View style={styles.titleView}>
            <Text style={styles.id}>ID {property?.pk || property?.id}</Text>
            <Text style={styles.titleTxt}>{property?.title}</Text>
          </View>
          <View style={styles.addressView}>
            <Text style={styles.address}>{property?.address}</Text>
            <Text style={styles.address}>
              {" "}
              {[
                property?.city,
                [property?.state, property?.post_code].join(" "),
              ].join(", ")}
            </Text>
          </View>
          <View style={{ display: "flex", flexDirection: "row" }}>
            <Text style={styles.typeTxt}>
              {capitalize(property?.property_type)}
            </Text>
            {!!property?.tenure_types?.length && (
              <Text style={styles.tenantTypeTxt}>
                {property?.tenure_types?.length === 1
                  ? property?.tenure_types[0]?.toLowerCase() === "rent"
                    ? "Lease"
                    : capitalize(property?.tenure_types[0])
                  : "Lease/Sale"}
              </Text>
            )}
          </View>
        </View>
        {/* Location Section */}
        <View style={styles.location.view}>
          <Text style={styles.location.title}>Location</Text>
          <Image
            src={getImageFromURL(propertyMap)}
            style={styles.location.img}
          />
        </View>
      </View>

      {/* Right Side  */}
      <View style={styles.rightSideView}>
        <View style={{ display: "flex", flexDirection: "row" }}>
          <Text style={styles.right.title}>Availabilities</Text>
        </View>
        <View style={styles.right.table}>
          <View style={styles.right.row}>
            {SUITE_LIST_COLUMNS.map((col, idx) => {
              if (idx === 0)
                return (
                  <View style={{ ...styles.right.col.view, width: col.width }}>
                    <Text style={{ ...styles.right.col.txt }}>{col.label}</Text>
                  </View>
                );
              return (
                <View
                  style={{
                    ...styles.right.col.view,
                    ...styles.right.col.borderView,
                    width: col.width,
                  }}
                >
                  <Text style={styles.right.col.txt}>{col.label}</Text>
                </View>
              );
            })}
          </View>

          {availabilities?.map((availability, idx) => {
            if (!availability) return;
            return (
              <View
                key={idx}
                style={{
                  ...styles.right.row,
                  borderTopWidth: 1,
                  borderTopStyle: "solid",
                  borderTopColor: "#DDDFE1",
                }}
              >
                {SUITE_LIST_COLUMNS.map((col, idx1) => {
                  if (idx1 === 0)
                    return (
                      <View
                        key={idx1}
                        style={{
                          ...styles.right.col.view,
                          width: col.width,
                          paddingTop: 14,
                          paddingBottom: 14,
                        }}
                      >
                        {getSuiteValue(availability, col.key, meta)}
                      </View>
                    );
                  return (
                    <View
                      key={idx1}
                      style={{
                        ...styles.right.col.view,
                        ...styles.right.col.borderView,
                        width: col.width,
                        paddingTop: 14,
                        paddingBottom: 14,
                      }}
                    >
                      {getSuiteValue(availability, col.key, meta)}
                    </View>
                  );
                })}
              </View>
            );
          })}
        </View>
        {!!property?.brokers?.length && (
          <View style={styles.brokerPanel}>
            {property?.brokers.slice(0, 4)?.map((item: any, idx: number) => (
              <View
                style={{
                  ...styles.brokerView,
                  ...{ borderLeftWidth: idx === 0 ? 0 : 1 },
                }}
              >
                <Image
                  src={getImageFromURL(item?.photo)}
                  style={styles.brokerImg}
                />
                <View style={styles.brokerContent}>
                  <Text style={styles.brokerName}>
                    {removeNewlines(item?.name)}
                  </Text>
                  <Text style={styles.brokerTxt}>{item?.email}</Text>
                  <Text style={styles.brokerTxt}>
                    {formatPhoneNumber(item?.telephone)}
                  </Text>
                </View>
              </View>
            ))}
          </View>
        )}
      </View>

      <View style={styles.logo.view}>
        <Image src={LogoImg} style={styles.logo.img} />
      </View>
    </Page>
  );
};

export default PageSuiteList;
