import { LayoutEditAvailability as Layout } from "ui-organisms";
import {
  AVAILABILITIES_STEPS,
  AVAILABILITY_TABS,
  NEW_ID,
  PUBLISH_STATUS_DRAFT,
  PUBLISH_STATUS_PUBLISHED,
  URLS,
} from "constant";
import cn from "classnames";
import queryString from "query-string";
import { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { StepTabBar } from "ui-molecules";
import AvailabilityEdit from "./AvailabilityEdit";
import AvailabilityContacts from "./AvailabilityContacts";
import AvailabilityMedia from "./AvailabilityMedia";
import { GlobalContext } from "context";
import { FiAlertCircle, FiCheckCircle } from "react-icons/fi";
import { Dot } from "ui-atoms";
import AvailabilityPublish from "./AvailabilityPublish";
import React from "react";

const Availability = () => {
  const { state } = useContext(GlobalContext);
  const { availability } = state;
  const location = useLocation();
  const { propertyId, availabilityId } = useParams();
  const isNew = availabilityId === NEW_ID;
  const ref = useRef<any>(null);
  const [diffFields, setDiffFields] = useState({});
  const [activeTab, setActiveTab] = useState(AVAILABILITY_TABS.EDIT);
  const [isOLM, setIsOLM] = useState(false);

  useEffect(() => {
    const filters = queryString.parse(location?.search, {
      arrayFormat: "bracket",
    });
    if (filters?.tab) {
      setActiveTab(filters?.tab?.toString()?.toLowerCase());
    }
  }, [location]);

  return (
    <Layout className="!bottom-[80px]" olmToggle={{ isOLM, setIsOLM }}>
      <div className="relative w-full h-full">
        {!isNew && (
          <div className="flex flex-row justify-between px-8 h-[80px] fixed top-[64px] right-0 left-[360px] z-10 shadow-detail-status-nav border-b border-jll-color-coldGray-3">
            <StepTabBar
              current={activeTab}
              steps={AVAILABILITIES_STEPS}
              className="h-full"
              baseUrl={`/property/${propertyId}/availability/${availabilityId}/${URLS.AVAILABILITY.FORM}`}
              tabLabel="Availability"
            />

            <div className="flex flex-col justify-center items-end space-y-1">
              <div className="flex flex-row text-sm space-x-2 items-center justify-end">
                <div>Status:</div>
                <div className="flex flex-row items-center">
                  <Dot
                    variant={
                      availability?.publish_status === PUBLISH_STATUS_PUBLISHED
                        ? "success"
                        : availability?.publish_status === PUBLISH_STATUS_DRAFT
                        ? "warning"
                        : "accent"
                    }
                    className="mr-2"
                  />
                  <span className="font-semibold">
                    {availability?.publish_status === PUBLISH_STATUS_PUBLISHED
                      ? "Published"
                      : availability?.publish_status === PUBLISH_STATUS_DRAFT
                      ? "Draft"
                      : "Un-Published"}
                  </span>
                </div>
              </div>

              <div className="flex items-center text-sm">
                {availability?.dirty ? (
                  <div className="flex items-center">
                    <FiAlertCircle className="w-4 h-4 text-jll-color-coldGray-5 mr-2" />
                    Local changes haven't been published
                  </div>
                ) : availability?.publish_status == PUBLISH_STATUS_PUBLISHED ? (
                  <div className="flex items-center">
                    <FiCheckCircle className="w-4 h-4 text-jll-color-coldGray-5 mr-2" />
                    Local data matches OLM
                  </div>
                ) : (
                  <div className="flex items-center">
                    <FiAlertCircle className="w-4 h-4 text-jll-color-coldGray-5 mr-2" />
                    This property is not published to OLM
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        <div
          className={cn(
            "w-full absolute left-0 right-0 bottom-0 overflow-auto",
            {
              "top-0": isNew,
              "top-[80px]": !isNew,
            }
          )}
        >
          {activeTab === AVAILABILITY_TABS.EDIT && (
            <AvailabilityEdit
              ref={ref}
              onDiffFields={setDiffFields}
              diffFields={diffFields}
              isOLM={isOLM}
            />
          )}
          {activeTab === AVAILABILITY_TABS.CONTACTS && (
            <AvailabilityContacts onDiffFields={setDiffFields} />
          )}
          {activeTab === AVAILABILITY_TABS.MEDIA && (
            <AvailabilityMedia
              ref={ref}
              onDiffFields={setDiffFields}
              diffFields={diffFields}
            />
          )}
          {activeTab === AVAILABILITY_TABS.PUBLISH && (
            <AvailabilityPublish
              onSave={ref?.current?.onClickSubmit || null}
              diffFields={diffFields}
            />
          )}
        </div>
      </div>
    </Layout>
  );
};

export default React.memo(Availability);
