import type { FC } from "react";
import cn from "classnames";

export interface TdProps {
  className?: string;
  children?: any;
  width?: string | number;
  isEdit?: boolean;
}

const Td: FC<TdProps> = ({ children, className, isEdit, ...props }) => {
  return (
    <td
      className={cn(
        "whitespace-nowrap px-3 py-4 text-sm text-jll-color-text-base-default relative z-0",
        className
      )}
      {...props}
    >
      {children}
      {isEdit && (
        <div className="absolute top-[16px] bottom-[16px] right-0 w-px bg-jll-color-coldGray-3"></div>
      )}
    </td>
  );
};

export default Td;
