import queryString from "query-string";
import axios from "../axiosConfig";
import { APIProps } from "types";
import { API_PREFIX_URL } from "constant";

export const getAvailabilitiesAPI = async (props: APIProps) => {
  const param_str = queryString.stringify(props, {
    arrayFormat: "bracket",
  });
  const res = await axios.get(`${API_PREFIX_URL}/suites/?${param_str}`);
  return res;
};

export const getAvailabilitiesExcelAPI = async (props: APIProps) => {
  const param_str = queryString.stringify(props, {
    arrayFormat: "bracket",
  });
  const res = await axios.get(`${API_PREFIX_URL}/suites/?${param_str}`, {
    responseType: "blob",
  });
  return res;
};

export const getAvailabilityAPI = async (id: string) => {
  const res = await axios.get(`${API_PREFIX_URL}/suites/${id}/`);
  return res;
};

// TODO replace any
export const postAvailabilityAPI = async (payload: any) => {
  const res = await axios.post(`${API_PREFIX_URL}/suites/`, payload);
  return res;
};

// TODO replace any
export const putAvailabilityAPI = async (id: number, data: any) => {
  const res = await axios.put(`${API_PREFIX_URL}/suites/${id}/`, data);
  return res;
};

// TODO replace any
export const patchAvailabilityAPI = async ({
  id,
  payload,
}: {
  id: number;
  payload: any;
}) => {
  const res = await axios.patch(`${API_PREFIX_URL}/suites/${id}/`, payload);
  return res;
};

export const postAvailabilityBrokerAPI = async ({
  role,
  entity_id,
  broker_id,
}: {
  role: string;
  entity_id: number;
  broker_id: number;
}) => {
  const res = await axios.post(`${API_PREFIX_URL}/suite/broker/`, {
    role: role,
    suite: entity_id,
    broker: broker_id,
  });
  return res;
};

export const deleteAvailabilityAPI = async ({ id, payload }: any) => {
  const res = await axios.delete(`${API_PREFIX_URL}/suites/${id}/`, {
    data: payload,
  });
  return res;
};

export const deleteAvailabilityBrokerAPI = async ({ id }: { id: number }) => {
  const res = await axios.delete(`${API_PREFIX_URL}/suite/broker/${id}/`);
  return res;
};
