import * as Yup from "yup";
import { useApiCall, useComponentVisible } from "hooks";
import { useContext, useEffect, useState } from "react";
import { FiCheck, FiEdit3, FiX } from "react-icons/fi";
import { patchAvailabilityAPI, patchPropertyAPI } from "services";
import { Checkbox, DatePicker, SelectAutoComplete } from "ui-atoms";
import {
  getApiDate,
  getApiPayloadFromForm,
  getOptionValue,
  getUTCDate,
  isCommonItem,
} from "utils";
import EditableIconBtn from "./EditableIconBtn";
import { useFormik } from "formik";
import {
  DATE_AVAILABLE_IMMEDIATE,
  LEASE_TERM_NEGOTIABLE,
  OLM_AVAILABILITY_FIELDS,
  OLM_PROPERTY_FIELDS,
  PROPERTY_VALIDATION,
  STATUS_ACTIVE,
} from "constant";
import { GlobalContext } from "context";

interface IEditableSingleField {
  initValues?: any;
  valueKeys: string[];
  pk?: string | number;
  type?: "number" | "date" | "text" | "file" | "select" | "check";
  children?: any;
  updateItem?: any;
  kind: "property" | "availability";
  isEditMode?: boolean;
  options?: any;
  loadOptions?: any;
  label?: string; // only check
  isCreatable?: boolean; // only select
}

const EditableSingleField: React.FC<IEditableSingleField> = ({
  initValues,
  type,
  valueKeys,
  pk,
  children,
  updateItem,
  kind = "property",
  isEditMode = false,
  options,
  label,
  isCreatable,
  loadOptions,
}) => {
  const { state } = useContext(GlobalContext);
  const { meta } = state;
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(true);
  const [isEditing, setIsEditing] = useState(false);
  const [patchProperty] = useApiCall(patchPropertyAPI);
  const [patchAvailability] = useApiCall(patchAvailabilityAPI);
  const [validationSchema, setValidationSchema] = useState(
    Yup.object().shape({})
  );
  const [isAvailable, setIsAvailable] = useState(false);
  const [selectedMarketOption, setSelectedMarketOption] =
    useState<any>(undefined);

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    values,
    errors,
    touched,
    setValues,
    isValid,
    dirty,
  }: any = useFormik({
    initialValues: {},
    validationSchema,
    onSubmit: () => {
      try {
        let payload: any = getApiPayloadFromForm(initValues, values);
        if (type === "date") {
          payload[valueKeys[0]] = getApiDate(values?.[valueKeys[0]]);
        }
        let service = null;
        if (kind === "property") {
          service = patchProperty;
          if (
            payload &&
            initValues?.publish_status === STATUS_ACTIVE &&
            isCommonItem(Object.keys(payload), OLM_PROPERTY_FIELDS)
          ) {
            payload["publish_status"] = STATUS_ACTIVE;
          }
        } else if (kind === "availability") {
          service = patchAvailability;
          if (
            payload &&
            initValues?.publish_status === STATUS_ACTIVE &&
            isCommonItem(Object.keys(payload), OLM_AVAILABILITY_FIELDS)
          ) {
            payload["publish_status"] = STATUS_ACTIVE;
          }
        }
        if (!!payload)
          service({
            id: pk,
            payload,
          }).then((res: any) => {
            updateItem({ [valueKeys[0]]: res?.[valueKeys[0]] }, pk);
            if (valueKeys[0] === "market") {
              updateItem({ market_name: res?.["market_name"] }, pk);
            }
          });
        setIsEditing(false);
        setIsComponentVisible(false);
      } catch (err) {
        setIsEditing(false);
        setIsComponentVisible(false);
      }
    },
  });

  useEffect(() => {
    if (!initValues || !valueKeys || !isEditing) return;
    let init: any = {};
    valueKeys?.forEach((key: string) => (init[key] = initValues?.[key]));
    setValues(init);

    let validation_fields: any = {};
    if (kind === "property") {
      validation_fields = PROPERTY_VALIDATION?.fields;
    }
    if (valueKeys[0] === "market") {
      setSelectedMarketOption({
        value: initValues?.market || "",
        label: initValues?.market_name || "",
      });
    }
    const newValidations: any = Object.keys(validation_fields)
      .filter((key) => valueKeys?.includes(key))
      .reduce((obj: any, key) => {
        obj[key] = validation_fields?.[key];
        return obj;
      }, {});
    setValidationSchema(Yup.object().shape({ ...newValidations }));
  }, [initValues, valueKeys, isEditing]);

  useEffect(() => {
    if (!!getApiPayloadFromForm(initValues, values)) setIsAvailable(true);
    else setIsAvailable(false);
  }, [initValues, values]);

  return (
    <>
      {isEditMode ? (
        <div
          onClick={(e: any) => {
            e.stopPropagation();
          }}
        >
          {isComponentVisible && isEditing ? (
            <div ref={ref}>
              <form onSubmit={handleSubmit}>
                {type !== "select" && type !== "check" && (
                  <div className="flex flex-row items-center px-2 py-1 border-jll-color-coldGray-3 border rounded">
                    {(type === "text" || type === "number") && (
                      <input
                        type={type}
                        name={valueKeys[0]}
                        className="bg-transparent border-0 w-full min-w-[80px] text-sm font-base focus:outline-0 focus:bg-trasparent hover:bg-trasparent focus:border-transparent focus:ring-0 p-0"
                        min={type === "number" ? 0 : undefined}
                        value={values?.[valueKeys[0]]}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    )}
                    {type === "date" && (
                      <DatePicker
                        className="!mb-0 text-base"
                        pickerDivClassName="!shadow-none"
                        pickerClassName="bg-transparent border-0 w-full min-w-[80px] text-sm font-base focus:outline-0 focus:bg-trasparent hover:bg-trasparent focus:border-transparent focus:ring-0 p-0"
                        selected={
                          values[valueKeys[0]]
                            ? getUTCDate(values[valueKeys[0]])
                            : getUTCDate()
                        }
                        value={
                          values[valueKeys[0]] &&
                          getUTCDate(values[valueKeys[0]])
                        }
                        onChange={(date: Date) =>
                          setFieldValue(valueKeys[0], date)
                        }
                        popperProps={{ strategy: "fixed" }}
                      />
                    )}
                  </div>
                )}
                {type === "select" && (
                  <>
                    {valueKeys[0] === "date_available" ? (
                      <SelectAutoComplete
                        className="!mb-0 min-w-[200px]"
                        name={valueKeys[0]}
                        onChange={(option: any) => {
                          if (!option) setFieldValue(valueKeys[0], null);
                          else setFieldValue(valueKeys[0], option?.value);
                        }}
                        onBlur={handleBlur}
                        value={
                          values?.date_available === ""
                            ? { label: "", value: "" }
                            : getOptionValue(
                                meta?.suite?.date_available &&
                                  values?.date_available &&
                                  values?.date_available in
                                    meta?.suite?.date_available
                                  ? meta?.suite?.date_available[
                                      values?.date_available
                                    ]?.value
                                  : values?.date_available
                                  ? values?.date_available
                                  : DATE_AVAILABLE_IMMEDIATE
                              )
                        }
                        options={options}
                        isCreatable
                        isCustomCreatable
                      />
                    ) : valueKeys[0] === "lease_term" ? (
                      <SelectAutoComplete
                        className="!mb-0 min-w-[200px]"
                        name={valueKeys[0]}
                        onChange={(option: any) => {
                          if (!option) setFieldValue(valueKeys[0], null);
                          else setFieldValue(valueKeys[0], option?.value);
                        }}
                        onBlur={handleBlur}
                        value={getOptionValue(
                          meta?.suite?.lease_term &&
                            values?.lease_term &&
                            values?.lease_term in meta?.suite?.lease_term
                            ? meta?.suite?.lease_term[values?.lease_term]?.value
                            : LEASE_TERM_NEGOTIABLE
                        )}
                        options={options}
                        isCreatable
                      />
                    ) : valueKeys[0] === "market" ? (
                      <SelectAutoComplete
                        className="!mb-0"
                        name={valueKeys[0]}
                        onChange={(option: any) => {
                          if (!option) {
                            setFieldValue(valueKeys[0], null);
                            setSelectedMarketOption(undefined);
                          } else {
                            setFieldValue(valueKeys[0], option?.value);
                            setSelectedMarketOption(option);
                          }
                        }}
                        onBlur={handleBlur}
                        value={selectedMarketOption}
                        loadOptions={loadOptions}
                        isCreatable={isCreatable}
                      />
                    ) : (
                      <SelectAutoComplete
                        className="!mb-0"
                        name={valueKeys[0]}
                        onChange={(option: any) => {
                          if (!option) setFieldValue(valueKeys[0], null);
                          else setFieldValue(valueKeys[0], option?.value);
                        }}
                        onBlur={handleBlur}
                        value={options?.find(
                          (option: any) =>
                            option?.value?.toString() ===
                            values?.[valueKeys[0]]?.toString()
                        )}
                        options={options}
                        isCreatable={isCreatable}
                      />
                    )}
                  </>
                )}
                {type === "check" && (
                  <Checkbox
                    className="!mb-0"
                    name={valueKeys[0]}
                    label={label}
                    onChange={(e: any) => {
                      setFieldValue(valueKeys[0], e.target.checked);
                    }}
                    onBlur={handleBlur}
                    checked={values?.[valueKeys[0]]}
                    id={valueKeys[0]}
                  />
                )}
                {!!errors?.[valueKeys[0]] && (
                  <p className="text-xs text-jll-color-surface-accent-default whitespace-normal">
                    {errors?.[valueKeys[0]]}
                  </p>
                )}

                <div className="flex flex-row items-center space-x-0.5 mt-2.5">
                  <EditableIconBtn
                    icon={FiCheck}
                    type="submit"
                    variant="success"
                    className="w-full"
                    disabled={!isValid || !dirty || !isAvailable}
                    onClick={handleSubmit}
                  />
                  <EditableIconBtn
                    icon={FiX}
                    variant="grey"
                    className="w-full"
                    onClick={() => {
                      setIsEditing(false);
                      setIsComponentVisible(false);
                    }}
                  />
                </div>
              </form>
            </div>
          ) : (
            <div
              className="flex flex-row items-center justify-between space-x-2"
              onDoubleClick={() => {
                setIsEditing(true);
                setIsComponentVisible(true);
              }}
            >
              <div>{children}</div>
              <FiEdit3
                className="w-4 h-4 text-jll-color-coldGray-4 cursor-pointer"
                onClick={() => {
                  setIsEditing(true);
                  setIsComponentVisible(true);
                }}
              />
            </div>
          )}
        </div>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

export default EditableSingleField;
