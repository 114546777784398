import { useEffect, useState, useMemo, useContext } from "react";
import type { FC } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FiPlusCircle, FiSearch } from "react-icons/fi";
import { EmptyResult, Loading, Button, Input } from "ui-atoms";
import {
  META_TAGS,
  SET_LOADING_MODAL,
  STATUS_ACTIVE,
  STATUS_REMOVED,
  URLS,
} from "constant";
import {
  Table,
  ShowMoreItems,
  Facets,
  TeamListingModal,
  StyledTeamListingValue,
} from "ui-molecules";
import queryString from "query-string";
import { LayoutListing } from "ui-organisms";
import { TABLE_COLUMNS, SEARCH_RESULT_LIMIT } from "./constants";
import {
  DirectionEnum,
  TeamListingProps,
  type FacetsProps,
  type FilteredFacetProps,
  type PropertyProps,
} from "types";
import { getTeamListingsAPI, patchTeamListingAPI } from "services";
import { getFilteredFacets } from "utils";
import { useApiCall } from "hooks";
import { Helmet } from "react-helmet-async";
import React from "react";
import cn from "classnames";
import debounce from "lodash.debounce";
import { GlobalContext } from "context";
import SlidingPane from "react-sliding-pane";
import TeamListingPane from "./TeamListingPane";

const TAB_LIST = [
  { label: "My Team Listings", key: "me" },
  { label: "All Team Listings", key: "all" },
];

const TeamListings: FC = () => {
  const session = JSON.parse(sessionStorage.getItem("session") || "null");
  const { dispatch } = useContext(GlobalContext);
  const [fetchData, loading] = useApiCall(getTeamListingsAPI);
  const [patchTeamListing] = useApiCall(patchTeamListingAPI);
  const location = useLocation();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("me");
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState<TeamListingProps[]>();
  const [filterFacets, setFilterFacets] = useState<FilteredFacetProps[]>([]);
  const [paramFilters, setParamFilters] = useState<any>(null);
  const [sortFields, setSortFields] = useState<string[]>([]);
  const [keyword, setKeyword] = useState<string>();
  const [isTeamListingModal, setIsTeamListingModal] = useState(false);
  const [selectedListing, setSelectedListing] = useState<any>(null);
  const [isSlidePane, setIsSlidePane] = useState<any>(undefined);

  useEffect(() => {
    const filters = queryString.parse(location?.search, {
      arrayFormat: "bracket",
    });
    if (filters?.tab) {
      setActiveTab(filters?.tab?.toString()?.toLowerCase());
    }
    setKeyword(filters?.keyword?.toString() || "");
    setParamFilters(filters);
  }, [location]);

  useEffect(() => {
    if (
      paramFilters === null ||
      (isSlidePane !== undefined && isSlidePane) ||
      isTeamListingModal
    )
      return;
    setKeyword(paramFilters?.keyword || "");
    setPage(1);
    setData([]);
    let payload = {
      page: 1,
      limit: SEARCH_RESULT_LIMIT,
      active_status: [STATUS_ACTIVE],
      ...paramFilters,
    };
    if (activeTab === "me") {
      payload = {
        ...payload,
        updated_user: [session?.id],
      };
    }
    delete payload.tab;
    fetchData(payload).then((data: any) => {
      setData([...data?.docs]);
      setPage(data?.page);
      setTotal(data?.total);
      setFilterFacets(getFilteredFacets(data?.facets as FacetsProps));
      setSortFields(data?.sort_fields);
    });
  }, [paramFilters, isSlidePane, isTeamListingModal]);

  const onClickSorting = (sort: string) => {
    const updatedFilters = {
      ...paramFilters,
      sort,
      direction:
        paramFilters?.direction === DirectionEnum.asc
          ? DirectionEnum?.desc
          : DirectionEnum.asc,
    };
    navigate(
      `${location.pathname}?${queryString.stringify(updatedFilters, {
        arrayFormat: "bracket",
      })}`
    );
  };

  const onClickRow = (pk: PropertyProps["pk"]) => () => {
    navigate(`/team-listing/${pk}/${URLS.TEAM_LISTING.PROPERTIES}`);
  };

  const onClickShowMore = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    setData([]);
    let payload = {
      page: 1,
      limit: SEARCH_RESULT_LIMIT,
      active_status: [STATUS_ACTIVE],
      ...paramFilters,
    };
    if (activeTab === "me") {
      payload = {
        ...payload,
        updated_user: [session?.id],
      };
    }
    delete payload.tab;
    fetchData(payload).then((data: any) => {
      setData([...data?.docs]);
      setPage(data?.page);
      setTotal(data?.total);
      setFilterFacets(getFilteredFacets(data?.facets as FacetsProps));
      setSortFields(data?.sort_fields);
    });
  };

  const updateKeywordFilter = (e: any) => {
    const filters = queryString.parse(location?.search);
    const updatedFilters = { ...filters };
    if (e.target.value) {
      updatedFilters["keyword"] = e.target.value;
    } else if (updatedFilters?.hasOwnProperty("keyword")) {
      delete updatedFilters.keyword;
    }
    navigate(
      `${location.pathname}?${queryString.stringify(updatedFilters, {
        arrayFormat: "bracket",
      })}`
    );
  };

  const debounceUpdateKeyword = useMemo(
    () => debounce(updateKeywordFilter, 300),
    [paramFilters]
  );

  const onChangeInput = (e: any) => {
    setKeyword(e.target.value);
    debounceUpdateKeyword(e);
  };

  const handleTab = (value: string) => {
    const updatedFilters: any = { tab: value };
    navigate(
      `${location.pathname}?${queryString.stringify(updatedFilters, {
        arrayFormat: "bracket",
      })}`
    );
  };

  const handleStatus = async (pk: number, status: number) => {
    try {
      dispatch({
        type: SET_LOADING_MODAL,
        payload: {
          open: true,
          label: `${
            status === STATUS_ACTIVE ? "Reinstating" : "Deleting"
          } the Team Listing`,
        },
      });
      const res = await patchTeamListing({ pk, active_status: status });
      setTimeout(() => {
        dispatch({
          type: SET_LOADING_MODAL,
          payload: {
            open: false,
            label: null,
          },
        });
      }, 100);
      if (!res) return;
      const index = data?.findIndex((item) => item.pk === pk);
      if (index !== undefined && index > -1) {
        const newData = [...(data || [])];
        if (paramFilters?.active_status?.length > 1) {
          newData[index]["active_status"] = status;
        } else newData.splice(index, 1);
        setData(newData);
        setTotal(total - 1);
      }
    } catch (err) {
      setTimeout(() => {
        dispatch({
          type: SET_LOADING_MODAL,
          payload: {
            open: false,
            label: null,
          },
        });
      }, 100);
    }
  };

  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>{META_TAGS?.team_listings?.title}</title>
        <meta property="og:title" content={META_TAGS?.team_listings?.title} />
      </Helmet>
      <LayoutListing>
        <LayoutListing.Header>
          <h1 className="text-heading1">Team Listings</h1>
          <div className="flex space-x-4 items-center">
            <Input
              className="w-[400px] !mb-0"
              name="search"
              leadingIcon={FiSearch}
              value={keyword}
              onChange={onChangeInput}
              isClearable
              placeholder="Search by set name, creators name or city name"
            />
            <Button
              variant="primary"
              leadingIcon={FiPlusCircle}
              onClick={() => {
                // setIsTeamListingModal(true);
                // setSelectedListing(null);
                setIsSlidePane(true);
              }}
            >
              Add Team Listing
            </Button>
          </div>
        </LayoutListing.Header>
        <LayoutListing.Container>
          <Facets filterFacets={filterFacets} />
          <LayoutListing.Content className="!overflow-hidden">
            <div className="h-[80px] border-b border-jll-color-coldGray-3 mx-6 flex flex-row space-x-8">
              {TAB_LIST.map((tab, idx) => (
                <div
                  key={idx}
                  className={cn(
                    "h-full flex items-center text-lg font-semibold cursor-pointer",
                    {
                      "text-jll-color-text-base-default border-b border-b-jll-color-text-base-subdued":
                        activeTab === tab.key,
                      "text-jll-color-coldGray-5": activeTab !== tab.key,
                    }
                  )}
                  onClick={() => handleTab(tab.key)}
                >
                  {tab.label}
                </div>
              ))}
            </div>

            {loading && !data?.length && <Loading />}
            {!loading && !data?.length && (
              <EmptyResult>
                <h3 className="text-heading3 text-jll-color-coldGray-5">
                  Sorry, we didn't find any team listings.
                </h3>
              </EmptyResult>
            )}

            {!loading && data?.length && (
              <div className="p-6 h-[calc(100%-80px)] overflow-y-auto">
                <div className="flex space-x-4 pb-4">
                  <div className="font-semibold text-jll-color-coldGray-7 text-sm">
                    {total} result{total > 1 ? "s" : ""}
                  </div>
                </div>
                <Table>
                  <Table.Thead>
                    <Table.Tr>
                      {TABLE_COLUMNS.map((column, index) => {
                        return (
                          <Table.Th
                            key={index}
                            // onClick={() => onClickSorting(column)}
                          >
                            {column.label}
                          </Table.Th>
                        );
                      })}
                      <Table.Th />
                    </Table.Tr>
                  </Table.Thead>
                  <Table.Tbody>
                    {data?.map((listing: TeamListingProps, index: number) => {
                      return (
                        <Table.Tr key={index} onClick={onClickRow(listing?.pk)}>
                          {TABLE_COLUMNS.map((column, index) => {
                            return (
                              <Table.Td
                                key={index}
                                className={column?.td?.className}
                              >
                                <StyledTeamListingValue
                                  listing={listing}
                                  valueKey={column.id}
                                />
                              </Table.Td>
                            );
                          })}
                          <Table.Td className="space-x-2">
                            {!!listing?.link_slug && (
                              <Button
                                variant="secondary"
                                size="small"
                                onClick={(e) => {
                                  e?.stopPropagation();
                                  window.open(
                                    `${window.location.origin}/pub/tl/${listing?.link_slug}/${listing?.link_uuid}`,
                                    "_blank"
                                  );
                                }}
                              >
                                Link
                              </Button>
                            )}
                            <Button
                              variant="secondary"
                              size="small"
                              onClick={(e) => {
                                e?.stopPropagation();
                                setIsTeamListingModal(true);
                                setSelectedListing(listing);
                              }}
                            >
                              Edit
                            </Button>
                            {listing?.active_status === STATUS_ACTIVE ? (
                              <Button
                                variant="danger"
                                size="small"
                                onClick={(e) => {
                                  e?.stopPropagation();
                                  handleStatus(listing?.pk, STATUS_REMOVED);
                                }}
                              >
                                Remove
                              </Button>
                            ) : (
                              <Button
                                variant="secondary"
                                size="small"
                                onClick={(e) => {
                                  e?.stopPropagation();
                                  handleStatus(listing?.pk, STATUS_ACTIVE);
                                }}
                              >
                                Reinstate
                              </Button>
                            )}
                          </Table.Td>
                        </Table.Tr>
                      );
                    })}
                  </Table.Tbody>
                </Table>
                <ShowMoreItems
                  onClick={onClickShowMore}
                  total={total}
                  isLoading={loading}
                  itemsCount={data.length}
                />
              </div>
            )}
          </LayoutListing.Content>
        </LayoutListing.Container>
      </LayoutListing>
      <TeamListingModal
        setIsOpen={setIsTeamListingModal}
        isOpen={isTeamListingModal}
        selectedListing={selectedListing}
      />

      <SlidingPane
        width="80%"
        from="right"
        isOpen={isSlidePane}
        onRequestClose={() => setIsSlidePane(false)}
        className="team-listing"
      >
        <TeamListingPane setIsSlidePane={setIsSlidePane} />
      </SlidingPane>
    </>
  );
};

export default React.memo(TeamListings);
