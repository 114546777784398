import { DATE_AVAILABLE_IMMEDIATE, LEASE_TERM_NEGOTIABLE } from "constant";
import { AvailabilityProps } from "types";

export type InitialValuesProps = {
  [key in keyof AvailabilityProps]?: any;
};

export const INITIAL_VALUES: InitialValuesProps = {
  id: undefined,
  name: "",
  building: "",
  floor: "",
  lease_term: LEASE_TERM_NEGOTIABLE,
  space_use_type: "",
  suite_condition: "",
  min_floor_area: "",
  max_floor_area: "",
  max_contiguous: "",
  date_available: DATE_AVAILABLE_IMMEDIATE,
  min_price: "",
  max_price: "",
  hide_price: false,
  tenure_types: [],
  building_property_type: "",
  highlights: [],
  dirty: false,
  publish_status: undefined,
  is_full_floor: false,
  is_vacant: false,
  date_on_market: "",
  rent_type: null
};
