import { StyledTaskValueProps } from "ui-molecules";

interface TableColumnProps {
  id: StyledTaskValueProps["valueKey"];
  label: string;
  sort?: string;
}

export const TASK_TABLE_COLUMNS: TableColumnProps[] = [
  {
    id: "building",
    label: "Building",
  },
  {
    id: "status",
    label: "Status",
  },
  {
    id: "assignee",
    label: "Assignee",
  },
  {
    id: "title",
    label: "Title",
  },
  {
    id: "update_timestamp",
    label: "Updated",
  },
];

export const TASK_PREVIEW_TABLE_COLUMNS: TableColumnProps[] = [
  {
    id: "detail_assignee",
    label: "Assignee",
  },
  {
    id: "building",
    label: "Building",
  },
  {
    id: "title",
    label: "Title",
  },
  {
    id: "body",
    label: "Body"
  },
  {
    id: "status",
    label: "Status",
  }
];
