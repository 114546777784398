export const emailHomeJSON = [
  {
    id: "home",
    cells: [1, 1],
    columns: [
      {
        id: "pB8l2wVDdm",
        contents: [
          {
            id: "1p3-4ZPH0W",
            type: "image",
            values: {
              containerPadding: "30px 10px 10px",
              anchor: "",
              src: {
                url: "https://assets.unlayer.com/stock-templates1692139739469-logo.png",
                width: 163,
                height: 72,
                autoWidth: false,
                maxWidth: "25%",
              },
              textAlign: "left",
              altText: "",
              action: {
                name: "web",
                values: { href: "", target: "_blank" },
              },
              hideDesktop: false,
              displayCondition: null,
              _meta: {
                htmlID: "u_content_image_10",
                htmlClassNames: "u_content_image",
              },
              selectable: true,
              draggable: true,
              duplicatable: true,
              deletable: true,
              hideable: true,
            },
          },
          {
            id: "property_name",
            type: "heading",
            values: {
              containerPadding: "10px",
              anchor: "",
              headingType: "h1",
              fontWeight: 700,
              fontSize: "20px",
              color: "#3a3e43",
              textAlign: "left",
              lineHeight: "140%",
              linkStyle: {
                inherit: true,
                linkColor: "#0000ee",
                linkHoverColor: "#0000ee",
                linkUnderline: true,
                linkHoverUnderline: true,
              },
              hideDesktop: false,
              displayCondition: null,
              _meta: {
                htmlID: "u_content_heading_4",
                htmlClassNames: "u_content_heading",
              },
              selectable: true,
              draggable: true,
              duplicatable: true,
              deletable: true,
              hideable: true,
              text: "John Widdicomb",
            },
          },
          {
            id: "property_address",
            type: "text",
            values: {
              containerPadding: "10px",
              anchor: "",
              fontSize: "15px",
              textAlign: "left",
              lineHeight: "140%",
              linkStyle: {
                inherit: true,
                linkColor: "#0000ee",
                linkHoverColor: "#0000ee",
                linkUnderline: true,
                linkHoverUnderline: true,
              },
              hideDesktop: false,
              displayCondition: null,
              _meta: {
                htmlID: "u_content_text_1",
                htmlClassNames: "u_content_text",
              },
              selectable: true,
              draggable: true,
              duplicatable: true,
              deletable: true,
              hideable: true,
              text: "",
            },
          },
          {
            id: "property_type",
            type: "text",
            values: {
              containerPadding: "10px",
              anchor: "",
              fontSize: "12px",
              color: "#60666e",
              textAlign: "left",
              lineHeight: "140%",
              linkStyle: {
                inherit: true,
                linkColor: "#0000ee",
                linkHoverColor: "#0000ee",
                linkUnderline: true,
                linkHoverUnderline: true,
              },
              hideDesktop: false,
              displayCondition: null,
              _meta: {
                htmlID: "u_content_text_2",
                htmlClassNames: "u_content_text",
              },
              selectable: true,
              draggable: true,
              duplicatable: true,
              deletable: true,
              hideable: true,
              text: "",
            },
          },
          {
            id: "property_link",
            type: "button",
            values: {
              containerPadding: "10px",
              anchor: "",
              href: {
                name: "web",
                values: {
                  href: "https://mylistings.com",
                  target: "_blank",
                },
                attrs: { href: "{{href}}", target: "{{target}}" },
              },
              buttonColors: {
                color: "#0064c8",
                backgroundColor: "#ffffff",
                hoverColor: "#FFFFFF",
                hoverBackgroundColor: "#3AAEE0",
              },
              size: { autoWidth: true, width: "100%" },
              fontSize: "14px",
              textAlign: "left",
              lineHeight: "100%",
              padding: "0px",
              border: {
                borderTopWidth: "0px",
                borderTopStyle: "solid",
                borderTopColor: "#CCC",
                borderLeftWidth: "0px",
                borderLeftStyle: "solid",
                borderLeftColor: "#CCC",
                borderRightWidth: "0px",
                borderRightStyle: "solid",
                borderRightColor: "#CCC",
                borderBottomWidth: "0px",
                borderBottomStyle: "solid",
                borderBottomColor: "#CCC",
              },
              borderRadius: "4px",
              hideDesktop: false,
              displayCondition: null,
              _meta: {
                htmlID: "u_content_button_1",
                htmlClassNames: "u_content_button",
              },
              selectable: true,
              draggable: true,
              duplicatable: true,
              deletable: true,
              hideable: true,
              text: '<span style="line-height: 14px;">Go to property.jll.com</span>',
              calculatedWidth: 132,
              calculatedHeight: 14,
            },
          },
        ],
        values: {
          backgroundColor: "",
          padding: "0px",
          border: {},
          borderRadius: "0px",
          _meta: { htmlID: "u_column_16", htmlClassNames: "u_column" },
        },
      },
      {
        id: "property_image",
        contents: [
          {
            id: "O38vAh379O",
            type: "image",
            values: {
              containerPadding: "0px",
              anchor: "",
              src: {
                url: "https://res.cloudinary.com/marketsphere/image/upload/h_300/w_280/q_80/q_auto/sn3cgedg7jy01o2tmkx9.jpg",
                width: 280,
                height: 300,
              },
              textAlign: "right",
              altText: "",
              action: {
                name: "web",
                values: { href: "", target: "_blank" },
              },
              hideDesktop: false,
              displayCondition: null,
              _meta: {
                htmlID: "u_content_image_9",
                htmlClassNames: "u_content_image",
              },
              selectable: true,
              draggable: true,
              duplicatable: true,
              deletable: true,
              hideable: true,
            },
          },
        ],
        values: {
          backgroundColor: "",
          padding: "0px",
          border: {},
          borderRadius: "0px",
          _meta: { htmlID: "u_column_17", htmlClassNames: "u_column" },
        },
      },
    ],
    values: {
      displayCondition: null,
      columns: false,
      backgroundColor: "",
      columnsBackgroundColor: "",
      backgroundImage: {
        url: "",
        fullWidth: true,
        repeat: "no-repeat",
        size: "custom",
        position: "center",
      },
      padding: "0px",
      anchor: "",
      hideDesktop: false,
      _meta: { htmlID: "u_row_8", htmlClassNames: "u_row" },
      selectable: true,
      draggable: true,
      duplicatable: true,
      deletable: true,
      hideable: true,
    },
  },
];
