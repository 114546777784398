import * as Yup from "yup";
import { useApiCall, useComponentVisible } from "hooks";
import { Fragment, useEffect, useState } from "react";
import { patchAvailabilityAPI, patchPropertyAPI } from "services";
import { useFormik } from "formik";
import {
  OLM_AVAILABILITY_FIELDS,
  OLM_PROPERTY_FIELDS,
  PROPERTY_VALIDATION,
  STATUS_ACTIVE,
} from "constant";
import { Button, Checkbox } from "ui-atoms";
import { FiCheck, FiEdit3, FiX } from "react-icons/fi";
import { Popover, Transition } from "@headlessui/react";
import EditableIconBtn from "./EditableIconBtn";
import { getApiPayloadFromForm, isCommonItem } from "utils";

interface IEditableMenuField {
  initValues?: any;
  valueKeys: string[];
  type?: "text" | "number";
  pk?: string | number;
  children?: any;
  kind: "property" | "availability";
  updateItem?: any;
  isEditMode?: boolean;
  options: any[];
}

const EditableMenuField: React.FC<IEditableMenuField> = ({
  initValues,
  valueKeys,
  pk,
  children,
  kind,
  updateItem,
  isEditMode,
  options,
}) => {
  const [patchProperty] = useApiCall(patchPropertyAPI);
  const [patchAvailability] = useApiCall(patchAvailabilityAPI);
  const [validationSchema, setValidationSchema] = useState(
    Yup.object().shape({})
  );

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    values,
    errors,
    touched,
    setValues,
    isValid,
    dirty,
  }: any = useFormik({
    initialValues: {},
    validationSchema,
    onSubmit: () => {
      try {
        let payload: any = getApiPayloadFromForm(initValues, values);
        let service = null;
        if (kind === "property") {
          service = patchProperty;
          if (
            payload &&
            initValues?.publish_status === STATUS_ACTIVE &&
            isCommonItem(Object.keys(payload), OLM_PROPERTY_FIELDS)
          ) {
            payload["publish_status"] = STATUS_ACTIVE;
          }
        } else if (kind === "availability") {
          service = patchAvailability;
          if (
            payload &&
            initValues?.publish_status === STATUS_ACTIVE &&
            isCommonItem(Object.keys(payload), OLM_AVAILABILITY_FIELDS)
          ) {
            payload["publish_status"] = STATUS_ACTIVE;
          }
        }
        service({
          id: pk,
          payload,
        }).then((res: any) => {
          updateItem(values, pk);
        });
      } catch (err) {}
    },
  });

  useEffect(() => {
    if (!initValues || !valueKeys) return;
    let init: any = {};
    valueKeys?.forEach((key: string) => (init[key] = initValues?.[key]));
    setValues(init);

    let validation_fields: any = {};
    if (kind === "property") {
      validation_fields = PROPERTY_VALIDATION?.fields;
    }
    const newValidations: any = Object.keys(validation_fields)
      .filter((key) => valueKeys?.includes(key))
      .reduce((obj: any, key) => {
        obj[key] = validation_fields?.[key];
        return obj;
      }, {});
    setValidationSchema(Yup.object().shape({ ...newValidations }));
  }, [initValues, valueKeys]);

  return (
    <>
      {isEditMode ? (
        <div
          onClick={(e: any) => {
            e.stopPropagation();
          }}
        >
          <div>
            <form
              onSubmit={handleSubmit}
              className="flex flex-row flex-wrap space-x-2 items-center justify-between"
            >
              <div className="flex flex-row items-center flex-wrap">
                {children}
              </div>
              {!!options?.length && (
                <Popover className="relative mt-1">
                  <Popover.Button>
                    <Button
                      variant="neutral"
                      leadingIcon={FiEdit3}
                      className="text-jll-color-coldGray-4"
                      leadingIconClass="text-jll-color-coldGray-4"
                    />
                  </Popover.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel className="absolute left-0 z-30 mt-1 transform  py-4 min-w-[150px] w-fit bg-white shadow-drop rounded-lg">
                      {({ close }) => (
                        <>
                          <div className="space-y-2 overflow-y-auto w-fit max-h-[300px] px-6">
                            {options?.map((option, idx) => (
                              <Checkbox
                                id={`${valueKeys[0]}-${pk}-${idx}`}
                                label={
                                  option?.value === "rent"
                                    ? "Lease"
                                    : option?.label
                                }
                                checked={values?.[valueKeys[0]]?.includes(
                                  option?.value
                                )}
                                onChange={(e: any) => {
                                  let result = values?.[valueKeys[0]]
                                    ? [...values?.[valueKeys[0]]]
                                    : [];
                                  if (e.target.checked)
                                    result.push(option?.value);
                                  else {
                                    const index = result.indexOf(option?.value);
                                    if (index > -1) result.splice(index, 1);
                                  }
                                  setFieldValue(valueKeys[0], result);
                                }}
                                onBlur={handleBlur}
                              />
                            ))}
                            {!!errors?.[valueKeys[0]] && (
                              <p className="text-xs text-jll-color-surface-accent-default whitespace-normal">
                                {errors?.[valueKeys[0]]}
                              </p>
                            )}
                          </div>
                          <div className="flex flex-row items-center space-x-0.5 mt-4 px-6">
                            <EditableIconBtn
                              icon={FiCheck}
                              type="submit"
                              variant="success"
                              className="w-full"
                              disabled={!isValid || !dirty}
                              onClick={() => {
                                handleSubmit();
                                close();
                              }}
                            />
                            <EditableIconBtn
                              icon={FiX}
                              variant="grey"
                              className="w-full"
                              disabled={!isValid}
                              onClick={() => {
                                close();
                              }}
                            />
                          </div>
                        </>
                      )}
                    </Popover.Panel>
                  </Transition>
                </Popover>
              )}
            </form>
          </div>
        </div>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

export default EditableMenuField;
