import { useApiCall } from "hooks";
import { useContext, useEffect } from "react";
import { FiDownload, FiX } from "react-icons/fi";
import { convertImageUrl, getAvailabilityAPI } from "services";
import { Button } from "ui-atoms";
import { renderToString } from "react-dom/server";
import {
  Modal,
  PreviewHighlight,
  StyledAvailabilityValue,
  StyledBrokerValue,
} from "ui-molecules";
import cn from "classnames";
import { convertExtension, ordinalSuffixOf, sliceIntoChunks } from "utils";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { GlobalContext } from "context";
import { AVAILABILITY_DETAILS, UPDATE_LIGHTGALLERY_DATA } from "constant";

interface IAvailabilityPreviewModal {
  pk?: number;
  isOpen: boolean;
  setIsOpen?: any;
}

const AvailabilityPreviewModal: React.FC<IAvailabilityPreviewModal> = ({
  pk = -1,
  isOpen,
  setIsOpen,
}) => {
  const { state, dispatch } = useContext(GlobalContext);
  const { lightGalleryRef, meta } = state;
  const [fetchData, , suiteData = null] = useApiCall(getAvailabilityAPI);

  useEffect(() => {
    if (!isOpen || pk < 0) return;
    fetchData(pk);
  }, [pk, isOpen]);

  const handleOpen = (e: boolean) => {
    if (!e) {
      setIsOpen(false);
    }
  };

  const handleDownload = (urls: string[]) => {
    urls?.forEach((url) => {
      window.open(url);
    });
  };

  const handleOpenGallery = async (name: string, index: number) => {
    if (suiteData?.[name]?.[index]?.includes(".pdf")) {
      window.open(suiteData?.[name]?.[index], "_blank");
      return;
    }
    let arr: any = [];
    suiteData[name]?.forEach((url: string, idx: number) => {
      arr.push({
        id: idx,
        src: convertImageUrl(convertExtension(url)),
        thumb: convertImageUrl(convertExtension(url)),
        subHtml: renderToString(<span>{idx + 1}</span>),
      });
    });
    await dispatch({
      type: UPDATE_LIGHTGALLERY_DATA,
      payload: arr,
    });
    setTimeout(function () {
      lightGalleryRef.current.openGallery(index);
    }, 100);
  };

  const openPropertyLink = () => {
    window.open(
      `${meta?.PROPERTY_STATIC_URL}${suiteData?.building_page_url}`,
      "_blank"
    );
  };

  return (
    <Modal isOpen={isOpen} setIsOpen={handleOpen} size="large">
      <Modal.Header className="relative">
        <Button
          variant="neutral"
          leadingIcon={FiX}
          className="absolute top-0 right-0 w-5 !h-5"
          leadingIconClass="w-5 h-5 text-jll-color-text-base-subdued"
          onClick={() => setIsOpen(false)}
        />
        <div className="flex flex-row items-center justify-between pb-4">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            {!!suiteData?.floor?.length &&
              `${ordinalSuffixOf(suiteData?.floor) || ""} Floor`}
            {!!suiteData?.floor?.length && !!suiteData?.name?.length && ", "}
            {!!suiteData?.name?.length && `Suite ${suiteData?.name || ""}`}
          </h3>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="pr-2">
          <div className="flex justify-between items-center mt-2 mb-4">
            <div className="flex flex-row items-center">
              {suiteData?.space_use_type && (
                <div className="mr-3 flex">
                  <StyledAvailabilityValue
                    availability={suiteData}
                    valueKey="space_use_type"
                  />
                </div>
              )}
              <span className="text-jll-color-text-base-default text-base">
                ID {suiteData?.id}
              </span>
            </div>
            <div>
              {!!suiteData?.brochures?.length && (
                <Button
                  variant="secondary"
                  className="mr-2"
                  leadingIcon={FiDownload}
                  onClick={() => handleDownload(suiteData?.brochures)}
                >
                  Brochure
                </Button>
              )}
            </div>
          </div>
          {!!suiteData?.floorplans?.length && (
            <div className="max-h-[500px] flex justify-center mb-4">
              <LazyLoadImage
                className="w-9/12 h-auto cursor-pointer"
                src={convertImageUrl(
                  convertExtension(suiteData?.floorplans[0])
                )}
                onClick={() => handleOpenGallery("floorplans", 0)}
              />
            </div>
          )}

          <div className="flex items-start justify-between mb-4">
            <div className="">
              <StyledAvailabilityValue
                availability={suiteData}
                valueKey={"floor_name"}
              />
            </div>
            <div>
              <Button
                variant="primary"
                className="!mb-0"
                onClick={openPropertyLink}
              >
                Link to property page
              </Button>
            </div>
          </div>

          <div className="mb-4 divide-jll-color-coldGray-3 divide-y">
            {sliceIntoChunks(AVAILABILITY_DETAILS, 3).map((detail, idx) => (
              <div
                className="flex divide-jll-color-coldGray-3 divide-x"
                key={idx}
              >
                {detail?.map((item, idx1) => (
                  <div
                    className={cn(
                      "flex flex-row items-center justify-between w-full py-3",
                      {
                        "pr-6": idx1 === 0,
                        "pl-8 pr-6": idx1 === 1,
                        "pl-8": idx1 === 2,
                      }
                    )}
                    key={idx1}
                  >
                    <span className="text-jll-color-coldGray-7 text-base">
                      {item?.label}
                    </span>
                    {item?.key && (
                      <span className="text-jll-color-coldGray-7 text-base">
                        <StyledAvailabilityValue
                          availability={suiteData}
                          valueKey={item?.key}
                        />
                      </span>
                    )}
                  </div>
                ))}
              </div>
            ))}
          </div>

          {!!suiteData?.highlights?.length && (
            <PreviewHighlight
              highlights={suiteData?.highlights}
              className="mb-4"
            />
          )}

          {!!suiteData?.brokers?.length && (
            <div className="flex flex-row flex-wrap">
              {suiteData?.brokers?.map((broker: any, idx: number) => (
                <div key={idx} className="w-[190px] mr-4 mb-4">
                  <StyledBrokerValue broker={broker} valueKey="name" />
                </div>
              ))}
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AvailabilityPreviewModal;
