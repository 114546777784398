import { PropertyProps } from "types";

export type InitialValuesProps = {
  [key in keyof PropertyProps]?: any;
};

export const INITIAL_VALUES: InitialValuesProps = {
  id: 0,
  websites: [],
};
