import type { FC } from "react";
import { Avatar, Chip } from "ui-atoms";
import type { ActivityProps } from "types";
import {
  pickSpecificKeyFromObj,
  getFormatedDateTime,
  joinTwoStr,
  lazyLoadImageOnError,
  noPropagation,
  convertExtension,
} from "utils";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";
import { PROPERTY_TABS, URLS } from "constant";
import { convertImageUrl } from "services";
import ActivityContent from "./ActivityContent";

export interface StyledActivityValueProps {
  activity: ActivityProps;
  valueKey: keyof ActivityProps | "info" | "content" | "building";
}

const StyledActivityValue: FC<StyledActivityValueProps> = ({
  activity,
  valueKey,
}) => {
  const isSuite = activity?.content_type_id?.name
    ?.toLowerCase()
    ?.includes("suite");

  return (
    <>
      {(() => {
        switch (valueKey) {
          case "type":
            if (!activity?.type) {
              return <>-</>;
            }
            return (
              <div>
                <span className="capitalize">
                  <Chip variant="secondary" className="mr-1" size="small">
                    {activity?.standardized?.type}
                  </Chip>
                </span>
                <span className="capitalize">{activity?.type_name}</span>
              </div>
            );

          case "update_timestamp":
            return (
              <div>
                <p>
                  {activity?.update_timestamp &&
                    getFormatedDateTime(activity?.update_timestamp, {
                      dateStyle: "long",
                    })}
                </p>
                {(!!activity?.first_name || !!activity?.last_name) && (
                  <p className="text-sm text-jll-color-text-base-default">
                    <span className="text-jll-color-coldGray-7">
                      updated by:{" "}
                    </span>
                    {joinTwoStr(activity?.first_name, activity?.last_name, " ")}
                  </p>
                )}
              </div>
            );
          case "create_timestamp":
            if (!activity?.create_timestamp) {
              return <>-</>;
            }
            return <>{getFormatedDateTime(activity?.create_timestamp)}</>;
          case "info":
            return (
              <div className="flex space-x-2 items-center">
                <Avatar
                  alt=""
                  size="default"
                  src={activity?.photo || ""}
                  name={
                    [activity?.first_name, activity?.last_name].join(" ") || ""
                  }
                />
                <div className="truncate">
                  <p className="font-bold text-jll-color-text-base-default text-ellipsis overflow-hidden">{`${activity?.first_name} ${activity?.last_name}`}</p>
                  <p>{activity?.office || ""}</p>
                </div>
              </div>
            );

          case "content":
            return <ActivityContent activity={activity} />;

          case "building":
            return isSuite ? (
              <div className="flex space-x-2">
                <LazyLoadImage
                  className="w-10 h-10 rounded"
                  src={convertImageUrl(
                    convertExtension(
                      activity?.object.floorplans?.[0] ||
                        activity?.building_images?.[0]
                    )
                  )}
                  onError={lazyLoadImageOnError}
                />
                <div className="flex flex-col truncate">
                  <Link
                    to={`/property/${activity?.building_id}/availability/${activity?.object?.id}/form`}
                    className="font-bold text-jll-color-text-base-default text-ellipsis overflow-hidden"
                    onClick={noPropagation}
                  >
                    {`Floor ${activity?.object?.floor} `}
                    {activity?.object?.name
                      ? `| Suite ${activity?.object?.name} `
                      : ""}
                  </Link>
                  <p>
                    <Link
                      to={`/property/${activity?.building_id}/${URLS.PROPERTY.FORM}`}
                      className="text-jll-color-icon-info text-ellipsis overflow-hidden"
                      onClick={noPropagation}
                    >
                      {activity?.building_title || activity?.building_address}
                    </Link>
                  </p>
                </div>
              </div>
            ) : (
              <div className="flex space-x-2">
                <LazyLoadImage
                  className="w-10 h-10 rounded"
                  src={convertImageUrl(
                    convertExtension(activity?.building_images?.[0] || "")
                  )}
                  onError={lazyLoadImageOnError}
                />
                <div className="flex flex-col truncate">
                  <Link
                    to={`/property/${activity?.building_id}/${URLS.PROPERTY.FORM}`}
                    className="font-bold text-jll-color-text-base-default text-ellipsis overflow-hidden"
                    onClick={noPropagation}
                  >
                    {activity?.building_title}
                  </Link>
                  <p>
                    <Link
                      to={`/property/${activity?.building_id}/${URLS.PROPERTY.FORM}`}
                      className="text-jll-color-icon-info text-ellipsis overflow-hidden"
                      onClick={noPropagation}
                    >
                      {activity?.building_address}
                    </Link>
                  </p>
                </div>
              </div>
            );
          default:
            if (activity.hasOwnProperty(valueKey)) {
              return <>{activity[valueKey]}</>;
            }
            return <>-</>;
        }
      })()}
    </>
  );
};

export default StyledActivityValue;
