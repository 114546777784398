import { useState, useEffect, useContext } from "react";
import { FiEdit3, FiGrid, FiPlusCircle } from "react-icons/fi";
import {
  Table,
  StyledAvailabilityValue,
  AvailabilityPreviewModal,
  ShowMoreItems,
} from "ui-molecules";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Button, EmptyResult, Toast } from "ui-atoms";
import queryString from "query-string";
import { LayoutEditProperty as Layout } from "ui-organisms";
import { useApiCall } from "hooks";
import { AvailabilityProps, DirectionEnum } from "types";
import {
  deleteAvailabilityAPI,
  getAvailabilitiesAPI,
  patchAvailabilityAPI,
  postSuiteMarketsphereAPI,
  postTableSchemaAPI,
} from "services";
import {
  ALL_AVAILABILITIES_COLUMNS,
  AVAILABILITIES_TABLE_COLUMNS,
  EVENT_IMPORT_FROM_MARKETSPHERE,
  EVENT_PUBLISHED,
  EVENT_REINSTATED_AVAILABILITY,
  EVENT_REMOVED_INACTIVE_AVAILABILITY,
  EVENT_UNPUBLISHED_AVAILABILITY,
  EventProps,
  META_TAGS,
  PUBLISH_STATUS_PUBLISHED,
  PUBLISH_STATUS_REMOVED,
  SEARCH_RESULT_LIMIT,
  SET_EVENT_MODAL,
  SET_INLINE_EDIT_SELECT_MODAL,
  SET_LOADING_MODAL,
  SET_META,
  STATUS_ACTIVE,
  STATUS_REMOVED,
  URLS,
} from "constant";
import { GlobalContext } from "context";
import { Helmet } from "react-helmet-async";
import cn from "classnames";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { getContactTypeId, reorder } from "utils";
import ReactDOM from "react-dom";

const TabMenu = [
  { label: "Active", value: STATUS_ACTIVE },
  { label: "Inactive", value: STATUS_REMOVED },
];

const PropertyAvailabilities = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [getAvailabilities, isLoadingAvailabilities] =
    useApiCall(getAvailabilitiesAPI);
  const [patchAvailability, isLoadingPatch] = useApiCall(patchAvailabilityAPI);
  const [deleteAvailabilities] = useApiCall(deleteAvailabilityAPI);
  const [postSuiteMarketsphere] = useApiCall(postSuiteMarketsphereAPI);
  const [availabilities, setAvailabilities] = useState<AvailabilityProps[]>();
  const [postTableSchema] = useApiCall(postTableSchemaAPI);
  const { propertyId } = useParams();
  const { state, dispatch } = useContext(GlobalContext);
  const { property, meta, portalRef } = state;
  const [columns, setColumns] = useState<any>([]);
  const [isInlineEditMode, setIsInlineEditMode] = useState(false);
  const [openedAvailability, setOpenedAvailability] = useState(-1);
  const [isOpen, setIsOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [sortFields, setSortFields] = useState<string[]>([]);
  const [paramFilters, setParamFilters] = useState<any>(null);
  const [activeTab, setActiveTab] = useState<any>(STATUS_ACTIVE);

  const loadData = () => {
    setPage(1);
    setAvailabilities([]);
    // Get the first set of Availabilities
    getAvailabilities({
      page: 1,
      limit: SEARCH_RESULT_LIMIT,
      buildings: [propertyId],
      ...paramFilters,
    }).then((res: any) => {
      setAvailabilities(res?.docs);
      setPage(res?.page);
      setTotal(res?.total);
      setSortFields(res?.sort_fields);
    });
  };

  useEffect(() => {
    const filters = queryString.parse(location?.search, {
      arrayFormat: "bracket",
    });
    if (filters?.active_status) {
      setActiveTab(filters?.active_status);
    }
    setParamFilters(filters);
  }, [location]);

  useEffect(() => {
    if (!propertyId || !paramFilters) return;
    loadData();
  }, [propertyId, paramFilters]);

  useEffect(() => {
    if (!meta) return;
    const content_type = getContactTypeId(meta, "suite")?.[0];
    setColumns(
      !!meta?.table_schema?.length &&
        !!meta?.table_schema?.find(
          (schema: any) => schema?.content_type === content_type
        )
        ? JSON.parse(
            meta?.table_schema?.find(
              (schema: any) => schema?.content_type === content_type
            )?.json
          )
        : AVAILABILITIES_TABLE_COLUMNS
    );
  }, [meta]);

  const onClickAdd = () => {
    navigate(
      `/property/${propertyId}/availability/new/${URLS.AVAILABILITY.FORM}`
    );
  };

  const onClickPreview = (e: any, id: number) => {
    e.stopPropagation();
    setOpenedAvailability(id);
    setIsOpen(true);
  };

  const onStatusUpdate = async (
    id: any,
    publish_status: number,
    event: any = {}
  ) => {
    const response = await patchAvailability({
      id,
      payload: {
        publish_status,
        ...{ ...event },
      },
    });
    if (!response) return;
    Toast.success("Updated successfully");
    const updatedAvailabilities = [...(availabilities || [])];
    const index = availabilities?.findIndex(
      (item) => Number(item?.pk) === Number(id)
    );
    if (index !== undefined && index > -1) {
      updatedAvailabilities[index]["publish_status"] = response?.publish_status;
      setAvailabilities(updatedAvailabilities);
    }
  };

  const onActiveUpdate = async (
    id: any,
    active_status: number,
    event: any = {}
  ) => {
    const response = await patchAvailability({
      id,
      payload: {
        active_status,
        ...{ ...event },
      },
    });
    if (!response) return;
    Toast.success("Updated successfully");
    const updatedAvailabilities = [...(availabilities || [])];
    const index = availabilities?.findIndex(
      (item) => Number(item?.pk) === Number(id)
    );
    if (index !== undefined && index > -1) {
      updatedAvailabilities[index]["active_status"] = response?.active_status;
      const newData = updatedAvailabilities?.filter(
        (item) => item?.active_status?.toString() === activeTab?.toString()
      );
      const newTotal = total - 1;
      setTotal(newTotal);
      setAvailabilities(newData);
    }
  };

  const onClickPublish = async (e: any, availability: any) => {
    e.stopPropagation();
    const action = async (event: EventProps) => {
      try {
        dispatch({
          type: SET_LOADING_MODAL,
          payload: {
            open: true,
            label: `${
              availability?.publish_status === PUBLISH_STATUS_PUBLISHED
                ? "Unpublishing"
                : "Publishing"
            } the availability`,
          },
        });
        if (availability?.publish_status === PUBLISH_STATUS_PUBLISHED)
          await onStatusUpdate(availability?.pk, PUBLISH_STATUS_REMOVED, event);
        else
          await onStatusUpdate(
            availability?.pk,
            PUBLISH_STATUS_PUBLISHED,
            event
          );
        setTimeout(() => {
          dispatch({
            type: SET_LOADING_MODAL,
            payload: {
              open: false,
              label: null,
            },
          });
        }, 100);
      } catch (err) {
        setTimeout(() => {
          dispatch({
            type: SET_LOADING_MODAL,
            payload: {
              open: false,
              label: null,
            },
          });
        }, 100);
      }
    };
    dispatch({
      type: SET_EVENT_MODAL,
      payload: {
        open: true,
        content: {
          title: `${
            availability?.publish_status === PUBLISH_STATUS_PUBLISHED
              ? "Unpublish"
              : "Publish"
          } Availability`,
          label: `What is reason for ${
            availability?.publish_status === PUBLISH_STATUS_PUBLISHED
              ? "unpublishing"
              : "publishing"
          } this availability?`,
          eventType:
            availability?.publish_status === PUBLISH_STATUS_PUBLISHED
              ? EVENT_UNPUBLISHED_AVAILABILITY
              : EVENT_PUBLISHED,
          buttonLabel: `${
            availability?.publish_status === PUBLISH_STATUS_PUBLISHED
              ? "Unpublish"
              : "Publish"
          } Availablity`,
          action,
        },
      },
    });
  };

  const onClickReinstate = async (e: any, availability: any) => {
    e.stopPropagation();
    const action = async (event: EventProps) => {
      try {
        dispatch({
          type: SET_LOADING_MODAL,
          payload: {
            open: true,
            label: `Reinstating the availability`,
          },
        });
        await onActiveUpdate(availability?.pk, STATUS_ACTIVE, event);
        setTimeout(() => {
          dispatch({
            type: SET_LOADING_MODAL,
            payload: {
              open: false,
              label: null,
            },
          });
        }, 100);
      } catch (err) {
        setTimeout(() => {
          dispatch({
            type: SET_LOADING_MODAL,
            payload: {
              open: false,
              label: null,
            },
          });
        }, 100);
      }
    };

    dispatch({
      type: SET_EVENT_MODAL,
      payload: {
        open: true,
        content: {
          title: "Reinstate Availability",
          label: `What is reason for reinstate this availability?`,
          eventType: EVENT_REINSTATED_AVAILABILITY,
          buttonLabel: "Reinstate Availability",
          action,
        },
      },
    });
  };

  const onClickEdit = (pk: AvailabilityProps["pk"]) => () => {
    navigate(
      `/property/${propertyId}/availability/${pk}/${URLS.AVAILABILITY.FORM}`
    );
  };

  const onClickRemove = (e: any, availability: AvailabilityProps) => {
    e.stopPropagation();
    const action = (event: EventProps) => {
      dispatch({
        type: SET_LOADING_MODAL,
        payload: {
          open: true,
          label: `Moving the availability to Inactive`,
        },
      });
      deleteAvailabilities({
        id: availability?.pk || availability?.id,
        payload: { ...{ ...event } },
      })
        .then((data: any) => {
          Toast.success("Availability removed with success!");
          const updatedAvailabilities = [...(availabilities || [])];
          const index = availabilities?.findIndex(
            (item) => Number(item?.pk) === Number(availability?.pk)
          );
          if (index !== undefined && index > -1) {
            updatedAvailabilities[index]["active_status"] = STATUS_REMOVED;
            const newData = updatedAvailabilities?.filter(
              (item) =>
                item?.active_status?.toString() === activeTab?.toString()
            );
            const newTotal = total - 1;
            setTotal(newTotal);
            setAvailabilities(newData);
          }
        })
        .finally(() => {
          setTimeout(() => {
            dispatch({
              type: SET_LOADING_MODAL,
              payload: {
                open: false,
                label: null,
              },
            });
          }, 100);
        });
    };

    dispatch({
      type: SET_EVENT_MODAL,
      payload: {
        open: true,
        content: {
          title: "Move Availability to Inactive",
          label: `${
            availability?.publish_status === PUBLISH_STATUS_PUBLISHED
              ? "This will unpublish the availability and move the availability to the inactive tab.  What is the reason for unpublishing and moving this availability to inactive?"
              : "This will move the availability to the inactive tab. "
          }`,
          eventType: EVENT_REMOVED_INACTIVE_AVAILABILITY,
          buttonLabel: "OK",
          action,
        },
      },
    });
  };

  const onClickSorting = (sort: string) => {
    const updatedFilters = {
      ...paramFilters,
      sort,
      direction:
        paramFilters?.direction === DirectionEnum.asc
          ? DirectionEnum?.desc
          : DirectionEnum.asc,
    };
    navigate(
      `${location.pathname}?${queryString.stringify(updatedFilters, {
        arrayFormat: "bracket",
      })}`
    );
  };

  const onClickShowMore = () => {
    const nextPage = page + 1;
    setPage(nextPage);

    getAvailabilities({
      page: nextPage,
      limit: SEARCH_RESULT_LIMIT,
      buildings: [propertyId],
      ...paramFilters,
    }).then((res: any) => {
      setAvailabilities((prevData: any) => [...prevData, ...res?.docs]);
      setPage(res?.page);
      setTotal(res?.total);
      setSortFields(res?.sort_fields);
    });
  };

  const handleTab = (value: string) => () => {
    const updatedFilters = { active_status: [value] };
    navigate(
      `${location.pathname}?${queryString.stringify(updatedFilters, {
        arrayFormat: "bracket",
      })}`
    );
  };

  const handleImportFromMarketSphere = () => {
    const action = (event: EventProps) => {
      try {
        dispatch({
          type: SET_LOADING_MODAL,
          payload: {
            open: true,
            label: `Importing Availability from MS`,
          },
        });
        postSuiteMarketsphere({ property_id: propertyId, ...{ ...event } })
          .then((res: any) => {
            if (!res) return;
            loadData();
            Toast.success("Imported successfully");
          })
          .finally(() => {
            setTimeout(() => {
              dispatch({
                type: SET_LOADING_MODAL,
                payload: {
                  open: false,
                  label: null,
                },
              });
            }, 100);
          });
      } catch (err) {
        setTimeout(() => {
          dispatch({
            type: SET_LOADING_MODAL,
            payload: {
              open: false,
              label: null,
            },
          });
        }, 100);
      }
    };

    dispatch({
      type: SET_EVENT_MODAL,
      payload: {
        open: true,
        content: {
          title: "Import from MarketSphere",
          label: `What is reason for importing from MarketSphere?`,
          eventType: EVENT_IMPORT_FROM_MARKETSPHERE,
          buttonLabel: "Import from MarketSphere",
          action,
        },
      },
    });
  };

  const updateItem = (newValues: any, pk: string | number) => {
    const index = availabilities?.findIndex(
      (item: AvailabilityProps) => item?.pk === pk || item?.id === pk
    );
    if (index !== undefined && index > -1) {
      const newData = [...(availabilities || [])];
      newData[index] = {
        ...newData[index],
        ...newValues,
      };
      setAvailabilities(newData);
    }
  };

  const handleColumnSubmit = (data: any) => {
    try {
      const content_type = getContactTypeId(meta, "suite")?.[0];
      const payload = {
        content_type,
        json: JSON.stringify(data),
      };
      postTableSchema(payload).then(() => {
        let table_schema = meta?.table_schema;
        const index = table_schema?.findIndex(
          (schema: any) => schema?.content_type === content_type
        );
        if (!table_schema) table_schema = [];
        if (index > -1) table_schema[index] = payload;
        else table_schema.push(payload);
        dispatch({
          type: SET_META,
          payload: {
            ...meta,
            table_schema,
          },
        });
      });
    } catch (err) {}
  };

  const handleColumn = () => {
    let totalColumns = ALL_AVAILABILITIES_COLUMNS;

    dispatch({
      type: SET_INLINE_EDIT_SELECT_MODAL,
      payload: {
        open: true,
        content: {
          line: 2,
          list: totalColumns,
          label: "Add Column",
          current: columns,
          func: handleColumnSubmit,
        },
      },
    });
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    const newOrder = reorder(
      [...columns],
      result.source.index,
      result.destination.index
    );
    setColumns(newOrder);
    handleColumnSubmit(newOrder);
  };

  return (
    <Layout>
      <Helmet prioritizeSeoTags>
        <title>{`${META_TAGS?.default?.title} ${
          property?.title || property?.address
        } Availabilities`}</title>
        <meta
          property="og:title"
          content={`${META_TAGS?.default?.title} ${
            property?.title || property?.address
          } Availabilities`}
        />
      </Helmet>
      <div id="items-container" className="w-full">
        <Layout.Section
          title="Availabilities"
          button={
            <div className="flex flex-row items-center space-x-2">
              <Button variant="primary" onClick={handleImportFromMarketSphere}>
                Import from MarketSphere
              </Button>
              <Button
                variant="secondary"
                leadingIcon={FiPlusCircle}
                onClick={onClickAdd}
              >
                Add availability
              </Button>
            </div>
          }
          titleClassName="!mb-2"
        >
          <div className="flex justify-between items-center h-[50px] z-10 border-b border-jll-color-coldGray-3">
            <div className="flex flex-row space-x-8 h-full">
              {TabMenu?.map((tab: any, idx: number) => (
                <div
                  className={cn(
                    "cursor-pointer font-semibold text-sm flex items-center",
                    {
                      "text-jll-color-text-base-default border-b border-jll-color-coldGray-7":
                        activeTab?.toString() === tab.value?.toString(),
                      "text-jll-color-coldGray-5":
                        activeTab?.toString() !== tab.value?.toString(),
                    }
                  )}
                  key={idx}
                  onClick={handleTab(tab?.value)}
                >
                  {tab?.label}
                </div>
              ))}
            </div>
          </div>
          {!availabilities?.length && (
            <EmptyResult className="mt-8">
              <h3 className="text-heading3 text-jll-color-coldGray-5">
                No availabilities found.
              </h3>
              <p className="mt-4 text-body1 text-jll-color-coldGray-7 mb-2 text-sm">
                Click{" "}
                <Button
                  size="default"
                  variant="neutral"
                  className="text-jll-color-icon-info text-body1"
                  onClick={onClickAdd}
                >
                  here
                </Button>{" "}
                to add an availability.
              </p>
            </EmptyResult>
          )}

          {!!availabilities?.length && (
            <div className="flex space-x-4 mt-4 justify-between items-center">
              <div className="font-semibold text-jll-color-coldGray-7 text-sm">
                {total} result{total > 1 ? "s" : ""}
              </div>
              <div className="flex flex-row items-center space-x-2">
                {!isInlineEditMode ? (
                  <Button
                    variant="secondary"
                    leadingIcon={FiEdit3}
                    onClick={() => setIsInlineEditMode(true)}
                  >
                    Edit mode
                  </Button>
                ) : (
                  <Button
                    variant="primary"
                    leadingIcon={FiGrid}
                    onClick={() => setIsInlineEditMode(false)}
                  >
                    View mode
                  </Button>
                )}
              </div>
            </div>
          )}

          {!!availabilities?.length && (
            <div>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable" direction="horizontal">
                  {(provided, snapshot) => {
                    return (
                      <Table
                        isEdit={isInlineEditMode}
                        handleColumn={handleColumn}
                        className="mt-2"
                      >
                        <div className="hidden">{provided.placeholder}</div>
                        <Table.Thead>
                          <Table.Tr
                            className="z-[3]"
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                          >
                            {columns.map((column: any, index: number) => {
                              return (
                                <Draggable
                                  key={column.id}
                                  draggableId={`${column?.id}`}
                                  index={index}
                                  isDragDisabled={!isInlineEditMode}
                                >
                                  {(provided, snapshot) => {
                                    const usePortal: boolean =
                                      snapshot.isDragging;
                                    const child = (
                                      <Table.Th
                                        key={index}
                                        className={column?.th?.className}
                                        filterId={column.id}
                                        sortFields={sortFields}
                                        filters={paramFilters}
                                        onClick={onClickSorting}
                                        width={column?.width || null}
                                        isEdit={isInlineEditMode}
                                        editable={true}
                                        ref={provided.innerRef}
                                        draggableProps={
                                          provided?.draggableProps
                                        }
                                        dragHandleProps={
                                          provided?.dragHandleProps
                                        }
                                        itemClassName={cn({
                                          "bg-jll-color-coldGray-1 ":
                                            snapshot.isDragging,
                                        })}
                                        handleColumnSubmit={handleColumnSubmit}
                                        currentColumns={[...columns]}
                                        handleColumn={handleColumn}
                                      >
                                        {column.label}
                                      </Table.Th>
                                    );
                                    if (!usePortal) {
                                      return child;
                                    }
                                    return ReactDOM.createPortal(
                                      child,
                                      portalRef?.current
                                    );
                                  }}
                                </Draggable>
                              );
                            })}
                            <Table.Th />
                          </Table.Tr>
                        </Table.Thead>
                        <Table.Tbody>
                          {availabilities?.map(
                            (
                              availability: AvailabilityProps,
                              index: number
                            ) => {
                              if (
                                availability?.active_status?.toString() !==
                                activeTab?.toString()
                              )
                                return;
                              return (
                                <Table.Tr
                                  key={index}
                                  isEdit={isInlineEditMode}
                                  onClick={onClickEdit(availability?.pk)}
                                >
                                  {columns.map((column: any, index: number) => {
                                    return (
                                      <Table.Td
                                        key={index}
                                        className={column?.td?.className}
                                        isEdit={isInlineEditMode}
                                      >
                                        <StyledAvailabilityValue
                                          availability={availability}
                                          valueKey={column.id}
                                          isEditMode={isInlineEditMode}
                                          updateItem={updateItem}
                                          isProperty
                                        />
                                      </Table.Td>
                                    );
                                  })}
                                  <Table.Td className="flex justify-end flex-row space-x-2">
                                    {availability?.active_status ===
                                      STATUS_ACTIVE && (
                                      <>
                                        <Button
                                          variant={
                                            isLoadingPatch
                                              ? "secondary"
                                              : availability?.publish_status ===
                                                PUBLISH_STATUS_PUBLISHED
                                              ? "danger"
                                              : "primary"
                                          }
                                          size="small"
                                          disabled={isLoadingPatch}
                                          onClick={(e) =>
                                            onClickPublish(e, availability)
                                          }
                                        >
                                          {availability?.publish_status ===
                                          PUBLISH_STATUS_PUBLISHED
                                            ? "Unpublish"
                                            : "Publish"}
                                        </Button>
                                        <Button
                                          variant="secondary"
                                          size="small"
                                          onClick={(e) =>
                                            onClickPreview(
                                              e,
                                              Number(availability?.pk)
                                            )
                                          }
                                        >
                                          Preview
                                        </Button>
                                        <Button
                                          variant="secondary"
                                          size="small"
                                          onClick={(e) =>
                                            onClickRemove(e, availability)
                                          }
                                        >
                                          Move to Inactive
                                        </Button>
                                      </>
                                    )}
                                    {availability?.active_status ===
                                      STATUS_REMOVED && (
                                      <Button
                                        variant="secondary"
                                        size="small"
                                        onClick={(e) =>
                                          onClickReinstate(e, availability)
                                        }
                                      >
                                        Reinstate
                                      </Button>
                                    )}
                                  </Table.Td>
                                </Table.Tr>
                              );
                            }
                          )}
                        </Table.Tbody>
                      </Table>
                    );
                  }}
                </Droppable>
              </DragDropContext>
              <ShowMoreItems
                onClick={onClickShowMore}
                total={total}
                isLoading={isLoadingAvailabilities}
                itemsCount={availabilities.length}
              />
            </div>
          )}
        </Layout.Section>
      </div>
      <AvailabilityPreviewModal
        pk={openedAvailability}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </Layout>
  );
};

export default PropertyAvailabilities;
