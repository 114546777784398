import { useState, useEffect, useRef, useContext } from "react";
import cn from "classnames";
import { FiChevronLeft, FiChevronRight, FiPlusCircle } from "react-icons/fi";
import { RxDragHandleHorizontal } from "react-icons/rx";
import {
  Table,
  StyledBrokerValue,
  BrokerDetailModal,
  RemoveDescriptionItem,
  ContactModal,
  AccordionItem,
} from "ui-molecules";
import { Button, EmptyResult, SelectAutoComplete } from "ui-atoms";
import { LayoutEditAvailability as Layout } from "ui-organisms";
import { BrokerProps, UserProps } from "types";
import {
  AVAILABILITY_TABS,
  BROKERS_TABLE_COLUMNS,
  META_TAGS,
  OLM_AVAILABILITY_FIELDS,
  SET_AVAILABILITY,
  SET_CONFIRM_MODAL,
  STATUS_ACTIVE,
  URLS,
} from "constant";
import {
  deleteAvailabilityBrokerAPI,
  getUsersAPI,
  patchAvailabilityAPI,
} from "services";
import { Toast } from "ui-atoms";
import { useApiCall } from "hooks";
import { GlobalContext } from "context";
import { Helmet } from "react-helmet-async";
import {
  getOptionsFromCoordinators,
  isCommonItem,
  joinTwoStr,
  reorder,
} from "utils";
import { renderToString } from "react-dom/server";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Link, useParams } from "react-router-dom";
import React from "react";

interface IAvailabilityContacts {
  onDiffFields?: any;
}

const AvailabilityContacts: React.FC<IAvailabilityContacts> = ({
  onDiffFields,
}) => {
  const [deleteAvailabilityBroker] = useApiCall(deleteAvailabilityBrokerAPI);
  const [patchAvailability] = useApiCall(patchAvailabilityAPI);
  const [getUsers] = useApiCall(getUsersAPI);
  const { availabilityId, propertyId } = useParams();
  const containerEl = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [contacts, setContacts] = useState<BrokerProps[]>();
  const [leadContact, setLeadContact] = useState<BrokerProps[]>();
  const [additionalContacts, setAdditionalContacts] = useState<BrokerProps[]>();
  const [page, setPage] = useState(1);
  const { state, dispatch } = useContext(GlobalContext);
  const { meta, availability } = state;
  const [isBrokerModalOpen, setIsBrokerModalOpen] = useState(false);
  const [selectedBroker, setSelectedBroker] = useState<BrokerProps>();
  const [selectedCoordinator, setSelectedCoordinator] = useState<any>([]);

  useEffect(() => {
    if (availability) {
      setContacts(availability.brokers || []);
      setSelectedCoordinator(
        getOptionsFromCoordinators(availability?.coordinators || [])
      );
    }
  }, [availability]);

  const onScroll = () => {
    const container = containerEl?.current;
    if (container) {
      const scrollTop = container.scrollTop;
      const scrollHeight = container.scrollHeight;
      const clientHeight = container.clientHeight;
      if (scrollTop + clientHeight >= scrollHeight) {
        setPage(page + 1);
      }
    }
  };

  useEffect(() => {
    onDiffFields({});
  }, []);

  useEffect(() => {
    const lead =
      contacts?.filter((item) => item?.suite_broker_role === "Lead Broker") ||
      [];
    const additionals =
      contacts?.filter((item) => item?.suite_broker_role !== "Lead Broker") ||
      [];

    setLeadContact(lead);
    setAdditionalContacts(additionals);

    const container = containerEl?.current;
    container?.addEventListener("scroll", onScroll);
    return () => container?.removeEventListener("scroll", onScroll);
  }, [contacts]);

  const onClickContact = (broker: BrokerProps) => {
    const updatedContacts = [...(contacts || [])];
    if (!updatedContacts?.find((x) => x.pk === broker.pk)) {
      updatedContacts?.push(broker);
      dispatch({
        type: SET_AVAILABILITY,
        payload: {
          ...availability,
          brokers: updatedContacts,
        },
      });
    }
  };

  const onClickRemove = (e: any, broker: BrokerProps) => {
    e.stopPropagation();
    const action = () => {
      const updatedBrokers = contacts?.filter(
        (x) => x.suite_broker_id !== Number(broker?.suite_broker_id)
      );
      deleteAvailabilityBroker({ id: Number(broker?.suite_broker_id) }).then(
        () => {
          Toast.success("Broker removed.");
          setContacts(updatedBrokers);
          dispatch({
            type: SET_AVAILABILITY,
            payload: {
              ...availability,
              brokers: updatedBrokers || [],
            },
          });
        }
      );
    };
    dispatch({
      type: SET_CONFIRM_MODAL,
      payload: {
        open: true,
        content: {
          description: renderToString(
            <RemoveDescriptionItem target={`${broker?.name}`} />
          ),
          action,
        },
      },
    });
  };

  const onClickDetail = (broker: BrokerProps) => () => {
    setSelectedBroker(broker);
    setIsBrokerModalOpen(true);
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    const oldOrder = additionalContacts;
    const newOrder = reorder(
      additionalContacts,
      result.source.index,
      result.destination.index
    );
    setAdditionalContacts(newOrder);
    const ids =
      [...(leadContact || []), ...newOrder]?.map((contact) => contact?.id) ||
      [];
    let payload: any = { broker: ids };
    if (
      payload &&
      availability?.publish_status === STATUS_ACTIVE &&
      isCommonItem(Object.keys(payload), OLM_AVAILABILITY_FIELDS)
    ) {
      payload["publish_status"] = STATUS_ACTIVE;
    }
    patchAvailability({
      id: availability?.id,
      payload,
    })
      .then(() => {})
      .catch((err: any) => {
        setAdditionalContacts(oldOrder);
      });
  };

  const updateCoordinators = (values: any) => {
    const oldValues = [...(selectedCoordinator || [])];
    const ids =
      [...(values || [])]?.map((coordinator) => coordinator?.value) || [];

    setSelectedCoordinator(values || []);
    let payload: any = { coordinator: ids };
    if (
      payload &&
      availability?.publish_status === STATUS_ACTIVE &&
      isCommonItem(Object.keys(payload), OLM_AVAILABILITY_FIELDS)
    ) {
      payload["publish_status"] = STATUS_ACTIVE;
    }
    patchAvailability({
      id: availability?.id,
      payload,
    })
      .then((res: any) => {
        dispatch({
          type: SET_AVAILABILITY,
          payload: {
            ...availability,
            coordinators: res?.coordinators || [],
          },
        });
      })
      .catch((err: any) => {
        setSelectedCoordinator(oldValues);
      });
  };

  const loadCoordinatorOptions = async (keyword: string) => {
    return await getUsers({ keyword }).then((res: any) => {
      return res.docs.map((user: UserProps) => ({
        value: user.pk,
        label:
          user?.first_name || user?.last_name
            ? [user?.first_name, user?.last_name].join(" ")
            : user?.email,
      }));
    });
  };

  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>{`${META_TAGS?.default?.title} ${joinTwoStr(
          availability?.building_title,
          availability?.name,
          " | "
        )} Contacts`}</title>
        <meta
          property="og:title"
          content={`${META_TAGS?.default?.title} ${joinTwoStr(
            availability?.building_title,
            availability?.name,
            " | "
          )} Contacts`}
        />
      </Helmet>
      <div id="items-container" className="pb-[50px]">
        <Layout.Section>
          <AccordionItem id="coordinators" label="Listing Coordinators">
            <div className="flex flex-col w-full pt-8">
              <SelectAutoComplete
                name="coordinators"
                isMulti
                onChange={(value) => {
                  updateCoordinators(value);
                }}
                value={selectedCoordinator}
                loadOptions={loadCoordinatorOptions}
              />
            </div>
          </AccordionItem>
        </Layout.Section>
        <Layout.Section>
          <AccordionItem id="brokerage" label="Brokerage Team">
            <div className="flex flex-col w-full pt-4">
              <div className="flex w-full justify-end items-center">
                <Button
                  variant="secondary"
                  className="w-fit"
                  leadingIcon={FiPlusCircle}
                  onClick={() => {
                    setIsOpen(true);
                  }}
                >
                  Add Broker
                </Button>
              </div>
              {!contacts?.length && (
                <EmptyResult className="mt-8">
                  <h3 className="text-heading3 text-jll-color-coldGray-5">
                    Brokerage Team is empty...
                  </h3>
                  <p className="mt-4 text-body1 text-jll-color-coldGray-7 mb-2">
                    Click on the Add Broker button.
                  </p>
                </EmptyResult>
              )}
              {!!contacts?.length && (
                <Table className="mt-2">
                  <Table.Thead>
                    <Table.Tr>
                      <Table.Th />
                      {BROKERS_TABLE_COLUMNS.map((column, index) => {
                        return <Table.Th key={index}>{column.label}</Table.Th>;
                      })}
                      <Table.Th>Role</Table.Th>
                      <Table.Th />
                    </Table.Tr>
                  </Table.Thead>
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                      {(provided, snapshot) => (
                        <Table.Tbody
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {leadContact?.map(
                            (contact: BrokerProps, contact_index: number) => {
                              return (
                                <Table.Tr
                                  key={contact_index}
                                  onClick={onClickDetail(contact)}
                                >
                                  <Table.Td />
                                  {BROKERS_TABLE_COLUMNS.map(
                                    (column, column_index) => {
                                      return (
                                        <Table.Td
                                          key={column_index}
                                          className={column?.td?.className}
                                        >
                                          <StyledBrokerValue
                                            broker={contact}
                                            valueKey={column.id}
                                          />
                                        </Table.Td>
                                      );
                                    }
                                  )}
                                  <Table.Td>
                                    {contact?.suite_broker_role || "-"}
                                  </Table.Td>
                                  <Table.Td className="space-x-2 text-right">
                                    <Button
                                      variant="secondary"
                                      size="small"
                                      onClick={(e) => onClickRemove(e, contact)}
                                    >
                                      Remove
                                    </Button>
                                  </Table.Td>
                                </Table.Tr>
                              );
                            }
                          )}
                          {additionalContacts?.map(
                            (contact: BrokerProps, contact_index: number) => {
                              return (
                                <Draggable
                                  key={contact?.id}
                                  draggableId={contact?.id.toString()}
                                  index={contact_index}
                                >
                                  {(provided, snapshot) => (
                                    <Table.Tr
                                      key={contact_index}
                                      onClick={onClickDetail(contact)}
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      className={cn({
                                        "bg-jll-color-coldGray-1 flex justify-between items-center":
                                          snapshot.isDragging,
                                      })}
                                    >
                                      <Table.Td>
                                        <div
                                          className="flex items-center justify-center w-5 h-6"
                                          {...provided.dragHandleProps}
                                        >
                                          <RxDragHandleHorizontal className="text-xl" />
                                        </div>
                                      </Table.Td>
                                      {BROKERS_TABLE_COLUMNS.map(
                                        (column, column_index) => {
                                          return (
                                            <Table.Td
                                              key={column_index}
                                              className={column?.td?.className}
                                            >
                                              <StyledBrokerValue
                                                broker={contact}
                                                valueKey={column.id}
                                              />
                                            </Table.Td>
                                          );
                                        }
                                      )}
                                      <Table.Td>
                                        {contact?.suite_broker_role || "-"}
                                      </Table.Td>
                                      <Table.Td className="space-x-2 text-right">
                                        <Button
                                          variant="secondary"
                                          size="small"
                                          onClick={(e) =>
                                            onClickRemove(e, contact)
                                          }
                                        >
                                          Remove
                                        </Button>
                                      </Table.Td>
                                    </Table.Tr>
                                  )}
                                </Draggable>
                              );
                            }
                          )}
                        </Table.Tbody>
                      )}
                    </Droppable>
                  </DragDropContext>
                </Table>
              )}
            </div>
          </AccordionItem>
        </Layout.Section>

        <div className="px-8 fixed bottom-0 left-[360px] right-0 flex justify-end bg-white shadow-detail-status-nav h-[80px] items-center">
          <div className="flex space-x-2">
            <Link
              to={`/property/${propertyId}/availability/${availabilityId}/${URLS.AVAILABILITY.FORM}?tab=${AVAILABILITY_TABS.EDIT}`}
            >
              <Button variant="secondary" leadingIcon={FiChevronLeft}>
                Previous
              </Button>
            </Link>
            <Link
              to={`/property/${propertyId}/availability/${availabilityId}/${URLS.AVAILABILITY.FORM}?tab=${AVAILABILITY_TABS.MEDIA}`}
            >
              <Button variant="secondary" trailingIcon={FiChevronRight}>
                Next
              </Button>
            </Link>
          </div>
        </div>
      </div>
      <ContactModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onClick={onClickContact}
        meta={meta}
        isProperty={false}
      />
      <BrokerDetailModal
        isOpen={isBrokerModalOpen}
        setIsOpen={setIsBrokerModalOpen}
        broker={selectedBroker}
      />
    </>
  );
};

export default React.memo(AvailabilityContacts);
