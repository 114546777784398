import { generateVideoThumbnailViaUrl } from "@rajesh896/video-thumbnails-generator";

// Split the Array with count
// For ex: [1, 2, 3, 4, 5] with 3
// Result: [[1, 2, 3], [4, 5, null]]
export const sliceIntoChunks = (arr: any[] = [], chunkSize: number = 1) => {
  const res = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    let chunk = arr.slice(i, i + chunkSize);
    let emptyArr = [];
    if (chunk.length < chunkSize) {
      emptyArr = [...Array(chunkSize - chunk.length)];
      chunk = [...chunk, ...emptyArr];
    }

    res.push(chunk);
  }
  return res;
};

// Split the Array with count
// For ex: [1, 2, 3, 4, 5] with 3
// Result: [1, 2, 3, 4, 5, null]
export const sliceIntoArray = (arr: any[] = [], chunkSize: number = 1) => {
  let res: any = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    let chunk = arr.slice(i, i + chunkSize);
    let emptyArr = [];
    if (chunk.length < chunkSize) {
      emptyArr = [...Array(chunkSize - chunk.length)];
      chunk = [...chunk, ...emptyArr];
    }
    res = [...res, ...chunk];
  }
  return res;
};

// Get the image url from PDF url (need to change the extension)
export const convertExtension = (url: string = "") => {
  try {
    if (url?.toLowerCase()?.includes(".pdf")) {
      return url.replace(".pdf", ".png");
    } else if (url?.toLowerCase()?.includes(".ai")) {
      return url.replace(".ai", ".png");
    } else return url;
  } catch (err) {
    return "";
  }
};

// Get the ordinal
// for ex: 1, 2, 3
// 1st, 2nd, 3rd
export const ordinalSuffixOf = (num: string | number = "") => {
  if (!Number(num)) return num;
  const newNum = Number(num);
  const j = newNum % 10,
    k = newNum % 100;
  if (j === 1 && k !== 11) {
    return newNum + "st";
  }
  if (j === 2 && k !== 11) {
    return newNum + "nd";
  }
  if (j === 3 && k !== 11) {
    return newNum + "rd";
  }
  return newNum + "th";
};

// Convert decimal format (1000 => 1,000, 2023001 => 2,023,001)
export const getDecimalFormating = (num: number | undefined | string) => {
  if (!num || isNaN(Number(num))) {
    return undefined;
  }
  return new Intl.NumberFormat("en-US", {
    style: "decimal",
    maximumFractionDigits: 4,
  }).format(Number(num));
};

// Get the thumbnail from Youtube Url
const get_youtube_thumbnail = (url: string) => {
  if (url) {
    let video_id, result;
    if ((result = url.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/))) {
      video_id = result.pop();
    } else if ((result = url.match(/youtu.be\/(.{11})/))) {
      video_id = result.pop();
    }

    if (video_id) {
      const quality_key = "hqdefault";

      const thumbnail =
        "http://img.youtube.com/vi/" + video_id + "/" + quality_key + ".jpg";
      return thumbnail;
    }
    return "";
  }
  return "";
};

const get_vimeo_thumbnail = (url: string) => {
  try {
    const match = /vimeo.*\/(\d+)/i.exec(url);
    if (!match || !match[1]) return "";
    const vimeo_id = match[1];
    return `https://vumbnail.com/${vimeo_id}.jpg`;
  } catch (err) {
    return "";
  }
};

// Get the thumbnail from video url (urls with .mp4, .avi, etc and Youtube URL)
export const getVideoThumb = async (url: string) => {
  let thumb = get_youtube_thumbnail(url);
  if (thumb) return thumb;
  thumb = get_vimeo_thumbnail(url);
  if (thumb) return thumb;
  thumb = await generateVideoThumbnailViaUrl(url, 10).then((res: any) => {
    return res;
  });
  return thumb;
};

/**
 * Pick object with specific key
 * @param value Ex: data => { firstName: "XXX", lastName: "XXX" }, str => "first"
 * @returns Ex: {firstName: "XXX"}
 */
export const pickSpecificKeyFromObj = (data: any, str: string) => {
  return Object.keys(data)
    .filter((key) => key.includes(str))
    ?.reduce((obj, key) => {
      return Object.assign(obj, { [key]: data[key] });
    }, {});
};
