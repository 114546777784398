import cn from 'classnames';

interface IDot {
  size?: "small" | "default";
  variant: "success" | "warning" | "danger" | 'accent';
  className?: string;
}

const Dot: React.FC<IDot> = ({ variant = "success", size = "default", className }) => {
  let variantClassName = "rounded-full";
  if (variant === "success")
    variantClassName += " bg-jll-color-surface-success-default";
  else if (variant === "warning")
    variantClassName += " bg-jll-color-surface-warning-default";
  else if (variant === "danger")
    variantClassName += " bg-jll-color-surface-danger-default";
  else if (variant === "accent")
    variantClassName += " bg-jll-color-surface-accent-default";

  if (size === "small") variantClassName += " w-2 h-2";
  else if (size === "default") variantClassName += " w-3 h-3";

  return (
    <div className={cn('h-full flex justify-center items-center', className)}>
      <div className={variantClassName}></div>
    </div>
  );
};

export default Dot;
