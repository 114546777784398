import { SET_PROPERTY_REMOVE_CONFIRM_MODAL } from "constant";
import { GlobalContext } from "context";
import { useContext } from "react";
import { FiX } from "react-icons/fi";
import { Button } from "ui-atoms";
import { Modal } from "ui-molecules";

const PropertyRemoveModal = () => {
  const { dispatch, state } = useContext(GlobalContext);
  const { propertyRemoveConfirmOpen, propertyRemoveConfirmContent } = state;

  const handleClose = () => {
    dispatch({
      type: SET_PROPERTY_REMOVE_CONFIRM_MODAL,
      payload: {
        open: false,
        content: null,
      },
    });
  };

  return (
    <Modal
      isOpen={propertyRemoveConfirmOpen}
      setIsOpen={handleClose}
      size="medium"
    >
      <Modal.Header className="relative">
        <Button
          variant="neutral"
          leadingIcon={FiX}
          className="absolute top-0 right-0 w-5 !h-5"
          leadingIconClass="w-5 h-5 text-jll-color-text-base-subdued"
          onClick={handleClose}
        />
        <h4 className="text-xl font-semibold text-jll-color-text-base-default">
          Remove Property
        </h4>
      </Modal.Header>
      <Modal.Body>
        <p className="text-jll-color-text-base-subdued mt-4">
          This Property is currently published on OLM (
          <a
            href="https://property.jll.com"
            target="_blank"
            className="text-jll-color-icon-info"
          >
            property.jll.com
          </a>
          ). To unpublish this Property please click into the Property, navigate
          to "Property Publish" and select "Unpublish Property".
        </p>
      </Modal.Body>
      <Modal.Footer>
        <div className="w-full flex flex-row items-center justify-between">
          <Button variant="secondary" onClick={handleClose}>
            Close, I need to Un-Publish
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              handleClose();
              propertyRemoveConfirmContent?.continueAction();
            }}
          >
            Proceed Without Un-Publishing
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default PropertyRemoveModal;
