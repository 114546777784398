import { useRef, useState } from "react";
import cn from "classnames";
import "./styles.css";
import { TeamListingForm, TeamListingLinkForm } from "ui-molecules";
import { FiArrowRight, FiChevronRight } from "react-icons/fi";
import { Button } from "ui-atoms";
import TeamListingPropertyPanel from "pages/TeamListingProperty/TeamListingPropertyPanel";

interface ITeamListingPane {
  setIsSlidePane?: any;
}

const TAB_LIST = [
  { label: "Create Team Listing", key: 1 },
  { label: "Add Properties", key: 2 },
  { label: "Create Page", key: 3 },
];

const TeamListingPane: React.FC<ITeamListingPane> = ({
  setIsSlidePane,
}) => {
  const [activeTab, setActiveTab] = useState<number>(1);
  const listingRef = useRef<any>(null);
  const linkRef = useRef<any>(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const [teamId, setTeamId] = useState(undefined);

  const handleStep = async (currentKey: number) => {
    switch (currentKey) {
      case 1:
        await listingRef?.current?.submitForm();
        const pk = listingRef?.current?.getTeamId();
        setTeamId(pk);
        setActiveTab(2);
        break;
      case 2:
        setActiveTab(3);
        break;
      case 3:
        await linkRef.current.submitForm();
        setIsSlidePane(false);
        const linkData = linkRef?.current?.getLinkData();
        window.open(
          `${window.location.origin}/pub/tl/${linkData?.slug}/${linkData?.uuid}`,
          "_blank"
        );
        window.location.href = `/team-listing/${linkData?.team_listing}/pages?active_status[]=1`;
        break;
      default:
        setIsSlidePane(false);
    }
  };

  return (
    <div className="h-full flex flex-col relative">
      <div className="h-[80px] flex flex-row items-center shadow-detail-status-nav px-8">
        <div className="flex flex-row h-full">
          {TAB_LIST.map((tab, idx) => (
            <div className={cn("flex flex-row items-center h-full mr-4")}>
              {idx > 0 && (
                <FiArrowRight
                  className={cn("w-4 h-4 mr-4", {
                    "text-jll-color-coldGray-5": tab?.key > activeTab,
                    "text-jll-color-text-base-default": tab?.key <= activeTab,
                  })}
                />
              )}
              <div className={cn("flex flex-row items-center h-full")}>
                <div
                  className={cn(
                    "whitespace-nowrap w-6 h-6 justify-center flex items-center rounded-full mr-2 text-xs font-semibold",
                    {
                      "bg-jll-color-coldGray-1 text-jll-color-text-base-subdued":
                        tab?.key > activeTab,
                      "bg-jll-color-surface-info-default text-white":
                        tab?.key <= activeTab,
                    }
                  )}
                >{`${tab?.key}`}</div>
                <div
                  className={cn("flex flex-col text-sm font-semibold", {
                    "text-jll-color-coldGray-5": tab?.key > activeTab,
                    "text-jll-color-text-base-default": tab?.key <= activeTab,
                  })}
                >
                  <span>{tab?.label}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div
        className={cn(
          "absolute top-[80px] bottom-[80px] left-0 right-0 px-8 py-8",
          {
            "!pr-0 !pb-0": activeTab === 2,
          }
        )}
      >
        {activeTab === 1 && (
          <TeamListingForm
            isOpen
            setIsOpen={() => {}}
            isAdd
            ref={listingRef}
            setIsDisabled={setIsDisabled}
          />
        )}
        {activeTab === 2 && <TeamListingPropertyPanel isAdd team_id={teamId} />}
        {activeTab === 3 && (
          <TeamListingLinkForm
            isOpen
            setIsOpen={() => {}}
            isAdd
            team_id={teamId}
            ref={linkRef}
            setIsDisabled={setIsDisabled}
          />
        )}
      </div>

      <div className="px-8 fixed bottom-0 left-0 right-0 flex justify-end bg-white shadow-detail-status-nav h-[80px] items-center">
        <div className="flex space-x-4">
          <Button
            trailingIcon={activeTab !== 3 && FiChevronRight}
            variant={"primary"}
            onClick={() => handleStep(activeTab)}
            disabled={isDisabled}
          >
            {activeTab === 3 ? "Done" : "Next"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TeamListingPane;
