export const emailSuiteDivideJSON = [
  {
    id: "Pkx--0o4H4",
    cells: [1],
    columns: [
      {
        id: "j-KN5eXlrH",
        contents: [
          {
            id: "LmOog5K6md",
            type: "divider",
            values: {
              width: "100%",
              border: {
                borderTopWidth: "2px",
                borderTopStyle: "solid",
                borderTopColor: "#BBBBBB",
              },
              textAlign: "center",
              containerPadding: "10px 0px",
              anchor: "",
              displayCondition: null,
              _meta: {
                htmlID: "u_content_divider_18",
                htmlClassNames: "u_content_divider",
              },
              selectable: true,
              draggable: true,
              duplicatable: true,
              deletable: true,
              hideable: true,
            },
          },
        ],
        values: {
          backgroundColor: "",
          padding: "0px",
          border: {},
          borderRadius: "0px",
          _meta: { htmlID: "u_column_87", htmlClassNames: "u_column" },
        },
      },
    ],
    values: {
      displayCondition: null,
      columns: false,
      backgroundColor: "",
      columnsBackgroundColor: "",
      backgroundImage: {
        url: "",
        fullWidth: true,
        repeat: "no-repeat",
        size: "custom",
        position: "center",
      },
      padding: "0px",
      anchor: "",
      _meta: { htmlID: "u_row_39", htmlClassNames: "u_row" },
      selectable: true,
      draggable: true,
      duplicatable: true,
      deletable: true,
      hideable: true,
    },
  },
];
