import { SET_LOADING_MODAL } from "constant";
import { GlobalContext } from "context";
import { useContext } from "react";
import { Loading } from "ui-atoms";
import { Modal } from "ui-molecules";

const LoadingModal = () => {
  const { state } = useContext(GlobalContext);
  const { loadingModalOpen, loadingModalLabel } = state;

  return (
    <Modal isOpen={loadingModalOpen} setIsOpen={() => {}} size="small">
      <Modal.Body className="flex flex-col justify-center items-center space-y-4 h-[200px]">
        <p className="text-body1 text-center">{loadingModalLabel || ""}</p>
        <div>
          <Loading />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default LoadingModal;
