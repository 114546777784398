import type { FC, ReactElement } from "react";
import cn from "classnames";

interface LayoutEditSectionProps {
  className?: string;
  title?: string;
  titleClassName?: string;
  children: any;
  button?: ReactElement;
  filter?: ReactElement;
}

const LayoutEditSection: FC<LayoutEditSectionProps> = ({
  title = "",
  className,
  children,
  button,
  filter,
  titleClassName,
}) => {
  return (
    <section className={cn("my-10 px-8", className)}>
      <div
        className={cn("flex justify-between items-center", titleClassName, {
          "mb-4": !!title?.length,
        })}
      >
        <h2 className="text-heading1 font-semibold">{title}</h2>
        <div className="flex flex-row items-center">
          {filter}
          {button}
        </div>
      </div>
      {children}
    </section>
  );
};

export default LayoutEditSection;
