import { useEffect, useState, useMemo, useContext } from "react";
import type { FC } from "react";
import { Helmet } from "react-helmet-async";
import debounce from "lodash.debounce";
import { useNavigate, useLocation } from "react-router-dom";
import {
  FiSearch,
  FiRotateCcw,
  FiEdit3,
  FiGrid,
  FiDownload,
} from "react-icons/fi";
import cn from "classnames";
import {
  EmptyResult,
  Loading,
  Button,
  Input,
  Toast,
  SearchableMultipleSelect,
} from "ui-atoms";
import {
  PROPERTIES_TABLE_COLUMNS,
  URLS,
  META_TAGS,
  SET_LOADING_MODAL,
  EventProps,
  SET_EVENT_MODAL,
  EVENT_ADD_TO_MYLISTINGS,
  EVENT_REMOVED_INACTIVE_PROPERTY,
  ALL_PROPERTY_COLUMNS,
  SET_INLINE_EDIT_SELECT_MODAL,
  SET_META,
  STATUS_ACTIVE,
  SET_PROPERTY_REMOVE_CONFIRM_MODAL,
  AVAILABILITIES_TABLE_COLUMNS,
  PUBLISH_STATUS_PUBLISHED,
  EVENT_REMOVED_INACTIVE_AVAILABILITY,
  STATUS_REMOVED,
  EVENT_REINSTATED_AVAILABILITY,
  ALL_AVAILABILITIES_COLUMNS,
} from "constant";
import {
  Table,
  ShowMoreItems,
  StyledPropertyValue,
  Facets,
  StyledAvailabilityValue,
  AvailabilityPreviewModal,
} from "ui-molecules";
import queryString from "query-string";
import { LayoutListing } from "ui-organisms";
import { SEARCH_RESULT_LIMIT } from "./constants";
import type {
  AvailabilityProps,
  BrokerProps,
  CoordinatorProps,
  FacetsProps,
  FilteredFacetProps,
  PropertyProps,
} from "types";
import { DirectionEnum } from "types";
import {
  deleteAvailabilityAPI,
  getAvailabilitiesAPI,
  getAvailabilitiesExcelAPI,
  getBrokersAPI,
  getMarketsAPI,
  getPropertiesAPI,
  getPropertiesExcelAPI,
  getUsersAPI,
  patchAvailabilityAPI,
  patchPropertyAPI,
  postTableSchemaAPI,
} from "services";
import { useApiCall } from "hooks";
import PropertyModal from "./PropertyModal";
import { GlobalContext } from "context";
import {
  getContactTypeId,
  getCoordinatorIds,
  getFilteredFacets,
  getFormatedDateTime,
  getMetaOptions,
  getStateOptions,
  reorder,
} from "utils";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import ReactDOM from "react-dom";
import React from "react";

const TABS = {
  PROPERTIES: "properties",
  AVAILABILITIES: "availabilities",
};

const TAB_LIST = [
  { label: "Properties", value: TABS.PROPERTIES },
  { label: "Availabilities", value: TABS.AVAILABILITIES },
];

const Properties: FC = () => {
  const { state, dispatch } = useContext(GlobalContext);
  const { meta, portalRef } = state;
  const session = JSON.parse(sessionStorage.getItem("session") || "null");
  const [getProperties, isLoadingProperties] = useApiCall(getPropertiesAPI);
  const [getPropertiesExcel] = useApiCall(getPropertiesExcelAPI);
  const [getAvailabilities, isLoadingAvailabilities] =
    useApiCall(getAvailabilitiesAPI);
  const [getAvailabilitiesExcel] = useApiCall(getAvailabilitiesExcelAPI);
  const [deleteAvailabilities] = useApiCall(deleteAvailabilityAPI);
  const [patchAvailability] = useApiCall(patchAvailabilityAPI);

  const [patchProperty] = useApiCall(patchPropertyAPI);
  const [getUsers] = useApiCall(getUsersAPI);
  const [getMarkets] = useApiCall(getMarketsAPI);
  const [getBrokers] = useApiCall(getBrokersAPI);
  const [postTableSchema] = useApiCall(postTableSchemaAPI);
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const [columns, setColumns] = useState<any>([]);
  const [isInlineEditMode, setIsInlineEditMode] = useState(false);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState<PropertyProps[]>();
  const [filterFacets, setFilterFacets] = useState<FilteredFacetProps[]>([]);
  const [paramFilters, setParamFilters] = useState<any>(null);
  const [sortFields, setSortFields] = useState<string[]>([]);
  const [keyword, setKeyword] = useState<string>();
  const [isExport, setIsExport] = useState(false);
  const [activeTab, setActiveTab] = useState(TAB_LIST[0].value);
  const [openedAvailability, setOpenedAvailability] = useState(-1);
  const [isSuitePreviewOpen, setIsSuitePreviewOpen] = useState(false);

  let service: any, serviceExcel: any;
  if (activeTab === TABS.PROPERTIES) {
    service = getProperties;
    serviceExcel = getPropertiesExcel;
  } else if (activeTab === TABS.AVAILABILITIES) {
    service = getAvailabilities;
    serviceExcel = getAvailabilitiesExcel;
  }

  useEffect(() => {
    const filters = queryString.parse(location?.search, {
      arrayFormat: "bracket",
    });
    if (filters?.tab) {
      setActiveTab(filters?.tab?.toString()?.toLowerCase());
    }
    setParamFilters(filters);
  }, [location]);

  useEffect(() => {
    if (!service || paramFilters === null) return;
    setKeyword(paramFilters?.keyword || "");
    setPage(1);
    setData([]);
    service({
      page: 1,
      limit: SEARCH_RESULT_LIMIT,
      ...paramFilters,
    }).then((data: any) => {
      setData([...data?.docs]);
      setPage(data?.page);
      setTotal(data?.total);
      setFilterFacets(getFilteredFacets(data?.facets as FacetsProps));
      setSortFields(data?.sort_fields);
    });
  }, [paramFilters]);

  useEffect(() => {
    if (!meta) return;
    let content_type: any = undefined;
    if (activeTab === TABS.PROPERTIES)
      content_type = getContactTypeId(meta, "building")?.[0];
    else if (activeTab === TABS.AVAILABILITIES)
      content_type = getContactTypeId(meta, "suite")?.[0];
    setColumns(
      !!meta?.table_schema?.length &&
        !!meta?.table_schema?.find(
          (schema: any) => schema?.content_type === content_type
        )
        ? JSON.parse(
            meta?.table_schema?.find(
              (schema: any) => schema?.content_type === content_type
            )?.json
          )
        : activeTab === TABS.PROPERTIES
        ? PROPERTIES_TABLE_COLUMNS
        : AVAILABILITIES_TABLE_COLUMNS
    );
  }, [meta, activeTab]);

  const onClickSorting = (sort: string) => {
    const updatedFilters = {
      ...paramFilters,
      sort,
      direction:
        paramFilters?.direction === DirectionEnum.asc
          ? DirectionEnum?.desc
          : DirectionEnum.asc,
    };
    navigate(
      `${location.pathname}?${queryString.stringify(updatedFilters, {
        arrayFormat: "bracket",
      })}`
    );
  };

  const onClickEdit = (pk: PropertyProps["pk"]) => () => {
    navigate(`/property/${pk}/${URLS.PROPERTY.FORM}`);
  };

  const onClickAvailabilityEdit = (availability: AvailabilityProps) => () => {
    navigate(
      `/property/${
        availability?.building || availability?.building_id
      }/availability/${availability?.pk}/${URLS.AVAILABILITY.FORM}`
    );
  };

  const onClickShowMore = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    service({
      page: nextPage,
      limit: SEARCH_RESULT_LIMIT,
      ...paramFilters,
    }).then((data: any) => {
      setData((prevData: any) => [...prevData, ...data?.docs]);
      setPage(data?.page);
      setTotal(data?.total);
      setFilterFacets(getFilteredFacets(data?.facets as FacetsProps));
      setSortFields(data?.sort_fields);
    });
  };

  const updateKeywordFilter = (e: any) => {
    const updatedFilters = { ...paramFilters };
    if (e.target.value) {
      updatedFilters["keyword"] = e.target.value;
    } else if (updatedFilters.hasOwnProperty("keyword")) {
      delete updatedFilters.keyword;
    }
    navigate(
      `${location.pathname}?${queryString.stringify(updatedFilters, {
        arrayFormat: "bracket",
      })}`
    );
  };

  const debounceUpdateKeyword = useMemo(
    () => debounce(updateKeywordFilter, 300),
    [paramFilters]
  );

  const onChangeInput = (e: any) => {
    setKeyword(e.target.value);
    debounceUpdateKeyword(e);
  };

  const onClickMarketSphere = () => {
    setIsOpen(true);
  };

  const onClickReset = () => {
    navigate(location.pathname);
  };

  const handleAddCoordinator = async (e: any, property: PropertyProps) => {
    e.stopPropagation();
    const action = async (event: EventProps) => {
      try {
        dispatch({
          type: SET_LOADING_MODAL,
          payload: {
            open: true,
            label: "Adding this to mylisting",
          },
        });
        const updatedCoordinators = [
          ...getCoordinatorIds(
            property?.coordinator || property?.coordinators || []
          ),
          session?.id,
        ];
        const response = await patchProperty({
          id: property?.pk,
          payload: { coordinator: updatedCoordinators, ...{ ...event } },
        });
        setTimeout(() => {
          dispatch({
            type: SET_LOADING_MODAL,
            payload: {
              open: false,
              label: null,
            },
          });
        }, 100);
        if (!response) return;
        Toast.success("Added to MyListings");
        const newData: PropertyProps[] =
          [...(data || [])]?.map((obj) => {
            if (obj?.pk === property?.pk) {
              return {
                ...obj,
                coordinator:
                  response?.coordinator || response?.coordinators || [],
              };
            }
            return obj;
          }) || [];
        setData(newData);
      } catch (err) {
        setTimeout(() => {
          dispatch({
            type: SET_LOADING_MODAL,
            payload: {
              open: false,
              label: null,
            },
          });
        }, 100);
      }
    };

    dispatch({
      type: SET_EVENT_MODAL,
      payload: {
        open: true,
        content: {
          title: "Add to MyListings",
          label: `What is reason for adding this property to MyListings?`,
          eventType: EVENT_ADD_TO_MYLISTINGS,
          buttonLabel: "Add to MyListings",
          action,
        },
      },
    });
  };

  const onClickPropertyRemove = (e: any, item: PropertyProps) => {
    e.stopPropagation();
    const action = (event: EventProps) => {
      dispatch({
        type: SET_LOADING_MODAL,
        payload: {
          open: true,
          label: `Removing the property from My Listings`,
        },
      });

      const broker_ids =
        (item?.broker as BrokerProps[])
          ?.filter((value: BrokerProps) => value.id !== session?.broker_id)
          ?.map((contact) => contact?.id) || [];
      const coordinator_ids =
        (item?.coordinator || item?.coordinators)
          ?.filter((value: CoordinatorProps) => value.id !== session?.id)
          ?.map((coordinator: CoordinatorProps) => coordinator?.id) || [];

      const body = {
        id: item?.pk,
        payload: {
          broker: broker_ids,
          coordinator: coordinator_ids,
          ...{ ...event },
        },
      };

      patchProperty(body)
        .then((res: any) => {
          if (!res?.id) return;
          Toast.success("Property removed with success!");
          const newData: PropertyProps[] =
            [...(data || [])]?.map((obj) => {
              if (obj?.pk === res?.id) {
                return {
                  ...obj,
                  coordinator: res?.coordinators || [],
                };
              }
              return obj;
            }) || [];
          setTotal(newData?.length);
          setData(newData);
        })
        .finally(() => {
          setTimeout(() => {
            dispatch({
              type: SET_LOADING_MODAL,
              payload: {
                open: false,
                label: null,
              },
            });
          }, 100);
        });
    };

    const handleEventModal = () => {
      dispatch({
        type: SET_EVENT_MODAL,
        payload: {
          open: true,
          content: {
            title: "Remove Property",
            label: `What is reason for removing this property?`,
            eventType: EVENT_REMOVED_INACTIVE_PROPERTY,
            buttonLabel: "Remove Property",
            action,
          },
        },
      });
    };

    if (item?.publish_status === STATUS_ACTIVE) {
      dispatch({
        type: SET_PROPERTY_REMOVE_CONFIRM_MODAL,
        payload: {
          open: true,
          content: {
            continueAction: handleEventModal,
          },
        },
      });
    } else {
      handleEventModal();
    }
  };

  const updateItem = (newValues: any, pk: string | number) => {
    const index = data?.findIndex(
      (item: PropertyProps | AvailabilityProps) =>
        item?.pk === pk || item?.id === pk
    );
    if (index !== undefined && index > -1) {
      const newData = [...(data || [])];
      newData[index] = {
        ...newData[index],
        ...newValues,
      };
      setData(newData);
    }
  };

  const handleColumnSubmit = (data: any) => {
    try {
      let content_type: any = undefined;
      if (activeTab === TABS.PROPERTIES)
        content_type = getContactTypeId(meta, "building")?.[0];
      else if (activeTab === TABS.AVAILABILITIES)
        content_type = getContactTypeId(meta, "suite")?.[0];
      const payload = {
        content_type,
        json: JSON.stringify(data),
      };
      postTableSchema(payload).then(() => {
        let table_schema = meta?.table_schema;
        const index = table_schema?.findIndex(
          (schema: any) => schema?.content_type === content_type
        );
        if (!table_schema) table_schema = [];
        if (index > -1) table_schema[index] = payload;
        else table_schema.push(payload);
        dispatch({
          type: SET_META,
          payload: {
            ...meta,
            table_schema,
          },
        });
      });
    } catch (err) {}
  };

  const handleColumn = () => {
    let totalColumns;
    if (activeTab === TABS.PROPERTIES) totalColumns = ALL_PROPERTY_COLUMNS;
    else if (activeTab === TABS.AVAILABILITIES)
      totalColumns = ALL_AVAILABILITIES_COLUMNS;

    dispatch({
      type: SET_INLINE_EDIT_SELECT_MODAL,
      payload: {
        open: true,
        content: {
          line: 2,
          list: totalColumns,
          label: "Add Column",
          current: columns,
          func: handleColumnSubmit,
        },
      },
    });
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    const newOrder = reorder(
      [...columns],
      result.source.index,
      result.destination.index
    );
    setColumns(newOrder);
    handleColumnSubmit(newOrder);
  };

  const handleExport = async () => {
    try {
      setIsExport(true);
      const response = await serviceExcel({
        export: true,
        ...paramFilters,
      });
      const fileData = new Blob([response], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const fileUrl = URL.createObjectURL(fileData);
      const link = document.createElement("a");
      link.href = fileUrl;
      link.download = `Export ${
        activeTab === TABS.PROPERTIES ? "Property" : "Availability"
      } - ${getFormatedDateTime(new Date().toString())}`;
      link.click();
      URL.revokeObjectURL(fileUrl);
      setIsExport(false);
    } catch (err: any) {
      if (err?.message) Toast.error(err?.message);
      setIsExport(false);
    }
  };

  const handleTab = (value: string) => () => {
    const updatedFilters: any = { tab: value };
    if (value === TABS.AVAILABILITIES)
      updatedFilters["active_status"] = [STATUS_ACTIVE];
    navigate(
      `${location.pathname}?${queryString.stringify(updatedFilters, {
        arrayFormat: "bracket",
      })}`
    );
  };

  const reloadAvailabilities = () => {
    getAvailabilities({
      page: 1,
      limit: SEARCH_RESULT_LIMIT,
      ...paramFilters,
    }).then((res: any) => {
      setData(res?.docs);
      setPage(res?.page);
      setTotal(res?.total);
      setFilterFacets(getFilteredFacets(res?.facets as FacetsProps));
      setSortFields(res?.sort_fields);
    });
  };

  const onClickPreview = (e: any, pk: AvailabilityProps["pk"]) => {
    e.stopPropagation();
    setOpenedAvailability(pk);
    setIsSuitePreviewOpen(true);
  };

  const onClickRemove = (e: any, availability: AvailabilityProps) => {
    e.stopPropagation();
    const action = (event: EventProps) => {
      dispatch({
        type: SET_LOADING_MODAL,
        payload: {
          open: true,
          label: "Moving Availability to Inactive",
        },
      });
      deleteAvailabilities({
        id: availability?.pk,
        payload: { ...{ ...event } },
      })
        .then((res: any) => {
          Toast.success("Availability moved with success!");
          reloadAvailabilities();
        })
        .finally(() => {
          setTimeout(() => {
            dispatch({
              type: SET_LOADING_MODAL,
              payload: {
                open: false,
                label: null,
              },
            });
          }, 100);
        });
    };

    dispatch({
      type: SET_EVENT_MODAL,
      payload: {
        open: true,
        content: {
          title: "Move Availability to Inactive",
          label: `${
            availability?.publish_status === PUBLISH_STATUS_PUBLISHED
              ? "This will unpublish the availability and move the availability to the inactive tab.  What is the reason for unpublishing and moving this availability to inactive?"
              : "This will move the availability to the inactive tab. "
          }`,
          eventType: EVENT_REMOVED_INACTIVE_AVAILABILITY,
          buttonLabel: "OK",
          action,
        },
      },
    });
  };

  const onActiveUpdate = async (
    id: any,
    active_status: number,
    event: any = {}
  ) => {
    const response = await patchAvailability({
      id,
      payload: {
        active_status,
        ...{ ...event },
      },
    });
    if (!response) return;
    Toast.success("Updated successfully");
    reloadAvailabilities();
  };

  const onClickReinstate = async (e: any, availability: any) => {
    e.stopPropagation();
    const action = async (event: EventProps) => {
      try {
        dispatch({
          type: SET_LOADING_MODAL,
          payload: {
            open: true,
            label: `Reinstating the availability`,
          },
        });
        await onActiveUpdate(availability?.pk, STATUS_ACTIVE, event);
        setTimeout(() => {
          dispatch({
            type: SET_LOADING_MODAL,
            payload: {
              open: false,
              label: null,
            },
          });
        }, 100);
      } catch (err) {
        setTimeout(() => {
          dispatch({
            type: SET_LOADING_MODAL,
            payload: {
              open: false,
              label: null,
            },
          });
        }, 100);
      }
    };
    dispatch({
      type: SET_EVENT_MODAL,
      payload: {
        open: true,
        content: {
          title: "Reinstate Availability",
          label: `What is reason for reinstate this availability?`,
          eventType: EVENT_REINSTATED_AVAILABILITY,
          buttonLabel: "Reinstate Availability",
          action,
        },
      },
    });
  };

  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>{META_TAGS?.properties?.title}</title>
        <meta property="og:title" content={META_TAGS?.properties?.title} />
      </Helmet>
      <LayoutListing>
        <LayoutListing.Header>
          <div className="flex flex-row space-x-8 h-full">
            {TAB_LIST?.map((tab: any, idx: number) => (
              <div
                className={cn(
                  "cursor-pointer font-semibold text-base flex items-center",
                  {
                    "text-jll-color-text-base-default border-b border-jll-color-coldGray-7":
                      activeTab?.toLowerCase() === tab.value,
                    "text-jll-color-coldGray-5":
                      activeTab?.toLowerCase() !== tab.value,
                  }
                )}
                key={idx}
                onClick={handleTab(tab?.value)}
              >
                {tab?.label}
              </div>
            ))}
          </div>
          <div className="flex space-x-4 items-center">
            <div className="flex flex-row items-center space-x-3">
              {(() => {
                if (!meta) return;
                const options = getStateOptions(meta);
                if (!options) return;
                return (
                  <SearchableMultipleSelect
                    name="state"
                    title="State"
                    options={options}
                  />
                );
              })()}
              {(() => {
                const data = filterFacets?.find(
                  (facet) => facet?.id === "city"
                );
                if (!data) return;
                return (
                  <SearchableMultipleSelect
                    name="city"
                    title="City"
                    options={data?.facets}
                  />
                );
              })()}
              {(() => {
                const data = filterFacets?.find(
                  (facet) => facet?.id === "market"
                );
                if (!data) return;
                return (
                  <SearchableMultipleSelect
                    name={data?.id}
                    title={data?.label}
                    serviceAPI={getMarkets}
                    isMissing={false}
                    isLabelValue
                  />
                );
              })()}
              {(() => {
                const data = filterFacets?.find(
                  (facet) => facet?.id === "coordinator"
                );
                if (!data) return;
                return (
                  <SearchableMultipleSelect
                    name={data?.id}
                    title={data?.label}
                    serviceAPI={getUsers}
                  />
                );
              })()}
              {(() => {
                const data = filterFacets?.find(
                  (facet) => facet?.id === "broker"
                );
                if (!data) return;
                return (
                  <SearchableMultipleSelect
                    name={data?.id}
                    title={data?.label}
                    serviceAPI={getBrokers}
                  />
                );
              })()}
            </div>
            <Input
              className="w-[400px] !mb-0"
              name="search"
              leadingIcon={FiSearch}
              onChange={onChangeInput}
              value={keyword}
              isClearable
              placeholder="Search by building name, listing ID, address, brokers name"
            />
          </div>
        </LayoutListing.Header>
        <LayoutListing.Container>
          <Facets filterFacets={filterFacets} />
          <LayoutListing.Content>
            <div className="p-6">
              <div className="flex space-x-4 pb-6 justify-between">
                <div className="font-semibold text-jll-color-coldGray-7 text-sm">
                  {total} result{total > 1 ? "s" : ""}
                </div>
                <div className="flex flex-row items-center space-x-2">
                  <Button
                    variant="primary"
                    leadingIcon={FiDownload}
                    leadingIconClass="font-bold"
                    onClick={handleExport}
                    disabled={isExport}
                  >
                    Export to Excel
                  </Button>
                  {!isInlineEditMode ? (
                    <Button
                      variant="secondary"
                      leadingIcon={FiEdit3}
                      onClick={() => setIsInlineEditMode(true)}
                    >
                      Edit mode
                    </Button>
                  ) : (
                    <Button
                      variant="primary"
                      leadingIcon={FiGrid}
                      onClick={() => setIsInlineEditMode(false)}
                    >
                      View mode
                    </Button>
                  )}
                  {paramFilters && !!Object.keys(paramFilters)?.length && (
                    <Button
                      variant="ghost"
                      size="small"
                      leadingIcon={FiRotateCcw}
                      onClick={onClickReset}
                    >
                      Reset
                    </Button>
                  )}
                </div>
              </div>
              {(activeTab === TABS.PROPERTIES
                ? isLoadingProperties
                : isLoadingAvailabilities) &&
                !data?.length && <Loading />}
              {(activeTab === TABS.PROPERTIES
                ? !isLoadingProperties
                : !isLoadingAvailabilities) &&
                data &&
                !data?.length &&
                (activeTab === TABS.PROPERTIES ? (
                  <EmptyResult>
                    <h3 className="text-heading3 text-jll-color-coldGray-5">
                      Sorry, no properties found.
                    </h3>
                    <p className="mt-4 text-body1 text-jll-color-coldGray-7 mb-2">
                      Property not in MyListings?
                    </p>
                    <Button
                      variant="primary"
                      leadingIcon={FiSearch}
                      onClick={onClickMarketSphere}
                    >
                      Search MarketSphere
                    </Button>
                  </EmptyResult>
                ) : (
                  <EmptyResult>
                    <h3 className="text-heading3 text-jll-color-coldGray-5">
                      Sorry, no availabilities found.
                    </h3>
                  </EmptyResult>
                ))}
              {!!data?.length && (
                <>
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable" direction="horizontal">
                      {(provided, snapshot) => {
                        return (
                          <Table
                            isEdit={isInlineEditMode}
                            handleColumn={handleColumn}
                          >
                            <div className="hidden">{provided.placeholder}</div>
                            <Table.Thead>
                              <Table.Tr
                                className="z-[3]"
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                              >
                                {columns.map((column: any, index: number) => {
                                  return (
                                    <Draggable
                                      key={column.id}
                                      draggableId={`${column?.id}`}
                                      index={index}
                                      isDragDisabled={!isInlineEditMode}
                                    >
                                      {(provided, snapshot) => {
                                        const usePortal: boolean =
                                          snapshot.isDragging;
                                        const child = (
                                          <Table.Th
                                            key={index}
                                            className={column?.th?.className}
                                            filterId={column.id}
                                            sortFields={sortFields}
                                            filters={paramFilters}
                                            onClick={onClickSorting}
                                            width={column?.width || null}
                                            isEdit={isInlineEditMode}
                                            editable={true}
                                            ref={provided.innerRef}
                                            draggableProps={
                                              provided?.draggableProps
                                            }
                                            dragHandleProps={
                                              provided?.dragHandleProps
                                            }
                                            itemClassName={cn({
                                              "bg-jll-color-coldGray-1 ":
                                                snapshot.isDragging,
                                            })}
                                            handleColumnSubmit={
                                              handleColumnSubmit
                                            }
                                            currentColumns={[...columns]}
                                            handleColumn={handleColumn}
                                          >
                                            {column.label}
                                          </Table.Th>
                                        );
                                        if (!usePortal) {
                                          return child;
                                        }
                                        return ReactDOM.createPortal(
                                          child,
                                          portalRef?.current
                                        );
                                      }}
                                    </Draggable>
                                  );
                                })}
                                <Table.Th />
                              </Table.Tr>
                            </Table.Thead>
                            <Table.Tbody>
                              {data?.map(
                                (
                                  item: PropertyProps | AvailabilityProps,
                                  index: number
                                ) => {
                                  return (
                                    <Table.Tr
                                      key={index}
                                      onClick={
                                        activeTab === TABS.PROPERTIES
                                          ? onClickEdit(item?.pk)
                                          : onClickAvailabilityEdit(
                                              item as AvailabilityProps
                                            )
                                      }
                                      isEdit={isInlineEditMode}
                                    >
                                      {columns.map(
                                        (column: any, index: number) => {
                                          return (
                                            <Table.Td
                                              key={index}
                                              className={column?.td?.className}
                                              isEdit={isInlineEditMode}
                                            >
                                              {activeTab === TABS.PROPERTIES ? (
                                                <StyledPropertyValue
                                                  property={
                                                    item as PropertyProps
                                                  }
                                                  valueKey={column.id}
                                                  isEditMode={isInlineEditMode}
                                                  updateItem={updateItem}
                                                />
                                              ) : (
                                                <StyledAvailabilityValue
                                                  availability={
                                                    item as AvailabilityProps
                                                  }
                                                  valueKey={column.id}
                                                  isEditMode={isInlineEditMode}
                                                  updateItem={updateItem}
                                                />
                                              )}
                                            </Table.Td>
                                          );
                                        }
                                      )}
                                      <Table.Td className="space-x-2 text-right">
                                        {activeTab === TABS.PROPERTIES ? (
                                          <>
                                            {(
                                              item?.coordinator ||
                                              item?.coordinators
                                            )?.find(
                                              (item: CoordinatorProps) =>
                                                item?.id?.toString() ===
                                                session?.id?.toString()
                                            ) ? (
                                              <Button
                                                variant="secondary"
                                                size="small"
                                                onClick={(e) =>
                                                  onClickPropertyRemove(
                                                    e,
                                                    item as PropertyProps
                                                  )
                                                }
                                              >
                                                Remove from My Listings
                                              </Button>
                                            ) : (
                                              <Button
                                                variant="secondary"
                                                size="small"
                                                trailingIconClass="w-5 h-5"
                                                onClick={(e) =>
                                                  handleAddCoordinator(
                                                    e,
                                                    item as PropertyProps
                                                  )
                                                }
                                              >
                                                Add to My Listings
                                              </Button>
                                            )}
                                          </>
                                        ) : (
                                          <>
                                            {(item as AvailabilityProps)
                                              ?.active_status ===
                                              STATUS_ACTIVE && (
                                              <>
                                                <Button
                                                  variant="secondary"
                                                  size="small"
                                                  onClick={(e) =>
                                                    onClickPreview(e, item?.pk)
                                                  }
                                                >
                                                  Preview
                                                </Button>
                                                <Button
                                                  variant="secondary"
                                                  size="small"
                                                  onClick={(e) =>
                                                    onClickRemove(
                                                      e,
                                                      item as AvailabilityProps
                                                    )
                                                  }
                                                >
                                                  Move to Inactive
                                                </Button>
                                              </>
                                            )}
                                            {(item as AvailabilityProps)
                                              ?.active_status ===
                                              STATUS_REMOVED && (
                                              <Button
                                                variant="secondary"
                                                size="small"
                                                onClick={(e) =>
                                                  onClickReinstate(
                                                    e,
                                                    item as AvailabilityProps
                                                  )
                                                }
                                              >
                                                Reinstate
                                              </Button>
                                            )}
                                          </>
                                        )}
                                      </Table.Td>
                                    </Table.Tr>
                                  );
                                }
                              )}
                            </Table.Tbody>
                          </Table>
                        );
                      }}
                    </Droppable>
                  </DragDropContext>
                  <ShowMoreItems
                    onClick={onClickShowMore}
                    total={total}
                    isLoading={isLoadingProperties}
                    itemsCount={data.length}
                  />
                  <div className="flex justify-center w-full">
                    <p className=" text-jll-color-coldGray-7">
                      <a
                        type="button"
                        className="text-jll-color-icon-info cursor-pointer"
                        onClick={onClickMarketSphere}
                      >
                        Don't see your Property in My Listings? Click here to
                        search MarketSphere.
                      </a>
                    </p>
                  </div>
                </>
              )}
            </div>
          </LayoutListing.Content>
        </LayoutListing.Container>
      </LayoutListing>
      <PropertyModal isOpen={isOpen} setIsOpen={setIsOpen} />
      <AvailabilityPreviewModal
        pk={openedAvailability}
        isOpen={isSuitePreviewOpen}
        setIsOpen={setIsSuitePreviewOpen}
      />
    </>
  );
};

export default React.memo(Properties);
