import { useContext, type FC, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";
import { Chip } from "ui-atoms";
import {
  ActivityContent,
  AvatarGroup,
  EditableFileField,
  EditableMenuField,
  EditableMultiField,
  EditableSingleField,
  ImageGroup,
  ListTooltip,
} from "ui-molecules";
import type { ActivityProps, PropertyProps } from "types";
import {
  DIRECT_AVAILABLE_SPACE_UNIT,
  MAX_CONTIGUOUS_SPACE_UNIT,
  PARKING_TYPES,
  PROPERTY_TABS,
  SET_INLINE_EDIT_AMENITY_MODAL,
  SET_INLINE_EDIT_HIGHLIGHT_MODAL,
  STATUS_CONDITIONS,
  URLS,
} from "constant";
import {
  lazyLoadImageOnError,
  getFormatedDate,
  getDecimalFormating,
  getFormatedDateTime,
  joinTwoStr,
  noPropagation,
  convertExtension,
  getMetaOptions,
  getDifferenceDaysFromNow,
} from "utils";
import { convertImageUrl, getMarketsAPI } from "services";
import { GlobalContext } from "context";
import { renderToString } from "react-dom/server";
import { FiCheckCircle, FiEdit3 } from "react-icons/fi";
import cn from "classnames";
import { useApiCall } from "hooks";

export interface StyledPropertyValueProps {
  property?: PropertyProps;
  activity?: ActivityProps;
  valueKey:
    | keyof PropertyProps
    | "info"
    | "building_size"
    | "brokers"
    | "rent_price"
    | "sale_price"
    | "updated_info"
    | "activity_type"
    | "activity_content"
    | "activity_updated"
    | "contract_date"
    | "listing_info"
    | "parking_cost"
    | "property_staleness";
  isEditMode?: boolean;
  updateItem?: any;
  variant?: "secondary" | "primary";
}

const StyledPropertyValue: FC<StyledPropertyValueProps> = ({
  property,
  valueKey,
  activity,
  isEditMode,
  updateItem,
  variant = "secondary",
}) => {
  const { state, dispatch } = useContext(GlobalContext);
  const { showTooltip, hideTooltip, meta } = state;
  const [getMarkets] = useApiCall(getMarketsAPI);

  if (
    property?.pk &&
    !property?.id?.toString()?.toLowerCase().includes("building")
  )
    return <></>;

  return (
    <>
      {(() => {
        let valueKeys: string[] = [];
        let labelKeys: string[] = [];
        const isLand = property?.property_type === "land";
        switch (valueKey) {
          case "info":
            return (
              <EditableSingleField
                kind="property"
                valueKeys={["title"]}
                isEditMode={isEditMode}
                initValues={property}
                type="text"
                pk={property?.pk || property?.id}
                updateItem={updateItem}
              >
                <div className="flex space-x-2">
                  <LazyLoadImage
                    className="w-10 h-10 rounded"
                    src={convertImageUrl(
                      convertExtension(property?.images?.[0] || "")
                    )}
                    onError={lazyLoadImageOnError}
                  />
                  <div className="flex flex-col truncate">
                    {property?.title && (
                      <div
                        className="font-bold text-jll-color-text-base-default text-ellipsis overflow-hidden"
                        id={`building-title-${
                          activity?.pk || activity?.id || "-"
                        }-${property?.pk || property?.id}`}
                        onMouseOver={() =>
                          showTooltip(
                            `building-title-${
                              activity?.pk || activity?.id || "-"
                            }-${property?.pk || property?.id}`
                          )
                        }
                        onMouseLeave={() => hideTooltip()}
                        data-tooltip-content={property?.title}
                      >
                        {property?.title}
                      </div>
                    )}
                    <div>ID {property?.pk}</div>
                  </div>
                </div>
              </EditableSingleField>
            );

          case "property_staleness":
            const diffDays: number | null = getDifferenceDaysFromNow(
              property?.user_saved || property?.update_timestamp
            );
            let type = "";
            if (diffDays !== null && diffDays < 45) {
              type = "green";
            } else if (diffDays !== null && diffDays >= 45 && diffDays < 90) {
              type = "yellow";
            } else if (diffDays === null || diffDays >= 90) {
              type = "red";
            }
            return (
              <div className="flex space-x-2">
                <div className="relative h-full">
                  <LazyLoadImage
                    className="w-10 h-10 rounded"
                    src={convertImageUrl(
                      convertExtension(property?.images?.[0] || "")
                    )}
                    onError={lazyLoadImageOnError}
                  />
                  <div className="rounded-full p-0.5 bg-white absolute right-[-5px] top-[-5px]">
                    <div
                      className={cn("w-3 h-3 rounded-full", {
                        "bg-jll-color-surface-danger-default": type === "red",
                        "bg-jll-color-surface-warning-default":
                          type === "yellow",
                        "bg-jll-color-surface-success-default":
                          type === "green",
                      })}
                    />
                  </div>
                </div>
                <div className="flex flex-col truncate">
                  {property?.title && (
                    <div
                      className="font-bold text-jll-color-text-base-default text-ellipsis overflow-hidden"
                      id={`building-title-${
                        activity?.pk || activity?.id || "-"
                      }-${property?.pk || property?.id}`}
                      onMouseOver={() =>
                        showTooltip(
                          `building-title-${
                            activity?.pk || activity?.id || "-"
                          }-${property?.pk || property?.id}`
                        )
                      }
                      onMouseLeave={() => hideTooltip()}
                      data-tooltip-content={property?.title}
                    >
                      {property?.title}
                    </div>
                  )}
                  <div>ID {property?.pk}</div>
                </div>
              </div>
            );

          case "listing_info":
            return (
              <div className="flex space-x-2">
                <LazyLoadImage
                  className="w-10 h-10 rounded"
                  src={convertImageUrl(
                    convertExtension(property?.images?.[0] || "")
                  )}
                  onError={lazyLoadImageOnError}
                />
                <div className="flex flex-col truncate">
                  {property?.title && (
                    <div
                      className="font-bold text-jll-color-text-base-default text-ellipsis overflow-hidden"
                      id={`building-title-${
                        activity?.pk || activity?.id || "-"
                      }-${property?.pk || property?.id}`}
                      onMouseOver={() =>
                        showTooltip(
                          `building-title-${
                            activity?.pk || activity?.id || "-"
                          }-${property?.pk || property?.id}`
                        )
                      }
                      onMouseLeave={() => hideTooltip()}
                      data-tooltip-content={property?.title}
                    >
                      {property?.title}
                    </div>
                  )}
                  <p className="text-jll-color-text-base-subdued text-sm whitespace-normal">
                    {[
                      property?.address,
                      property?.city,
                      property?.state,
                      property?.post_code,
                    ].join(", ")}
                  </p>
                </div>
              </div>
            );
          case "max_surface_area":
            return (
              <EditableSingleField
                kind="property"
                valueKeys={["max_surface_area"]}
                isEditMode={isEditMode}
                initValues={property}
                type="number"
                pk={property?.pk || property?.id}
                updateItem={updateItem}
              >
                {!!property?.max_surface_area ? (
                  <>
                    {getDecimalFormating(property?.max_surface_area)}
                    <span className="text-xs text-jll-color-coldGray-6 ml-1">
                      {property?.surface_area_unit}
                    </span>
                  </>
                ) : (
                  <>-</>
                )}
              </EditableSingleField>
            );

          case "address":
            valueKeys = ["address", "city", "state", "post_code"];
            labelKeys = ["Address", "City", "State", "Zip Code"];
            return (
              <EditableMultiField
                kind="property"
                valueKeys={valueKeys}
                isEditMode={isEditMode}
                initValues={property}
                pk={property?.pk || property?.id}
                updateItem={updateItem}
                labelKeys={labelKeys}
                options={{ state: getMetaOptions(meta?.building?.state) || [] }}
              >
                <Link
                  to={`/property/${property?.pk}/${URLS.PROPERTY.FORM}`}
                  className="text-jll-color-icon-info"
                  onClick={noPropagation}
                >
                  {property?.address}
                  <br />
                  {[property?.city, property?.state, property?.post_code].join(
                    ", "
                  )}
                </Link>
              </EditableMultiField>
            );

          case "property_type":
            if (!property?.property_type) {
              return <>-</>;
            }
            return (
              <Chip size="small" variant={variant} className="m-1">
                {property?.property_type}
              </Chip>
            );

          case "property_sub_types":
            return (
              <EditableMenuField
                kind="property"
                valueKeys={["property_sub_types"]}
                isEditMode={isEditMode}
                initValues={property}
                pk={property?.pk || property?.id}
                updateItem={updateItem}
                options={[
                  ...getMetaOptions(meta?.building?.property_sub_types),
                ]}
              >
                {!property?.property_sub_types?.length ? (
                  <>-</>
                ) : (
                  <div className="flex flex-wrap">
                    {property?.property_sub_types?.map((type, index) => (
                      <Chip
                        size="small"
                        key={index}
                        variant={variant}
                        className="m-1"
                      >
                        {type}
                      </Chip>
                    ))}
                  </div>
                )}
              </EditableMenuField>
            );

          case "tenure_types":
            return (
              <EditableMenuField
                kind="property"
                valueKeys={["tenure_types"]}
                isEditMode={isEditMode}
                initValues={property}
                pk={property?.pk || property?.id}
                updateItem={updateItem}
                options={[...getMetaOptions(meta?.building?.tenure_types)]}
              >
                {!property?.tenure_types?.length ? (
                  <>-</>
                ) : (
                  property?.tenure_types?.map((type, index) => (
                    <Chip
                      size="small"
                      key={index}
                      variant="secondary"
                      className="m-1"
                    >
                      {type?.toLowerCase() === "rent" ? "Lease" : type}
                    </Chip>
                  ))
                )}
              </EditableMenuField>
            );

          case "update_timestamp":
            return (
              <div>
                <p>
                  {property?.update_timestamp &&
                    getFormatedDateTime(property?.update_timestamp, {
                      dateStyle: "long",
                    })}
                </p>
                {(!!property?.updated_user_first_name ||
                  !!property?.updated_user_last_name) && (
                  <p className="text-sm text-jll-color-text-base-default">
                    <span className="text-jll-color-coldGray-7">
                      updated by:{" "}
                    </span>
                    {joinTwoStr(
                      property?.updated_user_first_name,
                      property?.updated_user_last_name,
                      " "
                    )}
                  </p>
                )}
              </div>
            );

          case "create_timestamp":
            if (!property?.create_timestamp) {
              return <>-</>;
            }
            return <>{getFormatedDate(property?.create_timestamp)}</>;

          case "rent_price":
            if (!property?.min_rent && !property?.max_rent) {
              return <>-</>;
            }
            if (property?.min_rent && !property?.max_rent) {
              return <>${getDecimalFormating(property?.min_rent)} </>;
            }
            if (!property?.min_rent && property?.max_rent) {
              return <>${getDecimalFormating(property?.max_rent)} </>;
            }
            return (
              <>
                {`$${getDecimalFormating(property?.min_rent)} -
                $${getDecimalFormating(property?.max_rent)}`}
              </>
            );

          case "sale_price":
            return (
              <EditableMultiField
                kind="property"
                valueKeys={["hide_sales_price", "max_sale"]}
                isEditMode={isEditMode}
                initValues={property}
                pk={property?.pk || property?.id}
                updateItem={updateItem}
                labelKeys={["Hide Sales Price", "Sale Price"]}
              >
                {property?.max_sale ? (
                  <>${getDecimalFormating(property?.max_sale)} </>
                ) : (
                  <>-</>
                )}
              </EditableMultiField>
            );

          case "building_size":
            const label = isLand ? "Lot Size" : "Building Size";
            valueKeys = [
              "max_surface_area",
              "min_surface_area",
              "surface_area_unit",
            ];
            labelKeys = [label, label, "Unit"];
            return (
              <EditableMultiField
                kind="property"
                valueKeys={valueKeys}
                isEditMode={isEditMode}
                initValues={property}
                pk={property?.pk || property?.id}
                updateItem={updateItem}
                labelKeys={labelKeys}
                isLand={isLand}
                type="number"
              >
                {(() => {
                  if (
                    !property?.min_surface_area &&
                    !property?.max_surface_area
                  ) {
                    return <>-</>;
                  }
                  if (
                    property?.min_surface_area &&
                    !property?.max_surface_area
                  ) {
                    return (
                      <>
                        {getDecimalFormating(property?.min_surface_area)}
                        <span className="text-xs text-jll-color-coldGray-6 ml-1">
                          {property?.surface_area_unit}
                        </span>
                      </>
                    );
                  }
                  if (
                    !property?.min_surface_area &&
                    property?.max_surface_area
                  ) {
                    return (
                      <>
                        {getDecimalFormating(property?.max_surface_area)}
                        <span className="text-xs text-jll-color-coldGray-6 ml-1">
                          {property?.surface_area_unit}
                        </span>
                      </>
                    );
                  }
                  if (
                    property?.min_surface_area === property?.max_surface_area
                  ) {
                    return (
                      <>
                        {getDecimalFormating(property?.max_surface_area)}
                        <span className="text-xs text-jll-color-coldGray-6 ml-1">
                          {property?.surface_area_unit}
                        </span>
                      </>
                    );
                  }
                  return (
                    <>
                      {getDecimalFormating(property?.min_surface_area)}-
                      {getDecimalFormating(property?.max_surface_area)}
                      <span className="text-xs text-jll-color-coldGray-6 ml-1">
                        {property?.surface_area_unit}
                      </span>
                    </>
                  );
                })()}
              </EditableMultiField>
            );

          case "brokers":
            if (!property?.broker?.length) {
              return <>-</>;
            }
            return (
              <Link
                onClick={noPropagation}
                className="text-jll-color-icon-info mb-1 flex flex-row items-center"
                to={`/property/${property?.pk}/${URLS.PROPERTY.FORM}?tab=${PROPERTY_TABS.CONTACTS}`}
              >
                <AvatarGroup
                  uniqueId={property?.pk || property?.id}
                  brokers={property?.broker}
                  max={3}
                />
              </Link>
            );

          case "suites":
            if (!property?.suite?.length) {
              return <>-</>;
            }
            return (
              <Link
                onClick={noPropagation}
                className="text-jll-color-icon-info mb-1 flex flex-row items-center"
                to={`/property/${property?.pk}/availabilities`}
                id={`tooltip-availability-${property?.pk}`}
                onMouseOver={() =>
                  !!property?.suite?.length &&
                  showTooltip(`tooltip-availability-${property?.pk}`)
                }
                onMouseLeave={() => hideTooltip()}
                data-tooltip-html={renderToString(
                  <ListTooltip
                    list={property?.suite_name?.map((item) => `${item || ""}`)}
                  />
                )}
              >
                {property?.suite.length} Availabilit
                {property?.suite.length > 1 ? "ies" : "y"}
              </Link>
            );

          case "space_available":
            if (
              !property?.min_space_available &&
              !property?.max_space_available
            ) {
              return <>-</>;
            } else if (
              property?.min_space_available &&
              property?.max_space_available &&
              property?.max_space_available != property?.min_space_available
            ) {
              return (
                <>
                  {getDecimalFormating(property?.min_space_available)} -{" "}
                  {getDecimalFormating(property?.max_space_available)}{" "}
                  <span className="text-xs text-jll-color-coldGray-6">
                    feet
                  </span>
                </>
              );
            } else if (property?.min_space_available) {
              return (
                <>
                  {getDecimalFormating(property?.min_space_available)}{" "}
                  <span className="text-xs text-jll-color-coldGray-6">
                    feet
                  </span>
                </>
              );
            }
            return (
              <>
                {getDecimalFormating(property?.max_space_available)}{" "}
                <span className="text-xs text-jll-color-coldGray-6">feet</span>
              </>
            );

          case "building_class":
          case "wls":
            return (
              <EditableSingleField
                kind="property"
                valueKeys={[valueKey]}
                isEditMode={isEditMode}
                initValues={property}
                type="select"
                pk={property?.pk || property?.id}
                updateItem={updateItem}
                options={[...getMetaOptions(meta?.building?.[valueKey])]}
              >
                {!property?.[valueKey] ? (
                  <>-</>
                ) : (
                  <span>
                    {
                      meta?.building?.[valueKey]?.[
                        property?.[valueKey] as string
                      ]?.value
                    }
                  </span>
                )}
              </EditableSingleField>
            );

          case "market":
            const loadMarketOptions = async (keyword: string) => {
              return getMarkets({ keyword: keyword }).then((res: any) => {
                return res.docs.map((market: any) => {
                  return {
                    value: market?.pk,
                    label: market?.name,
                  };
                });
              });
            };
            return (
              <EditableSingleField
                kind="property"
                valueKeys={[valueKey]}
                isEditMode={isEditMode}
                initValues={property}
                type="select"
                pk={property?.pk || property?.id}
                updateItem={updateItem}
                loadOptions={loadMarketOptions}
              >
                {!property?.market_name ? (
                  <>-</>
                ) : (
                  <span>{property?.market_name}</span>
                )}
              </EditableSingleField>
            );

          case "publish_status":
            if (!property?.publish_status) {
              return <>-</>;
            }
            return (
              <Chip
                size="small"
                variant={
                  // @ts-ignore
                  STATUS_CONDITIONS?.[property?.publish_status]?.variant as
                    | "secondary"
                    | "success"
                    | "error"
                }
              >
                {/* @ts-ignore */}
                {STATUS_CONDITIONS?.[property?.publish_status]?.label}
              </Chip>
            );

          case "updated_info":
            return (
              <div>
                <p>
                  {property?.update_timestamp &&
                    getFormatedDateTime(property?.update_timestamp, {
                      dateStyle: "long",
                    })}
                </p>
                {(!!property?.updated_user_first_name ||
                  !!property?.updated_user_last_name) && (
                  <p className="text-sm text-jll-color-text-base-default">
                    <span className="text-jll-color-coldGray-7">
                      updated by:{" "}
                    </span>
                    {joinTwoStr(
                      property?.updated_user_first_name,
                      property?.updated_user_last_name,
                      " "
                    )}
                  </p>
                )}
              </div>
            );

          case "activity_type":
            if (!activity?.type) {
              return <>-</>;
            }
            return <span className="capitalize">{activity?.type_name}</span>;

          case "activity_content":
            return <ActivityContent activity={activity} />;

          case "activity_updated":
            return (
              <div>
                <p>
                  {activity?.update_timestamp &&
                    getFormatedDateTime(activity?.update_timestamp, {
                      dateStyle: "long",
                    })}
                </p>
                {(!!activity?.first_name || !!activity?.last_name) && (
                  <p className="text-sm text-jll-color-text-base-default">
                    <span className="text-jll-color-coldGray-7">
                      updated by:{" "}
                    </span>
                    {joinTwoStr(activity?.first_name, activity?.last_name, " ")}
                  </p>
                )}
              </div>
            );

          case "hide_availabilities_price":
            return (
              <EditableSingleField
                kind="property"
                valueKeys={[valueKey]}
                isEditMode={isEditMode}
                initValues={property}
                type="check"
                label="Hide Availabilities Price"
                pk={property?.pk || property?.id}
                updateItem={updateItem}
                options={[...getMetaOptions(meta?.building?.[valueKey])]}
              >
                {!property?.[valueKey] ? <>-</> : <FiCheckCircle />}
              </EditableSingleField>
            );

          case "amenity_name":
            return (
              <div
                className="flex flex-row items-center justify-between space-x-2"
                id={`tooltip-amenity-${property?.pk}`}
                onMouseOver={() =>
                  (!!property?.["amenity_name"]?.length ||
                    !!property?.["custom_amenity_name"]?.length) &&
                  showTooltip(`tooltip-amenity-${property?.pk}`)
                }
                onMouseLeave={() => hideTooltip()}
                data-tooltip-html={renderToString(
                  <ListTooltip
                    list={
                      [
                        ...(property?.["amenity_name"] || []),
                        ...(property?.["custom_amenity_name"] || []),
                      ]?.map((item) => item) || []
                    }
                  />
                )}
              >
                {!!property?.["amenity_name"]?.length ||
                !!property?.["custom_amenity_name"]?.length ? (
                  <div
                    onClick={noPropagation}
                    className="text-jll-color-icon-info mb-1 flex flex-row items-center"
                  >
                    {Number(property?.["amenity_name"]?.length || 0) +
                      Number(
                        property?.["custom_amenity_name"]?.length || 0
                      )}{" "}
                    Amenit
                    {Number(
                      Number(property?.["amenity_name"]?.length || 0) +
                        Number(property?.["custom_amenity_name"]?.length || 0)
                    ) > 1
                      ? "ies"
                      : "y"}
                  </div>
                ) : (
                  <>-</>
                )}
                {isEditMode ? (
                  <FiEdit3
                    className="w-4 h-4 text-jll-color-coldGray-4 cursor-pointer"
                    onClick={() => {
                      dispatch({
                        type: SET_INLINE_EDIT_AMENITY_MODAL,
                        payload: {
                          open: true,
                          content: {
                            pk: property?.pk,
                            updateItem: updateItem,
                          },
                        },
                      });
                    }}
                  />
                ) : (
                  <></>
                )}
              </div>
            );

          case "images":
          case "floorplans":
          case "brochures":
            return (
              <EditableFileField
                kind="property"
                valueKeys={[valueKey]}
                isEditMode={isEditMode}
                initValues={property}
                pk={property?.pk || property?.id}
                updateItem={updateItem}
              >
                <ImageGroup images={property?.[valueKey] as string[]} max={3} />
              </EditableFileField>
            );

          case "highlights":
            return (
              <div className="flex flex-row items-center justify-between space-x-2">
                {!!property?.[valueKey]?.length ? (
                  <div
                    onClick={noPropagation}
                    className="text-jll-color-icon-info mb-1 flex flex-row items-center"
                    id={`tooltip-highlight-${property?.pk}`}
                    onMouseOver={() =>
                      !!property?.highlights?.length &&
                      showTooltip(`tooltip-highlight-${property?.pk}`)
                    }
                    onMouseLeave={() => hideTooltip()}
                    data-tooltip-html={renderToString(
                      <ListTooltip
                        list={property?.[valueKey]?.map((item) => item) || []}
                      />
                    )}
                  >
                    {property?.[valueKey]?.length} Highligt
                    {Number(property?.[valueKey]?.length) > 1 ? "s" : ""}
                  </div>
                ) : (
                  <>-</>
                )}
                {isEditMode ? (
                  <FiEdit3
                    className="w-4 h-4 text-jll-color-coldGray-4 cursor-pointer"
                    onClick={() => {
                      dispatch({
                        type: SET_INLINE_EDIT_HIGHLIGHT_MODAL,
                        payload: {
                          open: true,
                          content: {
                            values: property?.[valueKey],
                            kind: "property",
                            pk: property?.pk,
                            updateItem,
                          },
                        },
                      });
                    }}
                  />
                ) : (
                  <></>
                )}
              </div>
            );

          case "contract_date":
            valueKeys = ["contract_start", "contract_end"];
            labelKeys = ["Contract Start", "Contract End"];
            return (
              <EditableMultiField
                kind="property"
                valueKeys={valueKeys}
                isEditMode={isEditMode}
                initValues={property}
                pk={property?.pk || property?.id}
                updateItem={updateItem}
                labelKeys={labelKeys}
                options={{ state: getMetaOptions(meta?.building?.state) || [] }}
              >
                <>
                  {!!property?.contract_start
                    ? getFormatedDate(property?.contract_start)
                    : ""}
                  {!!property?.contract_start &&
                    !!property?.contract_end &&
                    " - "}
                  {!!property?.contract_end
                    ? getFormatedDate(property?.contract_end)
                    : ""}
                </>
              </EditableMultiField>
            );
          case "costar_id":
          case "dealio":
            return (
              <EditableSingleField
                kind="property"
                valueKeys={[valueKey]}
                isEditMode={isEditMode}
                initValues={property}
                type="number"
                pk={property?.pk || property?.id}
                updateItem={updateItem}
              >
                {property?.[valueKey] || "-"}
              </EditableSingleField>
            );

          case "parking_type":
            if (!property?.parking_type) return <>-</>;
            return PARKING_TYPES?.[property?.parking_type]?.value || "";

          case "parking_cost":
            if (
              !property?.parking_cost_reserved &&
              !property?.parking_cost_unreserved
            )
              return <>-</>;
            else if (
              property?.parking_cost_reserved &&
              !property?.parking_cost_unreserved
            )
              return (
                <>${getDecimalFormating(property?.parking_cost_reserved)}/-</>
              );
            else if (
              !property?.parking_cost_reserved &&
              property?.parking_cost_unreserved
            )
              return (
                <>-/${getDecimalFormating(property?.parking_cost_unreserved)}</>
              );

            return (
              <>
                ${getDecimalFormating(property?.parking_cost_reserved)}/$
                {getDecimalFormating(property?.parking_cost_unreserved)}
              </>
            );

          case "direct_available_space":
            if (!property?.direct_available_space) return <>-</>;
            return (
              <>
                {getDecimalFormating(property?.direct_available_space)}
                <span className="text-xs text-jll-color-coldGray-6 ml-1">
                  {property?.direct_available_space_unit
                    ? DIRECT_AVAILABLE_SPACE_UNIT?.[
                        property?.direct_available_space_unit
                      ]?.value || ""
                    : ""}
                </span>
              </>
            );

          case "direct_percentage_leased":
          case "percentage_subleased":
            if (!property?.[valueKey]) return <>-</>;
            return <>{getDecimalFormating(property?.[valueKey])} %</>;

          case "max_contiguous_space":
            if (!property?.max_contiguous_space) return <>-</>;
            return (
              <>
                {getDecimalFormating(property?.max_contiguous_space)}
                <span className="text-xs text-jll-color-coldGray-6 ml-1">
                  {property?.max_contiguous_space_unit
                    ? MAX_CONTIGUOUS_SPACE_UNIT?.[
                        property?.max_contiguous_space_unit
                      ]?.value || ""
                    : ""}
                </span>
              </>
            );

          default:
            if (property?.hasOwnProperty(valueKey)) {
              return <>{property[valueKey]}</>;
            }
            return <>-</>;
        }
      })()}
    </>
  );
};

export default StyledPropertyValue;
