import { StyledActivityLogValue, StyledActivityValue } from "ui-molecules";
import { capitalizeWords } from "utils";

interface IFieldItem {
  valueKey: string;
  value?: any;
  logFlag?: boolean;
}

interface IFieldList {
  data?: any;
  logFlag?: boolean;
}

const FieldItem: React.FC<IFieldItem> = ({ valueKey, value, logFlag }) => {
  let errorStr;
  if (value && !logFlag) {
    if (Array.isArray(value?.[valueKey])) {
      let index = -1;
      errorStr = value?.[valueKey]?.map((val: any, idx: number) => {
        if (!val) return;
        index++;
        return (
          <div key={idx} className="pb-0.5">
            {index !== 0 && ", "}
            {typeof val === "string" ? `${idx + 1} : ${val}` : ""}
          </div>
        );
      });
    } else {
      errorStr = value?.[valueKey] || '';
    }
  }
  return (
    <div className="py-2 flex flex-row justify-between items-center">
      <div className="text-jll-color-coldGray-5 font-semibold whitespace-nowrap mr-2">
        {`${capitalizeWords(valueKey, "_")}: `}
      </div>
      <div className="text-jll-color-text-base-subdued text-sm pt-0.5 flex flex-row">
        {logFlag ? (
          <StyledActivityLogValue activity={value} valueKey={valueKey} />
        ) : (
          errorStr || ""
        )}
      </div>
    </div>
  );
};

const FieldList: React.FC<IFieldList> = ({ data, logFlag = false }) => {
  return (
    <div className="divide-y divide-jll-color-stroke-base-default mb-8">
      {!!Object.keys(data)?.length &&
        Object.keys(data)?.map((key, idx1) => (
          <FieldItem key={idx1} valueKey={key} value={data} logFlag={logFlag} />
        ))}
    </div>
  );
};

export default FieldList;
