import cn from "classnames";
import { FiEdit3, FiX } from "react-icons/fi";
import { Button } from "ui-atoms";
import { Modal, StyledTaskValue } from "ui-molecules";
import { TASK_PREVIEW_TABLE_COLUMNS } from "constant";

interface ITaskPreviewModal {
  isOpen: boolean;
  setIsOpen?: any;
  selectedTask?: any;
  openEditModal?: any;
}

const TaskPreviewModal: React.FC<ITaskPreviewModal> = ({
  isOpen,
  setIsOpen,
  selectedTask,
  openEditModal
}) => {
  const handleOpen = () => {
    setIsOpen(false);
  };
  return (
    <Modal isOpen={isOpen} setIsOpen={handleOpen} size="medium">
      <Modal.Header>
        <div className="flex flex-row items-center justify-between pb-4">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            Task Preview
          </h3>
          <Button
            variant="neutral"
            className="!p-3"
            leadingIcon={FiX}
            leadingIconClass="!w-5 !h-5 font-semibold text-xl"
            onClick={() => setIsOpen(false)}
          />
        </div>
      </Modal.Header>
      <Modal.Body>
        <table className="w-full">
          {TASK_PREVIEW_TABLE_COLUMNS.map((column, index) => (
            <tr
              key={index}
              className={cn({
                "border-t border-jll-color-coldGray-3": index !== 0,
              })}
            >
              <td className="pr-5 border-r border-jll-color-coldGray-3 py-[14px] text-jll-color-text-base-subdued text-sm">
                {column?.label}
              </td>
              <td className="pl-5 py-[14px] text-jll-color-text-base-default">
                <StyledTaskValue
                  task={selectedTask}
                  valueKey={column.id}
                  isPreview
                />
              </td>
            </tr>
          ))}
        </table>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          className="w-full flex items-center justify-center"
          leadingIcon={FiEdit3}
          onClick={openEditModal}
        >
          Edit Task
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TaskPreviewModal;
