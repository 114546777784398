interface LayoutListingContainerProps {
  children: any;
}

function LayoutListingContainer({ children }: LayoutListingContainerProps) {
  return (
    <div className="absolute w-full top-[144px] grid grid-cols-12 overflow-hidden bottom-0">
      {children}
    </div>
  );
}

export default LayoutListingContainer;
