import { FiMail } from "react-icons/fi";
import { BsMicrosoftTeams } from "react-icons/bs";
import { SiMicrosoftsharepoint } from "react-icons/si";
import { Button } from "ui-atoms";
import { Modal } from "ui-molecules";

interface IContactSupportModal {
  isOpen: boolean;
  setIsOpen: any;
}

const ContactSupportModal: React.FC<IContactSupportModal> = ({
  isOpen,
  setIsOpen,
}) => {
  return (
    <Modal isOpen={isOpen} setIsOpen={() => setIsOpen(false)} size="medium">
      <Modal.Header>
        <h4 className="font-black text-jll-color-text-base-default text-2xl text-center">
          Contact Support
        </h4>
      </Modal.Header>
      <Modal.Body>
        <p className="text-center text-jll-color-text-base-default p-4 pt-6">
          We're here to help! Please include the Property Address, ID or URL
          that's causing the issue and any related files.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <div className="flex justify-center items-center w-full flex-row space-x-2">
          <a href="mailto:listingsupport@jll.com">
            <Button variant="secondary" leadingIcon={FiMail} size="small">
              Contact Listings Support
            </Button>
          </a>
          <a
            href="https://teams.microsoft.com/l/team/19%3aqOnmNvM1Tn5AkR-dIal59CxuMTWzYnc3PvlIzNhppjs1%40thread.tacv2/conversations?groupId=64fcdbd7-322e-4b65-ae57-d27067ba1a13&tenantId=bfef2b06-d256-4f8e-bd03-8d3687987063"
            target="_blank"
          >
            <Button variant="secondary" leadingIcon={BsMicrosoftTeams} size="small">
              Reach us on Teams
            </Button>
          </a>
          <a
            href="https://jll2.sharepoint.com/teams/JLLListingsPlatform/SitePages/Home.aspx"
            target="_blank"
          >
            <Button variant="secondary" leadingIcon={SiMicrosoftsharepoint} size="small">
              Listings Sharepoint Site
            </Button>
          </a>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ContactSupportModal;
