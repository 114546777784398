import type { FC } from "react";
import { FiXCircle } from "react-icons/fi";
import cn from "classnames";

interface IEmptyResultProps {
  className?: string;
  children: any;
}

const EmptyResult: FC<IEmptyResultProps> = ({ className, children }) => {
  return (
    <div
      className={cn(
        "flex items-center justify-center flex-col h-full w-full px-8 max-w-xl mx-auto",
        className
      )}
    >
      <FiXCircle className="h-10 w-10 mb-4 text-jll-color-coldGray-3" />
      {children}
    </div>
  );
};

export default EmptyResult;
