import type { PropertyMSProps } from "types";
import type { StyledMarketSphereValueProps, TdProps, ThProps } from "ui-molecules";
export const SEARCH_RESULT_LIMIT = 10;

interface ModalTableColumnProps {
  id: StyledMarketSphereValueProps["valueKey"];
  label: string;
  sort?: string;
  td?: TdProps;
  th?: ThProps;
}

export const MODAL_TABLE_COLUMNS: ModalTableColumnProps[] = [
  {
    id: "building",
    label: "Building",
    sort: "asc",
  },
  {
    id: "market",
    label: "Office",
  },
  {
    id: "propertyType",
    label: "Property type",
  },
];
