import { useApiCall } from "hooks";
import { Homepage } from "pages";
import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { getSessionAPI } from "services";
import { Pendo } from "ui-molecules";

export const RequiredAuth: React.FC = () => {
  const [getSession, , sessionInfo, error] = useApiCall(getSessionAPI);

  useEffect(() => {
    getSession();
  }, []);

  useEffect(() => {
    if (sessionInfo) {
      if (sessionInfo?.mock_user) {
        sessionStorage.setItem("mainSession", JSON.stringify(sessionInfo));
        sessionStorage.setItem(
          "session",
          JSON.stringify(sessionInfo?.mock_user)
        );
      } else {
        sessionStorage.setItem("session", JSON.stringify(sessionInfo));
        sessionStorage.removeItem("mainSession");
      }
    } else {
      sessionStorage.removeItem("session");
    }
  }, [sessionInfo]);

  if (!error && sessionInfo) {
    return (
      <>
        <Pendo sessionInfo={sessionInfo} />
        <Outlet />
      </>
    );
  }

  return <>{error && <Homepage />}</>;
};
