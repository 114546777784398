import cn from "classnames";
import Chip from "ui-atoms/Chip";
import Label from "ui-atoms/Label";

export interface IOption {
  label: string | number;
  value: string | number;
}

interface SelectProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  options: IOption[];
  label?: string;
  error?: any;
  optional?: boolean;
  className?: string;
}

const Select: React.FC<SelectProps> = ({
  label,
  error,
  optional,
  options,
  className,
  ...props
}) => {
  return (
    <div className={cn("relative mb-6", className)}>
      {(label || optional) && (
        <div className="flex flex-row items-center">
          {label ? <Label className="mr-2">{label}</Label> : <span></span>}
          {optional && (
            <Chip
              variant="secondary"
              size="small"
              className="py-1 text-jll-color-coldGray-7"
            >
              Optional
            </Chip>
          )}
        </div>
      )}
      <div className={cn({ "mt-2": !!label })}>
        <select
          className={cn(
            "block w-full rounded focus:border-jll-color-coldGray-7 focus:ring-jll-color-coldGray-7 sm:text-sm text-jll-color-text-base-default",
            {
              "border-jll-color-surface-accent-default": !!error,
              "border-jll-color-coldGray-4": !error,
            }
          )}
          {...props}
        >
          {options?.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
      {error && (
        <p className="absolute -bottom-5 text-xs text-jll-color-surface-accent-default">
          {error}
        </p>
      )}
    </div>
  );
};

export default Select;
