import { PropertyCoordinatorProps, AvailabilityCoordinatorProps } from "types";

/**
 * Get options array from coordinators of building or suite
 * @param value coordinators array from building/property or suite/availability
 * @returns array of options for select [{ value: 1, label: 'lorem ipsum' }]
 */
export const getOptionsFromCoordinators = (
  coordinators: PropertyCoordinatorProps[] | AvailabilityCoordinatorProps[]
) => {
  if (!coordinators?.length) {
    return [];
  }
  return coordinators?.map((x: any) => ({
    value: x?.id,
    label: x?.get_full_name || x?.email,
  }));
};

/**
 * Get phone number of user or broker
 * @param value +17138884098
 * @returns +1 (713) 888-4098
 */
export const formatPhoneNumber = (phoneNumber: string | undefined) => {
  if (!phoneNumber) return "";
  const cleanedPhoneNumber = phoneNumber.replace(/\D/g, "");
  // Check if the cleaned number starts with the country code
  if (cleanedPhoneNumber.startsWith("1")) {
    // Format as +1 (713) 888-4098
    return cleanedPhoneNumber.replace(
      /^1(\d{3})(\d{3})(\d{4})$/,
      "+1 ($1) $2-$3"
    );
  } else {
    // No recognized country code
    return phoneNumber;
  }
};

/**
 * Remove the white space - \n, \t..., keep the space
 * @param value "Hello\t   World!\nThis\t\tis a test.";
 * @returns "Hello   World!Thisis a test.";
 */
export const removeNewlines = (str: string | undefined) => {
  if (!str) return "";
  return str.replace(/[\r\n\t]+/g, "");
};
