import cn from "classnames";

const StalenessLine = ({ status_buckets, total_count, onClick }: any) => {
  return (
    <>
      {status_buckets?.map((bucket: any, idx: number) => (
        <div
          className={cn(
            "h-full text-sm text-white font-semibold text-right pr-1.5 cursor-pointer",
            {
              "rounded-l-full": idx === 0,
              "rounded-r-full": idx >= status_buckets?.length - 1,
              "bg-jll-color-surface-danger-default": bucket.color === "red",
              "bg-jll-color-surface-warning-default": bucket.color === "yellow",
              "bg-jll-color-surface-success-default": bucket.color === "green",
            }
          )}
          style={{ width: `${(bucket?.count / total_count) * 100}%` }}
          onClick={() => onClick && onClick(bucket.color)}
        >
          {Math.abs((bucket?.count / total_count) * 100).toFixed(0)}%
        </div>
      ))}
    </>
  );
};

export default StalenessLine;
