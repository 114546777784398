export const emailSuiteDetailJSON = [
  {
    id: "v1ozoZ-ii3",
    cells: [1, 1, 1, 1],
    columns: [
      {
        id: "PBcwQTl8i3",
        contents: [
          {
            id: "huWKxwipLn",
            type: "heading",
            values: {
              containerPadding: "10px",
              anchor: "",
              headingType: "h4",
              fontSize: "12px",
              textAlign: "left",
              lineHeight: "140%",
              linkStyle: {
                inherit: true,
                linkColor: "#0000ee",
                linkHoverColor: "#0000ee",
                linkUnderline: true,
                linkHoverUnderline: true,
              },
              hideDesktop: false,
              displayCondition: null,
              _meta: {
                htmlID: "u_content_heading_19",
                htmlClassNames: "u_content_heading",
              },
              selectable: true,
              draggable: true,
              duplicatable: true,
              deletable: true,
              hideable: true,
              text: "Floor",
            },
          },
        ],
        values: {
          backgroundColor: "",
          padding: "0px",
          border: {},
          borderRadius: "0px",
          _meta: { htmlID: "u_column_66", htmlClassNames: "u_column" },
        },
      },
      {
        id: "suite_floor",
        contents: [
          {
            id: "OcEAjLLfYl",
            type: "text",
            values: {
              containerPadding: "10px",
              anchor: "",
              fontWeight: 700,
              fontSize: "12px",
              textAlign: "right",
              lineHeight: "140%",
              linkStyle: {
                inherit: true,
                linkColor: "#0000ee",
                linkHoverColor: "#0000ee",
                linkUnderline: true,
                linkHoverUnderline: true,
              },
              hideDesktop: false,
              displayCondition: null,
              _meta: {
                htmlID: "u_content_text_10",
                htmlClassNames: "u_content_text",
              },
              selectable: true,
              draggable: true,
              duplicatable: true,
              deletable: true,
              hideable: true,
              text: "",
            },
          },
        ],
        values: {
          backgroundColor: "",
          padding: "0px",
          border: {},
          borderRadius: "0px",
          _meta: { htmlID: "u_column_68", htmlClassNames: "u_column" },
        },
      },
      {
        id: "vgtRRFltFD",
        contents: [
          {
            id: "ZxDtBpNNUo",
            type: "heading",
            values: {
              containerPadding: "10px",
              anchor: "",
              headingType: "h4",
              fontWeight: 400,
              fontSize: "12px",
              textAlign: "left",
              lineHeight: "140%",
              linkStyle: {
                inherit: true,
                linkColor: "#0000ee",
                linkHoverColor: "#0000ee",
                linkUnderline: true,
                linkHoverUnderline: true,
              },
              hideDesktop: false,
              displayCondition: null,
              _meta: {
                htmlID: "u_content_heading_20",
                htmlClassNames: "u_content_heading",
              },
              selectable: true,
              draggable: true,
              duplicatable: true,
              deletable: true,
              hideable: true,
              text: "Suite",
            },
          },
        ],
        values: {
          backgroundColor: "",
          padding: "0px",
          border: {},
          borderRadius: "0px",
          _meta: { htmlID: "u_column_69", htmlClassNames: "u_column" },
        },
      },
      {
        id: "suite_name",
        contents: [
          {
            id: "ug2P5UZzpb",
            type: "text",
            values: {
              containerPadding: "10px",
              anchor: "",
              fontWeight: 700,
              fontSize: "12px",
              textAlign: "right",
              lineHeight: "140%",
              linkStyle: {
                inherit: true,
                linkColor: "#0000ee",
                linkHoverColor: "#0000ee",
                linkUnderline: true,
                linkHoverUnderline: true,
              },
              hideDesktop: false,
              displayCondition: null,
              _meta: {
                htmlID: "u_content_text_11",
                htmlClassNames: "u_content_text",
              },
              selectable: true,
              draggable: true,
              duplicatable: true,
              deletable: true,
              hideable: true,
              text: "",
            },
          },
        ],
        values: {
          backgroundColor: "",
          padding: "0px",
          border: {},
          borderRadius: "0px",
          _meta: { htmlID: "u_column_70", htmlClassNames: "u_column" },
        },
      },
    ],
    values: {
      displayCondition: null,
      columns: false,
      backgroundColor: "",
      columnsBackgroundColor: "",
      backgroundImage: {
        url: "",
        fullWidth: true,
        repeat: "no-repeat",
        size: "custom",
        position: "center",
      },
      padding: "0px",
      anchor: "",
      hideDesktop: false,
      _meta: { htmlID: "u_row_30", htmlClassNames: "u_row" },
      selectable: true,
      draggable: true,
      duplicatable: true,
      deletable: true,
      hideable: true,
    },
  },
  {
    id: "YAJMyIlmhL",
    cells: [1],
    columns: [
      {
        id: "FJRihe7ISQ",
        contents: [
          {
            id: "62Y3NNmUv5",
            type: "divider",
            values: {
              width: "100%",
              border: {
                borderTopWidth: "1px",
                borderTopStyle: "solid",
                borderTopColor: "#BBBBBB",
              },
              textAlign: "center",
              containerPadding: "0px",
              anchor: "",
              hideDesktop: false,
              displayCondition: null,
              _meta: {
                htmlID: "u_content_divider_16",
                htmlClassNames: "u_content_divider",
              },
              selectable: true,
              draggable: true,
              duplicatable: true,
              deletable: true,
              hideable: true,
            },
          },
        ],
        values: {
          backgroundColor: "",
          padding: "0px",
          border: {},
          borderRadius: "0px",
          _meta: { htmlID: "u_column_80", htmlClassNames: "u_column" },
        },
      },
    ],
    values: {
      displayCondition: null,
      columns: false,
      backgroundColor: "",
      columnsBackgroundColor: "",
      backgroundImage: {
        url: "",
        fullWidth: true,
        repeat: "no-repeat",
        size: "custom",
        position: "center",
      },
      padding: "0px",
      anchor: "",
      hideDesktop: false,
      _meta: { htmlID: "u_row_35", htmlClassNames: "u_row" },
      selectable: true,
      draggable: true,
      duplicatable: true,
      deletable: true,
      hideable: true,
    },
  },
  {
    id: "iEwxwV6H6P",
    cells: [1, 1, 1, 1],
    columns: [
      {
        id: "UNXMFLzE8O",
        contents: [
          {
            id: "cUsaMIpPWc",
            type: "heading",
            values: {
              containerPadding: "10px",
              anchor: "",
              headingType: "h4",
              fontSize: "12px",
              textAlign: "left",
              lineHeight: "140%",
              linkStyle: {
                inherit: true,
                linkColor: "#0000ee",
                linkHoverColor: "#0000ee",
                linkUnderline: true,
                linkHoverUnderline: true,
              },
              hideDesktop: false,
              displayCondition: null,
              _meta: {
                htmlID: "u_content_heading_23",
                htmlClassNames: "u_content_heading",
              },
              selectable: true,
              draggable: true,
              duplicatable: true,
              deletable: true,
              hideable: true,
              text: "Size",
            },
          },
        ],
        values: {
          backgroundColor: "",
          padding: "0px",
          border: {},
          borderRadius: "0px",
          _meta: { htmlID: "u_column_76", htmlClassNames: "u_column" },
        },
      },
      {
        id: "suite_size",
        contents: [
          {
            id: "mjDxWZmyER",
            type: "text",
            values: {
              containerPadding: "10px",
              anchor: "",
              fontWeight: 700,
              fontSize: "12px",
              textAlign: "right",
              lineHeight: "140%",
              linkStyle: {
                inherit: true,
                linkColor: "#0000ee",
                linkHoverColor: "#0000ee",
                linkUnderline: true,
                linkHoverUnderline: true,
              },
              hideDesktop: false,
              displayCondition: null,
              _meta: {
                htmlID: "u_content_text_14",
                htmlClassNames: "u_content_text",
              },
              selectable: true,
              draggable: true,
              duplicatable: true,
              deletable: true,
              hideable: true,
              text: "",
            },
          },
        ],
        values: {
          backgroundColor: "",
          padding: "0px",
          border: {},
          borderRadius: "0px",
          _meta: { htmlID: "u_column_77", htmlClassNames: "u_column" },
        },
      },
      {
        id: "irKnGEEMWA",
        contents: [
          {
            id: "DwHREo54RU",
            type: "heading",
            values: {
              containerPadding: "10px",
              anchor: "",
              headingType: "h4",
              fontWeight: 400,
              fontSize: "12px",
              textAlign: "left",
              lineHeight: "140%",
              linkStyle: {
                inherit: true,
                linkColor: "#0000ee",
                linkHoverColor: "#0000ee",
                linkUnderline: true,
                linkHoverUnderline: true,
              },
              hideDesktop: false,
              displayCondition: null,
              _meta: {
                htmlID: "u_content_heading_24",
                htmlClassNames: "u_content_heading",
              },
              selectable: true,
              draggable: true,
              duplicatable: true,
              deletable: true,
              hideable: true,
              text: "Rate/SF",
            },
          },
        ],
        values: {
          backgroundColor: "",
          padding: "0px",
          border: {},
          borderRadius: "0px",
          _meta: { htmlID: "u_column_78", htmlClassNames: "u_column" },
        },
      },
      {
        id: "suite_rate",
        contents: [
          {
            id: "Yd6xusIRgS",
            type: "text",
            values: {
              containerPadding: "10px",
              anchor: "",
              fontWeight: 700,
              fontSize: "12px",
              textAlign: "right",
              lineHeight: "140%",
              linkStyle: {
                inherit: true,
                linkColor: "#0000ee",
                linkHoverColor: "#0000ee",
                linkUnderline: true,
                linkHoverUnderline: true,
              },
              hideDesktop: false,
              displayCondition: null,
              _meta: {
                htmlID: "u_content_text_15",
                htmlClassNames: "u_content_text",
              },
              selectable: true,
              draggable: true,
              duplicatable: true,
              deletable: true,
              hideable: true,
              text: "",
            },
          },
        ],
        values: {
          backgroundColor: "",
          padding: "0px",
          border: {},
          borderRadius: "0px",
          _meta: { htmlID: "u_column_79", htmlClassNames: "u_column" },
        },
      },
    ],
    values: {
      displayCondition: null,
      columns: false,
      backgroundColor: "",
      columnsBackgroundColor: "",
      backgroundImage: {
        url: "",
        fullWidth: true,
        repeat: "no-repeat",
        size: "custom",
        position: "center",
      },
      padding: "0px",
      anchor: "",
      hideDesktop: false,
      _meta: { htmlID: "u_row_34", htmlClassNames: "u_row" },
      selectable: true,
      draggable: true,
      duplicatable: true,
      deletable: true,
      hideable: true,
    },
  },
  {
    id: "eoHy7RIFsb",
    cells: [1],
    columns: [
      {
        id: "2x8iCZGim5",
        contents: [
          {
            id: "2eFdzOeOJZ",
            type: "divider",
            values: {
              width: "100%",
              border: {
                borderTopWidth: "1px",
                borderTopStyle: "solid",
                borderTopColor: "#BBBBBB",
              },
              textAlign: "center",
              containerPadding: "0px",
              anchor: "",
              hideDesktop: false,
              displayCondition: null,
              _meta: {
                htmlID: "u_content_divider_17",
                htmlClassNames: "u_content_divider",
              },
              selectable: true,
              draggable: true,
              duplicatable: true,
              deletable: true,
              hideable: true,
            },
          },
        ],
        values: {
          backgroundColor: "",
          padding: "0px",
          border: {},
          borderRadius: "0px",
          _meta: { htmlID: "u_column_81", htmlClassNames: "u_column" },
        },
      },
    ],
    values: {
      displayCondition: null,
      columns: false,
      backgroundColor: "",
      columnsBackgroundColor: "",
      backgroundImage: {
        url: "",
        fullWidth: true,
        repeat: "no-repeat",
        size: "custom",
        position: "center",
      },
      padding: "0px",
      anchor: "",
      hideDesktop: false,
      _meta: { htmlID: "u_row_36", htmlClassNames: "u_row" },
      selectable: true,
      draggable: true,
      duplicatable: true,
      deletable: true,
      hideable: true,
    },
  },
  {
    id: "kMbyiq3BEP",
    cells: [1, 1, 1, 1],
    columns: [
      {
        id: "F_U3LSdFaJ",
        contents: [
          {
            id: "QMhdGGnw03",
            type: "heading",
            values: {
              containerPadding: "10px",
              anchor: "",
              headingType: "h4",
              fontSize: "12px",
              textAlign: "left",
              lineHeight: "140%",
              linkStyle: {
                inherit: true,
                linkColor: "#0000ee",
                linkHoverColor: "#0000ee",
                linkUnderline: true,
                linkHoverUnderline: true,
              },
              hideDesktop: false,
              displayCondition: null,
              _meta: {
                htmlID: "u_content_heading_21",
                htmlClassNames: "u_content_heading",
              },
              selectable: true,
              draggable: true,
              duplicatable: true,
              deletable: true,
              hideable: true,
              text: "Availability Type",
            },
          },
        ],
        values: {
          backgroundColor: "",
          padding: "0px",
          border: {},
          borderRadius: "0px",
          _meta: { htmlID: "u_column_72", htmlClassNames: "u_column" },
        },
      },
      {
        id: "suite_type",
        contents: [
          {
            id: "5Np_eq_UtU",
            type: "text",
            values: {
              containerPadding: "10px",
              anchor: "",
              fontWeight: 700,
              fontSize: "12px",
              textAlign: "right",
              lineHeight: "140%",
              linkStyle: {
                inherit: true,
                linkColor: "#0000ee",
                linkHoverColor: "#0000ee",
                linkUnderline: true,
                linkHoverUnderline: true,
              },
              hideDesktop: false,
              displayCondition: null,
              _meta: {
                htmlID: "u_content_text_12",
                htmlClassNames: "u_content_text",
              },
              selectable: true,
              draggable: true,
              duplicatable: true,
              deletable: true,
              hideable: true,
              text: "",
            },
          },
        ],
        values: {
          backgroundColor: "",
          padding: "0px",
          border: {},
          borderRadius: "0px",
          _meta: { htmlID: "u_column_73", htmlClassNames: "u_column" },
        },
      },
      {
        id: "eEg8xOR6Q9",
        contents: [
          {
            id: "Vs0ohaFDYS",
            type: "heading",
            values: {
              containerPadding: "10px",
              anchor: "",
              headingType: "h4",
              fontWeight: 400,
              fontSize: "12px",
              textAlign: "left",
              lineHeight: "140%",
              linkStyle: {
                inherit: true,
                linkColor: "#0000ee",
                linkHoverColor: "#0000ee",
                linkUnderline: true,
                linkHoverUnderline: true,
              },
              hideDesktop: false,
              displayCondition: null,
              _meta: {
                htmlID: "u_content_heading_22",
                htmlClassNames: "u_content_heading",
              },
              selectable: true,
              draggable: true,
              duplicatable: true,
              deletable: true,
              hideable: true,
              text: "Date Available",
            },
          },
        ],
        values: {
          backgroundColor: "",
          padding: "0px",
          border: {},
          borderRadius: "0px",
          _meta: { htmlID: "u_column_74", htmlClassNames: "u_column" },
        },
      },
      {
        id: "9v_jWn9XNO",
        contents: [
          {
            id: "LxMW0LLw_v",
            type: "text",
            values: {
              containerPadding: "10px",
              anchor: "",
              fontWeight: 700,
              fontSize: "12px",
              textAlign: "right",
              lineHeight: "140%",
              linkStyle: {
                inherit: true,
                linkColor: "#0000ee",
                linkHoverColor: "#0000ee",
                linkUnderline: true,
                linkHoverUnderline: true,
              },
              hideDesktop: false,
              displayCondition: null,
              _meta: {
                htmlID: "u_content_text_13",
                htmlClassNames: "u_content_text",
              },
              selectable: true,
              draggable: true,
              duplicatable: true,
              deletable: true,
              hideable: true,
              text: "",
            },
          },
        ],
        values: {
          backgroundColor: "",
          padding: "0px",
          border: {},
          borderRadius: "0px",
          _meta: { htmlID: "u_column_75", htmlClassNames: "u_column" },
        },
      },
    ],
    values: {
      displayCondition: null,
      columns: false,
      backgroundColor: "",
      columnsBackgroundColor: "",
      backgroundImage: {
        url: "",
        fullWidth: true,
        repeat: "no-repeat",
        size: "custom",
        position: "center",
      },
      padding: "0px",
      anchor: "",
      hideDesktop: false,
      _meta: { htmlID: "u_row_33", htmlClassNames: "u_row" },
      selectable: true,
      draggable: true,
      duplicatable: true,
      deletable: true,
      hideable: true,
    },
  },

  {
    id: "eoHy7RIFsb",
    cells: [1],
    columns: [
      {
        id: "2x8iCZGim5",
        contents: [
          {
            id: "2eFdzOeOJZ",
            type: "divider",
            values: {
              width: "100%",
              border: {
                borderTopWidth: "1px",
                borderTopStyle: "solid",
                borderTopColor: "#BBBBBB",
              },
              textAlign: "center",
              containerPadding: "0px",
              anchor: "",
              hideDesktop: false,
              displayCondition: null,
              _meta: {
                htmlID: "u_content_divider_17",
                htmlClassNames: "u_content_divider",
              },
              selectable: true,
              draggable: true,
              duplicatable: true,
              deletable: true,
              hideable: true,
            },
          },
        ],
        values: {
          backgroundColor: "",
          padding: "0px",
          border: {},
          borderRadius: "0px",
          _meta: { htmlID: "u_column_81", htmlClassNames: "u_column" },
        },
      },
    ],
    values: {
      displayCondition: null,
      columns: false,
      backgroundColor: "",
      columnsBackgroundColor: "",
      backgroundImage: {
        url: "",
        fullWidth: true,
        repeat: "no-repeat",
        size: "custom",
        position: "center",
      },
      padding: "0px",
      anchor: "",
      hideDesktop: false,
      _meta: { htmlID: "u_row_36", htmlClassNames: "u_row" },
      selectable: true,
      draggable: true,
      duplicatable: true,
      deletable: true,
      hideable: true,
    },
  },
  {
    id: "kMbyiq3BEP",
    cells: [1, 1, 2],
    columns: [
      {
        id: "F_U3LSdFaJ",
        contents: [
          {
            id: "QMhdGGnw03",
            type: "heading",
            values: {
              containerPadding: "10px",
              anchor: "",
              headingType: "h4",
              fontSize: "12px",
              textAlign: "left",
              lineHeight: "140%",
              linkStyle: {
                inherit: true,
                linkColor: "#0000ee",
                linkHoverColor: "#0000ee",
                linkUnderline: true,
                linkHoverUnderline: true,
              },
              hideDesktop: false,
              displayCondition: null,
              _meta: {
                htmlID: "u_content_heading_21",
                htmlClassNames: "u_content_heading",
              },
              selectable: true,
              draggable: true,
              duplicatable: true,
              deletable: true,
              hideable: true,
              text: "Rent Type",
            },
          },
        ],
        values: {
          backgroundColor: "",
          padding: "0px",
          border: {},
          borderRadius: "0px",
          _meta: { htmlID: "u_column_72", htmlClassNames: "u_column" },
        },
      },
      {
        id: "rent_type",
        contents: [
          {
            id: "5Np_eq_UtU",
            type: "text",
            values: {
              containerPadding: "10px",
              anchor: "",
              fontWeight: 700,
              fontSize: "12px",
              textAlign: "right",
              lineHeight: "140%",
              linkStyle: {
                inherit: true,
                linkColor: "#0000ee",
                linkHoverColor: "#0000ee",
                linkUnderline: true,
                linkHoverUnderline: true,
              },
              hideDesktop: false,
              displayCondition: null,
              _meta: {
                htmlID: "u_content_text_12",
                htmlClassNames: "u_content_text",
              },
              selectable: true,
              draggable: true,
              duplicatable: true,
              deletable: true,
              hideable: true,
              text: "",
            },
          },
        ],
        values: {
          backgroundColor: "",
          padding: "0px",
          border: {},
          borderRadius: "0px",
          _meta: { htmlID: "u_column_73", htmlClassNames: "u_column" },
        },
      },
      {
        id: "-fldKSE7Zu",
        contents: [
          {
            id: "97t1ij5q1c",
            type: "html",
            values: {
              html: "",
              displayCondition: null,
              containerPadding: "10px",
              anchor: "",
              _meta: {
                htmlID: "u_content_html_4",
                htmlClassNames: "u_content_html",
              },
              selectable: true,
              draggable: true,
              duplicatable: true,
              deletable: true,
              hideable: true,
            },
          },
        ],
        values: {
          backgroundColor: "",
          padding: "0px",
          border: {},
          borderRadius: "0px",
          _meta: { htmlID: "u_column_74", htmlClassNames: "u_column" },
        },
      },
    ],
    values: {
      displayCondition: null,
      columns: false,
      backgroundColor: "",
      columnsBackgroundColor: "",
      backgroundImage: {
        url: "",
        fullWidth: true,
        repeat: "no-repeat",
        size: "custom",
        position: "center",
      },
      padding: "0px",
      anchor: "",
      hideDesktop: false,
      _meta: { htmlID: "u_row_33", htmlClassNames: "u_row" },
      selectable: true,
      draggable: true,
      duplicatable: true,
      deletable: true,
      hideable: true,
    },
  },
];
