import { Document, Font } from "@react-pdf/renderer";
import SourceSansProRegular from "assets/fonts/sanspro/SourceSansPro-Regular.ttf";
import SourceSansProBold from "assets/fonts/sanspro/SourceSansPro-Bold.ttf";
import MontserratBold from "assets/fonts/montserrat/Montserrat-ExtraBold.ttf";
import { convertExtension, sliceIntoChunks } from "utils";
import PageCover from "./PageCover";
import { convertImageUrl } from "services";
import BgImg from "assets/homepage_bg.jpg";

Font.register({
  family: "Source Sans Pro",
  fonts: [
    {
      src: SourceSansProRegular,
    },
    {
      src: SourceSansProBold,
      fontWeight: "bold",
    },
  ],
});

Font.register({
  family: "Montserrat",
  fonts: [
    {
      src: MontserratBold,
      fontWeight: 800,
    },
  ],
});

interface IDeliverablePDF {
  teamListingLink: any;
  teamListing: any;
}

const DeliverablePDF: React.FC<IDeliverablePDF> = ({
  teamListingLink,
  teamListing,
}) => {
  return (
    <Document>
      <PageCover
        bgImage={
          !!teamListingLink?.images?.length
            ? convertImageUrl(convertExtension(teamListingLink?.images[0]))
            : BgImg
        }
        teamListingLink={teamListingLink}
      />
    </Document>
  );
};

export default DeliverablePDF;
