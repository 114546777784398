import { useState, useEffect, useRef, useContext } from "react";
import cn from "classnames";
import { RxDragHandleDots2 } from "react-icons/rx";
import { Button, EmptyResult, SelectAutoComplete } from "ui-atoms";
import { FiChevronLeft, FiChevronRight, FiPlusCircle } from "react-icons/fi";
import {
  Table,
  StyledBrokerValue,
  BrokerDetailModal,
  RemoveDescriptionItem,
  ContactModal,
  AccordionItem,
} from "ui-molecules";
import { LayoutEditProperty as Layout } from "ui-organisms";
import { BrokerProps, BuildingBrokerProps, UserProps } from "types";
import {
  BROKERS_TABLE_COLUMNS,
  META_TAGS,
  OLM_PROPERTY_FIELDS,
  PROPERTY_TABS,
  SET_CONFIRM_MODAL,
  SET_LOADING_MODAL,
  SET_PROPERTY,
  STATUS_ACTIVE,
  URLS,
} from "constant";
import {
  deletePropertyBrokerAPI,
  getUsersAPI,
  patchPropertyAPI,
} from "services";
import { Toast } from "ui-atoms";
import { useApiCall } from "hooks";
import { GlobalContext } from "context";
import { Helmet } from "react-helmet-async";
import { Link, useParams } from "react-router-dom";
import { renderToString } from "react-dom/server";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { getOptionsFromCoordinators, isCommonItem, reorder } from "utils";

interface IPropertyContacts {
  onDiffFields?: any;
}

const PropertyContacts: React.FC<IPropertyContacts> = ({ onDiffFields }) => {
  const [deletePropertyBroker] = useApiCall(deletePropertyBrokerAPI);
  const [patchProperty] = useApiCall(patchPropertyAPI);
  const [getUsers] = useApiCall(getUsersAPI);
  const containerEl = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [contacts, setContacts] = useState<BuildingBrokerProps[]>();
  const [leadContact, setLeadContact] = useState<BuildingBrokerProps[]>();
  const [additionalContacts, setAdditionalContacts] =
    useState<BuildingBrokerProps[]>();
  const [page, setPage] = useState(1);
  const { state, dispatch } = useContext(GlobalContext);
  const { property, meta } = state;
  const { propertyId } = useParams();
  const [isBrokerModalOpen, setIsBrokerModalOpen] = useState(false);
  const [selectedBroker, setSelectedBroker] = useState<BuildingBrokerProps>();
  const [selectedCoordinator, setSelectedCoordinator] = useState<any>([]);

  useEffect(() => {
    if (property && property?.id?.toString() === propertyId?.toString()) {
      setContacts(property?.brokers || []);
      setSelectedCoordinator(
        getOptionsFromCoordinators(property?.coordinators || [])
      );
    }
  }, [property, propertyId]);

  const onScroll = () => {
    const container = containerEl?.current;
    if (container) {
      const scrollTop = container.scrollTop;
      const scrollHeight = container.scrollHeight;
      const clientHeight = container.clientHeight;
      if (scrollTop + clientHeight >= scrollHeight) {
        setPage(page + 1);
      }
    }
  };

  useEffect(() => {
    onDiffFields({});
  }, []);

  useEffect(() => {
    const lead =
      contacts?.filter(
        (item) => item?.building_broker_role === "Lead Broker"
      ) || [];
    const additionals =
      contacts?.filter(
        (item) => item?.building_broker_role !== "Lead Broker"
      ) || [];

    setLeadContact(lead);
    setAdditionalContacts(additionals);

    const container = containerEl?.current;
    container?.addEventListener("scroll", onScroll);
    return () => container?.removeEventListener("scroll", onScroll);
  }, [contacts]);

  const onClickContact = (broker: BuildingBrokerProps) => {
    const updatedContacts = [...(contacts || [])];
    if (!updatedContacts?.find((x) => x.id === broker.pk)) {
      updatedContacts?.push(broker);
      dispatch({
        type: SET_PROPERTY,
        payload: {
          ...property,
          brokers: updatedContacts,
        },
      });
    }
  };

  const onClickRemove = (e: any, broker: BrokerProps) => {
    e.stopPropagation();
    const action = () => {
      dispatch({
        type: SET_LOADING_MODAL,
        payload: {
          open: true,
          label: "Removing the Broker",
        },
      });
      const updatedBrokers = contacts?.filter(
        (x) => x.building_broker_id !== broker?.building_broker_id
      );
      deletePropertyBroker(broker?.building_broker_id)
        .then(() => {
          Toast.success("Broker removed.");
          setContacts(updatedBrokers);
          dispatch({
            type: SET_PROPERTY,
            payload: {
              ...property,
              brokers: updatedBrokers || [],
            },
          });
          patchProperty({
            id: property?.id,
            payload: { publish_status: STATUS_ACTIVE },
          });
        })
        .finally(() => {
          setTimeout(() => {
            dispatch({
              type: SET_LOADING_MODAL,
              payload: {
                open: false,
                label: null,
              },
            });
          }, 100);
        });
    };
    dispatch({
      type: SET_CONFIRM_MODAL,
      payload: {
        open: true,
        content: {
          description: renderToString(
            <RemoveDescriptionItem target={`${broker?.name}`} />
          ),
          action,
        },
      },
    });
  };

  const onClickDetail = (broker: BuildingBrokerProps) => () => {
    setSelectedBroker(broker);
    setIsBrokerModalOpen(true);
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    const oldOrder = [...(additionalContacts || [])];
    const newOrder = reorder(
      additionalContacts,
      result.source.index,
      result.destination.index
    );
    setAdditionalContacts(newOrder);
    const ids =
      [...(leadContact || []), ...newOrder]?.map((contact) => contact?.id) ||
      [];
    let payload: any = { broker: ids };
    if (
      payload &&
      property?.publish_status === STATUS_ACTIVE &&
      isCommonItem(Object.keys(payload), OLM_PROPERTY_FIELDS)
    ) {
      payload["publish_status"] = STATUS_ACTIVE;
    }
    patchProperty({
      id: property?.id,
      payload,
    })
      .then(() => {})
      .catch((err: any) => {
        setAdditionalContacts(oldOrder);
      });
  };

  const updateCoordinators = (values: any) => {
    const oldValues = [...(selectedCoordinator || [])];
    const ids =
      [...(values || [])]?.map((coordinator) => coordinator?.value) || [];
    setSelectedCoordinator(values);
    let payload: any = { coordinator: ids };
    if (
      payload &&
      property?.publish_status === STATUS_ACTIVE &&
      isCommonItem(Object.keys(payload), OLM_PROPERTY_FIELDS)
    ) {
      payload["publish_status"] = STATUS_ACTIVE;
    }
    patchProperty({
      id: property?.id,
      payload,
    })
      .then((res: any) => {
        dispatch({
          type: SET_PROPERTY,
          payload: {
            ...property,
            coordinators: res?.coordinators || [],
          },
        });
      })
      .catch((err: any) => {
        setSelectedCoordinator(oldValues);
      });
  };

  const loadCoordinatorOptions = async (keyword: string) => {
    return await getUsers({ keyword }).then((res: any) => {
      return res.docs.map((user: UserProps) => ({
        value: user.pk,
        label:
          user?.first_name || user?.last_name
            ? [user?.first_name, user?.last_name].join(" ")
            : user?.email,
      }));
    });
  };

  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>{`${META_TAGS?.default?.title} ${
          property?.title || property?.address
        } Contacts`}</title>
        <meta
          property="og:title"
          content={`${META_TAGS?.default?.title} ${
            property?.title || property?.address
          } Contacts`}
        />
      </Helmet>
      <div id="items-container">
        <Layout.Section>
          <AccordionItem id="coordinators" label="Listing Coordinators">
            <div className="flex flex-col w-full pt-8">
              <SelectAutoComplete
                name="coordinators"
                isMulti
                onChange={(value: any) => {
                  updateCoordinators(value);
                }}
                value={selectedCoordinator}
                loadOptions={loadCoordinatorOptions}
              />
            </div>
          </AccordionItem>
        </Layout.Section>
        <Layout.Section>
          <AccordionItem id="brokerage" label="Agency Brokerage Team">
            <div className="flex flex-col w-full pt-4">
              <div className="flex w-full justify-end items-center">
                <Button
                  variant="secondary"
                  className="w-fit"
                  leadingIcon={FiPlusCircle}
                  onClick={() => {
                    setIsOpen(true);
                  }}
                >
                  Add Broker
                </Button>
              </div>
              {!contacts?.length && (
                <EmptyResult className="mt-8">
                  <h3 className="text-heading3 text-jll-color-coldGray-5">
                    Brokerage Team is empty...
                  </h3>
                  <p className="mt-4 text-body1 text-jll-color-coldGray-7 mb-2">
                    Click on the Add Broker button.
                  </p>
                </EmptyResult>
              )}
              {!!contacts?.length && (
                <Table className="mt-6">
                  <Table.Thead>
                    <Table.Tr>
                      <Table.Th />
                      {BROKERS_TABLE_COLUMNS.map((column, index) => {
                        return <Table.Th key={index}>{column.label}</Table.Th>;
                      })}
                      <Table.Th>Role</Table.Th>
                      <Table.Th />
                    </Table.Tr>
                  </Table.Thead>
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                      {(provided, snapshot) => (
                        <Table.Tbody
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {leadContact?.map(
                            (
                              contact: BuildingBrokerProps,
                              contact_index: number
                            ) => {
                              return (
                                <Table.Tr
                                  key={contact_index}
                                  onClick={onClickDetail(contact)}
                                >
                                  <Table.Td />
                                  {BROKERS_TABLE_COLUMNS.map(
                                    (column, column_index) => {
                                      return (
                                        <Table.Td
                                          key={column_index}
                                          className={column?.td?.className}
                                        >
                                          <StyledBrokerValue
                                            broker={contact}
                                            valueKey={column.id}
                                          />
                                        </Table.Td>
                                      );
                                    }
                                  )}
                                  <Table.Td>
                                    {contact?.building_broker_role || "-"}
                                  </Table.Td>
                                  <Table.Td className="space-x-2 text-right">
                                    <Button
                                      variant="secondary"
                                      size="small"
                                      onClick={(e) => onClickRemove(e, contact)}
                                    >
                                      Remove
                                    </Button>
                                  </Table.Td>
                                </Table.Tr>
                              );
                            }
                          )}
                          {additionalContacts?.map(
                            (
                              contact: BuildingBrokerProps,
                              contact_index: number
                            ) => {
                              return (
                                <Draggable
                                  key={contact?.id}
                                  draggableId={contact?.id.toString()}
                                  index={contact_index}
                                >
                                  {(provided, snapshot) => (
                                    <Table.Tr
                                      key={contact_index}
                                      onClick={onClickDetail(contact)}
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      className={cn({
                                        "bg-jll-color-coldGray-1 flex justify-between items-center":
                                          snapshot.isDragging,
                                      })}
                                    >
                                      <Table.Td>
                                        <div
                                          className="flex items-center justify-center w-5 h-6"
                                          {...provided.dragHandleProps}
                                        >
                                          <RxDragHandleDots2 className="text-xl" />
                                        </div>
                                      </Table.Td>
                                      {BROKERS_TABLE_COLUMNS.map(
                                        (column, column_index) => {
                                          return (
                                            <Table.Td
                                              key={column_index}
                                              className={column?.td?.className}
                                            >
                                              <StyledBrokerValue
                                                broker={contact}
                                                valueKey={column.id}
                                              />
                                            </Table.Td>
                                          );
                                        }
                                      )}
                                      <Table.Td>
                                        {contact?.building_broker_role || "-"}
                                      </Table.Td>
                                      <Table.Td className="space-x-2 text-right">
                                        <Button
                                          variant="secondary"
                                          size="small"
                                          onClick={(e) =>
                                            onClickRemove(e, contact)
                                          }
                                        >
                                          Remove
                                        </Button>
                                      </Table.Td>
                                    </Table.Tr>
                                  )}
                                </Draggable>
                              );
                            }
                          )}
                          {provided.placeholder}
                        </Table.Tbody>
                      )}
                    </Droppable>
                  </DragDropContext>
                </Table>
              )}
            </div>
          </AccordionItem>
        </Layout.Section>

        <div className="px-8 fixed bottom-0 left-[360px] right-0 flex justify-end bg-white shadow-detail-status-nav h-[80px] items-center">
          <div className="flex space-x-2">
            <Link
              to={`/property/${propertyId}/${URLS.PROPERTY.FORM}?tab=${PROPERTY_TABS.EDIT}`}
            >
              <Button variant="secondary" leadingIcon={FiChevronLeft}>
                Previous
              </Button>
            </Link>
            <Link
              to={`/property/${propertyId}/${URLS.PROPERTY.FORM}?tab=${PROPERTY_TABS.MEDIA}`}
            >
              <Button variant="secondary" trailingIcon={FiChevronRight}>
                Next
              </Button>
            </Link>
          </div>
        </div>
      </div>

      <ContactModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onClick={onClickContact}
        meta={meta}
        isProperty={true}
      />
      <BrokerDetailModal
        isOpen={isBrokerModalOpen}
        setIsOpen={setIsBrokerModalOpen}
        broker={selectedBroker}
      />
    </>
  );
};

export default PropertyContacts;
