import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import {
  FiChevronLeft,
  FiChevronRight,
  FiExternalLink,
  FiMaximize2,
  FiPlayCircle,
  FiTrash2,
} from "react-icons/fi";
import { Label, Chip, Button, Dropzone, Toast } from "ui-atoms";
import {
  AccordionItem,
  Card,
  RemoveDescriptionItem,
  VideoUrlFields,
  WarningModal,
} from "ui-molecules";
import {
  convertImageUrl,
  getPropertyAPI,
  getPropertyMediaUpdateMarketsphereAPI,
  patchPropertyAPI,
  postImageAPI,
  postVideoAPI,
} from "services";
import { LayoutEditProperty as Layout } from "ui-organisms";
import { useFormik } from "formik";
import { renderToString } from "react-dom/server";
import { useApiCall, useCallbackPrompt, useWindowDimensions } from "hooks";
import { GlobalContext } from "context";
import {
  META_TAGS,
  OLM_PROPERTY_FIELDS,
  PROPERTY_TABS,
  SET_CONFIRM_MODAL,
  SET_LOADING_MODAL,
  SET_PROPERTY,
  STATUS_ACTIVE,
  UPDATE_LIGHTGALLERY_DATA,
  URLS,
} from "constant";
import {
  capitalizeWords,
  convertExtension,
  fetchFile,
  getApiPayloadFromForm,
  isCommonItem,
  ordinalSuffixOf,
} from "utils";
import validationSchema from "./validationSchema";
import { INITIAL_VALUES, InitialValuesProps } from "./constants";
import { Helmet } from "react-helmet-async";
import { Link, useParams } from "react-router-dom";
import { GridContextProvider, GridDropZone, GridItem, swap } from "ui-atoms";
import { FaStreetView } from "react-icons/fa";

interface IPropertyMedia {
  onDiffFields?: any;
  diffFields?: any;
}

const PropertyMedia = forwardRef(
  ({ onDiffFields, diffFields }: IPropertyMedia, ref) => {
    const [patchProperty] = useApiCall(patchPropertyAPI);
    const [getProperty] = useApiCall(getPropertyAPI);
    const [getPropertyMediaUpdateMarketsphere] = useApiCall(
      getPropertyMediaUpdateMarketsphereAPI
    );
    const { dispatch, state } = useContext(GlobalContext);
    const { property, lightGalleryRef, showTooltip, hideTooltip } = state;
    const { propertyId } = useParams();
    const [loading, setLoading] = useState(false);
    const [isWarningModal, setIsWarningModal] = useState(false);
    const { width } = useWindowDimensions();
    const publishStatus = useRef<any>(null);
    const publishAvailabilities = useRef<any>(false);
    const [showDialog, setShowDialog] = useState<boolean>(false);
    const [showPrompt, confirmNavigation, cancelNavigation] =
      useCallbackPrompt(showDialog);

    const {
      handleSubmit,
      handleChange,
      handleBlur,
      setFieldValue,
      values,
      errors,
      touched,
      setValues,
    } = useFormik({
      initialValues: INITIAL_VALUES,
      validationSchema,
      onSubmit: async () => {
        setLoading(true);
        hideTooltip();
        dispatch({
          type: SET_LOADING_MODAL,
          payload: {
            open: true,
            label: "Updating Property",
          },
        });
        try {
          let payload: InitialValuesProps | undefined = getApiPayloadFromForm(
            property,
            values
          );

          if (payload && Object.keys(payload)?.length) {
            if (publishStatus.current) {
              payload["publish_status"] = publishStatus.current;
            }
            if (publishAvailabilities.current) {
              payload["publish_availabilities"] = publishAvailabilities.current;
            }
            if (
              payload &&
              property?.publish_status === STATUS_ACTIVE &&
              isCommonItem(Object.keys(payload), OLM_PROPERTY_FIELDS)
            ) {
              payload["publish_status"] = STATUS_ACTIVE;
            }
            const response = await patchProperty({
              id: property?.id,
              payload,
            });
            setLoading(false);
            publishStatus.current = null;
            publishAvailabilities.current = false;
            setTimeout(() => {
              dispatch({
                type: SET_LOADING_MODAL,
                payload: {
                  open: false,
                  label: null,
                },
              });
            }, 100);
            if (!response) {
              return;
            }
            Toast.success("Changes saved with success");
            dispatch({
              type: SET_PROPERTY,
              payload: {
                ...property,
                ...payload,
              },
            });
          } else {
            Toast.warn("No changes to be saved");
            setLoading(false);
            publishStatus.current = null;
            publishAvailabilities.current = false;
            setTimeout(() => {
              dispatch({
                type: SET_LOADING_MODAL,
                payload: {
                  open: false,
                  label: null,
                },
              });
            }, 100);
          }
        } catch (err) {
          setLoading(false);
          publishStatus.current = null;
          publishAvailabilities.current = false;
          setTimeout(() => {
            dispatch({
              type: SET_LOADING_MODAL,
              payload: {
                open: false,
                label: null,
              },
            });
          }, 100);
        }
      },
    });

    const onClickSubmit = async (
      publish_status: any = null,
      publish_availabilities: boolean = false
    ) => {
      if (errors && !!Object.keys(errors)?.length) {
        setIsWarningModal(true);
      }
      publishStatus.current = publish_status;
      publishAvailabilities.current = publish_availabilities;
      await handleSubmit();
    };

    useImperativeHandle(ref, () => ({
      onClickSubmit,
    }));

    useEffect(() => {
      if (
        property?.id &&
        !values.id &&
        property?.id?.toString() === propertyId?.toString()
      ) {
        let formValues = {
          id: property.id,
        };
        Object.keys(INITIAL_VALUES).forEach((key: string) => {
          formValues = {
            ...formValues,
            [key]: property[key] || [],
          };
        });
        setValues({
          ...formValues,
          publish_status: property?.publish_status,
          dirty: property?.dirty,
        });
      }
    }, [property, values, propertyId]);

    useEffect(() => {
      if (!property || !values) return;
      const diff = getApiPayloadFromForm(property, values);
      onDiffFields(diff);
    }, [property, values]);

    useEffect(() => {
      if (!diffFields || !Object.keys(diffFields)?.length) setShowDialog(false);
      else setShowDialog(true);
    }, [diffFields]);

    useEffect(() => {
      if (!showPrompt) return;
      const action = async () => {
        try {
          if (errors && !!Object.keys(errors)?.length) {
            setIsWarningModal(true);
            // @ts-ignore
            cancelNavigation();
            return;
          }
          await handleSubmit();
          // @ts-ignore
          confirmNavigation();
        } catch (err) {
          // @ts-ignore
          cancelNavigation();
        }
      };
      let isPublish = false;
      if (
        diffFields &&
        property?.publish_status === STATUS_ACTIVE &&
        isCommonItem(Object.keys(diffFields), OLM_PROPERTY_FIELDS)
      ) {
        isPublish = true;
      }

      dispatch({
        type: SET_CONFIRM_MODAL,
        payload: {
          open: true,
          content: {
            description: renderToString(
              <RemoveDescriptionItem description="You have un-saved changes, would you like to save changes before proceeding" />
            ),
            btnTitle: `${isPublish ? "Save and Publish" : "Save"}`,
            cancelTitle: "Proceed without Saving",
            action: action,
            cancel: confirmNavigation,
            additionalCancel: cancelNavigation,
          },
        },
      });
    }, [showPrompt]);

    const handleSubmitImg = async (
      name: string,
      files: any = null,
      url: string = "",
      isVideo: boolean = false
    ) => {
      if (files?.length <= 0 && !url?.length) return;
      try {
        dispatch({
          type: SET_LOADING_MODAL,
          payload: {
            open: true,
            label: "Uploading files",
          },
        });

        let results: any[] = [];
        if (files?.length > 0) {
          await Promise.all(
            files?.map(async (file: any) => {
              let result;
              if (!isVideo) result = await postImageAPI(file);
              else result = await postVideoAPI(file);
              if (result) {
                results.push(result);
              }
            })
          );
        } else if (!!url?.length) {
          const file = await fetchFile(url);
          if (!file) results.push(url);
          else {
            let result;
            if (!isVideo) result = await postImageAPI(file);
            else result = await postVideoAPI(file);
            if (result) {
              results.push(result);
            } else {
              results.push(url);
            }
          }
        }
        if (!!results?.length) {
          let data = [...(property[name] || []), ...results];
          let payload: any = {
            [name]: data,
          };
          if (
            payload &&
            property?.publish_status === STATUS_ACTIVE &&
            isCommonItem(Object.keys(payload), OLM_PROPERTY_FIELDS)
          ) {
            payload["publish_status"] = STATUS_ACTIVE;
          }
          const response = await patchProperty({
            id: property?.id,
            payload,
          });
          if (!!response) {
            dispatch({
              type: SET_PROPERTY,
              payload: {
                ...property,
                [name]: response?.[name] || data,
              },
            });
          }
        }
        setTimeout(() => {
          dispatch({
            type: SET_LOADING_MODAL,
            payload: {
              open: false,
              label: null,
            },
          });
        }, 100);
      } catch (err) {
        setTimeout(() => {
          dispatch({
            type: SET_LOADING_MODAL,
            payload: {
              open: false,
              label: null,
            },
          });
        }, 100);
      }
    };

    const removeValue = (name: string, index: number) => {
      const action = () => {
        const newValue = [...property[name]];
        newValue.splice(index, 1);
        let payload: any = {
          [name]: newValue,
        };
        if (
          payload &&
          property?.publish_status === STATUS_ACTIVE &&
          isCommonItem(Object.keys(payload), OLM_PROPERTY_FIELDS)
        ) {
          payload["publish_status"] = STATUS_ACTIVE;
        }
        patchProperty({
          id: property?.id,
          payload,
        }).then((res: any) => {
          if (!res) return;
          dispatch({
            type: SET_PROPERTY,
            payload: {
              ...property,
              [name]: newValue,
            },
          });
        });
      };
      dispatch({
        type: SET_CONFIRM_MODAL,
        payload: {
          open: true,
          content: {
            description: renderToString(
              <RemoveDescriptionItem
                target={`${ordinalSuffixOf(
                  index + 1
                )} Item in ${capitalizeWords(name, "_")}`}
              />
            ),
            action,
          },
        },
      });
    };

    const handleOpenGallery = async (name: string, index: number) => {
      if (property?.[name]?.[index]?.includes(".pdf")) {
        window.open(property?.[name]?.[index], "_blank");
        return;
      }
      let arr: any = [];
      property[name]?.forEach((url: string, idx: number) => {
        arr.push({
          id: idx,
          src: convertImageUrl(convertExtension(url)),
          thumb: convertImageUrl(convertExtension(url)),
          subHtml: renderToString(<span>{idx + 1}</span>),
        });
      });
      await dispatch({
        type: UPDATE_LIGHTGALLERY_DATA,
        payload: arr,
      });
      setTimeout(() => {
        lightGalleryRef.current.openGallery(index);
      }, 100);
    };

    const handleSwap = async (
      sourceId: string,
      sourceIndex: number,
      targetIndex: number,
      targetId: any
    ) => {
      const oldState = [...property[sourceId]];
      const nextState = swap(
        [-1, ...property[sourceId]],
        sourceIndex,
        targetIndex
      );
      dispatch({
        type: SET_PROPERTY,
        payload: {
          ...property,
          [sourceId]: nextState.filter((item) => item !== -1),
        },
      });
      let payload: any = {
        [sourceId]: nextState.filter((item) => item !== -1),
      };
      if (
        payload &&
        property?.publish_status === STATUS_ACTIVE &&
        isCommonItem(Object.keys(payload), OLM_PROPERTY_FIELDS)
      ) {
        payload["publish_status"] = STATUS_ACTIVE;
      }
      const response = await patchProperty({
        id: property?.id,
        payload,
      });
      if (response) return;
      dispatch({
        type: SET_PROPERTY,
        payload: {
          ...property,
          [sourceId]: oldState,
        },
      });
    };

    const handleReset = () => {
      const action = async () => {
        try {
          dispatch({
            type: SET_LOADING_MODAL,
            payload: {
              open: true,
              label: "Resetting Media from MarketSphere",
            },
          });
          const res = await getPropertyMediaUpdateMarketsphere(propertyId);
          if (!!res) {
            getProperty(propertyId)
              .then((data: any) => {
                dispatch({
                  type: SET_PROPERTY,
                  payload: data,
                });
              })
              .finally(() => {
                setTimeout(() => {
                  dispatch({
                    type: SET_LOADING_MODAL,
                    payload: {
                      open: false,
                      label: null,
                    },
                  });
                }, 100);
              });
          } else
            setTimeout(() => {
              dispatch({
                type: SET_LOADING_MODAL,
                payload: {
                  open: false,
                  label: null,
                },
              });
            }, 100);
        } catch (err) {
          setTimeout(() => {
            dispatch({
              type: SET_LOADING_MODAL,
              payload: {
                open: false,
                label: null,
              },
            });
          }, 100);
        }
      };

      dispatch({
        type: SET_CONFIRM_MODAL,
        payload: {
          open: true,
          content: {
            description: renderToString(
              <RemoveDescriptionItem
                description="This will validate existing media, fetch existing media from marketsphere and add it to MyListings. This updates Images, Floorplans, and Brochures"
                target=""
              />
            ),
            action,
          },
        },
      });
    };

    const boxCount = Math.floor((width - 424) / 192);

    return (
      <>
        <Helmet prioritizeSeoTags>
          <title>{`${META_TAGS?.default?.title} ${
            property?.title || property?.address
          } Media`}</title>
          <meta
            property="og:title"
            content={`${META_TAGS?.default?.title} ${
              property?.title || property?.address
            } Media`}
          />
        </Helmet>

        {!!property?.id && (
          <form>
            <div className="px-8 flex flex-row justify-end pt-10 mb-[-75px] mr-8">
              <Button variant="primary" onClick={handleReset}>
                Reset Media from MarketSphere
              </Button>
            </div>
            <Layout.Section>
              <AccordionItem
                id="images"
                label={`Property Images (${property?.images?.length || 0})`}
              >
                <div className="flex flex-col w-full pt-4">
                  <div className="flex flex-row items-center space-x-2">
                    <Label>The first photo will be on the cover.</Label>
                    <Chip variant="secondary" size="small">
                      Optional
                    </Chip>
                  </div>
                  <div className="flex flex-col pt-4">
                    <GridContextProvider onChange={handleSwap}>
                      <GridDropZone
                        id="images"
                        boxesPerRow={boxCount}
                        rowHeight={192}
                        className="w-full"
                        style={{
                          height: `${
                            Math.ceil(
                              ((property?.images || [])?.length + 1) / boxCount
                            ) * 192
                          }px`,
                        }}
                        disableDropIndex={0}
                      >
                        {[-1, ...(property?.images || [])]?.map(
                          (item: string, idx: number) => {
                            const index = idx - 1;
                            if (index === -1) {
                              return (
                                <GridItem key={item} disabled={true}>
                                  <Dropzone
                                    className="w-44 h-44 mb-4"
                                    setDropFiles={(files: any) =>
                                      handleSubmitImg("images", files)
                                    }
                                    submitImg={handleSubmitImg}
                                    name="images"
                                  />
                                </GridItem>
                              );
                            }
                            return (
                              <GridItem key={item}>
                                <Card
                                  className="w-44 h-44 mb-4 mr-4"
                                  chip={
                                    index === 0 ? (
                                      <Chip
                                        variant="primary"
                                        className="absolute top-2 left-2"
                                      >
                                        Cover image
                                      </Chip>
                                    ) : (
                                      <></>
                                    )
                                  }
                                  src={convertExtension(item)}
                                  actions={[
                                    <Button
                                      variant="secondary"
                                      leadingIcon={FiMaximize2}
                                      onClick={() =>
                                        handleOpenGallery("images", index)
                                      }
                                    />,
                                    <Button
                                      variant="secondary"
                                      leadingIcon={FiTrash2}
                                      onClick={() =>
                                        removeValue("images", index)
                                      }
                                    />,
                                  ]}
                                />
                              </GridItem>
                            );
                          }
                        )}
                      </GridDropZone>
                    </GridContextProvider>
                  </div>
                </div>
              </AccordionItem>
            </Layout.Section>

            <Layout.Section>
              <AccordionItem
                id="floorplans"
                label={`Floorplans (${property?.floorplans?.length || 0})`}
              >
                <div className="flex flex-col w-full pt-4">
                  <div className="flex flex-row items-center space-x-2">
                    <Label>The first floorplan will be on the cover.</Label>
                    <Chip variant="secondary" size="small">
                      Optional
                    </Chip>
                  </div>
                  <div className="flex flex-wrap pt-4">
                    <GridContextProvider onChange={handleSwap}>
                      <GridDropZone
                        id="floorplans"
                        boxesPerRow={boxCount}
                        rowHeight={192}
                        className="w-full"
                        style={{
                          height: `${
                            Math.ceil(
                              ((property?.floorplans || [])?.length + 1) /
                                boxCount
                            ) * 192
                          }px`,
                        }}
                        disableDropIndex={0}
                      >
                        {[-1, ...(property?.floorplans || [])]?.map(
                          (src: string | number, idx: number) => {
                            const index = idx - 1;
                            if (index === -1) {
                              return (
                                <GridItem key={src} disabled={true}>
                                  <Dropzone
                                    className="w-44 h-44 mb-4 mr-4"
                                    setDropFiles={(files: any) =>
                                      handleSubmitImg("floorplans", files)
                                    }
                                    title="Add floorplans here,"
                                    submitImg={handleSubmitImg}
                                    name="floorplans"
                                  />
                                </GridItem>
                              );
                            }
                            return (
                              <GridItem key={src}>
                                <Card
                                  className="w-44 h-44 mb-4 mr-4"
                                  chip={
                                    index === 0 ? (
                                      <Chip
                                        variant="primary"
                                        className="absolute top-2 left-2"
                                      >
                                        Floor Plan
                                      </Chip>
                                    ) : (
                                      <></>
                                    )
                                  }
                                  src={convertExtension(src?.toString())}
                                  actions={[
                                    <Button
                                      variant="secondary"
                                      leadingIcon={FiMaximize2}
                                      onClick={() =>
                                        handleOpenGallery("floorplans", index)
                                      }
                                    />,
                                    <Button
                                      variant="secondary"
                                      leadingIcon={FiTrash2}
                                      onClick={() =>
                                        removeValue("floorplans", index)
                                      }
                                    />,
                                  ]}
                                />
                              </GridItem>
                            );
                          }
                        )}
                      </GridDropZone>
                    </GridContextProvider>
                  </div>
                </div>
              </AccordionItem>
            </Layout.Section>

            <Layout.Section>
              <AccordionItem
                id="brochures"
                labelChild={
                  <label className="text-jll-color-text-base-default text-2xl font-semibold">
                    Brochures ({property?.brochures?.length || 0})
                    {!!property?.brochures?.length && (
                      <a
                        href={`${process.env.REACT_APP_API_PREFIX_URL}/building/${propertyId}/brochure/`}
                        className="text-base text-jll-color-icon-info ml-3"
                        target="_blank"
                        id="brochure-link"
                        onMouseOver={() => showTooltip("brochure-link")}
                        onMouseLeave={() => hideTooltip()}
                        data-tooltip-content="Right click this link URL, and copy it to share the brochure. Changing the brochure will not impact the share-ability of a link"
                      >
                        Static Brochure Link
                      </a>
                    )}
                  </label>
                }
              >
                <div className="flex flex-col w-full pt-4">
                  <div className="flex  flex-row items-center space-x-2">
                    <Label>The first brochure will be on the cover.</Label>
                    <Chip variant="secondary" size="small">
                      Optional
                    </Chip>
                  </div>
                  <div className="flex flex-wrap pt-4">
                    <GridContextProvider onChange={handleSwap}>
                      <GridDropZone
                        id="brochures"
                        boxesPerRow={boxCount}
                        rowHeight={192}
                        className="w-full"
                        style={{
                          height: `${
                            Math.ceil(
                              ((property?.brochures || [])?.length + 1) /
                                boxCount
                            ) * 192
                          }px`,
                        }}
                        disableDropIndex={0}
                      >
                        {[-1, ...(property?.brochures || [])]?.map(
                          (src: string, idx: number) => {
                            const index = idx - 1;
                            if (index === -1) {
                              return (
                                <GridItem key={src} disabled={true}>
                                  <Dropzone
                                    className="w-44 h-44 mb-4 mr-4"
                                    setDropFiles={(files: any) =>
                                      handleSubmitImg("brochures", files)
                                    }
                                    setFileUrl={(url: string) => {
                                      handleSubmitImg("brochures", null, url);
                                    }}
                                    title="Add brochures here,"
                                    submitImg={handleSubmitImg}
                                    name="brochures"
                                    isInteractive
                                  />
                                </GridItem>
                              );
                            }
                            const isCloudaryFile = src?.includes("cloudinary")
                              ? true
                              : false;
                            return (
                              <GridItem key={src}>
                                <Card
                                  className="w-44 h-44 mb-4 mr-4"
                                  chip={
                                    index === 0 ? (
                                      <Chip
                                        variant="primary"
                                        className="absolute top-2 left-2"
                                      >
                                        Brochure
                                      </Chip>
                                    ) : !isCloudaryFile ? (
                                      <Chip
                                        variant="primary"
                                        className="absolute top-2 left-2"
                                      >
                                        Interactive Brochure
                                      </Chip>
                                    ) : (
                                      <></>
                                    )
                                  }
                                  src={convertExtension(src)}
                                  isInteractive
                                  actions={[
                                    !isCloudaryFile ? (
                                      <Button
                                        variant="secondary"
                                        leadingIcon={FiExternalLink}
                                        onClick={() => {
                                          window.open(src, "_blank");
                                          hideTooltip();
                                        }}
                                        id={`copy-url-${idx}`}
                                        onMouseOver={() =>
                                          showTooltip(`copy-url-${idx}`)
                                        }
                                        onMouseLeave={() => hideTooltip()}
                                        data-tooltip-content="Please click to load interactive brochure"
                                      />
                                    ) : (
                                      <Button
                                        variant="secondary"
                                        leadingIcon={FiMaximize2}
                                        onClick={() =>
                                          handleOpenGallery("brochures", index)
                                        }
                                      />
                                    ),
                                    <Button
                                      variant="secondary"
                                      leadingIcon={FiTrash2}
                                      onClick={() =>
                                        removeValue("brochures", index)
                                      }
                                    />,
                                  ]}
                                />
                              </GridItem>
                            );
                          }
                        )}
                      </GridDropZone>
                    </GridContextProvider>
                  </div>
                </div>
              </AccordionItem>
            </Layout.Section>

            <Layout.Section>
              <AccordionItem
                id="videos"
                label={`Videos (${values?.videos?.length || 0})`}
              >
                <div className="flex flex-col w-full pt-4">
                  <div className="flex flex-row items-center space-x-2 pb-2">
                    <Label>Link to video</Label>
                    <Chip variant="secondary" size="small">
                      Optional
                    </Chip>
                  </div>
                  <div className="flex flex-wrap pt-4">
                    <GridContextProvider onChange={handleSwap}>
                      <GridDropZone
                        id="videos"
                        boxesPerRow={boxCount}
                        rowHeight={192}
                        className="w-full"
                        style={{
                          height: `${
                            Math.ceil(
                              ((property?.videos || [])?.length + 1) / boxCount
                            ) * 192
                          }px`,
                        }}
                        disableDropIndex={0}
                      >
                        {[-1, ...(property?.videos || [])]?.map(
                          (src: string, idx: number) => {
                            const index = idx - 1;
                            if (index === -1) {
                              return (
                                <GridItem key={src} disabled={true}>
                                  <Dropzone
                                    className="w-44 h-44 mb-4 mr-4"
                                    setDropFiles={(files: any) =>
                                      handleSubmitImg("videos", files, "", true)
                                    }
                                    setFileUrl={(url: string) => {
                                      handleSubmitImg("videos", null, url, true);
                                    }}
                                    title="Add videos here,"
                                    submitImg={handleSubmitImg}
                                    name="videos"
                                    isInteractive
                                    icon={FiPlayCircle}
                                    acceptType="video/*"
                                  />
                                </GridItem>
                              );
                            }
                            const isCloudaryFile = src?.includes("cloudinary")
                              ? true
                              : false;
                            return (
                              <GridItem key={src}>
                                <Card
                                  className="w-44 h-44 mb-4 mr-4"
                                  src={convertExtension(src)}
                                  isInteractive
                                  actions={[
                                    !isCloudaryFile ? (
                                      <Button
                                        variant="secondary"
                                        leadingIcon={FiExternalLink}
                                        onClick={() => {
                                          window.open(src, "_blank");
                                          hideTooltip();
                                        }}
                                        id={`video-copy-url-${idx}`}
                                        onMouseOver={() =>
                                          showTooltip(`video-copy-url-${idx}`)
                                        }
                                        onMouseLeave={() => hideTooltip()}
                                        data-tooltip-content="Please click to load interactive videos"
                                      />
                                    ) : (
                                      <Button
                                        variant="secondary"
                                        leadingIcon={FiMaximize2}
                                        onClick={() => {
                                          window.open(src, "_blank");
                                        }}
                                      />
                                    ),
                                    <Button
                                      variant="secondary"
                                      leadingIcon={FiTrash2}
                                      onClick={() =>
                                        removeValue("videos", index)
                                      }
                                    />,
                                  ]}
                                />
                              </GridItem>
                            );
                          }
                        )}
                      </GridDropZone>
                    </GridContextProvider>
                  </div>
                </div>
              </AccordionItem>
            </Layout.Section>

            <Layout.Section>
              <AccordionItem
                id="virtual"
                label={`Virtual Tour (${values?.virtual_tours?.length || 0})`}
              >
                <div className="flex flex-col w-full pt-4">
                  <div className="flex flex-row items-center space-x-2 mb-2">
                    <Label>Link to Virtual Tour</Label>
                    <Chip variant="secondary" size="small">
                      Optional
                    </Chip>
                  </div>
                  <div className="flex flex-wrap pt-4">
                    <GridContextProvider onChange={handleSwap}>
                      <GridDropZone
                        id="virtual_tours"
                        boxesPerRow={boxCount}
                        rowHeight={192}
                        className="w-full"
                        style={{
                          height: `${
                            Math.ceil(
                              ((property?.virtual_tours || [])?.length + 1) /
                                boxCount
                            ) * 192
                          }px`,
                        }}
                        disableDropIndex={0}
                      >
                        {[-1, ...(property?.virtual_tours || [])]?.map(
                          (src: string, idx: number) => {
                            const index = idx - 1;
                            if (index === -1) {
                              return (
                                <GridItem key={src} disabled={true}>
                                  <Dropzone
                                    className="w-44 h-44 mb-4 mr-4"
                                    setDropFiles={(files: any) =>
                                      handleSubmitImg("virtual_tours", files)
                                    }
                                    setFileUrl={(url: string) => {
                                      handleSubmitImg(
                                        "virtual_tours",
                                        null,
                                        url
                                      );
                                    }}
                                    title="Add virtual tours here,"
                                    submitImg={handleSubmitImg}
                                    name="virtual_tours"
                                    isInteractive
                                    icon={FaStreetView}
                                  />
                                </GridItem>
                              );
                            }
                            const isCloudaryFile = src?.includes("cloudinary")
                              ? true
                              : false;
                            return (
                              <GridItem key={src}>
                                <Card
                                  className="w-44 h-44 mb-4 mr-4"
                                  src={convertExtension(src)}
                                  isInteractive
                                  actions={[
                                    !isCloudaryFile ? (
                                      <Button
                                        variant="secondary"
                                        leadingIcon={FiExternalLink}
                                        onClick={() => {
                                          window.open(src, "_blank");
                                          hideTooltip();
                                        }}
                                        id={`virtual-copy-url-${idx}`}
                                        onMouseOver={() =>
                                          showTooltip(`virtual-copy-url-${idx}`)
                                        }
                                        onMouseLeave={() => hideTooltip()}
                                        data-tooltip-content="Please click to load interactive virtual tours"
                                      />
                                    ) : (
                                      <Button
                                        variant="secondary"
                                        leadingIcon={FiMaximize2}
                                        onClick={() => {
                                          window.open(src, "_blank");
                                        }}
                                      />
                                    ),
                                    <Button
                                      variant="secondary"
                                      leadingIcon={FiTrash2}
                                      onClick={() =>
                                        removeValue("virtual_tours", index)
                                      }
                                    />,
                                  ]}
                                />
                              </GridItem>
                            );
                          }
                        )}
                      </GridDropZone>
                    </GridContextProvider>
                  </div>
                </div>
              </AccordionItem>
            </Layout.Section>
            <Layout.Section>
              <AccordionItem
                id="website"
                label={`Property Website (${values?.websites?.length || 0})`}
              >
                <div className="flex flex-col w-full pt-4">
                  <div className="flex flex-row items-center space-x-2 mb-2">
                    <Label>Link to Property Website</Label>
                    <Chip variant="secondary" size="small">
                      Optional
                    </Chip>
                  </div>
                  <VideoUrlFields
                    name="websites"
                    values={values}
                    touched={touched}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    isThumb={false}
                    placeholder="Enter link to website here"
                  />
                </div>
              </AccordionItem>
            </Layout.Section>

            <div className="px-8 fixed bottom-0 left-[360px] right-0 flex justify-end bg-white shadow-detail-status-nav h-[80px] items-center">
              <div className="flex space-x-4">
                <div className="flex space-x-2">
                  <Link
                    to={`/property/${propertyId}/${URLS.PROPERTY.FORM}?tab=${PROPERTY_TABS.CONTACTS}`}
                  >
                    <Button variant="secondary" leadingIcon={FiChevronLeft}>
                      Previous
                    </Button>
                  </Link>
                  <Link
                    to={`/property/${propertyId}/${URLS.PROPERTY.FORM}?tab=${PROPERTY_TABS.PREVIEW}`}
                  >
                    <Button variant="secondary" trailingIcon={FiChevronRight}>
                      Next
                    </Button>
                  </Link>
                </div>
                <Button
                  disabled={
                    loading || !diffFields || !Object.keys(diffFields)?.length
                  }
                  variant="primary"
                  id="save-tooltip"
                  onClick={() => onClickSubmit()}
                  onMouseOver={() => showTooltip("save-tooltip")}
                  onMouseLeave={() => hideTooltip()}
                  data-tooltip-content={
                    property?.publish_status === STATUS_ACTIVE
                      ? "This saves the property in MyListings and publishes to OLM"
                      : "This saves the property, but does not publish to OLM"
                  }
                >
                  {property?.publish_status === STATUS_ACTIVE
                    ? "Save and Publish"
                    : "Save"}
                </Button>
              </div>
            </div>
          </form>
        )}
        <WarningModal
          isOpen={isWarningModal}
          setIsOpen={setIsWarningModal}
          errors={errors}
        />
      </>
    );
  }
);

export default React.memo(PropertyMedia);
