import { Page, Text, View, Image } from "@react-pdf/renderer";
import { AVAILABILITY_DETAILS, PAGE_ORIENTATION, PAGE_SIZE } from "constant";
import styles from "./styles";
import {
  capitalize,
  convertExtension,
  formatPhoneNumber,
  getDecimalFormating,
  getImageFromURL,
  ordinalSuffixOf,
  removeNewlines,
  sliceIntoChunks,
} from "utils";
import { AvailabilityProps, PropertyProps } from "types";
import LogoImg from "assets/pdf/logo.png";

interface IPageSuite {
  availability: AvailabilityProps;
  property?: PropertyProps;
  propertyMap?: any;
  meta?: any;
}

const getValue = (availability: AvailabilityProps, key: any, meta: any) => {
  if (!key) return;
  switch (key) {
    case "size":
      let size;
      if (!availability?.min_floor_area && !availability?.max_floor_area)
        size = "N/A";
      else if (availability?.min_floor_area && !availability?.max_floor_area) {
        size = getDecimalFormating(availability?.min_floor_area);
      } else if (
        !availability?.min_floor_area &&
        availability?.max_floor_area
      ) {
        size = getDecimalFormating(availability?.max_floor_area);
      } else if (
        availability?.min_floor_area === availability?.max_floor_area
      ) {
        size = getDecimalFormating(availability?.max_floor_area);
      } else if (availability?.min_floor_area && availability?.max_floor_area) {
        size = `${getDecimalFormating(
          availability?.min_floor_area
        )}-${getDecimalFormating(availability?.max_floor_area)}`;
      }
      return (
        <View style={styles.detailColView}>
          <Text style={styles.detailTxt}>{size || ""}</Text>
          {size !== "N/A" && <Text style={styles.detailUnit}>sqft</Text>}
        </View>
      );

    case "rate":
      let price;
      if (!availability?.min_price && !availability?.max_price) {
        price = "Negotiable";
      } else if (availability?.min_price && !availability?.max_price) {
        price = `$${Number(
          getDecimalFormating(Number(availability?.min_price))
        ).toFixed(2)} ${
          availability?.rent_type
            ? `(${
                meta?.SUITE_RENT_TYPE_ABBREVIATED_MAP?.[availability?.rent_type]
              })`
            : ""
        }`;
      } else if (!availability?.min_price && availability?.max_price) {
        price = `$${Number(
          getDecimalFormating(Number(availability?.max_price))
        ).toFixed(2)} ${
          availability?.rent_type
            ? `(${
                meta?.SUITE_RENT_TYPE_ABBREVIATED_MAP?.[availability?.rent_type]
              })`
            : ""
        }`;
      } else if (availability?.min_price === availability?.max_price) {
        price = `$${Number(
          getDecimalFormating(Number(availability?.max_price))
        ).toFixed(2)} ${
          availability?.rent_type
            ? `(${
                meta?.SUITE_RENT_TYPE_ABBREVIATED_MAP?.[availability?.rent_type]
              })`
            : ""
        }`;
      } else if (!!availability?.min_price && !!availability?.max_price) {
        price = `$${Number(
          getDecimalFormating(Number(availability?.min_price))
        ).toFixed(2)}-$${Number(
          getDecimalFormating(Number(availability?.max_price))
        ).toFixed(2)} ${
          availability?.rent_type
            ? `(${
                meta?.SUITE_RENT_TYPE_ABBREVIATED_MAP?.[availability?.rent_type]
              })`
            : ""
        }`;
      }
      return <Text style={styles.detailTxt}>{price || ""}</Text>;

    case "date_available":
      return (
        <View style={styles.detailChip}>
          {/* @ts-ignore */}
          <Text style={{ ...styles.detailTxt, color: "#FFFFFF" }}>
            {availability?.date_available || "N/A"}
          </Text>
        </View>
      );

    case "lease_term":
      return (
        <Text style={styles.detailTxt}>
          {!!availability?.lease_term
            ? `${availability?.lease_term} Year`
            : "N/A"}
        </Text>
      );

    case "space":
      if (!!availability?.max_contiguous)
        return (
          <View style={styles.detailColView}>
            <Text style={styles.detailTxt}>
              {getDecimalFormating(Number(availability?.max_contiguous)) || ""}
            </Text>
            <Text style={styles.detailUnit}>sqft</Text>
          </View>
        );
      if (!!availability?.space_use_type)
        return (
          <Text style={styles.detailTxt}>{availability?.space_use_type}</Text>
        );
      if (!!availability?.suite_condition)
        return (
          <Text style={styles.detailTxt}>{availability?.suite_condition}</Text>
        );
      return <Text style={styles.detailTxt}></Text>;

    case "tenure_types":
      if (!availability?.tenure_types?.length) {
        return <Text style={styles.detailTxt}>N/A</Text>;
      }

      return availability?.tenure_types?.map((type, index) => (
        <View style={styles.detailType.chip}>
          {/* @ts-ignore */}
          <Text style={styles.detailType.txt}>
            {type?.toLowerCase() === "rent" ? "Lease" : type}
          </Text>
        </View>
      ));

    default:
      if (availability?.hasOwnProperty(key)) {
        // @ts-ignore
        return <Text style={styles.detailTxt}>{availability[key]}</Text>;
      }
      return <Text style={styles.detailTxt}>N/A</Text>;
  }
};

const PageSuite: React.FC<IPageSuite> = ({
  availability,
  property,
  propertyMap,
  meta,
}) => {
  return (
    <Page
      size={PAGE_SIZE}
      orientation={PAGE_ORIENTATION.LANDSCAPE}
      style={styles.page}
      id={`Suite_${availability?.pk}`}
    >
      {/* Left Side */}
      <View style={styles.leftSideView}>
        {/* Info Section */}
        <View style={styles.infoView}>
          {!!property?.images?.length && (
            <Image
              src={getImageFromURL(property?.images[0])}
              style={styles.leftImg}
            />
          )}
          <View style={styles.titleView}>
            <Text style={styles.id}>ID {property?.pk || property?.id}</Text>
            <Text style={styles.titleTxt}>{property?.title}</Text>
          </View>
          <View style={styles.addressView}>
            <Text style={styles.address}>{property?.address}</Text>
            <Text style={styles.address}>
              {[
                property?.city,
                [property?.state, property?.post_code].join(" "),
              ].join(", ")}
            </Text>
          </View>
          <View style={{ display: "flex", flexDirection: "row" }}>
            <Text style={styles.typeTxt}>
              {capitalize(property?.property_type)}
            </Text>
            {!!property?.tenure_types?.length && (
              <Text style={styles.tenantTypeTxt}>
                {property?.tenure_types?.length === 1
                  ? property?.tenure_types[0]?.toLowerCase() === "rent"
                    ? "Lease"
                    : capitalize(property?.tenure_types[0])
                  : "Lease/Sale"}
              </Text>
            )}
          </View>
        </View>
        {/* Location Section */}
        <View style={styles.location.view}>
          <Text style={styles.location.title}>Location</Text>
          <Image
            src={getImageFromURL(propertyMap)}
            style={styles.location.img}
          />
        </View>
      </View>

      <View style={styles.rightSideView}>
        <Text style={styles.title}>
          {[
            `${ordinalSuffixOf(availability?.floor) || ""} Floor`,
            `${availability?.name ? `, Suite ${availability?.name}` : ""}`,
          ].join("")}
        </Text>
        <View style={styles.detailView}>
          {sliceIntoChunks(AVAILABILITY_DETAILS, 3).map((detail: any, idx) => (
            <View
              style={{
                ...styles.detailRow,
                ...{ borderTopWidth: idx === 0 ? 0 : 1 },
              }}
            >
              {detail?.map((info: any, idx1: number) => (
                <View
                  style={{
                    ...styles.detailCol,
                    ...{
                      paddingLeft: idx1 === 0 ? 0 : 16,
                      paddingRight: idx1 === detail.length - 1 ? 0 : 16,
                      borderLeftWidth: idx1 === 0 ? 0 : 1,
                    },
                  }}
                >
                  <Text style={styles.detailLabel}>
                    {(() => {
                      if (info?.key === "space") {
                        if (!!availability?.max_contiguous)
                          return <>Maximum Contiguous Available</>;
                        if (!!availability?.space_use_type)
                          return <>Space Use</>;
                        if (!!availability?.suite_condition)
                          return <>Suite Condition</>;
                        return <></>;
                      }
                      return <>{info?.label}</>;
                    })()}
                  </Text>
                  {availability && getValue(availability, info?.key, meta)}
                </View>
              ))}
            </View>
          ))}
        </View>

        {!!availability?.floorplans?.length && (
          <View
            style={{
              ...styles.floorPlan.view,
              height: !!availability?.highlights?.length ? 280 : 350,
            }}
          >
            <Image
              src={getImageFromURL(
                convertExtension(availability?.floorplans?.[0]),
                true
              )}
              style={styles.floorPlan.image}
            />
          </View>
        )}

        {!!availability?.highlights?.length && (
          <View style={styles.highlightPanel}>
            {sliceIntoChunks(availability?.highlights?.slice(0, 4), 2).map(
              (detail: any, idx) => {
                if (!detail) return;
                return (
                  <View style={styles.highlightRow}>
                    {detail?.map((info: any, idx1: number) => (
                      <View
                        style={{
                          ...styles.highlightCol,
                          ...{
                            marginLeft: idx1 === 1 ? 9 : 0,
                            marginRight: idx1 === 0 ? 9 : 0,
                            borderTopWidth: idx === 0 ? 0 : 1,
                          },
                        }}
                      >
                        <Text style={styles.highlightTxt}>{info}</Text>
                      </View>
                    ))}
                  </View>
                );
              }
            )}
          </View>
        )}

        {!!availability?.broker?.length && (
          <View style={styles.brokerPanel}>
            {availability?.broker.slice(0, 4)?.map((item, idx) => (
              <View
                style={{
                  ...styles.brokerView,
                  ...{ borderLeftWidth: idx === 0 ? 0 : 1 },
                }}
              >
                <Image
                  src={getImageFromURL(item?.photo)}
                  style={styles.brokerImg}
                />
                <View style={styles.brokerContent}>
                  <Text style={styles.brokerName}>
                    {removeNewlines(item?.name)}
                  </Text>
                  <Text style={styles.brokerTxt}>{item?.email}</Text>
                  <Text style={styles.brokerTxt}>
                    {formatPhoneNumber(item?.telephone)}
                  </Text>
                </View>
              </View>
            ))}
          </View>
        )}
      </View>

      <View style={styles.logo.view}>
        <Image src={LogoImg} style={styles.logo.img} />
      </View>
    </Page>
  );
};

export default PageSuite;
