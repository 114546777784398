import { useContext, useEffect, useRef, useState } from "react";
import { LayoutEditProperty as Layout } from "ui-organisms";
import PropertyEdit from "./PropertyEdit";
import { StepTabBar } from "ui-molecules";
import {
  PROPERTY_STEPS,
  PROPERTY_TABS,
  PUBLISH_STATUS_DRAFT,
  PUBLISH_STATUS_PUBLISHED,
  URLS,
} from "constant";
import PropertyContacts from "./PropertyContacts";
import PropertyMedia from "./PropertyMedia";
import { useLocation, useParams } from "react-router-dom";
import queryString from "query-string";
import PropertyDetails from "./PropertyDetails";
import { FiAlertCircle, FiCheckCircle } from "react-icons/fi";
import { GlobalContext } from "context";
import { Dot } from "ui-atoms";
import PropertyPublish from "./PropertyPublish";
import React from "react";

const Property = () => {
  const { state } = useContext(GlobalContext);
  const { property } = state;
  const location = useLocation();
  const { propertyId } = useParams();
  const ref = useRef<any>(null);
  const [diffFields, setDiffFields] = useState({});
  const [activeTab, setActiveTab] = useState(PROPERTY_TABS.EDIT);
  const [isOLM, setIsOLM] = useState(false);

  useEffect(() => {
    const filters = queryString.parse(location?.search, {
      arrayFormat: "bracket",
    });
    if (filters?.tab) {
      setActiveTab(filters?.tab?.toString()?.toLowerCase());
    }
  }, [location]);

  return (
    <Layout className="!bottom-[80px]" olmToggle={{ isOLM, setIsOLM }}>
      <div className="relative w-full h-full">
        <div className="flex flex-row justify-between px-8 h-[80px] fixed top-[64px] right-0 left-[360px] z-10 shadow-detail-status-nav border-b border-jll-color-coldGray-3">
          <StepTabBar
            current={activeTab}
            steps={PROPERTY_STEPS}
            baseUrl={`/property/${propertyId}/${URLS.PROPERTY.FORM}`}
            className="h-full"
            tabLabel="Property"
          />

          <div className="flex flex-col justify-center items-end space-y-1">
            <div className="flex flex-row text-sm space-x-2 items-center justify-end">
              <div>Status:</div>
              <div className="flex flex-row items-center">
                <Dot
                  variant={
                    property?.publish_status === PUBLISH_STATUS_PUBLISHED
                      ? "success"
                      : property?.publish_status === PUBLISH_STATUS_DRAFT
                      ? "warning"
                      : "accent"
                  }
                  className="mr-2"
                />
                <span className="font-semibold">
                  {property?.publish_status === PUBLISH_STATUS_PUBLISHED
                    ? "Published"
                    : property?.publish_status === PUBLISH_STATUS_DRAFT
                    ? "Draft"
                    : "Un-Published"}
                </span>
              </div>
            </div>
            <div className="flex items-center text-sm">
              {property?.dirty ? (
                <div className="flex items-center">
                  <FiAlertCircle className="w-4 h-4 text-jll-color-coldGray-5 mr-2" />
                  Local changes haven't been published
                </div>
              ) : property?.publish_status == PUBLISH_STATUS_PUBLISHED ? (
                <div className="flex items-center">
                  <FiCheckCircle className="w-4 h-4 text-jll-color-coldGray-5 mr-2" />
                  Local data matches OLM
                </div>
              ) : (
                <div className="flex items-center">
                  <FiAlertCircle className="w-4 h-4 text-jll-color-coldGray-5 mr-2" />
                  This property is not published to OLM
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="w-full absolute left-0 top-[80px] right-0 bottom-0 overflow-auto">
          {activeTab === PROPERTY_TABS.EDIT && (
            <PropertyEdit
              ref={ref}
              onDiffFields={setDiffFields}
              diffFields={diffFields}
              isOLM={isOLM}
            />
          )}

          {activeTab === PROPERTY_TABS.CONTACTS && (
            <PropertyContacts onDiffFields={setDiffFields} />
          )}

          {activeTab === PROPERTY_TABS.MEDIA && (
            <PropertyMedia
              ref={ref}
              onDiffFields={setDiffFields}
              diffFields={diffFields}
            />
          )}

          {activeTab === PROPERTY_TABS.PREVIEW && <PropertyDetails />}

          {activeTab === PROPERTY_TABS.PUBLISH && (
            <PropertyPublish
              onSave={ref?.current?.onClickSubmit || null}
              diffFields={diffFields}
            />
          )}
        </div>
      </div>
    </Layout>
  );
};

export default React.memo(Property);
