import { PropertyProps } from "types";
import type { StyledPropertyValueProps } from "ui-molecules";
import type { TdProps, ThProps } from "ui-molecules";

interface TableColumnProps {
  id: StyledPropertyValueProps["valueKey"];
  label: string;
  sort?: string;
  td?: TdProps;
  th?: ThProps;
}

export const ALL_PROPERTY_COLUMNS: TableColumnProps[] = [
  {
    id: "info",
    label: "Property",
    sort: "asc",
  },
  {
    id: "address",
    label: "Address",
  },
  {
    id: "property_type",
    label: "Property Type",
    td: {
      className: "space-x-2",
    },
  },
  {
    id: "property_sub_types",
    label: "Property Sub Type",
    td: {
      className: "space-x-2 !z-auto",
    },
  },
  {
    id: "tenure_types",
    label: "Listing Type",
    td: {
      className: "space-x-2 !z-auto",
    },
  },
  {
    id: "building_size",
    label: "Building Size",
  },
  {
    id: "floors",
    label: "Stories",
  },
  {
    id: "year_built",
    label: "Year Built",
  },
  {
    id: "building_class",
    label: "Building Class",
  },
  {
    id: "wls",
    label: "Corporate Accounts (WLS)",
  },
  {
    id: "hide_availabilities_price",
    label: "Hide Availabilities Price",
  },
  {
    id: "amenity_name",
    label: "Amenities",
  },
  {
    id: "images",
    label: "Images",
  },
  {
    id: "floorplans",
    label: "Floorplans",
  },
  {
    id: "highlights",
    label: "Highlights",
  },
  {
    id: "brochures",
    label: "Brochures",
  },
  {
    id: "suites",
    label: "Availabilities",
  },
  {
    id: "space_available",
    label: "Space Available",
  },
  {
    id: "brokers",
    label: "Brokers",
  },
  {
    id: "publish_status",
    label: "Status",
  },
  {
    id: "costar_id",
    label: "Costar ID",
  },
  {
    id: "market",
    label: "Market",
  },
  {
    id: "contract_date",
    label: "Contract Date",
    td: {
      className: "!z-auto",
    },
  },
  {
    id: "dealio",
    label: "Dealio",
  },
  {
    id: "updated_info",
    label: "Updated",
  },
];

export const PROPERTIES_TABLE_COLUMNS: TableColumnProps[] = [
  {
    id: "info",
    label: "Property",
    sort: "asc",
  },
  {
    id: "address",
    label: "Address",
  },
  {
    id: "property_type",
    label: "Property Type",
    td: {
      className: "space-x-2",
    },
  },
  {
    id: "tenure_types",
    label: "Listing Type",
    td: {
      className: "space-x-2 !z-auto",
    },
  },
  {
    id: "building_size",
    label: "Building Size",
  },
  {
    id: "suites",
    label: "Availabilities",
  },
  {
    id: "space_available",
    label: "Space Available",
  },
  {
    id: "brokers",
    label: "Brokers",
  },
  {
    id: "publish_status",
    label: "Status",
  },
  {
    id: "updated_info",
    label: "Updated",
  },
];

export const PROPERTY_TABS = {
  EDIT: "edit",
  CONTACTS: "contacts",
  MEDIA: "media",
  PREVIEW: "preview",
  PUBLISH: "publish",
};

export const PROPERTY_TABS_V2 = {
  DATA: "data",
  ABOUT: "about",
  FINANCIALS: "financials",
  INDUSTRIAL: "industrial",
  OCCUPANCY: "occupancy",
  COMPANY: "company",
};

export const PROPERTY_STEPS = [
  {
    index: 1,
    label: "Edit",
    tag: PROPERTY_TABS.EDIT,
  },
  {
    index: 2,
    label: "Brokerage Team",
    tag: PROPERTY_TABS.CONTACTS,
  },
  {
    index: 3,
    label: "Media",
    tag: PROPERTY_TABS.MEDIA,
  },
  {
    index: 4,
    label: "Preview",
    tag: PROPERTY_TABS.PREVIEW,
  },
  {
    index: 5,
    label: "Publish",
    tag: PROPERTY_TABS.PUBLISH,
  },
];

export const PROPERTY_STEPS_INDUSTRIAL_V2 = [
  {
    index: 1,
    label: "Property Data",
    tag: PROPERTY_TABS_V2.DATA,
  },
  {
    index: 2,
    label: "About",
    tag: PROPERTY_TABS_V2.ABOUT,
  },
  {
    index: 3,
    label: "Financials",
    tag: PROPERTY_TABS_V2.FINANCIALS,
  },
  {
    index: 4,
    label: "Industrial",
    tag: PROPERTY_TABS_V2.INDUSTRIAL,
  },
  {
    index: 5,
    label: "Occupancy",
    tag: PROPERTY_TABS_V2.OCCUPANCY,
  },
  {
    index: 6,
    label: "Company",
    tag: PROPERTY_TABS_V2.COMPANY,
  },
];

export const PROPERTY_STEPS_V2 = [
  {
    index: 1,
    label: "Property Data",
    tag: PROPERTY_TABS_V2.DATA,
  },
  {
    index: 2,
    label: "About",
    tag: PROPERTY_TABS_V2.ABOUT,
  },
  {
    index: 3,
    label: "Financials",
    tag: PROPERTY_TABS_V2.FINANCIALS,
  },
  {
    index: 4,
    label: "Occupancy",
    tag: PROPERTY_TABS_V2.OCCUPANCY,
  },
  {
    index: 5,
    label: "Company",
    tag: PROPERTY_TABS_V2.COMPANY,
  },
];

export const PROPERTY_STEPS_OLM_V2 = [
  {
    index: 1,
    label: "Property Data",
    tag: PROPERTY_TABS_V2.DATA,
  },
  {
    index: 2,
    label: "About",
    tag: PROPERTY_TABS_V2.ABOUT,
  },
  {
    index: 3,
    label: "Financials",
    tag: PROPERTY_TABS_V2.FINANCIALS,
  },
];

export const PROPERTY_DETAILS = [
  { label: "Listing Type", key: "tenure_types" },
  { label: "Property Type", key: "property_type" },
  { label: "Property Subtypes", key: "property_sub_types" },
  { label: "Property Size", key: "building_size" },
  { label: "Rent Price", key: "rent_price" },
  { label: "Sale Price", key: "sale_price" },
];

export const PROPERTY_PDF_DETAILS = [
  { label: "Property Size", key: "building_size" },
  { label: "Stories", key: "floors" },
  { label: "Year Built/Renovated", key: "year" },
  { label: "Opex", key: "opex" },
  { label: "Lease Rate", key: "rent_price" },
  { label: "Sale Price", key: "sale_price" },
  { label: "", key: "website" },
];

export type InitialValuesProps = {
  [key in keyof PropertyProps]?: any;
};

export const PROPERTY_INITIAL_VALUES: InitialValuesProps = {
  id: 0,
  title: "",
  address: "",
  city: "",
  post_code: "",
  state: "",
  property_sub_types: [],
  tenure_types: [],
  min_rent: "",
  min_sale: "",
  max_rent: "",
  building_class: "",
  surface_area_unit: "feet",
  min_surface_area: "",
  max_surface_area: "",
  wls: "",
  sub_market: "",
  year_built: "",
  year_renovated: "",
  floors: "",
  dirty: false,
  latitude: "",
  longitude: "",
  parking_ratio: "",
  parking_type: "",
  parking_cost_unreserved: "",
  parking_cost_reserved: "",
  market: "",
  property_status: "",
};

export const PARKING_TYPES: any = {
  1: { value: "Surface", sort: 1 },
  2: { value: "Garage", sort: 2 },
  3: { value: "Both", sort: 3 },
  4: { value: "N/A", sort: 4 },
};

export const DIRECT_AVAILABLE_SPACE_UNIT: any = {
  acres: { value: "Acres", sort: 3 },
  feet: { value: "Feet", sort: 1 },
  meters: { value: "Meters", sort: 2 },
};

export const MAX_CONTIGUOUS_SPACE_UNIT: any = {
  acres: { value: "Acres", sort: 3 },
  feet: { value: "Feet", sort: 1 },
  meters: { value: "Meters", sort: 2 },
};

export const OLM_PROPERTY_FIELDS = [
  "market",
  "property_sub_types",
  "wls",
  "descriptions",
  "latitude",
  "longitude",
  "address_area",
  "address",
  "city",
  "state",
  "post_code",
  "amenities",
  "amenity",
  "custom_amenity",
  "brokers",
  "broker",
  "highlights",
  "brochures",
  "floorplans",
  "images",
  "videos",
  "virtual_tours",
  "tenure_types",
  "type",
  "min_surface_area",
  "max_surface_area",
  "surface_area_unit",
  "min_rent",
  "max_rent",
  "min_sale",
  "max_sale",
  "hide_sales_price",
  "hide_availabilities_price",
  "title",
  "floors",
  "property_status",
];
