import { useContext, useEffect, useState } from "react";
import {
  ActivityChangeModal,
  ShowMoreItems,
  StyledActivityValue,
  Table,
} from "ui-molecules";
import queryString from "query-string";
import { Button, EmptyResult } from "ui-atoms";
import { LayoutEditAvailability as Layout } from "ui-organisms";
import { ACTIVITY_TABLE_COLUMNS, META_TAGS } from "constant";
import { getActivitiesAPI } from "services";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useApiCall } from "hooks";
import { GlobalContext } from "context";
import { FiRotateCcw } from "react-icons/fi";
import { ActivityProps, DirectionEnum } from "types";
import { getContactTypeId, joinTwoStr, pickSpecificKeyFromObj } from "utils";
import { Helmet } from "react-helmet-async";
import React from "react";

const AvailabilityActivity = () => {
  const { availabilityId } = useParams();
  const { state } = useContext(GlobalContext);
  const { meta, availability } = state;
  const location = useLocation();
  const navigate = useNavigate();
  const [fetchData, loading] = useApiCall(getActivitiesAPI);
  const [activities, setActivities] = useState<ActivityProps[]>([]);
  const [total, setTotal] = useState(0);
  const [sortFields, setSortFields] = useState<string[]>([]);
  const [page, setPage] = useState(1);
  const [paramFilters, setParamFilters] = useState<any>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [detailData, setDetailData] = useState({});

  useEffect(() => {
    let filters = queryString.parse(location?.search, {
      arrayFormat: "bracket",
    });
    setParamFilters(filters);
  }, [location]);

  useEffect(() => {
    if (!meta || !availabilityId || paramFilters === null) return;
    setPage(1);
    setActivities([]);
    fetchData({
      content_type: getContactTypeId(meta, "suite"),
      object_id: [availabilityId],
      page: 1,
      ...paramFilters,
    }).then((data: any) => {
      setActivities([...data?.docs]);
      setPage(data?.page);
      setTotal(data?.total);
      setSortFields(data?.sort_fields);
    });
  }, [meta, availabilityId, paramFilters]);

  const onClickShowMore = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    fetchData({
      page: nextPage,
      content_type: getContactTypeId(meta, "suite"),
      object_id: [availabilityId],
      ...paramFilters,
    }).then((data: any) => {
      setActivities((prevData) => [...prevData, ...data?.docs]);
      setPage(data?.page);
      setTotal(data?.total);
      setSortFields(data?.sort_fields);
    });
  };

  const onClickSorting = (sort: string) => {
    const updatedFilters = {
      ...paramFilters,
      sort,
      direction:
        paramFilters?.direction === DirectionEnum.asc
          ? DirectionEnum?.desc
          : DirectionEnum.asc,
    };
    navigate(
      `${location.pathname}?${queryString.stringify(updatedFilters, {
        arrayFormat: "bracket",
      })}`
    );
  };

  const onClickReset = () => {
    navigate(location.pathname);
  };

  const handleChangeLog = (activity: any) => {
    setIsOpen(true);
    setDetailData({
      ...pickSpecificKeyFromObj(activity, "change__"),
      standardized: activity?.standardized,
      object: activity?.object,
      update_timestamp: activity?.update_timestamp,
      first_name: activity?.first_name,
      last_name: activity?.last_name,
    });
  };

  return (
    <Layout>
      <Helmet prioritizeSeoTags>
        <title>{`${META_TAGS?.default?.title} ${joinTwoStr(
          availability?.building_title,
          availability?.name,
          " | "
        )} Activity`}</title>
        <meta
          property="og:title"
          content={`${META_TAGS?.default?.title} ${joinTwoStr(
            availability?.building_title,
            availability?.name,
            " | "
          )} Activity`}
        />
      </Helmet>
      <div id="items-container" className="min-h-full">
        <Layout.Section title="Activities">
          {!loading && !activities?.length && (
            <EmptyResult className="mt-8">
              <h3 className="text-heading3 text-jll-color-coldGray-5">
                No activity yet...
              </h3>
            </EmptyResult>
          )}
          {!!activities?.length && (
            <div>
              <div className="flex space-x-4 pb-2 justify-between items-center">
                <div className="font-semibold text-jll-color-coldGray-7 text-sm">
                  {total} result{total > 1 ? "s" : ""}
                </div>
                {paramFilters && !!Object.keys(paramFilters)?.length && (
                  <Button
                    variant="ghost"
                    size="small"
                    leadingIcon={FiRotateCcw}
                    onClick={onClickReset}
                  >
                    Reset
                  </Button>
                )}
              </div>
              <Table className="mt-2">
                <Table.Thead>
                  <Table.Tr>
                    {ACTIVITY_TABLE_COLUMNS.map((column, index) => {
                      return (
                        <Table.Th
                          key={index}
                          onClick={onClickSorting}
                          filters={paramFilters}
                          filterId={column.id}
                          sortFields={sortFields}
                        >
                          {column.label}
                        </Table.Th>
                      );
                    })}
                  </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                  {activities?.map((activity: any, contact_index: number) => {
                    return (
                      <Table.Tr
                        key={contact_index}
                        onClick={() => handleChangeLog(activity)}
                      >
                        {ACTIVITY_TABLE_COLUMNS.map((column, column_index) => {
                          return (
                            <Table.Td key={column_index}>
                              <StyledActivityValue
                                activity={activity}
                                valueKey={column.id}
                              />
                            </Table.Td>
                          );
                        })}
                      </Table.Tr>
                    );
                  })}
                </Table.Tbody>
              </Table>
              <ShowMoreItems
                onClick={onClickShowMore}
                total={total}
                isLoading={loading}
                itemsCount={activities?.length}
              />
            </div>
          )}
        </Layout.Section>
      </div>
      <ActivityChangeModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        data={detailData}
      />
    </Layout>
  );
};

export default React.memo(AvailabilityActivity);
