import axios from "../axiosConfig";
import { API_PREFIX_URL } from "constant";

export const postEmailAPI = async (payload: {
  to: string[];
  subject: string;
  body: string;
}) => {
  const res = await axios.post(`${API_PREFIX_URL}/email/`, payload);
  return res;
};
