import type { StyledTeamListingValueProps } from "ui-molecules";
export const FACET_MAX = 5;
export const SEARCH_RESULT_LIMIT = 10;

interface TdProps {
  className: string;
}

interface TableColumnProps {
  id: StyledTeamListingValueProps["valueKey"];
  label: string;
  sort?: string;
  td?: TdProps;
}

export const TABLE_COLUMNS: TableColumnProps[] = [
  {
    id: "name",
    label: "Set name",
    sort: "asc",
  },
  {
    id: "listing_count",
    label: "Property count",
  },
  {
    id: "market",
    label: "Market",
  },
  {
    id: "createdBy",
    label: "Created by",
  },
];
