import { FC, useContext } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import cn from "classnames";
import {
  BrokerProps,
  PropertyBrokerProps,
  AvailabilityBrokerProps,
} from "types";
import { capitalizeName, lazyLoadImageOnError } from "utils";
import { GlobalContext } from "context";

interface AvatarGroupProps {
  className?: string;
  brokers:
    | BrokerProps[]
    | PropertyBrokerProps[]
    | AvailabilityBrokerProps[]
    | any[];
  max?: number;
  uniqueId?: string | number;
  fontClassName?: string;
}

/**
 * Avatar Group
 */
const AvatarGroup: FC<AvatarGroupProps> = ({
  className,
  brokers,
  max = 5,
  uniqueId = "",
  fontClassName,
  ...props
}) => {
  const { state } = useContext(GlobalContext);
  const { showTooltip, hideTooltip } = state;
  const limit = max || brokers.length;

  return (
    <>
      <ul
        aria-label="Avatar group"
        className={cn("flex -space-x-2", className)}
      >
        {brokers.slice(0, max).map((item, index) => {
          return (
            <li
              key={index}
              id={`${uniqueId}_item_${index}_${item?.id}`}
              className="list-item cursor-pointer"
              data-tooltip-content={
                item?.name ||
                item?.get_full_name ||
                [item?.user_first_name, item?.user_last_name]?.join(" ") ||
                [
                  item?.updated_user_first_name,
                  item?.updated_user_last_name,
                ]?.join(" ")
              }
              onMouseOver={() =>
                showTooltip(`${uniqueId}_item_${index}_${item?.id}`)
              }
              onMouseLeave={() => hideTooltip()}
            >
              <div className="rounded-full border-2 border-white flex justify-center items-center">
                {!!item?.photo?.length || !!item?.broker?.photo ? (
                  <LazyLoadImage
                    className="inline-block h-7 w-7 rounded-full object-cover"
                    src={item?.photo || item?.broker?.photo}
                    onError={lazyLoadImageOnError}
                    {...props}
                  />
                ) : (
                  <div className="h-7 w-7 rounded-full flex justify-center items-center bg-jll-color-coldGray-3">
                    <span
                      className={cn("text-xs font-semibold", fontClassName)}
                    >
                      {capitalizeName(
                        item?.name ||
                          item?.get_full_name ||
                          [item?.user_first_name, item?.user_last_name]?.join(
                            " "
                          ) ||
                          [
                            item?.updated_user_first_name,
                            item?.updated_user_last_name,
                          ]?.join(" ") ||
                          ""
                      )}
                    </span>
                  </div>
                )}
              </div>
            </li>
          );
        })}
        {brokers.length - max > 0 && (
          <li className="list-item">
            <div className="rounded-full border-2 border-white">
              <div className="inline-block h-7 w-7 text-xs font-semibold rounded-full bg-jll-color-coldGray-4 flex items-center text-jll-color-coldGray-10 justify-center">
                +{brokers.length - max}
              </div>
            </div>
          </li>
        )}
      </ul>
    </>
  );
};

export default AvatarGroup;
