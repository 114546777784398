const Embed = require("@editorjs/embed");
const List = require("@editorjs/list");
const LinkTool = require("@editorjs/link");
const Raw = require("@editorjs/raw");
const Header = require('@editorjs/header');
const Quote = require("@editorjs/quote");
const Marker = require("@editorjs/marker");
const CheckList = require("@editorjs/checklist");
const Paragraph = require('@editorjs/paragraph');

export const EDITOR_JS_TOOLS = {
  embed: Embed,
  // paragraph: Paragraph,
  list: List,
  linkTool: LinkTool,
  // raw: Raw,
  // checklist: CheckList,
  header: Header,
  // quote: Quote,
  marker: Marker,
};
