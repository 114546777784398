import cn from "classnames";

interface LayoutListingContentProps {
  children: any;
  className?: string;
}

function LayoutListingContent({
  children,
  className,
}: LayoutListingContentProps) {
  return (
    <div
      className={cn(
        "absolute top-0 left-[300px] right-0 bottom-0 col-span-10 h-full overflow-y-auto",
        className
      )}
    >
      {children}
    </div>
  );
}

export default LayoutListingContent;
