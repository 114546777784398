export const emailPropertyBrochuresJSON = [
  {
    id: "lzzlo9ReNc",
    cells: [1],
    columns: [
      {
        id: "BMgsB88Hyz",
        contents: [
          {
            id: "z6MoEK3tpO",
            type: "heading",
            values: {
              containerPadding: "10px",
              anchor: "",
              headingType: "h4",
              fontWeight: 700,
              fontSize: "14px",
              textAlign: "left",
              lineHeight: "140%",
              linkStyle: {
                inherit: true,
                linkColor: "#0000ee",
                linkHoverColor: "#0000ee",
                linkUnderline: true,
                linkHoverUnderline: true,
              },
              hideDesktop: false,
              displayCondition: null,
              _meta: {
                htmlID: "u_content_heading_14",
                htmlClassNames: "u_content_heading",
              },
              selectable: true,
              draggable: true,
              duplicatable: true,
              deletable: true,
              hideable: true,
              text: "Property Brochures",
            },
          },
        ],
        values: {
          backgroundColor: "#f6f7f7",
          padding: "0px",
          border: {},
          borderRadius: "0px",
          _meta: { htmlID: "u_column_40", htmlClassNames: "u_column" },
        },
      },
    ],
    values: {
      displayCondition: null,
      columns: false,
      backgroundColor: "",
      columnsBackgroundColor: "",
      backgroundImage: {
        url: "",
        fullWidth: true,
        repeat: "no-repeat",
        size: "custom",
        position: "center",
      },
      padding: "0px",
      anchor: "",
      hideDesktop: false,
      _meta: { htmlID: "u_row_16", htmlClassNames: "u_row" },
      selectable: true,
      draggable: true,
      duplicatable: true,
      deletable: true,
      hideable: true,
    },
  },
  {
    id: "property_brochures",
    cells: [1, 1, 1, 1],
    columns: [
      {
        id: "IZDB_JLFX-",
        contents: [
          {
            id: "ik0UwBSevR",
            type: "image",
            values: {
              containerPadding: "10px",
              anchor: "",
              src: {
                url: "",
                width: 800,
                height: 200,
              },
              textAlign: "center",
              altText: "",
              action: {
                name: "web",
                attrs: { href: "{{href}}", target: "{{target}}" },
                values: { href: "", target: "_blank" },
              },
              hideDesktop: false,
              displayCondition: null,
              _meta: {
                htmlID: "u_content_image_15",
                htmlClassNames: "u_content_image",
              },
              selectable: true,
              draggable: true,
              duplicatable: true,
              deletable: true,
              hideable: true,
            },
          },
        ],
        values: {
          backgroundColor: "#f6f7f7",
          padding: "0px",
          border: {},
          borderRadius: "0px",
          _meta: { htmlID: "u_column_41", htmlClassNames: "u_column" },
        },
      },
      {
        id: "Q1IpztLL8c",
        contents: [
          {
            id: "3LmRjSBD_B",
            type: "image",
            values: {
              containerPadding: "10px",
              anchor: "",
              src: {
                url: "",
                width: 800,
                height: 200,
              },
              textAlign: "center",
              altText: "",
              action: {
                name: "web",
                attrs: { href: "{{href}}", target: "{{target}}" },
                values: { href: "", target: "_blank" },
              },
              hideDesktop: false,
              displayCondition: null,
              _meta: {
                htmlID: "u_content_image_16",
                htmlClassNames: "u_content_image",
              },
              selectable: true,
              draggable: true,
              duplicatable: true,
              deletable: true,
              hideable: true,
            },
          },
        ],
        values: {
          backgroundColor: "#f6f7f7",
          padding: "0px",
          border: {},
          borderRadius: "0px",
          _meta: { htmlID: "u_column_42", htmlClassNames: "u_column" },
        },
      },
      {
        id: "onGbvhazta",
        contents: [
          {
            id: "RrY0l1NBS9",
            type: "image",
            values: {
              containerPadding: "10px",
              anchor: "",
              src: {
                url: "",
                width: 800,
                height: 200,
              },
              textAlign: "center",
              altText: "",
              action: {
                name: "web",
                attrs: { href: "{{href}}", target: "{{target}}" },
                values: { href: "", target: "_blank" },
              },
              hideDesktop: false,
              displayCondition: null,
              _meta: {
                htmlID: "u_content_image_17",
                htmlClassNames: "u_content_image",
              },
              selectable: true,
              draggable: true,
              duplicatable: true,
              deletable: true,
              hideable: true,
            },
          },
        ],
        values: {
          backgroundColor: "#f6f7f7",
          padding: "0px",
          border: {},
          borderRadius: "0px",
          _meta: { htmlID: "u_column_43", htmlClassNames: "u_column" },
        },
      },
      {
        id: "zS9uCJ5Nta",
        contents: [
          {
            id: "bYvajVaTgA",
            type: "image",
            values: {
              containerPadding: "10px",
              anchor: "",
              src: {
                url: "",
                width: 800,
                height: 200,
              },
              textAlign: "center",
              altText: "",
              action: {
                name: "web",
                attrs: { href: "{{href}}", target: "{{target}}" },
                values: { href: "", target: "_blank" },
              },
              hideDesktop: false,
              displayCondition: null,
              _meta: {
                htmlID: "u_content_image_18",
                htmlClassNames: "u_content_image",
              },
              selectable: true,
              draggable: true,
              duplicatable: true,
              deletable: true,
              hideable: true,
            },
          },
        ],
        values: {
          backgroundColor: "#f6f7f7",
          padding: "0px",
          border: {},
          borderRadius: "0px",
          _meta: { htmlID: "u_column_44", htmlClassNames: "u_column" },
        },
      },
    ],
    values: {
      displayCondition: null,
      columns: false,
      backgroundColor: "",
      columnsBackgroundColor: "",
      backgroundImage: {
        url: "",
        fullWidth: true,
        repeat: "no-repeat",
        size: "custom",
        position: "center",
      },
      padding: "0px",
      anchor: "",
      hideDesktop: false,
      _meta: { htmlID: "u_row_17", htmlClassNames: "u_row" },
      selectable: true,
      draggable: true,
      duplicatable: true,
      deletable: true,
      hideable: true,
    },
  },
  {
    id: "wBJDmd1756",
    cells: [1],
    columns: [
      {
        id: "UCWEp5urKT",
        contents: [
          {
            id: "dkJSv0cERe",
            type: "divider",
            values: {
              width: "100%",
              border: {
                borderTopWidth: "1px",
                borderTopStyle: "solid",
                borderTopColor: "#BBBBBB",
              },
              textAlign: "center",
              containerPadding: "5px",
              anchor: "",
              hideDesktop: false,
              displayCondition: null,
              _meta: {
                htmlID: "u_content_divider_11",
                htmlClassNames: "u_content_divider",
              },
              selectable: true,
              draggable: true,
              duplicatable: true,
              deletable: true,
              hideable: true,
            },
          },
        ],
        values: {
          backgroundColor: "#f6f7f7",
          padding: "0px",
          border: {},
          borderRadius: "0px",
          _meta: { htmlID: "u_column_45", htmlClassNames: "u_column" },
        },
      },
    ],
    values: {
      displayCondition: null,
      columns: false,
      backgroundColor: "",
      columnsBackgroundColor: "",
      backgroundImage: {
        url: "",
        fullWidth: true,
        repeat: "no-repeat",
        size: "custom",
        position: "center",
      },
      padding: "0px",
      anchor: "",
      hideDesktop: false,
      _meta: { htmlID: "u_row_18", htmlClassNames: "u_row" },
      selectable: true,
      draggable: true,
      duplicatable: true,
      deletable: true,
      hideable: true,
    },
  },
];
