import { FaStreetView } from "react-icons/fa";
import { FiBookOpen, FiCheckCircle, FiFile } from "react-icons/fi";
import { PropertyProps } from "types";
import { Button, Label } from "ui-atoms";
import cn from "classnames";
import {
  StyledBrokerValue,
  StyledDeliverableValue,
  StyledPropertyValue,
  StyledPropertyValueProps,
} from "ui-molecules";
import { sliceIntoArray } from "utils";

const InfoFields: {
  label: string;
  value: StyledPropertyValueProps["valueKey"];
}[] = [
  { label: "Listing Type", value: "tenure_types" },
  { label: "Building Class", value: "building_class" },
  { label: "Total Rent-able Area", value: "building_size" },
  { label: "Typical Floor Plate Size", value: "max_surface_area" },
  { label: "Property Type", value: "property_type" },
  { label: "Property Sub Type", value: "property_sub_types" },
  { label: "Opex", value: "opex" },
  { label: "Max Contiguous Available", value: "max_contiguous" },
];

const ParkingFields: {
  label: string;
  value: StyledPropertyValueProps["valueKey"];
}[] = [
  { label: "Parking Type", value: "parking_type" },
  { label: "Parking Ratio", value: "parking_ratio" },
  { label: "Parking Cost", value: "parking_cost" },
];

const OccupancyFields: {
  label: string;
  value: StyledPropertyValueProps["valueKey"];
}[] = [
  { label: "Direct Available", value: "direct_available_space" },
  { label: "Direct % Leased", value: "direct_percentage_leased" },
  { label: "% Subleased", value: "percentage_subleased" },
  { label: "Large Block Available", value: "max_contiguous_space" },
];

interface IBuildingInformation {
  building?: PropertyProps;
}

const BuildingInformation: React.FC<IBuildingInformation> = ({ building }) => {
  const handleDownload = (urls: string[] | undefined) => {
    if (!urls?.length) return;
    urls?.forEach((url) => {
      window.open(url);
    });
  }

  return (
    <div className="flex flex-col w-full py-4">
      {(!!building?.virtual_tours?.length ||
        !!building?.videos?.length ||
        !!building?.brochures?.length) && (
        <div className="flex flex-row items-center space-x-8 md:mb-6 mb-4 justify-end">
          {!!building?.virtual_tours?.length && (
            <Button
              variant="neutral"
              className="text-jll-color-icon-info"
              trailingIcon={FaStreetView}
              onClick={() => handleDownload(building?.virtual_tours)}
            >
              Virtual Tour
            </Button>
          )}
          {!!building?.videos?.length && (
            <Button
              variant="neutral"
              className="text-jll-color-icon-info"
              trailingIcon={FiFile}
              onClick={() => handleDownload(building?.videos)}
            >
              Download Flyer
            </Button>
          )}
          {!!building?.brochures?.length && (
            <Button
              variant="neutral"
              className="text-jll-color-icon-info"
              trailingIcon={FiBookOpen}
              onClick={() => handleDownload(building?.brochures)}
            >
              Download Brochure
            </Button>
          )}
        </div>
      )}

      <ul className="w-full divide-jll-color-coldGray-3 flex flex-wrap flex-row text-sm text-gray-606 md:mb-6 mb-4">
        {sliceIntoArray(InfoFields, 2)?.map((item: any, index: number) => {
          return (
            <li
              key={index}
              className={cn("flex justify-between items-center py-3 w-full", {
                "md:w-3/6": true,
                "border-t md:border-t-0": index === 1,
                "border-t": index > 1,
                "md:border-t-0": index < 2,
                "md:pl-4 md:border-l": index % 2 !== 0,
                "md:pr-4": index % 2 === 0,
              })}
            >
              <div className="flex flex-wrap max-w-[50%] text-sm text-jll-color-text-base-subdued whitespace-normal">
                {item?.label || ""}
              </div>
              {!!item && (
                <div className="flex flex-wrap max-w-[50%] text-sm text-jll-color-text-base-subdued whitespace-normal font-semibold items-end">
                  <StyledPropertyValue
                    property={building}
                    valueKey={item.value}
                    variant="primary"
                  />
                </div>
              )}
            </li>
          );
        })}
      </ul>

      {(building?.parking_ratio ||
        building?.parking_type ||
        building?.parking_cost_reserved ||
        building?.parking_cost_unreserved) && (
        <div className="mb-2">
          <p className="text-lg font-semibold text-jll-color-text-base-default mb-2">
            Parking
          </p>
          <ul className="w-full divide-jll-color-coldGray-3 flex flex-wrap flex-row text-sm text-gray-606 md:mb-6 mb-4">
            {sliceIntoArray(ParkingFields, 2)?.map(
              (item: any, index: number) => {
                return (
                  <li
                    key={index}
                    className={cn(
                      "flex justify-between items-center py-3 w-full",
                      {
                        "md:w-3/6": true,
                        "border-t md:border-t-0": index === 1,
                        "border-t": index > 1,
                        "md:border-t-0": index < 2,
                        "md:pl-4 md:border-l": index % 2 !== 0,
                        "md:pr-4": index % 2 === 0,
                      }
                    )}
                  >
                    <div className="flex flex-wrap max-w-[50%] text-sm text-jll-color-text-base-subdued whitespace-normal">
                      {item?.label || ""}
                    </div>
                    {!!item && (
                      <div className="flex flex-wrap max-w-[50%] text-sm text-jll-color-text-base-subdued whitespace-normal font-semibold items-end">
                        {item?.value === "opex" ? (
                          <StyledDeliverableValue
                            building={building}
                            valueKey={item.value}
                          />
                        ) : (
                          <StyledPropertyValue
                            property={building}
                            valueKey={item.value}
                          />
                        )}
                      </div>
                    )}
                  </li>
                );
              }
            )}
          </ul>
        </div>
      )}

      {(building?.direct_available_space ||
        building?.direct_percentage_leased ||
        building?.percentage_subleased ||
        building?.max_contiguous_space) && (
        <div className="mb-2">
          <p className="text-lg font-semibold text-jll-color-text-base-default mb-2">
            Occupancy
          </p>
          <ul className="w-full divide-jll-color-coldGray-3 flex flex-wrap flex-row text-sm text-gray-606 md:mb-6 mb-4">
            {sliceIntoArray(OccupancyFields, 2)?.map(
              (item: any, index: number) => {
                return (
                  <li
                    key={index}
                    className={cn(
                      "flex justify-between items-center py-3 w-full",
                      {
                        "md:w-3/6": true,
                        "border-t md:border-t-0": index === 1,
                        "border-t": index > 1,
                        "md:border-t-0": index < 2,
                        "md:pl-4 md:border-l": index % 2 !== 0,
                        "md:pr-4": index % 2 === 0,
                      }
                    )}
                  >
                    <div className="flex flex-wrap max-w-[50%] text-sm text-jll-color-text-base-subdued whitespace-normal">
                      {item?.label || ""}
                    </div>
                    {!!item && (
                      <div className="flex flex-wrap max-w-[50%] text-sm text-jll-color-text-base-subdued whitespace-normal font-semibold items-end">
                        <StyledPropertyValue
                          property={building}
                          valueKey={item.value}
                        />
                      </div>
                    )}
                  </li>
                );
              }
            )}
          </ul>
        </div>
      )}

      {/* Amenities */}
      {!!building?.amenity_name?.length && (
        <div className="w-full border border-jll-color-coldGray-3 py-2 px-4 flex flex-wrap rounded-lg md:mb-6 mb-4">
          {building?.amenity_name?.map((amenity) => {
            return (
              <div
                key={`icon-amenity-${building?.pk}-label`}
                className="flex justify-center lg:w-3/12 md:w-4/12 w-6/12 py-2"
              >
                <div className="w-full text-center flex">
                  <div className="flex flex-row items-center text-sm text-jll-color-text-base-subdued truncate">
                    <FiCheckCircle className="mr-2 text-jll-color-icon-success" />
                    <span className="whitespace-normal text-left">
                      {amenity}
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}

      {/* Highlights */}
      {!!building?.highlights?.length && (
        <div className="w-full border border-jll-color-coldGray-3 py-2 px-4 flex flex-wrap rounded-lg md:mb-6 mb-4">
          {building?.highlights?.map((highlight, idx) => {
            return (
              <div
                key={idx}
                className="flex justify-center items-start md:w-6/12 w-full py-2"
              >
                <div className="w-full text-center flex">
                  <div className="flex flex-row items-center text-sm text-jll-color-text-base-subdued truncate">
                    <FiCheckCircle className="mr-2 text-jll-color-icon-success flex w-4 h-4" />
                    <span className="whitespace-normal text-left flex w-fit">
                      {highlight}
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}

      {/* Description */}
      {!!building?.descriptions?.length && (
        <div className="md:mb-6 mb-4">
          {building?.descriptions?.map((description, idx) => (
            <div
              className={cn("py-2", {
                "border-t border-t-jll-color-coldGray-3": idx > 0,
              })}
            >
              <p className="text-lg font-semibold text-jll-color-text-base-default mb-2">
                {description?.title}
              </p>
              <div
                className="text-sm text-jll-color-text-base-subdued whitespace-normal"
                dangerouslySetInnerHTML={{
                  __html: description?.content,
                }}
              ></div>
            </div>
          ))}
        </div>
      )}

      {/* Broker */}
      {!!building?.brokers?.length && (
        <div className="w-full border border-jll-color-coldGray-3 py-2 px-4 flex flex-wrap rounded-lg md:mb-6 mb-4">
          {building?.brokers?.map((broker: any, idx: number) => (
            <div className="lg:w-3/12 md:w-4/12 w-6/12 py-2" key={idx}>
              <StyledBrokerValue broker={broker} valueKey="info" />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default BuildingInformation;
