import Thead from "./Thead";
import Tbody from "./Tbody";
import Tr from "./Tr";
import Th from "./Th";
import Td from "./Td";
import cn from "classnames";
import { FiPlusCircle } from "react-icons/fi";

export interface TableProps
  extends React.TableHTMLAttributes<HTMLTableElement> {
  className?: string;
  children: any;
  isEdit?: boolean;
  handleColumn?: any;
}

function Table<T extends object>({
  children,
  className,
  isEdit,
  handleColumn,
}: TableProps) {
  return (
    <div className="flex flex-row w-fit min-w-full">
      <table className={cn("w-full border-collapse border-hidden", className)}>
        {children}
      </table>
      {isEdit && (
        <button
          className="rounded border border-dashed border-jll-color-coldGray-3 px-2 pt-4 text-jll-color-coldGray-3 space-y-1 flex flex-col items-center mx-3"
          onClick={handleColumn}
        >
          <FiPlusCircle className="w-4 h-4" />
          <p className="text-center text-sm font-semibold">
            Add
            <br />
            Column
          </p>
        </button>
      )}
    </div>
  );
}

Table.Thead = Thead;
Table.Th = Th;
Table.Tbody = Tbody;
Table.Tr = Tr;
Table.Td = Td;

export default Table;
