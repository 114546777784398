import { PropertyProps } from "types";
import * as Yup from "yup";

export type InitialValuesProps = {
  [key in keyof PropertyProps]?: any;
};

export const PROPERTY_INDUSTRIAL_INITIAL_VALUES: InitialValuesProps = {
  dock_high_doors: null,
  semi_dock_doors: null,
  grade_level_doors: null,
  three_phase_power: false,
  total_amperage: null,
  hvac_warehouse: false,
  office_percentage: null,
  outside_storage: false,
  trailer_parking_spaces: null,
  clear_height: null,
};

export const PROPERTY_INDUSTRIAL_VALIDATION_SCHEMA = Yup.object().shape({
  dock_high_doors: Yup.number()
    .optional()
    .nullable()
    .transform((_, val) => (val === Number(val) ? val : null)),
  semi_dock_doors: Yup.number()
    .optional()
    .nullable()
    .transform((_, val) => (val === Number(val) ? val : null)),
  grade_level_doors: Yup.number()
    .optional()
    .nullable()
    .transform((_, val) => (val === Number(val) ? val : null)),
  total_amperage: Yup.number()
    .optional()
    .nullable()
    .transform((_, val) => (val === Number(val) ? val : null)),
  office_percentage: Yup.number()
    .optional()
    .nullable()
    .transform((_, val) => (val === Number(val) ? val : null)),
  trailer_parking_spaces: Yup.number()
    .optional()
    .nullable()
    .transform((_, val) => (val === Number(val) ? val : null)),
  clear_height: Yup.number()
    .optional()
    .nullable()
    .transform((_, val) => (val === Number(val) ? val : null)),
});
