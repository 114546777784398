import { Page, View, Text, Image, Link } from "@react-pdf/renderer";
import {
  PAGE_ORIENTATION,
  PAGE_SIZE,
  PROPERTY_DETAILS,
  PROPERTY_PDF_DETAILS,
} from "constant";
import styles from "./styles";
import { PropertyProps } from "types";
import {
  capitalize,
  convertExtension,
  formatPhoneNumber,
  getDecimalFormating,
  getImageFromURL,
  removeNewlines,
  sliceIntoChunks,
} from "utils";
import LeftIcon from "assets/pdf/icon-left.png";
import LogoImg from "assets/pdf/logo.png";

interface IPageProperty {
  property?: PropertyProps;
  propertyMap?: string;
  meta?: any;
}

const getValue = (property: PropertyProps, key: any, meta: any) => {
  if (!key) return;
  switch (key) {
    case "tenure_types":
    case "property_type":
    case "property_sub_types":
      return (
        <View style={styles.detailChip}>
          {/* @ts-ignore */}
          <Text style={styles.detailTxt}>{property?.[key] || "N/A"}</Text>
        </View>
      );

    case "website":
      if (!property?.websites?.length) return <></>;
      return (
        <View>
          {/* @ts-ignore */}
          <Link src={property?.websites[0]} style={styles.detailLink}>
            <Text>Property Website</Text>
            <Image src={LeftIcon} style={{ width: 10, height: 10 }} />
          </Link>
        </View>
      );

    case "opex":
      if (!property?.opex?.length) return "N/A";
      const current_year = new Date().getFullYear();
      const opex_data = [...property?.opex];
      opex_data.sort((a, b) =>
        a?.year > b?.year ? 1 : b.year > a?.year ? -1 : 0
      );
      const current_year_opex = opex_data.find(
        (item) => item?.year === current_year
      );
      if (!!current_year_opex) {
        return (
          <View style={styles.detailColView}>
            <Text style={styles.detailTxt}>{`$${getDecimalFormating(
              current_year_opex?.operating_expenses
            )} `}</Text>
            <Text style={styles.detailNormalTxt}>{`${
              current_year_opex?.utility_type
                ? `($${getDecimalFormating(
                    current_year_opex?.utility_rate
                  )} for ${
                    meta?.opex?.utility_type?.[current_year_opex?.utility_type]
                      ?.value
                  })`
                : ""
            }`}</Text>
          </View>
        );
      } else {
        const opex_item = opex_data?.[opex_data?.length - 1];
        return (
          <View style={styles.detailColView}>
            <Text style={styles.detailTxt}>{`$${getDecimalFormating(
              opex_item?.operating_expenses
            )} `}</Text>
            <Text style={styles.detailNormalTxt}>{`(${
              opex_item?.utility_type
                ? `$${getDecimalFormating(opex_item?.utility_rate)} for ${
                    meta?.opex?.utility_type?.[opex_item?.utility_type]?.value
                  }`
                : ""
            }, ${opex_item?.year})`}</Text>
          </View>
        );
      }

    case "building_size":
      if (!property?.min_surface_area && !property?.max_surface_area) {
        return <Text style={styles.detailTxt}>-</Text>;
      }
      if (property?.min_surface_area && !property?.max_surface_area) {
        return (
          <View style={styles.detailColView}>
            <Text style={styles.detailTxt}>
              {getDecimalFormating(property?.min_surface_area)}{" "}
            </Text>
            <Text style={styles.detailUnit}>{property?.surface_area_unit}</Text>
          </View>
        );
      }
      if (!property?.min_surface_area && property?.max_surface_area) {
        return (
          <View style={styles.detailColView}>
            <Text style={styles.detailTxt}>
              {getDecimalFormating(property?.max_surface_area)}{" "}
            </Text>
            <Text style={styles.detailUnit}>{property?.surface_area_unit}</Text>
          </View>
        );
      }
      if (property?.min_surface_area === property?.max_surface_area) {
        return (
          <View style={styles.detailColView}>
            <Text style={styles.detailTxt}>
              {getDecimalFormating(property?.max_surface_area)}{" "}
            </Text>
            <Text style={styles.detailUnit}>{property?.surface_area_unit}</Text>
          </View>
        );
      }
      return (
        <View style={styles.detailColView}>
          <Text style={styles.detailTxt}>
            {getDecimalFormating(property?.min_surface_area)}-
            {getDecimalFormating(property?.max_surface_area)}{" "}
          </Text>
          <Text style={styles.detailUnit}>{property?.surface_area_unit}</Text>
        </View>
      );

    case "year":
      if (!!property?.year_built && !!property?.year_renovated)
        return (
          <Text style={styles.detailTxt}>
            {property?.year_built}/{property?.year_renovated}
          </Text>
        );
      if (!!property?.year_built)
        return <Text style={styles.detailTxt}>{property?.year_built}/-</Text>;
      if (!!property?.year_renovated)
        return (
          <Text style={styles.detailTxt}>-/{property?.year_renovated}</Text>
        );
      return <Text style={styles.detailTxt}>N/A</Text>;

    case "rent_price":
      if (!property?.min_rent && !property?.max_rent) {
        return <Text style={styles.detailTxt}>N/A</Text>;
      }
      if (property?.min_rent && !property?.max_rent) {
        return (
          <View style={styles.detailColView}>
            <Text style={styles.detailTxt}>
              ${getDecimalFormating(property?.min_rent?.toFixed(2))}
            </Text>
            <Text style={styles.detailUnit}>
              {" "}
              per {property?.surface_area_unit}
            </Text>
          </View>
        );
      }
      if (!property?.min_rent && property?.max_rent) {
        return (
          <View style={styles.detailColView}>
            <Text style={styles.detailTxt}>
              ${getDecimalFormating(property?.max_rent?.toFixed(2))}
            </Text>
            <Text style={styles.detailUnit}>
              {" "}
              per {property?.surface_area_unit}
            </Text>
          </View>
        );
      }
      return (
        <View style={styles.detailColView}>
          <Text style={styles.detailTxt}>
            {`$${getDecimalFormating(
              property?.min_rent?.toFixed(2)
            )}-$${getDecimalFormating(property?.max_rent?.toFixed(2))}`}
          </Text>
          <Text style={styles.detailUnit}>
            {" "}
            per {property?.surface_area_unit}
          </Text>
        </View>
      );

    case "sale_price":
      if (!property?.min_sale && !property?.max_sale) {
        return <Text style={styles.detailTxt}>N/A</Text>;
      }
      if (property?.min_sale && !property?.max_sale) {
        return (
          <Text style={styles.detailTxt}>
            ${getDecimalFormating(property?.min_rent?.toFixed(2))}
          </Text>
        );
      }
      if (!property?.min_sale && property?.max_sale) {
        return (
          <Text style={styles.detailTxt}>
            ${getDecimalFormating(property?.max_sale?.toFixed(2))}
          </Text>
        );
      }
      return (
        <Text style={styles.detailTxt}>
          {`$${getDecimalFormating(property?.min_sale?.toFixed(2))} -
            $${getDecimalFormating(property?.max_sale?.toFixed(2))}`}
        </Text>
      );

    default:
      if (property?.hasOwnProperty(key)) {
        // @ts-ignore
        return <Text style={styles.detailTxt}>{property[key] || "N/A"}</Text>;
      }
      return <Text style={styles.detailTxt}>N/A</Text>;
  }
};

const PageProperty = ({ property, propertyMap, meta }: IPageProperty) => {
  let property_images: string[] = [];
  let property_width: number = 0;
  if (property?.images && property?.images?.length > 6) {
    property_images = [...property?.images].slice(1, 6);
    property_width = Number(
      (
        (596 - (property_images.length - 1) * 10) /
        property_images?.length
      ).toFixed(2)
    );
  }

  const detail_columns = [...PROPERTY_PDF_DETAILS];

  if (!property?.floors) {
    const index = detail_columns.findIndex((column) => column.key === "floors");
    detail_columns.splice(index, 1);
  }

  if (!property?.year_built && !property?.year_renovated) {
    const index = detail_columns.findIndex((column) => column.key === "year");
    detail_columns.splice(index, 1);
  }

  if (!property?.opex?.length) {
    const index = detail_columns.findIndex((column) => column.key === "opex");
    detail_columns.splice(index, 1);
  }

  if (!property?.min_rent && !property?.max_rent) {
    const index = detail_columns.findIndex(
      (column) => column.key === "rent_price"
    );
    detail_columns.splice(index, 1);
  }

  if (!property?.min_sale && !property?.max_sale) {
    const index = detail_columns.findIndex(
      (column) => column.key === "sale_price"
    );
    detail_columns.splice(index, 1);
  }

  if (!property?.websites?.length) {
    const index = detail_columns.findIndex(
      (column) => column.key === "website"
    );
    detail_columns.splice(index, 1);
  }

  if (detail_columns?.length > 6) {
    const index = detail_columns.findIndex((column) => column.key === "floors");
    detail_columns.splice(index, 1);
  }

  return (
    <Page
      size={PAGE_SIZE}
      orientation={PAGE_ORIENTATION.LANDSCAPE}
      style={styles.page}
      wrap={false}
    >
      {/* Left Side */}
      <View style={styles.leftSideView}>
        {/* Info Section */}
        <View style={styles.infoView}>
          {!!property?.images?.length && (
            <Image
              src={getImageFromURL(property?.images[0])}
              style={styles.leftImg}
            />
          )}
          <View style={styles.titleView}>
            <Text style={styles.id}>ID {property?.pk || property?.id}</Text>
            <Text style={styles.titleTxt}>{property?.title}</Text>
          </View>
          <View style={styles.addressView}>
            <Text style={styles.address}>{property?.address}</Text>
            <Text style={styles.address}>
              {[
                property?.city,
                [property?.state, property?.post_code].join(" "),
              ].join(", ")}
            </Text>
          </View>
          <View style={{ display: "flex", flexDirection: "row" }}>
            <Text style={styles.typeTxt}>
              {capitalize(property?.property_type)}
            </Text>
            {!!property?.tenure_types?.length && (
              <Text style={styles.tenantTypeTxt}>
                {property?.tenure_types?.length === 1
                  ? property?.tenure_types[0]?.toLowerCase() === "rent"
                    ? "Lease"
                    : capitalize(property?.tenure_types[0])
                  : "Lease/Sale"}
              </Text>
            )}
          </View>
        </View>
        {/* Location Section */}
        <View style={styles.location.view}>
          <Text style={styles.location.title}>Location</Text>
          <Image
            src={getImageFromURL(propertyMap)}
            style={styles.location.img}
          />
        </View>
      </View>

      {/* Right Side  */}
      <View style={styles.rightSideView}>
        {!!property_images?.length && (
          <View style={styles.propertyImgs}>
            {property_images?.map((image: string, idx: number) => (
              <Image
                src={getImageFromURL(convertExtension(image))}
                style={{
                  ...styles.propertyImg,
                  width: property_width,
                  marginRight: idx === property_images?.length - 1 ? 0 : 10,
                }}
              />
            ))}
          </View>
        )}

        <View style={styles.detailView}>
          {sliceIntoChunks(detail_columns, 3).map((detail: any, idx) => (
            <View
              style={{
                ...styles.detailRow,
                ...{ borderTopWidth: idx === 0 ? 0 : 1 },
              }}
            >
              {detail?.map((info: any, idx1: number) => (
                <View
                  style={{
                    ...styles.detailCol,
                    ...{
                      paddingLeft: idx1 === 0 ? 0 : 16,
                      paddingRight: idx1 === detail.length - 1 ? 0 : 16,
                      borderLeftWidth: idx1 === 0 ? 0 : 1,
                    },
                  }}
                >
                  <Text style={styles.detailLabel}>{info?.label}</Text>
                  {property && getValue(property, info?.key, meta)}
                </View>
              ))}
            </View>
          ))}
        </View>

        {!!property?.descriptions?.length && (
          <View style={{ ...styles.sectionView, marginBottom: 16 }}>
            <Text style={styles.title}>Descriptions</Text>
            <View style={styles.descriptionPanel} wrap={true}>
              <View style={styles.descriptionView}>
                {property?.descriptions?.map((description, idx) => {
                  const content = description?.content
                    ?.toString()
                    ?.replaceAll("<p>", "")
                    .replaceAll("</p>", "");
                  return (
                    <View
                      style={{
                        ...styles.descriptionItem,
                        ...{ borderTopWidth: idx === 0 ? 0 : 1 },
                      }}
                      wrap={true}
                    >
                      <Text style={styles.descriptionTitle}>
                        {description?.title || ""}
                      </Text>
                      <Text style={styles.descriptionContent}>{content}</Text>
                    </View>
                  );
                })}
              </View>
            </View>
          </View>
        )}

        {(!!property?.amenities?.length || !!property?.highlights?.length) && (
          <View style={styles.sectionView}>
            <Text style={styles.title}>
              {!!property?.amenities?.length && !!property?.highlights?.length
                ? "Amenity and Highlight"
                : !!property?.amenities?.length
                ? "Amenity"
                : "Highlight"}
            </Text>

            {!!property?.amenities?.length && (
              <View style={{ marginBottom: 16 }}>
                {sliceIntoChunks(property?.amenities, 5).map(
                  (detail: any, idx) => {
                    return (
                      <View
                        style={{
                          ...styles.detailRow,
                          ...{ borderTopWidth: idx === 0 || !detail ? 0 : 1 },
                        }}
                      >
                        {detail?.map((info: any, idx1: number) => (
                          <View
                            style={{
                              ...styles.detailCol,
                              ...{
                                paddingLeft: idx1 === 0 ? 0 : 16,
                                paddingRight:
                                  idx1 === detail.length - 1 ? 0 : 16,
                                borderLeftWidth: idx1 === 0 || !info ? 0 : 1,
                              },
                            }}
                          >
                            <Text style={styles.amenityTxt}>
                              {info?.amenity_name || info?.custom_amenity}
                            </Text>
                          </View>
                        ))}
                      </View>
                    );
                  }
                )}
              </View>
            )}

            {!!property?.highlights?.length && (
              <View style={styles.highlightPanel}>
                {sliceIntoChunks(property?.highlights, 2).map(
                  (detail: any, idx) => (
                    <View style={styles.highlightRow}>
                      {detail?.map((info: any, idx1: number) => (
                        <View
                          style={{
                            ...styles.highlightCol,
                            ...{
                              marginLeft: idx1 === 1 ? 9 : 0,
                              marginRight: idx1 === 0 ? 9 : 0,
                              borderTopWidth: idx === 0 ? 0 : 1,
                            },
                          }}
                        >
                          <Text style={styles.highlightTxt}>{info}</Text>
                        </View>
                      ))}
                    </View>
                  )
                )}
              </View>
            )}
          </View>
        )}

        {!!property?.brokers?.length && (
          <View style={styles.brokerPanel}>
            {property?.brokers.slice(0, 4)?.map((item, idx) => (
              <View
                style={{
                  ...styles.brokerView,
                  ...{ borderLeftWidth: idx === 0 ? 0 : 1 },
                }}
              >
                <Image
                  src={getImageFromURL(item?.photo)}
                  style={styles.brokerImg}
                />
                <View style={styles.brokerContent}>
                  <Text style={styles.brokerName}>
                    {removeNewlines(item?.name)}
                  </Text>
                  <Text style={styles.brokerTxt}>{item?.email}</Text>
                  <Text style={styles.brokerTxt}>
                    {formatPhoneNumber(item?.telephone)}
                  </Text>
                </View>
              </View>
            ))}
          </View>
        )}
      </View>

      <View style={styles.logo.view}>
        <Image src={LogoImg} style={styles.logo.img} />
      </View>
    </Page>
  );
};

export default PageProperty;
