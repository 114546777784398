import { OpexModal, Table } from "ui-molecules";
import { OPEX_TABLE_COLUMNS } from "./constants";
import { Button, Loading } from "ui-atoms";
import { FiPlus } from "react-icons/fi";
import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "context";
import { OpexProps } from "types";
import { StyledOpexValue } from "ui-molecules";
import { useApiCall } from "hooks";
import { deleteOpexAPI, getOpexAPI } from "services";
import { useParams } from "react-router-dom";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { SET_LOADING_MODAL } from "constant";

const PropertyOpexSection = () => {
  const { dispatch } = useContext(GlobalContext);
  const { propertyId } = useParams();
  const [getOpex] = useApiCall(getOpexAPI);
  const [deleteOpex] = useApiCall(deleteOpexAPI);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOpex, setSelectedOpex] = useState<any>(null);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState<any>();

  useEffect(() => {
    if (!propertyId) return;
    const payload = {
      buildings: [propertyId],
      page: 1,
    };
    getOpex(payload).then((res: any) => {
      setData(res?.docs);
      setPage(res?.page);
      setTotal(res?.total);
    });
  }, [propertyId]);

  const loadMore = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    const payload = {
      buildings: [propertyId],
    };
    getOpex({
      page: nextPage,
      ...payload,
    }).then((res: any) => {
      setData((prevData: any) => [...prevData, ...res?.docs]);
      setPage(res?.page);
      setTotal(res?.total);
    });
  };

  const [sentryRef] = useInfiniteScroll({
    loading: false,
    hasNextPage: total > data?.length,
    onLoadMore: loadMore,
  });

  const handleOpex = (
    newOpexData: OpexProps,
    pk: number | undefined = undefined
  ) => {
    if (!!pk) {
      const index = data?.findIndex(
        (opex: OpexProps) => opex?.pk === pk || opex?.id === pk
      );
      if (index > -1) {
        const newData = [...data];
        newData[index] = newOpexData;
        setData(newData);
      }
    } else {
      const newData = [...data];
      newData.push(newOpexData);
      setData(newData);
    }
  };

  const handleDelete = async (opexId: number | string) => {
    try {
      dispatch({
        type: SET_LOADING_MODAL,
        payload: {
          open: true,
          label: `Deleting the OPEX`,
        },
      });
      const res = await deleteOpex(opexId);
      setTimeout(() => {
        dispatch({
          type: SET_LOADING_MODAL,
          payload: {
            open: false,
            label: null,
          },
        });
      }, 100);
      const index = data?.findIndex(
        (opex: OpexProps) => opex?.pk === opexId || opex?.id === opexId
      );
      if (index > -1) {
        const newData = [...data];
        newData.splice(index, 1);
        setTotal(total - 1);
        setData(newData);
      }
    } catch (err) {
      setTimeout(() => {
        dispatch({
          type: SET_LOADING_MODAL,
          payload: {
            open: false,
            label: null,
          },
        });
      }, 100);
    }
  };

  return (
    <div className="flex flex-col w-full">
      {!!data?.length && (
        <Table className="mb-4">
          <Table.Thead>
            <Table.Tr>
              {OPEX_TABLE_COLUMNS.map((column, index) => {
                return (
                  <Table.Th key={index} filterId={column.id}>
                    {column.label}
                  </Table.Th>
                );
              })}
              <Table.Th />
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {data?.map((opex: OpexProps, idx: number) => (
              <Table.Tr key={idx}>
                {OPEX_TABLE_COLUMNS.map((column, index) => (
                  <Table.Td key={index}>
                    <StyledOpexValue opex={opex} valueKey={column?.id} />
                  </Table.Td>
                ))}
                <Table.Td className="space-x-2 text-right">
                  <Button
                    variant="secondary"
                    size="small"
                    onClick={() => {
                      setIsOpen(true);
                      setSelectedOpex(opex);
                    }}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="secondary"
                    size="small"
                    onClick={() => handleDelete(opex?.pk || opex?.id)}
                  >
                    Remove
                  </Button>
                </Table.Td>
              </Table.Tr>
            ))}
          </Table.Tbody>
        </Table>
      )}
      {!!(total > data?.length) && (
        <div
          className="w-full py-2 flex justify-center items-center"
          ref={sentryRef}
        >
          <Loading />
        </div>
      )}

      <Button
        className="w-fit"
        variant="secondary"
        leadingIcon={FiPlus}
        onClick={() => {
          setIsOpen(true);
          setSelectedOpex(null);
        }}
      >
        Add OPEX
      </Button>

      <OpexModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        opexData={selectedOpex}
        handleOpex={handleOpex}
      />
    </div>
  );
};

export default PropertyOpexSection;
