import { default as ReactSelect, StylesConfig } from "react-select";
import CreatableSelect from "react-select/creatable";
import type { CreatableProps } from "react-select/creatable";
import cn from "classnames";
import Chip from "ui-atoms/Chip";
import Label from "ui-atoms/Label";
import { OptionProps } from "types";

interface SelectCreatetableProps {
  options: OptionProps[];
  isMulti?: boolean;
  label?: string;
  error?: any;
  optional?: boolean;
  placeholder?: string;
  className?: string;
  name: string;
  // defaultValue?: OptionProps[] | OptionProps | undefined;
  onChange?: (e: any) => void;
  onBlur?: (e: any) => void;
  value?: OptionProps;
}

const colourStyles: any = {
  control: (base: any) => ({
    ...base,
    "&:hover": { borderColor: "gray" }, // border style on hover
    border: "1px solid lightgray", // default border color
    boxShadow: "none", // no box-shadow
  }),
  menuPortal: (base: any) => ({ ...base, zIndex: 9999 })
};

const SelectCreatetable: React.FC<SelectCreatetableProps> = ({
  label,
  error,
  optional,
  options,
  placeholder,
  className,
  name,
  isMulti = false,
  ...props
}) => {
  return (
    <div className={cn("relative mb-6", className)}>
      {(label || optional) && (
        <div className="flex flex-row items-center">
          {label ? <Label className="mr-2">{label}</Label> : <span></span>}
          {optional && (
            <Chip
              variant="secondary"
              size="small"
              className="py-1 text-jll-color-coldGray-7"
            >
              Optional
            </Chip>
          )}
        </div>
      )}
      <div className={cn({ "mt-2": !!label })}>
        <CreatableSelect
          options={options}
          isMulti={isMulti}
          placeholder={placeholder}
          isClearable
          className={cn({
            error: !!error,
          })}
          styles={colourStyles}
          menuPortalTarget={document.body}
          {...props}
        />
      </div>
      {error && (
        <p className="absolute -bottom-5 text-xs text-jll-color-surface-accent-default">
          {error}
        </p>
      )}
    </div>
  );
};

export default SelectCreatetable;
