import { LazyLoadImage } from "react-lazy-load-image-component";
import { PropertyProps, TeamListingProps } from "types";
import { getDecimalFormating } from "utils";
import NoImage from "assets/noImage.png";
import { Chip, Pointer } from "ui-atoms";
import ImageGroup from "ui-molecules/ImageGroup";

export interface StyledDeliverableValueProps {
  building?: PropertyProps;
  valueKey: keyof PropertyProps | "name" | "size" | "rent_price";
  index?: number;
}

const StyledDeliverableValue: React.FC<StyledDeliverableValueProps> = ({
  building,
  valueKey,
  index,
}) => {
  return (
    <>
      {(() => {
        switch (valueKey) {
          case "name":
            return (
              <div className="flex flex-row items-center space-x-2">
                <div className="flex flex-row items-center">
                  <LazyLoadImage
                    className="w-10 h-10 rounded"
                    src={
                      !!building?.images?.length ? building?.images[0] : NoImage
                    }
                  />
                  <Pointer
                    index={index}
                    outClassName="!w-8 !h-8 ml-[-16px]"
                    inClassName="w-6 h-6"
                  />
                </div>
                <div className="flex flex-col truncate flex-wrap w-[calc(100%-64px)]">
                  <p className="text-jll-color-text-base-default font-semibold whitespace-normal max-w-[150px]">
                    {building?.title}
                  </p>
                  <p className="text-jll-color-text-base-subdued text-sm whitespace-normal truncate">
                    {[
                      building?.address,
                      building?.city,
                      building?.state,
                      building?.post_code,
                    ].join(", ")}
                  </p>
                </div>
              </div>
            );

          case "type":
            if (!building?.property_type) {
              return <>-</>;
            }
            return (
              <Chip size="small" variant="secondary" className="m-1">
                {building?.property_type}
              </Chip>
            );

          case "opex":
            if (building?.opex?.length) {
              const current_year = new Date().getFullYear();
              const opex_data = [...building?.opex];
              opex_data.sort((a, b) =>
                a?.year > b?.year ? 1 : b.year > a?.year ? -1 : 0
              );
              const current_year_opex = opex_data.find(
                (item) => item?.year === current_year
              );
              if (!!current_year_opex) {
                if (!current_year_opex?.operating_expenses) return <>-</>;
                return (
                  <>
                    $
                    {getDecimalFormating(
                      current_year_opex?.operating_expenses?.toFixed(2)
                    )}
                  </>
                );
              } else {
                const opex_item = opex_data?.[opex_data?.length - 1];
                if (!opex_item?.operating_expenses) return <>-</>;
                return (
                  <>
                    $
                    {getDecimalFormating(
                      opex_item?.operating_expenses?.toFixed(2)
                    )}
                  </>
                );
              }
            } else if (building?.operating_expenses) {
              if (!building?.operating_expenses) return <>-</>;
              return (
                <>
                  $
                  {getDecimalFormating(building?.operating_expenses.toFixed(2))}
                  {building?.opex_utility_type_name &&
                  building?.opex_utility_rate ? (
                    <span className="text-xs text-jll-color-coldGray-6 pl-1">
                      + ${Number(building?.opex_utility_rate).toFixed(2)}{" "}
                      {building?.opex_utility_type_name}
                    </span>
                  ) : (
                    ""
                  )}
                </>
              );
            }

            return <>-</>;

          case "size":
            return (
              <>
                {(() => {
                  if (
                    !building?.min_surface_area &&
                    !building?.max_surface_area
                  ) {
                    return <>-</>;
                  }
                  if (
                    building?.min_surface_area &&
                    !building?.max_surface_area
                  ) {
                    return (
                      <>
                        {getDecimalFormating(building?.min_surface_area)}{" "}
                        <span className="text-xs text-jll-color-coldGray-6">
                          {building?.surface_area_unit}
                        </span>
                      </>
                    );
                  }
                  if (
                    !building?.min_surface_area &&
                    building?.max_surface_area
                  ) {
                    return (
                      <>
                        {getDecimalFormating(building?.max_surface_area)}{" "}
                        <span className="text-xs text-jll-color-coldGray-6">
                          {building?.surface_area_unit}
                        </span>
                      </>
                    );
                  }
                  if (
                    building?.min_surface_area === building?.max_surface_area
                  ) {
                    return (
                      <>
                        {getDecimalFormating(building?.max_surface_area)}{" "}
                        <span className="text-xs text-jll-color-coldGray-6">
                          {building?.surface_area_unit}
                        </span>
                      </>
                    );
                  }
                  return (
                    <>
                      {getDecimalFormating(building?.min_surface_area)}-
                      {getDecimalFormating(building?.max_surface_area)}{" "}
                      <span className="text-xs text-jll-color-coldGray-6">
                        {building?.surface_area_unit}
                      </span>
                    </>
                  );
                })()}
              </>
            );

          case "max_space_available":
            return (
              <>
                {getDecimalFormating(building?.max_space_available)}{" "}
                <span className="text-xs text-jll-color-coldGray-6">feet</span>
              </>
            );

          case "rent_price":
            if (!building?.min_rent && !building?.max_rent) {
              return <>-</>;
            }
            if (
              (building?.min_rent && !building?.max_rent) ||
              building?.min_rent == building?.max_rent
            ) {
              return (
                <>
                  ${Number(getDecimalFormating(building?.min_rent)).toFixed(2)}{" "}
                </>
              );
            }
            if (!building?.min_rent && building?.max_rent) {
              return (
                <>
                  ${Number(getDecimalFormating(building?.max_rent)).toFixed(2)}{" "}
                </>
              );
            }
            return (
              <>
                {`$${Number(getDecimalFormating(building?.min_rent)).toFixed(
                  2
                )} -
                $${Number(getDecimalFormating(building?.max_rent)).toFixed(2)}`}
              </>
            );

          case "brochures":
            if (!building?.[valueKey]?.length) return <>-</>;
            return (
              <div
                onClick={(e) => {
                  e?.stopPropagation();
                  window.open((building?.[valueKey] as string[])[0], "_blank");
                }}
              >
                <ImageGroup
                  images={building?.[valueKey] as string[]}
                  max={3}
                  className="w-max"
                />
              </div>
            );

          default:
            if (building?.hasOwnProperty(valueKey)) {
              return <>{building[valueKey]}</>;
            }
            return <>-</>;
        }
      })()}
    </>
  );
};

export default StyledDeliverableValue;
