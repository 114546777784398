import type { FC } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import cn from "classnames";
import { capitalizeName, lazyLoadImageOnError } from "utils";

interface IAvatarProps {
  className?: string;
  size?: "small" | "default" | "large";
  src: string;
  alt?: string;
  name?: string;
}

/**
 * Avatar
 * @param src example: https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80
 */
const Avatar: FC<IAvatarProps> = ({
  className,
  size = "default",
  src,
  name,
  ...props
}) => {
  let variantClassName = "flex-none";
  let fontClassName = "font-semibold";
  if (size === "small") {
    variantClassName = variantClassName + " h-7 w-7";
    fontClassName += " text-sm";
  } else if (size === "default") {
    variantClassName = variantClassName + " h-10 w-10";
    fontClassName += " text-base";
  } else if (size === "large") {
    variantClassName = variantClassName + " h-14 w-14";
    fontClassName += " text-lg";
  }

  return (
    <div className={cn(variantClassName, className)}>
      {!!src?.length ? (
        <LazyLoadImage
          className="h-full w-full rounded-full object-cover"
          src={src}
          onError={lazyLoadImageOnError}
          {...props}
        />
      ) : (
        <div className="h-full w-full rounded-full flex justify-center items-center bg-jll-color-coldGray-3">
          <span className={fontClassName}>{capitalizeName(name)}</span>
        </div>
      )}
    </div>
  );
};

export default Avatar;
