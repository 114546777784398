import { forwardRef, useContext, useImperativeHandle } from "react";
import type { FC, FocusEvent } from "react";
import type { FormikTouched, FormikValues, FormikErrors } from "formik";
import { FiPlus, FiTrash } from "react-icons/fi";
import { Button, SelectCreatetable } from "ui-atoms";
import { GlobalContext } from "context";
import { OptionProps, AmenityProps } from "types";
import cn from 'classnames';
import React from "react";

interface PropertyAmenitiesProps {
  label: string;
  onChange: (value: AmenityProps[]) => void;
  onBlur: (e: FocusEvent<HTMLInputElement, Element>) => void;
  name: string;
  value?: AmenityProps[];
  touched?: FormikTouched<FormikValues>;
  errors?: FormikErrors<FormikValues>;
  isModal?: boolean;
}

const PropertyAmenities = forwardRef(
  (
    {
      label,
      name = "",
      onChange,
      onBlur,
      value = [],
      errors,
      touched,
      isModal,
    }: PropertyAmenitiesProps,
    ref
  ) => {
    const { state } = useContext(GlobalContext);
    const { meta } = state;

    const handleAdd = () => {
      onChange([...value, {}]);
    };

    const handleRemove = (index: number) => {
      let updatedValue: AmenityProps[] = [...value].filter(
        (_, i) => i !== index
      );
      onChange(updatedValue);
    };

    const handleUpdate = (index: number, newValue: OptionProps) => {
      let updatedItems = [...value].map(
        (amenity: AmenityProps, amenity_index: number) => {
          if (amenity_index === index) {
            if (newValue?.value === newValue?.label) {
              // Custom amenity
              return {
                custom_amenity: newValue?.label,
              };
            }
            // Amenity selected from meta
            return {
              id: Number(newValue?.value),
              amenity_name: newValue?.label,
            };
          }
          return amenity;
        }
      );
      onChange(updatedItems);
    };

    useImperativeHandle(ref, () => ({
      handleAdd,
    }));

    return (
      <div className="w-full">
        <div className={cn({
          'mb-6': !isModal
        })}>
          {!isModal && (
            <p className="text-jll-color-coldGray-7 text-sm mb-6">
              Select amenities from the list, or type your custom amenity in the
              text input.
            </p>
          )}

          {value?.map((amenity: AmenityProps, index: number) => {
            let value = undefined;
            if (amenity?.amenity || amenity?.custom_amenity) {
              value = {
                value: amenity?.id,
                label:
                  amenity?.custom_amenity ||
                  amenity?.amenity_name ||
                  `${amenity?.id}`,
              };
            }
            let error = undefined;
            // @ts-ignore
            const amenity_error = errors?.[name]?.[index]?.amenity_name;
            // @ts-ignore
            const custom_error = errors?.[name]?.[index]?.custom_amenity;
            if (
              // @ts-ignore
              touched?.[name]?.[index] &&
              (amenity_error || custom_error)
            ) {
              error = amenity_error || custom_error;
            }
            return (
              <div className="flex space-x-2">
                <div className="w-8 h-9 flex flex-row items-center">
                  <span>{index + 1}</span>
                </div>
                <div className="w-full flex items-center z-40">
                  <SelectCreatetable
                    name={`${name}[${index}]`}
                    onChange={(value) => handleUpdate(index, value)}
                    onBlur={onBlur}
                    options={[...meta?.amenity]?.map((x) => ({
                      value: x.id,
                      label: x.name,
                    }))}
                    className="w-full"
                    value={value}
                    error={error}
                  />
                </div>
                <div className="w-[100px] h-9 flex items-center justify-end">
                  <Button
                    variant="neutral"
                    size="small"
                    leadingIcon={FiTrash}
                    className="text-jll-color-stroke-danger"
                    onClick={() => handleRemove(index)}
                  >
                    Delete
                  </Button>
                </div>
              </div>
            );
          })}
        </div>

        {!isModal && (
          <Button variant="secondary" leadingIcon={FiPlus} onClick={handleAdd}>
            Add amenity
          </Button>
        )}
      </div>
    );
  }
);

export default React.memo(PropertyAmenities);
