import { capitalize } from "lodash";
import { Link } from "react-router-dom";
import { getPastTense } from "utils";
import ActivityDate from "./ActivityDate";
import { URLS } from "constant";

const ActivityItem = ({ activeItem, setOpenBrokerModal, setBrokerId }: any) => {
  const session = JSON.parse(sessionStorage.getItem("session") || "null");

  const isBuilding = activeItem?.content_type_id?.name
    ?.toLowerCase()
    ?.includes("building");
  const isSuite = activeItem?.content_type_id?.name
    ?.toLowerCase()
    ?.includes("suite");
  const isBroker = activeItem?.content_type_id?.name
    ?.toLowerCase()
    ?.includes("broker");
  const displayName =
    `${activeItem?.first_name} ${activeItem?.last_name}` ===
    session?.get_full_name
      ? "You"
      : `${activeItem?.first_name} ${activeItem?.last_name}`;

  return (
    <div className="py-[14px] text-sm">
      {(isBuilding || isSuite || isBroker) && (
        <div>
          <p className="text-jll-color-text-base-default">
            {displayName}{" "}
            <span className="font-semibold">
              {capitalize(getPastTense(activeItem?.type))}
            </span>
            {` ${activeItem?.content_type_id?.name}`}
          </p>
          <p className="truncate text-jll-color-text-base-default mb-1">
            {(() => {
              if (isBuilding) {
                return (
                  <>
                    <Link
                      to={`/property/${
                        activeItem?.object?.building || activeItem?.object?.id
                      }/${URLS.PROPERTY.FORM}`}
                      className="text-jll-color-icon-info"
                    >
                      {`${
                        activeItem?.object?.building_title ||
                        activeItem?.object?.title ||
                        activeItem?.object?.building_name ||
                        " "
                      } - 
                      ${
                        activeItem?.object?.building_address_area ||
                        activeItem?.object?.address_area ||
                        ""
                      }`}
                    </Link>
                  </>
                );
              } else if (isSuite && !isBroker) {
                return (
                  <>
                    <Link
                      to={`/property/${activeItem?.object?.building}/availability/${activeItem?.object?.id}/form`}
                      className="text-jll-color-icon-info"
                    >
                      {`Floor ${activeItem?.object?.floor} `}
                      {activeItem?.object?.name
                        ? `| Suite ${activeItem?.object?.name} `
                        : ""}
                    </Link>
                    for{" "}
                    {activeItem?.object?.building && (
                      <Link
                        to={`/property/${activeItem?.object?.building}/form`}
                        className="text-jll-color-icon-info"
                      >
                        {activeItem?.object?.building_title ||
                          activeItem?.object?.title ||
                          ""}
                      </Link>
                    )}
                    {activeItem?.object?.suite && (
                      <Link
                        to={`/property/${activeItem?.object?.building}/availabilities`}
                        className="text-jll-color-icon-info"
                      >
                        {activeItem?.object?.building_title ||
                          activeItem?.object?.title ||
                          ""}
                      </Link>
                    )}
                  </>
                );
              } else if (isBroker) {
                return (
                  <>
                    <span
                      className="text-jll-color-icon-info cursor-pointer"
                      onClick={() => {
                        setBrokerId(activeItem?.change__broker);
                        setOpenBrokerModal(true);
                      }}
                    >
                      {activeItem?.change__broker_name || ""}
                    </span>{" "}
                    - {activeItem?.change__role || ""}
                  </>
                );
              }
            })()}
          </p>

          <ActivityDate date={activeItem?.update_timestamp} />
        </div>
      )}
    </div>
  );
};

export default ActivityItem;
