import queryString from "query-string";
import axios from "../axiosConfig";
import { API_PREFIX_URL, HTTP_ERROR_UNAUTHORIZED } from "constant";
import { logoutApi } from "./auth";

const logoutFunc = async () => {
  const logoutRes: any = await logoutApi();
  if (!logoutRes) return;
  window.location.href = "/";
};

const handleAxiosError = async (error: any) => {
  if (axios.isAxiosError(error)) {
    if (error?.response) {
      if (
        error?.response?.data &&
        error?.response?.data?.["error"] === "invalid_grant"
      ) {
        await logoutFunc();
      }
    }

    return error?.response?.data;
  }
  throw error;
};

export const getMarketsphereAPI = async (props: any) => {
  try {
    const param_str = queryString.stringify(props, {
      arrayFormat: "bracket",
    });
    const res: any = await axios.get(
      `${API_PREFIX_URL}/ms/property/?${param_str}`
    );
    if (
      (res?.error && res?.error?.toLowerCase()?.includes("invalid_grant")) ||
      res?.detail?.statusCode === HTTP_ERROR_UNAUTHORIZED
    ) {
      await logoutFunc();
      return;
    }
    return res;
  } catch (error: any) {
    return await handleAxiosError(error);
  }
};

export const postPropertyMarketsphereAPI = async (payload: any) => {
  try {
    const res: any = await axios.post(
      `${API_PREFIX_URL}/building/ms/`,
      payload
    );
    if (
      (res?.error && res?.error?.toLowerCase()?.includes("invalid_grant")) ||
      res?.detail?.statusCode === HTTP_ERROR_UNAUTHORIZED
    ) {
      await logoutFunc();
      return;
    }
    return res;
  } catch (error: any) {
    return await handleAxiosError(error);
  }
};

export const postSuiteMarketsphereAPI = async (payload: any) => {
  try {
    const res: any = await axios.post(
      `${API_PREFIX_URL}/suite/ms/`,
      payload
    );
    if (
      (res?.error && res?.error?.toLowerCase()?.includes("invalid_grant")) ||
      res?.detail?.statusCode === HTTP_ERROR_UNAUTHORIZED
    ) {
      await logoutFunc();
      return;
    }
    return res;
  } catch (error: any) {
    return await handleAxiosError(error);
  }
};

export const postPropertyUpdateMarketsphereAPI = async (id: number) => {
  try {
    const res: any = await axios.put(`${API_PREFIX_URL}/building/ms/${id}/`, {
      property_id: id,
    });
    if (
      (res?.error && res?.error?.toLowerCase()?.includes("invalid_grant")) ||
      res?.detail?.statusCode === HTTP_ERROR_UNAUTHORIZED
    ) {
      await logoutFunc();
      return;
    }
    return res;
  } catch (error: any) {
    return await handleAxiosError(error);
  }
};

export const getPropertyMediaUpdateMarketsphereAPI = async (id: number) => {
  try {
    const res: any = await axios.get(`${API_PREFIX_URL}/building/ms/assets/${id}/`);
    if (
      (res?.error && res?.error?.toLowerCase()?.includes("invalid_grant")) ||
      res?.detail?.statusCode === HTTP_ERROR_UNAUTHORIZED
    ) {
      await logoutFunc();
      return;
    }
    return res;
  } catch (error: any) {
    return await handleAxiosError(error);
  }
};
