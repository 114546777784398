interface IEditableIconBtn {
  icon: any;
  variant: "success" | "grey";
  onClick?: any;
  className?: string;
  disabled?: boolean;
  type?: "button" | "submit";
}

const EditableIconBtn: React.FC<IEditableIconBtn> = ({
  icon,
  variant,
  onClick,
  className,
  disabled,
  type = "button",
}) => {
  const Icon = icon;
  let variantClassName = "flex items-center justify-center h-5";
  if (variant === "success") {
    variantClassName += " bg-jll-color-icon-success text-white rounded-sm";
  } else if (variant === "grey") {
    variantClassName +=
      " bg-jll-color-coldGray-2 text-jll-color-coldGray-7 rounded-sm";
  }
  variantClassName += ` ${className}`;

  if (disabled) {
    variantClassName += " opacity-40 cursor-not-allowed";
  }
  
  return (
    <button
      type={type}
      className={variantClassName}
      onClick={onClick}
      disabled={disabled}
    >
      <Icon className="w-4 h-4" />
    </button>
  );
};

export default EditableIconBtn;
