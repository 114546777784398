import React, { useEffect, useContext, useState, forwardRef } from "react";
import { GlobalContext } from "context";
import cn from "classnames";
import {
  PROPERTY_STEPS_INDUSTRIAL_V2,
  PROPERTY_STEPS_OLM_V2,
  PROPERTY_STEPS_V2,
  PROPERTY_TABS_V2,
} from "constant";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import PropertyEditV2 from "../PropertyEditV2";
import PropertyFinancialV2 from "../PropertyFinancialV2";
import PropertyIndustrialV2 from "../PropertyIndustrialV2";
import PropertyOccupancyV2 from "../PropertyOccupancyV2";
import { PropertyAboutV2 } from "../PropertyAboutV2";
import PropertyCompanyV2 from "../PropertyCompanyV2";

interface IPropertyEdit {
  onDiffFields?: any;
  diffFields?: any;
  isOLM?: boolean;
}

const PropertyEdit = forwardRef(
  ({ onDiffFields, diffFields, isOLM }: IPropertyEdit, ref) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { state } = useContext(GlobalContext);
    const { property } = state;
    const [propertyStep, setPropertyStep] = useState(PROPERTY_STEPS_V2);
    const [activeSubTab, setActiveSubTab] = useState<any>(
      PROPERTY_TABS_V2.DATA
    );

    useEffect(() => {
      const filters = queryString.parse(location?.search, {
        arrayFormat: "bracket",
      });
      setActiveSubTab(filters?.sub_tab || PROPERTY_TABS_V2.DATA);
    }, [location]);

    useEffect(() => {
      if (!property || !property?.property_type) return;
      if (isOLM) {
        if (
          activeSubTab === PROPERTY_TABS_V2.INDUSTRIAL ||
          activeSubTab === PROPERTY_TABS_V2.OCCUPANCY ||
          activeSubTab === PROPERTY_TABS_V2.COMPANY
        ) {
          handleSubTab(PROPERTY_TABS_V2.DATA);
        }
        setPropertyStep(PROPERTY_STEPS_OLM_V2);
      } else {
        if (property?.property_type === "industrial") {
          setPropertyStep(PROPERTY_STEPS_INDUSTRIAL_V2);
        } else {
          setPropertyStep(PROPERTY_STEPS_V2);
        }
      }
    }, [property, isOLM]);

    useEffect(() => {}, [isOLM]);

    const handleSubTab = (subTab: string) => {
      const filters = queryString.parse(location?.search, {
        arrayFormat: "bracket",
      });
      filters["sub_tab"] = subTab;
      navigate(
        `${location.pathname}?${queryString.stringify(filters, {
          arrayFormat: "bracket",
        })}`
      );
    };

    return (
      <>
        <div className="relative h-full">
          <div className="bg-jll-color-coldGray-1 h-[60px] px-8 flex items-end absolute left-0 top-0 right-0">
            {propertyStep?.map((step, idx: number) => (
              <div
                className={cn(
                  "border-x-8 border-t-8 rounded-t-xl cursor-pointer",
                  {
                    "border-white bg-white": activeSubTab === step?.tag,
                    "border-jll-color-coldGray-1 ": activeSubTab !== step?.tag,
                  }
                )}
                key={idx}
                onClick={() => handleSubTab(step.tag)}
              >
                <p className="text-jll-color-text-base-default px-1.5 pb-3 font-semibold">
                  {step.label}
                </p>
              </div>
            ))}
          </div>

          <div className="absolute top-[60px] left-0 right-0 overflow-y-auto bottom-0 mt-2">
            {activeSubTab === PROPERTY_TABS_V2.DATA && (
              <PropertyEditV2
                ref={ref}
                onDiffFields={onDiffFields}
                diffFields={diffFields}
                isOLM={isOLM}
              />
            )}

            {activeSubTab === PROPERTY_TABS_V2.ABOUT && (
              <PropertyAboutV2
                onDiffFields={onDiffFields}
                diffFields={diffFields}
                isOLM={isOLM}
              />
            )}

            {activeSubTab === PROPERTY_TABS_V2.FINANCIALS && (
              <PropertyFinancialV2
                onDiffFields={onDiffFields}
                diffFields={diffFields}
                isOLM={isOLM}
              />
            )}

            {!isOLM && activeSubTab === PROPERTY_TABS_V2.INDUSTRIAL && (
              <PropertyIndustrialV2
                onDiffFields={onDiffFields}
                diffFields={diffFields}
              />
            )}
            {!isOLM && activeSubTab === PROPERTY_TABS_V2.OCCUPANCY && (
              <PropertyOccupancyV2
                onDiffFields={onDiffFields}
                diffFields={diffFields}
              />
            )}
            {!isOLM && activeSubTab === PROPERTY_TABS_V2.COMPANY && (
              <PropertyCompanyV2 />
            )}
          </div>
        </div>
      </>
    );
  }
);

export default React.memo(PropertyEdit);
