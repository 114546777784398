import {
  REPORT_PROPERTY_STALENESS_COLUMNS,
  REPORT_SUITE_STALENESS_COLUMNS,
  SEARCH_RESULT_LIMIT,
} from "constant";
import { useApiCall } from "hooks";
import { useEffect, useState } from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";
import {
  getAvailabilitiesAPI,
  getPropertiesAPI,
} from "services";
import { EmptyResult, Loading } from "ui-atoms";
import {
  StalenessLine,
  StyledAvailabilityValue,
  StyledPropertyValue,
  Table,
} from "ui-molecules";
import "./styles.css";
import { getApiDate, getUTCDate } from "utils";
import { subDays } from "date-fns";

interface IReportStalenessPane {
  market_id?: number;
  name?: string;
  status_buckets?: string;
  type?: "property" | "suite";
}
const ReportStalenessPane: React.FC<IReportStalenessPane> = ({
  market_id,
  name,
  type,
  status_buckets,
}) => {
  const [getProperties, isLoadingProperties] = useApiCall(getPropertiesAPI);
  const [getAvailabilities, isLoadingSuites] = useApiCall(getAvailabilitiesAPI);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState<any>();
  const [filters, setFilters] = useState<any>(undefined);

  let service: any = null;
  if (type === "property") {
    service = getProperties;
  } else if (type === "suite") {
    service = getAvailabilities;
  }

  useEffect(() => {
    if (
      !service === null ||
      (filters === undefined && market_id !== undefined && market_id < 0)
    )
      return;
    setPage(1);
    if (!service) return;
    setData([]);
    let params = {
      page: 1,
      limit: SEARCH_RESULT_LIMIT,
      market: [name],
    };
    if (!!filters) {
      params = {
        ...params,
        ...filters,
      };
    }
    service(params).then((res: any) => {
      setData(res?.docs);
      setPage(res?.page);
      setTotal(res?.total);
    });
  }, [market_id, filters]);

  let buckets: any = [];
  let total_count = 0;
  if (!!status_buckets) {
    buckets = JSON.parse(status_buckets);
    if (!!buckets?.length)
      total_count = buckets?.reduce((a: any, b: any) => {
        return Number(a) + Number(b?.count);
      }, 0);
  }

  const loadMore = () => {
    if (!service) return;
    const nextPage = page + 1;
    setPage(nextPage);
    let params = {
      page: nextPage,
      limit: SEARCH_RESULT_LIMIT,
      market: [name],
    };
    if (!!filters) {
      params = {
        ...params,
        ...filters,
      };
    }
    service(params).then((res: any) => {
      setData((prevData: any) => [...prevData, ...res?.docs]);
      setPage(res?.page);
      setTotal(res?.total);
    });
  };

  const [sentryRef] = useInfiniteScroll({
    loading: false,
    hasNextPage: total > data?.length,
    onLoadMore: loadMore,
  });

  const handleFilter = (type: "red" | "yellow" | "green") => {
    let user_saved_start_date;
    let user_saved_end_date;
    switch (type) {
      case "green":
        user_saved_start_date = getApiDate(subDays(getUTCDate(), 45));
        user_saved_end_date = getApiDate(getUTCDate());
        setFilters({ user_saved_start_date, user_saved_end_date });
        break;
      case "yellow":
        user_saved_start_date = getApiDate(subDays(getUTCDate(), 90));
        user_saved_end_date = getApiDate(subDays(getUTCDate(), 45));
        setFilters({ user_saved_start_date, user_saved_end_date });
        break;
      default:
        user_saved_end_date = getApiDate(subDays(getUTCDate(), 90));
        setFilters({ user_saved_end_date });
    }
  };

  return (
    <div className="h-full flex flex-col">
      <div className="pr-8">
        <div className="h-[70px] border-b border-jll-color-coldGray-3 flex flex-row justify-between mt-[-23px] w-full">
          <div className="flex flex-row space-x-8 items-center w-full justify-between">
            <p className="font-semibold text-xl text-jll-color-text-base-default">
              {name}
            </p>
            <div className="flex flex-row items-center w-8/12 space-x-4">
              <span className="font-medium text-jll-color-text-base-default text-sm whitespace-nowrap">
                {total_count} {type === "property" ? "Propert" : "Availabilit"}
                {total_count <= 1 ? "y" : "ies"}
              </span>
              <div className="flex flex-row w-full">
                <StalenessLine
                  status_buckets={buckets}
                  total_count={total_count}
                  onClick={handleFilter}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pr-8 h-full overflow-y-auto">
        {(isLoadingProperties || isLoadingSuites) && !data?.length && (
          <Loading />
        )}
        {(!isLoadingProperties || !isLoadingSuites) &&
          data &&
          !data?.length && (
            <EmptyResult>
              {type === "property" ? (
                <>
                  <h3 className="text-heading3 text-jll-color-coldGray-5">
                    Sorry, no properties found.
                  </h3>
                  <p className="mt-4 text-body1 text-jll-color-coldGray-7 mb-2">
                    Property not in MyListings?
                  </p>
                </>
              ) : (
                <h3 className="text-heading3 text-jll-color-coldGray-5">
                  Sorry, no availabilities found.
                </h3>
              )}
            </EmptyResult>
          )}
        {!!data?.length && (
          <>
            <Table>
              <Table.Thead>
                <Table.Tr>
                  {type === "property" &&
                    REPORT_PROPERTY_STALENESS_COLUMNS.map(
                      (column: any, index: number) => (
                        <Table.Th key={index} filterId={column.id}>
                          {column.label}
                        </Table.Th>
                      )
                    )}
                  {type === "suite" &&
                    REPORT_SUITE_STALENESS_COLUMNS.map(
                      (column: any, index: number) => (
                        <Table.Th key={index} filterId={column.id}>
                          {column.label}
                        </Table.Th>
                      )
                    )}
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                {data?.map((item: any, idx: number) => (
                  <Table.Tr key={idx}>
                    {type === "property" &&
                      REPORT_PROPERTY_STALENESS_COLUMNS.map(
                        (column: any, index: number) => {
                          return (
                            <Table.Td
                              key={index}
                              className={column?.td?.className}
                            >
                              <StyledPropertyValue
                                property={item}
                                valueKey={column.id}
                              />
                            </Table.Td>
                          );
                        }
                      )}
                    {type === "suite" &&
                      REPORT_SUITE_STALENESS_COLUMNS.map(
                        (column: any, index: number) => {
                          return (
                            <Table.Td
                              key={index}
                              className={column?.td?.className}
                            >
                              <StyledAvailabilityValue
                                availability={item}
                                valueKey={column.id}
                              />
                            </Table.Td>
                          );
                        }
                      )}
                  </Table.Tr>
                ))}
              </Table.Tbody>
            </Table>
            {!!(total > data?.length) && (
              <div
                className="w-full py-2 flex justify-center items-center"
                ref={sentryRef}
              >
                <Loading />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ReportStalenessPane;
