import type { FC } from "react";
import { Button, Loading } from "ui-atoms";

interface ShowMoreItemsProps {
  total: number;
  onClick?: () => void;
  isLoading?: boolean;
  itemsCount: number;
}

/**
 * Show More Items
 */
const ShowMoreItems: FC<ShowMoreItemsProps> = ({
  onClick,
  total,
  isLoading,
  itemsCount,
}) => {
  return (
    <div className="flex items-center justify-center flex-col space-y-2 h-[100px]">
      {isLoading && <Loading />}
      {!isLoading && (
        <>
          <div className="text-xs">
            Showing {itemsCount} of {total}
          </div>
          {itemsCount < total && (
            <Button size="small" variant="secondary" onClick={onClick}>
              Show more
            </Button>
          )}
        </>
      )}
    </div>
  );
};
export default ShowMoreItems;
