import { SET_INLINE_EDIT_AMENITY_MODAL } from "constant";
import { GlobalContext } from "context";
import { useFormik } from "formik";
import { useApiCall } from "hooks";
import PropertyAmenities from "pages/Property/PropertyAboutV2/PropertyAmenities";
import { useContext, useEffect, useRef } from "react";
import { FiPlus, FiX } from "react-icons/fi";
import { getPropertyAPI, patchPropertyAPI } from "services";
import { AmenityProps } from "types";
import { Button } from "ui-atoms";
import { Modal } from "ui-molecules";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  amenities: Yup.array()
    .of(
      Yup.object({
        amenity_name: Yup.string().test(
          "amenity-name-validation",
          "This field must not be empty",
          // @ts-ignore
          (value: number | undefined, context: any) => {
            return context.parent.amenity_name || context.parent.custom_amenity;
          }
        ),
      })
    )
    .nullable(),
});

const InlineAmenityModal = () => {
  const amenityRef = useRef<any>(null);
  const { state, dispatch } = useContext(GlobalContext);
  const { inlineAmenityModalOpen, inlineAmenityModalContent } = state;
  const [getProperty] = useApiCall(getPropertyAPI);
  const [patchProperty] = useApiCall(patchPropertyAPI);

  const {
    handleSubmit,
    handleBlur,
    setFieldValue,
    values,
    errors,
    touched,
    isValid,
    setValues,
  }: any = useFormik({
    initialValues: {
      amenities: [],
    },
    validationSchema,
    onSubmit: () => {
      let payload: any = {};
      let amenity: any = [];
      let amenityLabels: any = [];

      [...values?.amenities]
        .filter((x: AmenityProps) => {
          if (x?.amenity && !x?.custom_amenity) {
            return x.amenity;
          } else if (x?.hasOwnProperty("id") && !x?.custom_amenity) {
            return x.id;
          }
        })
        .forEach((x: AmenityProps) => {
          amenity.push(x?.amenity || x?.id);
          amenityLabels.push(x?.amenity_name || x?.id);
        });

      if (amenity?.length) {
        payload["amenity"] = amenity;
      } else {
        payload["amenity"] = [];
      }
      const custom_amenity = [...values?.amenities]
        .filter((x: AmenityProps) => {
          if (x?.custom_amenity && !x?.amenity) {
            return x.custom_amenity;
          }
        })
        .map((x: AmenityProps) => x.custom_amenity);

      if (custom_amenity?.length) {
        payload["custom_amenity"] = custom_amenity;
      } else {
        payload["custom_amenity"] = [];
      }
      patchProperty({
        id: inlineAmenityModalContent?.pk,
        payload,
      }).then((res: any) => {
        inlineAmenityModalContent?.updateItem(
          {
            amenity_name: amenityLabels || [],
            custom_amenity_name: payload?.["custom_amenity"] || [],
          },
          inlineAmenityModalContent?.pk
        );
      });
      handleClose();
    },
  });

  useEffect(() => {
    if (!inlineAmenityModalContent?.pk) return;
    getProperty(inlineAmenityModalContent?.pk).then((res: any) => {
      setValues({ amenities: res?.amenities || [] });
    });
  }, [inlineAmenityModalContent]);

  const handleClose = () => {
    dispatch({
      type: SET_INLINE_EDIT_AMENITY_MODAL,
      payload: {
        open: false,
        content: null,
      },
    });
  };

  return (
    <Modal
      isOpen={inlineAmenityModalOpen}
      setIsOpen={handleClose}
      size="default"
    >
      <Modal.Header className="relative">
        <Button
          variant="neutral"
          leadingIcon={FiX}
          className="absolute top-0 right-0 w-5 !h-5"
          leadingIconClass="w-5 h-5 text-jll-color-text-base-subdued"
          onClick={handleClose}
        />
        <h5 className="text-xl font-semibold text-jll-color-text-base-default">
          Amenities
          <span className="text-sm text-jll-color-text-base-subdued font-normal ml-[10px]">
            {values?.amenities?.length || 0} Amenities
          </span>
        </h5>
      </Modal.Header>
      <Modal.Body className="mt-8">
        <PropertyAmenities
          label="Amenities"
          name="amenities"
          touched={touched}
          errors={errors}
          onChange={(value) => {
            setFieldValue("amenities", value);
          }}
          onBlur={(e) => {
            handleBlur(e);
          }}
          value={values?.amenities}
          ref={amenityRef}
          isModal
        />
      </Modal.Body>
      <Modal.Footer>
        <div className="flex flex-row space-x-4 w-full">
          <Button
            variant="secondary"
            leadingIcon={FiPlus}
            onClick={() => amenityRef?.current?.handleAdd()}
            className="w-full justify-center"
          >
            Add amenity
          </Button>
          <Button
            variant="primary"
            onClick={handleSubmit}
            className="w-full justify-center"
            disabled={!isValid}
          >
            Done
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default InlineAmenityModal;
