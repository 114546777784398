import queryString from "query-string";
import axios from "../axiosConfig";
import { APIProps } from "types";
import { API_PREFIX_URL } from "constant";

export const getBrokersAPI = async (props: APIProps) => {
  const param_str = queryString.stringify(props, {
    arrayFormat: "bracket",
  });
  const res = await axios.get(`${API_PREFIX_URL}/brokers/?${param_str}`);
  return res;
};

export const getRecentBrokersAPI = async (props: APIProps) => {
  const param_str = queryString.stringify(props, {
    arrayFormat: "bracket",
  });
  const res = await axios.get(`${API_PREFIX_URL}/brokers/recent/?${param_str}`);
  return res;
};

export const getBrokerAPI = async (id: APIProps) => {
  const res = await axios.get(`${API_PREFIX_URL}/brokers/${id}/`);
  return res;
};
