import { GlobalContext } from "context";
import { useState, useCallback, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Toast } from "ui-atoms";
import {
  HTTP_ERROR_BAD_REQUEST,
  HTTP_ERROR_UNAUTHORIZED,
  HTTP_ERROR_WRITE_ACCESS,
  SET_ERROR_MODAL,
  SET_PERMISSION_MODAL,
} from "constant";

const useApiCall = (service: any) => {
  const { dispatch } = useContext(GlobalContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState<any>(null);
  const navigate = useNavigate();

  const fetchData = useCallback(async (param: any) => {
    setLoading(true);
    try {
      const result = await service(param);
      setLoading(false);

      if (result?.status) {
        setData(result?.data || result?.result);
        return result?.data || result?.result;
      } else {
        if (
          (result?.error &&
            result?.error?.toLowerCase()?.includes("invalid_grant")) ||
          result?.detail?.statusCode === HTTP_ERROR_UNAUTHORIZED
        ) {
          navigate(`/`);
          return;
        }
        if (
          result?.message ||
          result?.detail?.error_description ||
          result?.detail?.message ||
          result?.detail
        )
          Toast.warn(
            result?.message ||
              result?.detail?.error_description ||
              result?.detail?.message ||
              result?.detail
          );
      }
    } catch (error: any) {
      setLoading(false);
      setError(error?.message || error?.detail);
      if (error?.response?.status === HTTP_ERROR_UNAUTHORIZED) {
        navigate(`/`);
        return;
      }

      if (error?.response?.status === HTTP_ERROR_WRITE_ACCESS) {
        dispatch({
          type: SET_PERMISSION_MODAL,
          payload: {
            open: true,
          },
        });
        return;
      }
      if (error?.response?.status === HTTP_ERROR_BAD_REQUEST) {
        dispatch({
          type: SET_ERROR_MODAL,
          payload: {
            open: true,
            content: {
              code: error?.response?.status,
              values: error?.response?.data?.errors?.[0] || error?.response?.data,
            },
          },
        });
        return;
      }
      if (error?.message || error?.detail?.message || error?.detail) {
        Toast.error(error?.message || error?.detail?.message || error?.detail);
      }
    }
  }, []);
  return [fetchData as Function, loading, data, error];
};

export default useApiCall;
