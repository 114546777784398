import { useContext, useEffect, useState } from "react";
import { Button, Textarea } from "ui-atoms";
import { GlobalContext } from "context";
import { useApiCall } from "hooks";
import { getFormatedDateTime } from "utils";
import {
  getPropertyCommentsAPI,
  postPropertyCommentAPI,
  deletePropertyCommentAPI,
} from "services";
import { CommentProps } from "types";

const PropertyDetail = () => {
  const { state } = useContext(GlobalContext);
  const { property } = state;
  const [deletePropertyComment] = useApiCall(deletePropertyCommentAPI);
  const [postPropertyComment] = useApiCall(postPropertyCommentAPI);
  const [getPropertyComments, , propertyComments] = useApiCall(
    getPropertyCommentsAPI
  );
  const [comments, setComments] = useState<CommentProps[]>([]);
  const [comment, setComment] = useState("");
  const [error, setError] = useState("");
  const session = JSON.parse(sessionStorage.getItem("session") || "null");

  useEffect(() => {
    getPropertyComments(property.id);
  }, []);

  useEffect(() => {
    setComments(propertyComments?.docs || []);
  }, [propertyComments]);

  // TODO: replace any
  const onChange = (e: any) => {
    const value = e.currentTarget.value;
    if (!value) {
      setError("This field is required");
    } else {
      setError("");
    }
    setComment(e.currentTarget.value);
  };

  const onClickClear = () => {
    setComment("");
  };

  const onClickAddComment = () => {
    if (comment) {
      setError("");
      postPropertyComment({
        building: property.id,
        comment,
        updated_user: session?.id,
      }).then((response: CommentProps) => {
        setComments([...comments, response]);
        setComment("");
      });
    } else {
      setError("This field is required");
    }
  };

  const onClickDelete = (comment: CommentProps) => () => {
    deletePropertyComment(comment?.pk).then(() => {
      setComments([...comments].filter((x) => x.id !== comment.id));
    });
  };

  return (
    <>
      {!!comments.length && (
        <div className="divide-y divide-jll-color-stroke-base-default mb-4">
          {comments?.map((comment: CommentProps, index: number) => {
            return (
              <div key={index} className=" py-4">
                <div className="flex justify-between">
                  <div>
                    <p className="text-body1">{comment?.comment}</p>
                    <div>
                      <small className="text-xs text-jll-color-text-base-subdued">
                        {getFormatedDateTime(comment?.update_timestamp)} |{" "}
                        {[comment?.first_name, comment?.last_name].join(" ")}
                      </small>
                    </div>
                  </div>
                  <Button
                    size="small"
                    variant="secondary"
                    onClick={onClickDelete(comment)}
                  >
                    Delete
                  </Button>
                </div>
              </div>
            );
          })}
        </div>
      )}
      <Textarea
        name="comment"
        placeholder="Ex: There is a distinct odor on the hallways that maintenance is working to resolve"
        onChange={onChange}
        value={comment}
        error={error}
      />
      <div className="flex space-x-2">
        <Button variant="secondary" onClick={onClickClear}>
          Clear
        </Button>
        <Button variant="primary" onClick={onClickAddComment}>
          Add comment
        </Button>
      </div>
    </>
  );
};

export default PropertyDetail;
