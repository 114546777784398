import { useEffect, useMemo, useState } from "react";
import type { FC } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FiRotateCcw, FiSearch } from "react-icons/fi";
import { EmptyResult, Loading, Input, Button } from "ui-atoms";
import {
  Table,
  ShowMoreItems,
  StyledActivityValue,
  ActivityChangeModal,
  Facets,
} from "ui-molecules";
import debounce from "lodash.debounce";
import queryString from "query-string";
import { LayoutListing } from "ui-organisms";
import { SEARCH_RESULT_LIMIT } from "./constants";
import type { FacetsProps, ActivityProps } from "types";
import { DirectionEnum } from "types";
import { getActivitiesAPI } from "services";
import { useApiCall } from "hooks";
import { ACTIVITY_TABLE_COLUMNS, META_TAGS } from "constant";
import { pickSpecificKeyFromObj } from "utils";
import { Helmet } from "react-helmet-async";
import React from "react";

const Activity: FC = () => {
  const [getActivities, loading] = useApiCall(getActivitiesAPI);
  const location = useLocation();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [activities, setActivities] = useState<ActivityProps[]>();
  const [facets, setFacets] = useState<FacetsProps>();
  const [sortFields, setSortFields] = useState<string[]>([]);
  const [paramFilters, setParamFilters] = useState<any>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [detailData, setDetailData] = useState({});
  const [keyword, setKeyword] = useState<string>();

  useEffect(() => {
    const filters = queryString.parse(location?.search, {
      arrayFormat: "bracket",
    });
    setParamFilters(filters);
  }, [location]);

  useEffect(() => {
    if (paramFilters === null) return;
    setKeyword(paramFilters?.keyword || "");
    setPage(1);
    setActivities([]);
    getActivities({
      page: 1,
      limit: SEARCH_RESULT_LIMIT,
      ...paramFilters,
    }).then((data: any) => {
      setActivities([...data?.docs]);
      setPage(data?.page);
      setTotal(data?.total);
      setFacets(data?.facets);
      setSortFields(data?.sort_fields);
    });
  }, [paramFilters]);

  const onClickSorting = (sort: string) => {
    const updatedFilters = {
      ...paramFilters,
      sort,
      direction:
        paramFilters?.direction === DirectionEnum.asc
          ? DirectionEnum?.desc
          : DirectionEnum.asc,
    };
    navigate(
      `${location.pathname}?${queryString.stringify(updatedFilters, {
        arrayFormat: "bracket",
      })}`
    );
  };

  const onClickShowMore = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    getActivities({
      page: nextPage,
      limit: SEARCH_RESULT_LIMIT,
      ...paramFilters,
    }).then((data: any) => {
      setActivities((prevData: any) => [...prevData, ...data?.docs]);
      setPage(data?.page);
      setTotal(data?.total);
      setFacets(data?.facets);
      setSortFields(data?.sort_fields);
    });
  };

  const updateKeywordFilter = (e: any) => {
    const updatedFilters = { ...paramFilters };
    if (e.target.value) {
      updatedFilters["keyword"] = e.target.value;
    } else if (updatedFilters.hasOwnProperty("keyword")) {
      delete updatedFilters.keyword;
    }
    navigate(
      `${location.pathname}?${queryString.stringify(updatedFilters, {
        arrayFormat: "bracket",
      })}`
    );
  };

  const debounceUpdateKeyword = useMemo(
    () => debounce(updateKeywordFilter, 300),
    [paramFilters]
  );

  const onChangeInput = (e: any) => {
    setKeyword(e.target.value);
    debounceUpdateKeyword(e);
  };

  const handleChangeLog = (activity: any) => {
    setIsOpen(true);
    setDetailData({
      ...pickSpecificKeyFromObj(activity, "change__"),
      standardized: activity?.standardized,
      object: activity?.object,
      update_timestamp: activity?.update_timestamp,
      first_name: activity?.first_name,
      last_name: activity?.last_name,
    });
  };

  const onClickReset = () => {
    navigate(location.pathname);
  };

  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>{META_TAGS?.activity?.title}</title>
        <meta property="og:title" content={META_TAGS?.activity?.title} />
      </Helmet>
      <LayoutListing>
        <LayoutListing.Header>
          <h1 className="text-heading1">Activities</h1>
          <div className="flex space-x-4 items-center">
            <Input
              className="w-[400px] !mb-0"
              name="search"
              leadingIcon={FiSearch}
              onChange={onChangeInput}
              value={keyword}
              isClearable
              placeholder="Search by building name, listing ID, address, brokers name"
            />
          </div>
        </LayoutListing.Header>
        <LayoutListing.Container>
          <Facets facets={facets} />
          <LayoutListing.Content>
            {!!loading && !activities?.length && <Loading />}
            {!loading && activities && !activities?.length && (
              <EmptyResult>
                <h3 className="text-heading3 text-jll-color-coldGray-5">
                  No activities found.
                </h3>
              </EmptyResult>
            )}

            {!!activities?.length && (
              <div className="p-6">
                <div className="flex space-x-4 pb-6 justify-between items-center">
                  <div className="font-semibold text-jll-color-coldGray-7 text-body1">
                    {total} result{total > 1 ? "s" : ""}
                  </div>
                  {paramFilters && !!Object.keys(paramFilters)?.length && (
                    <Button
                      variant="ghost"
                      size="small"
                      leadingIcon={FiRotateCcw}
                      onClick={onClickReset}
                    >
                      Reset
                    </Button>
                  )}
                </div>
                <Table>
                  <Table.Thead>
                    <Table.Tr>
                      {ACTIVITY_TABLE_COLUMNS.map((column, index) => {
                        return (
                          <Table.Th
                            key={index}
                            filterId={column.id}
                            sortFields={sortFields}
                            filters={paramFilters}
                            onClick={onClickSorting}
                          >
                            {column.label}
                          </Table.Th>
                        );
                      })}
                    </Table.Tr>
                  </Table.Thead>
                  <Table.Tbody>
                    {activities?.map(
                      (activity: ActivityProps, index: number) => {
                        return (
                          <Table.Tr
                            key={index}
                            onClick={() => handleChangeLog(activity)}
                          >
                            {ACTIVITY_TABLE_COLUMNS.map((column, index) => {
                              return (
                                <Table.Td key={index}>
                                  <StyledActivityValue
                                    activity={activity}
                                    valueKey={column.id}
                                  />
                                </Table.Td>
                              );
                            })}
                          </Table.Tr>
                        );
                      }
                    )}
                  </Table.Tbody>
                </Table>
                <ShowMoreItems
                  onClick={onClickShowMore}
                  total={total}
                  isLoading={loading}
                  itemsCount={activities?.length}
                />
              </div>
            )}
          </LayoutListing.Content>
        </LayoutListing.Container>
        <ActivityChangeModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          data={detailData}
        />
      </LayoutListing>
    </>
  );
};

export default React.memo(Activity);
