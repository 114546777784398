export const emailPropertyVirtualToursJSON = [
  {
    id: "LjhH8DdtDG",
    cells: [1],
    columns: [
      {
        id: "qTNZzDU2YE",
        contents: [
          {
            id: "Hs667Mm95z",
            type: "heading",
            values: {
              containerPadding: "10px",
              anchor: "",
              headingType: "h4",
              fontWeight: 700,
              fontSize: "14px",
              textAlign: "left",
              lineHeight: "140%",
              linkStyle: {
                inherit: true,
                linkColor: "#0000ee",
                linkHoverColor: "#0000ee",
                linkUnderline: true,
                linkHoverUnderline: true,
              },
              hideDesktop: false,
              displayCondition: null,
              _meta: {
                htmlID: "u_content_heading_17",
                htmlClassNames: "u_content_heading",
              },
              selectable: true,
              draggable: true,
              duplicatable: true,
              deletable: true,
              hideable: true,
              text: "Virtual tours",
            },
          },
        ],
        values: {
          backgroundColor: "#f6f7f7",
          padding: "0px",
          border: {},
          borderRadius: "0px",
          _meta: { htmlID: "u_column_59", htmlClassNames: "u_column" },
        },
      },
    ],
    values: {
      displayCondition: null,
      columns: false,
      backgroundColor: "",
      columnsBackgroundColor: "",
      backgroundImage: {
        url: "",
        fullWidth: true,
        repeat: "no-repeat",
        size: "custom",
        position: "center",
      },
      padding: "0px",
      anchor: "",
      hideDesktop: false,
      _meta: { htmlID: "u_row_26", htmlClassNames: "u_row" },
      selectable: true,
      draggable: true,
      duplicatable: true,
      deletable: true,
      hideable: true,
    },
  },
  {
    id: "property_virtual_tours",
    cells: [1, 1, 1, 1],
    columns: [
      {
        id: "7eSL4aVDUU",
        contents: [
          {
            id: "YeQXsek72R",
            type: "image",
            values: {
              containerPadding: "10px",
              anchor: "",
              src: {
                url: "https://cdn.tools.unlayer.com/image/placeholder.png",
                width: 800,
                height: 200,
              },
              textAlign: "center",
              altText: "",
              action: {
                name: "web",
                attrs: { href: "{{href}}", target: "{{target}}" },
                values: { href: "", target: "_blank" },
              },
              hideDesktop: false,
              displayCondition: null,
              _meta: {
                htmlID: "u_content_image_27",
                htmlClassNames: "u_content_image",
              },
              selectable: true,
              draggable: true,
              duplicatable: true,
              deletable: true,
              hideable: true,
            },
          },
        ],
        values: {
          backgroundColor: "#f6f7f7",
          padding: "0px",
          border: {},
          borderRadius: "0px",
          _meta: { htmlID: "u_column_60", htmlClassNames: "u_column" },
        },
      },
      {
        id: "Z4ZM4YD0Dd",
        contents: [
          {
            id: "fuHO9e0FDF",
            type: "image",
            values: {
              containerPadding: "10px",
              anchor: "",
              src: {
                url: "https://cdn.tools.unlayer.com/image/placeholder.png",
                width: 800,
                height: 200,
              },
              textAlign: "center",
              altText: "",
              action: {
                name: "web",
                attrs: { href: "{{href}}", target: "{{target}}" },
                values: { href: "", target: "_blank" },
              },
              hideDesktop: false,
              displayCondition: null,
              _meta: {
                htmlID: "u_content_image_28",
                htmlClassNames: "u_content_image",
              },
              selectable: true,
              draggable: true,
              duplicatable: true,
              deletable: true,
              hideable: true,
            },
          },
        ],
        values: {
          backgroundColor: "#f6f7f7",
          padding: "0px",
          border: {},
          borderRadius: "0px",
          _meta: { htmlID: "u_column_61", htmlClassNames: "u_column" },
        },
      },
      {
        id: "kx4CQx6K15",
        contents: [
          {
            id: "riInUqvOby",
            type: "image",
            values: {
              containerPadding: "10px",
              anchor: "",
              src: {
                url: "https://cdn.tools.unlayer.com/image/placeholder.png",
                width: 800,
                height: 200,
              },
              textAlign: "center",
              altText: "",
              action: {
                name: "web",
                attrs: { href: "{{href}}", target: "{{target}}" },
                values: { href: "", target: "_blank" },
              },
              hideDesktop: false,
              displayCondition: null,
              _meta: {
                htmlID: "u_content_image_29",
                htmlClassNames: "u_content_image",
              },
              selectable: true,
              draggable: true,
              duplicatable: true,
              deletable: true,
              hideable: true,
            },
          },
        ],
        values: {
          backgroundColor: "#f6f7f7",
          padding: "0px",
          border: {},
          borderRadius: "0px",
          _meta: { htmlID: "u_column_62", htmlClassNames: "u_column" },
        },
      },
      {
        id: "aDZ2SPWhOR",
        contents: [
          {
            id: "oru916i_AZ",
            type: "image",
            values: {
              containerPadding: "10px",
              anchor: "",
              src: {
                url: "https://cdn.tools.unlayer.com/image/placeholder.png",
                width: 800,
                height: 200,
              },
              textAlign: "center",
              altText: "",
              action: {
                name: "web",
                attrs: { href: "{{href}}", target: "{{target}}" },
                values: { href: "", target: "_blank" },
              },
              hideDesktop: false,
              displayCondition: null,
              _meta: {
                htmlID: "u_content_image_30",
                htmlClassNames: "u_content_image",
              },
              selectable: true,
              draggable: true,
              duplicatable: true,
              deletable: true,
              hideable: true,
            },
          },
        ],
        values: {
          backgroundColor: "#f6f7f7",
          padding: "0px",
          border: {},
          borderRadius: "0px",
          _meta: { htmlID: "u_column_63", htmlClassNames: "u_column" },
        },
      },
    ],
    values: {
      displayCondition: null,
      columns: false,
      backgroundColor: "",
      columnsBackgroundColor: "",
      backgroundImage: {
        url: "",
        fullWidth: true,
        repeat: "no-repeat",
        size: "custom",
        position: "center",
      },
      padding: "0px",
      anchor: "",
      hideDesktop: false,
      _meta: { htmlID: "u_row_27", htmlClassNames: "u_row" },
      selectable: true,
      draggable: true,
      duplicatable: true,
      deletable: true,
      hideable: true,
    },
  },
];
