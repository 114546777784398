import cn from "classnames";
import { FiChevronLeft, FiExternalLink } from "react-icons/fi";
import { useLocation, Link, useParams } from "react-router-dom";
import { Button, Chip } from "ui-atoms";
import { FC, useContext } from "react";
import { GlobalContext } from "context";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { convertExtension, lazyLoadImageOnError } from "utils";
import { convertImageUrl } from "services";
import { URLS } from "constant";

interface menuItem {
  label: string;
  icon: any;
  id?: string;
  count?: boolean;
  suffixUrl: string;
}

interface LayoutEditTeamListingSidebarProps {
  menuItems: menuItem[];
  backToUrl: string;
}

const LayoutEditTeamListingSidebar: FC<LayoutEditTeamListingSidebarProps> = ({
  menuItems,
  backToUrl,
}) => {
  const { state } = useContext(GlobalContext);
  const { teamListing, showTooltip, hideTooltip } = state;
  const { teamId } = useParams();
  const location = useLocation();
  const isNew = teamId === "new";

  return (
    <div
      className={cn(
        "fixed top-[64px] bottom-0 left-0 w-[360px] pt-8 overflow-y-auto flex flex-col justify-between bg-jll-color-surface-base-subdued"
      )}
    >
      <div>
        <div className="border-b border-black border-opacity-10 px-8 pb-9">
          <div className="flex flex-row justify-between items-center">
            <Link to={backToUrl}>
              <Button
                variant="neutral"
                leadingIconClass="w-5 h-5"
                leadingIcon={FiChevronLeft}
              >
                Back
              </Button>
            </Link>
          </div>
          <div className="flex flex-row pt-9">
            <div className="flex flex-col overflow-hidden">
              <p
                className="text-3xl font-semibold text-jll-color-coldGray-9 whitespace-normal line-clamp-2"
                id="team-name"
                data-tooltip-content={teamListing?.name}
                onMouseOver={() => showTooltip("team-name")}
                onMouseLeave={() => hideTooltip()}
              >
                {teamListing?.name || ''}
              </p>
            </div>
          </div>
        </div>

        <div className="mt-8 px-8">
          {!isNew &&
            menuItems?.map((item: any, idx) => {
              const active = location.pathname.includes(
                item.tag || item.suffixUrl
              );
              const Icon = item.icon;
              let to = `/team-listing/${teamId}`;
              to += `/${item.suffixUrl}`;
              let count = "";
              if (item?.count) {
                if (teamListing?.[item.id]?.length > -1) {
                  count = teamListing?.[item.id]?.length;
                }
              }

              return (
                <Link
                  key={idx}
                  className={cn(
                    "flex justify-between items-center cursor-pointer mb-6 hover:text-jll-color-coldGray-11",
                    {
                      "text-jll-color-coldGray-11": active,
                      "text-jll-color-coldGray-4": !active,
                    }
                  )}
                  to={to}
                >
                  <div className="flex flex-row items-center">
                    <Icon className="w-5 h-5 mr-2" />
                    <span className={cn({ "font-semibold": active })}>
                      {item.label}
                    </span>
                  </div>
                  {item?.count && (
                    <span className={cn({ "font-semibold": active })}>
                      {count}
                    </span>
                  )}
                </Link>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default LayoutEditTeamListingSidebar;
