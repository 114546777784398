import { StyledPropertyValueProps } from "ui-molecules";

interface TdProps {
  className: string;
}

interface TableColumnProps {
  id: StyledPropertyValueProps["valueKey"];
  label: string;
  sort?: string;
  td?: TdProps;
}

export const PROPERTY_TABLE_COLUMNS: TableColumnProps[] = [
  {
    id: "info",
    label: "Property",
    sort: "asc",
  },
  {
    id: "address",
    label: "Address",
  },
  {
    id: "updated_info",
    label: "Updated",
  },
];

export const PROPERTY_TABLE_PANE_COLUMNS: TableColumnProps[] = [
  {
    id: "listing_info",
    label: "Property",
    sort: "asc",
  },
  {
    id: "property_type",
    label: "Property Type",
    td: {
      className: "space-x-2",
    },
  },
  {
    id: "building_size",
    label: "Building Size",
  },
  {
    id: "suites",
    label: "Availabilities",
  },
  {
    id: "publish_status",
    label: "Status",
  },
  {
    id: "updated_info",
    label: "Updated",
  },
];
