import { isSameDay, subMonths, subWeeks } from "date-fns";
import { useEffect, useState } from "react";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "./dateRange.css";
import { getApiDate, getUTCDate } from "utils";

interface IDateRange {
  isFutureDate?: boolean;
  onChange?: any;
  value?: { start_date: string; end_date: string };
}

const staticRanges = [
  {
    label: "Last Week",
    range: () => ({
      startDate: subWeeks(getUTCDate(), 1),
      endDate: getUTCDate(),
    }),
    isSelected(range: any) {
      const definedRange = this.range();
      return (
        isSameDay(range.startDate, definedRange.startDate) &&
        isSameDay(range.endDate, definedRange.endDate)
      );
    },
  },
  {
    label: "Last 2 Weeks",
    range: () => ({
      startDate: subWeeks(getUTCDate(), 2),
      endDate: getUTCDate(),
    }),
    isSelected(range: any) {
      const definedRange = this.range();
      return (
        isSameDay(range.startDate, definedRange.startDate) &&
        isSameDay(range.endDate, definedRange.endDate)
      );
    },
  },
  {
    label: "Last Month",
    range: () => ({
      startDate: subMonths(getUTCDate(), 1),
      endDate: getUTCDate(),
    }),
    isSelected(range: any) {
      const definedRange = this.range();
      return (
        isSameDay(range.startDate, definedRange.startDate) &&
        isSameDay(range.endDate, definedRange.endDate)
      );
    },
  },
  {
    label: "Last 2 Months",
    range: () => ({
      startDate: subMonths(getUTCDate(), 2),
      endDate: getUTCDate(),
    }),
    isSelected(range: any) {
      const definedRange = this.range();
      return (
        isSameDay(range.startDate, definedRange.startDate) &&
        isSameDay(range.endDate, definedRange.endDate)
      );
    },
  },
  {
    label: "Last 3 Months",
    range: () => ({
      startDate: subMonths(getUTCDate(), 3),
      endDate: getUTCDate(),
    }),
    isSelected(range: any) {
      const definedRange = this.range();
      return (
        isSameDay(range.startDate, definedRange.startDate) &&
        isSameDay(range.endDate, definedRange.endDate)
      );
    },
  },
];

const DateRange: React.FC<IDateRange> = ({
  isFutureDate = false,
  onChange,
  value,
}) => {
  const [state, setState] = useState<any>([
    {
      startDate: !!value?.start_date?.length
        ? getUTCDate(value?.start_date)
        : getUTCDate(),
      endDate: !!value?.end_date?.length
        ? getUTCDate(value?.end_date)
        : subWeeks(getUTCDate(), 1),
      key: "selection",
    },
  ]);

  useEffect(() => {
    if (!value || !Object.keys(value)?.length) return;
    setState([
      {
        ...state[0],
        startDate: !!value?.start_date?.length
          ? getUTCDate(value?.start_date)
          : getUTCDate(),
        endDate: !!value?.end_date?.length
          ? getUTCDate(value?.end_date)
          : subWeeks(getUTCDate(), 1),
      },
    ]);
  }, [value]);

  const handleDate = (selection: any) => {
    const newSelection = {
      ...selection,
      startDate: new Date(
        new Date(selection?.startDate)?.toLocaleString("en-US", {
          timeZone: "UTC",
        })
      ),
      endDate: new Date(
        new Date(selection?.endDate)?.toLocaleString("en-US", {
          timeZone: "UTC",
        })
      ),
    };
    setState([newSelection]);
    onChange({
      start_date: getApiDate(selection?.startDate) || "",
      end_date: getApiDate(selection?.endDate) || "",
    });
  };

  return (
    <DateRangePicker
      // @ts-ignore
      onChange={(item) => handleDate(item?.selection)}
      // @ts-ignore
      showSelectionPreview={true}
      moveRangeOnFirstSelection={false}
      months={2}
      ranges={state}
      direction="horizontal"
      preventSnapRefocus={true}
      calendarFocus="backwards"
      staticRanges={staticRanges}
      maxDate={!isFutureDate ? getUTCDate() : undefined}
    />
  );
};

export default DateRange;
