import { GlobalContext } from "context";
import { useContext } from "react";
import { AvailabilityProps } from "types";
import { getDecimalFormating } from "utils";

export interface StyledLeaseRateValueProps {
  availability: AvailabilityProps;
  valueKey: keyof AvailabilityProps | "suite_name" | "price";
}

const StyledLeaseRateValue: React.FC<StyledLeaseRateValueProps> = ({
  availability,
  valueKey,
}) => {
  const { state } = useContext(GlobalContext);
  const { meta } = state;

  return (
    <>
      {(() => {
        switch (valueKey) {
          case "suite_name":
            return <>Suite {availability?.name || ""}</>;
            
          case "price":
            if (!availability?.min_price && !availability?.max_price) {
              return <>Negotiable</>;
            }
            if (availability?.min_price && !availability?.max_price) {
              return (
                <>${getDecimalFormating(Number(availability?.min_price))}</>
              );
            }
            if (!availability?.min_price && availability?.max_price) {
              return (
                <>${getDecimalFormating(Number(availability?.max_price))}</>
              );
            }

            if (availability?.min_price === availability?.max_price) {
              return (
                <>${getDecimalFormating(Number(availability?.max_price))}</>
              );
            }

            if (availability?.min_price && availability?.max_price) {
              return (
                <>
                  ${getDecimalFormating(Number(availability?.min_price))}- $
                  {getDecimalFormating(Number(availability?.max_price))}
                </>
              );
            }
            return <>-</>;

          case "rent_type":
            if (
              !availability?.["rent_type"] ||
              !meta?.suite?.[valueKey]?.[availability?.["rent_type"]]
            )
              return <>-</>;
            return (
              <>
                {meta?.suite?.[valueKey]?.[availability?.["rent_type"]]?.value}
              </>
            );

          default:
            if (availability?.hasOwnProperty(valueKey)) {
              return <>{availability[valueKey]}</>;
            }
            return <>-</>;
        }
      })()}
    </>
  );
};

export default StyledLeaseRateValue;
