export const emailHeaderJSON = [
  {
    id: "lazjNWo4-6",
    cells: [1],
    columns: [
      {
        id: "uTefM_NDt7",
        contents: [
          {
            id: "YM8tpKOKKV",
            type: "text",
            values: {
              containerPadding: "10px",
              anchor: "",
              fontSize: "14px",
              textAlign: "left",
              lineHeight: "140%",
              linkStyle: {
                inherit: true,
                linkColor: "#0000ee",
                linkHoverColor: "#0000ee",
                linkUnderline: true,
                linkHoverUnderline: true,
              },
              displayCondition: null,
              _meta: {
                htmlID: "u_content_text_18",
                htmlClassNames: "u_content_text",
              },
              selectable: true,
              draggable: true,
              duplicatable: true,
              deletable: true,
              hideable: true,
              text: '<p style="line-height: 140%;">Customize this message here.</p>',
            },
          },
        ],
        values: {
          backgroundColor: "",
          padding: "0px",
          border: {},
          borderRadius: "0px",
          _meta: { htmlID: "u_column_87", htmlClassNames: "u_column" },
        },
      },
    ],
    values: {
      displayCondition: null,
      columns: false,
      backgroundColor: "",
      columnsBackgroundColor: "",
      backgroundImage: {
        url: "",
        fullWidth: true,
        repeat: "no-repeat",
        size: "custom",
        position: "center",
      },
      padding: "0px",
      anchor: "",
      _meta: { htmlID: "u_row_39", htmlClassNames: "u_row" },
      selectable: true,
      draggable: true,
      duplicatable: true,
      deletable: true,
      hideable: true,
    },
  },
  {
    id: "aQBhfNRSbf",
    cells: [1],
    columns: [
      {
        id: "0WM7V_Lr-M",
        contents: [
          {
            id: "_PBx4yE46k",
            type: "divider",
            values: {
              width: "100%",
              border: {
                borderTopWidth: "1px",
                borderTopStyle: "solid",
                borderTopColor: "#BBBBBB",
              },
              textAlign: "center",
              containerPadding: "10px 0px",
              anchor: "",
              displayCondition: null,
              _meta: {
                htmlID: "u_content_divider_18",
                htmlClassNames: "u_content_divider",
              },
              selectable: true,
              draggable: true,
              duplicatable: true,
              deletable: true,
              hideable: true,
            },
          },
        ],
        values: {
          backgroundColor: "",
          padding: "0px",
          border: {},
          borderRadius: "0px",
          _meta: { htmlID: "u_column_88", htmlClassNames: "u_column" },
        },
      },
    ],
    values: {
      displayCondition: null,
      columns: false,
      backgroundColor: "",
      columnsBackgroundColor: "",
      backgroundImage: {
        url: "",
        fullWidth: true,
        repeat: "no-repeat",
        size: "custom",
        position: "center",
      },
      padding: "0px",
      anchor: "",
      _meta: { htmlID: "u_row_40", htmlClassNames: "u_row" },
      selectable: true,
      draggable: true,
      duplicatable: true,
      deletable: true,
      hideable: true,
    },
  },
];
