import React from "react";
import { FiMaximize2 } from "react-icons/fi";

interface IZoomButton {
  className?: string;
  onClick?: any;
}

const ZoomButton: React.FC<IZoomButton> = ({ className, onClick }) => {
  return (
    <div
      className={`flex items-center justify-center rounded-lg cursor-pointer text-base font-semibold z-10 ${className}`}
      onClick={onClick}
    >
      <FiMaximize2 />
    </div>
  );
};

export default ZoomButton;
