import {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import {
  AVAILABILITY_TABS,
  META_TAGS,
  OLM_AVAILABILITY_FIELDS,
  SET_AVAILABILITY,
  SET_CONFIRM_MODAL,
  SET_LOADING_MODAL,
  STATUS_ACTIVE,
  UPDATE_LIGHTGALLERY_DATA,
  URLS,
} from "constant";
import { GlobalContext } from "context";
import { renderToString } from "react-dom/server";
import { useFormik } from "formik";
import { useApiCall, useCallbackPrompt, useWindowDimensions } from "hooks";
import {
  FiChevronLeft,
  FiChevronRight,
  FiExternalLink,
  FiMaximize2,
  FiPlayCircle,
  FiTrash2,
} from "react-icons/fi";
import {
  convertImageUrl,
  patchAvailabilityAPI,
  postImageAPI,
  postVideoAPI,
} from "services";
import { Label, Chip, Button, Dropzone, Toast } from "ui-atoms";
import {
  AccordionItem,
  Card,
  RemoveDescriptionItem,
  VideoUrlFields,
  WarningModal,
} from "ui-molecules";
import { LayoutEditAvailability as Layout } from "ui-organisms";
import validationSchema from "./validationSchema";
import {
  convertExtension,
  joinTwoStr,
  getApiPayloadFromForm,
  ordinalSuffixOf,
  capitalizeWords,
  fetchFile,
  isCommonItem,
} from "utils";
import { INITIAL_VALUES, InitialValuesProps } from "./constants";
import { Helmet } from "react-helmet-async";
import { Link, useParams } from "react-router-dom";
import { GridContextProvider, GridDropZone, GridItem, swap } from "ui-atoms";
import React from "react";
import { FaStreetView } from "react-icons/fa";

interface IAvailabilityMedia {
  diffFields?: any;
  onDiffFields?: any;
}

const AvailabilityMedia = forwardRef(
  ({ onDiffFields, diffFields }: IAvailabilityMedia, ref) => {
    const { state, dispatch } = useContext(GlobalContext);
    const { availability, lightGalleryRef, showTooltip, hideTooltip } = state;
    const [patchAvailability] = useApiCall(patchAvailabilityAPI);
    const [loading, setLoading] = useState(false);
    const { availabilityId, propertyId } = useParams();
    const [isWarningModal, setIsWarningModal] = useState(false);
    const publishStatus = useRef<any>(null);
    const { width } = useWindowDimensions();
    const [showDialog, setShowDialog] = useState<boolean>(false);
    const [showPrompt, confirmNavigation, cancelNavigation] =
      useCallbackPrompt(showDialog);

    const {
      handleSubmit,
      submitForm,
      handleChange,
      handleBlur,
      setFieldValue,
      values,
      errors,
      touched,
      setValues,
      isValid,
    } = useFormik({
      initialValues: INITIAL_VALUES,
      validationSchema,
      onSubmit: async () => {
        setLoading(true);
        dispatch({
          type: SET_LOADING_MODAL,
          payload: {
            open: true,
            label: "Updating Property",
          },
        });
        hideTooltip();
        try {
          let payload: InitialValuesProps | undefined = getApiPayloadFromForm(
            availability,
            values
          );
          if (payload && Object.keys(payload)?.length) {
            if (publishStatus.current) {
              payload.publish_status = publishStatus.current;
            }
            if (
              payload &&
              availability?.publish_status === STATUS_ACTIVE &&
              isCommonItem(Object.keys(payload), OLM_AVAILABILITY_FIELDS)
            ) {
              payload["publish_status"] = STATUS_ACTIVE;
            }
            const response = await patchAvailability({
              id: availability?.id,
              payload,
            });
            setLoading(false);
            publishStatus.current = null;
            setTimeout(() => {
              dispatch({
                type: SET_LOADING_MODAL,
                payload: {
                  open: false,
                  label: null,
                },
              });
            }, 100);
            if (!response) return;
            Toast.success("Changes saved with success");
            dispatch({
              type: SET_AVAILABILITY,
              payload: {
                ...availability,
                ...payload,
              },
            });
          } else {
            Toast.warn("No changes to be saved");
            setLoading(false);
            publishStatus.current = null;
            setTimeout(() => {
              dispatch({
                type: SET_LOADING_MODAL,
                payload: {
                  open: false,
                  label: null,
                },
              });
            }, 100);
          }
        } catch (err) {
          setLoading(false);
          publishStatus.current = null;
          setTimeout(() => {
            dispatch({
              type: SET_LOADING_MODAL,
              payload: {
                open: false,
                label: null,
              },
            });
          }, 100);
        }
      },
    });

    useEffect(() => {
      if (
        availability?.id &&
        !values.id &&
        availability?.id?.toString() === availabilityId?.toString()
      ) {
        let formValues = {
          id: availability.id,
        };
        Object.keys(INITIAL_VALUES).forEach((key: string) => {
          formValues = {
            ...formValues,
            [key]: availability[key] || [],
          };
        });
        setValues({
          ...formValues,
        });
      }
    }, [availability, values, availabilityId]);

    useEffect(() => {
      if (!availability || !values) return;
      onDiffFields(getApiPayloadFromForm(availability, values));
    }, [availability, values]);

    useEffect(() => {
      if (!diffFields || !Object.keys(diffFields)?.length) setShowDialog(false);
      else setShowDialog(true);
    }, [diffFields]);

    useEffect(() => {
      if (!showPrompt) return;
      const action = async () => {
        try {
          if (errors && !!Object.keys(errors)?.length) {
            setIsWarningModal(true);
            // @ts-ignore
            cancelNavigation();
            return;
          }
          await handleSubmit();
          // @ts-ignore
          confirmNavigation();
        } catch (err) {
          // @ts-ignore
          cancelNavigation();
        }
      };

      let isPublish = false;
      if (
        diffFields &&
        availability?.publish_status === STATUS_ACTIVE &&
        isCommonItem(Object.keys(diffFields), OLM_AVAILABILITY_FIELDS)
      ) {
        isPublish = true;
      }

      dispatch({
        type: SET_CONFIRM_MODAL,
        payload: {
          open: true,
          content: {
            description: renderToString(
              <RemoveDescriptionItem description="You have un-saved changes, would you like to save changes before proceeding" />
            ),
            btnTitle: `${isPublish ? "Save and Publish" : "Save"}`,
            cancelTitle: "Proceed without Saving",
            action: action,
            cancel: confirmNavigation,
            additionalCancel: cancelNavigation,
          },
        },
      });
    }, [showPrompt]);

    const handleSubmitImg = async (
      name: string,
      files: any = null,
      url: string = "",
      isVideo: boolean = false
    ) => {
      if (files?.length <= 0 && !url?.length) return;
      dispatch({
        type: SET_LOADING_MODAL,
        payload: {
          open: true,
          label: "Uploading files",
        },
      });
      let results: any[] = [];
      if (files?.length > 0) {
        await Promise.all(
          files?.map(async (file: any) => {
            let result;
            if (!isVideo) result = await postImageAPI(file);
            else result = await postVideoAPI(file);
            if (result) {
              results.push(result);
            }
          })
        );
      } else if (!!url?.length) {
        const file = await fetchFile(url);
        if (!file) results.push(url);
        else {
          let result;
          if (!isVideo) result = await postImageAPI(file);
          else result = await postVideoAPI(file);
          if (result) {
            results.push(result);
          } else {
            results.push(url);
          }
        }
      }
      if (!!results?.length) {
        const data = [...(availability[name] || []), ...results];
        let payload: any = {
          [name]: data,
        };
        if (
          payload &&
          availability?.publish_status === STATUS_ACTIVE &&
          isCommonItem(Object.keys(payload), OLM_AVAILABILITY_FIELDS)
        ) {
          payload["publish_status"] = STATUS_ACTIVE;
        }
        const response = await patchAvailability({
          id: availability?.id,
          payload,
        });
        if (!!response) {
          dispatch({
            type: SET_AVAILABILITY,
            payload: {
              ...availability,
              [name]: response?.[name] || data,
            },
          });
        }
      }
      setTimeout(() => {
        dispatch({
          type: SET_LOADING_MODAL,
          payload: {
            open: false,
            label: null,
          },
        });
      }, 100);
    };

    const removeValue = async (name: string, index: number) => {
      const action = () => {
        const newList = [...availability[name]];
        newList.splice(index, 1);
        let payload: any = {
          [name]: newList,
        };
        if (
          payload &&
          availability?.publish_status === STATUS_ACTIVE &&
          isCommonItem(Object.keys(payload), OLM_AVAILABILITY_FIELDS)
        ) {
          payload["publish_status"] = STATUS_ACTIVE;
        }
        patchAvailability({
          id: availability?.id,
          payload,
        }).then((res: any) => {
          if (!res) return;
          dispatch({
            type: SET_AVAILABILITY,
            payload: {
              ...availability,
              [name]: newList,
            },
          });
        });
      };
      dispatch({
        type: SET_CONFIRM_MODAL,
        payload: {
          open: true,
          content: {
            description: renderToString(
              <RemoveDescriptionItem
                target={`${ordinalSuffixOf(
                  index + 1
                )} Item in ${capitalizeWords(name, "_")}`}
              />
            ),
            action,
          },
        },
      });
    };

    const handleOpenGallery = async (name: string, index: number) => {
      if (availability?.[name]?.[index]?.includes(".pdf")) {
        window.open(availability?.[name]?.[index], "_blank");
        return;
      }
      let arr: any = [];
      availability[name]?.forEach((url: string, idx: number) => {
        arr.push({
          id: idx,
          src: convertImageUrl(convertExtension(url)),
          thumb: convertImageUrl(convertExtension(url)),
          subHtml: renderToString(<span>{idx + 1}</span>),
        });
      });
      await dispatch({
        type: UPDATE_LIGHTGALLERY_DATA,
        payload: arr,
      });
      setTimeout(function () {
        lightGalleryRef.current.openGallery(index);
      }, 100);
    };

    const onClickSubmit = async (publish_status: any = null) => {
      if (errors && !!Object.keys(errors)?.length) {
        setIsWarningModal(true);
      }
      publishStatus.current = publish_status;
      await submitForm();
    };

    useImperativeHandle(ref, () => ({
      onClickSubmit,
    }));

    const handleSwap = async (
      sourceId: string,
      sourceIndex: number,
      targetIndex: number,
      targetId: any
    ) => {
      const oldState = [...availability[sourceId]];
      const nextState = swap(
        [-1, ...availability[sourceId]],
        sourceIndex,
        targetIndex
      );
      dispatch({
        type: SET_AVAILABILITY,
        payload: {
          ...availability,
          [sourceId]: nextState.filter((item) => item !== -1),
        },
      });
      let payload: any = {
        [sourceId]: nextState.filter((item) => item !== -1),
      };
      if (
        payload &&
        availability?.publish_status === STATUS_ACTIVE &&
        isCommonItem(Object.keys(payload), OLM_AVAILABILITY_FIELDS)
      ) {
        payload["publish_status"] = STATUS_ACTIVE;
      }
      const response = await patchAvailability({
        id: availability?.id,
        payload,
      });
      if (response) return;
      dispatch({
        type: SET_AVAILABILITY,
        payload: {
          ...availability,
          [sourceId]: oldState,
        },
      });
    };

    const boxCount = Math.floor((width - 424) / 192);

    return (
      <>
        <Helmet prioritizeSeoTags>
          <title>{`${META_TAGS?.default?.title} ${joinTwoStr(
            availability?.building_title,
            availability?.name,
            " | "
          )} Media`}</title>
          <meta
            property="og:title"
            content={`${META_TAGS?.default?.title} ${joinTwoStr(
              availability?.building_title,
              availability?.name,
              " | "
            )} Media`}
          />
        </Helmet>
        {!!availability?.id && (
          <form>
            <Layout.Section>
              <AccordionItem
                id="images"
                label={`Images (${availability?.images?.length || 0})`}
              >
                <div className="flex flex-col w-full pt-4">
                  <div className="flex flex-row items-center space-x-2">
                    <Label>The first photo will be on the cover.</Label>
                    <Chip variant="secondary" size="small">
                      Optional
                    </Chip>
                  </div>
                  <div className="flex flex-wrap pt-4">
                    <GridContextProvider onChange={handleSwap}>
                      <GridDropZone
                        id="images"
                        boxesPerRow={boxCount}
                        rowHeight={192}
                        className="w-full"
                        style={{
                          height: `${
                            Math.ceil(
                              ((availability?.images || [])?.length + 1) /
                                boxCount
                            ) * 192
                          }px`,
                        }}
                        disableDropIndex={0}
                      >
                        {[-1, ...(availability?.images || [])]?.map(
                          (item: any, idx: number) => {
                            const index = idx - 1;
                            if (index === -1) {
                              return (
                                <GridItem key={item} disabled={true}>
                                  <Dropzone
                                    className="w-44 h-44 mb-4 mr-4"
                                    setDropFiles={(files: any) =>
                                      handleSubmitImg("images", files)
                                    }
                                    title="Add images here,"
                                    submitImg={handleSubmitImg}
                                    name="images"
                                  />
                                </GridItem>
                              );
                            }
                            return (
                              <GridItem key={item}>
                                <Card
                                  className="w-44 h-44 mb-4 mr-4"
                                  chip={
                                    index === 0 ? (
                                      <Chip
                                        variant="primary"
                                        className="absolute top-2 left-2"
                                      >
                                        Cover image
                                      </Chip>
                                    ) : (
                                      <></>
                                    )
                                  }
                                  key={index}
                                  src={convertExtension(item)}
                                  actions={[
                                    <Button
                                      variant="secondary"
                                      leadingIcon={FiMaximize2}
                                      onClick={() =>
                                        handleOpenGallery("images", index)
                                      }
                                    />,
                                    <Button
                                      variant="secondary"
                                      leadingIcon={FiTrash2}
                                      onClick={() =>
                                        removeValue("images", index)
                                      }
                                    />,
                                  ]}
                                />
                              </GridItem>
                            );
                          }
                        )}
                      </GridDropZone>
                    </GridContextProvider>
                  </div>
                </div>
              </AccordionItem>
            </Layout.Section>

            <Layout.Section>
              <AccordionItem
                id="floors"
                label={`Floorplans (${availability?.floorplans?.length || 0})`}
              >
                <div className="flex flex-col w-full pt-4">
                  <div className="flex flex-row items-center space-x-2">
                    <Label>The first floorplan will be on the cover.</Label>
                    <Chip variant="secondary" size="small">
                      Optional
                    </Chip>
                  </div>
                  <div className="flex flex-wrap pt-4">
                    <GridContextProvider onChange={handleSwap}>
                      <GridDropZone
                        id="floorplans"
                        boxesPerRow={boxCount}
                        rowHeight={192}
                        className="w-full"
                        style={{
                          height: `${
                            Math.ceil(
                              ((availability?.floorplans || [])?.length + 1) /
                                boxCount
                            ) * 192
                          }px`,
                        }}
                        disableDropIndex={0}
                      >
                        {[-1, ...(availability?.floorplans || [])]?.map(
                          (src: string, idx: number) => {
                            const index = idx - 1;
                            if (index === -1) {
                              return (
                                <GridItem key={src} disabled={true}>
                                  <Dropzone
                                    className="w-44 h-44 mb-4 mr-4"
                                    setDropFiles={(files: any) =>
                                      handleSubmitImg("floorplans", files)
                                    }
                                    title="Add floorplans here,"
                                    submitImg={handleSubmitImg}
                                    name="floorplans"
                                  />
                                </GridItem>
                              );
                            }
                            return (
                              <GridItem key={src}>
                                <Card
                                  className="w-44 h-44 mb-4 mr-4"
                                  key={index}
                                  chip={
                                    index === 0 ? (
                                      <Chip
                                        variant="primary"
                                        className="absolute top-2 left-2"
                                      >
                                        Floor Plan
                                      </Chip>
                                    ) : (
                                      <></>
                                    )
                                  }
                                  src={convertExtension(src)}
                                  actions={[
                                    <Button
                                      variant="secondary"
                                      leadingIcon={FiMaximize2}
                                      onClick={() =>
                                        handleOpenGallery("floorplans", index)
                                      }
                                    />,
                                    <Button
                                      variant="secondary"
                                      leadingIcon={FiTrash2}
                                      onClick={() =>
                                        removeValue("floorplans", index)
                                      }
                                    />,
                                  ]}
                                />
                              </GridItem>
                            );
                          }
                        )}
                      </GridDropZone>
                    </GridContextProvider>
                  </div>
                </div>
              </AccordionItem>
            </Layout.Section>

            <Layout.Section>
              <AccordionItem
                id="brochures"
                label={`Brochures (${availability?.brochures?.length || 0})`}
              >
                <div className="flex flex-col w-full pt-4">
                  <div className="flex  flex-row items-center space-x-2">
                    <Label>The first brochure will be on the cover.</Label>
                    <Chip variant="secondary" size="small">
                      Optional
                    </Chip>
                  </div>
                  <div className="flex flex-wrap pt-4">
                    <GridContextProvider onChange={handleSwap}>
                      <GridDropZone
                        id="brochures"
                        boxesPerRow={boxCount}
                        rowHeight={192}
                        className="w-full"
                        style={{
                          height: `${
                            Math.ceil(
                              ((availability?.brochures || [])?.length + 1) /
                                boxCount
                            ) * 192
                          }px`,
                        }}
                        disableDropIndex={0}
                      >
                        {[-1, ...(availability?.brochures || [])]?.map(
                          (src: string, idx: number) => {
                            const index = idx - 1;
                            if (index === -1) {
                              return (
                                <GridItem key={src} disabled={true}>
                                  <Dropzone
                                    className="w-44 h-44 mb-4 mr-4"
                                    setDropFiles={(files: any) =>
                                      handleSubmitImg("brochures", files)
                                    }
                                    setFileUrl={(url: string) => {
                                      handleSubmitImg("brochures", null, url);
                                    }}
                                    title="Add brochures here,"
                                    submitImg={handleSubmitImg}
                                    name="brochures"
                                    isInteractive
                                  />
                                </GridItem>
                              );
                            }
                            const isCloudaryFile = src?.includes("cloudinary")
                              ? true
                              : false;
                            return (
                              <GridItem key={src}>
                                <Card
                                  className="w-44 h-44 mb-4 mr-4"
                                  key={index}
                                  chip={
                                    index === 0 ? (
                                      <Chip
                                        variant="primary"
                                        className="absolute top-2 left-2"
                                      >
                                        Brochure
                                      </Chip>
                                    ) : !isCloudaryFile ? (
                                      <Chip
                                        variant="primary"
                                        className="absolute top-2 left-2"
                                      >
                                        Interactive Brochure
                                      </Chip>
                                    ) : (
                                      <></>
                                    )
                                  }
                                  src={convertExtension(src)}
                                  isInteractive
                                  actions={[
                                    !isCloudaryFile ? (
                                      <Button
                                        variant="secondary"
                                        leadingIcon={FiExternalLink}
                                        onClick={() => {
                                          window.open(src, "_blank");
                                          hideTooltip();
                                        }}
                                        id={`copy-url-${idx}`}
                                        onMouseOver={() =>
                                          showTooltip(`copy-url-${idx}`)
                                        }
                                        onMouseLeave={() => hideTooltip()}
                                        data-tooltip-content="Please click to load interactive brochure"
                                      />
                                    ) : (
                                      <Button
                                        variant="secondary"
                                        leadingIcon={FiMaximize2}
                                        onClick={() =>
                                          handleOpenGallery("brochures", index)
                                        }
                                      />
                                    ),
                                    <Button
                                      variant="secondary"
                                      leadingIcon={FiTrash2}
                                      onClick={() =>
                                        removeValue("brochures", index)
                                      }
                                    />,
                                  ]}
                                />
                              </GridItem>
                            );
                          }
                        )}
                      </GridDropZone>
                    </GridContextProvider>
                  </div>
                </div>
              </AccordionItem>
            </Layout.Section>

            <Layout.Section>
              <AccordionItem
                id="videos"
                label={`Videos (${values?.videos?.length || 0})`}
              >
                <div className="flex flex-col w-full pt-4">
                  <div className="flex flex-row items-center space-x-2 mb-2">
                    <Label>Link to video</Label>
                    <Chip variant="secondary" size="small">
                      Optional
                    </Chip>
                  </div>
                  <div className="flex flex-wrap pt-4">
                    <GridContextProvider onChange={handleSwap}>
                      <GridDropZone
                        id="videos"
                        boxesPerRow={boxCount}
                        rowHeight={192}
                        className="w-full"
                        style={{
                          height: `${
                            Math.ceil(
                              ((availability?.videos || [])?.length + 1) /
                                boxCount
                            ) * 192
                          }px`,
                        }}
                        disableDropIndex={0}
                      >
                        {[-1, ...(availability?.videos || [])]?.map(
                          (src: string, idx: number) => {
                            const index = idx - 1;
                            if (index === -1) {
                              return (
                                <GridItem key={src} disabled={true}>
                                  <Dropzone
                                    className="w-44 h-44 mb-4 mr-4"
                                    setDropFiles={(files: any) =>
                                      handleSubmitImg("videos", files, "", true)
                                    }
                                    setFileUrl={(url: string) => {
                                      handleSubmitImg(
                                        "videos",
                                        null,
                                        url,
                                        true
                                      );
                                    }}
                                    title="Add videos here,"
                                    submitImg={handleSubmitImg}
                                    name="videos"
                                    isInteractive
                                    icon={FiPlayCircle}
                                    acceptType="video/*"
                                  />
                                </GridItem>
                              );
                            }
                            const isCloudaryFile = src?.includes("cloudinary")
                              ? true
                              : false;
                            return (
                              <GridItem key={src}>
                                <Card
                                  className="w-44 h-44 mb-4 mr-4"
                                  src={convertExtension(src)}
                                  isInteractive
                                  actions={[
                                    !isCloudaryFile ? (
                                      <Button
                                        variant="secondary"
                                        leadingIcon={FiExternalLink}
                                        onClick={() => {
                                          window.open(src, "_blank");
                                          hideTooltip();
                                        }}
                                        id={`video-copy-url-${idx}`}
                                        onMouseOver={() =>
                                          showTooltip(`video-copy-url-${idx}`)
                                        }
                                        onMouseLeave={() => hideTooltip()}
                                        data-tooltip-content="Please click to load interactive videos"
                                      />
                                    ) : (
                                      <Button
                                        variant="secondary"
                                        leadingIcon={FiMaximize2}
                                        onClick={() => {
                                          window.open(src, "_blank");
                                        }}
                                      />
                                    ),
                                    <Button
                                      variant="secondary"
                                      leadingIcon={FiTrash2}
                                      onClick={() =>
                                        removeValue("videos", index)
                                      }
                                    />,
                                  ]}
                                />
                              </GridItem>
                            );
                          }
                        )}
                      </GridDropZone>
                    </GridContextProvider>
                  </div>
                </div>
              </AccordionItem>
            </Layout.Section>

            <Layout.Section>
              <AccordionItem
                id="virtual"
                label={`Virtual Tour (${values?.virtual_tours?.length || 0})`}
              >
                <div className="flex flex-col w-full pt-4">
                  <div className="flex flex-row items-center space-x-2 mb-2">
                    <Label>Link to Virtual Tour</Label>
                    <Chip variant="secondary" size="small">
                      Optional
                    </Chip>
                  </div>
                  <div className="flex flex-wrap pt-4">
                    <GridContextProvider onChange={handleSwap}>
                      <GridDropZone
                        id="virtual_tours"
                        boxesPerRow={boxCount}
                        rowHeight={192}
                        className="w-full"
                        style={{
                          height: `${
                            Math.ceil(
                              ((availability?.virtual_tours || [])?.length +
                                1) /
                                boxCount
                            ) * 192
                          }px`,
                        }}
                        disableDropIndex={0}
                      >
                        {[-1, ...(availability?.virtual_tours || [])]?.map(
                          (src: string, idx: number) => {
                            const index = idx - 1;
                            if (index === -1) {
                              return (
                                <GridItem key={src} disabled={true}>
                                  <Dropzone
                                    className="w-44 h-44 mb-4 mr-4"
                                    setDropFiles={(files: any) =>
                                      handleSubmitImg("virtual_tours", files)
                                    }
                                    setFileUrl={(url: string) => {
                                      handleSubmitImg(
                                        "virtual_tours",
                                        null,
                                        url
                                      );
                                    }}
                                    title="Add virtual tours here,"
                                    submitImg={handleSubmitImg}
                                    name="virtual_tours"
                                    isInteractive
                                    icon={FaStreetView}
                                  />
                                </GridItem>
                              );
                            }
                            const isCloudaryFile = src?.includes("cloudinary")
                              ? true
                              : false;
                            return (
                              <GridItem key={src}>
                                <Card
                                  className="w-44 h-44 mb-4 mr-4"
                                  src={convertExtension(src)}
                                  isInteractive
                                  actions={[
                                    !isCloudaryFile ? (
                                      <Button
                                        variant="secondary"
                                        leadingIcon={FiExternalLink}
                                        onClick={() => {
                                          window.open(src, "_blank");
                                          hideTooltip();
                                        }}
                                        id={`virtual-copy-url-${idx}`}
                                        onMouseOver={() =>
                                          showTooltip(`virtual-copy-url-${idx}`)
                                        }
                                        onMouseLeave={() => hideTooltip()}
                                        data-tooltip-content="Please click to load interactive virtual tours"
                                      />
                                    ) : (
                                      <Button
                                        variant="secondary"
                                        leadingIcon={FiMaximize2}
                                        onClick={() => {
                                          window.open(src, "_blank");
                                        }}
                                      />
                                    ),
                                    <Button
                                      variant="secondary"
                                      leadingIcon={FiTrash2}
                                      onClick={() =>
                                        removeValue("virtual_tours", index)
                                      }
                                    />,
                                  ]}
                                />
                              </GridItem>
                            );
                          }
                        )}
                      </GridDropZone>
                    </GridContextProvider>
                  </div>
                </div>
              </AccordionItem>
            </Layout.Section>

            <div className="px-8 fixed bottom-0 left-[360px] right-0 flex justify-end bg-white shadow-detail-status-nav h-[80px] items-center">
              <div className="flex space-x-4">
                <div className="flex space-x-2">
                  <Link
                    to={`/property/${propertyId}/availability/${availabilityId}/${URLS.AVAILABILITY.FORM}?tab=${AVAILABILITY_TABS.CONTACTS}`}
                  >
                    <Button variant="secondary" leadingIcon={FiChevronLeft}>
                      Previous
                    </Button>
                  </Link>
                  <Link
                    to={`/property/${propertyId}/availability/${availabilityId}/${URLS.AVAILABILITY.FORM}?tab=${AVAILABILITY_TABS.PUBLISH}`}
                  >
                    <Button variant="secondary" trailingIcon={FiChevronRight}>
                      Next
                    </Button>
                  </Link>
                </div>

                <Button
                  disabled={
                    loading || !diffFields || !Object.keys(diffFields)?.length
                  }
                  onClick={() => onClickSubmit()}
                  variant="primary"
                  id="save-tooltip"
                  onMouseOver={() => showTooltip("save-tooltip")}
                  onMouseLeave={() => hideTooltip()}
                  data-tooltip-content={
                    availability?.publish_status === STATUS_ACTIVE
                      ? "This saves the availability in MyListings and publishes to OLM"
                      : "This saves the availability, but does not publish to OLM"
                  }
                >
                  {availability?.publish_status === STATUS_ACTIVE
                    ? "Save and Publish"
                    : "Save"}
                </Button>
              </div>
            </div>
          </form>
        )}
        <WarningModal
          isOpen={isWarningModal}
          setIsOpen={setIsWarningModal}
          errors={errors}
        />
      </>
    );
  }
);

export default React.memo(AvailabilityMedia);
