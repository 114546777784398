import queryString from "query-string";
import axios from "../axiosConfig";
import { APIProps } from "types";
import { API_PREFIX_URL } from "constant";

export const getTeamListingDeliverableLinkAPI = async (props: APIProps) => {
  const uuid = props?.uuid;
  delete props?.uuid;
  const param_str = queryString.stringify(props, {
    arrayFormat: "bracket",
  });
  const res = await axios.get(
    `${API_PREFIX_URL}/team-listing-link/deliverable/${uuid}/?${param_str}`
  );
  return res;
};
