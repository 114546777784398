export const emailPropertyImagesJSON = [
  {
    id: "Ee6gjvTz9v",
    cells: [1],
    columns: [
      {
        id: "UEhSKddcvR",
        contents: [
          {
            id: "AdKnzUUjd1",
            type: "heading",
            values: {
              containerPadding: "10px",
              anchor: "",
              headingType: "h4",
              fontWeight: 700,
              fontSize: "14px",
              textAlign: "left",
              lineHeight: "140%",
              linkStyle: {
                inherit: true,
                linkColor: "#0000ee",
                linkHoverColor: "#0000ee",
                linkUnderline: true,
                linkHoverUnderline: true,
                body: false,
              },
              hideDesktop: false,
              displayCondition: null,
              _meta: {
                htmlID: "u_content_heading_12",
                htmlClassNames: "u_content_heading",
              },
              selectable: true,
              draggable: true,
              duplicatable: true,
              deletable: true,
              hideable: true,
              text: "Property Images",
            },
          },
        ],
        values: {
          backgroundColor: "#f6f7f7",
          padding: "0px",
          border: {},
          borderRadius: "0px",
          _meta: { htmlID: "u_column_34", htmlClassNames: "u_column" },
        },
      },
    ],
    values: {
      displayCondition: null,
      columns: false,
      backgroundColor: "",
      columnsBackgroundColor: "",
      backgroundImage: {
        url: "",
        fullWidth: true,
        repeat: "no-repeat",
        size: "custom",
        position: "center",
      },
      padding: "0px",
      anchor: "",
      hideDesktop: false,
      _meta: { htmlID: "u_row_13", htmlClassNames: "u_row" },
      selectable: true,
      draggable: true,
      duplicatable: true,
      deletable: true,
      hideable: true,
    },
  },
  {
    id: "property_images",
    cells: [1, 1, 1, 1],
    columns: [
      {
        id: "YEP4bKlXh2",
        contents: [
          {
            id: "kmwcrrXRay",
            type: "image",
            values: {
              containerPadding: "10px",
              anchor: "",
              src: {
                url: "",
                width: 800,
                height: 200,
              },
              textAlign: "center",
              altText: "",
              action: {
                name: "web",
                attrs: { href: "{{href}}", target: "{{target}}" },
                values: { href: "", target: "_blank" },
              },
              hideDesktop: false,
              displayCondition: null,
              _meta: {
                htmlID: "u_content_image_11",
                htmlClassNames: "u_content_image",
              },
              selectable: true,
              draggable: true,
              duplicatable: true,
              deletable: true,
              hideable: true,
            },
          },
        ],
        values: {
          backgroundColor: "#f6f7f7",
          padding: "0px",
          border: {},
          borderRadius: "0px",
          _meta: { htmlID: "u_column_35", htmlClassNames: "u_column" },
        },
      },
      {
        id: "t5MEHy4V8M",
        contents: [
          {
            id: "dUk74-AkEX",
            type: "image",
            values: {
              containerPadding: "10px",
              anchor: "",
              src: {
                url: "",
                width: 800,
                height: 200,
              },
              textAlign: "center",
              altText: "",
              action: {
                name: "web",
                attrs: { href: "{{href}}", target: "{{target}}" },
                values: { href: "", target: "_blank" },
              },
              hideDesktop: false,
              displayCondition: null,
              _meta: {
                htmlID: "u_content_image_12",
                htmlClassNames: "u_content_image",
              },
              selectable: true,
              draggable: true,
              duplicatable: true,
              deletable: true,
              hideable: true,
            },
          },
        ],
        values: {
          backgroundColor: "#f6f7f7",
          padding: "0px",
          border: {},
          borderRadius: "0px",
          _meta: { htmlID: "u_column_36", htmlClassNames: "u_column" },
        },
      },
      {
        id: "RiccN7DwbG",
        contents: [
          {
            id: "RZdwXnZNnr",
            type: "image",
            values: {
              containerPadding: "10px",
              anchor: "",
              src: {
                url: "",
                width: 800,
                height: 200,
              },
              textAlign: "center",
              altText: "",
              action: {
                name: "web",
                attrs: { href: "{{href}}", target: "{{target}}" },
                values: { href: "", target: "_blank" },
              },
              hideDesktop: false,
              displayCondition: null,
              _meta: {
                htmlID: "u_content_image_13",
                htmlClassNames: "u_content_image",
              },
              selectable: true,
              draggable: true,
              duplicatable: true,
              deletable: true,
              hideable: true,
            },
          },
        ],
        values: {
          backgroundColor: "#f6f7f7",
          padding: "0px",
          border: {},
          borderRadius: "0px",
          _meta: { htmlID: "u_column_37", htmlClassNames: "u_column" },
        },
      },
      {
        id: "oycsV2KrFp",
        contents: [
          {
            id: "CoeCvoOd3q",
            type: "image",
            values: {
              containerPadding: "10px",
              anchor: "",
              src: {
                url: "",
                width: 800,
                height: 200,
              },
              textAlign: "center",
              altText: "",
              action: {
                name: "web",
                attrs: { href: "{{href}}", target: "{{target}}" },
                values: { href: "", target: "_blank" },
              },
              hideDesktop: false,
              displayCondition: null,
              _meta: {
                htmlID: "u_content_image_14",
                htmlClassNames: "u_content_image",
              },
              selectable: true,
              draggable: true,
              duplicatable: true,
              deletable: true,
              hideable: true,
            },
          },
        ],
        values: {
          backgroundColor: "#f6f7f7",
          padding: "0px",
          border: {},
          borderRadius: "0px",
          _meta: { htmlID: "u_column_38", htmlClassNames: "u_column" },
        },
      },
    ],
    values: {
      displayCondition: null,
      columns: false,
      backgroundColor: "",
      columnsBackgroundColor: "",
      backgroundImage: {
        url: "",
        fullWidth: true,
        repeat: "no-repeat",
        size: "custom",
        position: "center",
      },
      padding: "0px",
      anchor: "",
      hideDesktop: false,
      _meta: { htmlID: "u_row_14", htmlClassNames: "u_row" },
      selectable: true,
      draggable: true,
      duplicatable: true,
      deletable: true,
      hideable: true,
    },
  },
  {
    id: "rl_E1KISST",
    cells: [1],
    columns: [
      {
        id: "JbayvCTb8k",
        contents: [
          {
            id: "9vF0hv-N4H",
            type: "divider",
            values: {
              width: "100%",
              border: {
                borderTopWidth: "1px",
                borderTopStyle: "solid",
                borderTopColor: "#BBBBBB",
              },
              textAlign: "center",
              containerPadding: "0px",
              anchor: "",
              hideDesktop: false,
              displayCondition: null,
              _meta: {
                htmlID: "u_content_divider_10",
                htmlClassNames: "u_content_divider",
              },
              selectable: true,
              draggable: true,
              duplicatable: true,
              deletable: true,
              hideable: true,
            },
          },
        ],
        values: {
          backgroundColor: "#f6f7f7",
          padding: "5px",
          border: {},
          borderRadius: "0px",
          _meta: { htmlID: "u_column_39", htmlClassNames: "u_column" },
        },
      },
    ],
    values: {
      displayCondition: null,
      columns: false,
      backgroundColor: "",
      columnsBackgroundColor: "",
      backgroundImage: {
        url: "",
        fullWidth: true,
        repeat: "no-repeat",
        size: "custom",
        position: "center",
      },
      padding: "0px",
      anchor: "",
      hideDesktop: false,
      _meta: { htmlID: "u_row_15", htmlClassNames: "u_row" },
      selectable: true,
      draggable: true,
      duplicatable: true,
      deletable: true,
      hideable: true,
    },
  },
];
