import { FiAlertTriangle, FiChevronLeft } from "react-icons/fi";
import { Button } from "ui-atoms";
import Modal from "ui-molecules/Modal/Modal";
import FieldList from "./FieldList";

interface IWarningModal {
  isOpen: boolean;
  setIsOpen: any;
  errors?: any;
}

const WarningModal: React.FC<IWarningModal> = ({
  isOpen,
  setIsOpen,
  errors,
}) => {
  return (
    <Modal isOpen={isOpen} setIsOpen={() => setIsOpen(false)} size="medium">
      <Modal.Header>
        <div className="flex justify-center items-center flex-col">
          <FiAlertTriangle className="h-16 w-16 text-jll-color-coldGray-4" />
        </div>
      </Modal.Header>
      <Modal.Body className="pt-4">
        <FieldList data={errors}/>
      </Modal.Body>
      <Modal.Footer className="justify-center !mt-0">
        <Button
          variant="primary"
          leadingIcon={FiChevronLeft}
          leadingIconClass="w-4 h-4 mr-2"
          onClick={() => setIsOpen(false)}
        >
          Go Back
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default WarningModal;
