import {
  FacetsProps,
  BucketProps,
  FacetProps,
  FilteredFacetProps,
} from "types";
import { capitalize } from "./text";

export const getFilteredFacets = (facets: FacetsProps) => {
  return Object.keys(facets)
    .filter((key) => key !== "count")
    .map((key) => {
      // @ts-ignore fix ts(7053)
      const buckets: BucketProps[] = facets[key].buckets;
      let additional: any = [];

      if (!!buckets?.length && (key === "coordinator" || key === "broker")) {
        additional.push({
          count: (facets[key] as FacetProps)?.missing?.count || 0,
          label: "Missing",
          value: `${key}_missing`,
          id: `${key}_missing`,
        });
      }

      return {
        id: key,
        label: capitalize(key.replaceAll("_", " ")),
        facets: [
          ...additional,
          ...buckets.map((facet) => {
            let label = facet?.name || facet.val;
            if (label?.toString()?.toLowerCase() === "removed")
              label = "Inactive";
            return {
              count: facet.count,
              label: label,
              value: facet.val,
              id:
                typeof facet?.val === "string"
                  ? `${key}_${facet.val.toLowerCase().replaceAll(" ", "_")}`
                  : `${key}_${facet?.val}`,
            };
          }),
        ],
      };
    });
};

export const getOrderedFacets = (
  facets: FilteredFacetProps[],
  sortList: string[]
) => {
  const sortedFacets = facets.sort((a, b) => {
    return sortList.indexOf(a.id) - sortList.indexOf(b.id);
  });
  return sortedFacets;
};
