import {
  InitialValuesProps,
  META_TAGS,
  OLM_PROPERTY_FIELDS,
  PROPERTY_TABS,
  SET_CONFIRM_MODAL,
  SET_LOADING_MODAL,
  SET_PROPERTY,
  STATUS_ACTIVE,
  URLS,
} from "constant";
import { GlobalContext } from "context";
import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import {
  PROPERTY_OCCUPANCY_INITIAL_VALUES,
  PROPERTY_OCCUPANCY_VALIDATION_SCHEMA,
} from "./constants";
import { Link, useParams } from "react-router-dom";
import { LayoutEditProperty as Layout } from "ui-organisms";
import { Button, Input, Label, SelectAutoComplete, Toast } from "ui-atoms";
import { getApiPayloadFromForm, getMetaOptions, isCommonItem } from "utils";
import { FiChevronRight } from "react-icons/fi";
import { useApiCall, useCallbackPrompt } from "hooks";
import { patchPropertyAPI } from "services";
import React from "react";
import { renderToString } from "react-dom/server";
import { RemoveDescriptionItem, WarningModal } from "ui-molecules";

interface IPropertyOccupancyV2 {
  onDiffFields?: any;
  diffFields?: any;
}

const PropertyOccupancyV2: React.FC<IPropertyOccupancyV2> = ({
  onDiffFields,
  diffFields,
}) => {
  const { state, dispatch } = useContext(GlobalContext);
  const { property, meta, showTooltip, hideTooltip } = state;
  const { propertyId } = useParams();
  const [patchProperty] = useApiCall(patchPropertyAPI);
  const [isWarningModal, setIsWarningModal] = useState(false);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(showDialog);

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    values,
    errors,
    touched,
    setValues,
  } = useFormik({
    initialValues: PROPERTY_OCCUPANCY_INITIAL_VALUES,
    validationSchema: PROPERTY_OCCUPANCY_VALIDATION_SCHEMA,
    onSubmit: async () => {
      try {
        dispatch({
          type: SET_LOADING_MODAL,
          payload: {
            open: true,
            label: "Updating Property",
          },
        });
        let payload: InitialValuesProps | undefined = getApiPayloadFromForm(
          property,
          values
        );
        if (
          payload &&
          property?.publish_status === STATUS_ACTIVE &&
          isCommonItem(Object.keys(payload || {}), OLM_PROPERTY_FIELDS)
        ) {
          payload["publish_status"] = STATUS_ACTIVE;
        }
        const response = await patchProperty({
          id: property.id,
          payload,
        });
        setTimeout(() => {
          dispatch({
            type: SET_LOADING_MODAL,
            payload: {
              open: false,
              label: null,
            },
          });
        }, 100);
        if (!response) return;
        Toast.success("Changes saved with success");
        dispatch({
          type: SET_PROPERTY,
          payload: response,
        });
      } catch (err) {
        Toast.warn("No changes to be saved");
        setTimeout(() => {
          dispatch({
            type: SET_LOADING_MODAL,
            payload: {
              open: false,
              label: null,
            },
          });
        }, 100);
      }
    },
  });

  useEffect(() => {
    if (!property || property?.id?.toString() !== propertyId?.toString())
      return;
    let formValues = {};
    Object.keys(PROPERTY_OCCUPANCY_INITIAL_VALUES).forEach((key: string) => {
      formValues = {
        ...formValues,
        [key]: property[key],
      };
    });
    setValues({ ...formValues });
  }, [property]);

  useEffect(() => {
    if (!property || !values) return;
    const diff = getApiPayloadFromForm(property, values);
    onDiffFields(diff);
  }, [property, values]);

  useEffect(() => {
    if (!diffFields || !Object.keys(diffFields)?.length) setShowDialog(false);
    else setShowDialog(true);
  }, [diffFields]);

  useEffect(() => {
    if (!showPrompt) return;
    const action = async () => {
      try {
        if (errors && !!Object.keys(errors)?.length) {
          setIsWarningModal(true);
          // @ts-ignore
          cancelNavigation();
          return;
        }
        await handleSubmit();
        // @ts-ignore
        confirmNavigation();
      } catch (err) {
        // @ts-ignore
        cancelNavigation();
      }
    };
    let isPublish = false;
    if (
      diffFields &&
      property?.publish_status === STATUS_ACTIVE &&
      isCommonItem(Object.keys(diffFields), OLM_PROPERTY_FIELDS)
    ) {
      isPublish = true;
    }

    dispatch({
      type: SET_CONFIRM_MODAL,
      payload: {
        open: true,
        content: {
          description: renderToString(
            <RemoveDescriptionItem description="You have un-saved changes, would you like to save changes before proceeding" />
          ),
          btnTitle: `${isPublish ? "Save and Publish" : "Save"}`,
          cancelTitle: "Proceed without Saving",
          action: action,
          cancel: confirmNavigation,
          additionalCancel: cancelNavigation,
        },
      },
    });
  }, [showPrompt]);

  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>{`${META_TAGS?.default?.title} ${
          property?.title || property?.address
        } Occupancy`}</title>
        <meta
          property="og:title"
          content={`${META_TAGS?.default?.title} ${
            property?.title || property?.address
          } Occupancy`}
        />
      </Helmet>

      <form onSubmit={handleSubmit}>
        <Layout.Section>
          <div className="flex flex-col w-full">
            <div className="flex flex-row items-center space-x-2">
              <Label className="w-4/12 mb-6" optional>
                Direct Available
              </Label>
              <div className="flex flex-row space-x-2 w-full">
                <Input
                  className="w-full"
                  type="number"
                  name="direct_available_space"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.direct_available_space}
                  error={
                    touched.direct_available_space
                      ? errors.direct_available_space
                      : ""
                  }
                />
                <SelectAutoComplete
                  className="w-[200px]"
                  name="direct_available_space_unit"
                  onChange={(option) => {
                    if (!option)
                      setFieldValue("direct_available_space_unit", null);
                    else
                      setFieldValue(
                        "direct_available_space_unit",
                        option.value
                      );
                  }}
                  onBlur={handleBlur}
                  value={{
                    value: values?.direct_available_space_unit,
                    label:
                      meta?.building?.direct_available_space_unit[
                        values?.direct_available_space_unit
                      ]?.value,
                  }}
                  error={
                    touched.direct_available_space_unit
                      ? errors.direct_available_space_unit
                      : ""
                  }
                  options={[
                    ...getMetaOptions(
                      meta?.building?.direct_available_space_unit
                    ),
                  ]}
                />
              </div>
            </div>

            <div className="flex flex-row items-center space-x-2">
              <Label className="w-4/12 mb-6" optional>
                Direct % Leased
              </Label>
              <Input
                className="w-full"
                type="number"
                trailing="%"
                name="direct_percentage_leased"
                error={
                  touched.direct_percentage_leased
                    ? errors.direct_percentage_leased
                    : ""
                }
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Enter percent..."
                value={values?.direct_percentage_leased}
              />
            </div>

            <div className="flex flex-row items-center space-x-2">
              <Label className="w-4/12 mb-6" optional>
                % Subleased
              </Label>
              <Input
                className="w-full"
                type="number"
                trailing="%"
                name="percentage_subleased"
                error={
                  touched.percentage_subleased
                    ? errors.percentage_subleased
                    : ""
                }
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Enter percent..."
                value={values?.percentage_subleased}
              />
            </div>

            <div className="flex flex-row items-center space-x-2">
              <Label className="w-4/12 mb-6" optional>
                Large Block Available
              </Label>
              <div className="flex flex-row space-x-2 w-full">
                <Input
                  className="w-full"
                  type="number"
                  name="max_contiguous_space"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.max_contiguous_space}
                  error={
                    touched.max_contiguous_space
                      ? errors.max_contiguous_space
                      : ""
                  }
                />
                <SelectAutoComplete
                  className="w-[200px]"
                  name="max_contiguous_space_unit"
                  onChange={(option) => {
                    if (!option)
                      setFieldValue("max_contiguous_space_unit", null);
                    else
                      setFieldValue("max_contiguous_space_unit", option.value);
                  }}
                  onBlur={handleBlur}
                  value={{
                    value: values?.max_contiguous_space_unit,
                    label:
                      meta?.building?.max_contiguous_space_unit[
                        values?.max_contiguous_space_unit
                      ]?.value,
                  }}
                  error={
                    touched.max_contiguous_space_unit
                      ? errors.max_contiguous_space_unit
                      : ""
                  }
                  options={[
                    ...getMetaOptions(
                      meta?.building?.max_contiguous_space_unit
                    ),
                  ]}
                />
              </div>
            </div>
          </div>
        </Layout.Section>

        <div className="px-8 fixed bottom-0 left-[360px] right-0 flex justify-end bg-white shadow-detail-status-nav h-[80px] items-center">
          <div className="flex space-x-4">
            <Link
              to={`/property/${propertyId}/${URLS.PROPERTY.FORM}?tab=${PROPERTY_TABS.CONTACTS}`}
            >
              <Button variant="secondary" trailingIcon={FiChevronRight}>
                Next
              </Button>
            </Link>
            <Button
              disabled={!diffFields || !Object.keys(diffFields)?.length}
              variant="primary"
              type="submit"
              id="save-tooltip"
              onMouseOver={() => showTooltip("save-tooltip")}
              onMouseLeave={() => hideTooltip()}
              data-tooltip-content="This saves the property, but does not publish to OLM"
            >
              Save
            </Button>
          </div>
        </div>
      </form>

      <WarningModal
        isOpen={isWarningModal}
        setIsOpen={setIsWarningModal}
        errors={errors}
      />
    </>
  );
};

export default React.memo(PropertyOccupancyV2);
