import type { FC } from "react";
import cn from "classnames";
import { Chip } from "ui-atoms";

interface ILabelProps extends React.LabelHTMLAttributes<HTMLLabelElement> {
  children: any;
  className?: string;
  optional?: boolean;
}

const Label: FC<ILabelProps> = ({
  children,
  className,
  optional,
  ...props
}) => {
  return (
    <label
      className={cn(
        "flex flex-wrap text-sm font-semibold text-jll-color-coldGray-7 py-[2px]",
        className
      )}
      {...props}
    >
      <span
        className={cn("flex flex-row items-center", {
          "mr-2 mb-1 truncate": optional,
        })}
      >
        {children}
      </span>
      {optional && (
        <Chip
          variant="secondary"
          size="small"
          className="py-1 text-jll-color-coldGray-7"
        >
          Optional
        </Chip>
      )}
    </label>
  );
};

export default Label;
