import { sliceIntoChunks } from "utils";

interface IPreviewHighlight {
  highlights: string[];
  className?: string;
}
const PreviewHighlight: React.FC<IPreviewHighlight> = ({
  highlights,
  className,
}) => {
  return (
    <div
      className={`bg-jll-color-coldGray-1 space-x-[32px] px-8 py-3 flex rounded-lg ${className}`}
    >
      <div className="divide-jll-color-coldGray-3 divide-y w-6/12">
        {sliceIntoChunks(highlights, 2).map((item, idx) => (
          <p className="text-jll-color-coldGray-7 text-base py-3" key={idx}>
            {item[0] || ""}
          </p>
        ))}
      </div>
      <div className="divide-jll-color-coldGray-3 divide-y w-6/12">
        {sliceIntoChunks(highlights, 2).map((item, idx) => (
          <p className="text-jll-color-coldGray-7 text-base py-3" key={idx}>
            {item[1] || ""}
          </p>
        ))}
      </div>
    </div>
  );
};

export default PreviewHighlight;
