import { useEffect, useContext, useRef, useState } from "react";
import { LayoutEditProperty as Layout } from "ui-organisms";
import cn from "classnames";
import { FiDownload, FiMaximize2, FiTrash2 } from "react-icons/fi";
import {
  Button,
  Chip,
  DatePicker,
  Input,
  Dropzone,
  Toast,
  Label,
  Radio,
} from "ui-atoms";
import { Card, EmailEditorModal, RemoveDescriptionItem } from "ui-molecules";
import { GlobalContext } from "context";
import {
  capitalizeWords,
  convertBytesTo,
  convertExtension,
  getFormatedDateTime,
  getUTCDate,
  ordinalSuffixOf,
  reorder,
} from "utils";
import { useFormik } from "formik";
import { renderToString } from "react-dom/server";
import validationSchema from "./validationSchema";
import {
  convertImageUrl,
  getAvailabilitiesAPI,
  patchPropertyAPI,
  postImageAPI,
  postPropertyUpdateMarketsphereAPI,
} from "services";
import {
  META_TAGS,
  SEARCH_RESULT_LIMIT,
  SET_CONFIRM_MODAL,
  SET_LOADING_MODAL,
  SET_PROPERTY,
  STATUS_ACTIVE,
  UPDATE_LIGHTGALLERY_DATA,
} from "constant";
import { getApiDate } from "utils";
import { useApiCall, useWindowDimensions } from "hooks";
import PropertyComments from "./PropertyComments";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import { GridContextProvider, GridDropZone, GridItem, swap } from "ui-atoms";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { RxDragHandleHorizontal } from "react-icons/rx";
import { AvailabilityProps, ListingContractProps } from "types";

const PropertyAdmin = () => {
  const [patchProperty] = useApiCall(patchPropertyAPI);
  const [getAvailabilities] = useApiCall(getAvailabilitiesAPI);
  const { dispatch, state } = useContext(GlobalContext);
  const { property, lightGalleryRef, showTooltip, hideTooltip } = state;
  const { propertyId } = useParams();
  const { width } = useWindowDimensions();
  const [postPropertyUpdateMarketsphere] = useApiCall(
    postPropertyUpdateMarketsphereAPI
  );
  const [isEmailModal, setIsEmailModal] = useState(false);
  const [availabilities, setAvailabilities] = useState<AvailabilityProps[]>();

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    values,
    errors,
    touched,
    setValues,
  } = useFormik({
    initialValues: {
      id: 0,
      costar_id: "",
      contract_start: "",
      contract_end: "",
      dealio: "",
      aero_opportunity: 0,
    },
    validationSchema,
    onSubmit: () => {},
  });

  useEffect(() => {
    if (!propertyId) return;
    // Get the first set of Availabilities
    getAvailabilities({
      page: 1,
      limit: SEARCH_RESULT_LIMIT,
      buildings: [propertyId],
      active_status: [STATUS_ACTIVE],
    }).then((data: any) => {
      setAvailabilities(data?.docs);
    });
  }, [propertyId]);

  useEffect(() => {
    if (property && property?.id?.toString() === propertyId?.toString()) {
      setValues({
        ...property,
        contract_start: property?.contract_start
          ? getUTCDate(property?.contract_start)
          : "",
        contract_end: property?.contract_end
          ? getUTCDate(property?.contract_end)
          : "",
        aero_opportunity: property?.aero_opportunity ? 1 : 0,
      });
    }
  }, [property, propertyId]);

  const handleOnBlur = (e: any) => {
    handleBlur(e);
    const name: string = e.target.name;
    let value = e.target.value;
    let payload: any = {};
    if (e.target.type === "checkbox") {
      value = e.target.checked;
    }
    payload = {
      [name]: parseInt(value),
    };
    if (e.target.type === "radio") {
      payload = {
        [name]: Number(e?.target?.value) === 1 ? true : false,
      };
    }
    // @ts-ignore
    if (!errors[name]) {
      patchProperty({
        id: property.id,
        payload,
      }).then(() => {
        dispatch({
          type: SET_PROPERTY,
          payload: {
            ...property,
            [name]: payload?.[name],
          },
        });
      });
    }
  };

  const handleSubmitImg = async (name: string, files: any) => {
    if (files?.length <= 0) return;
    dispatch({
      type: SET_LOADING_MODAL,
      payload: {
        open: true,
        label: "Uploading files",
      },
    });
    try {
      let results: any[] = [];
      await Promise.all(
        files?.map(async (file: any) => {
          const result = await postImageAPI(file, name === "listing_contracts");
          if (result) {
            if (name === "listing_contracts" || name === "signage")
              results.push({
                name: file?.name,
                url: result,
                size: Number(file?.size),
                create_timestamp: getFormatedDateTime(),
              });
            else results.push(result);
          }
        })
      );
      if (!!results?.length) {
        const payload = [
          ...(property?.[name]
            ? !Array.isArray(property?.[name])
              ? []
              : property?.[name]
            : []),
          ...results,
        ];
        const response = await patchProperty({
          id: Number(propertyId),
          payload: {
            [name]: payload,
          },
        });
        if (!!response) {
          dispatch({
            type: SET_PROPERTY,
            payload: {
              ...property,
              [name]: payload,
            },
          });
        }
      }
      setTimeout(() => {
        dispatch({
          type: SET_LOADING_MODAL,
          payload: {
            open: false,
            label: null,
          },
        });
      }, 100);
    } catch (err) {
      setTimeout(() => {
        dispatch({
          type: SET_LOADING_MODAL,
          payload: {
            open: false,
            label: null,
          },
        });
      }, 100);
    }
  };

  const handleOpenGallery = async (name: string, index: number) => {
    let arr: any = [];
    property[name]?.forEach((item: any, idx: number) => {
      let url = "";
      if (name === "signage") url = item?.url;
      else url = item;
      arr.push({
        id: idx,
        src: convertImageUrl(convertExtension(url)),
        thumb: convertImageUrl(convertExtension(url)),
        subHtml: renderToString(<span>{idx + 1}</span>),
      });
    });
    await dispatch({
      type: UPDATE_LIGHTGALLERY_DATA,
      payload: arr,
    });
    setTimeout(function () {
      lightGalleryRef.current.openGallery(index);
    }, 100);
  };

  const removeValue = async (name: string, index: number) => {
    const action = () => {
      const newValue = [...property[name]];
      newValue.splice(index, 1);
      patchProperty({
        id: Number(propertyId),
        payload: {
          [name]: newValue,
        },
      }).then(() => {
        dispatch({
          type: SET_PROPERTY,
          payload: {
            ...property,
            [name]: newValue,
          },
        });
      });
    };
    dispatch({
      type: SET_CONFIRM_MODAL,
      payload: {
        open: true,
        content: {
          description: renderToString(
            <RemoveDescriptionItem
              target={`${ordinalSuffixOf(index + 1)} Item in ${capitalizeWords(
                name,
                "_"
              )}`}
            />
          ),
          action,
        },
      },
    });
  };

  const onBlurDateAvailable = (
    name: "contract_start" | "contract_end",
    value: Date
  ) => {
    if (!errors?.[name] && value) {
      patchProperty({
        id: property.id,
        payload: {
          [name]: getApiDate(value),
        },
      }).then(() => {
        dispatch({
          type: SET_PROPERTY,
          payload: {
            ...property,
            [name]: value,
          },
        });
      });
    }
  };

  const handleSwap = async (
    sourceId: string,
    sourceIndex: number,
    targetIndex: number,
    targetId: any
  ) => {
    const oldState = [...property[sourceId]];
    const nextState = swap(
      [-1, ...property[sourceId]],
      sourceIndex,
      targetIndex
    );
    dispatch({
      type: SET_PROPERTY,
      payload: {
        ...property,
        [sourceId]: nextState.filter((item) => item !== -1),
      },
    });
    const response = await patchProperty({
      id: Number(propertyId),
      payload: {
        [sourceId]: nextState.filter((item) => item !== -1),
      },
    });
    if (response) return;
    dispatch({
      type: SET_PROPERTY,
      payload: {
        ...property,
        [sourceId]: oldState,
      },
    });
  };

  const onClickUpdate = (id: number) => {
    dispatch({
      type: SET_LOADING_MODAL,
      payload: {
        open: true,
        label: `Syncing non-OLM fields into the current listing`,
      },
    });
    try {
      postPropertyUpdateMarketsphere(id)
        .then((res: any) => {
          if (!res) return;
          dispatch({
            type: SET_PROPERTY,
            payload: res,
          });
          Toast.success("Synced successfully");
        })
        .finally(() => {
          setTimeout(() => {
            dispatch({
              type: SET_LOADING_MODAL,
              payload: {
                open: false,
                label: null,
              },
            });
          }, 100);
        });
    } catch (err) {
      setTimeout(() => {
        dispatch({
          type: SET_LOADING_MODAL,
          payload: {
            open: false,
            label: null,
          },
        });
      }, 100);
    }
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    const oldOrder = [...property?.listing_contracts];
    const newOrder = reorder(
      oldOrder,
      result.source.index,
      result.destination.index
    );

    patchProperty({
      id: property?.id,
      payload: { listing_contracts: newOrder },
    })
      .then(() => {
        dispatch({
          type: SET_PROPERTY,
          payload: {
            ...property,
            listing_contracts: newOrder,
          },
        });
      })
      .catch((err: any) => {
        dispatch({
          type: SET_PROPERTY,
          payload: {
            ...property,
            listing_contracts: oldOrder,
          },
        });
      });
  };

  const handleAeroChange = (e: any) => {
    handleOnBlur(e);
  };

  const boxCount = Math.floor((width - 424) / 192);

  return (
    <Layout>
      <Helmet prioritizeSeoTags>
        <title>{`${META_TAGS?.default?.title} ${
          property?.title || property?.address
        } Admin`}</title>
        <meta
          property="og:title"
          content={`${META_TAGS?.default?.title} ${
            property?.title || property?.address
          } Admin`}
        />
      </Helmet>
      {!!values.id && (
        <form onSubmit={handleSubmit}>
          <Layout.Section title="Admin">
            <div className="w-full">
              <div className="flex flex-row space-x-6">
                <div className="w-8/12">
                  <Label>
                    The information in this section in only visible to
                    administrators, and not visible on OLM.
                  </Label>
                </div>
                <div className="w-4/12 justify-end flex items-center flex-row flex-wrap">
                  <Button
                    variant={"secondary"}
                    className="mb-2"
                    onClick={() => setIsEmailModal(true)}
                  >
                    Export Property Details
                  </Button>
                  <Button
                    variant="secondary"
                    trailingIconClass="w-5 h-5"
                    onClick={() => {
                      hideTooltip();
                      onClickUpdate(values?.id);
                    }}
                    className="text-right mb-2 ml-2"
                    leadingIcon={FiDownload}
                    id="sync-ms-tooltip"
                    type="button"
                    onMouseOver={() => showTooltip("sync-ms-tooltip")}
                    onMouseLeave={() => hideTooltip()}
                    data-tooltip-content={`This will pull in a series of non-OLM fields into the current listing. Companies, sub-market, etc.`}
                  >
                    MarketSphere Sync
                  </Button>
                </div>
              </div>
            </div>

            <div className="w-full pt-4">
              <div className="flex flex-row space-x-6">
                <div className="w-4/12">
                  <Input
                    name="costar_id"
                    type="number"
                    label="CoStar ID"
                    onChange={handleChange}
                    onBlur={handleOnBlur}
                    value={values?.costar_id}
                    error={touched.costar_id ? errors.costar_id : ""}
                  />
                </div>
                <div className="w-4/12" />
                <div className="w-4/12" />
              </div>
              <div className="flex flex-row space-x-6">
                <div className="w-4/12">
                  <DatePicker
                    label="Contract Start"
                    selected={
                      values?.contract_start
                        ? getUTCDate(values?.contract_start)
                        : getUTCDate()
                    }
                    onSelect={(date: any) =>
                      setFieldValue("contract_start", date)
                    }
                    error={touched.contract_start ? errors.contract_start : ""}
                    onChange={(value: Date) =>
                      onBlurDateAvailable("contract_start", value)
                    }
                    value={values?.contract_start}
                    popperProps={{ strategy: "fixed" }}
                  />
                </div>
                <div className="w-4/12">
                  <DatePicker
                    label="Contract End"
                    selected={
                      values?.contract_end
                        ? getUTCDate(values?.contract_end)
                        : getUTCDate()
                    }
                    onSelect={(date: any) =>
                      setFieldValue("contract_end", date)
                    }
                    error={touched.contract_end ? errors.contract_end : ""}
                    onChange={(value: Date) =>
                      onBlurDateAvailable("contract_end", value)
                    }
                    value={values?.contract_end}
                    popperProps={{ strategy: "fixed" }}
                  />
                </div>
                <div className="w-4/12" />
              </div>
              <div className="flex flex-row space-x-6">
                <div className="w-4/12">
                  <Input
                    name="dealio"
                    type="number"
                    label="Dealio"
                    onChange={handleChange}
                    onBlur={handleOnBlur}
                    value={values?.dealio}
                    error={touched.dealio ? errors.dealio : ""}
                  />
                </div>
                <div className="w-4/12" />
                <div className="w-4/12" />
              </div>
              <div className="flex flex-row space-x-6">
                <Label className="mr-2">Aero Opportunity Created</Label>
                <div className="flex flex-row space-x-4">
                  <Radio
                    id="aero-yes"
                    name="aero_opportunity"
                    value={1}
                    checked={values.aero_opportunity === 1}
                    label="Yes"
                    onChange={handleAeroChange}
                  />
                  <Radio
                    id="aero-no"
                    name="aero_opportunity"
                    value={0}
                    checked={values.aero_opportunity === 0}
                    label="No"
                    onChange={handleAeroChange}
                  />
                </div>
              </div>
            </div>
          </Layout.Section>

          <Layout.Section title="Listing Agreements(s)">
            <div className="flex flex-wrap pt-4">
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <div
                      className="divide-y divide-jll-color-stroke-base-default mb-4 w-full flex flex-col"
                      {...provided.droppableProps}
                      ref={provided?.innerRef}
                    >
                      {!!property?.listing_contracts?.length &&
                        property?.listing_contracts?.map(
                          (item: ListingContractProps, index: number) => (
                            <Draggable
                              key={index}
                              draggableId={`contact-${index}`}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  className={cn(
                                    "py-3 text-jll-color-text-base-default bg-jll-color-coldGray-1 flex flex-row px-4 items-center w-full",
                                    {
                                      "bg-jll-color-coldGray-1 flex items-center":
                                        snapshot.isDragging,
                                    }
                                  )}
                                >
                                  <div {...provided.dragHandleProps}>
                                    <RxDragHandleHorizontal className="text-xl mr-4" />
                                  </div>
                                  <div className="flex flex-row justify-between items-center flex-1 truncate">
                                    <p className="flex flex-row items-center justify-between flex-1 pr-4 truncate">
                                      <span className="truncate">
                                        {item?.name}{" "}
                                        {item?.size &&
                                          `(${convertBytesTo(item?.size)})`}
                                      </span>
                                      <span className="text-sm">
                                        {item?.create_timestamp &&
                                          getFormatedDateTime(
                                            item?.create_timestamp
                                          )}
                                      </span>
                                    </p>
                                    <div className="flex">
                                      <Button
                                        variant="ghost"
                                        leadingIcon={FiDownload}
                                        onClick={() => window.open(item?.url)}
                                      />
                                      <Button
                                        variant="ghost"
                                        leadingIcon={FiTrash2}
                                        onClick={() =>
                                          removeValue(
                                            "listing_contracts",
                                            index
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          )
                        )}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              <Dropzone
                className="w-full h-20 mb-4"
                setDropFiles={(files: any) =>
                  handleSubmitImg("listing_contracts", files)
                }
                title="Add contracts here,"
                name="listing_contracts"
                isImg={false}
              />
            </div>
          </Layout.Section>

          <Layout.Section title="Signage">
            <div className="flex flex-wrap pt-4">
              <GridContextProvider onChange={handleSwap}>
                <GridDropZone
                  id="signage"
                  boxesPerRow={boxCount}
                  rowHeight={192}
                  className="w-full"
                  style={{
                    height: `${
                      Math.ceil(
                        ((property?.signage || [])?.length + 1) / boxCount
                      ) * 192
                    }px`,
                  }}
                  disableDropIndex={0}
                >
                  {[-1, ...(property?.signage || [])]?.map(
                    (item: any, idx: number) => {
                      const index = idx - 1;
                      if (index === -1) {
                        return (
                          <GridItem key={item} disabled={true}>
                            <Dropzone
                              className="w-44 h-44 mb-4 mr-4"
                              setDropFiles={(files: any) =>
                                handleSubmitImg("signage", files)
                              }
                              title="Add signage here,"
                            />
                          </GridItem>
                        );
                      }
                      return (
                        <GridItem key={item.url}>
                          <Card
                            className="w-44 h-44 mb-4 mr-4"
                            chip={
                              index === 0 ? (
                                <Chip
                                  variant="primary"
                                  className="absolute top-2 left-2"
                                >
                                  Cover image
                                </Chip>
                              ) : (
                                <></>
                              )
                            }
                            key={index}
                            src={item?.url}
                            actions={[
                              <Button
                                variant="secondary"
                                leadingIcon={FiMaximize2}
                                onClick={() =>
                                  handleOpenGallery("signage", index)
                                }
                              />,
                              <Button
                                variant="secondary"
                                leadingIcon={FiTrash2}
                                onClick={() => removeValue("signage", index)}
                              />,
                            ]}
                          />
                        </GridItem>
                      );
                    }
                  )}
                </GridDropZone>
              </GridContextProvider>
            </div>
          </Layout.Section>

          <Layout.Section title="Internal comment(s)">
            <PropertyComments />
          </Layout.Section>
        </form>
      )}

      <EmailEditorModal
        isOpen={isEmailModal}
        setIsOpen={setIsEmailModal}
        property={property}
        suites={availabilities}
      />
    </Layout>
  );
};

export default PropertyAdmin;
