import { PropertyProps } from "types";
import * as Yup from "yup";

export type InitialValuesProps = {
  [key in keyof PropertyProps]?: any;
};

export const PROPERTY_OCCUPANCY_INITIAL_VALUES: InitialValuesProps = {
  direct_available_space: null,
  direct_available_space_unit: null,
  direct_percentage_leased: null,
  percentage_subleased: null,
  max_contiguous_space: null,
  max_contiguous_space_unit: null,
};

export const PROPERTY_OCCUPANCY_VALIDATION_SCHEMA = Yup.object().shape({
  direct_available_space: Yup.number()
    .optional()
    .nullable()
    .transform((_, val) => (val === Number(val) ? val : null)),
  direct_available_space_unit: Yup.string().optional().nullable(),
  direct_percentage_leased: Yup.number()
    .optional()
    .nullable()
    .transform((_, val) => (val === Number(val) ? val : null)),
  percentage_subleased: Yup.number()
    .optional()
    .nullable()
    .transform((_, val) => (val === Number(val) ? val : null)),
  max_contiguous_space: Yup.number()
    .optional()
    .nullable()
    .transform((_, val) => (val === Number(val) ? val : null)),
  max_contiguous_space_unit: Yup.string().optional().nullable(),
});
