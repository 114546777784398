import {
  EDITOR_JS_TOOLS,
  PROPERTY_TABS,
  STATUS_REMOVED,
  TASK_COMPLETED,
  TASK_IN_PROGRESS,
  TASK_NOT_STARTED,
  URLS,
} from "constant";
import { GlobalContext } from "context";
import { FC, useContext } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";
import { convertImageUrl } from "services";
import { TaskProps } from "types";
import { Avatar, Chip, Editor } from "ui-atoms";
import { AvatarGroup } from "ui-molecules";
import {
  convertExtension,
  getFormatedDateTime,
  joinTwoStr,
  lazyLoadImageOnError,
  noPropagation,
} from "utils";

export interface StyledTaskValueProps {
  task: TaskProps;
  valueKey:
    | keyof TaskProps
    | "building"
    | "status"
    | "assignee"
    | "title"
    | "detail_assignee";
  isPreview?: boolean;
}

const StyledTaskValue: FC<StyledTaskValueProps> = ({
  task,
  valueKey,
  isPreview,
}) => {
  const { state } = useContext(GlobalContext);
  const { meta } = state;

  if (!task?.id?.toString()?.toLowerCase().includes("task"))
    return <></>;

  return (
    <>
      {(() => {
        switch (valueKey) {
          case "building":
            return (
              <div className="flex items-center space-x-2">
                <LazyLoadImage
                  className="w-10 h-10 rounded"
                  src={convertImageUrl(
                    convertExtension(task?.building_images?.[0] || "")
                  )}
                  onError={lazyLoadImageOnError}
                />
                <div className="flex flex-col truncate">
                  <Link
                    to={`/property/${task?.building_id || task?.building}/${
                      URLS.PROPERTY.FORM
                    }`}
                    className="font-bold text-jll-color-text-base-default text-ellipsis overflow-hidden"
                    onClick={noPropagation}
                  >
                    {task?.building_title}
                  </Link>
                  <p>
                    <Link
                      to={`/property/${task?.building_id || task?.building}/${
                        URLS.PROPERTY.FORM
                      }`}
                      className="text-jll-color-icon-info text-ellipsis overflow-hidden"
                      onClick={noPropagation}
                    >
                      {task?.building_address}
                    </Link>
                  </p>
                </div>
              </div>
            );
          case "assignee":
            const users = [
              {
                photo: task?.photo,
                name:
                  joinTwoStr(
                    task?.assignee_first_name,
                    task?.assignee_last_name,
                    " "
                  ) || "",
              },
            ];
            return <AvatarGroup uniqueId={task?.pk} brokers={users} max={3} />;
          case "detail_assignee":
            return (
              <div className="flex space-x-2 items-center">
                <Avatar
                  alt=""
                  size="default"
                  src={task?.photo || ""}
                  name={
                    joinTwoStr(
                      task?.assignee_first_name,
                      task?.assignee_last_name,
                      " "
                    ) || ""
                  }
                />

                <div className="truncate cursor-pointer">
                  <p className="font-bold text-jll-color-text-base-default text-ellipsis overflow-hidden">
                    {joinTwoStr(
                      task?.assignee_first_name,
                      task?.assignee_last_name,
                      " "
                    ) || ""}
                  </p>
                </div>
              </div>
            );
          case "update_timestamp":
            return (
              <div>
                <p>
                  {task?.update_timestamp &&
                    getFormatedDateTime(task?.update_timestamp, {
                      dateStyle: "long",
                    })}
                </p>
                {(!!task?.updated_user_first_name ||
                  !!task?.updated_user_last_name) && (
                  <p className="text-sm text-jll-color-text-base-default">
                    <span className="text-jll-color-coldGray-7">
                      updated by:{" "}
                    </span>
                    {joinTwoStr(
                      task?.updated_user_first_name,
                      task?.updated_user_last_name,
                      " "
                    )}
                  </p>
                )}
              </div>
            );

          case "title":
            return (
              <p className="whitespace-normal line-clamp-2">{task?.title}</p>
            );
          case "body":
            return (
              <Editor blocks={task?.body} tools={EDITOR_JS_TOOLS} readOnly />
            );
          case "status":
            if (!task?.status || !task?.active_status || !meta?.task?.status) {
              return <>-</>;
            }
            if (task?.active_status === STATUS_REMOVED)
              return (
                <Chip size={isPreview ? "default" : "small"} variant="error">
                  Deleted
                </Chip>
              );
            const status: any = Object.keys(meta?.task?.status)
              .map((key) => {
                return { ...meta?.task?.status[key], key };
              })
              ?.find(
                (item: any) =>
                  item?.key?.toString() === task?.status?.toString()
              );
            let variant:
              | "primary"
              | "secondary"
              | "success"
              | "error"
              | "warning";
            switch (task?.status?.toString()) {
              case TASK_NOT_STARTED.toString():
                variant = "primary";
                break;
              case TASK_IN_PROGRESS.toString():
                variant = "secondary";
                break;
              case TASK_COMPLETED.toString():
                variant = "success";
                break;
              default:
                variant = "warning";
            }
            return (
              <Chip size={isPreview ? "default" : "small"} variant={variant}>
                {status?.value || ""}
              </Chip>
            );
          default:
            if (task.hasOwnProperty(valueKey)) {
              // @ts-ignore fix type
              return <>{task[valueKey]}</>;
            }
            return <>-</>;
        }
      })()}
    </>
  );
};

export default StyledTaskValue;
