import { FC } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import cn from "classnames";
import { convertExtension, lazyLoadImageOnError } from "utils";

interface ImageGroupProps {
  className?: string;
  images: string[];
  max?: number;
  uniqueId?: string | number;
  fontClassName?: string;
}

const ImageGroup: FC<ImageGroupProps> = ({
  className,
  images = [],
  max = 5,
  uniqueId = "",
  fontClassName,
  ...props
}) => {
  return (
    <>
      {!!images?.length ? (
        <ul
          aria-label="Image group"
          className={cn("flex -space-x-6 w-max", className)}
        >
          {images?.slice(0, max).map((item, index: number) => {
            return (
              <li
                key={index}
                id={`${uniqueId}_item_${index}`}
                className={cn(`list-item cursor-pointer`, {
                  [`z-${(max - index) * 10}`]: true,
                })}
              >
                <div className="rounded-md border-2 border-white flex justify-center items-center">
                  <LazyLoadImage
                    className="inline-block h-11 w-11 rounded-md object-cover"
                    src={convertExtension(item)}
                    onError={lazyLoadImageOnError}
                    {...props}
                  />
                </div>
              </li>
            );
          })}
          {images.length - max > 0 && (
            <li className="list-item !ml-[-8px]">
              <div className="inline-block text-sm font-semibold w-11 h-11 flex items-center text-jll-color-text-base-subdued justify-center">
                +{images.length - max}
              </div>
            </li>
          )}
        </ul>
      ) : (
        <>-</>
      )}
    </>
  );
};

export default ImageGroup;
