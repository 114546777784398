import cn from "classnames";

interface ITab {
  value: string;
  label: string;
}

interface ITabbar {
  tabList: ITab[];
  activeTab?: string;
  setActiveTab?: any;
}

const Tabbar: React.FC<ITabbar> = ({ tabList, activeTab, setActiveTab }) => {
  return (
    <div className="p-1 bg-jll-color-coldGray-1 rounded-full w-fit flex flex-row items-center space-x-1">
      {tabList?.map((item, idx) => (
        <div
          key={idx}
          className={cn(
            "py-1 px-3 rounded-full text-sm font-semibold cursor-pointer",
            {
              "text-white bg-jll-color-text-base-default":
                activeTab === item?.value,
              "text-jll-color-text-base-subdued bg-jll-color-coldGray-1":
                activeTab !== item?.value,
            }
          )}
          onClick={() => setActiveTab(item?.value)}
        >
          {item?.label}
        </div>
      ))}
    </div>
  );
};

export default Tabbar;
