import type { FC } from "react";
import { FiPlusCircle } from "react-icons/fi";
import { Button } from "ui-atoms";
import VideoUrlItem from "../VideoUrlItem";

interface VideoUrlFieldsProps {
  name: "videos" | "virtual_tours" | "virtual_tour" | "websites";
  values: any;
  setFieldValue: any;
  handleChange: any;
  handleBlur: any;
  errors: any;
  touched: any;
  isThumb?: boolean;
  placeholder?: string;
}

const VideoUrlFields: FC<VideoUrlFieldsProps> = ({
  name,
  values,
  setFieldValue,
  handleChange,
  handleBlur,
  errors,
  touched,
  isThumb = true,
  placeholder
}) => {
  const addVideo = (name: VideoUrlFieldsProps["name"]) => {
    setFieldValue(name, [...values?.[name], ""]);
  };

  return (
    <>
      <div className="w-full flex flex-col">
        {values?.[name]?.map((_: string, index: number) => {
          return (
            <VideoUrlItem
              key={index}
              name={`${name}[${index}]`}
              value={values?.[name]?.[index]}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder={placeholder}
              onRemove={() => {
                setFieldValue(name, [
                  ...values?.[name].filter(
                    (_: string, i: number) => i !== index
                  ),
                ]);
              }}
              error={
                // @ts-ignore
                touched?.[name]?.[index]
                  ? // @ts-ignore
                    errors?.[name]?.[index]
                  : ""
              }
              isThumb={isThumb}
            />
          );
        })}
      </div>
      <Button
        variant="primary"
        className="w-fit"
        leadingIcon={FiPlusCircle}
        onClick={() => addVideo(name)}
      >
        Add another link
      </Button>
    </>
  );
};

export default VideoUrlFields;
