import { ReportTotalDataProps } from "types";
import StalenessLine from "./StalenessLine";

interface IStyledStalenessValue {
  staleness: ReportTotalDataProps;
  type: "property" | "suite";
  valueKey: keyof ReportTotalDataProps | "total_count" | "line";
}

const StyledStalenessValue: React.FC<IStyledStalenessValue> = ({
  staleness,
  type,
  valueKey,
}) => {
  return (
    <>
      {(() => {
        let status_buckets = [];
        let total_count = 0;
        if (
          (valueKey === "total_count" || valueKey === "line") &&
          !!staleness?.status_buckets
        ) {
          status_buckets = JSON.parse(staleness?.status_buckets);
          if (!!status_buckets?.length)
            total_count = status_buckets?.reduce((a: any, b: any) => {
              return Number(a) + Number(b?.count);
            }, 0);
        }
        switch (valueKey) {
          case "total_count":
            return (
              <>
                {total_count} {type === "property" ? "Propert" : "Availabilit"}
                {total_count <= 1 ? "y" : "ies"}
              </>
            );
          case "line":
            if (!status_buckets?.length) return <></>;
            return (
              <div className="w-full rounded-full h-5 flex flex-row">
                <StalenessLine
                  status_buckets={status_buckets}
                  total_count={total_count}
                />
              </div>
            );
          default:
            if (staleness?.hasOwnProperty(valueKey)) {
              return <>{staleness?.[valueKey] || ""}</>;
            }
            return <>-</>;
        }
      })()}
    </>
  );
};

export default StyledStalenessValue;
