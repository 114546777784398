import {
  InitialValuesProps,
  META_TAGS,
  OLM_PROPERTY_FIELDS,
  PROPERTY_TABS,
  SET_CONFIRM_MODAL,
  SET_LOADING_MODAL,
  SET_PROPERTY,
  STATUS_ACTIVE,
  URLS,
} from "constant";
import { GlobalContext } from "context";
import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Button, Input, Label, Switch, Toast } from "ui-atoms";
import { LayoutEditProperty as Layout } from "ui-organisms";
import {
  PROPERTY_INDUSTRIAL_INITIAL_VALUES,
  PROPERTY_INDUSTRIAL_VALIDATION_SCHEMA,
} from "./constants";
import { Link, useParams } from "react-router-dom";
import { FiChevronRight } from "react-icons/fi";
import { useApiCall, useCallbackPrompt } from "hooks";
import { patchPropertyAPI } from "services";
import { getApiPayloadFromForm, isCommonItem } from "utils";
import React from "react";
import { RemoveDescriptionItem, WarningModal } from "ui-molecules";
import { renderToString } from "react-dom/server";

interface IPropertyIndustrialV2 {
  onDiffFields?: any;
  diffFields?: any;
}

const PropertyIndustrialV2: React.FC<IPropertyIndustrialV2> = ({
  onDiffFields,
  diffFields,
}) => {
  const { state, dispatch } = useContext(GlobalContext);
  const { property, showTooltip, hideTooltip } = state;
  const { propertyId } = useParams();
  const [patchProperty] = useApiCall(patchPropertyAPI);
  const [isWarningModal, setIsWarningModal] = useState(false);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(showDialog);

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    values,
    errors,
    touched,
    setValues,
    isValid,
    dirty,
  } = useFormik({
    initialValues: PROPERTY_INDUSTRIAL_INITIAL_VALUES,
    validationSchema: PROPERTY_INDUSTRIAL_VALIDATION_SCHEMA,
    onSubmit: async () => {
      try {
        dispatch({
          type: SET_LOADING_MODAL,
          payload: {
            open: true,
            label: "Updating Property",
          },
        });
        let payload: InitialValuesProps | undefined = getApiPayloadFromForm(
          property,
          values
        );
        const response = await patchProperty({
          id: property.id,
          payload,
        });
        setTimeout(() => {
          dispatch({
            type: SET_LOADING_MODAL,
            payload: {
              open: false,
              label: null,
            },
          });
        }, 100);
        if (!response) return;
        Toast.success("Changes saved with success");
        dispatch({
          type: SET_PROPERTY,
          payload: response,
        });
      } catch (err) {
        Toast.warn("No changes to be saved");
        setTimeout(() => {
          dispatch({
            type: SET_LOADING_MODAL,
            payload: {
              open: false,
              label: null,
            },
          });
        }, 100);
      }
    },
  });

  useEffect(() => {
    if (!property || property?.id?.toString() !== propertyId?.toString())
      return;
    let formValues = {};
    Object.keys(PROPERTY_INDUSTRIAL_INITIAL_VALUES).forEach((key: string) => {
      formValues = {
        ...formValues,
        [key]: property[key],
      };
    });
    setValues({ ...formValues });
  }, [property]);

  useEffect(() => {
    if (!property || !values) return;
    const diff = getApiPayloadFromForm(property, values);
    onDiffFields(diff);
  }, [property, values]);

  useEffect(() => {
    if (!diffFields || !Object.keys(diffFields)?.length) setShowDialog(false);
    else setShowDialog(true);
  }, [diffFields]);

  useEffect(() => {
    if (!showPrompt) return;
    const action = async () => {
      try {
        if (errors && !!Object.keys(errors)?.length) {
          setIsWarningModal(true);
          // @ts-ignore
          cancelNavigation();
          return;
        }
        await handleSubmit();
        // @ts-ignore
        confirmNavigation();
      } catch (err) {
        // @ts-ignore
        cancelNavigation();
      }
    };
    let isPublish = false;
    if (
      diffFields &&
      property?.publish_status === STATUS_ACTIVE &&
      isCommonItem(Object.keys(diffFields), OLM_PROPERTY_FIELDS)
    ) {
      isPublish = true;
    }

    dispatch({
      type: SET_CONFIRM_MODAL,
      payload: {
        open: true,
        content: {
          description: renderToString(
            <RemoveDescriptionItem description="You have un-saved changes, would you like to save changes before proceeding" />
          ),
          btnTitle: `${isPublish ? "Save and Publish" : "Save"}`,
          cancelTitle: "Proceed without Saving",
          action: action,
          cancel: confirmNavigation,
          additionalCancel: cancelNavigation,
        },
      },
    });
  }, [showPrompt]);

  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>{`${META_TAGS?.default?.title} ${
          property?.title || property?.address
        } Indistrial`}</title>
        <meta
          property="og:title"
          content={`${META_TAGS?.default?.title} ${
            property?.title || property?.address
          } Indistrial`}
        />
      </Helmet>

      <form onSubmit={handleSubmit}>
        <Layout.Section>
          <div className="flex flex-col w-full">
            <div className="flex flex-row items-center space-x-2">
              <Label className="w-4/12 mb-6" optional>
                Dock High Doors
              </Label>
              <Input
                className="w-full"
                type="number"
                name="dock_high_doors"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values?.dock_high_doors}
                error={touched.dock_high_doors ? errors.dock_high_doors : ""}
              />
            </div>

            <div className="flex flex-row items-center space-x-2">
              <Label className="w-4/12 mb-6" optional>
                Semi-Dock Doors
              </Label>
              <Input
                className="w-full"
                type="number"
                name="semi_dock_doors"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values?.semi_dock_doors}
                error={touched.semi_dock_doors ? errors.semi_dock_doors : ""}
              />
            </div>

            <div className="flex flex-row items-center space-x-2">
              <Label className="w-4/12 mb-6" optional>
                Grade Level Doors
              </Label>
              <Input
                className="w-full"
                type="number"
                name="grade_level_doors"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values?.grade_level_doors}
                error={
                  touched.grade_level_doors ? errors.grade_level_doors : ""
                }
              />
            </div>

            <div className="flex flex-row items-center space-x-2">
              <Label className="w-4/12 mb-6" optional>
                3-Phase Power
              </Label>
              <Switch
                onChange={(flag: boolean) => {
                  setFieldValue("three_phase_power", flag);
                }}
                label_1="No"
                label_2="Yes"
                className="h-6 w-full"
                enabled={values?.three_phase_power}
              />
            </div>

            <div className="flex flex-row items-center space-x-2">
              <Label className="w-4/12 mb-6" optional>
                Total Amperage
              </Label>
              <Input
                className="w-full"
                type="number"
                name="total_amperage"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values?.total_amperage}
                error={touched.total_amperage ? errors.total_amperage : ""}
              />
            </div>

            <div className="flex flex-row items-center space-x-2">
              <Label className="w-4/12 mb-6" optional>
                HVAC Warehouse
              </Label>
              <Switch
                onChange={(flag: boolean) => {
                  setFieldValue("hvac_warehouse", flag);
                }}
                label_1="No"
                label_2="Yes"
                className="h-6 w-full"
                enabled={values?.hvac_warehouse}
              />
            </div>

            <div className="flex flex-row items-center space-x-2">
              <Label className="w-4/12 mb-6" optional>
                Office SQ FT
              </Label>
              <Input
                className="w-full"
                type="number"
                name="office_percentage"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values?.office_percentage}
                error={
                  touched.office_percentage ? errors.office_percentage : ""
                }
              />
            </div>

            <div className="flex flex-row items-center space-x-2">
              <Label className="w-4/12 mb-6" optional>
                Outside Storage
              </Label>
              <Switch
                onChange={(flag: boolean) => {
                  setFieldValue("outside_storage", flag);
                }}
                label_1="No"
                label_2="Yes"
                className="h-6 w-full"
                enabled={values?.outside_storage}
              />
            </div>

            <div className="flex flex-row items-center space-x-2">
              <Label className="w-4/12 mb-6" optional>
                Trailer Parking
              </Label>
              <Input
                className="w-full"
                type="number"
                name="trailer_parking_spaces"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values?.trailer_parking_spaces}
                error={
                  touched.trailer_parking_spaces
                    ? errors.trailer_parking_spaces
                    : ""
                }
              />
            </div>

            <div className="flex flex-row items-center space-x-2">
              <Label className="w-4/12 mb-6" optional>
                Clear Height
              </Label>
              <Input
                className="w-full"
                type="number"
                name="clear_height"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values?.clear_height}
                error={touched.clear_height ? errors.clear_height : ""}
              />
            </div>
          </div>
        </Layout.Section>

        <div className="px-8 fixed bottom-0 left-[360px] right-0 flex justify-end bg-white shadow-detail-status-nav h-[80px] items-center">
          <div className="flex space-x-4">
            <Link
              to={`/property/${propertyId}/${URLS.PROPERTY.FORM}?tab=${PROPERTY_TABS.CONTACTS}`}
            >
              <Button variant="secondary" trailingIcon={FiChevronRight}>
                Next
              </Button>
            </Link>
            <Button
              disabled={!diffFields || !Object.keys(diffFields)?.length}
              variant="primary"
              type="submit"
              id="save-tooltip"
              onMouseOver={() => showTooltip("save-tooltip")}
              onMouseLeave={() => hideTooltip()}
              data-tooltip-content="This saves the property, but does not publish it"
            >
              Save
            </Button>
          </div>
        </div>
      </form>

      <WarningModal
        isOpen={isWarningModal}
        setIsOpen={setIsWarningModal}
        errors={errors}
      />
    </>
  );
};

export default React.memo(PropertyIndustrialV2);
