import { useContext, useState } from "react";
import { Button } from "ui-atoms";
import { GlobalContext } from "context";
import { FiPlus } from "react-icons/fi";
import { SET_LOADING_MODAL, SET_PROPERTY, STATUS_ACTIVE } from "constant";
import { useApiCall } from "hooks";
import { DescriptionProps } from "types";
import PropertyDescription from "./PropertyDescription";
import {
  patchPropertyDescriptionAPI,
  postPropertyDescriptionAPI,
  deletePropertyDescriptionAPI,
  patchPropertyAPI,
  getPropertyAIAPI,
} from "services";
import { exportDescriptionContent } from "utils";
import { DescriptionAIModal } from "ui-molecules";

interface ErrorsProps {
  title?: string;
  content?: string;
}

const PropertyDetail = () => {
  const { dispatch, state } = useContext(GlobalContext);
  const { property } = state;
  const [patchPropertyDescription] = useApiCall(patchPropertyDescriptionAPI);
  const [postPropertyDescription] = useApiCall(postPropertyDescriptionAPI);
  const [deletePropertyDescription] = useApiCall(deletePropertyDescriptionAPI);
  const [getPropertyAI] = useApiCall(getPropertyAIAPI);
  const [patchProperty] = useApiCall(patchPropertyAPI);
  const [descriptions, setDescriptions] = useState<any[]>(
    property.descriptions
  );
  const [errors, setErrors] = useState<ErrorsProps>();
  const [isOpen, setIsOpen] = useState(false);

  const onAdd = () => {
    setDescriptions([
      ...descriptions,
      {
        title: "",
        content: "",
      },
    ]);
  };

  const onChange = (index: number, name: string, value: string) => {
    const updatedDescriptions = [...descriptions].map(
      (description: any, i: number) => {
        if (i === index) {
          return {
            ...description,
            [name]: value,
          };
        }
        return description;
      }
    );
    setDescriptions(updatedDescriptions);
  };

  // TODO e should be the right type
  const onBlur = (e: any, index: number) => {
    const name: string = e.target.name;
    let value = e.target.value;
    if (value === "") {
      setErrors({
        ...errors,
        [name]: "This field is required",
      });
    } else {
      // @ts-ignore fix typing issue
      if (errors?.[name]) {
        // @ts-ignore fix typing issue
        delete errors[name];
        setErrors(errors);
      }

      if (name === "content") {
        value = exportDescriptionContent(value);
      }
      const description = [...descriptions].find(
        (_: any, i: number) => i == index
      );

      if (description.id) {
        patchPropertyDescription({
          id: description.id,
          payload: {
            [name]: value,
          },
        }).then(async (res: any) => {
          const updatedDescriptions = [...descriptions].map(
            (description: any, i: number) => {
              if (i === index) {
                return res;
              }
              return description;
            }
          );
          setDescriptions(updatedDescriptions);
          dispatch({
            type: SET_PROPERTY,
            payload: {
              ...property,
              descriptions: updatedDescriptions,
            },
          });
          if (property?.publish_status === STATUS_ACTIVE)
            await patchProperty({
              id: property.id,
              payload: { publish_status: STATUS_ACTIVE },
            });
        });
      } else if (description.title && description.content) {
        postPropertyDescription({
          building: property.id,
          ...description,
          [name]: value,
        }).then(async (res: any) => {
          const updatedDescriptions = [...descriptions].map(
            (description: any, i: number) => {
              if (i === index) {
                return res;
              }
              return description;
            }
          );
          setDescriptions(updatedDescriptions);
          dispatch({
            type: SET_PROPERTY,
            payload: {
              ...property,
              descriptions: updatedDescriptions,
            },
          });
          if (e?.relatedTarget?.textContent === "Add description") onAdd();
          if (property?.publish_status === STATUS_ACTIVE)
            await patchProperty({
              id: property.id,
              payload: { publish_status: STATUS_ACTIVE },
            });
        });
      }
    }
  };

  const onDelete = (index: number) => {
    const updatedDescriptions = [...descriptions].filter(
      (_: any, i: number) => i !== index
    );
    const description = [...descriptions].find(
      (_: any, i: number) => i == index
    );
    if (description?.id) {
      deletePropertyDescription(description?.id).then(async () => {
        setDescriptions(updatedDescriptions);
        dispatch({
          type: SET_PROPERTY,
          payload: {
            ...property,
            descriptions: updatedDescriptions,
          },
        });
        if (property?.publish_status === STATUS_ACTIVE)
          await patchProperty({
            id: property.id,
            payload: { publish_status: STATUS_ACTIVE },
          });
      });
    } else {
      setDescriptions(updatedDescriptions);
    }
  };

  const handleGPT = async (content: string) => {
    let newDescriptions: any = [];
    if (descriptions?.length > 0) {
      newDescriptions = [...descriptions];
      newDescriptions[0] = {
        ...descriptions[0],
        title: "lease description",
        content: exportDescriptionContent(content),
      };
      setDescriptions(newDescriptions);
      patchPropertyDescription({
        id: descriptions[0].id,
        payload: {
          title: "lease description",
          content: exportDescriptionContent(content),
        },
      }).then(async (res: any) => {
        dispatch({
          type: SET_PROPERTY,
          payload: {
            ...property,
            descriptions: newDescriptions,
          },
        });
        if (property?.publish_status === STATUS_ACTIVE)
          await patchProperty({
            id: property.id,
            payload: { publish_status: STATUS_ACTIVE },
          });
      });
    } else {
      newDescriptions = [
        {
          title: "lease description",
          content: exportDescriptionContent(content),
        },
      ];
      setDescriptions(newDescriptions);
      postPropertyDescription({
        building: property.id,
        ...newDescriptions[0],
      }).then(async (res: any) => {
        dispatch({
          type: SET_PROPERTY,
          payload: {
            ...property,
            descriptions: newDescriptions,
          },
        });
        if (property?.publish_status === STATUS_ACTIVE)
          await patchProperty({
            id: property.id,
            payload: { publish_status: STATUS_ACTIVE },
          });
      });
    }
  };

  return (
    <>
      <div className="mb-2.5 flex flex-row justify-between items-center space-x-4">
        <p className="text-jll-color-coldGray-7 text-sm whitespace-normal">
          An auto generated description will appear on OLM unless a description
          has been entered below.
        </p>
        <Button variant="secondary" onClick={() => setIsOpen(true)}>
          Generate GPT Description
        </Button>
      </div>
      {descriptions?.map((description: DescriptionProps, index: number) => {
        return (
          <PropertyDescription
            key={index}
            index={index}
            onDelete={onDelete}
            onChange={onChange}
            onBlur={onBlur}
            errors={errors}
            {...description}
          />
        );
      })}
      <Button
        variant="secondary"
        leadingIcon={FiPlus}
        onClick={onAdd}
        className="w-fit"
      >
        Add description
      </Button>

      <DescriptionAIModal isOpen={isOpen} setIsOpen={setIsOpen} handleGPT={handleGPT}/>
    </>
  );
};

export default PropertyDetail;
