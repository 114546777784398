import { SET_CONFIRM_MODAL } from "constant";
import { GlobalContext } from "context";
import { useContext } from "react";
import { FiCheckCircle, FiX } from "react-icons/fi";
import { Button } from "ui-atoms";
import { Modal } from "ui-molecules";
import ReactHtmlParser from "react-html-parser";

const ConfirmModal = () => {
  const { state, dispatch } = useContext(GlobalContext);
  const { confirmModalOpen, confirmModalContent } = state;

  const Icon = confirmModalContent?.icon || FiCheckCircle;

  const handleCloseClick = () => {
    dispatch({
      type: SET_CONFIRM_MODAL,
      payload: { open: false },
    });
  };

  const handleClose = () => {
    confirmModalContent?.additionalCancel &&
      confirmModalContent?.additionalCancel();
    handleCloseClick();
  };

  const handleCancel = () => {
    confirmModalContent?.cancel && confirmModalContent?.cancel();
    handleCloseClick();
  };

  const actionFunc = () => {
    confirmModalContent?.action && confirmModalContent?.action();
    handleCloseClick();
  };

  const action2Func = () => {
    confirmModalContent?.action2 && confirmModalContent?.action2();
    handleCloseClick();
  };

  return (
    <Modal isOpen={confirmModalOpen} setIsOpen={handleClose} size="medium">
      <Modal.Header className="relative">
        <Button
          variant="neutral"
          leadingIcon={FiX}
          className="absolute top-0 right-0 w-5 !h-5"
          leadingIconClass="w-5 h-5 text-jll-color-text-base-subdued"
          onClick={handleClose}
        />

        <div className="flex justify-center items-center flex-col">
          <Icon className="h-8 w-8 text-jll-color-coldGray-4 mb-2" />
          <h4 className="font-black text-jll-color-coldGray-4 text-2xl">
            {confirmModalContent?.title || "Warning"}
          </h4>
        </div>
      </Modal.Header>
      <Modal.Body>
        {confirmModalContent?.description &&
          ReactHtmlParser(confirmModalContent?.description)}
      </Modal.Body>
      <Modal.Footer className="justify-between items-center !mt-0">
        <Button
          variant="secondary"
          leadingIconClass="w-4 h-4 mr-2"
          onClick={handleCancel}
        >
          {confirmModalContent?.cancelTitle || "Cancel"}
        </Button>
        <div className="flex flex-row items-center space-x-2">
          {confirmModalContent?.action && (
            <Button
              variant="primary"
              leadingIconClass="w-4 h-4 mr-2"
              onClick={actionFunc}
            >
              {confirmModalContent?.btnTitle || "Confirm"}
            </Button>
          )}
          {confirmModalContent?.action2 && (
            <Button
              variant="primary"
              leadingIconClass="w-4 h-4 mr-2"
              onClick={action2Func}
            >
              {confirmModalContent?.btnTitle2 || "Confirm"}
            </Button>
          )}
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmModal;
