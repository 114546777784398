import { StyledLeaseRateValue, Table } from "ui-molecules";
import { LEASE_RATE_TABLE_COLUMNS, OPEX_TABLE_COLUMNS } from "./constants";
import { Loading } from "ui-atoms";
import { useEffect, useState } from "react";
import { AvailabilityProps } from "types";
import { useApiCall } from "hooks";
import { getAvailabilitiesAPI } from "services";
import { useParams } from "react-router-dom";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { SEARCH_RESULT_LIMIT, STATUS_ACTIVE } from "constant";

interface IPropertyLeaseRateSection {
  setIsLeaseRate?: any;
}

const PropertyLeaseRateSection: React.FC<IPropertyLeaseRateSection> = ({ setIsLeaseRate }) => {
  const { propertyId } = useParams();
  const [getAvailabilities] = useApiCall(getAvailabilitiesAPI);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState<any>();

  useEffect(() => {
    if (!propertyId) return;
    const payload = {
      buildings: [propertyId],
      has_rates: true,
      active_status: [STATUS_ACTIVE],
    };
    getAvailabilities(payload).then((res: any) => {
      setData(res?.docs);
      setPage(res?.page);
      setTotal(res?.total);
    });
  }, [propertyId]);

  useEffect(() => {
    if (!!data?.length) {
      setIsLeaseRate(true);
    } else {
      setIsLeaseRate(false);
    }
  }, [data])

  const loadMore = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    const payload = {
      buildings: [propertyId],
      has_rates: true,
      active_status: [STATUS_ACTIVE],
    };
    getAvailabilities({
      page: nextPage,
      limit: SEARCH_RESULT_LIMIT,
      ...payload,
    }).then((res: any) => {
      setData((prevData: any) => [...prevData, ...res?.docs]);
      setPage(res?.page);
      setTotal(res?.total);
    });
  };

  const [sentryRef] = useInfiniteScroll({
    loading: false,
    hasNextPage: total > data?.length,
    onLoadMore: loadMore,
  });

  return (
    <div className="flex flex-col w-full">
      {!!data?.length && (
        <Table className="mb-4">
          <Table.Thead>
            <Table.Tr>
              {LEASE_RATE_TABLE_COLUMNS.map((column, index) => {
                return (
                  <Table.Th key={index} filterId={column.id}>
                    {column.label}
                  </Table.Th>
                );
              })}
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {data?.map((availability: AvailabilityProps, idx: number) => (
              <Table.Tr key={idx}>
                {LEASE_RATE_TABLE_COLUMNS.map((column, index) => (
                  <Table.Td key={index}>
                    <StyledLeaseRateValue
                      availability={availability}
                      valueKey={column?.id}
                    />
                  </Table.Td>
                ))}
              </Table.Tr>
            ))}
          </Table.Tbody>
        </Table>
      )}

      {!!(total > data?.length) && (
        <div
          className="w-full py-2 flex justify-center items-center"
          ref={sentryRef}
        >
          <Loading />
        </div>
      )}
    </div>
  );
};

export default PropertyLeaseRateSection;
