import { GlobalContext } from "context";
import { useContext, useState } from "react";
import { COMPANY_TABLE_COLUMNS } from "./constants";
import { LayoutEditProperty as Layout } from "ui-organisms";
import { Helmet } from "react-helmet-async";
import { META_TAGS, SET_LOADING_MODAL, SET_PROPERTY } from "constant";
import { Button } from "ui-atoms";
import { FiPlus } from "react-icons/fi";
import { CompanyModal, StyledCompanyValue, Table } from "ui-molecules";
import { BuildingCompanyProps } from "types";
import { useApiCall } from "hooks";
import { deleteBuildingCompanyAPI } from "services";

const PropertyCompanyV2 = () => {
  const { state, dispatch } = useContext(GlobalContext);
  const { property } = state;
  const [deleteBuildingCompany] = useApiCall(deleteBuildingCompanyAPI);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState<any>(undefined);

  const handleDelete = async (id: number) => {
    try {
      dispatch({
        type: SET_LOADING_MODAL,
        payload: {
          open: true,
          label: `Deleting the Company`,
        },
      });
      const res = await deleteBuildingCompany(id);
      setTimeout(() => {
        dispatch({
          type: SET_LOADING_MODAL,
          payload: {
            open: false,
            label: null,
          },
        });
      }, 100);
      const index = property?.companies?.findIndex(
        (company: BuildingCompanyProps) => company?.id === id
      );
      if (index > -1) {
        const newData = [...property?.companies];
        newData.splice(index, 1);
        dispatch({
          type: SET_PROPERTY,
          payload: {
            ...property,
            companies: newData,
          },
        });
      }
    } catch (err) {
      setTimeout(() => {
        dispatch({
          type: SET_LOADING_MODAL,
          payload: {
            open: false,
            label: null,
          },
        });
      }, 100);
    }
  };

  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>{`${META_TAGS?.default?.title} ${
          property?.title || property?.address
        } Company`}</title>
        <meta
          property="og:title"
          content={`${META_TAGS?.default?.title} ${
            property?.title || property?.address
          } Company`}
        />
      </Helmet>

      <Layout.Section>
        <div className="flex flex-col w-full">
          {!!property?.companies?.length && (
            <Table className="mb-4">
              <Table.Thead>
                <Table.Tr>
                  {COMPANY_TABLE_COLUMNS.map((column, index) => {
                    return (
                      <Table.Th key={index} filterId={column.id}>
                        {column.label}
                      </Table.Th>
                    );
                  })}
                  <Table.Th />
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                {property?.companies?.map(
                  (company: BuildingCompanyProps, idx: number) => (
                    <Table.Tr key={idx}>
                      {COMPANY_TABLE_COLUMNS.map((column, index) => (
                        <Table.Td key={index}>
                          <StyledCompanyValue
                            company={company}
                            valueKey={column?.id}
                          />
                        </Table.Td>
                      ))}
                      <Table.Td className="space-x-2 text-right">
                        <Button
                          variant="secondary"
                          size="small"
                          onClick={() => {
                            setIsOpen(true);
                            setSelectedCompany(company);
                          }}
                        >
                          Edit
                        </Button>
                        <Button
                          variant="secondary"
                          size="small"
                          onClick={() => handleDelete(company?.id)}
                        >
                          Remove
                        </Button>
                      </Table.Td>
                    </Table.Tr>
                  )
                )}
              </Table.Tbody>
            </Table>
          )}
          <Button
            className="w-fit"
            variant="secondary"
            leadingIcon={FiPlus}
            onClick={() => {
              setSelectedCompany(null);
              setIsOpen(true);
            }}
          >
            Add Company
          </Button>
        </div>
      </Layout.Section>
      <CompanyModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        selectedCompany={selectedCompany}
      />
    </>
  );
};

export default PropertyCompanyV2;
