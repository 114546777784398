import { useFormik } from "formik";
import { FiPlusCircle, FiX } from "react-icons/fi";
import { Button, Label, SelectAutoComplete, Toast } from "ui-atoms";
import { Modal } from "ui-molecules";
import * as Yup from "yup";
import { SET_LOADING_MODAL, SET_PROPERTY } from "constant";
import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "context";
import { useApiCall } from "hooks";
import {
  getOwnersAPI,
  postBuildingCompanyAPI,
  postCompanyAPI,
  putBuildingCompanyAPI,
} from "services";
import { useParams } from "react-router-dom";
import { BuildingCompanyProps } from "types";

export type InitialValuesProps = {
  [key in keyof BuildingCompanyProps]?: any;
};

export const PROPERTY_COMPANY_INITIAL_VALUES: InitialValuesProps = {
  company: undefined,
  roles: [],
};

export const PROPERTY_COMPANY_VALIDATION_SCHEMA = Yup.object().shape({
  company: Yup.string().required("This field is required"),
  roles: Yup.array()
    .required("This field is required")
    .min(1, "Need at least one role"),
});

interface ICompanyModal {
  isOpen: boolean;
  setIsOpen: any;
  selectedCompany?: any;
}
const CompanyModal: React.FC<ICompanyModal> = ({
  isOpen,
  setIsOpen,
  selectedCompany,
}) => {
  const { state, dispatch } = useContext(GlobalContext);
  const { property, meta } = state;
  const { propertyId } = useParams();
  const [getCompany] = useApiCall(getOwnersAPI);
  const [postCompany] = useApiCall(postCompanyAPI);
  const [postBuildingCompany] = useApiCall(postBuildingCompanyAPI);
  const [putBuildingCompany] = useApiCall(putBuildingCompanyAPI);
  const [selectedCompanyOption, setSelectedCompanyOption] =
    useState<any>(undefined);

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    values,
    errors,
    touched,
    setValues,
    isValid,
    dirty,
  } = useFormik({
    initialValues: PROPERTY_COMPANY_INITIAL_VALUES,
    validationSchema: PROPERTY_COMPANY_VALIDATION_SCHEMA,
    onSubmit: async () => {
      try {
        dispatch({
          type: SET_LOADING_MODAL,
          payload: {
            open: true,
            label: `${!!selectedCompany ? "Updating" : "Creating"} Company`,
          },
        });
        let company = values?.company;
        if (typeof values?.company === "string") {
          const res = await postCompany({ name: values?.company });
          company = res?.id;
        }
        const roles = values?.roles?.map((item: any) => item.value);
        let response: any;
        if (selectedCompany) {
          response = await putBuildingCompany({
            id: selectedCompany?.id,
            payload: { building: propertyId, ...values, company, roles },
          });
          if (response) {
            const index = property?.companies?.findIndex(
              (company: BuildingCompanyProps) => company?.id === response?.id
            );
            if (index > -1) {
              const new_companies = [...property?.companies];
              new_companies[index] = response;
              dispatch({
                type: SET_PROPERTY,
                payload: {
                  ...property,
                  companies: new_companies,
                },
              });
            }
          }
        } else {
          response = await postBuildingCompany({
            building: propertyId,
            ...values,
            company,
            roles,
          });
          if (response) {
            const new_companies = [...property?.companies];
            new_companies.push(response);
            dispatch({
              type: SET_PROPERTY,
              payload: {
                ...property,
                companies: new_companies,
              },
            });
          }
        }
        setTimeout(() => {
          dispatch({
            type: SET_LOADING_MODAL,
            payload: {
              open: false,
              label: null,
            },
          });
        }, 100);

        Toast.success("Changes saved with success");
        setIsOpen(false);
      } catch (err) {
        Toast.warn("No changes to be saved");
        setTimeout(() => {
          dispatch({
            type: SET_LOADING_MODAL,
            payload: {
              open: false,
              label: null,
            },
          });
        }, 100);
      }
    },
  });

  useEffect(() => {
    if (!isOpen) return;
    if (selectedCompany) {
      let formValues = {};
      Object.keys(PROPERTY_COMPANY_INITIAL_VALUES).forEach((key: string) => {
        if (key === "roles") {
          const roles = selectedCompany?.roles?.map((item: any) => ({
            label: item,
            value: item,
          }));
          formValues = {
            ...formValues,
            [key]: roles || [],
          };
          return;
        }
        formValues = {
          ...formValues,
          [key]: selectedCompany[key] || null,
        };
      });
      setValues(formValues);
      if (selectedCompany?.company && selectedCompany?.name)
        setSelectedCompanyOption({
          value: selectedCompany?.company,
          label: selectedCompany?.name,
        });
    } else {
      setValues(PROPERTY_COMPANY_INITIAL_VALUES);
      setSelectedCompanyOption(undefined);
    }
  }, [selectedCompany, isOpen]);

  const loadCompanyOptions = async (keyword: string) => {
    return getCompany({ keyword: keyword }).then((res: any) => {
      return res.docs.map((company: any) => {
        return {
          value: company?.pk || company?.id,
          label: company?.name,
        };
      });
    });
  };

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen} size="default">
      <Modal.Header className="relative">
        <Button
          variant="neutral"
          leadingIcon={FiX}
          className="absolute top-0 right-0 w-5 !h-5"
          leadingIconClass="w-5 h-5 text-jll-color-text-base-subdued"
          onClick={() => setIsOpen(false)}
        />
        <h5 className="text-xl font-semibold text-jll-color-text-base-default">
          {selectedCompany ? "Update" : "Add"} Company
        </h5>
      </Modal.Header>

      <form onSubmit={handleSubmit}>
        <Modal.Body className="mt-6">
          <div className="flex flex-col space-y-4">
            <div className="flex flex-row items-center space-x-2">
              <Label className="w-4/12">Company</Label>
              <SelectAutoComplete
                name="company"
                className="w-full !mb-0"
                onChange={(option) => {
                  if (!option) {
                    setFieldValue("company", null);
                    setSelectedCompanyOption(undefined);
                  } else {
                    setFieldValue("company", option?.value);
                    setSelectedCompanyOption(option);
                  }
                }}
                onBlur={handleBlur}
                value={selectedCompanyOption}
                loadOptions={loadCompanyOptions}
                isCreatable
                isClearable
              />
            </div>

            <div className="flex flex-row items-center space-x-2">
              <Label className="w-4/12">Role</Label>
              <SelectAutoComplete
                name="roles"
                className="w-full !mb-0"
                isMulti
                onChange={(value) => {
                  setFieldValue("roles", value);
                }}
                onBlur={handleBlur}
                value={values?.roles}
                options={
                  meta?.BUILDING_COMPANY_ROLE_CHOICES?.map((role: string) => ({
                    value: role || "",
                    label: role || "",
                  })) || []
                }
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="submit"
            variant="primary"
            leadingIcon={FiPlusCircle}
            className="w-full justify-center"
            disabled={!isValid || !dirty}
          >
            {`${selectedCompany ? "Update" : "Create"} Company`}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default CompanyModal;
