import cn from "classnames";
import { Navbar } from "ui-molecules";
import LayoutBase from "../LayoutBase";
import LayoutListingHeader from "./LayoutListingHeader";
import LayoutListingContent from "./LayoutListingContent";
import LayoutListingContainer from "./LayoutListingContainer";

interface ILayoutProps {
  children: any;
  className?: string;
}

function LayoutListing<T extends object>({
  children,
  className,
}: ILayoutProps) {
  return (
    <LayoutBase>
      <div className={cn("w-full", className)}>
        <Navbar />
        {children}
      </div>
    </LayoutBase>
  );
}

LayoutListing.Header = LayoutListingHeader;
LayoutListing.Container = LayoutListingContainer;
LayoutListing.Content = LayoutListingContent;

export default LayoutListing;
