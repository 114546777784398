interface LayoutListingHeaderProps {
  children: any;
}

const LayoutListingHeader: React.FC<LayoutListingHeaderProps> = ({
  children,
}) => {
  return (
    <div className="absolute top-[64px] w-full flex justify-between items-center h-[80px] px-6 z-10 shadow-lg">
      {children}
    </div>
  );
};

export default LayoutListingHeader;
