import cn from "classnames";
import { Link } from "react-router-dom";
import { Chip } from "ui-atoms";

interface IStepTab {
  index: number;
  label: string;
  tag: string;
}
interface IStepTabBar {
  steps?: IStepTab[];
  setActiveTab?: any;
  current: string;
  className?: string;
  baseUrl?: string;
  tabLabel?: string;
}

const StepTabBar: React.FC<IStepTabBar> = ({
  steps,
  current,
  className,
  baseUrl,
  tabLabel,
}) => {
  return (
    <div className={cn("flex flex-row items-center", className)}>
      {steps?.map((step: IStepTab, idx: number) => (
        <div className={cn("flex flex-row items-center h-full mr-4")} key={idx}>
          {idx > 0 && (
            <hr
              className={cn("w-4 mr-4 border-jll-color-stroke-base-default")}
            />
          )}
          <Link
            to={`${baseUrl}?tab=${step.tag}`}
            className={cn("flex flex-row items-center h-full", {
              "border-b border-jll-color-coldGray-7": step?.tag === current,
            })}
          >
            <div
              className={cn(
                "whitespace-nowrap w-6 h-6 justify-center flex items-center rounded-full mr-2 text-xs font-semibold",
                {
                  "bg-jll-color-coldGray-1 text-jll-color-text-base-subdued":
                    step?.tag !== current,
                  "bg-jll-color-surface-info-default text-white":
                    step?.tag === current,
                }
              )}
            >{`${step?.index}`}</div>
            <div
              className={cn(
                "flex flex-col text-sm font-semibold",
                {
                  "text-jll-color-coldGray-5": step?.tag !== current,
                  "text-jll-color-text-base-default": step?.tag === current,
                }
              )}
            >
              {step?.index !== 1 && <span>{tabLabel}</span>}
              <span>{step?.label}</span>
              {step?.index === 1 && <span>{tabLabel}</span>}
            </div>
          </Link>
        </div>
      ))}
    </div>
  );
};

export default StepTabBar;
