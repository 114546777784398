import { capitalize } from "lodash";
import { Button } from "ui-atoms";
import {
  Modal,
  StyledActivityLogValue,
  StyledActivityValue,
} from "ui-molecules";
import { getFormatedDateTime, joinTwoStr } from "../../utils";
import { FiX } from "react-icons/fi";

interface IActivityChangeModal {
  data?: any;
  isOpen: boolean;
  setIsOpen?: any;
}

const ActivityChangeModal: React.FC<IActivityChangeModal> = ({
  isOpen,
  setIsOpen,
  data,
}) => {
  const handleOpen = (e: boolean) => {
    if (!e) {
      setIsOpen(false);
    }
  };

  return (
    <Modal isOpen={isOpen} setIsOpen={handleOpen} size="large">
      <Modal.Header>
        <div className="flex flex-row items-center justify-between pb-4">
          <h3 className="text-lg font-medium leading-6 text-gray-900">{`${
            data?.standardized?.target || ""
          } - ${data?.standardized?.type || ""} ${
            capitalize(data?.standardized?.action) || ""
          } Log`}</h3>

          <Button
            variant="neutral"
            leadingIcon={FiX}
            leadingIconClass="!w-5 !h-5 font-semibold text-xl"
            onClick={() => setIsOpen(false)}
          />
        </div>
        <div className="p-2 flex items-center justify-end">
          <p className="mr-2">
            {data?.update_timestamp &&
              getFormatedDateTime(data?.update_timestamp, {
                dateStyle: "long",
              })}
          </p>
          {(!!data?.first_name || !!data?.last_name) && (
            <p className="text-sm text-jll-color-text-base-default">
              <span className="text-jll-color-coldGray-7">updated by: </span>
              {joinTwoStr(data?.first_name, data?.last_name, " ")}
            </p>
          )}
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="divide-y divide-jll-color-stroke-base-default">
          {Object.keys(data)?.map((key, idx) => {
            if (!key?.includes("change__")) return;
            return (
              <div
                className="py-3 px-2 flex justify-between text-jll-color-text-base-default"
                key={idx}
              >
                <span className="mr-2">
                  {capitalize(
                    key?.replace("change__", "")?.replaceAll("_", " ")
                  )}
                </span>
                <StyledActivityLogValue activity={data} valueKey={key} />
              </div>
            );
          })}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ActivityChangeModal;
