import cn from "classnames";
import ReactDatePicker from "react-datepicker";
import type { ReactDatePickerProps } from "react-datepicker";
import { Chip, Label } from "ui-atoms";
import "react-datepicker/dist/react-datepicker.css";
import "./styles.css";
import { forwardRef, useEffect, useRef, useState } from "react";
import { getApiDate, getUTCDate } from "utils";

interface DatePickerProps extends ReactDatePickerProps {
  label?: string;
  error?: any;
  className?: string;
  pickerClassName?: string;
  pickerDivClassName?: string;
  optional?: boolean;
  trailingIcon?: any;
  onTrailingClick?: any;
  onChange: any;
}

const CustomInput = forwardRef(({ value, onChange, ...props }: any, ref) => {
  const [date, setDate] = useState<any>("");

  useEffect(() => {
    setDate(getApiDate(value));
  }, [value]);

  const handleChange = (e: any) => {
    setDate(e?.target?.value);
  };

  const handleBlur = (e: any) => {
    if (!!onChange && !!date) {
      onChange(getUTCDate(date));
    }
  };

  return (
    <input
      type="date"
      {...props}
      onChange={handleChange}
      onBlur={handleBlur}
      value={date}
      ref={ref}
    />
  );
});

const DatePicker: React.FC<DatePickerProps> = ({
  className,
  optional,
  label,
  error,
  pickerClassName,
  pickerDivClassName,
  trailingIcon,
  onTrailingClick,
  onChange,
  ...props
}) => {
  const inputRef = useRef<any>(null);

  let TrailingIcon;
  if (trailingIcon) {
    TrailingIcon = trailingIcon;
  }

  const handleChangeRaw = (date: any) => {
    onChange(date);
  };

  return (
    <div className={cn("relative mb-6", className)}>
      {(label || optional) && (
        <div className="flex flex-row items-center">
          {label ? (
            <Label htmlFor={props.id} className="mr-2">
              {label}
            </Label>
          ) : (
            <span></span>
          )}
          {optional && (
            <Chip
              variant="secondary"
              size="small"
              className="py-1 text-jll-color-coldGray-7"
            >
              Optional
            </Chip>
          )}
        </div>
      )}
      <div
        className={cn("relative rounded-md shadow-sm", pickerDivClassName, {
          "mt-2": !!label,
        })}
      >
        <ReactDatePicker
          className={cn(
            "block w-full rounded focus:border-jll-color-coldGray-7 focus:ring-jll-color-coldGray-7 sm:text-sm text-jll-color-text-base-default disabled:bg-jll-color-coldGray-2",
            {
              "border-jll-color-surface-accent-default": !!error,
              "border-jll-color-coldGray-4": !error,
              "pr-10": !!trailingIcon,
            },
            pickerClassName
          )}
          dateFormat="MM/dd/yyyy"
          customInput={<CustomInput ref={inputRef} />}
          onChangeRaw={(e) => handleChangeRaw(e)}
          onChange={(date: any) => {
            onChange(date);
          }}
          {...props}
        />
      </div>
      {trailingIcon && (
        <div
          className={cn("absolute inset-y-0 right-0 flex items-center pr-3", {
            "cursor-pointer": !!onTrailingClick,
            "pointer-events-none": !onTrailingClick,
          })}
          onClick={!!onTrailingClick ? onTrailingClick : undefined}
        >
          <TrailingIcon className="h-5 w-5 text-gray-400" />
        </div>
      )}
      {error && (
        <p className="absolute -bottom-5 text-xs text-jll-color-surface-accent-default">
          {error}
        </p>
      )}
    </div>
  );
};

export default DatePicker;
