import {
  InitialValuesProps,
  META_TAGS,
  OLM_PROPERTY_FIELDS,
  PROPERTY_TABS,
  SET_CONFIRM_MODAL,
  SET_LOADING_MODAL,
  SET_PROPERTY,
  STATUS_ACTIVE,
  URLS,
} from "constant";
import { GlobalContext } from "context";
import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import {
  AccordionItem,
  OLMFilterItem,
  RemoveDescriptionItem,
  WarningModal,
} from "ui-molecules";
import { LayoutEditProperty as Layout } from "ui-organisms";
import PropertyOpexSection from "./PropertyOpexSection";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Button, Checkbox, DatePicker, Input, Label, Toast } from "ui-atoms";
import {
  getApiDate,
  getApiPayloadFromForm,
  getUTCDate,
  isCommonItem,
} from "utils";
import { FiCalendar, FiChevronRight, FiInfo } from "react-icons/fi";
import { PROPERTY_FINANCIAL_INITIAL_VALUES } from "./constants";
import { Link, useParams } from "react-router-dom";
import { useApiCall, useCallbackPrompt } from "hooks";
import { patchPropertyAPI } from "services";
import PropertyLeaseRateSection from "./PropertyLeaseRateSection";
import cn from "classnames";
import React from "react";
import { renderToString } from "react-dom/server";

interface IPropertyFinancialV2 {
  onDiffFields?: any;
  diffFields?: any;
  isOLM?: boolean;
}

const validationSchema = Yup.object().shape({});

const PropertyFinancialV2: React.FC<IPropertyFinancialV2> = ({
  onDiffFields,
  diffFields,
  isOLM,
}) => {
  const { dispatch, state } = useContext(GlobalContext);
  const { property, showTooltip, hideTooltip } = state;
  const { propertyId } = useParams();
  const [patchProperty] = useApiCall(patchPropertyAPI);
  const [isLeaseRate, setIsLeaseRate] = useState(false);
  const [isWarningModal, setIsWarningModal] = useState(false);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(showDialog);

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    values,
    errors,
    touched,
    setValues,
  } = useFormik({
    initialValues: PROPERTY_FINANCIAL_INITIAL_VALUES,
    validationSchema,
    onSubmit: async () => {
      try {
        dispatch({
          type: SET_LOADING_MODAL,
          payload: {
            open: true,
            label: "Updating Property",
          },
        });
        let payload: InitialValuesProps | undefined = getApiPayloadFromForm(
          property,
          values
        );
        if (payload && Object.keys(payload)?.length) {
          if (payload?.last_sale_date) {
            payload["last_sale_date"] = getApiDate(payload?.last_sale_date);
          }
          if (payload?.debt_maturity_date) {
            payload["debt_maturity_date"] = getApiDate(
              payload?.debt_maturity_date
            );
          }
          if (
            payload &&
            property?.publish_status === STATUS_ACTIVE &&
            isCommonItem(Object.keys(payload), OLM_PROPERTY_FIELDS)
          ) {
            payload["publish_status"] = STATUS_ACTIVE;
          }
          const response = await patchProperty({
            id: property.id,
            payload,
          });
          setTimeout(() => {
            dispatch({
              type: SET_LOADING_MODAL,
              payload: {
                open: false,
                label: null,
              },
            });
          }, 100);
          if (!response) return;
          Toast.success("Changes saved with success");
          dispatch({
            type: SET_PROPERTY,
            payload: response,
          });
        } else {
          Toast.warn("No changes to be saved");
          setTimeout(() => {
            dispatch({
              type: SET_LOADING_MODAL,
              payload: {
                open: false,
                label: null,
              },
            });
          }, 100);
        }
      } catch (err) {
        Toast.warn("No changes to be saved");
        setTimeout(() => {
          dispatch({
            type: SET_LOADING_MODAL,
            payload: {
              open: false,
              label: null,
            },
          });
        }, 100);
      }
    },
  });

  useEffect(() => {
    if (!property || property?.id?.toString() !== propertyId?.toString())
      return;
    let formValues = {};
    Object.keys(PROPERTY_FINANCIAL_INITIAL_VALUES).forEach((key: string) => {
      formValues = {
        ...formValues,
        [key]: property[key],
      };
    });
    setValues({
      ...formValues,
      last_sale_date: property?.last_sale_date
        ? getUTCDate(property?.last_sale_date)
        : null,
      debt_maturity_date: property?.debt_maturity_date
        ? getUTCDate(property?.debt_maturity_date)
        : null,
    });
  }, [property, propertyId]);

  useEffect(() => {
    if (!property || !values) return;
    const diff = getApiPayloadFromForm(property, values);
    onDiffFields(diff);
  }, [property, values]);

  useEffect(() => {
    if (!diffFields || !Object.keys(diffFields)?.length) setShowDialog(false);
    else setShowDialog(true);
  }, [diffFields]);

  useEffect(() => {
    if (!showPrompt) return;
    const action = async () => {
      try {
        if (errors && !!Object.keys(errors)?.length) {
          setIsWarningModal(true);
          // @ts-ignore
          cancelNavigation();
          return;
        }
        await handleSubmit();
        // @ts-ignore
        confirmNavigation();
      } catch (err) {
        // @ts-ignore
        cancelNavigation();
      }
    };
    let isPublish = false;
    if (
      diffFields &&
      property?.publish_status === STATUS_ACTIVE &&
      isCommonItem(Object.keys(diffFields), OLM_PROPERTY_FIELDS)
    ) {
      isPublish = true;
    }

    dispatch({
      type: SET_CONFIRM_MODAL,
      payload: {
        open: true,
        content: {
          description: renderToString(
            <RemoveDescriptionItem description="You have un-saved changes, would you like to save changes before proceeding" />
          ),
          btnTitle: `${isPublish ? "Save and Publish" : "Save"}`,
          cancelTitle: "Proceed without Saving",
          action: action,
          cancel: confirmNavigation,
          additionalCancel: cancelNavigation,
        },
      },
    });
  }, [showPrompt]);

  const isSale = property?.tenure_types?.find((x: any) => x === "sale");
  const hideSale = values?.hide_sales_price;

  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>{`${META_TAGS?.default?.title} ${
          property?.title || property?.address
        } Financials`}</title>
        <meta
          property="og:title"
          content={`${META_TAGS?.default?.title} ${
            property?.title || property?.address
          } Financials`}
        />
      </Helmet>

      <form onSubmit={handleSubmit}>
        <OLMFilterItem
          fieldKey="opex"
          olmFields={OLM_PROPERTY_FIELDS}
          isOLM={isOLM}
        >
          <Layout.Section>
            <AccordionItem id="opex" label="OPEX">
              <div className="flex flex-row space-x-2 pt-8">
                <PropertyOpexSection />
              </div>
            </AccordionItem>
          </Layout.Section>
        </OLMFilterItem>

        <OLMFilterItem
          fieldKey="detail"
          olmFields={OLM_PROPERTY_FIELDS}
          isOLM={isOLM}
        >
          <Layout.Section>
            <AccordionItem id="details" label="Details">
              <div className="flex flex-col w-full pt-8">
                <div className="flex flex-row items-center space-x-2">
                  <Label className="w-4/12 mb-6">Purchase Price</Label>
                  <Input
                    className="w-full"
                    type="number"
                    name="purchase_price"
                    currency={property?.currency}
                    error={touched.purchase_price ? errors.purchase_price : ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Enter cost..."
                    value={values?.purchase_price}
                  />
                </div>

                <div className="flex flex-row items-center space-x-2">
                  <Label className="w-4/12 mb-6">Last Sale Date</Label>
                  <DatePicker
                    className="w-full"
                    selected={
                      values?.last_sale_date
                        ? getUTCDate(values?.last_sale_date)
                        : null
                    }
                    error={touched.last_sale_date ? errors.last_sale_date : ""}
                    value={values?.last_sale_date}
                    onChange={(date: Date) =>
                      setFieldValue("last_sale_date", date)
                    }
                    trailingIcon={FiCalendar}
                    placeholderText="Select..."
                    popperProps={{ strategy: "fixed" }}
                  />
                </div>

                <div className="flex flex-row items-center space-x-2">
                  <Label className="w-4/12 mb-6">Debt Amount</Label>
                  <Input
                    className="w-full"
                    type="number"
                    name="debt_amount"
                    currency={property?.currency}
                    error={touched.debt_amount ? errors.debt_amount : ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Enter cost..."
                    value={values?.debt_amount}
                  />
                </div>

                <div className="flex flex-row items-center space-x-2">
                  <Label className="w-4/12 mb-6">Debt Maturity Date</Label>
                  <DatePicker
                    className="w-full"
                    selected={
                      values?.debt_maturity_date
                        ? getUTCDate(values?.debt_maturity_date)
                        : null
                    }
                    error={
                      touched.debt_maturity_date
                        ? errors.debt_maturity_date
                        : ""
                    }
                    value={values?.debt_maturity_date}
                    onChange={(date: Date) =>
                      setFieldValue("debt_maturity_date", date)
                    }
                    trailingIcon={FiCalendar}
                    placeholderText="Select..."
                    popperProps={{ strategy: "fixed" }}
                  />
                </div>
              </div>
            </AccordionItem>
          </Layout.Section>
        </OLMFilterItem>

        <OLMFilterItem
          fieldKey="lease_rate"
          olmFields={OLM_PROPERTY_FIELDS}
          isOLM={isOLM}
        >
          <Layout.Section
            className={cn({
              block: isLeaseRate,
              hidden: !isLeaseRate,
            })}
          >
            <AccordionItem id="lease-rates" label="Lease Rates">
              <div className="flex flex-row space-x-2 pt-8">
                <PropertyLeaseRateSection setIsLeaseRate={setIsLeaseRate} />
              </div>
            </AccordionItem>
          </Layout.Section>
        </OLMFilterItem>

        <Layout.Section>
          <AccordionItem id="settings" label="Settings">
            <div className="flex flex-col w-full pt-8">
              <OLMFilterItem
                fieldKey="hide_availabilities_price"
                olmFields={OLM_PROPERTY_FIELDS}
                isOLM={isOLM}
              >
                <div className="flex flex-row items-center w-full space-x-4 mb-6">
                  <Checkbox
                    label="Hide Availabilities Price"
                    name="hide_availabilities_price"
                    id="hide_availabilities_price"
                    checked={values.hide_availabilities_price}
                    onChange={(e: any) => {
                      setFieldValue(
                        "hide_availabilities_price",
                        e.target.checked
                      );
                    }}
                    onBlur={handleBlur}
                  />
                  <div className="flex flex-row items-center">
                    <FiInfo className="w-4 h-4 text-jll-color-text-base-reversedSubdued mr-2" />
                    <span className="text-jll-color-text-base-default text-xs">
                      If checked, will not display availability pricing.
                    </span>
                  </div>
                </div>
              </OLMFilterItem>

              <OLMFilterItem
                fieldKey="hide_sales_price"
                olmFields={OLM_PROPERTY_FIELDS}
                isOLM={isOLM}
              >
                <div className="flex flex-row items-center w-full space-x-4 mb-6">
                  <Checkbox
                    label="Hide Sales Price"
                    name="hide_sales_price"
                    id="hide_sales_price"
                    disabled={!isSale}
                    checked={values.hide_sales_price}
                    onChange={(e: any) => {
                      setFieldValue("hide_sales_price", e.target.checked);
                    }}
                    onBlur={handleBlur}
                  />
                  <div className="flex flex-row items-center">
                    <FiInfo className="w-4 h-4 text-jll-color-text-base-reversedSubdued mr-2" />
                    <span className="text-jll-color-text-base-default text-xs">
                      If checked, will not display sale pricing.
                    </span>
                  </div>
                </div>
              </OLMFilterItem>

              <OLMFilterItem
                fieldKey="max_sale"
                olmFields={OLM_PROPERTY_FIELDS}
                isOLM={isOLM}
              >
                <div className="flex flex-row items-center space-x-2">
                  <Label className="w-4/12 mb-6" optional>
                    Sale Price
                  </Label>
                  <Input
                    className="w-full"
                    type="number"
                    name="max_sale"
                    currency={property?.currency}
                    error={touched.max_sale ? errors.max_sale : ""}
                    onChange={handleChange}
                    disabled={!isSale || hideSale}
                    onBlur={handleBlur}
                    value={values?.max_sale}
                  />
                </div>
              </OLMFilterItem>
            </div>
          </AccordionItem>
        </Layout.Section>

        <div className="px-8 fixed bottom-0 left-[360px] right-0 flex justify-end bg-white shadow-detail-status-nav h-[80px] items-center">
          <div className="flex space-x-4">
            <Link
              to={`/property/${propertyId}/${URLS.PROPERTY.FORM}?tab=${PROPERTY_TABS.CONTACTS}`}
            >
              <Button variant="secondary" trailingIcon={FiChevronRight}>
                Next
              </Button>
            </Link>
            <Button
              disabled={!diffFields || !Object.keys(diffFields)?.length}
              variant="primary"
              type="submit"
              id="save-tooltip"
              onMouseOver={() => showTooltip("save-tooltip")}
              onMouseLeave={() => hideTooltip()}
              data-tooltip-content={
                property?.publish_status === STATUS_ACTIVE
                  ? "This saves the property in MyListings and publishes to OLM"
                  : "This saves the property, but does not publish to OLM"
              }
            >
              {property?.publish_status === STATUS_ACTIVE
                ? "Save and Publish"
                : "Save"}
            </Button>
          </div>
        </div>
      </form>

      <WarningModal
        isOpen={isWarningModal}
        setIsOpen={setIsWarningModal}
        errors={errors}
      />
    </>
  );
};

export default React.memo(PropertyFinancialV2);
