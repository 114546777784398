import queryString from "query-string";
import axios from "../axiosConfig";
import { APIProps } from "types";
import { API_PREFIX_URL } from "constant";

export const getTasksAPI = async (props: APIProps) => {
  const param_str = queryString.stringify(props, {
    arrayFormat: "bracket",
  });
  const res = await axios.get(`${API_PREFIX_URL}/task/?${param_str}`);
  return res;
};

export const postTaskAPI = async (payload: any) => {
  const res = await axios.post(`${API_PREFIX_URL}/task/`, payload);
  return res;
};

export const patchTaskAPI = async ({ id, payload}: any ) => {
  const res = await axios.patch(`${API_PREFIX_URL}/task/${id}/`, payload);
  return res;
};

export const deleteTaskAPI = async (id: number) => {
  const res = await axios.delete(`${API_PREFIX_URL}/task/${id}/`);
  return res;
};
