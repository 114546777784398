import { Page, View, Image, Text } from "@react-pdf/renderer";
import { PAGE_ORIENTATION, PAGE_SIZE } from "constant";
import styles from "./styles";
import HomepageBg from "assets/homepage_bg.jpg";
import LightLogo from "assets/pdf/light-logo.png";
import BlurImg from "assets/pdf/blur.png";
import { PropertyProps } from "types";
import { formatPhoneNumber, getImageFromURL, removeNewlines } from "utils";

interface IPageCover {
  bgImage?: string;
  property?: PropertyProps;
}

const PageCover: React.FC<IPageCover> = ({ bgImage, property }) => {
  let filteredBrokers: any[] = [];
  if (property?.brokers) {
    if (property?.brokers?.length <= 2) {
      filteredBrokers = property?.brokers;
    } else {
      filteredBrokers = [...property?.brokers]?.filter(
        (broker: any) => broker?.building_broker_role === "Lead Broker"
      );
    }
  }
  return (
    <Page
      size={PAGE_SIZE}
      orientation={PAGE_ORIENTATION.LANDSCAPE}
      style={styles.page}
    >
      <View style={styles.view}>
        {!bgImage?.length ? (
          <Image src={HomepageBg} fixed style={styles.image} />
        ) : (
          <Image src={getImageFromURL(bgImage)} fixed style={styles.image} />
        )}
      </View>
      <View style={styles.blurImg}>
        <Image src={BlurImg} fixed />
      </View>
      <View style={styles.coverImg}>
        <Image
          src={LightLogo}
          style={{ width: "100%", height: "100%" }}
          fixed
        />
      </View>

      <View style={styles.txtView}>
        <Text style={styles.surveyType}>{property?.title}</Text>
        <Text style={styles.address}>
          {[
            property?.address,
            [property?.city, property?.state, property?.post_code].join(", "),
          ].join(" ")}
        </Text>
        <Text style={styles.tenantName}>Property Brochure</Text>
      </View>

      <View style={styles.brokerView}>
        <Text style={styles.representTxt}>Contact</Text>
        {!!filteredBrokers?.length &&
          filteredBrokers?.map((broker) => (
            <View style={styles.brokerItem}>
              <Image
                src={getImageFromURL(broker?.photo)}
                style={styles.brokerImg}
              />
              <View style={styles.brokerContent}>
                <Text style={styles.brokerName}>
                  {removeNewlines(broker?.name)}
                </Text>
                <Text style={styles.brokerEmail}>{broker?.email}</Text>
                <Text style={styles.brokerEmail}>
                  {formatPhoneNumber(broker?.telephone)}
                </Text>
              </View>
            </View>
          ))}
      </View>
    </Page>
  );
};

export default PageCover;
