import cn from "classnames";
import { CHART_BAR_COLORS } from "constant";
import React from "react";
import Chart from "react-apexcharts";
import { ISeries } from "types";

interface IReportChart {
  label?: string;
  className?: string;
  series?: ISeries[];
  categories?: string[];
}

const initOptions = {
  chart: {
    type: "bar",
    height: "100%",
    toolbar: {
      show: true,
    },
    zoom: {
      enabled: true,
    },
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: "55%",
      endingShape: "rounded",
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    show: true,
    width: 2,
    colors: ["transparent"],
  },
  xaxis: {
    categories: [],
  },
  fill: {
    opacity: 1,
  },
  legend: {
    show: true,
    position: "top",
    horizontalAlign: "center",
    markers: {
      radius: 50,
    },
    showForSingleSeries: true,
  },
  tooltip: {
    y: {
      formatter: function (val: any) {
        return val;
      },
    },
  },
};

const ReportChart: React.FC<IReportChart> = ({
  label,
  className,
  series,
  categories,
}) => {
  let colors: string[] = [];
  if (!!series?.length) {
    series?.forEach((item: ISeries) => {
      colors.push(CHART_BAR_COLORS?.[item?.name]);
    });
  }
  let options = {
    ...initOptions,
    colors,
    series,
    xaxis: {
      categories,
    },
  };

  return (
    <div className={cn("flex flex-col h-full", className)}>
      <div className="flex flex-row justify-between items-center my-5">
        <span className="font-semibold text-xl text-jll-color-text-base-default">
          {label}
        </span>
      </div>
      <div className="w-full h-full">
        <Chart
          options={options as any}
          series={options?.series as any}
          type="bar"
          width="100%"
          height="100%"
        />
      </div>
    </div>
  );
};

export default React.memo(ReportChart);
