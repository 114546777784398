import type { FC } from "react";
import cn from "classnames";

export interface IChipProps extends React.HTMLAttributes<HTMLSpanElement> {
  variant: "primary" | "secondary" | "success" | "error" | "warning";
  size?: "small" | "default" | "large";
  children?: string;
  className?: string;
}

const Chip: FC<IChipProps> = ({
  variant,
  children,
  className,
  size = "default",
  ...props
}) => {
  let variantClassName =
    "inline-flex items-center rounded-full font-medium capitalize";
  if (variant === "primary") {
    variantClassName =
      variantClassName +
      " bg-jll-color-text-base-default text-jll-color-text-base-reversed ";
  } else if (variant === "secondary") {
    variantClassName =
      variantClassName + " bg-jll-color-coldGray-1 text-jll-color-coldGray-7";
  } else if (variant === "success") {
    variantClassName =
      variantClassName + " bg-jll-color-surface-success-default text-white";
  } else if (variant === "error") {
    variantClassName =
      variantClassName +
      " bg-jll-color-surface-danger-secondary text-jll-color-text-danger";
  } else if (variant === "warning") {
    variantClassName =
      variantClassName +
      " bg-jll-color-surface-warning-subdued text-jll-color-text-warning";
  }

  if (size === "small") {
    variantClassName = variantClassName + " px-2.5 py-0.5 text-xs";
  } else if (size === "default") {
    variantClassName = variantClassName + " px-3 py-1 text-sm";
  } else if (size === "large") {
    variantClassName = variantClassName + " px-3 py-2 text-base";
  }

  return (
    <span className={cn(variantClassName, className)} {...props}>
      {children}
    </span>
  );
};

export default Chip;
