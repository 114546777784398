import { format, formatDistance, differenceInDays } from "date-fns";
/**
 * Get formated date
 * @param value Ex: 2023-02-18T21:52:16.381000Z
 * @returns Ex: 2/18/2023
 */
export const getFormatedDate = (value: string = "") => {
  if (!value?.length) return "";
  const mydate = getUTCDate(value);
  return new Intl.DateTimeFormat("en-US").format(mydate);
};

/**
 * Get full formated date
 * Build with Intl. For more information about Intl please go to
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param value Ex: 2023-02-18T21:52:16.381000Z
 * @param options Ex: { dateStyle: "long" }
 * @returns Ex: March 20, 2023 at 2:26 PM
 */
export const getFormatedDateTime = (value?: string, options?: any) => {
  const defaultOption: any = {
    dateStyle: "long",
    timeStyle: "short",
  };
  try {
    const mydate = value ? getUTCDate(value) : getUTCDate();
    return new Intl.DateTimeFormat("en-US", options || defaultOption).format(
      mydate
    );
  } catch (err) {
    return "";
  }
};

/**
 * Get from now
 * @param value Ex: 2023-02-18T21:52:16.381000Z
 * @returns Ex: 3 years ago or a few seconds ago...
 */
export const getFormatedFromNow = (value: string) => {
  try {
    const mydate = formatDistance(new Date(value), new Date(), {
      addSuffix: true,
    });
    return mydate;
  } catch (err) {
    return "";
  }
};

/**
 * Get Difference Days from now
 * @param value Ex: 2023-02-18T21:52:16.381000Z
 * @returns Ex: 60
 */
export const getDifferenceDaysFromNow = (value: string | undefined) => {
  try {
    if (!value) return null;
    const days: number = differenceInDays(new Date(), new Date(value));
    return days;
  } catch (err) {
    return null;
  }
};

/**
 * Get formated date for api
 * @param value Ex: 2023-02-18T21:52:16.381000Z
 * @returns Ex: YYYY-MM-DD
 */
export const getApiDate = (value: Date) => {
  if (!value) return "";
  const result = format(
    new Date(new Date(value)?.toLocaleString("en-US", { timeZone: "UTC" })),
    "yyyy-MM-dd"
  );
  return result;
};

export const getUTCDate = (value: string = "") => {
  let date = new Date()?.toLocaleString("en-US", { timeZone: "UTC" });
  if (value)
    date = new Date(value)?.toLocaleString("en-US", { timeZone: "UTC" });
  const result = new Date(date);
  return result;
};
