export const emailContactTitleJSON = [
  {
    id: "e9FZIHr_PU",
    cells: [1],
    columns: [
      {
        id: "4phJIIib2F",
        contents: [
          {
            id: "MeRkOATMqd",
            type: "heading",
            values: {
              containerPadding: "30px 10px 10px",
              anchor: "",
              headingType: "h3",
              fontWeight: 700,
              fontSize: "16px",
              textAlign: "left",
              lineHeight: "140%",
              linkStyle: {
                inherit: true,
                linkColor: "#0000ee",
                linkHoverColor: "#0000ee",
                linkUnderline: true,
                linkHoverUnderline: true,
              },
              hideDesktop: false,
              displayCondition: null,
              _meta: {
                htmlID: "u_content_heading_25",
                htmlClassNames: "u_content_heading",
              },
              selectable: true,
              draggable: true,
              duplicatable: true,
              deletable: true,
              hideable: true,
              text: "Contacts",
            },
          },
        ],
        values: {
          backgroundColor: "",
          padding: "0px",
          border: {},
          borderRadius: "0px",
          _meta: { htmlID: "u_column_82", htmlClassNames: "u_column" },
        },
      },
    ],
    values: {
      displayCondition: null,
      columns: false,
      backgroundColor: "",
      columnsBackgroundColor: "",
      backgroundImage: {
        url: "",
        fullWidth: true,
        repeat: "no-repeat",
        size: "custom",
        position: "center",
      },
      padding: "0px",
      anchor: "",
      hideDesktop: false,
      _meta: { htmlID: "u_row_37", htmlClassNames: "u_row" },
      selectable: true,
      draggable: true,
      duplicatable: true,
      deletable: true,
      hideable: true,
    },
  },
];
