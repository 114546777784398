import React, { ReactElement, useEffect, useState } from "react";
import "./styles.css";

export interface IAccordionProps {
  type?: string;
  id: string;
  checked?: boolean;
  onChange?: (e: any) => void;
  className?: string;
  label?: string;
  labelChild?: React.ReactNode;
  labelChildClass?: string;
  children: React.ReactNode;
  tabContentChildClass?: string;
  button?: ReactElement;
}

const AccordionItem: React.FC<IAccordionProps> = ({
  type = "checkbox",
  id,
  checked = true,
  onChange,
  className,
  label,
  labelChild,
  labelChildClass,
  children,
  tabContentChildClass,
  button
}) => {
  const [isChecked, setIsChecked] = useState(checked);

  useEffect(() => {
    if (isChecked === checked) return;
    setIsChecked(checked);
  }, [checked]);

  const handleChange = (e: any) => {
    setIsChecked(e?.target.checked);
    onChange && onChange(e?.target.checked);
  };
  
  return (
    <div className={`w-full overflow-hidden ${className}`}>
      <input
        type={type}
        id={id}
        name={id}
        className="popup-accordion-input absolute opacity-0 -z-1"
        checked={isChecked}
        onChange={handleChange}
      />
      <label
        className={`tab-label flex justify-between items-center pb-4 border-b border-jll-color-coldGray-3 cursor-pointer ${labelChildClass}`}
        htmlFor={id}
      >
        {!!labelChild && labelChild}
        {!!label && (
          <label className="text-jll-color-text-base-default text-2xl font-semibold">
            {label}
          </label>
        )}
      </label>
      <div
        className={`tab-content max-h-0 bg-white truncate duration-150 ${tabContentChildClass}`}
      >
        {children}
      </div>
    </div>
  );
};

export default AccordionItem;
