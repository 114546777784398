import { useEffect, useContext } from "react";
import { Loading } from "ui-atoms";
import { Navbar } from "ui-molecules";
import {
  FiCalendar,
  FiFileText,
  FiActivity,
  FiSettings,
  FiCheckSquare,
} from "react-icons/fi";
import Sidebar from "../LayoutEditSidebar";
import Section from "../LayoutEditSection";
import { useApiCall } from "hooks";
import { URLS } from "constant";
import { GlobalContext } from "context";
import {
  SET_PROPERTY,
} from "constant";
import { useParams } from "react-router-dom";
import { getPropertyAPI } from "services";
import LayoutBase from "../LayoutBase";
import cn from "classnames";

interface LayoutEditPropertyProps {
  children: any;
  className?: string;
  olmToggle?: { isOLM: boolean, setIsOLM: any }
}

function LayoutEditProperty<T extends object>({
  children,
  className,
  olmToggle
}: LayoutEditPropertyProps) {
  const { dispatch, state } = useContext(GlobalContext);
  const [getProperty, isLoading] = useApiCall(getPropertyAPI);
  const { propertyId } = useParams();
  const { property } = state;
  const backToUrl = `/`;
  const menuItems = [
    {
      label: "Property",
      icon: FiFileText,
      suffixUrl: URLS.PROPERTY.FORM,
      isOLMToggle: true
    },
    {
      label: "Availabilities",
      icon: FiCalendar,
      count: true,
      id: "suites",
      suffixUrl: `${URLS.PROPERTY.AVAILABILITIES}?active_status[]=1`,
      tag: URLS.PROPERTY.AVAILABILITIES,
    },
    {
      label: "Activity",
      icon: FiActivity,
      suffixUrl: URLS.PROPERTY.ACTIVITY,
    },
    {
      label: "Tasks",
      icon: FiCheckSquare,
      count: true,
      id: "tasks",
      suffixUrl: `${URLS.PROPERTY.TASKS}?active_status[]=1`,
      tag: URLS.PROPERTY.TASKS,
    },
  ];

  menuItems.push({
    label: "Admin",
    icon: FiSettings,
    suffixUrl: URLS.PROPERTY.ADMIN,
  });

  useEffect(() => {
    if (!propertyId) return;
    getProperty(propertyId).then((data: any) => {
      dispatch({
        type: SET_PROPERTY,
        payload: data,
      });
    });
  }, [propertyId]);

  if (isLoading && !property) {
    return (
      <div className="h-[200px]">
        <Loading />
      </div>
    );
  }

  return (
    <LayoutBase>
      <Navbar />
      <Sidebar.Property backToUrl={backToUrl} menuItems={menuItems} olmToggle={olmToggle}/>
      <div
        className={cn(
          "fixed top-[64px] left-[360px] bottom-0 right-0 overflow-x-auto",
          className
        )}
      >
        {isLoading && !!property && <Loading />}
        {!isLoading && !!property && children}
      </div>
    </LayoutBase>
  );
}

LayoutEditProperty.Section = Section;

export default LayoutEditProperty;
