import { useContext, type FC } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import {
  ActivityContent,
  AvatarGroup,
  EditableFileField,
  EditableMenuField,
  EditableMultiField,
  EditableSingleField,
  ImageGroup,
  ListTooltip,
} from "ui-molecules";
import { Link } from "react-router-dom";
import { Chip } from "ui-atoms";
import type { ActivityProps, AvailabilityProps } from "types";
import {
  AVAILABILITY_TABS,
  LEASE_TERM_NEGOTIABLE,
  SET_INLINE_EDIT_HIGHLIGHT_MODAL,
  STATUS_CONDITIONS,
  STATUS_REMOVED,
  URLS,
} from "constant";
import {
  lazyLoadImageOnError,
  getFormatedDate,
  ordinalSuffixOf,
  getDecimalFormating,
  convertExtension,
  joinTwoStr,
  getFormatedDateTime,
  noPropagation,
  getMetaOptions,
  capitalizeWords,
  getDifferenceDaysFromNow,
} from "utils";
import { convertImageUrl } from "services";
import { GlobalContext } from "context";
import { FiCheckCircle, FiEdit3 } from "react-icons/fi";
import { renderToString } from "react-dom/server";
import cn from "classnames";

export interface StyledAvailabilityValueProps {
  availability: AvailabilityProps;
  activity?: ActivityProps;
  valueKey:
    | keyof AvailabilityProps
    | "info"
    | "updated"
    | "created"
    | "size"
    | "space_use_type"
    | "floor_name"
    | "space_available"
    | "lease_terms"
    | "tenure_types"
    | "my_info"
    | "updated_info"
    | "status"
    | "activity_type"
    | "activity_content"
    | "building_staleness"
    | "activity_updated";
  isEditMode?: boolean;
  updateItem?: any;
  isProperty?: boolean;
}

const StyledAvailabilityValue: FC<StyledAvailabilityValueProps> = ({
  availability,
  valueKey,
  activity,
  isEditMode,
  updateItem,
  isProperty,
}) => {
  const { state, dispatch } = useContext(GlobalContext);
  const { showTooltip, hideTooltip, meta } = state;

  if (
    availability?.pk &&
    !availability?.id?.toString()?.toLowerCase().includes("suite")
  )
    return <></>;

  return (
    <>
      {(() => {
        let valueKeys: string[] = [];
        let labelKeys: string[] = [];
        const isLand = availability?.building_property_type === "land";
        let label = "";
        switch (valueKey) {
          case "building":
            return (
              <div className="flex space-x-2">
                <LazyLoadImage
                  className="w-10 h-10 rounded"
                  src={convertImageUrl(
                    convertExtension(availability?.building_images?.[0] || "")
                  )}
                  onError={lazyLoadImageOnError}
                />
                <Link
                  to={
                    !isProperty
                      ? `/property/${availability?.building}/${URLS.PROPERTY.FORM}`
                      : ""
                  }
                  onClick={!isProperty ? noPropagation : undefined}
                  className="flex flex-col truncate"
                >
                  <p
                    className="font-bold text-jll-color-text-base-default text-ellipsis overflow-hidden"
                    id={`suite-building-title-${
                      activity?.pk || activity?.id || "-"
                    }-${availability?.pk || availability?.id}`}
                    onMouseOver={() =>
                      showTooltip(
                        `suite-building-title-${
                          activity?.pk || activity?.id || "-"
                        }-${availability?.pk || availability?.id}`
                      )
                    }
                    onMouseLeave={() => hideTooltip()}
                    data-tooltip-content={availability?.building_title}
                  >
                    {availability?.building_title}
                  </p>
                  <p
                    className={cn(" text-ellipsis overflow-hidden", {
                      "text-jll-color-icon-info": !isProperty,
                    })}
                  >
                    {availability?.building_address}
                  </p>
                </Link>
              </div>
            );

          case "building_staleness":
            const diffDays: number | null = getDifferenceDaysFromNow(
              availability?.user_saved || availability?.update_timestamp
            );
            let type = "";
            if (diffDays !== null && diffDays < 45) {
              type = "green";
            } else if (diffDays !== null && diffDays >= 45 && diffDays < 90) {
              type = "yellow";
            } else if (diffDays === null || diffDays >= 90) {
              type = "red";
            }
            return (
              <div className="flex space-x-2">
                <div className="relative h-full w-max">
                  <LazyLoadImage
                    className="w-10 h-10 rounded"
                    src={convertImageUrl(
                      convertExtension(availability?.building_images?.[0] || "")
                    )}
                    onError={lazyLoadImageOnError}
                  />
                  <div className="rounded-full p-0.5 bg-white absolute right-[-5px] top-[-5px]">
                    <div
                      className={cn("w-3 h-3 rounded-full", {
                        "bg-jll-color-surface-danger-default": type === "red",
                        "bg-jll-color-surface-warning-default":
                          type === "yellow",
                        "bg-jll-color-surface-success-default":
                          type === "green",
                      })}
                    />
                  </div>
                </div>

                <Link
                  to={
                    !isProperty
                      ? `/property/${availability?.building}/${URLS.PROPERTY.FORM}`
                      : ""
                  }
                  onClick={!isProperty ? noPropagation : undefined}
                  className="flex flex-col truncate"
                >
                  <p
                    className="font-bold text-jll-color-text-base-default text-ellipsis overflow-hidden"
                    id={`suite-building-title-${
                      activity?.pk || activity?.id || "-"
                    }-${availability?.pk || availability?.id}`}
                    onMouseOver={() =>
                      showTooltip(
                        `suite-building-title-${
                          activity?.pk || activity?.id || "-"
                        }-${availability?.pk || availability?.id}`
                      )
                    }
                    onMouseLeave={() => hideTooltip()}
                    data-tooltip-content={availability?.building_title}
                  >
                    {availability?.building_title}
                  </p>
                  <p
                    className={cn(" text-ellipsis overflow-hidden", {
                      "text-jll-color-icon-info": !isProperty,
                    })}
                  >
                    {availability?.building_address}
                  </p>
                </Link>
              </div>
            );

          case "info":
          case "my_info":
            return (
              <EditableSingleField
                kind="availability"
                valueKeys={["name"]}
                isEditMode={isEditMode}
                initValues={availability}
                type="text"
                pk={availability?.pk || availability?.id}
                updateItem={updateItem}
              >
                <div className="flex space-x-2 w-max">
                  <LazyLoadImage
                    className="w-10 h-10 rounded"
                    src={convertImageUrl(
                      convertExtension(availability?.floorplans?.[0] || "")
                    )}
                    onError={lazyLoadImageOnError}
                  />
                  <div className="flex flex-col truncate">
                    <p className="font-bold text-jll-color-text-base-default text-ellipsis overflow-hidden">
                      Suite {availability?.name || ""}
                    </p>
                    <p className="whitespace-normal text-jll-color-text-base-subdued">
                      {valueKey === "info"
                        ? `${availability?.building_title || ""}`
                        : `ID ${availability.pk || availability.id || ""}`}
                    </p>
                  </div>
                </div>
              </EditableSingleField>
            );

          case "max_floor_area":
            label = isLand ? "Acreage Available" : "Space Available";
            valueKeys = ["min_floor_area", "max_floor_area", "floor_range"];
            labelKeys = [label, label, "Range"];
            return (
              <EditableMultiField
                kind="availability"
                valueKeys={valueKeys}
                isEditMode={isEditMode}
                initValues={availability}
                pk={availability?.pk || availability?.id}
                updateItem={updateItem}
                labelKeys={labelKeys}
                isLand={isLand}
                type="number"
              >
                {(() => {
                  if (
                    !availability?.min_floor_area &&
                    !availability?.max_floor_area
                  ) {
                    return <>-</>;
                  }
                  if (
                    availability?.min_floor_area &&
                    !availability?.max_floor_area
                  ) {
                    return (
                      <>
                        {getDecimalFormating(availability?.min_floor_area)}{" "}
                        <span className="text-xs text-jll-color-coldGray-6">
                          {availability?.building_surface_area_unit}
                        </span>
                      </>
                    );
                  }
                  if (
                    !availability?.min_floor_area &&
                    availability?.max_floor_area
                  ) {
                    return (
                      <>
                        {getDecimalFormating(availability?.max_floor_area)}{" "}
                        <span className="text-xs text-jll-color-coldGray-6">
                          {availability?.building_surface_area_unit}
                        </span>
                      </>
                    );
                  }

                  if (
                    availability?.min_floor_area ===
                    availability?.max_floor_area
                  ) {
                    return (
                      <>
                        {getDecimalFormating(availability?.max_floor_area)}{" "}
                        <span className="text-xs text-jll-color-coldGray-6">
                          {availability?.building_surface_area_unit}
                        </span>
                      </>
                    );
                  }

                  if (
                    availability?.min_floor_area &&
                    availability?.max_floor_area
                  ) {
                    return (
                      <>
                        {getDecimalFormating(availability?.min_floor_area)}-
                        {getDecimalFormating(availability?.max_floor_area)}
                        <span className="text-xs text-jll-color-coldGray-6">
                          {availability?.building_surface_area_unit}
                        </span>
                      </>
                    );
                  }
                  return <>-</>;
                })()}
              </EditableMultiField>
            );

          case "tenure_types":
            return (
              <EditableMenuField
                kind="availability"
                valueKeys={["tenure_types"]}
                isEditMode={isEditMode}
                initValues={availability}
                pk={availability?.pk || availability?.id}
                updateItem={updateItem}
                options={[...getMetaOptions(meta?.suite?.tenure_types)]}
              >
                {!availability?.tenure_types?.length ? (
                  <>-</>
                ) : (
                  availability?.tenure_types?.map((type, index) => (
                    <Chip
                      size="small"
                      key={index}
                      variant="secondary"
                      className="m-1"
                    >
                      {type?.toLowerCase() === "rent" ? "Lease" : type}
                    </Chip>
                  ))
                )}
              </EditableMenuField>
            );

          case "broker":
            if (!availability?.broker?.length) {
              return <>-</>;
            }
            return (
              <Link
                onClick={noPropagation}
                className="text-jll-color-icon-info mb-1 flex flex-row items-center"
                to={`/property/${availability?.building}/availability/${availability?.pk}/${URLS.AVAILABILITY.FORM}?tab=${AVAILABILITY_TABS.CONTACTS}`}
              >
                <AvatarGroup
                  uniqueId={availability.pk}
                  brokers={availability?.broker}
                  max={3}
                />
              </Link>
            );

          case "create_timestamp":
            if (!availability?.create_timestamp) {
              return <>-</>;
            }
            return <>{getFormatedDate(availability?.create_timestamp)}</>;

          case "space_use_type":
          case "suite_condition":
            return (
              <EditableSingleField
                kind="availability"
                valueKeys={[valueKey]}
                isEditMode={isEditMode}
                initValues={availability}
                type="select"
                pk={availability?.pk || availability?.id}
                updateItem={updateItem}
                options={[...getMetaOptions(meta?.suite?.[valueKey])]}
              >
                {availability?.[valueKey] ? (
                  <Chip size="small" variant={"primary"}>
                    {availability?.[valueKey]}
                  </Chip>
                ) : (
                  <>-</>
                )}
              </EditableSingleField>
            );

          case "floor_name":
            return (
              <div className="flex flex-col">
                <p className="font-semibold text-jll-color-text-base-default text-2xl">
                  {!!availability?.floor?.length &&
                    `${ordinalSuffixOf(availability?.floor) || ""} Floor`}
                  {!!availability?.floor?.length &&
                    !!availability?.name?.length &&
                    ", "}
                  {!!availability?.name?.length &&
                    `Suite ${availability?.name || ""}`}
                </p>
                <p className="text-jll-color-coldGray-7 text-base">
                  {availability?.building_address}
                </p>
                <p className="text-jll-color-coldGray-7 text-base">{`${joinTwoStr(
                  joinTwoStr(
                    availability?.building_address_area || "",
                    availability?.building_city || "",
                    " "
                  ),
                  availability?.building_state || ""
                )}`}</p>
              </div>
            );

          case "space_available":
            if (availability?.max_floor_area && availability?.min_floor_area) {
              if (availability?.max_floor_area === availability?.min_floor_area)
                return (
                  <>{`${getDecimalFormating(
                    availability?.max_floor_area
                  )} SF`}</>
                );
              return (
                <>{`${getDecimalFormating(
                  availability?.min_floor_area
                )} - ${getDecimalFormating(
                  availability?.max_floor_area
                )} SF`}</>
              );
            } else if (availability?.max_floor_area) {
              return (
                <>{`${getDecimalFormating(availability?.max_floor_area)} SF`}</>
              );
            } else if (availability?.min_floor_area) {
              return (
                <>{`${getDecimalFormating(availability?.min_floor_area)} SF`}</>
              );
            } else return <>-</>;

          case "lease_terms":
            return <>{availability?.lease_term || "-"}</>;

          case "max_contiguous":
            return (
              <EditableSingleField
                kind="availability"
                valueKeys={[valueKey]}
                isEditMode={isEditMode}
                initValues={availability}
                type="number"
                pk={availability?.pk || availability?.id}
                updateItem={updateItem}
              >
                {availability[valueKey] ? (
                  <>{availability[valueKey]}</>
                ) : (
                  <>-</>
                )}
              </EditableSingleField>
            );

          case "date_on_market":
            return (
              <EditableSingleField
                kind="availability"
                valueKeys={[valueKey]}
                isEditMode={isEditMode}
                initValues={availability}
                type="date"
                pk={availability?.pk || availability?.id}
                updateItem={updateItem}
              >
                {availability[valueKey] ? (
                  <>{getFormatedDate(availability[valueKey])}</>
                ) : (
                  <>-</>
                )}
              </EditableSingleField>
            );

          case "is_full_floor":
          case "is_vacant":
            return (
              <EditableSingleField
                kind="availability"
                valueKeys={[valueKey]}
                isEditMode={isEditMode}
                initValues={availability}
                type="check"
                label={capitalizeWords(valueKey, "_")}
                pk={availability?.pk || availability?.id}
                updateItem={updateItem}
              >
                {!availability?.[valueKey] ? <>-</> : <FiCheckCircle />}
              </EditableSingleField>
            );

          case "date_available":
            return (
              <EditableSingleField
                kind="availability"
                valueKeys={[valueKey]}
                isEditMode={isEditMode}
                initValues={availability}
                type="select"
                pk={availability?.pk || availability?.id}
                updateItem={updateItem}
                options={[...getMetaOptions(meta?.suite?.[valueKey])]}
              >
                {availability?.[valueKey] ? (
                  <>{availability?.[valueKey]}</>
                ) : (
                  <>-</>
                )}
              </EditableSingleField>
            );

          case "rent_type":
            return (
              <EditableSingleField
                kind="availability"
                valueKeys={[valueKey]}
                isEditMode={isEditMode}
                initValues={availability}
                type="select"
                pk={availability?.pk || availability?.id}
                updateItem={updateItem}
                options={[...getMetaOptions(meta?.suite?.[valueKey])]}
              >
                {availability?.["rent_type"] ? (
                  <>
                    {
                      meta?.suite?.[valueKey]?.[availability?.["rent_type"]]
                        ?.value
                    }
                  </>
                ) : (
                  <>-</>
                )}
              </EditableSingleField>
            );

          case "lease_term":
            return (
              <EditableSingleField
                kind="availability"
                valueKeys={[valueKey]}
                isEditMode={isEditMode}
                initValues={availability}
                type="select"
                pk={availability?.pk || availability?.id}
                updateItem={updateItem}
                options={[...getMetaOptions(meta?.suite?.[valueKey])]}
              >
                {availability?.[valueKey] ? (
                  <>
                    {availability?.[valueKey] === LEASE_TERM_NEGOTIABLE
                      ? availability?.[valueKey]
                      : `${availability?.[valueKey]} Months`}
                  </>
                ) : (
                  <>-</>
                )}
              </EditableSingleField>
            );

          case "floor":
            return (
              <EditableSingleField
                kind="availability"
                valueKeys={["floor"]}
                isEditMode={isEditMode}
                initValues={availability}
                type="text"
                pk={availability?.pk || availability?.id}
                updateItem={updateItem}
              >
                {ordinalSuffixOf(availability?.floor) || ""}
              </EditableSingleField>
            );

          case "status":
            if (!availability?.publish_status || !availability?.active_status) {
              return <>-</>;
            }
            if (availability?.active_status === STATUS_REMOVED)
              return (
                <Chip size="small" variant="error">
                  Inactive
                </Chip>
              );
            return (
              <Chip
                size="small"
                variant={
                  // @ts-ignore
                  STATUS_CONDITIONS?.[availability?.publish_status]?.variant as
                    | "secondary"
                    | "success"
                    | "error"
                }
              >
                {/* @ts-ignore */}
                {STATUS_CONDITIONS?.[availability?.publish_status]?.label}
              </Chip>
            );

          case "updated_info":
            return (
              <div>
                <p>
                  {availability?.update_timestamp &&
                    getFormatedDateTime(availability?.update_timestamp, {
                      dateStyle: "long",
                    })}
                </p>
                {(!!availability?.updated_user_first_name ||
                  !!availability?.updated_user_last_name) && (
                  <p className="text-sm text-jll-color-text-base-default">
                    <span className="text-jll-color-coldGray-7">
                      updated by:{" "}
                    </span>
                    {joinTwoStr(
                      availability?.updated_user_first_name,
                      availability?.updated_user_last_name,
                      " "
                    )}
                  </p>
                )}
              </div>
            );

          case "activity_type":
            if (!activity?.type) {
              return <>-</>;
            }
            return <span className="capitalize">{activity?.type_name}</span>;

          case "activity_content":
            return <ActivityContent activity={activity} />;

          case "activity_updated":
            return (
              <div>
                <p>
                  {activity?.update_timestamp &&
                    getFormatedDateTime(activity?.update_timestamp, {
                      dateStyle: "long",
                    })}
                </p>
                {(!!activity?.first_name || !!activity?.last_name) && (
                  <p className="text-sm text-jll-color-text-base-default">
                    <span className="text-jll-color-coldGray-7">
                      updated by:{" "}
                    </span>
                    {joinTwoStr(activity?.first_name, activity?.last_name, " ")}
                  </p>
                )}
              </div>
            );

          case "highlights":
            return (
              <div className="flex flex-row items-center justify-between space-x-2">
                {!!availability?.[valueKey]?.length ? (
                  <div
                    onClick={noPropagation}
                    className="text-jll-color-icon-info mb-1 flex flex-row items-center"
                    id={`tooltip-highlights-${availability?.pk}`}
                    onMouseOver={() =>
                      !!availability?.highlights?.length &&
                      showTooltip(`tooltip-highlights-${availability?.pk}`)
                    }
                    onMouseLeave={() => hideTooltip()}
                    data-tooltip-html={renderToString(
                      <ListTooltip
                        list={
                          availability?.[valueKey]?.map((item) => item) || []
                        }
                      />
                    )}
                  >
                    {availability?.[valueKey]?.length} Highligt
                    {Number(availability?.[valueKey]?.length) > 1 ? "s" : ""}
                  </div>
                ) : (
                  <>-</>
                )}
                {isEditMode ? (
                  <FiEdit3
                    className="w-4 h-4 text-jll-color-coldGray-4 cursor-pointer"
                    onClick={() => {
                      dispatch({
                        type: SET_INLINE_EDIT_HIGHLIGHT_MODAL,
                        payload: {
                          open: true,
                          content: {
                            values: availability?.[valueKey],
                            kind: "availability",
                            pk: availability?.pk,
                            updateItem,
                          },
                        },
                      });
                    }}
                  />
                ) : (
                  <></>
                )}
              </div>
            );

          case "images":
          case "floorplans":
          case "brochures":
            return (
              <EditableFileField
                kind="availability"
                valueKeys={[valueKey]}
                isEditMode={isEditMode}
                initValues={availability}
                pk={availability?.pk || availability?.id}
                updateItem={updateItem}
              >
                <ImageGroup
                  images={availability?.[valueKey] as string[]}
                  max={3}
                />
              </EditableFileField>
            );

          case "max_price":
            label = isLand ? "Acre" : "SF";
            valueKeys = ["min_price", "max_price", "price_range"];
            labelKeys = [label, label, "Range"];
            return (
              <EditableMultiField
                kind="availability"
                valueKeys={valueKeys}
                isEditMode={isEditMode}
                initValues={availability}
                pk={availability?.pk || availability?.id}
                updateItem={updateItem}
                labelKeys={labelKeys}
                isLand={isLand}
                type="number"
              >
                {(() => {
                  if (!availability?.min_price && !availability?.max_price) {
                    return <>Negotiable</>;
                  }
                  if (availability?.min_price && !availability?.max_price) {
                    return (
                      <>
                        $
                        {Number(
                          getDecimalFormating(Number(availability?.min_price))
                        )?.toFixed(2)}
                      </>
                    );
                  }
                  if (!availability?.min_price && availability?.max_price) {
                    return (
                      <>
                        $
                        {Number(
                          getDecimalFormating(Number(availability?.max_price))
                        )?.toFixed(2)}
                      </>
                    );
                  }

                  if (availability?.min_price === availability?.max_price) {
                    return (
                      <>
                        $
                        {Number(
                          getDecimalFormating(Number(availability?.max_price))
                        )?.toFixed(2)}
                      </>
                    );
                  }

                  if (availability?.min_price && availability?.max_price) {
                    return (
                      <>
                        $
                        {Number(
                          getDecimalFormating(Number(availability?.min_price))
                        )?.toFixed(2)}
                        - $
                        {Number(
                          getDecimalFormating(Number(availability?.max_price))
                        )?.toFixed(2)}
                      </>
                    );
                  }
                  return <>-</>;
                })()}
              </EditableMultiField>
            );

          default:
            if (availability && availability.hasOwnProperty(valueKey)) {
              // @ts-ignore fix type
              return <>{availability[valueKey]}</>;
            }
            return <>-</>;
        }
      })()}
    </>
  );
};

export default StyledAvailabilityValue;
