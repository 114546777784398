import { useState } from "react";
import { ReactMultiEmail, isEmail } from "react-multi-email";
import "react-multi-email/dist/style.css";
import "./style.css";
import cn from "classnames";

interface IMultiEmailInput {
  emails: string[];
  setEmails: any;
  onBlur?: any;
  name?: string;
  error?: string;
  className?: string;
}

const MultiEmailInput: React.FC<IMultiEmailInput> = ({
  emails,
  setEmails,
  onBlur,
  error,
  className,
  ...props
}) => {
  return (
    <>
      <ReactMultiEmail
        placeholder="Input your email"
        emails={emails}
        onChange={(_emails: string[]) => {
          setEmails(_emails);
        }}
        autoFocus={true}
        {...props}
        onBlur={onBlur}
        className={cn(
          "focus:!border-jll-color-coldGray-7 focus:!ring-jll-color-coldGray-7 !text-jll-color-text-base-default !border-jll-color-coldGray-4 !p-0 h-[38px]",
          className
        )}
        inputClassName={cn(
          "block w-full rounded focus:border-jll-color-coldGray-7 focus:ring-jll-color-coldGray-7 sm:text-sm text-jll-color-text-base-default disabled:bg-jll-color-coldGray-2 h-full !px-3",
          {
            "border-jll-color-surface-accent-default": !!error,
            "border-jll-color-coldGray-4": !error,
          }
        )}
        getLabel={(email, index, removeEmail) => {
          return (
            <div data-tag key={index}>
              <div data-tag-item>{email}</div>
              <span data-tag-handle onClick={() => removeEmail(index)}>
                ×
              </span>
            </div>
          );
        }}
      />
      {!!error && (
        <p className="absolute -bottom-5 text-xs text-jll-color-surface-accent-default">
          {error}
        </p>
      )}
    </>
  );
};

export default MultiEmailInput;
