import NoImage from "assets/noImage.png";
import NoFloorPlanImg from "assets/pdf/no-floor-plan.png";

export const lazyLoadImageOnError = (
  event: React.SyntheticEvent<HTMLImageElement, Event>
) => {
  event.currentTarget.onerror = null;
  event.currentTarget.src = NoImage;
};

let noImageBuffer: any = undefined;
let noFloorPlan: any = undefined;

const getBufferFromNoImage = (url: string) => {
  let xhr = new XMLHttpRequest();
  xhr.open("GET", url, true);
  xhr.responseType = "blob";
  xhr.onload = function () {
    if (xhr.status === 200) {
      const reader = new FileReader();
      reader.readAsDataURL(xhr.response);
      reader.onloadend = () => (noImageBuffer = reader.result);
    }
  };
  xhr.send();
};

const getBufferFromNoFloor = (url: string) => {
  let xhr = new XMLHttpRequest();
  xhr.open("GET", url, true);
  xhr.responseType = "blob";
  xhr.onload = function () {
    if (xhr.status === 200) {
      const reader = new FileReader();
      reader.readAsDataURL(xhr.response);
      reader.onloadend = () => (noFloorPlan = reader.result);
    }
  };
  xhr.send();
};

getBufferFromNoImage(NoImage);
getBufferFromNoFloor(NoFloorPlanImg);

export const getImageFromURL = (image_url?: string, flag = false): any => {
  const url = image_url;
  if (!url) {
    if (!flag) return noImageBuffer;
    else return null;
  }
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.responseType = "blob";
    xhr.onload = () => {
      if (xhr.readyState === 4 && xhr.status === 200) {
        resolve(url);
      } else {
        if (!flag) resolve(noImageBuffer);
        else resolve(noFloorPlan);
      }
    };
    xhr.onerror = (e) => {
      if (!flag) resolve(noImageBuffer);
      else resolve(noFloorPlan);
    };
    xhr.ontimeout = (e) => {
      if (!flag) resolve(noImageBuffer);
      else resolve(noFloorPlan);
    };
    xhr.send();
  });
};

export const convertBytesTo = (bytes: number, decimals: number = 2) => {
  if (!+bytes) return "0 bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["bytes", "kb", "mb", "gb", "tb", "pb", "eb", "zb", "yb"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const fetchFile = async (url: string) => {
  try {
    if (!url?.length) return;
    const response = await fetch(url);
    if (response.ok) {
      const contentType = response.headers.get("Content-Type");
      if (
        contentType?.includes("image") ||
        contentType?.includes("pdf") ||
        contentType?.includes("doc")
      ) {
        const data = await response.blob();
        return data;
      } else {
        return null;
      }
    } else {
      return null;
    }
  } catch (err) {
    console.error("Error fetching file:", err);
  }
};
