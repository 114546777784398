export const emailPropertyVideosJSON = [
  {
    id: "ZecTQI4Iwk",
    cells: [1],
    columns: [
      {
        id: "E8EKFbk6TW",
        contents: [
          {
            id: "NkTwFBtq4f",
            type: "heading",
            values: {
              containerPadding: "10px",
              anchor: "",
              headingType: "h4",
              fontWeight: 700,
              fontSize: "14px",
              textAlign: "left",
              lineHeight: "140%",
              linkStyle: {
                inherit: true,
                linkColor: "#0000ee",
                linkHoverColor: "#0000ee",
                linkUnderline: true,
                linkHoverUnderline: true,
              },
              hideDesktop: false,
              displayCondition: null,
              _meta: {
                htmlID: "u_content_heading_16",
                htmlClassNames: "u_content_heading",
              },
              selectable: true,
              draggable: true,
              duplicatable: true,
              deletable: true,
              hideable: true,
              text: "Videos",
            },
          },
        ],
        values: {
          backgroundColor: "#f6f7f7",
          padding: "0px",
          border: {},
          borderRadius: "0px",
          _meta: { htmlID: "u_column_53", htmlClassNames: "u_column" },
        },
      },
    ],
    values: {
      displayCondition: null,
      columns: false,
      backgroundColor: "",
      columnsBackgroundColor: "",
      backgroundImage: {
        url: "",
        fullWidth: true,
        repeat: "no-repeat",
        size: "custom",
        position: "center",
      },
      padding: "0px",
      anchor: "",
      hideDesktop: false,
      _meta: { htmlID: "u_row_23", htmlClassNames: "u_row" },
      selectable: true,
      draggable: true,
      duplicatable: true,
      deletable: true,
      hideable: true,
    },
  },
  {
    id: "property_videos",
    cells: [1, 1, 1, 1],
    columns: [
      {
        id: "UiYidKFqMv",
        contents: [
          {
            id: "fSaYlItLaW",
            type: "image",
            values: {
              containerPadding: "10px",
              anchor: "",
              src: {
                url: "https://cdn.tools.unlayer.com/image/placeholder.png",
                width: 800,
                height: 200,
              },
              textAlign: "center",
              altText: "",
              action: {
                name: "web",
                attrs: { href: "{{href}}", target: "{{target}}" },
                values: { href: "", target: "_blank" },
              },
              hideDesktop: false,
              displayCondition: null,
              _meta: {
                htmlID: "u_content_image_23",
                htmlClassNames: "u_content_image",
              },
              selectable: true,
              draggable: true,
              duplicatable: true,
              deletable: true,
              hideable: true,
            },
          },
        ],
        values: {
          backgroundColor: "#f6f7f7",
          padding: "0px",
          border: {},
          borderRadius: "0px",
          _meta: { htmlID: "u_column_54", htmlClassNames: "u_column" },
        },
      },
      {
        id: "z8zgPCJFQP",
        contents: [
          {
            id: "GvIjTwi7gZ",
            type: "image",
            values: {
              containerPadding: "10px",
              anchor: "",
              src: {
                url: "https://cdn.tools.unlayer.com/image/placeholder.png",
                width: 800,
                height: 200,
              },
              textAlign: "center",
              altText: "",
              action: {
                name: "web",
                attrs: { href: "{{href}}", target: "{{target}}" },
                values: { href: "", target: "_blank" },
              },
              hideDesktop: false,
              displayCondition: null,
              _meta: {
                htmlID: "u_content_image_24",
                htmlClassNames: "u_content_image",
              },
              selectable: true,
              draggable: true,
              duplicatable: true,
              deletable: true,
              hideable: true,
            },
          },
        ],
        values: {
          backgroundColor: "#f6f7f7",
          padding: "0px",
          border: {},
          borderRadius: "0px",
          _meta: { htmlID: "u_column_55", htmlClassNames: "u_column" },
        },
      },
      {
        id: "ro6kfpgaW0",
        contents: [
          {
            id: "2tyxHbsmEd",
            type: "image",
            values: {
              containerPadding: "10px",
              anchor: "",
              src: {
                url: "https://cdn.tools.unlayer.com/image/placeholder.png",
                width: 800,
                height: 200,
              },
              textAlign: "center",
              altText: "",
              action: {
                name: "web",
                attrs: { href: "{{href}}", target: "{{target}}" },
                values: { href: "", target: "_blank" },
              },
              hideDesktop: false,
              displayCondition: null,
              _meta: {
                htmlID: "u_content_image_25",
                htmlClassNames: "u_content_image",
              },
              selectable: true,
              draggable: true,
              duplicatable: true,
              deletable: true,
              hideable: true,
            },
          },
        ],
        values: {
          backgroundColor: "#f6f7f7",
          padding: "0px",
          border: {},
          borderRadius: "0px",
          _meta: { htmlID: "u_column_56", htmlClassNames: "u_column" },
        },
      },
      {
        id: "q-QoKN0hd_",
        contents: [
          {
            id: "26daXfxhmL",
            type: "image",
            values: {
              containerPadding: "10px",
              anchor: "",
              src: {
                url: "https://cdn.tools.unlayer.com/image/placeholder.png",
                width: 800,
                height: 200,
              },
              textAlign: "center",
              altText: "",
              action: {
                name: "web",
                attrs: { href: "{{href}}", target: "{{target}}" },
                values: { href: "", target: "_blank" },
              },
              hideDesktop: false,
              displayCondition: null,
              _meta: {
                htmlID: "u_content_image_26",
                htmlClassNames: "u_content_image",
              },
              selectable: true,
              draggable: true,
              duplicatable: true,
              deletable: true,
              hideable: true,
            },
          },
        ],
        values: {
          backgroundColor: "#f6f7f7",
          padding: "0px",
          border: {},
          borderRadius: "0px",
          _meta: { htmlID: "u_column_57", htmlClassNames: "u_column" },
        },
      },
    ],
    values: {
      displayCondition: null,
      columns: false,
      backgroundColor: "",
      columnsBackgroundColor: "",
      backgroundImage: {
        url: "",
        fullWidth: true,
        repeat: "no-repeat",
        size: "custom",
        position: "center",
      },
      padding: "0px",
      anchor: "",
      hideDesktop: false,
      _meta: { htmlID: "u_row_24", htmlClassNames: "u_row" },
      selectable: true,
      draggable: true,
      duplicatable: true,
      deletable: true,
      hideable: true,
    },
  },
  {
    id: "XsSU4qP9l7",
    cells: [1],
    columns: [
      {
        id: "VnyA0yLUc9",
        contents: [
          {
            id: "TMhUN5yp8x",
            type: "divider",
            values: {
              width: "100%",
              border: {
                borderTopWidth: "1px",
                borderTopStyle: "solid",
                borderTopColor: "#BBBBBB",
              },
              textAlign: "center",
              containerPadding: "5px",
              anchor: "",
              hideDesktop: false,
              displayCondition: null,
              _meta: {
                htmlID: "u_content_divider_13",
                htmlClassNames: "u_content_divider",
              },
              selectable: true,
              draggable: true,
              duplicatable: true,
              deletable: true,
              hideable: true,
            },
          },
        ],
        values: {
          backgroundColor: "#f6f7f7",
          padding: "0px",
          border: {},
          borderRadius: "0px",
          _meta: { htmlID: "u_column_58", htmlClassNames: "u_column" },
        },
      },
    ],
    values: {
      displayCondition: null,
      columns: false,
      backgroundColor: "",
      columnsBackgroundColor: "",
      backgroundImage: {
        url: "",
        fullWidth: true,
        repeat: "no-repeat",
        size: "custom",
        position: "center",
      },
      padding: "0px",
      anchor: "",
      hideDesktop: false,
      _meta: { htmlID: "u_row_25", htmlClassNames: "u_row" },
      selectable: true,
      draggable: true,
      duplicatable: true,
      deletable: true,
      hideable: true,
    },
  },
];
