import { useEffect, useState } from "react";
import { Switch as ReactSwitch } from "@headlessui/react";
import { Chip, Label } from "ui-atoms";
import cn from "classnames";

// @ts-ignore
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

interface ISwitch {
  enabled: boolean;
  onChange: any;
  labelClassName?: string;
  label?: string;
  className?: string;
  optional?: boolean;
  title?: string;
  switchClassName?: string;
  label_1?: string;
  label_2?: string;
}

const Switch: React.FC<ISwitch> = ({
  enabled = false,
  onChange,
  labelClassName,
  label,
  className,
  optional,
  title,
  switchClassName,
  label_1,
  label_2,
}) => {
  const [innerEnabled, setInnerEnabled] = useState(enabled);

  useEffect(() => {
    if (innerEnabled !== enabled) setInnerEnabled(enabled);
  }, [enabled]);

  const handleChange = (flag: boolean) => {
    onChange(flag);
    setInnerEnabled(flag);
  };

  return (
    <div className={cn("relative mb-6", className)}>
      {(label || optional) && (
        <div className="flex flex-row items-center">
          {label ? (
            <Label className={cn("mr-2", labelClassName)}>{label}</Label>
          ) : (
            <span></span>
          )}
          {optional && (
            <Chip
              variant="secondary"
              size="small"
              className="py-1 text-jll-color-coldGray-7"
            >
              Optional
            </Chip>
          )}
        </div>
      )}
      <div
        className={cn("flex items-center", switchClassName, {
          "mt-2": !!label,
        })}
      >
        {!!label_1 && (
          <span
            className={cn("text-xs font-semibold mr-2", {
              "text-jll-color-text-base-subdued": !innerEnabled,
              "text-jll-color-coldGray-5": innerEnabled,
            })}
          >
            {label_1}
          </span>
        )}
        <ReactSwitch.Group>
          <ReactSwitch.Label passive>{title}</ReactSwitch.Label>
          <ReactSwitch
            checked={innerEnabled}
            onChange={handleChange}
            className={classNames(
              innerEnabled
                ? "bg-jll-color-text-base-default"
                : "bg-jll-color-coldGray-4",
              "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            )}
          >
            <span className="sr-only">Use setting</span>
            <span
              aria-hidden="true"
              className={classNames(
                innerEnabled ? "translate-x-5" : "translate-x-0",
                "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-jll-color-text-base-reversed shadow ring-0 transition duration-200 ease-in-out"
              )}
            />
          </ReactSwitch>
        </ReactSwitch.Group>
        {!!label_2 && (
          <span
            className={cn("text-xs font-semibold ml-2", {
              "text-jll-color-text-base-subdued": innerEnabled,
              "text-jll-color-coldGray-5": !innerEnabled,
            })}
          >
            {label_2}
          </span>
        )}
      </div>
    </div>
  );
};

export default Switch;
