const styles: any = {
  page: {
    backgroundColor: "white",
    fontFamily: "Source Sans Pro",
    color: "white",
    position: "relative",
    display: "flex",
    flexDirection: "row",
  },

  leftSideView: {
    width: 210,
    backgroudColor: "#F6F7F7",
    borderBottomRightRadius: 16,
    height: "100%",
  },
  infoView: {
    width: "100%",
    backgroudColor: "#F6F7F7",
    borderBottomRightRadius: 16,
    padding: 18,
    backgroundColor: "#F6F7F7",
    display: "flex",
    flexDirection: "column",
    alignSelf: "flex-start",
    marginBottom: 10,
  },
  leftImg: {
    width: 178,
    height: 178,
    borderRadius: 2,
    marginBottom: 10,
  },
  titleView: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 12,
  },
  id: {
    color: "#A4A9B0",
    fontSize: 10,
  },
  titleTxt: {
    color: "#3A3E43",
    fontSize: 14,
    fontWeight: 600,
  },
  addressView: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 12,
  },
  address: {
    color: "#3A3E43",
    fontSize: 10,
  },
  typeTxt: {
    backgroundColor: "#3A3E43",
    borderRadius: 28,
    padding: "2px 7px",
    color: "#FFFFFF",
    fontSize: 8,
    fontWeight: 600,
    display: "block",
    width: "auto",
    marginRight: 4,
  },
  tenantTypeTxt: {
    backgroundColor: "#11C169",
    borderRadius: 28,
    padding: "2px 7px",
    color: "#FFFFFF",
    fontSize: 8,
    fontWeight: 600,
    display: "block",
    width: "auto",
  },
  location: {
    view: {
      paddingLeft: 18,
      display: "flex",
      flexDirection: "column",
    },
    title: {
      fontWeight: 600,
      fontSize: 14,
      color: "#3A3E43",
      marginBottom: 10,
    },
    img: {
      width: "100%",
      height: 200,
      borderRadius: 4,
    },
  },

  rightSideView: {
    width: 632,
    padding: 18,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  right: {
    title: {
      fontSize: 14,
      fontWeight: 600,
      marginBottom: 12,
      color: "#3A3E43",
    },
    table: {
      display: "flex",
      flexDirection: "column",
    },
    row: {
      display: "flex",
      flexDirection: "row",
    },
    col: {
      view: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        paddingTop: 8,
        paddingBottom: 8,
      },
      borderView: {
        borderLeftWidth: 1,
        borderLeftColor: "#DDDFE1",
        borderLeftStyle: "solid",
        paddingLeft: 10,
        paddingRight: 10,
      },
      txt: {
        display: "flex",
        flexDirection: "row",
        fontSize: 10,
        fontWeight: 600,
        color: "#60666E",
      },
      contentTxt: {
        fontSize: 10,
        color: "#60666E",
      },
    },
  },

  brokerPanel: {
    width: "100%",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "row",
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    paddingLeft: 18,
    paddingRight: 18,
    marginBottom: 40,
  },
  brokerView: {
    display: "flex",
    flexDirection: "row",
    width: "25%",
    paddingLeft: 8,
    paddingRight: 8,
    borderLeftStyle: "solid",
    borderLeftColor: "#DDDFE1",
  },
  brokerImg: {
    width: 28,
    height: 28,
    borderRadius: 28,
    marginRight: 8,
    backgroundColor: "white",
    objectFit: "contain",
  },
  brokerContent: {
    display: "flex",
    flexDirection: "column",
  },
  brokerName: {
    fontWeight: 600,
    fontSize: 10,
    color: "#3A3E43",
  },
  brokerTxt: {
    fontSize: 8,
    color: "#60666E",
  },

  logo: {
    view: {
      position: "absolute",
      bottom: 17,
      right: 22,
      height: 18,
      width: 41,
    },
    img: {
      height: "100%",
      width: "100%",
    },
  },
};

export default styles;
