import { SET_INLINE_EDIT_SELECT_MODAL } from "constant";
import { GlobalContext } from "context";
import { useContext, useEffect, useState } from "react";
import { FiX } from "react-icons/fi";
import { AmenityProps } from "types";
import { Button, Checkbox } from "ui-atoms";
import { Modal } from "ui-molecules";
import { sliceIntoChunks } from "utils";

const InlineSelectModal = () => {
  const { state, dispatch } = useContext(GlobalContext);
  // inlineSelectModalContent - line(1 or 2, ...), list: {label, value}, label: "", func, current, key, service
  const { inlineSelectModalOpen, inlineSelectModalContent } = state;
  const [selectedOptions, setSelectedOptions] = useState<any>([]);

  useEffect(() => {
    if (
      !inlineSelectModalContent?.current &&
      inlineSelectModalContent?.service
    ) {
      inlineSelectModalContent?.service?.then((res: any) => {
        if (inlineSelectModalContent?.key === "amenities") {
          let arr: any = [];
          res?.[inlineSelectModalContent?.key]?.forEach(
            (amenity: AmenityProps) => {
              arr.push({
                value: amenity?.amenity,
                label:
                  amenity?.custom_amenity ||
                  amenity?.amenity_name ||
                  `${amenity?.id}`,
              });
            }
          );
          setSelectedOptions(arr);
        } else {
          setSelectedOptions(res?.[inlineSelectModalContent?.key]);
        }
      });
    } else if (inlineSelectModalContent?.current) {
      setSelectedOptions(inlineSelectModalContent?.current);
    } else if (
      !inlineSelectModalContent?.current &&
      inlineSelectModalContent?.service
    ) {
      setSelectedOptions([]);
    }
  }, [inlineSelectModalContent]);

  const handleClose = () => {
    dispatch({
      type: SET_INLINE_EDIT_SELECT_MODAL,
      payload: {
        open: false,
        content: null,
      },
    });
  };

  const handleCheck = (e: any, item: any) => {
    const index = selectedOptions.findIndex((option: any) =>
      option?.value ? option?.value === item?.value : option?.id === item?.id
    );
    let newSelectedOption: any[] = [...selectedOptions];
    if (e.target.checked) {
      newSelectedOption.push(item);
    } else {
      if (index > -1) newSelectedOption.splice(index, 1);
    }
    setSelectedOptions(newSelectedOption);
  };

  const handleSubmit = () => {
    inlineSelectModalContent?.func &&
      inlineSelectModalContent?.func(selectedOptions);
    handleClose();
  };

  return (
    <Modal
      isOpen={inlineSelectModalOpen}
      setIsOpen={handleClose}
      size="default"
    >
      <Modal.Header className="relative">
        <Button
          variant="neutral"
          leadingIcon={FiX}
          className="absolute top-0 right-0 w-5 !h-5"
          leadingIconClass="w-5 h-5 text-jll-color-text-base-subdued"
          onClick={handleClose}
        />
        <h5 className="text-xl font-semibold text-jll-color-text-base-default">
          {inlineSelectModalContent?.label || ""}
          <span className="text-sm text-jll-color-text-base-subdued font-normal ml-[10px]">
            {selectedOptions?.length || 0} selected
          </span>
        </h5>
      </Modal.Header>
      <Modal.Body className="space-y-2 mt-8">
        {inlineSelectModalContent?.list?.length &&
          sliceIntoChunks(
            inlineSelectModalContent?.list,
            inlineSelectModalContent?.line
          ).map((group, idx) => (
            <div key={idx} className="flex flex-row space-x-2">
              {group?.map((item, idx1) => {
                if (!item) return;
                return (
                  <Checkbox
                    key={idx1}
                    id={`column-${idx}-${idx1}`}
                    label={
                      item?.label ||
                      item?.[inlineSelectModalContent?.checkLabelKey]
                    }
                    className="w-full"
                    checked={
                      !!selectedOptions.find((option: any) =>
                        option?.value
                          ? option?.value === item?.value
                          : option?.id === item?.id
                      )
                    }
                    onChange={(e: any) => {
                      handleCheck(e, item);
                    }}
                  />
                );
              })}
            </div>
          ))}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          className="w-full flex justify-center items-center"
          onClick={handleSubmit}
        >
          Done
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default InlineSelectModal;
