interface IOLMFilterItem {
  fieldKey: string;
  olmFields: string[];
  isOLM?: boolean;
  children?: any;
}

const OLMFilterItem: React.FC<IOLMFilterItem> = ({
  fieldKey,
  olmFields,
  isOLM,
  children,
}) => {
  return (
    <>
      {isOLM ? (
        olmFields?.includes(fieldKey) ? (
          <>{children}</>
        ) : (
          <></>
        )
      ) : (
        <>{children}</>
      )}
    </>
  );
};

export default OLMFilterItem;
