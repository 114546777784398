import { useEffect, useState, useMemo } from "react";
import type { FC } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FiRotateCcw, FiSearch } from "react-icons/fi";
import { EmptyResult, Loading, Button, Input } from "ui-atoms";
import { Table, ShowMoreItems, StyledCommentValue, Facets } from "ui-molecules";
import queryString from "query-string";
import { LayoutListing } from "ui-organisms";
import { SEARCH_RESULT_LIMIT, COMMENT_TABLE_COLUMNS } from "./constants";
import { CommentProps, DirectionEnum, FacetsProps } from "types";
import { getCommentsAPI } from "services";
import { useApiCall } from "hooks";
import debounce from "lodash.debounce";
import CommentDetailModal from "./CommentDetailModal";
import { Helmet } from "react-helmet-async";
import { META_TAGS } from "constant";
import React from "react";

const Comments: FC = () => {
  const [getComments, loading] = useApiCall(getCommentsAPI);
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [comments, setComments] = useState<CommentProps[]>([]);
  const [facets, setFacets] = useState<FacetsProps>();
  const [paramFilters, setParamFilters] = useState<any>(null);
  const [keyword, setKeyword] = useState<string>();
  const [sortFields, setSortFields] = useState<string[]>([]);
  const [selectedBuildingId, setSelectedBuildingId] = useState<any>(null);

  useEffect(() => {
    let filters = queryString.parse(location?.search, {
      arrayFormat: "bracket",
    });
    setParamFilters(filters);
  }, [location]);

  useEffect(() => {
    if (paramFilters === null) return;
    setKeyword(paramFilters?.keyword || "");
    setPage(1);
    setComments([]);
    getComments({
      page: 1,
      limit: SEARCH_RESULT_LIMIT,
      ...paramFilters,
    }).then((data: any) => {
      setComments([...data?.docs]);
      setPage(data?.page);
      setTotal(data?.total);
      setFacets(data?.facets);
      setSortFields(data?.sort_fields);
    });
  }, [paramFilters]);

  const onClickSorting = (sort: string) => {
    const updatedFilters = {
      ...paramFilters,
      sort,
      direction:
        paramFilters?.direction === DirectionEnum.asc
          ? DirectionEnum?.desc
          : DirectionEnum.asc,
    };
    navigate(
      `${location.pathname}?${queryString.stringify(updatedFilters, {
        arrayFormat: "bracket",
      })}`
    );
  };

  const onClickDetail = (buildingId: number) => () => {
    setSelectedBuildingId(buildingId);
    setIsOpen(true);
  };

  const onClickShowMore = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    getComments({
      page: nextPage,
      limit: SEARCH_RESULT_LIMIT,
      ...paramFilters,
    }).then((data: any) => {
      setComments((prevData: any) => [...prevData, ...data?.docs]);
      setPage(data?.page);
      setTotal(data?.total);
      setFacets(data?.facets);
      setSortFields(data?.sort_fields);
    });
  };

  const updateKeywordFilter = (e: any) => {
    const updatedFilters = { ...paramFilters };
    if (e.target.value) {
      updatedFilters["keyword"] = e.target.value;
    } else if (updatedFilters.hasOwnProperty("keyword")) {
      delete updatedFilters.keyword;
    }
    navigate(
      `${location.pathname}?${queryString.stringify(updatedFilters, {
        arrayFormat: "bracket",
      })}`
    );
  };

  const debounceUpdateKeyword = useMemo(
    () => debounce(updateKeywordFilter, 300),
    [paramFilters]
  );

  const onChangeInput = (e: any) => {
    setKeyword(e.target.value);
    debounceUpdateKeyword(e);
  };

  const onClickReset = () => {
    navigate(location.pathname);
  };

  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>{META_TAGS?.comments?.title}</title>
        <meta property="og:title" content={META_TAGS?.comments?.title} />
      </Helmet>
      <LayoutListing>
        <LayoutListing.Header>
          <h1 className="text-heading1">Comments</h1>
          <div className="flex space-x-4 items-center">
            <Input
              className="w-[400px] !mb-0"
              name="search"
              leadingIcon={FiSearch}
              onChange={onChangeInput}
              value={keyword}
              isClearable
              placeholder="Search comments by user name, property name or date"
            />
          </div>
        </LayoutListing.Header>
        <LayoutListing.Container>
          <Facets facets={facets} />
          <LayoutListing.Content>
            {loading && !comments?.length && <Loading />}
            {!loading && !comments?.length && (
              <EmptyResult>
                <h3 className="text-heading3 text-jll-color-coldGray-5">
                  No property comments found.
                </h3>
              </EmptyResult>
            )}

            {!!comments?.length && (
              <div className="p-6">
                <div className="flex space-x-4 pb-6 justify-between">
                  <div className="font-semibold text-jll-color-coldGray-7 text-body1">
                    {total} result{total > 1 ? "s" : ""}
                  </div>
                  {paramFilters && !!Object.keys(paramFilters)?.length && (
                    <Button
                      variant="ghost"
                      size="small"
                      leadingIcon={FiRotateCcw}
                      onClick={onClickReset}
                    >
                      Reset
                    </Button>
                  )}
                </div>
                <Table>
                  <Table.Thead>
                    <Table.Tr>
                      {COMMENT_TABLE_COLUMNS.map((column, index) => {
                        return (
                          <Table.Th
                            key={index}
                            filterId={column.id}
                            sortFields={sortFields}
                            filters={paramFilters}
                            onClick={onClickSorting}
                          >
                            {column.label}
                          </Table.Th>
                        );
                      })}
                    </Table.Tr>
                  </Table.Thead>
                  <Table.Tbody>
                    {comments?.map((comment: CommentProps, index: number) => {
                      return (
                        <Table.Tr
                          key={index}
                          onClick={onClickDetail(comment?.building_id)}
                        >
                          {COMMENT_TABLE_COLUMNS.map((column, index) => {
                            return (
                              <Table.Td
                                key={index}
                                className={column?.td?.className}
                              >
                                <StyledCommentValue
                                  comment={comment}
                                  valueKey={column.id}
                                />
                              </Table.Td>
                            );
                          })}
                        </Table.Tr>
                      );
                    })}
                  </Table.Tbody>
                </Table>
                <ShowMoreItems
                  onClick={onClickShowMore}
                  total={total}
                  isLoading={loading}
                  itemsCount={comments.length}
                />
              </div>
            )}
          </LayoutListing.Content>
        </LayoutListing.Container>
      </LayoutListing>
      <CommentDetailModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        buildingId={selectedBuildingId}
      />
    </>
  );
};

export default React.memo(Comments);
