import { SEARCH_RESULT_LIMIT, SET_LOADING_MODAL } from "constant";
import { useApiCall } from "hooks";
import debounce from "lodash.debounce";
import { useContext, useEffect, useMemo, useState } from "react";
import {
  FiMinusCircle,
  FiPlusCircle,
  FiRotateCcw,
  FiSearch,
  FiTrash,
  FiX,
} from "react-icons/fi";
import {
  deleteTeamListingBuildingAPI,
  getBrokersAPI,
  getMarketsAPI,
  getOwnersAPI,
  getPropertiesAPI,
  getTeamListingAPI,
  getUsersAPI,
  postTeamListingBuildingAPI,
  postTeamListingBuildingBulkAPI,
} from "services";
import {
  DirectionEnum,
  FacetsProps,
  FilteredFacetProps,
  PropertyProps,
} from "types";
import {
  Button,
  Input,
  MultipleSelect,
  SearchableMultipleSelect,
  Toast,
} from "ui-atoms";
import { ShowMoreItems, StyledPropertyValue, Table } from "ui-molecules";
import { PROPERTY_TABLE_PANE_COLUMNS } from "./constants";
import { capitalizeWords, getFilteredFacets } from "utils";
import { GlobalContext } from "context";
import { useParams } from "react-router-dom";
import cn from "classnames";

interface ITeamListingPropertyPanel {
  setIsSlidePane?: any;
  isAdd?: boolean;
  team_id?: number | string;
}

const TAB_LIST = [
  { label: "Selected Properties", key: "selected" },
  { label: "All Properties", key: "all" },
];

const TeamListingPropertyPanel: React.FC<ITeamListingPropertyPanel> = ({
  setIsSlidePane,
  isAdd,
  team_id,
}) => {
  const { state, dispatch } = useContext(GlobalContext);
  const { teamListing } = state;
  const param = useParams();
  let teamId: string | number | undefined = undefined;
  if (isAdd) {
    teamId = team_id;
  } else {
    teamId = param?.teamId;
  }
  const [getProperties, isLoadingProperties] = useApiCall(getPropertiesAPI);
  const [getUsers] = useApiCall(getUsersAPI);
  const [getBrokers] = useApiCall(getBrokersAPI);
  const [getMarkets] = useApiCall(getMarketsAPI);
  const [getOwners] = useApiCall(getOwnersAPI);
  const [postTeamListingBuilding] = useApiCall(postTeamListingBuildingAPI);
  const [postTeamListingBuildingBulk] = useApiCall(
    postTeamListingBuildingBulkAPI
  );
  const [deleteTeamListingBuilding] = useApiCall(deleteTeamListingBuildingAPI);
  const [getTeamListing] = useApiCall(getTeamListingAPI);
  const [activeTab, setActiveTab] = useState(
    !isAdd && !!teamListing?.listings?.length ? "selected" : "all"
  );
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState<PropertyProps[]>();
  const [listingData, setListingData] = useState<PropertyProps[]>();
  const [facets, setFacets] = useState<FilteredFacetProps[]>();
  const [paramFilters, setParamFilters] = useState<any>([]);
  const [sortFields, setSortFields] = useState<string[]>([]);
  const [keyword, setKeyword] = useState<string>();

  useEffect(() => {
    setPage(1);
    setData([]);
    if (activeTab === "selected")
      getTeamListing({ pk: teamId }).then((data: any) => {
        setListingData([...data?.listings]);
        setPage(1);
        setTotal(data?.listings?.length);
        setFacets([]);
        setSortFields([]);
      });
    else
      getProperties({
        page: 1,
        limit: SEARCH_RESULT_LIMIT,
        keyword: keyword,
        ...paramFilters,
      }).then((data: any) => {
        setData([...data?.docs]);
        setPage(data?.page);
        setTotal(data?.total);
        setFacets(getFilteredFacets(data?.facets as FacetsProps));
        setSortFields(data?.sort_fields);
      });
  }, [keyword, paramFilters, activeTab]);

  const handleFilterChange = (selectedOptions: any, name: string) => {
    let additional: any = {};
    const values =
      selectedOptions?.map(({ value }: any) => {
        if (value?.toString()?.includes("_missing")) {
          additional[value] = true;
          return;
        }
        return value;
      }) || [];
    let updatedFilters = {
      ...paramFilters,
      [name]: values,
    };
    if (name === "coordinator" || name === "broker") {
      delete updatedFilters[`${name}_missing`];
    }
    updatedFilters = {
      ...updatedFilters,
      ...additional,
    };
    setParamFilters(updatedFilters);
  };

  const updateKeywordFilter = (e: any) => {
    setKeyword(e.target.value);
  };

  const debounceUpdateKeyword = useMemo(
    () => debounce(updateKeywordFilter, 300),
    [paramFilters]
  );

  const onChangeInput = (e: any) => {
    setKeyword(e.target.value);
    debounceUpdateKeyword(e);
  };

  const onClickSorting = (sort: string) => {
    const updatedFilters = {
      ...paramFilters,
      sort,
      direction:
        paramFilters?.direction === DirectionEnum.asc
          ? DirectionEnum?.desc
          : DirectionEnum.asc,
    };
    setParamFilters(updatedFilters);
  };

  const onClickShowMore = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    getProperties({
      page: nextPage,
      limit: SEARCH_RESULT_LIMIT,
      ...paramFilters,
    }).then((data: any) => {
      setData((prevData: any) => [...prevData, ...data?.docs]);
      setPage(data?.page);
      setTotal(data?.total);
      setFacets(getFilteredFacets(data?.facets as FacetsProps));
      setSortFields(data?.sort_fields);
    });
  };

  const addGroup = async (pk: string | number) => {
    try {
      dispatch({
        type: SET_LOADING_MODAL,
        payload: {
          open: true,
          label: `Adding this buiding to Set`,
        },
      });
      const res = await postTeamListingBuilding({
        team_listing: teamId,
        building: pk,
      });
      setTimeout(() => {
        dispatch({
          type: SET_LOADING_MODAL,
          payload: {
            open: false,
            label: null,
          },
        });
      }, 100);
      if (res) {
        const new_data = [...(listingData || [])];
        new_data.push(res);
        setListingData(new_data);
        Toast.success("Added this building successfully");
      }
    } catch (err: any) {
      if (err?.message) Toast.error(err?.message);
      setTimeout(() => {
        dispatch({
          type: SET_LOADING_MODAL,
          payload: {
            open: false,
            label: null,
          },
        });
      }, 100);
    }
  };

  const removeGroup = async (pk: string | number) => {
    try {
      dispatch({
        type: SET_LOADING_MODAL,
        payload: {
          open: true,
          label: `Removing this buiding from Set`,
        },
      });
      deleteTeamListingBuilding(pk)
        .then((res: any) => {
          const index: number | undefined = listingData?.findIndex(
            (item: any) =>
              item?.team_listing_building?.id === pk || item?.id === pk
          );
          if (index !== undefined && index > -1) {
            const new_data = [...(listingData || [])];
            new_data?.splice(index, 1);
            setListingData(new_data);
            Toast.success("Removed this building successfully");
          }
        })
        .finally(() => {
          setTimeout(() => {
            dispatch({
              type: SET_LOADING_MODAL,
              payload: {
                open: false,
                label: null,
              },
            });
          }, 100);
        });
    } catch (err: any) {
      if (err?.message) Toast.error(err?.message);
      setTimeout(() => {
        dispatch({
          type: SET_LOADING_MODAL,
          payload: {
            open: false,
            label: null,
          },
        });
      }, 100);
    }
  };

  const handleTab = (tab: string) => {
    setActiveTab(tab);
  };

  const handleGroup = async (isAdd: boolean) => {
    try {
      dispatch({
        type: SET_LOADING_MODAL,
        payload: {
          open: true,
          label: `${isAdd ? "Adding" : "Removing"} the buidings to Set`,
        },
      });

      let building_ids: number[] = [];
      let buildings: any[] = [];
      if (isAdd)
        data?.forEach((property) => {
          if (
            !!listingData?.find(
              (item: any) =>
                item?.pk === property?.pk ||
                item?.building?.toString() === property?.pk?.toString()
            )
          )
            return;
          buildings.push(property);
          building_ids.push(property?.pk);
        });
      else
        listingData?.forEach((listing: any) => {
          building_ids.push(listing?.pk || listing?.building);
        });
      if (!building_ids?.length) {
        setTimeout(() => {
          dispatch({
            type: SET_LOADING_MODAL,
            payload: {
              open: false,
              label: null,
            },
          });
        }, 100);
        return;
      }
      let payload: any = {
        team_listing: teamId,
        buildings: building_ids,
      };
      if (!isAdd)
        payload = {
          ...payload,
          delete: true,
        };
      const res = await postTeamListingBuildingBulk(payload);
      setTimeout(() => {
        dispatch({
          type: SET_LOADING_MODAL,
          payload: {
            open: false,
            label: null,
          },
        });
      }, 100);

      if (res) {
        if (isAdd) {
          const new_data = [...(listingData || []), ...buildings];
          setListingData(new_data);
        } else {
          setListingData([]);
        }
        Toast.success(
          `${isAdd ? "Added" : "Removed"} this building successfully`
        );
      }
    } catch (err: any) {
      if (err?.message) Toast.error(err?.message);
      setTimeout(() => {
        dispatch({
          type: SET_LOADING_MODAL,
          payload: {
            open: false,
            label: null,
          },
        });
      }, 100);
    }
  };

  return (
    <div className="h-full flex flex-col">
      <div className="pr-8">
        <div className="h-[70px] border-b border-jll-color-coldGray-3 flex flex-row justify-between mt-[-23px]">
          <div className="flex flex-row space-x-8">
            {TAB_LIST.map((tab, idx) => (
              <div
                key={idx}
                className={cn(
                  "h-full flex items-center text-sm font-semibold cursor-pointer",
                  {
                    "text-jll-color-text-base-default border-b border-b-jll-color-text-base-subdued":
                      activeTab === tab.key,
                    "text-jll-color-coldGray-5": activeTab !== tab.key,
                  }
                )}
                onClick={() => handleTab(tab.key)}
              >
                {tab.label}
              </div>
            ))}
          </div>
          {!isAdd && (
            <div className="flex items-center">
              <Button
                variant="primary"
                leadingIcon={FiX}
                onClick={() => setIsSlidePane(false)}
              >
                Close
              </Button>
            </div>
          )}
        </div>

        <div className="flex flex-row items-center w-full justify-between py-4">
          <span className="font-semibold text-jll-color-text-base-default">
            Find a property to add in this set
          </span>
          <div className="flex flex-row space-x-4 items-center">
            <Input
              className="w-[400px] !mb-0"
              name="search"
              leadingIcon={FiSearch}
              onChange={onChangeInput}
              value={keyword}
              isClearable
              placeholder="Search by building name, listing ID, address, brokers name"
            />
          </div>
        </div>

        <div className="flex flex-row w-full items-center justify-between py-4 border-y border-y-jll-color-coldGray-3 space-x-4">
          <div className="flex flex-row items-center space-x-3">
            <span className="font-semibold text-jll-color-coldGray-7 text-sm whitespace-nowrap">
              {total} result{total > 1 ? "s" : ""}
            </span>
          </div>
          <div className="w-full">
            <div className="flex flex-row flex-wrap">
              {facets?.map((facetCategory, facet_index) => {
                if (
                  !facetCategory.facets?.length ||
                  facetCategory?.id === "corporate_accounts" ||
                  facetCategory?.id === "state"
                ) {
                  return null;
                }

                if (
                  facetCategory?.id === "market" &&
                  !!facetCategory?.facets?.length
                ) {
                  return (
                    <SearchableMultipleSelect
                      key={facet_index}
                      name={facetCategory?.id}
                      title={facetCategory?.label}
                      serviceAPI={getMarkets}
                      isMissing={false}
                      isLabelValue
                      className="!mr-2 mb-2"
                      onChange={handleFilterChange}
                      zIdx={`${40 - Number(facet_index)}`}
                      divClassName="mr-2"
                      isSearch
                      filters={paramFilters}
                      setFilters={setParamFilters}
                    />
                  );
                }

                if (facetCategory?.id === "coordinator") {
                  return (
                    <SearchableMultipleSelect
                      key={facet_index}
                      name={facetCategory?.id}
                      title={facetCategory?.label}
                      serviceAPI={getUsers}
                      className="!mr-2 mb-2"
                      onChange={handleFilterChange}
                      zIdx={`${40 - Number(facet_index)}`}
                      divClassName="mr-2"
                      filters={paramFilters}
                      setFilters={setParamFilters}
                    />
                  );
                }

                if (facetCategory?.id === "broker") {
                  return (
                    <SearchableMultipleSelect
                      key={facet_index}
                      name={facetCategory?.id}
                      title={facetCategory?.label}
                      serviceAPI={getBrokers}
                      className="!mr-2 mb-2"
                      onChange={handleFilterChange}
                      zIdx={`${40 - Number(facet_index)}`}
                      divClassName="mr-2"
                      filters={paramFilters}
                      setFilters={setParamFilters}
                    />
                  );
                }

                if (facetCategory?.id === "owner") {
                  return (
                    <SearchableMultipleSelect
                      key={facet_index}
                      name={facetCategory?.id}
                      title={facetCategory?.label}
                      serviceAPI={getOwners}
                      className="!mr-2 mb-2"
                      onChange={handleFilterChange}
                      zIdx={`${40 - Number(facet_index)}`}
                      divClassName="mr-2"
                      filters={paramFilters}
                      setFilters={setParamFilters}
                    />
                  );
                }

                return (
                  <MultipleSelect
                    key={facet_index}
                    options={facetCategory?.facets}
                    selectedOptions={facetCategory?.facets?.filter((facet) => {
                      if (facet?.value?.toString()?.includes("_missing")) {
                        return !!paramFilters[facet?.value];
                      }
                      return (
                        paramFilters?.[facetCategory?.id]?.includes(
                          facet?.value?.toString()
                        ) ||
                        paramFilters?.[facetCategory?.id]?.includes(
                          facet?.value
                        )
                      );
                    })}
                    name={facetCategory?.id}
                    title={capitalizeWords(facetCategory?.label)}
                    className="!mr-2 mb-2"
                    onChange={handleFilterChange}
                    zIdx={`${40 - Number(facet_index)}`}
                    divClassName="mr-2"
                    filters={paramFilters}
                    setFilters={setParamFilters}
                  />
                );
              })}

              {paramFilters && !!Object.keys(paramFilters)?.length && (
                <Button
                  variant="ghost"
                  size="small"
                  leadingIcon={FiRotateCcw}
                  onClick={() => {
                    setParamFilters({});
                    setKeyword("");
                  }}
                  className="mr-2 mb-2"
                >
                  Reset
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="pr-8 h-full overflow-y-auto">
        {((activeTab === "all" && !!data?.length) ||
          (activeTab === "selected" && !!listingData?.length)) && (
          <Table>
            <Table.Thead>
              <Table.Tr>
                {PROPERTY_TABLE_PANE_COLUMNS.map(
                  (column: any, index: number) => (
                    <Table.Th
                      key={index}
                      filterId={column.id}
                      sortFields={sortFields}
                      filters={paramFilters}
                      onClick={onClickSorting}
                    >
                      {column.label}
                    </Table.Th>
                  )
                )}
                <Table.Th>
                  {activeTab === "all" ? (
                    <Button
                      variant="ghost"
                      leadingIcon={FiPlusCircle}
                      onClick={() => handleGroup(true)}
                    >
                      Add All
                    </Button>
                  ) : (
                    <Button
                      variant="ghost"
                      leadingIcon={FiMinusCircle}
                      onClick={() => handleGroup(false)}
                    >
                      Remove All
                    </Button>
                  )}
                </Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {(activeTab === "all" ? data : listingData)?.map(
                (property: PropertyProps, index: number) => {
                  return (
                    <Table.Tr key={index}>
                      {PROPERTY_TABLE_PANE_COLUMNS.map(
                        (column: any, index: number) => {
                          return (
                            <Table.Td
                              key={index}
                              className={column?.td?.className}
                            >
                              <StyledPropertyValue
                                property={property}
                                valueKey={column.id}
                              />
                            </Table.Td>
                          );
                        }
                      )}
                      <Table.Td>
                        {activeTab === "all" && (
                          <>
                            {!!listingData?.length &&
                            listingData?.find(
                              (item: any) =>
                                item?.pk === property?.pk ||
                                item?.building?.toString() ===
                                  property?.pk?.toString()
                            ) ? (
                              <Button
                                variant="neutral"
                                className="text-jll-color-text-danger hover:text-jll-color-text-danger"
                                leadingIcon={FiTrash}
                                leadingIconClass="text-jll-color-text-danger"
                                onClick={() =>
                                  removeGroup(
                                    (
                                      listingData?.find(
                                        (item: any) =>
                                          item?.pk === property?.pk ||
                                          item?.building?.toString() ===
                                            property?.pk?.toString()
                                      ) as any
                                    )?.team_listing_building?.id ||
                                      (
                                        listingData?.find(
                                          (item: any) =>
                                            item?.pk === property?.pk ||
                                            item?.building?.toString() ===
                                              property?.pk?.toString()
                                        ) as any
                                      )?.id
                                  )
                                }
                              >
                                Remove from Group
                              </Button>
                            ) : (
                              <Button
                                variant="neutral"
                                className="text-jll-color-icon-info hover:text-jll-color-icon-info"
                                leadingIcon={FiPlusCircle}
                                leadingIconClass="text-jll-color-icon-info"
                                onClick={() =>
                                  addGroup(property?.pk || property?.id)
                                }
                              >
                                Add to group
                              </Button>
                            )}
                          </>
                        )}
                        {activeTab === "selected" && (
                          <Button
                            variant="neutral"
                            className="text-jll-color-text-danger hover:text-jll-color-text-danger"
                            leadingIcon={FiTrash}
                            leadingIconClass="text-jll-color-text-danger"
                            onClick={() =>
                              removeGroup(
                                (property as any).team_listing_building?.id
                              )
                            }
                          >
                            Remove from Group
                          </Button>
                        )}
                      </Table.Td>
                    </Table.Tr>
                  );
                }
              )}
            </Table.Tbody>
          </Table>
        )}
        {activeTab === "all" && (
          <ShowMoreItems
            onClick={onClickShowMore}
            total={total}
            isLoading={isLoadingProperties}
            itemsCount={data?.length || 0}
          />
        )}
      </div>
    </div>
  );
};

export default TeamListingPropertyPanel;
