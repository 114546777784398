import { SET_LOADING_MODAL } from "constant";
import { GlobalContext } from "context";
import { useApiCall } from "hooks";
import { useContext, useEffect, useState } from "react";
import { FiX } from "react-icons/fi";
import { getPropertyAIAPI, postPropertyAIAPI } from "services";
import { Button, Textarea } from "ui-atoms";
import { Modal } from "ui-molecules";

interface IDescriptionAIModal {
  isOpen: boolean;
  setIsOpen: any;
  handleGPT?: any;
}

const DescriptionAIModal: React.FC<IDescriptionAIModal> = ({
  isOpen,
  setIsOpen,
  handleGPT,
}) => {
  const { dispatch, state } = useContext(GlobalContext);
  const { property } = state;
  const session = JSON.parse(sessionStorage.getItem("session") || "null");
  const [getPropertyAI] = useApiCall(getPropertyAIAPI);
  const [postPropertyAI] = useApiCall(postPropertyAIAPI);
  const [gptDescription, setGptDescription] = useState("");
  const [buildingDescription, setBuildingDescription] = useState("");

  useEffect(() => {
    if (!isOpen || !property?.id) return;
    setBuildingDescription("");
    loadData();
  }, [isOpen, property]);

  const loadData = async () => {
    try {
      dispatch({
        type: SET_LOADING_MODAL,
        payload: {
          open: true,
          label: `Creating Description`,
        },
      });
      const result = await getPropertyAI(property.id);
      if (!result?.prompt) {
        setGptDescription("");
        return;
      }
      setGptDescription(result?.prompt);
      let payload = {
        building: property?.id,
        prompt: result?.prompt,
        updated_user: session?.id,
      };
      const buildingRes = await postPropertyAI(payload);
      if (!buildingRes?.response) {
        setBuildingDescription("");
        return;
      }
      setBuildingDescription(buildingRes?.response);
      dispatch({
        type: SET_LOADING_MODAL,
        payload: {
          open: false,
          label: `Creating Description`,
        },
      });
    } catch (err) {
      dispatch({
        type: SET_LOADING_MODAL,
        payload: {
          open: false,
          label: `Creating Description`,
        },
      });
    }
  }

  const handleJLLGPT = () => {
    try {
      dispatch({
        type: SET_LOADING_MODAL,
        payload: {
          open: true,
          label: `Creating Description`,
        },
      });
      let payload = {
        building: property?.id,
        prompt: gptDescription,
        updated_user: session?.id,
      };
      setBuildingDescription("");
      postPropertyAI(payload)
        .then((res: any) => {
          if (!res?.response) {
            setBuildingDescription("");
            return;
          }
          setBuildingDescription(res?.response);
        })
        .finally(() => {
          dispatch({
            type: SET_LOADING_MODAL,
            payload: {
              open: false,
              label: `Creating Description`,
            },
          });
        });
    } catch (err) {
      dispatch({
        type: SET_LOADING_MODAL,
        payload: {
          open: false,
          label: `Creating Description`,
        },
      });
    }
  };

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen} size="medium">
      <Modal.Header className="relative">
        <Button
          variant="neutral"
          leadingIcon={FiX}
          className="absolute top-0 right-0 w-5 !h-5"
          leadingIconClass="w-5 h-5 text-jll-color-text-base-subdued"
          onClick={() => setIsOpen(false)}
        />
        <h5 className="text-xl font-semibold text-jll-color-text-base-default">
          Generate GPT Description
        </h5>
      </Modal.Header>
      <Modal.Body>
        <div className="mt-8">
          <Textarea
            name="gptDescription"
            onChange={(e) => setGptDescription(e?.target?.value)}
            value={gptDescription}
            rows={8}
          />
          <div className="w-full flex justify-end">
            <Button
              variant="primary"
              onClick={handleJLLGPT}
              disabled={!gptDescription?.length}
            >
              Call JLL GPT
            </Button>
          </div>
        </div>
        {!!buildingDescription?.length && (
          <>
            <hr className="my-6" />
            <div>
              <Textarea
                name="buildingDescription"
                onChange={(e) => setBuildingDescription(e?.target?.value)}
                value={buildingDescription}
                rows={8}
              />
              <div className="w-full flex justify-end">
                <Button
                  variant="primary"
                  onClick={() => {
                    handleGPT(buildingDescription);
                    setIsOpen(false);
                  }}
                >
                  Save
                </Button>
              </div>
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default DescriptionAIModal;
