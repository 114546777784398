const styles: any = {
  page: {
    fontFamily: 'Source Sans Pro',
    display: 'block',
    fontSize: '12px',
    position: 'relative',
  },
  contactBack: {
    width: '100%',
    height: '100%',
  },
  contactBackImg: {
    width: '100%',
    height: '100%',
  },
  contactInfoView: {
    position: 'absolute',
    paddingLeft: 77,
    paddingRight: 77,
    top: 160,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%'
  },
  contactDetailView: {
    display: 'flex',
    flexDirection: 'column',
    width: '33.33%',
    paddingRight: 20,
    marginBottom: 20
  },
  brokerImg: {
    width: 40,
    height: 40,
    borderRadius: 48,
    backgroundColor: 'white',
    objectFit: 'contain',
    marginBottom: 4
  },
  brokerView: {
    display: 'flex',
    flexDirection: 'column'
  },
  brokerName: {
    fontSize: 14,
    fontWeight: 600,
    color: 'white',
  },
  brokerTxt: {
    color: 'white',
    fontSize: 10
  },
  brokerLink: {
    display: 'flex',
    flexDirection: 'row',
  },
  contactTxtView: {
    position: 'absolute',
    paddingLeft: 77,
    paddingRight: 77,
    top: 430,
    width: '100%'
  },
  contactTxt: {
    fontSize: 10,
    fontWeight: 400,
    color: 'white',
  }
};

export default styles;
