import React, { useContext, useEffect, useState } from "react";
import cn from "classnames";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Carousel, { Dots, arrowsPlugin } from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import NoImage from "assets/noImage.png";
import { IGalleryImageProps } from "types";
import { GlobalContext } from "context";
import { ImageHoverEffect } from "ui-molecules";
import { UPDATE_LIGHTGALLERY_DATA } from "constant";

export interface IReactCarouselProps {
  items: IGalleryImageProps[];
  height?: string;
  width?: string;
  mobile?: boolean;
  isZoomButton?: boolean;
}

interface IProperty {
  value: number;
  slides: JSX.Element[];
  thumbnails: JSX.Element[];
}

const ReactCarousel: React.FC<IReactCarouselProps> = ({
  items,
  height = "220",
  width = "w-11/12",
  isZoomButton = false,
  mobile = false,
}) => {
  const { state, dispatch } = useContext(GlobalContext);
  const { lightGalleryRef } = state;
  const isMobile = mobile ? true : false;

  const [property, setProperty] = useState<IProperty>({
    value: 0,
    slides: [],
    thumbnails: [],
  });

  const plugins = [
    "infinite",
    {
      resolve: arrowsPlugin,
      options: {
        offset: 50,
        arrowLeft:
          property?.thumbnails?.length > 1 ? (
            <button>
              <FiChevronLeft />
            </button>
          ) : (
            <></>
          ),
        arrowLeftDisabled:
          property?.thumbnails?.length > 1 ? (
            <button>
              <FiChevronLeft />
            </button>
          ) : (
            <></>
          ),
        arrowRight:
          property?.thumbnails?.length > 1 ? (
            <button>
              <FiChevronRight />
            </button>
          ) : (
            <></>
          ),
        arrowRightDisabled:
          property?.thumbnails?.length > 1 ? (
            <button>
              <FiChevronRight />
            </button>
          ) : (
            <></>
          ),
        addArrowClickHandler: true,
      },
    },
  ];

  useEffect(() => {
    if (items.length > 0) {
      let imgs = items.map((item, idx) => (
        <ImageHoverEffect
          className={cn({
            [`${width}`]: true,
            "h-[160px]": items?.length > 1,
            [`md:h-[220px]`]: items?.length > 1,
            "h-[280px] md:h-[320px]": items?.length <= 1,
          })}
          onClick={() => handleOpenGallery(idx)}
        >
          <LazyLoadImage
            className={`object-contain cursor-pointer w-full h-full`}
            src={item.src}
            onClick={() => handleOpenGallery(idx)}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = NoImage;
            }}
          />
        </ImageHoverEffect>
      ));
      let thumbs = items.map((item, idx) => (
        <LazyLoadImage
          key={idx}
          className="object-contain w-[44px] h-[44px] rounded-md bg-white"
          src={item.thumb}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = NoImage;
          }}
        />
      ));
      setProperty({ ...property, slides: imgs, thumbnails: thumbs });
    }
  }, [items]);

  const onchange = (value: any) => {
    setProperty({ ...property, value });
  };

  const handleOpenGallery = async (currentIdx: number) => {
    await dispatch({
      type: UPDATE_LIGHTGALLERY_DATA,
      payload: items,
    });
    lightGalleryRef.current.openGallery(currentIdx);
  };

  return (
    <div className="bg-white relative w-full h-full md:pb-[20px] pt-[20px] md:px-[40px] px-[15px] shadow-var-4 rounded flex items-center">
      <div className="h-full w-full flex flex-col justify-center">
        <Carousel
          plugins={plugins}
          value={property.value}
          slides={property.slides}
          onChange={onchange}
          className="mb-[15px]"
        />
        {property?.thumbnails?.length > 1 && (
          <Dots
            number={property.thumbnails.length}
            thumbnails={!isMobile ? property.thumbnails : undefined}
            value={property.value}
            onChange={onchange}
            className={cn({ "py-0": !isMobile })}
          />
        )}
      </div>
    </div>
  );
};

export default ReactCarousel;
