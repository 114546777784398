import { type FC, type ReactElement, useContext } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import cn from "classnames";
import { lazyLoadImageOnError } from "utils";
import { convertImageUrl } from "services";
import { FiClipboard, FiLink, FiLink2 } from "react-icons/fi";
import { GlobalContext } from "context";

interface ICardProps {
  className?: string;
  maxItems?: number;
  src?: string;
  chip?: ReactElement;
  actions?: ReactElement[];
  isInteractive?: boolean;
}

/**
 * Card
 */
const Card: FC<ICardProps> = ({
  className,
  maxItems = 0,
  src = "",
  chip,
  actions = [],
  isInteractive,
  ...props
}) => {
  const { state } = useContext(GlobalContext);
  const { showTooltip, hideTooltip } = state;
  const isCloudaryFile = src?.includes("cloudinary") ? true : false;

  return (
    <div
      className={cn(
        "border border-jll-color-coldGray-3 rounded relative max-w-xs",
        className
      )}
    >
      {(!isInteractive || (isInteractive && isCloudaryFile)) && (
        <div className="h-full group w-full">
          <LazyLoadImage
            className="w-full h-full object-cover rounded"
            src={convertImageUrl(src)}
            onError={lazyLoadImageOnError}
            {...props}
          />
          {chip}
          {actions && (
            <div className="w-full h-full absolute top-0 left-0 rounded hidden bg-black/50 group-hover:flex cursor-pointer items-center justify-center z-20 space-x-2">
              {actions.map((action) => action)}
            </div>
          )}
        </div>
      )}
      {isInteractive && !isCloudaryFile && (
        <div className="h-full group w-full flex justify-center items-center cursor-pointer relative">
          <FiLink className="w-10 h-10 text-jll-color-text-base-default" />
          {chip}
          {actions && (
            <div className="w-full h-full absolute top-0 left-0 rounded hidden bg-black/50 group-hover:flex cursor-pointer items-center justify-center z-20 space-x-2">
              {actions.map((action) => action)}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Card;
