import React, { useState } from 'react';
import clsx from 'clsx';
import { FiSearch } from 'react-icons/fi';
import './styles.css';

interface IImageHoverEffectProps {
  children: React.ReactNode;
  width?: string;
  height?: string | number;
  className?: string;
  onClick?: () => {};
}

const ImageHoverEffect: React.FC<IImageHoverEffectProps> = ({
  children,
  className,
  width = null,
  height = null,
  onClick,
}) => {
  const [isHover, setIsHover] = useState(false);
  const classes = clsx(
    {
      'image-hover-wrapper relative overflow-hidden cursor-pointer': true,
      'w-full': !width,
      [`${width}`]: width,
      'h-full': !height,
      [`${height}`]: height,
    },
    className
  );
  return (
    <figure
      className={classes}
      onMouseLeave={() => setIsHover(false)}
      onMouseOver={() => setIsHover(true)}
      onClick={onClick}
    >
      {children}
      {isHover && (
        <div className="image-hover-icon w-[40px] h-[40px] flex items-center justify-center absolute top-1/2 left-1/2 rounded-full text-[24px] font-semibold text-[#2472a4] bg-white">
          <FiSearch className="w-5 h-5" />
        </div>
      )}
    </figure>
  );
};

export default ImageHoverEffect;
