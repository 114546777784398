import {
  META_TAGS,
  SEARCH_RESULT_LIMIT,
  SET_CONFIRM_MODAL,
  SET_LOADING_MODAL,
  STATUS_ACTIVE,
  STATUS_REMOVED,
  TASK_TABLE_COLUMNS,
} from "constant";
import { GlobalContext } from "context";
import { useApiCall } from "hooks";
import debounce from "lodash.debounce";
import queryString from "query-string";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { FiPlusCircle, FiRotateCcw, FiSearch } from "react-icons/fi";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { deleteTaskAPI, getTasksAPI, patchTaskAPI } from "services";
import {
  Button,
  EmptyResult,
  Input,
  Loading,
  SelectAutoComplete,
  Toast,
} from "ui-atoms";
import {
  RemoveDescriptionItem,
  StyledTaskValue,
  Table,
  TaskEditModal,
  TaskPreviewModal,
} from "ui-molecules";
import { LayoutEditProperty as Layout } from "ui-organisms";
import { getFilteredFacets } from "utils";
import cn from "classnames";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { DirectionEnum, TaskProps } from "types";
import { OptionProps } from "types";
import { renderToString } from "react-dom/server";
import React from "react";

const PropertyTask = () => {
  const { state, dispatch } = useContext(GlobalContext);
  const { property } = state;
  const { propertyId } = useParams();
  const [fetchTask, loading] = useApiCall(getTasksAPI);
  const [deleteTask] = useApiCall(deleteTaskAPI);
  const [patchTask] = useApiCall(patchTaskAPI);
  const location = useLocation();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState<any>([]);
  const [facets, setFacets] = useState<any>();
  const [sortFields, setSortFields] = useState<string[]>([]);
  const [keyword, setKeyword] = useState<string>();
  const [paramFilters, setParamFilters] = useState<any>(null);
  const [isFacet, setIsFacet] = useState(false);
  const headerRef = useRef<any>();
  const [height, setHeight] = useState(70);
  const [selectedTask, setSelectedTask] = useState<any>(null);

  useEffect(() => {
    const filters = queryString.parse(location?.search, {
      arrayFormat: "bracket",
    });
    setParamFilters(filters);
  }, [location]);

  useEffect(() => {
    if (paramFilters === null || !propertyId) return;
    setKeyword(paramFilters?.keyword || "");
    setPage(1);
    setData([]);
    fetchTask({
      page: 1,
      limit: SEARCH_RESULT_LIMIT,
      buildings: [propertyId],
      ...paramFilters,
    }).then((res: any) => {
      setData(res?.docs);
      setPage(res?.page);
      setTotal(res?.total);
      setFacets(getFilteredFacets(res?.facets || {}));
      setSortFields(res?.sort_fields);
    });
  }, [paramFilters, propertyId]);

  useEffect(() => {
    if (facets) {
      let flag = false;
      facets?.forEach((facet: any) => {
        if (facet?.facets && !!facet?.facets?.length) {
          flag = true;
        }
      });
      setIsFacet(flag);
    } else {
      setIsFacet(false);
    }
  }, [facets]);

  useEffect(() => {
    if (!headerRef?.current) return;
    if (headerRef?.current?.offsetHeight !== height) {
      setHeight(headerRef.current.offsetHeight);
    }
  }, [headerRef?.current, isFacet]);

  const loadMore = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    fetchTask({
      page: nextPage,
      limit: SEARCH_RESULT_LIMIT,
      buildings: [propertyId],
      ...paramFilters,
    }).then((res: any) => {
      setData((prevData: any) => [...prevData, ...res?.docs]);
      setPage(res?.page);
      setTotal(res?.total);
      setFacets(getFilteredFacets(res?.facets || {}));
      setSortFields(res?.sort_fields);
    });
  };

  const [sentryRef] = useInfiniteScroll({
    loading: false,
    hasNextPage: total > data?.length,
    onLoadMore: loadMore,
  });

  const updateKeywordFilter = (e: any) => {
    const updatedFilters = { ...paramFilters };
    if (e.target.value) {
      updatedFilters["keyword"] = e.target.value;
    } else if (updatedFilters.hasOwnProperty("keyword")) {
      delete updatedFilters.keyword;
    }
    navigate(
      `${location.pathname}?${queryString.stringify(updatedFilters, {
        arrayFormat: "bracket",
      })}`
    );
  };

  const debounceUpdateKeyword = useMemo(
    () => debounce(updateKeywordFilter, 300),
    [paramFilters]
  );

  const onChangeInput = (e: any) => {
    setKeyword(e.target.value);
    debounceUpdateKeyword(e);
  };

  const onClickReset = () => {
    navigate(location.pathname);
  };

  const onClickSorting = (sort: string) => {
    const updatedFilters = {
      ...paramFilters,
      sort,
      direction:
        paramFilters?.direction === DirectionEnum.asc
          ? DirectionEnum?.desc
          : DirectionEnum.asc,
    };
    navigate(
      `${location.pathname}?${queryString.stringify(updatedFilters, {
        arrayFormat: "bracket",
      })}`
    );
  };

  const handleFilter = (option: any, id: string) => {
    const values = option?.map((item: OptionProps) => item?.value);
    const updatedFilters = { ...paramFilters };
    if (!!values?.length) {
      updatedFilters[id] = values;
    } else if (updatedFilters.hasOwnProperty(id)) {
      delete updatedFilters?.[id];
    }
    navigate(
      `${location.pathname}?${queryString.stringify(updatedFilters, {
        arrayFormat: "bracket",
      })}`
    );
  };

  const onClickEdit = (e: any, task: TaskProps) => {
    e.stopPropagation();
    setSelectedTask(task);
    setIsOpen(true);
  };

  const onClickRemove = (e: any, task: TaskProps) => {
    e.stopPropagation();
    const action = () => {
      dispatch({
        type: SET_LOADING_MODAL,
        payload: {
          open: true,
          label: `Deleting the task`,
        },
      });
      deleteTask(task?.pk)
        .then((res: any) => {
          Toast.success("Task removed with success!");
          const index = data?.findIndex(
            (item: TaskProps) => item?.pk === task?.pk
          );
          if (index !== undefined && index > -1) {
            const newData = [...data];
            newData[index]["active_status"] = STATUS_REMOVED;
            setData(newData);
          }
        })
        .finally(() => {
          setTimeout(() => {
            dispatch({
              type: SET_LOADING_MODAL,
              payload: {
                open: false,
                label: null,
              },
            });
          }, 100);
        });
    };

    dispatch({
      type: SET_CONFIRM_MODAL,
      payload: {
        open: true,
        content: {
          description: renderToString(
            <RemoveDescriptionItem target={task?.title.toString()} />
          ),
          action,
        },
      },
    });
  };

  const onActiveUpdate = async (id: any, active_status: number) => {
    const response = await patchTask({
      id,
      payload: {
        active_status,
      },
    });
    if (!response) return;
    const index = data?.findIndex(
      (item: TaskProps) => item?.pk === response?.id
    );
    if (index !== undefined && index > -1) {
      const newData = [...data];
      newData[index]["active_status"] = response?.active_status;
      setData(newData);
    }
    Toast.success("Updated successfully");
  };

  const onClickReinstate = async (e: any, task: TaskProps) => {
    e.stopPropagation();
    try {
      dispatch({
        type: SET_LOADING_MODAL,
        payload: {
          open: true,
          label: `Reinstating the task`,
        },
      });
      await onActiveUpdate(task?.pk, STATUS_ACTIVE);
      setTimeout(() => {
        dispatch({
          type: SET_LOADING_MODAL,
          payload: {
            open: false,
            label: null,
          },
        });
      }, 100);
    } catch (err) {
      setTimeout(() => {
        dispatch({
          type: SET_LOADING_MODAL,
          payload: {
            open: false,
            label: null,
          },
        });
      }, 100);
    }
  };

  const onClickPreview = (task: TaskProps) => {
    setSelectedTask(task);
    setIsPreviewOpen(true);
  };

  const openEditModal = () => {
    setIsPreviewOpen(false);
    setIsOpen(true);
  };

  const updateData = (res: TaskProps) => {
    const index = data?.findIndex(
      (item: TaskProps) => item?.pk === res?.pk || item?.pk === res?.id
    );
    if (index !== undefined && index > -1) {
      // Edit
      const newData = [...data];
      newData[index] = {
        ...newData[index],
        ...res,
      };
      setData(newData);
    } else {
      // Add
      let newData = [...data];
      newData.push({
        ...res,
        pk: res?.id,
      });
      setData(newData);
    }
  };

  return (
    <Layout>
      <Helmet prioritizeSeoTags>
        <title>{`${META_TAGS?.default?.title} ${
          property?.title || property?.address
        } Contacts`}</title>
        <meta
          property="og:title"
          content={`${META_TAGS?.default?.title} ${
            property?.title || property?.address
          } Contacts`}
        />
      </Helmet>
      <div
        id="items-container"
        className="absolute top-0 left-0 right-0 bottom-0"
      >
        <div className="relative w-full h-full">
          <section
            className="px-8 flex flex-col shadow-detail-status-nav mb-4"
            ref={headerRef}
          >
            <div
              className={cn(
                "flex flex-row justify-between items-center py-4 w-full",
                {
                  "border-b border-jll-color-coldGray-3": isFacet,
                }
              )}
            >
              <h2 className="text-heading1 font-semibold">Tasks</h2>
              <div className="flex flex-row items-center space-x-3">
                <Input
                  className="w-[320px] !mb-0"
                  name="search"
                  leadingIcon={FiSearch}
                  onChange={onChangeInput}
                  value={keyword}
                  isClearable
                  placeholder="Search tasks"
                />
                <Button
                  variant="secondary"
                  leadingIcon={FiPlusCircle}
                  onClick={() => {
                    setSelectedTask(null);
                    setIsOpen(true);
                  }}
                >
                  Add task
                </Button>
              </div>
            </div>
            {isFacet && (
              <div className="flex flex-row items-center flex-wrap py-3">
                {facets?.map((facet: any, index: number) => {
                  if (
                    !facet?.facets ||
                    !facet?.facets?.length ||
                    facet?.id === "buildings"
                  )
                    return;
                  return (
                    <SelectAutoComplete
                      key={index}
                      label={facet?.label}
                      name={facet?.id}
                      isMulti
                      className="w-fit min-w-[200px] max-w-full !mb-1 mr-3"
                      labelClassName="text-jll-color-text-base-default text-sm font-medium"
                      onChange={(option) => handleFilter(option, facet?.id)}
                      options={facet?.facets || []}
                      value={
                        !!Object.keys(paramFilters)?.length &&
                        facet?.facets?.filter((item: any) =>
                          paramFilters?.[facet?.id]?.includes?.(
                            item?.value.toString()
                          )
                        )
                      }
                    />
                  );
                })}
              </div>
            )}
          </section>
          <div
            className={cn(
              "absolute left-0 right-0 bottom-0 col-span-10 overflow-y-scroll p-6 flex"
            )}
            style={{ top: `${height}px` }}
          >
            {!loading && !data?.length && (
              <EmptyResult>
                <h3 className="text-heading3 text-jll-color-coldGray-5">
                  There are no active tasks for this property
                </h3>
              </EmptyResult>
            )}
            {!!data?.length && (
              <div className="w-full">
                <div className="flex space-x-4 pb-6 justify-between">
                  <div className="font-semibold text-jll-color-coldGray-7 text-sm">
                    {total} result{total > 1 ? "s" : ""}
                  </div>
                  {paramFilters && !!Object.keys(paramFilters)?.length && (
                    <Button
                      variant="ghost"
                      size="small"
                      leadingIcon={FiRotateCcw}
                      onClick={onClickReset}
                    >
                      Reset
                    </Button>
                  )}
                </div>
                <Table>
                  <Table.Thead>
                    <Table.Tr>
                      {TASK_TABLE_COLUMNS.map((column, index) => (
                        <Table.Th
                          key={index}
                          filterId={column.id}
                          sortFields={sortFields}
                          filters={paramFilters}
                          onClick={onClickSorting}
                        >
                          {column.label}
                        </Table.Th>
                      ))}
                      <Table.Th />
                    </Table.Tr>
                  </Table.Thead>
                  <Table.Tbody>
                    {data?.map((task: TaskProps, index: number) => (
                      <Table.Tr
                        key={index}
                        onClick={() => onClickPreview(task)}
                      >
                        {TASK_TABLE_COLUMNS?.map((column, index) => (
                          <Table.Td key={index}>
                            <StyledTaskValue task={task} valueKey={column.id} />
                          </Table.Td>
                        ))}
                        <Table.Td className="space-x-2 text-right">
                          {task?.active_status === STATUS_ACTIVE && (
                            <>
                              <Button
                                variant="secondary"
                                size="small"
                                onClick={(e) => onClickEdit(e, task)}
                              >
                                Edit
                              </Button>
                              <Button
                                variant="secondary"
                                size="small"
                                onClick={(e) => onClickRemove(e, task)}
                              >
                                Delete
                              </Button>
                            </>
                          )}
                          {task?.active_status === STATUS_REMOVED && (
                            <Button
                              variant="secondary"
                              size="small"
                              onClick={(e) => onClickReinstate(e, task)}
                            >
                              Reinstate
                            </Button>
                          )}
                        </Table.Td>
                      </Table.Tr>
                    ))}
                  </Table.Tbody>
                </Table>
              </div>
            )}
            {!!(total > data?.length) && (
              <div
                className="w-full py-2 flex justify-center items-center"
                ref={sentryRef}
              >
                <Loading />
              </div>
            )}
          </div>
        </div>
      </div>

      <TaskEditModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        selectedTask={selectedTask}
        updateData={updateData}
        isProperty={true}
      />
      <TaskPreviewModal
        isOpen={isPreviewOpen}
        setIsOpen={setIsPreviewOpen}
        selectedTask={selectedTask}
        openEditModal={openEditModal}
      />
    </Layout>
  );
};

export default React.memo(PropertyTask);
