import { useEffect, useState, useMemo } from "react";
import type { FC } from "react";
import debounce from "lodash.debounce";
import { LayoutListing } from "ui-organisms";
import { useNavigate, useLocation } from "react-router-dom";
import queryString from "query-string";
import { EmptyResult, Loading, Input, Button } from "ui-atoms";
import { FiSearch, FiRotateCcw } from "react-icons/fi";
import {
  Table,
  ShowMoreItems,
  StyledBrokerValue,
  BrokerDetailModal,
  Facets,
} from "ui-molecules";
import { BROKERS_TABLE_COLUMNS, META_TAGS } from "constant";
import { SEARCH_RESULT_LIMIT } from "./constants";
import type { BrokerProps, FacetsProps } from "types";
import { DirectionEnum } from "types";
import { getBrokersAPI } from "services";
import { useApiCall } from "hooks";
import { Helmet } from "react-helmet-async";
import React from "react";

const Brokers: FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [getBrokers, isLoadingBrokers] = useApiCall(getBrokersAPI);
  const [data, setData] = useState<BrokerProps[]>();
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [facets, setFacets] = useState<FacetsProps>();
  const [selectedBroker, setSelectedBroker] = useState<BrokerProps>();
  const [sortFields, setSortFields] = useState<string[]>([]);
  const [keyword, setKeyword] = useState<string>();
  const [paramFilters, setParamFilters] = useState<any>(null);

  useEffect(() => {
    let filters = queryString.parse(location?.search, {
      arrayFormat: "bracket",
    });
    setParamFilters(filters);
  }, [location]);

  useEffect(() => {
    if (paramFilters === null) return;
    setKeyword(paramFilters?.keyword || "");
    setPage(1);
    setData([]);
    // Get the first set of brokers
    getBrokers({ page: 1, limit: SEARCH_RESULT_LIMIT, ...paramFilters }).then(
      (data: any) => {
        setData(data?.docs);
        setPage(data?.page);
        setTotal(data?.total);
        setFacets(data?.facets);
        setSortFields(data?.sort_fields);
      }
    );
  }, [paramFilters]);

  const onClickSorting = (sort: string) => {
    const updatedFilters = {
      ...paramFilters,
      sort,
      direction:
        paramFilters?.direction === DirectionEnum.asc
          ? DirectionEnum?.desc
          : DirectionEnum.asc,
    };
    navigate(
      `${location.pathname}?${queryString.stringify(updatedFilters, {
        arrayFormat: "bracket",
      })}`
    );
  };

  const onClickDetail = (broker: BrokerProps) => () => {
    setSelectedBroker(broker);
    setIsOpen(true);
  };

  const onClickShowMore = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    // Get the next batch of properties and add them to the queue
    getBrokers({
      page: nextPage,
      limit: SEARCH_RESULT_LIMIT,
      ...paramFilters,
    }).then((data: any) => {
      setData((prevData: any) => [...prevData, ...data?.docs]);
      setPage(data?.page);
      setTotal(data?.total);
      setFacets(data?.facets);
      setSortFields(data?.sort_fields);
    });
  };

  const updateKeywordFilter = (e: any) => {
    const updatedFilters = { ...paramFilters };
    if (e.target.value) {
      updatedFilters["keyword"] = e.target.value;
    } else if (updatedFilters.hasOwnProperty("keyword")) {
      delete updatedFilters.keyword;
    }
    navigate(
      `${location.pathname}?${queryString.stringify(updatedFilters, {
        arrayFormat: "bracket",
      })}`
    );
  };

  const debounceUpdateKeyword = useMemo(
    () => debounce(updateKeywordFilter, 300),
    [paramFilters]
  );

  const onChangeInput = (e: any) => {
    setKeyword(e.target.value);
    debounceUpdateKeyword(e);
  };

  const onClickReset = () => {
    navigate(location.pathname);
  };

  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>{META_TAGS?.brokers?.title}</title>
        <meta property="og:title" content={META_TAGS?.brokers?.title} />
      </Helmet>
      <LayoutListing>
        <LayoutListing.Header>
          <h1 className="text-heading1">Brokers</h1>
          <div className="flex space-x-4 items-center">
            <Input
              className="w-[400px] !mb-0"
              name="search"
              leadingIcon={FiSearch}
              onChange={onChangeInput}
              value={keyword}
              isClearable
              placeholder="Search by name, title, email"
            />
          </div>
        </LayoutListing.Header>
        <LayoutListing.Container>
          <Facets facets={facets} />
          <LayoutListing.Content>
            <div className="p-6">
              <div className="flex space-x-4 pb-6 justify-between">
                <div className="font-semibold text-jll-color-coldGray-7 text-sm">
                  {total} result{total > 1 ? "s" : ""}
                </div>
                {paramFilters && !!Object.keys(paramFilters)?.length && (
                  <Button
                    variant="ghost"
                    size="small"
                    leadingIcon={FiRotateCcw}
                    onClick={onClickReset}
                  >
                    Reset
                  </Button>
                )}
              </div>
              {!!isLoadingBrokers && !data?.length && <Loading />}
              {!isLoadingBrokers && !data?.length && (
                <EmptyResult>
                  <h3 className="text-heading3 text-jll-color-coldGray-5">
                    Sorry, no users found.
                  </h3>
                  <div className="pt-6 pb-2 flex justify-center px-4">
                    <p className="text-base text-jll-color-coldGray-7 text-center">
                      To create a broker profile in{" "}
                      <a
                        href="https://www.us.jll.com/en/people"
                        target="_blank"
                        className="text-jll-color-icon-info"
                      >
                        JLL People Finder
                      </a>
                      , please submit a request via{" "}
                      <a
                        href="https://jll.my.workfront.com/requests/submitted"
                        target="_blank"
                        className="text-jll-color-icon-info"
                      >
                        WorkFront
                      </a>{" "}
                      {">"} New Request {">"} Global Digital Marketing Request
                      Queue {">"} JLL.com (Corporate Website) {">"} People
                      Finder.
                    </p>
                  </div>
                </EmptyResult>
              )}
              {!!data?.length && (
                <>
                  <Table>
                    <Table.Thead>
                      <Table.Tr>
                        {BROKERS_TABLE_COLUMNS.map((column, index) => {
                          return (
                            <Table.Th
                              key={index}
                              filterId={column.id}
                              sortFields={sortFields}
                              filters={paramFilters}
                              onClick={onClickSorting}
                            >
                              {column.label}
                            </Table.Th>
                          );
                        })}
                      </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody>
                      {data?.map((broker: BrokerProps, index: number) => {
                        return (
                          <Table.Tr key={index} onClick={onClickDetail(broker)}>
                            {BROKERS_TABLE_COLUMNS.map((column, index) => {
                              return (
                                <Table.Td key={index}>
                                  <StyledBrokerValue
                                    broker={broker}
                                    valueKey={column.id}
                                  />
                                </Table.Td>
                              );
                            })}
                          </Table.Tr>
                        );
                      })}
                    </Table.Tbody>
                  </Table>
                  <ShowMoreItems
                    onClick={onClickShowMore}
                    total={total}
                    isLoading={isLoadingBrokers}
                    itemsCount={data.length}
                  />
                </>
              )}
            </div>
          </LayoutListing.Content>
        </LayoutListing.Container>
      </LayoutListing>
      <BrokerDetailModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        broker={selectedBroker}
      />
    </>
  );
};

export default React.memo(Brokers);
