import { FiX } from "react-icons/fi";
import { Button } from "ui-atoms";
import { Modal } from "ui-molecules";
import TeamListingForm from "./TeamListingForm";

interface ITeamListingModal {
  isOpen: boolean;
  setIsOpen: any;
  selectedListing?: any;
}

const TeamListingModal: React.FC<ITeamListingModal> = ({
  isOpen,
  setIsOpen,
  selectedListing,
}) => {
  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen} size="default">
      <Modal.Header className="relative">
        <Button
          variant="neutral"
          leadingIcon={FiX}
          className="absolute top-0 right-0 w-5 !h-5"
          leadingIconClass="w-5 h-5 text-jll-color-text-base-subdued"
          onClick={() => setIsOpen(false)}
        />
        <h5 className="text-xl font-semibold text-jll-color-text-base-default">
          {selectedListing ? "Update" : "Add"} Team Listing
        </h5>
      </Modal.Header>

      <Modal.Body className="mt-6">
        <TeamListingForm
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          selectedListing={selectedListing}
        />
      </Modal.Body>
    </Modal>
  );
};

export default TeamListingModal;
