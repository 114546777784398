const styles: any = {
  page: {
    backgroundColor: "white",
    fontFamily: "Source Sans Pro",
    color: "white",
    position: "relative",
    display: "flex",
    flexDirection: "row",
  },

  leftSideView: {
    width: 210,
    backgroudColor: "#F6F7F7",
    borderBottomRightRadius: 16,
    height: "100%",
  },
  infoView: {
    width: "100%",
    backgroudColor: "#F6F7F7",
    borderBottomRightRadius: 16,
    padding: 18,
    backgroundColor: "#F6F7F7",
    display: "flex",
    flexDirection: "column",
    alignSelf: "flex-start",
    marginBottom: 10,
  },
  leftImg: {
    width: 178,
    height: 178,
    borderRadius: 2,
    marginBottom: 10,
  },
  titleView: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 12,
  },
  id: {
    color: "#A4A9B0",
    fontSize: 10,
  },
  titleTxt: {
    color: "#3A3E43",
    fontSize: 14,
    fontWeight: 600,
  },
  addressView: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 12,
  },
  address: {
    color: "#3A3E43",
    fontSize: 10,
  },
  typeTxt: {
    backgroundColor: "#3A3E43",
    borderRadius: 28,
    padding: "2px 7px",
    color: "#FFFFFF",
    fontSize: 8,
    fontWeight: 600,
    display: "block",
    width: "auto",
    marginRight: 4,
  },
  tenantTypeTxt: {
    backgroundColor: "#11C169",
    borderRadius: 28,
    padding: "2px 7px",
    color: "#FFFFFF",
    fontSize: 8,
    fontWeight: 600,
    display: "block",
    width: "auto",
  },
  location: {
    view: {
      paddingLeft: 18,
      display: "flex",
      flexDirection: "column",
    },
    title: {
      fontWeight: 600,
      fontSize: 14,
      color: "#3A3E43",
      marginBottom: 10,
    },
    img: {
      width: "100%",
      height: 200,
      borderRadius: 4,
    },
  },

  rightSideView: {
    width: 632,
    padding: 18,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },

  title: {
    color: "#3A3E43",
    fontSize: 14,
    fontWeight: 600,
    marginBottom: 18,
  },
  detailView: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 16,
  },
  detailRow: {
    borderTopColor: "#DDDFE1",
    borderTopStyle: "solid",
    width: "100%",
    display: "flex",
    flexDirection: "row",
  },
  detailCol: {
    paddingTop: 8,
    paddingBottom: 8,
    width: "100%",
    borderLeftColor: "#DDDFE1",
    borderLeftStyle: "solid",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  detailLabel: {
    color: "#60666E",
    fontSize: 10,
  },
  detailTxt: {
    color: "#60666E",
    fontSize: 10,
    fontWeight: 600,
  },
  detailUnit: {
    color: "#60666E",
    fontSize: 8,
  },
  detailColView: {
    display: "flex",
    flexDirection: "row",
  },
  detailChip: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#11C169",
    borderRadius: 28,
    padding: "2px 8px",
  },
  detailType: {
    txt: {
      color: "#60666E",
      fontSize: 10,
      fontWeight: 600,
      display: "flex",
      flexDirection: "row",
    },
    chip: {
      display: "flex",
      backgroundColor: "#F6F7F7",
      borderRadius: 28,
      padding: "2px 8px",
      flexDirection: "row",
    },
  },

  floorPlan: {
    view: {
      width: "100%",
      border: "1px solid #DDDFE1",
      borderRadius: 4,
      backgroundColor: "white",
      padding: 10,
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: 16,
    },
    image: {
      height: "100%",
      width: "auto",
    },
    txt: {
      color: "#60666E",
      fontSize: 10,
    },
  },

  brokerPanel: {
    width: "100%",
    borderRadius: 4,
    backgroundColor: "white",
    paddingTop: 8,
    paddingRight: 8,
    display: "flex",
    flexDirection: "row",
  },
  brokerView: {
    display: "flex",
    flexDirection: "row",
    width: "25%",
    paddingLeft: 8,
    paddingRight: 8,
    borderLeftStyle: "solid",
    borderLeftColor: "#DDDFE1",
  },
  brokerImg: {
    width: 28,
    height: 28,
    borderRadius: 28,
    marginRight: 8,
    backgroundColor: "white",
    objectFit: "contain",
  },
  brokerContent: {
    display: "flex",
    flexDirection: "column",
  },
  brokerName: {
    fontWeight: 600,
    fontSize: 10,
    color: "#3A3E43",
  },
  brokerTxt: {
    fontSize: 8,
    color: "#60666E",
  },

  highlightPanel: {
    backgroundColor: "#F6F7F7",
    borderRadius: 4,
    padding: "4px 9px",
    marginBottom: 16,
  },
  highlightRow: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
  },
  highlightCol: {
    paddingTop: 6,
    paddingBottom: 6,
    width: "100%",
    borderTopColor: "#DDDFE1",
    borderTopStyle: "solid",
  },
  highlightTxt: {
    color: "#60666E",
    fontSize: 10,
    height: 15,
    overflow: "hidden",
    maxHeight: 15,
  },

  logo: {
    view: {
      position: "absolute",
      bottom: 17,
      right: 22,
      height: 18,
      width: 41,
    },
    img: {
      height: "100%",
      width: "100%",
    },
  },
};

export default styles;
