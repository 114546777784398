import React, {
  useEffect,
  useContext,
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import {
  Input,
  Checkbox,
  SelectAutoComplete,
  Button,
  Toast,
  DatePicker,
  Label,
  Switch,
} from "ui-atoms";
import cn from "classnames";
import { LayoutEditAvailability as Layout } from "ui-organisms";
import { useFormik } from "formik";
import { GlobalContext } from "context";
import { useParams, useNavigate, Link } from "react-router-dom";
import {
  AccordionItem,
  GoogleMap,
  Highlight,
  OLMFilterItem,
  RemoveDescriptionItem,
  WarningModal,
} from "ui-molecules";
import {
  patchAvailabilityAPI,
  getPropertiesAPI,
  postAvailabilityAPI,
  getPropertyAPI,
} from "services";
import { useApiCall, useCallbackPrompt } from "hooks";
import {
  getMetaOptions,
  joinTwoStr,
  getApiPayloadFromForm,
  getApiDate,
  getUTCDate,
  getOptionValue,
  isCommonItem,
} from "utils";
import {
  SET_AVAILABILITY,
  NEW_ID,
  URLS,
  META_TAGS,
  SET_LOADING_MODAL,
  SET_CONFIRM_MODAL,
  AVAILABILITY_TABS,
  DATE_AVAILABLE_IMMEDIATE,
  LEASE_TERM_NEGOTIABLE,
  AVAILABILITY_VALIDATION,
  STATUS_ACTIVE,
  OLM_AVAILABILITY_FIELDS,
} from "constant";
import { INITIAL_VALUES, InitialValuesProps } from "./constants";
import { Helmet } from "react-helmet-async";
import { FiCalendar, FiChevronRight, FiInfo } from "react-icons/fi";
import { renderToString } from "react-dom/server";

interface IAvailabilityEdit {
  diffFields?: any;
  onDiffFields?: any;
  isOLM?: boolean;
}

const AvailabilityEdit = forwardRef(
  ({ onDiffFields, diffFields, isOLM }: IAvailabilityEdit, ref) => {
    const session = JSON.parse(sessionStorage.getItem("session") || "null");
    const [patchAvailability] = useApiCall(patchAvailabilityAPI);
    const [fetchProperty] = useApiCall(getPropertyAPI);
    const navigate = useNavigate();
    const [postAvailability] = useApiCall(postAvailabilityAPI);
    const [getProperties] = useApiCall(getPropertiesAPI);
    const { dispatch, state } = useContext(GlobalContext);
    const { meta, availability, showTooltip, hideTooltip } = state;
    const [loading, setLoading] = useState(false);
    const { availabilityId, propertyId } = useParams();
    const isNew = availabilityId === NEW_ID;
    const [isWarningModal, setIsWarningModal] = useState(false);
    const publishStatus = useRef<any>(null);
    const [showDialog, setShowDialog] = useState<boolean>(false);
    const [showPrompt, confirmNavigation, cancelNavigation] =
      useCallbackPrompt(showDialog);
    const [isFloorRange, setIsFloorRange] = useState(
      !!availability?.min_floor_area
    );
    const [isPriceRange, setIsPriceRange] = useState(!!availability?.min_price);

    const {
      handleSubmit,
      handleChange,
      handleBlur,
      setFieldValue,
      values,
      errors,
      touched,
      setValues,
    } = useFormik({
      initialValues: INITIAL_VALUES,
      validationSchema: AVAILABILITY_VALIDATION,
      onSubmit: async () => {
        setLoading(true);
        dispatch({
          type: SET_LOADING_MODAL,
          payload: {
            open: true,
            label: "Updating Availability",
          },
        });
        hideTooltip();
        try {
          let payload: InitialValuesProps | undefined = getApiPayloadFromForm(
            availability,
            values
          );

          if (payload && Object.keys(payload)?.length) {
            if (
              payload.hasOwnProperty("hide_price") &&
              payload?.hide_price === null
            ) {
              payload.hide_price = false;
            }

            if (publishStatus.current) {
              payload.publish_status = publishStatus.current;
            }

            if (payload.hasOwnProperty("date_on_market")) {
              payload["date_on_market"] = getApiDate(values.date_on_market);
            }

            if (isNew) {
              const updated_user = session?.id;

              const res = await postAvailability({
                building: propertyId,
                updated_user,
                ...payload,
              });
              setLoading(false);
              publishStatus.current = null;
              setTimeout(() => {
                dispatch({
                  type: SET_LOADING_MODAL,
                  payload: {
                    open: false,
                    label: null,
                  },
                });
              }, 100);
              if (!res) return;
              setShowDialog(false);
              // @ts-ignore
              cancelNavigation();
              navigate(
                `/property/${propertyId}/availability/${res.id}/${URLS.AVAILABILITY.FORM}`
              );
            } else {
              if (
                payload &&
                availability?.publish_status === STATUS_ACTIVE &&
                isCommonItem(Object.keys(payload), OLM_AVAILABILITY_FIELDS)
              ) {
                payload["publish_status"] = STATUS_ACTIVE;
              }
              const response = await patchAvailability({
                id: availability.id,
                payload,
              });
              setLoading(false);
              publishStatus.current = null;
              setTimeout(() => {
                dispatch({
                  type: SET_LOADING_MODAL,
                  payload: {
                    open: false,
                    label: null,
                  },
                });
              }, 100);
              if (!response) return;
              Toast.success("Changes saved with success");
              dispatch({
                type: SET_AVAILABILITY,
                payload: response,
              });
            }
          } else {
            Toast.warn("No changes to be saved");
            setLoading(false);
            publishStatus.current = null;
            setTimeout(() => {
              dispatch({
                type: SET_LOADING_MODAL,
                payload: {
                  open: false,
                  label: null,
                },
              });
            }, 100);
          }
        } catch (err) {
          setLoading(false);
          publishStatus.current = null;
          setTimeout(() => {
            dispatch({
              type: SET_LOADING_MODAL,
              payload: {
                open: false,
                label: null,
              },
            });
          }, 100);
        }
      },
    });

    const setNewCase = async () => {
      const building = await fetchProperty(propertyId);
      setValues({
        ...INITIAL_VALUES,
        building: propertyId,
        id: NEW_ID,
        building_property_type: building?.property_type,
        date_on_market: getUTCDate(),
      });
    };

    useEffect(() => {
      if (isNew && !values.id) {
        setNewCase();
      } else if (
        (availability &&
          !values.id &&
          availability?.id?.toString() === availabilityId?.toString()) ||
        availability?.id !== values?.id
      ) {
        let formValues = {};
        Object.keys(INITIAL_VALUES).forEach((key: string) => {
          formValues = {
            ...formValues,
            [key]: availability?.[key] || "",
          };
        });
        setValues({
          ...formValues,
          date_available: availability?.date_available
            ? availability?.date_available
            : DATE_AVAILABLE_IMMEDIATE,
          lease_term: availability?.lease_term
            ? availability?.lease_term
            : LEASE_TERM_NEGOTIABLE,
          tenure_types: availability?.tenure_types,
          publish_status: availability?.publish_status,
          dirty: availability?.dirty,
          date_on_market: availability?.date_on_market
            ? getUTCDate(availability?.date_on_market)
            : "",
        });
      }
    }, [availability, values, availabilityId, meta]);

    useEffect(() => {
      if (!availability || !values) return;
      const diff = getApiPayloadFromForm(availability, values);
      onDiffFields(diff);
    }, [availability, values]);

    useEffect(() => {
      if (isNew || !diffFields || !Object.keys(diffFields)?.length)
        setShowDialog(false);
      else setShowDialog(true);
    }, [diffFields]);

    useEffect(() => {
      if (!showPrompt) return;
      const action = async () => {
        try {
          if (errors && !!Object.keys(errors)?.length) {
            setIsWarningModal(true);
            // @ts-ignore
            cancelNavigation();
            return;
          }
          await handleSubmit();
          // @ts-ignore
          confirmNavigation();
        } catch (err) {
          // @ts-ignore
          cancelNavigation();
        }
      };

      let isPublish = false;
      if (
        diffFields &&
        availability?.publish_status === STATUS_ACTIVE &&
        isCommonItem(Object.keys(diffFields), OLM_AVAILABILITY_FIELDS)
      ) {
        isPublish = true;
      }

      dispatch({
        type: SET_CONFIRM_MODAL,
        payload: {
          open: true,
          content: {
            description: renderToString(
              <RemoveDescriptionItem description="You have un-saved changes, would you like to save changes before proceeding" />
            ),
            btnTitle: `${isPublish ? "Save and Publish" : "Save"}`,
            cancelTitle: "Proceed without Saving",
            action: action,
            cancel: confirmNavigation,
            additionalCancel: cancelNavigation,
          },
        },
      });
    }, [showPrompt]);

    const loadPropertyOptions = async (keyword: string) => {
      if (!keyword) {
        return;
      }
      return getProperties({ keyword: keyword }).then((res: any) => {
        return res.docs.map((building: any) => {
          return {
            value: building.pk,
            label: joinTwoStr(building?.title, building?.address),
          };
        });
      });
    };

    const onClickSubmit = async (publish_status: any = null) => {
      if (errors && !!Object.keys(errors)?.length) {
        setIsWarningModal(true);
      }
      publishStatus.current = publish_status;
      await handleSubmit();
    };

    useImperativeHandle(ref, () => ({
      onClickSubmit,
    }));

    // @ryong this is where if it is new, the solution does not work.
    const isLand = values?.building_property_type === "land";

    return (
      <>
        <Helmet prioritizeSeoTags>
          <title>{`${META_TAGS?.default?.title} ${joinTwoStr(
            availability?.building_title,
            availability?.name,
            " | "
          )} Edit`}</title>
          <meta
            property="og:title"
            content={`${META_TAGS?.default?.title} ${joinTwoStr(
              availability?.building_title,
              availability?.name,
              " | "
            )} Edit`}
          />
        </Helmet>
        {!!values.id && (
          <form>
            <Layout.Section>
              <AccordionItem id="details" label="Details">
                <div className="flex flex-row space-x-4 pt-8">
                  <div
                    className={cn("md:w-9/12 w-full flex flex-col", {
                      "md:!w-9/12 !w-full":
                        !!availability?.latitude && !!availability?.longitude,
                    })}
                  >
                    {availability?.building && (
                      <div className="flex flex-row items-center space-x-2">
                        <Label className="w-6/12 mb-6">Property</Label>
                        <SelectAutoComplete
                          name="building"
                          className="w-full"
                          onChange={(option) => {
                            if (!option) setFieldValue("building", null);
                            else setFieldValue("building", option.value);
                          }}
                          onBlur={handleBlur}
                          value={{
                            value: availability?.building,
                            label: joinTwoStr(
                              availability?.building_title,
                              availability?.building_address
                            ),
                          }}
                          isDisabled={!!availability?.building}
                          loadOptions={loadPropertyOptions}
                        />
                      </div>
                    )}
                    <OLMFilterItem
                      fieldKey="tenure_types"
                      olmFields={OLM_AVAILABILITY_FIELDS}
                      isOLM={isOLM}
                    >
                      <div className="flex flex-row items-center space-x-2">
                        <Label className="w-6/12 mb-6">Availability Type</Label>
                        <div className="flex flex-row items-center w-full space-x-4 mb-6">
                          {[...getMetaOptions(meta?.suite?.tenure_types)]?.map(
                            (option, idx) => (
                              <Checkbox
                                key={idx}
                                id={`tenure-${idx}`}
                                name={option?.value}
                                label={option?.label}
                                labelClassName="font-semibold"
                                className="!space-x-2"
                                value={option.value}
                                checked={
                                  !!values?.tenure_types?.includes(option.value)
                                }
                                onChange={(e: any) => {
                                  const isChecked = e.target.checked;
                                  const vals = [
                                    ...(values?.tenure_types || []),
                                  ];
                                  if (isChecked) {
                                    const index = vals.indexOf(option?.value);
                                    if (index < 0) vals.push(option?.value);
                                  } else {
                                    const index = vals.indexOf(option?.value);
                                    if (index > -1) vals.splice(index, 1);
                                  }
                                  setFieldValue("tenure_types", vals);
                                }}
                              />
                            )
                          )}
                        </div>
                      </div>
                    </OLMFilterItem>
                    <OLMFilterItem
                      fieldKey="date_on_market"
                      olmFields={OLM_AVAILABILITY_FIELDS}
                      isOLM={isOLM}
                    >
                      <div className="flex flex-row items-center space-x-2">
                        <Label className="w-6/12 mb-6">Date On Market</Label>
                        <DatePicker
                          className="w-full"
                          trailingIcon={FiCalendar}
                          selected={
                            values?.date_on_market
                              ? getUTCDate(values?.date_on_market)
                              : getUTCDate()
                          }
                          error={
                            touched.date_on_market ? errors.date_on_market : ""
                          }
                          value={values?.date_on_market}
                          onChange={(date: Date) =>
                            setFieldValue("date_on_market", date)
                          }
                          popperProps={{ strategy: "fixed" }}
                        />
                      </div>
                    </OLMFilterItem>

                    <OLMFilterItem
                      fieldKey="name"
                      olmFields={OLM_AVAILABILITY_FIELDS}
                      isOLM={isOLM}
                    >
                      <div className="flex flex-row items-center space-x-2">
                        <Label className="w-6/12 mb-6" optional>
                          Suite
                        </Label>
                        <Input
                          name="name"
                          className="w-full"
                          onChange={handleChange}
                          error={touched.name ? errors.name : ""}
                          onBlur={handleBlur}
                          value={values?.name}
                        />
                      </div>
                    </OLMFilterItem>
                    <OLMFilterItem
                      fieldKey="floor"
                      olmFields={OLM_AVAILABILITY_FIELDS}
                      isOLM={isOLM}
                    >
                      <div className="flex flex-row items-center space-x-2">
                        <Label className="w-6/12 mb-6">Floor</Label>
                        <Input
                          name="floor"
                          className="w-full"
                          onChange={handleChange}
                          error={touched.floor ? errors.floor : ""}
                          onBlur={handleBlur}
                          value={values?.floor}
                        />
                      </div>
                    </OLMFilterItem>
                  </div>
                  {!!availability?.latitude && !!availability?.longitude && (
                    <div className="md:w-3/12 w-full flex flex-col">
                      <Label className="mb-6">Building on map</Label>
                      <GoogleMap
                        position={{
                          lat: Number(availability?.latitude),
                          lng: Number(availability?.longitude),
                        }}
                        className="rounded md:h-full mb-6 overflow-hidden"
                        zoom={13}
                      />
                    </div>
                  )}
                </div>
              </AccordionItem>
            </Layout.Section>

            <Layout.Section>
              <AccordionItem id="size" label="Size">
                <div className="flex flex-col w-full pt-8">
                  <OLMFilterItem
                    fieldKey="max_floor_area"
                    olmFields={OLM_AVAILABILITY_FIELDS}
                    isOLM={isOLM}
                  >
                    <div className="flex flex-row items-center w-full space-x-2">
                      <div className="w-4/12 mb-6 flex flex-row items-center justify-between">
                        <Label>
                          {isLand ? "Acreage Available" : "Space Available"}
                        </Label>
                        <Switch
                          enabled={isFloorRange}
                          onChange={(flag: boolean) => {
                            setIsFloorRange(flag);
                            if (!flag) setFieldValue("min_floor_area", null);
                            else
                              setFieldValue(
                                "min_floor_area",
                                availability?.min_floor_area
                              );
                          }}
                          className="!mb-0 h-6"
                        />
                      </div>
                      <div className="flex flex-row space-x-2 w-full">
                        <Input
                          name="max_floor_area"
                          type="number"
                          area={isLand ? "Acres" : "SQ FT."}
                          onChange={handleChange}
                          value={values?.max_floor_area}
                          onBlur={handleBlur}
                          error={
                            touched.max_floor_area ? errors.max_floor_area : ""
                          }
                          className="w-full"
                        />
                        {isFloorRange && (
                          <Input
                            name="min_floor_area"
                            type="number"
                            area={isLand ? "Acres" : "SQ FT."}
                            onChange={handleChange}
                            value={values?.min_floor_area}
                            error={
                              touched.min_floor_area
                                ? errors.min_floor_area
                                : ""
                            }
                            onBlur={handleBlur}
                            className="w-full"
                          />
                        )}
                      </div>
                    </div>
                  </OLMFilterItem>

                  {!isLand && (
                    <OLMFilterItem
                      fieldKey="max_contiguous"
                      olmFields={OLM_AVAILABILITY_FIELDS}
                      isOLM={isOLM}
                    >
                      <div className="flex flex-row items-center space-x-2">
                        <Label className="w-4/12 mb-6" optional>
                          Maximum Contiguous Available
                        </Label>
                        <Input
                          name="max_contiguous"
                          type="number"
                          className="w-full"
                          area={"SQ FT."}
                          onChange={handleChange}
                          value={values?.max_contiguous}
                          onBlur={handleBlur}
                          error={
                            touched.max_contiguous ? errors.max_contiguous : ""
                          }
                        />
                      </div>
                    </OLMFilterItem>
                  )}

                  <OLMFilterItem
                    fieldKey="space_use_type"
                    olmFields={OLM_AVAILABILITY_FIELDS}
                    isOLM={isOLM}
                  >
                    <div className="flex flex-row items-center space-x-2">
                      <Label className="w-4/12 mb-6" optional>
                        Space Use
                      </Label>
                      <SelectAutoComplete
                        name="space_use_type"
                        className="w-full"
                        onChange={(option) => {
                          if (!option) setFieldValue("space_use_type", null);
                          else setFieldValue("space_use_type", option.value);
                        }}
                        onBlur={handleBlur}
                        value={getOptionValue(values?.space_use_type)}
                        error={
                          touched.space_use_type ? errors.space_use_type : ""
                        }
                        options={[
                          ...getMetaOptions(meta?.suite?.space_use_type),
                        ]}
                      />
                    </div>
                  </OLMFilterItem>

                  <OLMFilterItem
                    fieldKey="suite_condition"
                    olmFields={OLM_AVAILABILITY_FIELDS}
                    isOLM={isOLM}
                  >
                    <div className="flex flex-row items-center space-x-2">
                      <Label className="w-4/12 mb-6" optional>
                        Suite Condition
                      </Label>
                      <SelectAutoComplete
                        name="suite_condition"
                        className="w-full"
                        onChange={(option) => {
                          if (!option) setFieldValue("suite_condition", null);
                          else setFieldValue("suite_condition", option.value);
                        }}
                        onBlur={handleBlur}
                        value={getOptionValue(values?.suite_condition)}
                        error={
                          touched.suite_condition ? errors.suite_condition : ""
                        }
                        options={[
                          ...getMetaOptions(meta?.suite?.suite_condition),
                        ]}
                      />
                    </div>
                  </OLMFilterItem>

                  {!isLand && (
                    <>
                      <OLMFilterItem
                        fieldKey="is_full_floor"
                        olmFields={OLM_AVAILABILITY_FIELDS}
                        isOLM={isOLM}
                      >
                        <div className="flex flex-row items-center w-full space-x-4 mb-6">
                          <Checkbox
                            label="Is Full Floor"
                            name="is_full_floor"
                            id="is_full_floor"
                            checked={values.is_full_floor}
                            onChange={(e: any) => {
                              setFieldValue("is_full_floor", e.target.checked);
                            }}
                            onBlur={handleBlur}
                          />
                          <div className="flex flex-row items-center">
                            <FiInfo className="w-4 h-4 text-jll-color-text-base-reversedSubdued mr-2" />
                            <span className="text-jll-color-text-base-default text-xs">
                              If checked, it indicates the space is a full
                              floor.
                            </span>
                          </div>
                        </div>
                      </OLMFilterItem>
                      <OLMFilterItem
                        fieldKey="is_vacant"
                        olmFields={OLM_AVAILABILITY_FIELDS}
                        isOLM={isOLM}
                      >
                        <div className="flex flex-row items-center w-full space-x-4 mb-6">
                          <Checkbox
                            label="Is Vacant"
                            name="is_vacant"
                            id="is_vacant"
                            checked={values.is_vacant}
                            onChange={(e: any) => {
                              setFieldValue("is_vacant", e.target.checked);
                            }}
                            onBlur={handleBlur}
                          />
                          <div className="flex flex-row items-center">
                            <FiInfo className="w-4 h-4 text-jll-color-text-base-reversedSubdued mr-2" />
                            <span className="text-jll-color-text-base-default text-xs">
                              If checked, it indicates the space is vacant.
                            </span>
                          </div>
                        </div>
                      </OLMFilterItem>
                    </>
                  )}
                </div>
              </AccordionItem>
            </Layout.Section>

            <Layout.Section>
              <AccordionItem id="financials" label="Financials & Date">
                <div className="flex flex-col w-full pt-4">
                  {availability?.building_hide_availabilities_price
                    ?.toString()
                    .toLowerCase() === "true" && (
                    <div className="flex flex-row items-center pb-5">
                      <FiInfo className="w-4 h-4 text-jll-color-text-base-reversedSubdued mr-2" />
                      <p className="text-jll-color-coldGray-7 text-sm ">
                        Lease Prices will be saved but not displayed on OLM. To
                        change this, update the Hide Availabilities Price
                        setting at the property level.
                      </p>
                    </div>
                  )}

                  <OLMFilterItem
                    fieldKey="rent_type"
                    olmFields={OLM_AVAILABILITY_FIELDS}
                    isOLM={isOLM}
                  >
                    <div className="flex flex-row items-center w-full space-x-2">
                      <Label className="w-4/12 mb-6" optional>
                        Rent Type
                      </Label>
                      <SelectAutoComplete
                        name="rent_type"
                        className="w-full"
                        onChange={(option) => {
                          if (!option) setFieldValue("rent_type", null);
                          else setFieldValue("rent_type", Number(option.value));
                        }}
                        onBlur={handleBlur}
                        value={{
                          label:
                            meta?.suite?.rent_type[values?.rent_type]?.value,
                          value: values?.rent_type,
                        }}
                        error={touched.rent_type ? errors.rent_type : ""}
                        options={[...getMetaOptions(meta?.suite?.rent_type)]}
                      />
                    </div>
                  </OLMFilterItem>

                  {availability?.building_hide_availabilities_price
                    ?.toString()
                    .toLowerCase() !== "true" && (
                    <OLMFilterItem
                      fieldKey="hide_price"
                      olmFields={OLM_AVAILABILITY_FIELDS}
                      isOLM={isOLM}
                    >
                      <div className="flex flex-row items-center w-full space-x-4 mb-6">
                        <Checkbox
                          label="Hide Lease Price"
                          name="hide_price"
                          id="hide_price"
                          checked={values.hide_price}
                          onChange={(e: any) => {
                            setFieldValue("hide_price", e?.target?.checked);
                          }}
                          onBlur={handleBlur}
                        />
                        <div className="flex flex-row items-center">
                          <FiInfo className="w-4 h-4 text-jll-color-text-base-reversedSubdued mr-2" />
                          <span className="text-jll-color-text-base-default text-xs">
                            If checked, will not display lease pricing.
                          </span>
                        </div>
                      </div>
                    </OLMFilterItem>
                  )}

                  <OLMFilterItem
                    fieldKey="max_price"
                    olmFields={OLM_AVAILABILITY_FIELDS}
                    isOLM={isOLM}
                  >
                    <div className="flex flex-row items-center w-full space-x-2">
                      <div className="w-4/12 mb-6 flex flex-row items-center justify-between">
                        <Label optional>{`Lease Price / ${
                          isLand ? "Acre" : "SF"
                        }`}</Label>
                        <Switch
                          enabled={isPriceRange}
                          onChange={(flag: boolean) => {
                            setIsPriceRange(flag);
                            if (!flag) setFieldValue("min_price", null);
                            else
                              setFieldValue(
                                "min_price",
                                availability?.min_price
                              );
                          }}
                          className="!mb-0 h-6"
                        />
                      </div>
                      <div className="flex flex-row space-x-2 w-full">
                        <Input
                          name="max_price"
                          type="number"
                          currency="USD"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.max_price}
                          error={touched.max_price ? errors.max_price : ""}
                          className="w-full"
                        />
                        {!!isPriceRange && (
                          <Input
                            name="min_price"
                            type="number"
                            currency="USD"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.min_price}
                            error={touched.min_price ? errors.min_price : ""}
                            className="w-full"
                          />
                        )}
                      </div>
                    </div>
                  </OLMFilterItem>

                  <OLMFilterItem
                    fieldKey="date_available"
                    olmFields={OLM_AVAILABILITY_FIELDS}
                    isOLM={isOLM}
                  >
                    <div className="flex flex-row items-center w-full space-x-2">
                      <Label className="w-4/12 mb-6">Date Available</Label>
                      <SelectAutoComplete
                        name="date_available"
                        className="w-full"
                        isCreatable
                        isCustomCreatable
                        onChange={(option) => {
                          if (!option) setFieldValue("date_available", null);
                          else setFieldValue("date_available", option.value);
                        }}
                        onBlur={handleBlur}
                        value={
                          values?.date_available === ""
                            ? { label: "", value: "" }
                            : getOptionValue(
                                meta?.suite?.date_available &&
                                  values?.date_available &&
                                  values?.date_available in
                                    meta?.suite?.date_available
                                  ? meta?.suite?.date_available[
                                      values?.date_available
                                    ]?.value
                                  : values?.date_available
                                  ? values?.date_available
                                  : DATE_AVAILABLE_IMMEDIATE
                              )
                        }
                        error={
                          touched.date_available ? errors.date_available : ""
                        }
                        options={[
                          ...getMetaOptions(meta?.suite?.date_available),
                        ]}
                      />
                    </div>
                  </OLMFilterItem>

                  <OLMFilterItem
                    fieldKey="lease_term"
                    olmFields={OLM_AVAILABILITY_FIELDS}
                    isOLM={isOLM}
                  >
                    <div className="flex flex-row items-center w-full space-x-2">
                      <Label className="w-4/12 mb-6">Lease Term</Label>
                      <SelectAutoComplete
                        className="w-full"
                        name="lease_term"
                        isCreatable
                        onChange={(option) => {
                          if (!option) setFieldValue("lease_term", null);
                          else setFieldValue("lease_term", option?.value);
                        }}
                        onBlur={handleBlur}
                        value={getOptionValue(
                          meta?.suite?.lease_term &&
                            values?.lease_term &&
                            values?.lease_term in meta?.suite?.lease_term
                            ? meta?.suite?.lease_term[values?.lease_term]?.value
                            : values?.lease_term || LEASE_TERM_NEGOTIABLE
                        )}
                        error={touched.lease_term ? errors.lease_term : ""}
                        options={[...getMetaOptions(meta?.suite?.lease_term)]}
                      />
                    </div>
                  </OLMFilterItem>
                </div>
              </AccordionItem>
            </Layout.Section>

            <OLMFilterItem
              fieldKey="highlights"
              olmFields={OLM_AVAILABILITY_FIELDS}
              isOLM={isOLM}
            >
              <Layout.Section>
                <AccordionItem id="highlights" label="Highlights">
                  <div className="flex flex-col w-full pt-8">
                    <Highlight
                      label="Highlights"
                      name="highlights"
                      touched={touched}
                      errors={errors}
                      onChange={(value) => {
                        setFieldValue("highlights", value);
                      }}
                      onBlur={(e) => {
                        handleBlur(e);
                      }}
                      value={values?.highlights}
                    />
                  </div>
                </AccordionItem>
              </Layout.Section>
            </OLMFilterItem>

            <div className="px-8 fixed bottom-0 left-[360px] right-0 flex justify-end bg-white shadow-detail-status-nav h-[80px] items-center">
              <div className="space-x-4">
                {!isNew && (
                  <Link
                    to={`/property/${propertyId}/availability/${availabilityId}/${URLS.AVAILABILITY.FORM}?tab=${AVAILABILITY_TABS.CONTACTS}`}
                  >
                    <Button variant="secondary" trailingIcon={FiChevronRight}>
                      Next
                    </Button>
                  </Link>
                )}
                <Button
                  disabled={
                    loading || !diffFields || !Object.keys(diffFields)?.length
                  }
                  variant="primary"
                  id="save-tooltip"
                  onMouseOver={() => showTooltip("save-tooltip")}
                  onMouseLeave={() => hideTooltip()}
                  data-tooltip-content={
                    availability?.publish_status === STATUS_ACTIVE
                      ? "This saves the availability in MyListings and publishes to OLM"
                      : "This saves the availability, but does not publish to OLM"
                  }
                  onClick={() => onClickSubmit()}
                >
                  {isNew
                    ? "Create"
                    : availability?.publish_status === STATUS_ACTIVE
                    ? "Save and Publish"
                    : "Save"}
                </Button>
              </div>
            </div>
          </form>
        )}
        <WarningModal
          isOpen={isWarningModal}
          setIsOpen={setIsWarningModal}
          errors={errors}
        />
      </>
    );
  }
);

export default React.memo(AvailabilityEdit);
