import { StyledCompanyValueProps } from "ui-molecules";

interface CompanyTableColumnProps {
  id: StyledCompanyValueProps["valueKey"];
  label: string;
}

export const COMPANY_TABLE_COLUMNS: CompanyTableColumnProps[] = [
  {
    id: "name",
    label: "Company",
  },
  {
    id: "roles",
    label: "Role",
  },
];
