export const AUTH_TABS = {
  PROPERTY: "property",
  AVAILABILITY: "availability",
  TASK: "task",
  CONTRACT: "contract",
};

export const AUTH_HOME_TABS = [
  { label: "Properties", value: AUTH_TABS.PROPERTY },
  { label: "Availabilities", value: AUTH_TABS.AVAILABILITY },
  { label: "Tasks", value: AUTH_TABS.TASK },
  { label: "Agreements", value: AUTH_TABS.CONTRACT },
];
