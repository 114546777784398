export const reorder = (list: any[] = [], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

/**
 * Check the common items in 2 arrays
 * @param value Ex: [1, 2, 3], [3, 4]
 * @returns Ex: true(true in this case) or false
 */
export const isCommonItem = (array1: any[], array2: any[]): boolean  => {
  return !!array1.some(item => array2.includes(item));
}
