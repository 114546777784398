import { PropertyProps } from "types";
import { StyledLeaseRateValueProps, StyledOpexValueProps } from "ui-molecules";

interface OpexTableColumnProps {
  id: StyledOpexValueProps["valueKey"];
  label: string;
}

interface LeaseRateTableColumnProps {
  id: StyledLeaseRateValueProps["valueKey"];
  label: string;
}

export type InitialValuesProps = {
  [key in keyof PropertyProps]?: any;
};

export const OPEX_TABLE_COLUMNS: OpexTableColumnProps[] = [
  {
    id: "operating_expenses",
    label: "Opex",
  },
  {
    id: "year",
    label: "Year",
  },
  {
    id: "utility_type",
    label: "Component Type",
  },
  {
    id: "utility_rate",
    label: "Component Fee",
  },
  {
    id: "log_date",
    label: "Log Date",
  },
];

export const LEASE_RATE_TABLE_COLUMNS: LeaseRateTableColumnProps[] = [
  {
    id: "suite_name",
    label: "Suite Name",
  },
  {
    id: "floor",
    label: "Floor",
  },
  {
    id: "rent_type",
    label: "Rent Type",
  },
  {
    id: "price",
    label: "Price",
  },
];

export const PROPERTY_FINANCIAL_INITIAL_VALUES: InitialValuesProps = {
  debt_amount: "",
  debt_maturity_date: "",
  purchase_price: "",
  last_sale_date: "",
  hide_availabilities_price: false,
  hide_sales_price: true,
  max_sale: "",
};
