import { Input, Textarea, Button } from "ui-atoms";
import { DescriptionProps } from "types";
import { FiTrash } from "react-icons/fi";
import { importDescriptionContent } from "utils";

interface PropertyDescriptionProps extends DescriptionProps {
  index: number;
  // TODO e should be the right type
  onBlur: (e: any, index: number) => void;
  onDelete: (index: any) => void;
  onChange: (index: number, name: string, value: string) => void;
  errors: any;
}

const PropertyDescription = ({
  content,
  title,
  index,
  onDelete,
  onBlur,
  onChange,
  errors,
}: PropertyDescriptionProps) => (
  <>
    <div className="flex space-x-2">
      <div className="w-8 h-9 flex flex-row items-center">{index + 1}</div>
      <div className="w-full">
        <Input
          name="title"
          label="Header"
          onChange={(e: any) => onChange(index, "title", e.currentTarget.value)}
          onBlur={(e) => onBlur(e, index)}
          value={title}
          error={errors?.title || ""}
        />
        <Textarea
          name="content"
          label="Description"
          onChange={(e) => onChange(index, "content", e.currentTarget.value)}
          onBlur={(e) => onBlur(e, index)}
          value={importDescriptionContent(content)}
          error={errors?.content || ""}
        />
      </div>
    </div>
    <div className="flex justify-end">
      <Button
        variant="neutral"
        size="small"
        leadingIcon={FiTrash}
        className="text-jll-color-stroke-danger"
        onClick={() => onDelete(index)}
      >
        Delete
      </Button>
    </div>
  </>
);

export default PropertyDescription;
