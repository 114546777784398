import { BuildingCompanyProps } from "types";
import { Chip } from "ui-atoms";

export interface StyledCompanyValueProps {
  company: BuildingCompanyProps;
  valueKey: keyof BuildingCompanyProps;
}

const StyledCompanyValue: React.FC<StyledCompanyValueProps> = ({
  company,
  valueKey,
}) => {
  return (
    <>
      {(() => {
        switch (valueKey) {
          case "roles":
            if (!company?.roles?.length) return <>-</>;
            else
              return (
                <div className="flex flex-wrap">
                  {company?.roles?.map((type, index) => (
                    <Chip
                      size="small"
                      key={index}
                      variant="secondary"
                      className="m-1"
                    >
                      {type}
                    </Chip>
                  ))}
                </div>
              );
          default:
            if (company?.hasOwnProperty(valueKey)) {
              return <>{company[valueKey]}</>;
            }
            return <>-</>;
        }
      })()}
    </>
  );
};

export default StyledCompanyValue;
