import { AvailabilityProps, IGalleryImageProps } from "types";
import cn from "classnames";
import {
  ReactCarousel,
  StyledAvailabilityValue,
  StyledAvailabilityValueProps,
  StyledBrokerValue,
} from "ui-molecules";
import { FiCheckCircle, FiDownload, FiLink } from "react-icons/fi";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { convertExtension, lazyLoadImageOnError } from "utils";
import { Button, Chip } from "ui-atoms";
import { useWindowDimensions } from "hooks";
import { useEffect, useRef, useState } from "react";
import { convertImageUrl } from "services";
import { renderToString } from "react-dom/server";
import { FaStreetView } from "react-icons/fa";

const DetailFields: {
  label: string;
  value: StyledAvailabilityValueProps["valueKey"];
}[] = [
  { label: "Availability Type", value: "tenure_types" },
  { label: "Date on Market", value: "date_on_market" },
  { label: "Suite", value: "name" },
  { label: "Floor", value: "floor" },
  { label: "Space Available", value: "space_available" },
  { label: "Maximum Contiguous Available", value: "max_contiguous" },
  { label: "Space Use", value: "space_use_type" },
  { label: "Suite Condition", value: "suite_condition" },
  { label: "Rent Type", value: "rent_type" },
  { label: "Lease Price / SF", value: "max_price" },
  { label: "Date Available", value: "date_available" },
  { label: "Lease term", value: "lease_term" },
];

interface ISuiteInformation {
  suite: AvailabilityProps;
}

const CardBox = ({ file }: any) => {
  const { width } = useWindowDimensions();
  const cardRef = useRef<any>(null);
  const [height, setHeight] = useState(0);

  const isCloudaryFile = file?.includes("cloudinary") ? true : false;

  useEffect(() => {
    if (!width || !cardRef?.current) return;
    const refWidth = cardRef?.current?.clientWidth;
    setHeight(refWidth * 0.7);
  }, [width, cardRef]);

  return (
    <div
      className="w-full relative lg:w-3/12 md:w-4/12 sm:w-6/12 w-full mb-4 md:mr-2 bg-white rounded justify-between"
      ref={cardRef}
      style={{ height: `${height}px` }}
    >
      {isCloudaryFile ? (
        <LazyLoadImage
          src={convertExtension(file)}
          className="w-full h-full object-cover rounded"
          onError={lazyLoadImageOnError}
        />
      ) : (
        <div className="h-full group w-full flex justify-center items-center cursor-pointer relative">
          <FiLink className="w-6 h-6 text-jll-color-text-base-default" />
        </div>
      )}
      <Button
        variant="primary"
        leadingIcon={FiDownload}
        className="absolute bottom-[10px] right-[10px] !px-2.5"
        onClick={() => {
          window.open(file, "_blank");
        }}
      />
    </div>
  );
};

const SuiteInformation: React.FC<ISuiteInformation> = ({ suite }) => {
  const [sliderImgs, setSliderImgs] = useState<IGalleryImageProps[]>([]);

  useEffect(() => {
    if (!!suite?.floorplans?.length) {
      const suiteImages = [...suite?.floorplans];
      if (suiteImages?.length > 0) {
        let arr: IGalleryImageProps[] = [];
        suiteImages?.forEach((item, idx) => {
          arr.push({
            id: idx,
            src: convertImageUrl(convertExtension(item)),
            thumb: convertImageUrl(convertExtension(item)),
            subHtml: renderToString(<span>{idx + 1}</span>),
          });
        });
        setSliderImgs(arr);
      }
    }
  }, [suite?.floorplans]);

  const handleDownload = (urls: string[] | undefined) => {
    if (!urls?.length) return;
    urls?.forEach((url) => {
      window.open(url);
    });
  };

  return (
    <div className="flex flex-col w-full py-4">
      {(!!suite?.virtual_tours?.length || !!suite?.brochures?.length) && (
        <div className="flex flex-row items-center space-x-8 md:mb-6 mb-4 justify-end">
          {!!suite?.virtual_tours?.length && (
            <Button
              variant="neutral"
              className="text-jll-color-icon-info"
              trailingIcon={FaStreetView}
              onClick={() => handleDownload(suite?.virtual_tours)}
            >
              Virtual Tour
            </Button>
          )}
        </div>
      )}

      <ul className="w-full divide-jll-color-coldGray-3 flex flex-wrap flex-row text-sm text-gray-606 md:mb-6 mb-4">
        {DetailFields?.map((item, index) => {
          return (
            <li
              key={index}
              className={cn("flex justify-between items-center py-3 w-full", {
                "md:w-3/6": true,
                "border-t md:border-t-0": index === 1,
                "border-t": index > 1,
                "md:border-t-0": index < 2,
                "md:pl-4 md:border-l": index % 2 !== 0,
                "md:pr-4": index % 2 === 0,
              })}
            >
              <div className="flex flex-wrap max-w-[50%] text-sm text-jll-color-text-base-subdued whitespace-normal">
                {item.label}
              </div>
              <div className="flex flex-wrap max-w-[50%] text-sm text-jll-color-text-base-subdued whitespace-normal font-semibold items-end">
                <StyledAvailabilityValue
                  availability={suite}
                  valueKey={item.value}
                />
              </div>
            </li>
          );
        })}
      </ul>

      {/* Highlights */}
      {!!suite?.highlights?.length && (
        <div className="w-full border border-jll-color-coldGray-3 py-2 px-4 flex flex-wrap rounded-lg md:mb-6 mb-4">
          {suite?.highlights?.map((highlight, idx) => {
            return (
              <div
                key={idx}
                className="flex justify-center items-start md:w-6/12 w-full py-2"
              >
                <div className="w-full text-center flex">
                  <div className="flex flex-row items-center text-sm text-jll-color-text-base-subdued truncate">
                    <FiCheckCircle className="mr-2 text-jll-color-icon-success flex w-4 h-4" />
                    <span className="whitespace-normal text-left flex w-fit">
                      {highlight}
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}

      {/* Floor Plan */}
      {!!sliderImgs?.length && (
        <div className="md:mb-6 mb-4">
          <p className="text-jll-color-text-base-default font-semibold md:mb-1.5 mb-4">
            Floorplan
          </p>
          <div className="flex flex-row flex-wrap">
            {sliderImgs && sliderImgs?.length > 0 && (
              <div className="mt-5 w-full">
                <ReactCarousel items={sliderImgs} />
              </div>
            )}
          </div>
        </div>
      )}

      {/* Images */}
      {!!suite?.images?.length && (
        <div className="md:mb-6 mb-4">
          <p className="text-jll-color-text-base-default font-semibold md:mb-1.5 mb-4">
            Images
          </p>
          <div className="flex flex-row flex-wrap">
            {suite?.images?.map((image, idx) => (
              <CardBox file={image} key={idx} />
            ))}
          </div>
        </div>
      )}

      {/* Brochures */}
      {!!suite?.brochures?.length && (
        <div className="md:mb-6 mb-4">
          <p className="text-jll-color-text-base-default font-semibold md:mb-1.5 mb-4">
            Brochures
          </p>
          <div className="flex flex-row flex-wrap">
            {suite?.brochures?.map((brochure, idx) => (
              <CardBox file={brochure} key={idx} />
            ))}
          </div>
        </div>
      )}

      {/* Broker */}
      {!!suite?.brokers?.length && (
        <div className="w-full border border-jll-color-coldGray-3 py-2 px-4 flex flex-wrap rounded-lg md:mb-6 mb-4">
          {suite?.brokers?.map((broker: any, idx: number) => (
            <div className="lg:w-3/12 md:w-4/12 w-6/12 py-2" key={idx}>
              <StyledBrokerValue broker={broker} valueKey="info" />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SuiteInformation;
