import { useState, useEffect } from "react";
import { FiPlayCircle } from "react-icons/fi";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Button, Input } from "ui-atoms";
import { getVideoThumb, lazyLoadImageOnError } from "utils";

interface VideoUrlItemProps {
  name: string;
  onChange: (e: any) => void;
  onBlur: (e: any) => void;
  onRemove: () => void;
  error?: string;
  value?: string;
  isThumb?: boolean;
  placeholder?: string;
}

const VideoUrlItem: React.FC<VideoUrlItemProps> = ({
  name,
  onChange,
  onBlur,
  onRemove,
  error,
  value,
  isThumb,
  placeholder = "Enter link to video here"
}) => {
  const [thumb, setThumb] = useState<any>(null);

  useEffect(() => {
    if (value && !error && isThumb) {
      getVideoThumb(value)
        .then((response) => {
          setThumb(response);
        })
        .catch((e) => {
          setThumb(undefined);
        });
    } else {
      setThumb(undefined);
    }
  }, [value, error]);

  const handleOnChange = (e: any, value: string) => {
    onChange(e);
  };

  return (
    <div className="pb-4 flex space-x-4 flex-row w-full">
      <div className="flex flex-col w-full items-end">
        <Input
          name={name}
          className="w-full !mb-1"
          placeholder={placeholder}
          value={value}
          onChange={(e: any) => handleOnChange(e, e.target.value)}
          onBlur={onBlur}
          error={error}
        />
        <Button
          className="mt-2"
          variant="secondary"
          size="small"
          onClick={onRemove}
        >
          Delete
        </Button>
      </div>
      {isThumb ? (
        <div
          className={`w-[150px] h-[80px] flex justify-center items-center border border-jll-color-coldGray-3 bg-jll-color-coldGray-1 rounded ${
            !thumb && "border-dashed"
          }`}
        >
          {thumb ? (
            <LazyLoadImage
              src={thumb}
              onError={lazyLoadImageOnError}
              className="w-full h-full object-cover rounded"
            />
          ) : (
            <FiPlayCircle className="w-6 h-6 text-jll-color-icon-base-subdued" />
          )}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default VideoUrlItem;
