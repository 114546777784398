import { StyledContractValueProps, TdProps, ThProps } from "ui-molecules";

interface TableColumnProps {
  id: StyledContractValueProps["valueKey"];
  label: string;
  td?: TdProps;
  th?: ThProps;
  sort?: string;
  isEdit?: boolean;
  type?: "number" | "date" | "file";
}

export const CONTRACT_TABLE_COLUMNS: TableColumnProps[] = [
  {
    id: "info",
    label: "Property",
    sort: "asc",
  },
  {
    id: "property_type",
    label: "Property Type",
    td: {
      className: "space-x-2",
    },
  },
  {
    id: "tenure_types",
    label: "Listing Type",
    td: {
      className: "space-x-2",
    },
  },
  {
    id: "building_size",
    label: "Building Size",
  },
  {
    id: "suites",
    label: "Availabilities",
  },
  {
    id: "publish_status",
    label: "Status",
  },
  {
    id: "dealio",
    label: "Dealio",
    isEdit: true,
    type: "number",
  },
  {
    id: "costar_id",
    label: "Costar ID",
    isEdit: true,
    type: "number",
  },
  {
    id: "contract_start",
    label: "Contract Start",
    isEdit: true,
    type: "date",
  },
  {
    id: "contract_end",
    label: "Contract End",
    isEdit: true,
    type: "date",
  },
  {
    id: "listing_contracts",
    label: "Listing Agreement",
    isEdit: true,
    type: "file",
  },
  {
    id: "signage",
    label: "Signage",
    isEdit: true,
    type: "file",
  },
];
