import type { FC } from "react";
import { Avatar, Chip } from "ui-atoms";
import type { BrokerProps } from "types";
import { getFormatedDate, noPropagation } from "utils";
import { Link } from "react-router-dom";
import { FiAlertCircle, FiUser } from "react-icons/fi";
import React from "react";
import { STATUS_REMOVED } from "../../constant";

export interface StyledBrokerValueProps {
  broker: BrokerProps;
  valueKey: keyof BrokerProps | "info";
}

const StyledBrokerValue: FC<StyledBrokerValueProps> = ({
  broker,
  valueKey,
}) => {
  return (
    <>
      {(() => {
        switch (valueKey) {
          case "name":
            return (
              <div className="flex space-x-2 items-center">
                <Avatar alt="" size="default" src={broker?.photo || ""} />
                <div className="truncate">
                  <p className="font-bold text-jll-color-text-base-default text-ellipsis overflow-hidden">
                    {broker?.name}

                    {broker?.active_status === STATUS_REMOVED && (
                      <span className="ml-2 text-jll-color-surface-accent-default font-medium">
                        (Inactive)
                      </span>
                    )}
                  </p>
                  <p className="whitespace-normal">{broker?.job_title}</p>
                </div>
              </div>
            );
          case "info":
            return (
              <div className="flex space-x-4 items-center">
                <Avatar alt="" size="default" src={broker?.photo || ""} />
                <div className="truncate text-jll-color-text-base-default">
                  <p className="font-semibold  text-ellipsis overflow-hidden">
                    {broker?.name}
                  </p>
                  <p className="whitespace-normal text-xs">{broker?.email}</p>
                  <p className="whitespace-normal text-xs">
                    {broker?.telephone}
                  </p>
                </div>
              </div>
            );
          case "job_title":
            return (
              <div className="text-ellipsis overflow-hidden">
                {broker?.job_title}
                <div>{broker?.service_line}</div>
              </div>
            );
          case "city":
            return (
              <div className="text-ellipsis overflow-hidden">
                {broker?.city}
              </div>
            );
          case "email":
            return (
              <div className="text-ellipsis overflow-hidden">
                <a
                  href={`mailto:${broker?.email}`}
                  className="text-jll-color-surface-info-default"
                  onClick={noPropagation}
                >
                  {broker?.email}
                </a>
              </div>
            );
          case "department":
            if (!broker?.department?.length || !broker?.department) {
              return <>-</>;
            }
            return broker?.department?.map((name, index) => (
              <Chip size="small" key={index} variant="secondary">
                {name}
              </Chip>
            ));

          case "page_url":
            if (!broker?.page_url) {
              return <>-</>;
            }
            return (
              <Link
                to={broker?.page_url}
                target="_blank"
                className="text-jll-color-icon-info text-ellipsis overflow-hidden"
              >
                <FiUser className="w-4 h-5 text-jll-color-coldGray-5 mb-1 inline" />{" "}
                Profile
              </Link>
            );

          case "update_timestamp":
            if (!broker?.update_timestamp) {
              return <>-</>;
            }
            return <>{getFormatedDate(broker?.update_timestamp)}</>;
          case "create_timestamp":
            if (!broker?.create_timestamp) {
              return <>-</>;
            }
            return <>{getFormatedDate(broker?.create_timestamp)}</>;
          default:
            if (broker.hasOwnProperty(valueKey)) {
              return <>{broker[valueKey]}</>;
            }
            return <>-</>;
        }
      })()}
    </>
  );
};

export default StyledBrokerValue;
