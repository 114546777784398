import { GlobalContext } from "context";
import { useContext } from "react";
import cn from "classnames";
import { renderToString } from "react-dom/server";
import { PropertyMSProps } from "types";
import { Chip } from "ui-atoms";

export interface StyledMarketSphereValueProps {
  property: PropertyMSProps;
  valueKey: keyof PropertyMSProps | "building";
}

const BuildingInfo = ({ property, isTooltip = false }: any) => {
  return (
    <>
      <p
        className={cn("font-bold text-ellipsis overflow-hidden", {
          "text-jll-color-text-base-default": !isTooltip,
        })}
      >
        {property?.name}
      </p>
      <p
        className={cn("text-ellipsis overflow-hidden", {
          "text-jll-color-text-base-subdued": !isTooltip,
        })}
      >
        {property?.address}
      </p>
    </>
  );
};

const StyledMarketSphereValue: React.FC<StyledMarketSphereValueProps> = ({
  property,
  valueKey,
}) => {
  const { state } = useContext(GlobalContext);
  const { showTooltip, hideTooltip } = state;

  return (
    <>
      {(() => {
        switch (valueKey) {
          case "building":
            return (
              <div
                className="flex flex-col truncate"
                id={`ms-tooltip-${property?.resultId}`}
                onMouseOver={() =>
                  showTooltip(`ms-tooltip-${property?.resultId}`)
                }
                onMouseLeave={() => hideTooltip()}
                data-tooltip-html={renderToString(
                  <BuildingInfo property={property} isTooltip />
                )}
              >
                <BuildingInfo property={property} />
              </div>
            );
          case "propertyType":
            if (!property?.propertyType) {
              return <>-</>;
            }
            return (
              <Chip size="small" variant="secondary">
                {property?.propertyType}
              </Chip>
            );
          default:
            if (property?.hasOwnProperty(valueKey)) {
              return <>{property[valueKey]}</>;
            }
            return <>-</>;
        }
      })()}
    </>
  );
};

export default StyledMarketSphereValue;
