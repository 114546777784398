import { useEffect, useContext, useState, useMemo } from "react";
import type { FC } from "react";
import debounce from "lodash.debounce";
import { GlobalContext } from "context";
import { LayoutListing } from "ui-organisms";
import { useNavigate, useLocation } from "react-router-dom";
import queryString from "query-string";
import {
  EmptyResult,
  Loading,
  Input,
  Button,
  SelectAutoComplete,
} from "ui-atoms";
import { FiSearch, FiRotateCcw } from "react-icons/fi";
import { Table, ShowMoreItems, StyledUserValue, Facets } from "ui-molecules";
import {
  USERS_TABLE_COLUMNS,
  SET_PAGE_USERS_FILTERS,
  META_TAGS,
} from "constant";
import { SEARCH_RESULT_LIMIT } from "./constants";
import type { UserProps, FacetsProps, OptionProps } from "types";
import { DirectionEnum } from "types";
import { getUsersAPI, postUserGroupAPI, deleteUserGroupAPI } from "services";
import { useApiCall } from "hooks";
import { Helmet } from "react-helmet-async";
import React from "react";

const ManageUsers: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [getUsers, isLoadingBrokers] = useApiCall(getUsersAPI);
  const [postUserGroup] = useApiCall(postUserGroupAPI);
  const [deleteUserGroup] = useApiCall(deleteUserGroupAPI);
  const { dispatch, state } = useContext(GlobalContext);
  const { meta } = state;
  const [data, setData] = useState<any[]>([]);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [facets, setFacets] = useState<FacetsProps>();
  const [sortFields, setSortFields] = useState<string[]>([]);
  const [keyword, setKeyword] = useState<string>();
  const [paramFilters, setParamFilters] = useState<any>(null);

  useEffect(() => {
    const filters = queryString.parse(location?.search, {
      arrayFormat: "bracket",
    });
    setParamFilters(filters);
  }, [location]);

  useEffect(() => {
    if (paramFilters === null) return;
    setKeyword(paramFilters?.keyword || "");
    setPage(1);
    setData([]);
    getUsers({
      page: 1,
      limit: SEARCH_RESULT_LIMIT,
      ...paramFilters,
    }).then((data: any) => {
      setData([...data?.docs]);
      setPage(data?.page);
      setTotal(data?.total);
      setFacets(data?.facets);
      setSortFields(data?.sort_fields);
    });
  }, [paramFilters]);

  const onClickSorting = (sort: string) => {
    const updatedFilters = {
      ...paramFilters,
      sort,
      direction:
        paramFilters?.direction === DirectionEnum.asc
          ? DirectionEnum?.desc
          : DirectionEnum.asc,
    };
    navigate(
      `${location.pathname}?${queryString.stringify(updatedFilters, {
        arrayFormat: "bracket",
      })}`
    );
  };

  const onClickShowMore = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    getUsers({
      page: nextPage,
      limit: SEARCH_RESULT_LIMIT,
      ...paramFilters,
    }).then((data: any) => {
      setData((prevData: any) => [...prevData, ...data?.docs]);
      setPage(data?.page);
      setTotal(data?.total);
      setFacets(data?.facets);
      setSortFields(data?.sort_fields);
    });
  };

  const updateKeywordFilter = (e: any) => {
    const updatedFilters = { ...paramFilters };
    const value = e.target.value;
    if (value) {
      updatedFilters["keyword"] = value;
    } else if (updatedFilters.hasOwnProperty("keyword")) {
      delete updatedFilters.keyword;
    }
    navigate(
      `${location.pathname}?${queryString.stringify(updatedFilters, {
        arrayFormat: "bracket",
      })}`
    );
  };

  const debounceUpdateKeyword = useMemo(
    () => debounce(updateKeywordFilter, 300),
    [paramFilters]
  );

  const onChangeInput = (e: any) => {
    setKeyword(e.target.value);
    debounceUpdateKeyword(e);
  };

  const onClickReset = () => {
    setKeyword("");
    dispatch({
      type: SET_PAGE_USERS_FILTERS,
      payload: null,
    });
    navigate(location.pathname);
  };

  const onChangeRole = (user: UserProps, groups: OptionProps[]) => {
    const userGroups = user?.group || [];
    const updateGroups = [...groups]?.map((x) => x.value) || [];
    const updateGroupLabels = [...groups]?.map((x) => x.label) || [];
    const deleteGroups =
      [...userGroups]?.filter((x) => {
        return !updateGroups.includes(x);
      }) || [];

    updateGroups?.forEach((group) => {
      postUserGroup({
        user: user.pk,
        group,
      });
    });

    deleteGroups?.forEach((group) => {
      deleteUserGroup({
        user: user.pk,
        group,
      });
    });

    const index = (data || [])?.findIndex(
      (item: UserProps) => item?.id === user?.id
    );
    if (index > -1) {
      setData((prev) => {
        const newItems = [...(prev || [])];
        newItems[index]["group"] = updateGroups;
        newItems[index]["group_name"] = updateGroupLabels;
        return newItems;
      });
    }
  };

  return (
    <LayoutListing>
      <Helmet prioritizeSeoTags>
        <title>{META_TAGS?.admin?.title}</title>
        <meta property="og:title" content={META_TAGS?.admin?.title} />
      </Helmet>
      <LayoutListing.Header>
        <h1 className="text-heading1">User Management</h1>
        <div className="flex space-x-4 items-center">
          <Input
            className="w-[400px] !mb-0"
            name="search"
            leadingIcon={FiSearch}
            onChange={onChangeInput}
            value={keyword}
            isClearable
            placeholder="Search by name, title, email"
          />
        </div>
      </LayoutListing.Header>
      <LayoutListing.Container>
        <Facets facets={facets} />
        <LayoutListing.Content>
          <div className="p-6">
            <div className="flex space-x-4 pb-6 justify-between">
              <div className="font-semibold text-jll-color-coldGray-7 text-sm">
                {total} result{total > 1 ? "s" : ""}
              </div>
              {paramFilters && !!Object.keys(paramFilters)?.length && (
                <Button
                  variant="ghost"
                  size="small"
                  leadingIcon={FiRotateCcw}
                  onClick={onClickReset}
                >
                  Reset
                </Button>
              )}
            </div>
            {!!isLoadingBrokers && !data && <Loading />}
            {!isLoadingBrokers && data && !data?.length && (
              <EmptyResult>
                <h3 className="text-heading3 text-jll-color-coldGray-5">
                  Sorry, no users found.
                </h3>
              </EmptyResult>
            )}
            {meta && !!data?.length && (
              <>
                <Table>
                  <Table.Thead>
                    <Table.Tr>
                      {USERS_TABLE_COLUMNS.map((column, index) => {
                        return (
                          <Table.Th
                            key={index}
                            filterId={column.id}
                            sortFields={sortFields}
                            filters={paramFilters}
                            onClick={onClickSorting}
                          >
                            {column.label}
                          </Table.Th>
                        );
                      })}
                      <Table.Th>Role</Table.Th>
                    </Table.Tr>
                  </Table.Thead>
                  <Table.Tbody>
                    {data?.map((user: UserProps, index: number) => {
                      return (
                        <Table.Tr key={index}>
                          {USERS_TABLE_COLUMNS.map((column, index) => {
                            return (
                              <Table.Td key={index}>
                                <StyledUserValue
                                  user={user}
                                  valueKey={column.id}
                                />
                              </Table.Td>
                            );
                          })}
                          <Table.Td className="w-[300px]">
                            <SelectAutoComplete
                              name="role"
                              className="!mb-0"
                              isMulti
                              options={[...meta?.user_group]?.map((x: any) => ({
                                value: x?.id,
                                label: x?.name,
                              }))}
                              placeholder="Select Role"
                              onChange={(option) => onChangeRole(user, option)}
                              value={user?.group?.map(
                                (groupId: number, index) => ({
                                  value: groupId,
                                  label: user?.group_name?.[index] || "",
                                })
                              )}
                            />
                          </Table.Td>
                        </Table.Tr>
                      );
                    })}
                  </Table.Tbody>
                </Table>
                <ShowMoreItems
                  onClick={onClickShowMore}
                  total={total}
                  isLoading={isLoadingBrokers}
                  itemsCount={data.length}
                />
              </>
            )}
          </div>
        </LayoutListing.Content>
      </LayoutListing.Container>
    </LayoutListing>
  );
};

export default React.memo(ManageUsers);
