import { PropertyProps } from "types";
import { getDecimalFormating } from "utils";

export const importDescriptionContent = (content: string) => {
  return content.replaceAll("<p>", "").replaceAll("</p>", "\r");
};

export const exportDescriptionContent = (content: string) => {
  return "<p>" + content.trim().split("\n").join("</p><p>") + "</p>";
};

export const getOptions = (value?: string[], meta?: any) => {
  if (!value?.length) {
    return [];
  }

  return value?.map((x: string) => ({
    value: x,
    label: meta?.[x]?.value || x,
  }));
};

export const getPropertyItem = (
  property: PropertyProps | any,
  key: string,
  meta?: any
) => {
  switch (key) {
    case "building_size":
      if (!property?.min_surface_area && !property?.max_surface_area) {
        return "N/A";
      }
      if (property?.min_surface_area && !property?.max_surface_area) {
        return `${getDecimalFormating(property?.min_surface_area)} ${
          property?.surface_area_unit
        }`;
      }
      if (!property?.min_surface_area && property?.max_surface_area) {
        return `${getDecimalFormating(property?.max_surface_area)} ${
          property?.surface_area_unit
        }`;
      }
      if (property?.min_surface_area === property?.max_surface_area) {
        return `${getDecimalFormating(property?.max_surface_area)} ${
          property?.surface_area_unit
        }`;
      }
      return `${getDecimalFormating(property?.min_surface_area)}-
          ${getDecimalFormating(property?.max_surface_area)} ${
        property?.surface_area_unit
      }`;

    case "rent_price":
      if (!property?.min_rent && !property?.max_rent) {
        return "N/A";
      }
      if (property?.min_rent && !property?.max_rent) {
        return `$${getDecimalFormating(property?.min_rent)}`;
      }
      if (!property?.min_rent && property?.max_rent) {
        return `$${getDecimalFormating(property?.max_rent)}`;
      }
      return `$${getDecimalFormating(property?.min_rent)} -
                $${getDecimalFormating(property?.max_rent)}`;

    case "sale_price":
      if (property?.max_sale)
        return `$${getDecimalFormating(property?.max_sale)}`;
      return "N/A";

    case "opex":
      if (!property?.opex?.length) return "N/A";
      const current_year = new Date().getFullYear();
      const opex_data = [...property?.opex];
      opex_data.sort((a, b) =>
        a?.year > b?.year ? 1 : b.year > a?.year ? -1 : 0
      );
      const current_year_opex = opex_data.find(
        (item) => item?.year === current_year
      );
      if (!!current_year_opex) {
        return `<strong>$${getDecimalFormating(
          current_year_opex?.operating_expenses
        )}</strong> ${
          current_year_opex?.utility_type
            ? `($${getDecimalFormating(current_year_opex?.utility_rate)} for ${
                meta?.opex?.utility_type?.[current_year_opex?.utility_type]
                  ?.value
              })`
            : ""
        }`;
      } else {
        const opex_item = opex_data?.[opex_data?.length - 1];
        return `<strong>$${getDecimalFormating(
          opex_item?.operating_expenses
        )}</strong> (${
          opex_item?.utility_type
            ? `$${getDecimalFormating(opex_item?.utility_rate)} for ${
                meta?.opex?.utility_type?.[opex_item?.utility_type]?.value
              }`
            : ""
        }, ${opex_item?.year})`;
      }
    default:
      if (property?.hasOwnProperty(key)) {
        return property?.[key] || "N/A";
      }
      return "N/A";
  }
};

export const deepArrayClone = (arr: any): any => {
  if (Array.isArray(arr)) {
    return arr.map((item) => deepArrayClone(item));
  } else if (typeof arr === "object" && arr !== null) {
    const newObj: any = {};
    for (const key in arr) {
      newObj[key] = deepArrayClone(arr[key]);
    }
    return newObj;
  } else {
    return arr;
  }
};
