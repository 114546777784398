import type {
  StyledDeliverableValueProps,
  StyledTeamListingValueProps,
} from "ui-molecules";
export const FACET_MAX = 5;
export const SEARCH_RESULT_LIMIT = 10;

interface TdProps {
  className: string;
}

interface TableColumnProps {
  id: StyledDeliverableValueProps["valueKey"];
  label: string;
  sort?: string;
  td?: TdProps;
}

export const TABLE_COLUMNS: TableColumnProps[] = [
  {
    id: "name",
    label: "Building Name",
    sort: "asc",
  },
  {
    id: "type",
    label: "Type",
  },
  {
    id: "company_building_owner_name",
    label: "Landlord",
  },
  {
    id: "size",
    label: "RBA",
  },
  {
    id: "max_space_available",
    label: "Max Contiguous",
  },
  {
    id: "rent_price",
    label: "Quoted Rental Rate",
  },
  {
    id: "opex",
    label: "Opex",
  },
  {
    id: "brochures",
    label: "Brochure"
  }
];
