import { FC, useCallback, useRef } from "react";
import cn from "classnames";
import Label from "../Label";
import { Chip } from "ui-atoms";
import { createReactEditorJS } from "react-editor-js";
import "./editor.css";

const ReactEditorJS = createReactEditorJS();
const version = "2.26.5";

interface IEditor extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  error?: any;
  className?: string;
  optional?: boolean;
  readOnly?: boolean;
  tools?: any;
  blocks?: any;
  onChange?: any;
}

const Editor: FC<IEditor> = ({
  label = "",
  error = "",
  className,
  optional,
  readOnly = false,
  tools,
  blocks = [],
  onChange,
}) => {
  const editorCore = useRef<any>(null);

  const handleInitialize = useCallback((instance: any) => {
    // await instance._editorJS.isReady;
    instance._editorJS.isReady
      .then(() => {
        // set reference to editor
        editorCore.current = instance;
      })
      .catch((err: any) => console.log("An error occured", err));
  }, []);

  const handleSave = useCallback(async () => {
    // retrieve data inserted
    const savedData = await editorCore.current.save();
    // save data
    onChange(savedData);
  }, [onChange]);

  return (
    <div className={cn("relative mb-6", className)}>
      {(label || optional) && (
        <div className="flex flex-row items-center">
          {label ? <Label className="mr-2">{label}</Label> : <span></span>}
          {optional && (
            <Chip
              variant="secondary"
              size="small"
              className="py-1 text-jll-color-coldGray-7"
            >
              Optional
            </Chip>
          )}
        </div>
      )}
      <div
        className={cn("relative rounded-md", {
          "mt-2": !!label,
          "border border-jll-color-coldGray-3 bg-white px-3 py-2 shadow-sm":
            !readOnly,
        })}
      >
        <ReactEditorJS
          onInitialize={handleInitialize}
          defaultValue={
            blocks && {
              blocks: typeof blocks === "string" ? JSON.parse(blocks) : blocks,
              time: new Date().getTime(),
              version,
            }
          }
          tools={tools}
          readOnly={readOnly}
          minHeight={150}
          onChange={handleSave}
        />
      </div>
      {!!error && (
        <p className="absolute -bottom-5 text-xs text-jll-color-surface-accent-default">
          {error}
        </p>
      )}
    </div>
  );
};

export default Editor;
