import Navbar from "ui-molecules/Navbar";
import { LayoutBase } from "ui-organisms";
import AuthHomeNavbar from "./AuthHomeNavbar";

interface IAuthHome {
  children: any;
}

const AuthHome = ({ children }: IAuthHome) => {
  return (
    <LayoutBase>
      <Navbar />
      <div>{children}</div>
    </LayoutBase>
  );
};

AuthHome.Navbar = AuthHomeNavbar;

export default AuthHome;
