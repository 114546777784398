import { StyledActivityValueProps } from "ui-molecules";

export const SEARCH_RESULT_LIMIT = 10;
export const FACET_MAX = 5;

interface TableColumnProps {
  id: StyledActivityValueProps["valueKey"];
  label: string;
  sort?: string;
}

export const ACTIVITY_TABLE_COLUMNS: TableColumnProps[] = [
  {
    id: "building",
    label: "Property",
  },
  {
    id: "type",
    label: "Activity type",
  },
  {
    id: "content",
    label: "Content",
  },

  {
    id: "update_timestamp",
    label: "Updated",
    sort: "asc",
  },
];
