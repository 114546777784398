import queryString from "query-string";
import axios from "../axiosConfig";
import { APIProps } from "types";
import { API_PREFIX_URL } from "constant";

export const getActivitiesAPI = async (props: any) => {
  const param_str = queryString.stringify(props, {
    arrayFormat: "bracket",
  });
  const res = await axios.get(`${API_PREFIX_URL}/activity/?${param_str}`);
  return res;
};

export const getActivityAPI = async (id: string) => {
  const res = await axios.get(`${API_PREFIX_URL}/activity/${id}/`);
  return res;
};

// // TODO: can't put any type for data
// export const putAvailabilityAPI = async (id: number, data: any) => {
//   const res = await axios.put(`${API_PREFIX_URL}/suites/${id}/`, data);
//   return res;
// }

// // TODO: can't put any type for data
// export const patchAvailabilityAPI = async (id: number, data: any) => {
//   const res = await axios.patch(`${API_PREFIX_URL}/suites/${id}/`, data);
//   return res;
// }

// export const postAvailabilityBrokerAPI = async ({
//   role,
//   suite_id,
//   broker_id,
// }: {
//   role: string;
//   suite_id: number;
//   broker_id: number;
// }) => {
//   const res = await axios.post(`${API_PREFIX_URL}/suite/broker/`, {
//     role: role,
//     suite_id,
//     broker_id
//   });
//   return res;
// };

// export const deleteAvailabilityBrokerAPI = async (brokerId: number) => {
//   const res = await axios.delete(`${API_PREFIX_URL}/suite/broker/${brokerId}/`);
//   return res;
// };
