import type { FC } from "react";
import cn from "classnames";

interface IButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant:
    | "primary"
    | "secondary"
    | "ghost"
    | "danger"
    | "ghost-danger"
    | "neutral";
  size?: "small" | "default";
  children?: string;
  className?: string;
  leadingIcon?: any;
  trailingIcon?: any;
  disabled?: boolean;
  leadingIconClass?: string;
  trailingIconClass?: string;
}

const Button: FC<IButtonProps> = ({
  variant = "primary",
  size = "default",
  type = "button",
  children,
  className,
  leadingIcon,
  trailingIcon,
  leadingIconClass,
  trailingIconClass,
  disabled = false,
  ...props
}) => {
  let LeadingIcon;
  if (leadingIcon) {
    LeadingIcon = leadingIcon;
  }
  let TrailingIcon;
  if (trailingIcon) {
    TrailingIcon = trailingIcon;
  }
  let variantClassName =
    " transition-all inline-flex items-center rounded border-transparent";
  if (variant === "primary") {
    variantClassName =
      variantClassName +
      " bg-jll-color-text-base-default text-jll-color-text-base-reversed";
    if (!disabled) {
      variantClassName += " hover:bg-jll-color-coldGray-8";
    }
  } else if (variant === "secondary") {
    variantClassName =
      variantClassName +
      " bg-jll-color-surface-base-secondary text-jll-color-coldGray-10";
    if (!disabled) {
      variantClassName += " hover:bg-jll-color-coldGray-4";
    }
  } else if (variant === "ghost") {
    if (!disabled) {
      variantClassName += " hover:bg-jll-color-coldGray-2";
    }
  } else if (variant === "danger") {
    variantClassName =
      variantClassName +
      " bg-jll-color-surface-accent-default text-jll-color-text-base-reversed ";
  } else if (variant === "ghost-danger") {
    variantClassName =
      variantClassName + " text-jll-color-surface-accent-default";
  } else if (variant === "neutral") {
    if (!disabled) {
      variantClassName +=
        " hover:bg-transparent !px-0 hover:text-jll-color-coldGray-7";
    }
  }

  if (size === "small") {
    variantClassName =
      variantClassName + " h-[28px] px-2.5 text-xs font-medium";
    if (!children) {
      variantClassName += " px-2";
    }
  } else if (size === "default") {
    variantClassName = variantClassName + " h-[36px] px-4 text-sm font-medium";
    if (!children) {
      variantClassName += " px-3";
    }
  }

  if (disabled) {
    variantClassName += " opacity-40 cursor-not-allowed";
  }

  return (
    <button
      type={type}
      className={cn(variantClassName, className)}
      disabled={disabled}
      {...props}
    >
      {leadingIcon && (
        <LeadingIcon
          className={cn(
            "-ml-0.5 mr-2 h-4 w-4",
            { "!mr-0 !-ml-0": !children },
            leadingIconClass
          )}
          aria-hidden="true"
        />
      )}
      {children}
      {trailingIcon && (
        <TrailingIcon
          className={cn(
            "ml-2 -mr-0.5 h-4 w-4",
            { "!ml-0 !-mr-0": !children },
            trailingIconClass
          )}
          aria-hidden="true"
        />
      )}
    </button>
  );
};

export default Button;
