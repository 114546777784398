import type { Orientation } from '@react-pdf/types';

export const PAGE_SIZE = 'A4';
export const PAGE_ORIENTATION = {
  PORTRAIT: 'portrait' as Orientation,
  LANDSCAPE: 'landscape' as Orientation,
}

export const CSS = {
  PADDING: '20px',
  PORTRAIT: {
    WIDTH: '595px',
    HEIGHT: '842px',
  },
  LANDSCAPE: {
    WIDTH: '842px',
    HEIGHT: '595px',
  }
};

export const PDF_SIZE = {
  PORTRAIT: {
    WIDTH: 595,
    HEIGHT: 842,
  },
  LANDSCAPE: {
    WIDTH: 842,
    HEIGHT: 595,
  }
};
