import { StyledTeamListingValueProps } from "ui-molecules";

interface TdProps {
  className: string;
}

interface TableColumnProps {
  id: StyledTeamListingValueProps["valueKey"];
  label: string;
  sort?: string;
  td?: TdProps;
}

export const LINL_TABLE_COLUMNS: TableColumnProps[] = [
  {
    id: "title",
    label: "Title",
    sort: "asc",
  },
  {
    id: "description",
    label: "Description",
  },
  {
    id: "authors",
    label: "Authors",
  },
  {
    id: "breakdown",
    label: "Property Break Down",
  }
];
