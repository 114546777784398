import cn from "classnames";
import { GlobalContext } from "context";
import { useContext, useEffect, useRef, useState } from "react";
import { FiX } from "react-icons/fi";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { convertImageUrl } from "services";
import { Button } from "ui-atoms";
import { convertExtension, lazyLoadImageOnError } from "utils";
import NoImage from "assets/noImage.png";

interface IBuildingPointer {
  index?: number;
  lat?: number;
  lng?: number;
  outClassName?: string;
  inClassName?: string;
  isTooltip?: boolean;
  data?: any;
  setIsOpenPanel?: any;
  setSelectedBuilding?: any;
  openTooltipIndex?: any;
  setOpenTooltipIndex?: any;
}

const Pointer: React.FC<IBuildingPointer> = ({
  index,
  lat,
  lng,
  outClassName = "w-9 h-9",
  inClassName = "w-7 h-7",
  isTooltip,
  data,
  setIsOpenPanel,
  setSelectedBuilding,
  openTooltipIndex,
  setOpenTooltipIndex,
}) => {
  const { state } = useContext(GlobalContext);
  const { showTooltip, hideTooltip } = state;
  const parentRef = useRef<any>(null);
  const nameRef = useRef<any>(null);
  const [disableTooltip, setDisableTooltip] = useState(false);

  useEffect(() => {
    if (parentRef?.current && nameRef?.current && data?.name) {
      if (parentRef?.current?.clientWidth < nameRef?.current?.offsetWidth)
        setDisableTooltip(false);
      else setDisableTooltip(true);
    }
  }, [data, nameRef, parentRef]);

  return (
    <div className="relative">
      {isTooltip && !!data && openTooltipIndex === index && (
        <div className="absolute -top-[125px] -left-[169px] z-20">
          <div className="relative bg-white flex items-center bg-white rounded shadow-lg h-[128px] md:h-[112px] md:w-[352px] md:w-[352px] hidden md:flex after:absolute after:left-1/2 after:-bottom-[10px] after:border-[10px] after:border-solid after:border-x-transparent after:border-b-transparent after:border-b-0 after:border-t-white ">
            <Button
              className="absolute cursor-pointer text-3a3 text-base right-2 top-1 hidden md:block"
              variant="neutral"
              leadingIcon={FiX}
              leadingIconClass="w-4 h-4"
              onClick={() => setOpenTooltipIndex(null)}
            />
            <div className="flex-none relative w-[100px] h-full">
              <LazyLoadImage
                src={!!data?.images?.length ? data?.images[0] : NoImage}
                alt="building-image"
                className="object-cover rounded w-full h-full"
                onError={lazyLoadImageOnError}
              />
            </div>
            <div className="h-full flex flex-col flex-wrap flex-auto items-center justify-around px-3 overflow-hidden">
              <div className="w-full">
                <div className="flex justify-start items-center w-full mb-1">
                  <div
                    className="text-base font-semibold text-jll-color-text-base-default truncate mr-2"
                    ref={parentRef}
                    data-tip={!disableTooltip && data?.name}
                    onMouseEnter={() =>
                      !disableTooltip && showTooltip(parentRef)
                    }
                    onMouseLeave={() =>
                      !disableTooltip && hideTooltip(parentRef)
                    }
                  >
                    <span ref={nameRef}>{data?.title}</span>
                  </div>
                </div>
                <div className="max-w-[238px] text-jll-color-text-base-subdued self-start truncate">
                  {[
                    data?.address,
                    data?.city,
                    data?.state,
                    data?.post_code,
                  ].join(", ")}
                </div>
              </div>
              <div className="flex items-center justify-between w-full">
                <div className="flex items-center self-start w-4/5">
                  <Button
                    variant="primary"
                    size="small"
                    onClick={() => {
                      setIsOpenPanel(true);
                      setSelectedBuilding({
                        building: data,
                        index: Number(index) + 1,
                      });
                    }}
                  >
                    See more
                  </Button>
                </div>
                <Button
                  leadingIcon={FiX}
                  variant="neutral"
                  leadingIconClass="w-4 h-4"
                  onClick={() => setOpenTooltipIndex(null)}
                  className="w-[44px] h-[44px] flex items-center justify-center bg-jll-color-text-base-default cursor-pointer text-606 rounded-md text-base ml-3 md:hidden"
                />
              </div>
            </div>
          </div>
        </div>
      )}
      <div
        className={cn(
          "bg-white rounded-full p-1 flex shadow-pointer cursor-pointer",
          outClassName
        )}
        onClick={() => setOpenTooltipIndex(index)}
      >
        <div
          className={cn(
            "flex justify-center items-center bg-jll-color-icon-info rounded-full",
            inClassName
          )}
        >
          <span className="text-sm font-semibold text-white">{Number(index) + 1}</span>
        </div>
      </div>
    </div>
  );
};

export default Pointer;
