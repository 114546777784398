import {
  useContext,
  type FC,
  type FocusEvent,
  forwardRef,
  useImperativeHandle,
} from "react";
import { FiPlus, FiTrash } from "react-icons/fi";
import { Button, Input } from "ui-atoms";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { RxDragHandleHorizontal } from "react-icons/rx";
import { reorder } from "utils";
import cn from "classnames";
import ReactDOM from "react-dom";
import { GlobalContext } from "context";

interface HighlightProps {
  label: string;
  onChange: (value: string[]) => void;
  onBlur: (e: FocusEvent<HTMLInputElement, Element>) => void;
  name: string;
  value?: string[];
  touched?: any;
  errors?: any;
  isModal?: boolean;
}

const Highlight = forwardRef(
  (
    {
      label,
      name = "",
      onChange,
      onBlur,
      value = [],
      errors,
      touched,
      isModal,
    }: HighlightProps,
    ref
  ) => {
    const { state } = useContext(GlobalContext);
    const { portalRef } = state;

    const handleAdd = () => {
      let updatedItems = value ? [...value, ""] : [""];
      onChange(updatedItems);
    };

    const handleRemove = (index: number) => {
      let updatedValue: string[] = [...value].filter((_, i) => i !== index);
      onChange(updatedValue);
    };

    const handleUpdate = (index: number, newValue: string) => {
      let updatedItems = [...value].map((x: string, x_index) =>
        x_index === index ? newValue : x
      );
      onChange(updatedItems);
    };

    const onDragEnd = (result: any) => {
      if (!result.destination) {
        return;
      }
      const newOrder = reorder(
        [...value],
        result.source.index,
        result.destination.index
      );
      onChange(newOrder);
    };

    useImperativeHandle(ref, () => ({
      handleAdd,
    }));

    if (!value?.length && !isModal) {
      return (
        <div className="w-full">
          <Button variant="secondary" leadingIcon={FiPlus} onClick={handleAdd}>
            {`Add ${label.toLowerCase()}`}
          </Button>
        </div>
      );
    }

    return (
      <div className="w-full">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="highlight-droppable">
            {(provided, snapshot) => {
              return (
                <div
                  className={cn({
                    "mb-6": !isModal,
                    "pb-[62px]": snapshot?.isDraggingOver,
                  })}
                  {...provided.droppableProps}
                  ref={provided?.innerRef}
                >
                  {value?.map((item: string, index: number) => (
                    <Draggable
                      key={index}
                      draggableId={`highlight-item-${index}`}
                      index={index}
                    >
                      {(provided, snapshot) => {
                        const usePortal: boolean = snapshot.isDragging;
                        const child = (
                          <div
                            className={cn("flex space-x-2")}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                          >
                            <div
                              {...provided.dragHandleProps}
                              className="flex justify-center items-center w-8 h-9"
                            >
                              <RxDragHandleHorizontal className="text-xl mr-2" />
                            </div>
                            <div className="w-8 h-9 flex flex-row items-center">
                              <span>{index + 1}</span>
                            </div>
                            <div className="w-full flex items-center">
                              <Input
                                name={`${name}[${index}]`}
                                value={item}
                                placeholder="Description"
                                onChange={(e: any) =>
                                  handleUpdate(index, e.target.value)
                                }
                                onBlur={onBlur}
                                className="w-full"
                                error={
                                  touched?.[name]?.[index]
                                    ? errors?.[name]?.[index]
                                    : ""
                                }
                              />
                            </div>
                            <div className="w-[100px] h-9 flex items-center justify-end">
                              <Button
                                variant="neutral"
                                size="small"
                                leadingIcon={FiTrash}
                                className="text-jll-color-stroke-danger"
                                onClick={() => handleRemove(index)}
                              >
                                Delete
                              </Button>
                            </div>
                          </div>
                        );
                        if (!usePortal) {
                          return child;
                        }
                        return ReactDOM.createPortal(child, portalRef?.current);
                      }}
                    </Draggable>
                  ))}
                </div>
              );
            }}
          </Droppable>
        </DragDropContext>
        {!isModal && (
          <Button variant="secondary" leadingIcon={FiPlus} onClick={handleAdd}>
            {`Add ${label.toLowerCase()}`}
          </Button>
        )}
      </div>
    );
  }
);

export default Highlight;
