import {
  SET_META,
  SET_TOOLTIP,
  SET_PROPERTIES,
  SET_PROPERTY,
  SET_AVAILABILITIES,
  SET_AVAILABILITY,
  SET_BROKERS,
  SET_PAGE_ACTIVITIES_FILTERS,
  SET_PAGE_PROPERTIES_FILTERS,
  SET_PAGE_BROKERS_FILTERS,
  SET_PAGE_AVAILABILITIES_FILTERS,
  SET_PAGE_USERS_FILTERS,
  CREATE_LIGHTGALLERY,
  UPDATE_LIGHTGALLERY_DATA,
  SET_ERROR_MODAL,
  SET_CONFIRM_MODAL,
  SET_LOADING_MODAL,
  SET_PERMISSION_MODAL,
  SET_RIGHT_CONTEXT,
  SET_EVENT_MODAL,
  SET_INLINE_EDIT_SELECT_MODAL,
  SET_INLINE_EDIT_HIGHLIGHT_MODAL,
  SET_PORTAL,
  SET_INLINE_EDIT_AMENITY_MODAL,
  SET_TEAM_LISTING,
  SET_PROPERTY_REMOVE_CONFIRM_MODAL,
} from "constant";
import { useReducer, createContext } from "react";
import { PropertyProps, AvailabilityProps, BrokerProps } from "types";

interface StateProps {
  showTooltip: any | null;
  hideTooltip: any | null;
  properties: PropertyProps[] | null;
  property: PropertyProps | null;
  availabilities: AvailabilityProps[] | null;
  availability: AvailabilityProps | null;
  brokers: BrokerProps[] | null;
  pagePropertiesFilters: any;
  pageAvailabilitiesFilters: any;
  pageBrokersFilters: any;
  pageActivitiesFilters: any;
  pageUsersFilters: any;
  meta: any;
  lightGalleryData: any;
  lightGalleryRef: any;

  rightContextId: string;
  rightContextFunc: any;
}

const initialState: any = {
  showTooltip: null,
  hideTooltip: null,
  properties: null,
  property: null,
  availabilities: null,
  availability: null,
  brokers: null,
  pagePropertiesFilters: null,
  pageAvailabilitiesFilters: null,
  pageBrokersFilters: null,
  pageActivitiesFilters: null,
  pageUsersFilters: null,
  meta: null,
  lightGalleryData: [],
  lightGalleryRef: null,

  errorModalOpen: false,
  errorModalContent: null,

  confirmModalOpen: false,
  // title, delete action func, description, icon
  confirmModalContent: null,

  loadingModalOpen: false,
  loadingModalLabel: null,

  permissionModalOpen: false,
  permissionModalContent: null,

  rightContextId: null,
  rightContextFunc: null,

  // Property Remove Confirm
  propertyRemoveConfirmOpen: false,
  propertyRemoveConfirmContent: null,

  eventModalOpen: false,
  eventModalContent: null,
  // Inline Edit Select Modal
  inlineSelectModalOpen: false,
  inlineSelectModalContent: null,

  // Inline Edit Highlight Modal
  inlineHighlightModalOpen: false,
  inlineHighlightModalContent: null,

  // Inline Edit Amenity Modal
  inlineAmenityModalOpen: false,
  inlineAmenityModalContent: null,

  portalRef: null,

  teamListing: null,
};

export const GlobalContext = createContext(initialState);

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case SET_META:
      return {
        ...state,
        meta: action?.payload,
      };
    case SET_PORTAL:
      return {
        ...state,
        portalRef: action?.payload,
      };
    case SET_PROPERTIES:
      return {
        ...state,
        properties: action?.payload,
      };
    case SET_PROPERTY:
      return {
        ...state,
        property: action?.payload,
      };
    case SET_AVAILABILITY:
      return {
        ...state,
        availability: action?.payload,
      };
    case SET_PAGE_ACTIVITIES_FILTERS:
      return {
        ...state,
        pageActivitiesFilters: action?.payload,
      };
    case SET_PAGE_PROPERTIES_FILTERS:
      return {
        ...state,
        pagePropertiesFilters: action?.payload,
      };
    case SET_PAGE_BROKERS_FILTERS:
      return {
        ...state,
        pageBrokersFilters: action?.payload,
      };
    case SET_PAGE_AVAILABILITIES_FILTERS:
      return {
        ...state,
        pageAvailabilitiesFilters: action?.payload,
      };
    case SET_PAGE_USERS_FILTERS:
      return {
        ...state,
        pageUsersFilters: action?.payload,
      };
    case SET_AVAILABILITIES:
      return {
        ...state,
        availabilities: action?.payload,
      };
    case SET_BROKERS:
      return {
        ...state,
        brokers: action?.payload,
      };
    case SET_TOOLTIP:
      return {
        ...state,
        showTooltip: action?.payload?.showTooltip,
        hideTooltip: action?.payload?.hideTooltip,
      };
    case UPDATE_LIGHTGALLERY_DATA:
      return {
        ...state,
        lightGalleryData: action.payload,
      };
    case CREATE_LIGHTGALLERY:
      return {
        ...state,
        lightGalleryRef: action.payload,
      };
    case SET_ERROR_MODAL:
      return {
        ...state,
        errorModalOpen: action.payload?.open,
        errorModalContent: action.payload?.content,
      };
    case SET_CONFIRM_MODAL:
      return {
        ...state,
        confirmModalOpen: action.payload?.open,
        confirmModalContent: action.payload?.content,
      };
    case SET_LOADING_MODAL:
      return {
        ...state,
        loadingModalOpen: action.payload?.open,
        loadingModalLabel: action.payload?.label,
      };
    case SET_PERMISSION_MODAL:
      return {
        ...state,
        permissionModalOpen: action.payload?.open,
        permissionModalContent: action.payload?.content,
      };
    case SET_RIGHT_CONTEXT:
      return {
        ...state,
        rightContextId: action?.payload?.id,
        rightContextFunc: action?.payload?.func,
      };
    case SET_PROPERTY_REMOVE_CONFIRM_MODAL:
      return {
        ...state,
        propertyRemoveConfirmOpen: action.payload?.open,
        propertyRemoveConfirmContent: action.payload?.content,
      };
    case SET_EVENT_MODAL:
      return {
        ...state,
        eventModalOpen: action.payload?.open,
        eventModalContent: action.payload?.content,
      };
    case SET_INLINE_EDIT_SELECT_MODAL:
      return {
        ...state,
        inlineSelectModalOpen: action.payload?.open,
        inlineSelectModalContent: action.payload?.content,
      };
    case SET_INLINE_EDIT_HIGHLIGHT_MODAL:
      return {
        ...state,
        inlineHighlightModalOpen: action.payload?.open,
        inlineHighlightModalContent: action.payload?.content,
      };
    case SET_INLINE_EDIT_AMENITY_MODAL:
      return {
        ...state,
        inlineAmenityModalOpen: action.payload?.open,
        inlineAmenityModalContent: action.payload?.content,
      };
    case SET_TEAM_LISTING:
      return {
        ...state,
        teamListing: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export const GlobalContextProvider = (props: any) => {
  const [state, dispatch] = useReducer<any>(reducer, initialState);
  return (
    <GlobalContext.Provider value={{ state, dispatch }}>
      {props.children}
    </GlobalContext.Provider>
  );
};
