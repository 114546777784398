import { getFormatedDateTime, getFormatedFromNow, joinTwoStr } from "utils";

interface IActivityDate {
  date: string;
}
const ActivityDate: React.FC<IActivityDate> = ({ date }) => {
  return (
    <p className="text-jll-color-coldGray-7 text-xs">
      {joinTwoStr(getFormatedDateTime(date), getFormatedFromNow(date), " | ")}
    </p>
  );
};

export default ActivityDate;
