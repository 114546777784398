import type { FC } from "react";
import cn from "classnames";
import Label from "../Label";
import Chip from "ui-atoms/Chip";
import { FiX } from "react-icons/fi";

interface IInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  type?: "text" | "email" | "number";
  area?: "SQ FT." | "Acres" | "";
  currency?: string;
  month?: boolean;
  error?: any;
  className?: string;
  optional?: boolean;
  leadingIcon?: any;
  trailingIcon?: any;
  onTrailingClick?: any;
  trailing?: any;
  isClearable?: boolean;
  name: string;
  onChange?: any;
}

const Input: FC<IInputProps> = ({
  label = "",
  type = "text",
  error = "",
  className,
  currency,
  area,
  month,
  optional,
  leadingIcon,
  trailingIcon,
  onTrailingClick,
  isClearable,
  trailing,
  ...props
}) => {
  let LeadingIcon;
  if (leadingIcon) {
    LeadingIcon = leadingIcon;
  }
  let TrailingIcon;
  if (trailingIcon) {
    TrailingIcon = trailingIcon;
  }
  return (
    <div className={cn("relative mb-6", className)}>
      {(label || optional) && (
        <div className="flex flex-row items-center">
          {label ? (
            <Label htmlFor={props.id} className="mr-2">
              {label}
            </Label>
          ) : (
            <span></span>
          )}
          {optional && (
            <Chip
              variant="secondary"
              size="small"
              className="py-1 text-jll-color-coldGray-7"
            >
              Optional
            </Chip>
          )}
        </div>
      )}
      <div className={cn("relative rounded-md shadow-sm", { "mt-2": !!label })}>
        {leadingIcon && !currency && !area && !month && (
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <LeadingIcon className="h-5 w-5 text-gray-400" />
          </div>
        )}
        {currency && (
          <div
            className={cn(
              "pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3 text-jll-color-coldGray-5"
            )}
          >
            $
          </div>
        )}
        <input
          className={cn(
            "block w-full rounded focus:border-jll-color-coldGray-7 focus:ring-jll-color-coldGray-7 sm:text-sm text-jll-color-text-base-default disabled:bg-jll-color-coldGray-2",
            {
              "pl-10": !!leadingIcon || currency,
              "pr-10": !!trailingIcon || !!trailing,
              "pr-12": currency,
              "pr-16": area || month,
              "border-jll-color-surface-accent-default": !!error,
              "border-jll-color-coldGray-4": !error,
            }
          )}
          type={type}
          {...props}
        />
        {trailingIcon && !currency && !area && !month && (
          <div
            className={cn("absolute inset-y-0 right-0 flex items-center pr-3", {
              "cursor-pointer": !!onTrailingClick,
              "pointer-events-none": !onTrailingClick,
            })}
            onClick={!!onTrailingClick ? onTrailingClick : undefined}
          >
            <TrailingIcon className="h-5 w-5 text-gray-400" />
          </div>
        )}
        {isClearable && !!props?.value?.toString()?.length && (
          <div
            className="cursor-pointer absolute inset-y-0 right-0 flex items-center pr-3"
            onClick={() => props?.onChange({ target: { value: "" } })}
          >
            <FiX className="h-5 w-5 text-jll-color-text-base-default" />
          </div>
        )}
        {currency && (
          <div
            className={cn(
              "pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3 text-jll-color-coldGray-5"
            )}
          >
            {currency}
          </div>
        )}
        {area && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            {area}
          </div>
        )}
        {month && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            Months
          </div>
        )}
        {trailing && (
          <div
            className={cn(
              "pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3 text-jll-color-coldGray-5"
            )}
          >
            {trailing}
          </div>
        )}
      </div>
      {!!error && (
        <p className="absolute -bottom-5 text-xs text-jll-color-surface-accent-default">
          {error}
        </p>
      )}
    </div>
  );
};

export default Input;
