import {
  OLM_AVAILABILITY_FIELDS,
  OLM_PROPERTY_FIELDS,
  SET_CONFIRM_MODAL,
  SET_LOADING_MODAL,
  STATUS_ACTIVE,
} from "constant";
import { GlobalContext } from "context";
import { useApiCall } from "hooks";
import { useContext, useEffect, useState } from "react";
import { renderToString } from "react-dom/server";
import { useDropzone } from "react-dropzone";
import { FiTrash, FiUpload } from "react-icons/fi";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { patchAvailabilityAPI, patchPropertyAPI, postImageAPI } from "services";
import { RemoveDescriptionItem } from "ui-molecules";
import {
  capitalizeWords,
  convertExtension,
  isCommonItem,
  lazyLoadImageOnError,
  ordinalSuffixOf,
} from "utils";

interface IEditableFileField {
  initValues?: any;
  valueKeys: string[];
  pk?: string | number;
  children?: any;
  updateItem?: any;
  kind: "property" | "availability";
  isEditMode?: boolean;
}

interface IFileItem {
  value?: any;
  valueKey: string;
  handleRemove?: any;
  index: number;
}

const FileItem: React.FC<IFileItem> = ({
  value,
  handleRemove,
  valueKey,
  index,
}) => {
  return (
    <div className="flex flex-row items-center justify-between py-[5px] space-x-[30px]">
      <div className="flex flex-row items-center truncate">
        <LazyLoadImage
          src={convertExtension(value)}
          className="w-5 h-5 mr-2 rounded"
          onError={lazyLoadImageOnError}
        />
        <a
          href={value}
          className="text-jll-color-surface-info-default max-w-[220px] truncate mr-2 cursor-pointer"
          target="_blank"
          rel="noreferrer"
        >
          {value}
        </a>
      </div>
      <div className="flex flex-row items-center w-fit">
        <div className="pl-2 border-l border-black border-opacity-10">
          <FiTrash
            className="w-4 h-4 text-jll-color-text-danger cursor-pointer"
            onClick={() => handleRemove(valueKey, index)}
          />
        </div>
      </div>
    </div>
  );
};

const EditableFileField: React.FC<IEditableFileField> = ({
  initValues,
  valueKeys,
  pk,
  children,
  updateItem,
  kind,
  isEditMode,
}) => {
  const { dispatch } = useContext(GlobalContext);
  const [patchProperty] = useApiCall(patchPropertyAPI);
  const [patchAvailability] = useApiCall(patchAvailabilityAPI);
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    setFileList(initValues?.[valueKeys[0]] || []);
  }, [initValues]);

  const handleSubmitFile = async (name: string, files: any) => {
    if (files?.length <= 0) return;
    dispatch({
      type: SET_LOADING_MODAL,
      payload: {
        open: true,
        label: "Uploading files",
      },
    });
    try {
      let results: any[] = [];
      await Promise.all(
        files?.map(async (file: any) => {
          const result = await postImageAPI(file);
          results.push(result);
        })
      );
      if (!!results?.length) {
        const data: any = [
          ...(fileList ? (!Array.isArray(fileList) ? [] : fileList) : []),
          ...results,
        ];
        let service;

        let payload: any = {
          [name]: data,
        };
        if (kind === "property") {
          service = patchProperty;
          if (
            payload &&
            initValues?.publish_status === STATUS_ACTIVE &&
            isCommonItem(Object.keys(payload), OLM_PROPERTY_FIELDS)
          ) {
            payload["publish_status"] = STATUS_ACTIVE;
          }
        } else {
          service = patchAvailability;
          if (
            payload &&
            initValues?.publish_status === STATUS_ACTIVE &&
            isCommonItem(Object.keys(payload), OLM_AVAILABILITY_FIELDS)
          ) {
            payload["publish_status"] = STATUS_ACTIVE;
          }
        }
        const response = await service({
          id: Number(pk),
          payload,
        });
        if (!!response) {
          setFileList(data);
          updateItem({ [valueKeys[0]]: data }, pk);
        }
      }
      setTimeout(() => {
        dispatch({
          type: SET_LOADING_MODAL,
          payload: {
            open: false,
            label: null,
          },
        });
      }, 100);
    } catch (err) {
      setTimeout(() => {
        dispatch({
          type: SET_LOADING_MODAL,
          payload: {
            open: false,
            label: null,
          },
        });
      }, 100);
    }
  };

  const onDrop = (acceptedFiles: any[]) => {
    handleSubmitFile(valueKeys[0], [...acceptedFiles]);
  };

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 5,
    onDrop,
    multiple: true,
    // ...acceptType,
  });

  const removeValue = async (name: string, index: number) => {
    const action = () => {
      const newValue = [...fileList];
      newValue.splice(index, 1);

      let payload: any = {
        [name]: newValue,
      };
      let service;
      if (kind === "property") {
        service = patchProperty;
        if (
          payload &&
          initValues?.publish_status === STATUS_ACTIVE &&
          isCommonItem(Object.keys(payload), OLM_PROPERTY_FIELDS)
        ) {
          payload["publish_status"] = STATUS_ACTIVE;
        }
      } else if (kind === "availability") {
        service = patchAvailability;
        if (
          payload &&
          initValues?.publish_status === STATUS_ACTIVE &&
          isCommonItem(Object.keys(payload), OLM_AVAILABILITY_FIELDS)
        ) {
          payload["publish_status"] = STATUS_ACTIVE;
        }
      }
      service({
        id: Number(pk),
        payload,
      }).then(() => {
        setFileList(newValue);
        updateItem({ [valueKeys[0]]: newValue }, pk);
      });
    };

    dispatch({
      type: SET_CONFIRM_MODAL,
      payload: {
        open: true,
        content: {
          description: renderToString(
            <RemoveDescriptionItem
              target={`${ordinalSuffixOf(index + 1)} Item in ${capitalizeWords(
                name,
                "_"
              )}`}
            />
          ),
          action,
        },
      },
    });
  };

  return (
    <>
      {isEditMode ? (
        <div
          onClick={(e: any) => {
            e.stopPropagation();
          }}
        >
          <div
            {...getRootProps({
              className:
                "w-full flex h-full cursor-pointer bg-white border border-dashed border-jll-color-coldGray-3 rounded justify-center items-center py-2 px-10",
            })}
          >
            <input {...getInputProps()} />
            <div className="flex items-center flex-col">
              <div className="flex flex-row items-center text-jll-color-text-base-default text-xs font-semibold">
                <FiUpload className="w-3 h-3 mr-1" />
                Drag and drop files here or upload
              </div>
              <p className="text-jll-color-coldGray-4 text-xs">
                Accepted file types: JPEG, Doc, PDF, PNG
              </p>
            </div>
          </div>
          <div className="max-h-[120px] overflow-y-auto pr-2">
            {!!fileList?.length &&
              fileList?.map((item: any, index: number) => (
                <FileItem
                  key={index}
                  value={item}
                  handleRemove={removeValue}
                  valueKey={valueKeys[0]}
                  index={index}
                />
              ))}
          </div>
        </div>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

export default EditableFileField;
