import type { StyledBrokerValueProps } from "ui-molecules";

interface TdProps {
  className: string;
}

interface TableColumnProps {
  id: StyledBrokerValueProps["valueKey"];
  label: string;
  sort?: string;
  td?: TdProps;
}

export const BROKERS_TABLE_COLUMNS: TableColumnProps[] = [
  {
    id: "name",
    label: "Name",
  },
  {
    id: "page_url",
    label: "Agent Profile",
  },
  {
    id: "city",
    label: "City",
  },
  {
    id: "department",
    label: "Department",
  },
];
