import { LazyLoadComponent } from "react-lazy-load-image-component";
import GoogleMapReact from "google-map-react";
import { FiMapPin } from "react-icons/fi";
import { useEffect, useState } from "react";
import { Pointer } from "ui-atoms";

interface IPosition {
  lat: number;
  lng: number;
  [key: string]: any;
}

interface IGoogleMap {
  position?: IPosition;
  className?: string;
  zoom?: number;
  locations?: IPosition[];
  isTooltip?: boolean;
  setIsOpenPanel?: any;
  setSelectedBuilding?: any;
}

interface IMarker {
  lng: number;
  lat: number;
}

const Marker: React.FC<IMarker> = ({ lng, lat }) => (
  <div className="w-9 h-9 flex justify-center items-center bg-white rounded-full">
    <FiMapPin className="w-4 h-4 text-jll-color-icon-info" />
  </div>
);

const GoogleMap: React.FC<IGoogleMap> = ({
  position,
  className,
  zoom = 11,
  locations,
  isTooltip,
  setIsOpenPanel,
  setSelectedBuilding,
}) => {
  const [googleApiObj, setIsGoogleApiLoadedObj] = useState<any>(null);
  const [centerPosition, setCenterPosition] = useState({
    lat: 30.268843,
    lng: -97.74821,
  });
  const [openTooltipIndex, setOpenTooltipIndex] = useState<any>(null);

  const getMapBounds = (map: any, maps: any, places: any) => {
    const bounds = new maps.LatLngBounds();
    places.forEach((place: { lat: number; lng: number }) => {
      bounds.extend(new maps.LatLng(place?.lat, place?.lng));
    });
    return bounds;
  };

  const bindResizeListener = (map: any, maps: any, bounds: any) => {
    map.addListener(
      "idle",
      () => {
        window.addEventListener("resize", () => {
          map.fitBounds(bounds);
        });
      },
      { once: true }
    );
  };

  const isLoaded = (map: any, maps: any, places: any) => {
    if (places?.length == 0 || !places) return;
    const bounds = getMapBounds(map, maps, places);
    map.fitBounds(bounds);
    bindResizeListener(map, maps, bounds);
  };

  useEffect(() => {
    if (position) {
      setCenterPosition(position);
    }
  }, [position]);

  useEffect(() => {
    if (googleApiObj) {
      setOpenTooltipIndex(null);
      const { map, maps } = googleApiObj;
      // or else call that isApiLoaded function and pass-on these arguments
      map.setTilt(0);
      if (locations && locations?.length > 0) isLoaded(map, maps, locations);
    }
  }, [googleApiObj, locations]);

  return (
    <div className={`w-full h-[200px] ${className}`}>
      <LazyLoadComponent>
        <GoogleMapReact
          bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAP_KEY || "" }}
          // center={position}
          center={centerPosition}
          defaultZoom={zoom}
          options={(maps: any) => {
            return { zoomControl: true };
          }}
          yesIWantToUseGoogleMapApiInternals={true}
          onGoogleApiLoaded={({ map, maps }) => {
            // If not api loaded we till want to check notable locations because we need to fit bounds.
            setIsGoogleApiLoadedObj({ map, maps });
          }}
        >
          {position && <Marker lat={position.lat} lng={position.lng} />}
          {!!locations?.length &&
            locations?.map((location, idx) => (
              <Pointer
                key={idx}
                index={idx}
                lng={location?.lng}
                lat={location?.lat}
                data={location}
                isTooltip
                setIsOpenPanel={setIsOpenPanel}
                setSelectedBuilding={setSelectedBuilding}
                openTooltipIndex={openTooltipIndex}
                setOpenTooltipIndex={setOpenTooltipIndex}
              />
            ))}
        </GoogleMapReact>
      </LazyLoadComponent>
    </div>
  );
};

export default GoogleMap;
