import { PUBLISH_LOG_RESULT_LIMIT, PUBLISH_LOG_TYPES } from "constant/publish";
import { GlobalContext } from "context";
import { useApiCall } from "hooks";
import {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { getActivitiesAPI } from "services";
import { ActivityProps } from "types";
import { Label, Loading } from "ui-atoms";
import { getContactTypeId, getFormatedDateTime, joinTwoStr } from "utils";
import { LayoutEditProperty as Layout } from "../../ui-organisms";
import { AccordionItem } from "ui-molecules";

interface IPublishLogList {
  type: "building" | "suite";
  objectId?: string | number;
}

const PublishLogList = forwardRef(
  ({ type, objectId }: IPublishLogList, ref) => {
    const { state } = useContext(GlobalContext);
    const { meta } = state;
    const [getActivities] = useApiCall(getActivitiesAPI);

    const [activities, setActivities] = useState<ActivityProps[]>([]);
    const [page, setPage] = useState(0);
    const [total, setTotal] = useState(0);

    const loadData = () => {
      getActivities({
        page: 1,
        limit: PUBLISH_LOG_RESULT_LIMIT,
        content_type: getContactTypeId(meta, type),
        type: PUBLISH_LOG_TYPES,
        object_id: [objectId],
      }).then((data: any) => {
        setActivities([...data?.docs]);
        setPage(data?.page);
        setTotal(data?.total);
      });
    };

    useEffect(() => {
      loadData();
    }, []);

    const loadMore = () => {
      const nextPage = page + 1;
      setPage(nextPage);
      getActivities({
        page: nextPage,
        limit: PUBLISH_LOG_RESULT_LIMIT,
        content_type: getContactTypeId(meta, type),
        type: PUBLISH_LOG_TYPES,
        object_id: [objectId],
      }).then((data: any) => {
        setActivities((prevData: any) => [...prevData, ...data?.docs]);
        setPage(data?.page);
        setTotal(data?.total);
      });
    };

    const [sentryRef] = useInfiniteScroll({
      loading: false,
      hasNextPage: total > activities?.length,
      onLoadMore: loadMore,
    });

    useImperativeHandle(ref, () => ({
      loadData,
    }));

    return activities?.length ? (
      <Layout.Section>
        <AccordionItem id="log" label="Publish Log">
          <div className="flex flex-col w-full pt-8">
            <div className="flex flex-col bg-jll-color-coldGray-1 px-8 py-4 divide-jll-color-coldGray-3 divide-y rounded-lg">
              {activities?.map((activity, idx) => (
                <div
                  className="flex items-center justify-between py-3"
                  key={idx}
                >
                  <div className="">
                    <span className="text-jll-color-text-base-default font-semibold">
                      {activity?.type_name}
                    </span>
                    {activity?.event_reason_name && (
                      <p className="text-jll-color-text-base-default text-sm">
                        <span className="font-medium mr-1">Reason:</span>
                        {activity?.event_reason_name}
                      </p>
                    )}
                  </div>
                  <div className="flex flex-col items-end">
                    <span className="text-jll-color-text-base-default font-semibold  text-sm flex">
                      {activity?.update_timestamp &&
                        getFormatedDateTime(activity?.update_timestamp, {
                          dateStyle: "long",
                        })}
                    </span>
                    <span className="text-jll-color-text-base-default text-sm flex">
                      {joinTwoStr(
                        activity?.first_name,
                        activity?.last_name,
                        " "
                      )}
                    </span>
                  </div>
                </div>
              ))}
              {!!(total > activities?.length) && (
                <div
                  className="w-full py-2 flex justify-center items-center"
                  ref={sentryRef}
                >
                  <Loading />
                </div>
              )}
            </div>
          </div>
        </AccordionItem>
      </Layout.Section>
    ) : (
      <div />
    );
  }
);

export default PublishLogList;
