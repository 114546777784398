import queryString from "query-string";
import { useApiCall } from "hooks";
import { useEffect, useMemo, useState } from "react";
import { FiSearch } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router-dom";
import { getActivitiesAPI } from "services";
import { Input, Loading } from "ui-atoms";
import { SEARCH_RESULT_LIMIT } from "./constants";
import { ActivityProps } from "types";
import useInfiniteScroll from "react-infinite-scroll-hook";
import debounce from "lodash.debounce";
import { ActivityItem, BrokerDetailModal } from "ui-molecules";
import React from "react";

const MyActivity = () => {
  const [getActivities, loading] = useApiCall(getActivitiesAPI);
  const location = useLocation();
  const session = JSON.parse(sessionStorage.getItem("session") || "null");
  const navigate = useNavigate();
  const [oldParam, setOldParam] = useState<any>(null);
  const [paramFilters, setParamFilters] = useState<any>(null);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [keyword, setKeyword] = useState<string>();
  const [data, setData] = useState<any>();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedBrokerId, setSelectedBrokerId] = useState<number>();

  const loadMore = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    getActivities({
      page: nextPage,
      limit: SEARCH_RESULT_LIMIT,
      ...getFilterParam(),
    }).then((res: any) => {
      setData((prevData: any) => [...prevData, ...res?.docs]);
      setPage(res?.page);
      setTotal(res?.total);
    });
  };

  const [sentryRef] = useInfiniteScroll({
    loading: false,
    hasNextPage: total > data?.length,
    onLoadMore: loadMore,
  });

  const getFilterParam = () => {
    let param = { user: [session?.id] };
    if (paramFilters?.activity_keyword) {
      return { keyword: paramFilters?.activity_keyword, ...param };
    }
    return { ...param };
  };

  useEffect(() => {
    const filters = queryString.parse(location?.search, {
      arrayFormat: "bracket",
    });
    setKeyword(filters?.activity_keyword?.toString() || "");
    setOldParam(filters);
    if (!filters?.activity_keyword) return;
    if (
      filters?.activity_keyword &&
      oldParam?.activity_keyword === filters?.activity_keyword
    )
      return;
    setParamFilters(filters);
  }, [location]);

  useEffect(() => {
    setPage(1);
    getActivities({
      page: 1,
      limit: SEARCH_RESULT_LIMIT,
      ...getFilterParam(),
    }).then((res: any) => {
      setData(res?.docs);
      setPage(res?.page);
      setTotal(res?.total);
    });
  }, []);

  useEffect(() => {
    if (paramFilters === null) return;
    if (paramFilters?.activity_keyword)
      setKeyword(paramFilters?.activity_keyword);
    setPage(1);
    if (!session) return;
    setData([]);
    getActivities({
      page: 1,
      limit: SEARCH_RESULT_LIMIT,
      ...getFilterParam(),
    }).then((res: any) => {
      setData(res?.docs);
      setPage(res?.page);
      setTotal(res?.total);
    });
  }, [paramFilters]);

  const updateKeywordFilter = (e: any) => {
    const filters = queryString.parse(location?.search);
    const updatedFilters = { ...filters };
    if (e.target.value) {
      updatedFilters["activity_keyword"] = e.target.value;
    } else if (updatedFilters.hasOwnProperty("activity_keyword")) {
      delete updatedFilters.activity_keyword;
    }
    navigate(
      `${location.pathname}?${queryString.stringify(updatedFilters, {
        arrayFormat: "bracket",
      })}`
    );
  };

  const debounceUpdateKeyword = useMemo(
    () => debounce(updateKeywordFilter, 300),
    [paramFilters]
  );

  const onChangeInput = (e: any) => {
    setKeyword(e.target.value);
    debounceUpdateKeyword(e);
  };

  return (
    <div className="min-h-[calc(100vh-72px)] flex flex-col">
      <div className="pr-8 mb-4">
        <h4 className="text-jll-color-text-base-default font-semibold text-xl mb-4">
          My activity
        </h4>
        <Input
          className="!mb-0"
          name="search"
          leadingIcon={FiSearch}
          onChange={onChangeInput}
          isClearable
          value={keyword}
          placeholder="Search activity"
        />
      </div>
      <div className="mr-4 pr-4 h-full overflow-y-auto divide-y divide-jll-color-stroke-base-default">
        {!!data?.length &&
          data?.map((item: ActivityProps, idx: number) => (
            <ActivityItem
              activeItem={item}
              key={idx}
              setOpenBrokerModal={setIsOpen}
              setBrokerId={setSelectedBrokerId}
            />
          ))}
        {!!(total > data?.length) && (
          <div
            className="w-full py-2 flex justify-center items-center"
            ref={sentryRef}
          >
            <Loading />
          </div>
        )}
      </div>
      <BrokerDetailModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        brokerId={selectedBrokerId}
      />
    </div>
  );
};

export default React.memo(MyActivity);
