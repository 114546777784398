import { useContext, useEffect, useRef, useState } from "react";
import { Tooltip } from "react-tooltip";
import RootRoutes from "routes/RootRoutes";
import { ToastContainer } from "react-toastify";
import { GlobalContext } from "context";
import { CREATE_LIGHTGALLERY, SET_PORTAL, SET_TOOLTIP } from "constant";
import "react-tooltip/dist/react-tooltip.css";
import "react-toastify/dist/ReactToastify.css";
import {
  ErrorModal,
  InlineAmenityModal,
  InlineHighlightModal,
  InlineSelectModal,
  LoadingModal,
  PermissionModal,
  EventModal,
  PropertyRemoveModal,
  LightGallery,
} from "ui-molecules";
import { ContextMenu, MenuItem } from "react-contextmenu";
import { Toast } from "ui-atoms";
import { Helmet } from "react-helmet-async";

const App = () => {
  const { state, dispatch } = useContext(GlobalContext);
  const { rightContextId, rightContextFunc } = state;
  const lightGalleryRef = useRef(null);
  const portalRef = useRef(null);
  const [anchorId, setAnchorId] = useState("");
  const showTooltip = (id: string) => {
    setAnchorId(id);
  };
  const isLocal = process.env?.REACT_APP_AUTH_API_PREFIX_URL?.includes("local")
    ? true
    : false;

  const hideTooltip = () => {
    setAnchorId("");
  };

  useEffect(() => {
    if (!dispatch) return;
    dispatch({
      type: CREATE_LIGHTGALLERY,
      payload: lightGalleryRef,
    });
    dispatch({
      type: SET_TOOLTIP,
      payload: { showTooltip, hideTooltip },
    });
    dispatch({
      type: SET_PORTAL,
      payload: portalRef,
    });
  }, [dispatch]);

  const pasteImg = async () => {
    const queryOpts: any = {
      name: "clipboard-read",
      allowWithoutGesture: false,
    };
    const permissionStatus = await navigator.permissions.query(queryOpts);
    // Will be 'granted', 'denied' or 'prompt':
    if (permissionStatus.state === "denied") {
      Toast.warn(
        "You're not allowed to read clipboard. Please allow this website to access the clipboard."
      );
      return;
    }
    try {
      const clipboardContents = await navigator.clipboard.read();
      const blobOutput = await clipboardContents[0].getType("image/png");
      rightContextFunc(rightContextId, [blobOutput]);
    } catch (e) {
      Toast.warn("The image file is not copied");
    }
  };

  return (
    <>
      <Helmet prioritizeSeoTags>
        {!isLocal && (
          <meta
            http-equiv="Content-Security-Policy"
            content="upgrade-insecure-requests"
          />
        )}
      </Helmet>
      <div ref={portalRef}></div>
      <RootRoutes />
      <Tooltip
        anchorId={anchorId}
        place="top"
        variant="dark"
        isOpen={!!anchorId}
      />
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar
        className="!w-[400px]"
        bodyClassName="!px-[10px] !py-3"
      />
      <LightGallery ref={lightGalleryRef} />
      <ErrorModal />
      <LoadingModal />
      <PermissionModal />
      <EventModal />
      <InlineSelectModal />
      <InlineHighlightModal />
      <InlineAmenityModal />
      <PropertyRemoveModal />
      {/* @ts-ignore */}
      { !!rightContextId && <ContextMenu
        id={rightContextId}
        className="shadow-right rounded bg-white"
      >
        {/* @ts-ignore */}
        <MenuItem
          className="py-2 px-6 cursor-pointer hover:bg-jll-color-coldGray-1 text-jll-color-text-base-default hover:rounded font-medium"
          onClick={pasteImg}
          holdToDisplay={1000}
        >
          Paste
        </MenuItem>
      </ContextMenu>}
    </>
  );
};

export default App;
