import { Document, Font } from "@react-pdf/renderer";
import { AvailabilityProps, PropertyProps } from "types";
import PageCover from "./PageCover/PageCover";
import SourceSansProRegular from "assets/fonts/sanspro/SourceSansPro-Regular.ttf";
import SourceSansProBold from "assets/fonts/sanspro/SourceSansPro-Bold.ttf";
import MontserratBold from "assets/fonts/montserrat/Montserrat-ExtraBold.ttf";
import PageProperty from "./PageProperty/PageProperty";
import PageSuite from "./PageSuite/PageSuite";
import PageDisclamer from "./PageDisclamer/PageDisclamer";
import PageSuiteList from "./PageSuiteList/PageSuiteList";
import { sliceIntoChunks } from "utils";

Font.register({
  family: "Source Sans Pro",
  fonts: [
    {
      src: SourceSansProRegular,
    },
    {
      src: SourceSansProBold,
      fontWeight: "bold",
    },
  ],
});

Font.register({
  family: "Montserrat",
  fonts: [
    {
      src: MontserratBold,
      fontWeight: 800,
    },
  ],
});

interface IPropertyPDF {
  property?: PropertyProps;
  availabilities?: AvailabilityProps[];
  propertyMap?: string;
  meta?: any;
}

const PropertyPDF = ({
  property,
  availabilities,
  propertyMap,
  meta,
}: IPropertyPDF) => {
  return (
    <Document>
      <PageCover bgImage={property?.images?.[0] || ""} property={property} />
      <PageProperty property={property} propertyMap={propertyMap} meta={meta} />
      {!!availabilities?.length &&
        sliceIntoChunks(availabilities, 9).map((availabilityList: any, idx) => (
          <PageSuiteList
            key={idx}
            property={property}
            propertyMap={propertyMap}
            availabilities={availabilityList}
            meta={meta}
          />
        ))}
      {!!availabilities?.length &&
        availabilities?.map((availability, idx) => (
          <PageSuite
            availability={availability}
            property={property}
            propertyMap={propertyMap}
            key={idx}
            meta={meta}
          />
        ))}
      <PageDisclamer brokers={property?.brokers} />
    </Document>
  );
};

export default PropertyPDF;
