import type { FC } from "react";
import HomepageBg from "assets/homepage_bg.jpg";
import { FiLogIn } from "react-icons/fi";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Button } from "ui-atoms";
import HomepageLaptop from "assets/homepage_laptop_3.png";
import JllLogo from "assets/jll-logo-white.svg";
import { lazyLoadImageOnError } from "utils";
import { Helmet } from "react-helmet-async";
import { META_TAGS } from "constant";
import React from "react";

const Homepage: FC = () => {
  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>{META_TAGS?.home?.title}</title>
        <meta property="og:title" content={META_TAGS?.home?.title} />
      </Helmet>
      <div
        className="w-full h-full top-0 left-0 absolute bg-cover bg-center"
        style={{ backgroundImage: `url(${HomepageBg})` }}
      />
      <div className="absolute top-0 left-0 w-3/4 h-full bg-jll-color-coldGray-10/50 backdrop-blur-md " />

      <div className="flex flex-row w-full h-full absolute top-0 left-0">
        <div className="flex items-start justify-center flex-col space-y-8 pl-[10%] text-jll-color-surface-base-default w-1/2">
          <LazyLoadImage
            src={JllLogo}
            className="mb-11"
            onError={lazyLoadImageOnError}
          />
          <div className="font-semibold text-8xl">My Listings</div>
          <div className=" text-5xl">
            JLL’s home to manage Agency properties and Assets
          </div>
          <div className="font-semibold text-xl">
            Manage your data and sync your changes external providers.
          </div>
          <Button
            variant="danger"
            trailingIcon={FiLogIn}
            onClick={() => {
              window.location.href =
                process.env.REACT_APP_BACKEND_AUTH_URL || "";
            }}
          >
            Log in
          </Button>
        </div>
        <div className="flex items-center w-1/2 justify-end">
          <LazyLoadImage
            src={HomepageLaptop}
            className="max-h-[80%]"
            onError={lazyLoadImageOnError}
          />
        </div>
      </div>
    </>
  );
};

export default React.memo(Homepage);
