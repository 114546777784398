import React, { Fragment, useContext, useEffect, useState } from "react";
import cn from "classnames";
import {
  FiActivity,
  FiCheckSquare,
  FiChevronDown,
  FiChevronUp,
  FiFolder,
  FiList,
  FiLogOut,
  FiMessageSquare,
  FiUserPlus,
} from "react-icons/fi";
import { Link } from "react-router-dom";
import { FaRegBuilding } from "react-icons/fa";
import { HiOutlineDocumentReport } from "react-icons/hi";
import { useLocation } from "react-router-dom";
import { FiUsers, FiCalendar } from "react-icons/fi";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { getUsersAPI, logoutApi, switchUserAPI } from "services";
import JllLogo from "assets/jll-logo.svg";
import { useApiCall } from "hooks";
import { SET_CONFIRM_MODAL, STATUS_ACTIVE, URLS } from "constant";
import { Button, SelectAutoComplete, Toast } from "ui-atoms";
import { ContactSupportModal, RemoveDescriptionItem } from "ui-molecules";
import { UserProps } from "types";
import { renderToString } from "react-dom/server";
import { GlobalContext } from "context";

export default function Navbar() {
  const session = JSON.parse(sessionStorage.getItem("session") || "null");
  const mainSession = JSON.parse(
    sessionStorage.getItem("mainSession") || "null"
  );
  const [currentUser, setCurrentUser] = useState<any>(null);
  const { dispatch } = useContext(GlobalContext);
  const [logout] = useApiCall(logoutApi);
  const [getUsers] = useApiCall(getUsersAPI);
  const [switchUser] = useApiCall(switchUserAPI);
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const menuItems = [
    {
      href: "/",
      icon: <FiFolder />,
      label: "My Listings",
    },
    {
      href: `/${URLS.PROPERTIES}`,
      icon: <FaRegBuilding />,
      label: "All JLL Properties",
    },
    {
      href: `/${URLS.TEAM_LISTINGS}`,
      icon: <FiList />,
      label: "Team Listings",
    },
    {
      href: `/${URLS.REPORTS}`,
      icon: <HiOutlineDocumentReport className="w-5 h-5" />,
      label: "Reports",
    },
  ];

  useEffect(() => {
    if (!session || session?.id === currentUser?.value) return;
    setCurrentUser({
      label: session?.get_full_name || session?.email,
      value: session?.id,
    });
  }, [session]);

  const loadCoordinatorOptions = async (keyword: string) => {
    return await getUsers({ keyword }).then((res: any) => {
      return res.docs.map((user: UserProps) => ({
        value: user.pk,
        label:
          user?.first_name || user?.last_name
            ? [user?.first_name, user?.last_name].join(" ")
            : user?.email,
      }));
    });
  };

  const handleUser = async (option: any) => {
    try {
      if (!option) {
        const action = async () => {
          const payload = {
            user: mainSession?.id,
          };
          await switchUser(payload);
          window.location.reload();
        };

        if (!mainSession) {
          Toast.warn("The current user can't be switched");
          setCurrentUser({
            label: session?.get_full_name || session?.email,
            value: session?.id,
          });
          return;
        }

        dispatch({
          type: SET_CONFIRM_MODAL,
          payload: {
            open: true,
            content: {
              description: renderToString(
                <RemoveDescriptionItem
                  target={`${mainSession?.get_full_name || mainSession?.email}`}
                  description="Temporarily switch user to: "
                  suffix="This will render pages as the listed user sees them."
                />
              ),
              action,
            },
          },
        });
        return;
      }
      if (option?.value === session?.id) return;
      const action = async () => {
        const payload = {
          user: option?.value,
        };
        await switchUser(payload);
        window.location.reload();
      };

      dispatch({
        type: SET_CONFIRM_MODAL,
        payload: {
          open: true,
          content: {
            description: renderToString(
              <RemoveDescriptionItem
                target={`${option?.label}`}
                description="Temporarily switch user to: "
                suffix="This will render pages as the listed user sees them."
              />
            ),
            action,
          },
        },
      });
    } catch (err) {}
  };

  const onClickLogout = () => {
    logout().then((data: any) => {
      window.location.href = data?.redirect;
    });
  };

  return (
    <>
      <Disclosure
        as="nav"
        className={cn("bg-white shadow fixed top-0 left-0 w-full z-20")}
      >
        {({ open }) => (
          <>
            <div className="mx-auto w-full px-4 sm:px-6 lg:px-8">
              <div className="flex h-16 justify-between">
                <div className="flex">
                  <div className="-ml-2 mr-2 flex items-center md:hidden">
                    {/* Mobile menu button */}
                    <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XMarkIcon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      ) : (
                        <Bars3Icon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      )}
                    </Disclosure.Button>
                  </div>
                  <div className="flex flex-shrink-0 items-center border-r border-r-jll-color-coldGray-2 pr-4">
                    <Link to="/">
                      <img
                        className="block h-12 w-auto relative -left-3"
                        src={JllLogo}
                        alt="Jll"
                      />
                    </Link>
                  </div>
                  <div className="hidden sm:ml-6 sm:flex sm:space-x-4">
                    {menuItems.map((item, index) => (
                      <Link
                        key={index}
                        to={item.href}
                        className={cn(
                          "transition-all space-x-2 inline-flex items-center font-medium text-jll-color-coldGray-7 px-1 pt-1 text-sm hover:border-b hover:border-jll-color-coldGray-7 hover:text-jll-color-coldGray-9",
                          {
                            "border-b !border-jll-color-coldGray-7 text-jll-color-coldGray-9 font-semibold":
                              location.pathname === item.href,
                          }
                        )}
                      >
                        {item.icon} <span>{item.label}</span>
                      </Link>
                    ))}
                  </div>
                </div>
                <div className="flex items-center">
                  <div className="hidden md:ml-4 md:flex md:flex-shrink-0 md:items-center space-x-2">
                    <Button variant="primary" onClick={() => setIsOpen(true)}>
                      Contact Support
                    </Button>
                    <Link
                      to={`/${URLS.ACTIVITY}?user[]=${session?.id}`}
                      className={cn(
                        "transition-all inline-flex items-center border-b border-transparent text-jll-color-coldGray-7 px-1 pt-1 text-sm font-medium space-x-1 hover:text-jll-color-coldGray-9"
                      )}
                    >
                      <button
                        type="button"
                        className={cn(
                          "rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none",
                          {
                            "text-jll-color-coldGray-9":
                              location.pathname ===
                              `/${URLS.ACTIVITY}?user[]=${session?.id}`,
                          }
                        )}
                      >
                        <span className="sr-only">Activities</span>
                        <FiActivity className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </Link>

                    {/* Profile dropdown */}
                    <Menu as="div" className="relative ml-3">
                      {({ open }) => (
                        <>
                          <div className="flex space-x-2 items-center">
                            <Menu.Button className="flex bg-white text-sm ">
                              <div className="text-jll-color-coldGray-9 text-body2 font-semibold flex flex-row items-center space-x-2">
                                <span>{session?.get_full_name}</span>
                                {open ? (
                                  <FiChevronUp className="text-lg" />
                                ) : (
                                  <FiChevronDown className="text-lg" />
                                )}
                              </div>
                            </Menu.Button>
                          </div>
                          {open && (
                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-200"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items className="absolute right-0 z-10 mt-2 w-60 origin-top-right divide-y divide-jll-color-coldGray-3 rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <div className="py-1">
                                  <Menu.Item>
                                    {({ active }) => (
                                      <Link
                                        to={`/${URLS.AVAILABILITIES}?active_status[]=1`}
                                        className={cn(
                                          "block px-4 py-2 text-sm text-gray-700 w-full text-left",
                                          { "bg-gray-100": active }
                                        )}
                                      >
                                        <FiCalendar className="inline mr-2" />
                                        Availabilities
                                      </Link>
                                    )}
                                  </Menu.Item>
                                  <Menu.Item>
                                    {({ active }) => (
                                      <Link
                                        to={`/${URLS.BROKERS}`}
                                        className={cn(
                                          "block px-4 py-2 text-sm text-gray-700 w-full text-left",
                                          { "bg-gray-100": active }
                                        )}
                                      >
                                        <FiUsers className="inline mr-2" />
                                        Brokers
                                      </Link>
                                    )}
                                  </Menu.Item>
                                  <Menu.Item>
                                    {({ active }) => (
                                      <Link
                                        to={`/${URLS.TEAM_LISTINGS}`}
                                        className={cn(
                                          "block px-4 py-2 text-sm text-gray-700 w-full text-left",
                                          { "bg-gray-100": active }
                                        )}
                                      >
                                        <FiList className="inline mr-2" />
                                        Team Listings
                                      </Link>
                                    )}
                                  </Menu.Item>
                                  <Menu.Item>
                                    {({ active }) => (
                                      <Link
                                        to={`/${URLS.COMMENTS}?user[]=${session?.id}&active_status[]=${STATUS_ACTIVE}`}
                                        className={cn(
                                          "block px-4 py-2 text-sm text-gray-700 w-full text-left",
                                          { "bg-gray-100": active }
                                        )}
                                      >
                                        <FiMessageSquare className="inline mr-2" />
                                        Comments
                                      </Link>
                                    )}
                                  </Menu.Item>
                                  <Menu.Item>
                                    {({ active }) => (
                                      <Link
                                        to={`/${URLS.TASKS}`}
                                        className={cn(
                                          "block px-4 py-2 text-sm text-gray-700 w-full text-left",
                                          { "bg-gray-100": active }
                                        )}
                                      >
                                        <FiCheckSquare className="inline mr-2" />
                                        Tasks
                                      </Link>
                                    )}
                                  </Menu.Item>
                                  {session?.is_superuser && (
                                    <Menu.Item>
                                      {({ active }) => (
                                        <Link
                                          to={`/${URLS.MANAGE_USERS}`}
                                          className={cn(
                                            "block px-4 py-2 text-sm text-gray-700",
                                            { "bg-gray-100": active }
                                          )}
                                        >
                                          <FiUserPlus className="inline mr-2" />
                                          Manage Users
                                        </Link>
                                      )}
                                    </Menu.Item>
                                  )}
                                </div>
                                <div className="py-1">
                                  {(session?.is_superuser ||
                                    mainSession?.is_superuser) && (
                                    <Menu.Item disabled>
                                      <div className="px-4 py-2 text-sm text-gray-700">
                                        <SelectAutoComplete
                                          label="Switch User"
                                          name="user"
                                          className="!mb-0"
                                          onChange={(option) => {
                                            handleUser(option);
                                          }}
                                          value={currentUser}
                                          loadOptions={loadCoordinatorOptions}
                                          isMenuPortalTarget={false}
                                        />
                                      </div>
                                    </Menu.Item>
                                  )}
                                  <Menu.Item>
                                    {({ active }) => (
                                      <button
                                        onClick={onClickLogout}
                                        className={cn(
                                          "block px-4 py-2 text-sm text-gray-700 w-full text-left",
                                          { "bg-gray-100": active }
                                        )}
                                      >
                                        <FiLogOut className="inline mr-2" />
                                        Sign Out
                                      </button>
                                    )}
                                  </Menu.Item>
                                </div>
                              </Menu.Items>
                            </Transition>
                          )}
                        </>
                      )}
                    </Menu>
                  </div>
                </div>
              </div>
            </div>
            <Disclosure.Panel className="md:hidden">
              <div className="space-y-1 pt-2 pb-3">
                {/* Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" */}
                {menuItems.map((item, index) => (
                  <Disclosure.Button
                    key={index}
                    as="a"
                    href={item.href}
                    className={cn(
                      "flex block border-l-4 space-x-4 text-gray-500  border-transparent py-2 pl-3 pr-4 text-base font-medium sm:pl-5 sm:pr-6 space-x-2 items-center",
                      {
                        "border-indigo-500 bg-indigo-50 text-indigo-700  hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700":
                          location.pathname === item.href,
                      }
                    )}
                  >
                    {item.icon} <span>{item.label}</span>
                  </Disclosure.Button>
                ))}
              </div>
              <div className="border-t border-gray-200 pt-4 pb-3">
                <div className="flex items-center px-4 sm:px-6">
                  <div className="flex-shrink-0">
                    <img
                      className="h-10 w-10 rounded-full"
                      src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                      alt=""
                    />
                  </div>
                  <div className="ml-3">
                    <div className="text-base font-medium text-gray-800">
                      Firstname Lastname
                    </div>
                    <div className="text-sm font-medium text-gray-500">
                      email@jll.com
                    </div>
                  </div>
                  {/* 
                TODO: https://trepbi.atlassian.net/browse/MYL-65
                <button
                  type="button"
                  className="ml-auto flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                </button>
                */}
                </div>
                <div className="mt-3 space-y-1">
                  {session?.is_superuser && (
                    <Disclosure.Button
                      as="a"
                      href="#"
                      className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800 sm:px-6"
                    >
                      Manage Users
                    </Disclosure.Button>
                  )}
                  <Disclosure.Button
                    as="a"
                    href="#"
                    className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800 sm:px-6"
                  >
                    Sign out
                  </Disclosure.Button>
                </div>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
      <ContactSupportModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
}
