import { useApiCall } from "hooks";
import React from "react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getCommentsAPI } from "services";
import { CommentProps } from "types";
import { Button } from "ui-atoms";
import { Modal, ShowMoreItems } from "ui-molecules";
import { getFormatedDateTime } from "utils";

interface ICommentDetailModal {
  buildingId: number;
  isOpen: boolean;
  setIsOpen: any;
}

const CommentDetailModal: React.FC<ICommentDetailModal> = ({
  buildingId,
  isOpen,
  setIsOpen,
}) => {
  const [getComments, loading] = useApiCall(getCommentsAPI);
  const [comments, setComments] = useState<CommentProps[]>([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (!buildingId) return;
    setPage(1);
    getComments({ building: [buildingId] }).then((data: any) => {
      setComments([...data?.docs]);
      setTotal(data?.total);
    });
  }, [buildingId]);

  const handleOpen = (e: boolean) => {
    if (!e) {
      setIsOpen(false);
    }
  };

  const onClickShowMore = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    getComments({
      page: nextPage,
      building: [buildingId],
    }).then((data: any) => {
      setComments((prevData: any) => [...prevData, ...data?.docs]);
      setPage(data?.page);
      setTotal(data?.total);
    });
  };
  return (
    <Modal isOpen={isOpen} setIsOpen={handleOpen} size="large">
      <Modal.Header>
        <div className="flex flex-row items-center justify-between pb-4">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            Comments for
            {!!comments?.length && (
              <Link
                to={`/property/${comments[0]?.building_id}/admin`}
                className="text-jll-color-icon-info ml-1 font-semibold"
              >
                {comments[0]?.building_title}
              </Link>
            )}
          </h3>
          <Button variant="secondary" onClick={() => setIsOpen(false)}>
            Close
          </Button>
        </div>
      </Modal.Header>
      <Modal.Body>
        {!!comments.length && (
          <div className="divide-y divide-jll-color-stroke-base-default mb-4">
            {comments?.map((comment: CommentProps, index: number) => {
              return (
                <div key={index} className=" py-4">
                  <div className="flex justify-between">
                    <div>
                      <p className="text-body1">{comment?.comment}</p>
                      <div>
                        <small className="text-xs text-jll-color-text-base-subdued">
                          {getFormatedDateTime(comment?.update_timestamp)} |{" "}
                          {comment?.first_name || comment?.last_name
                            ? [comment?.first_name, comment?.last_name].join(
                                " "
                              )
                            : [
                                comment?.building_address,
                                [
                                  comment?.building_state,
                                  comment?.building_post_code,
                                ].join(" "),
                              ].join(", ")}
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
        {total > comments?.length && (
          <ShowMoreItems
            onClick={onClickShowMore}
            total={total}
            isLoading={loading}
            itemsCount={comments?.length}
          />
        )}
      </Modal.Body>
    </Modal>
  );
};

export default React.memo(CommentDetailModal);
