import { HTTP_ERROR_BAD_REQUEST, SET_ERROR_MODAL } from "constant";
import { GlobalContext } from "context";
import { useContext } from "react";
import { FiChevronLeft, FiXCircle } from "react-icons/fi";
import { Button } from "ui-atoms";
import { Modal } from "ui-molecules";
import { capitalizeWords } from "utils";

interface IErrorItem {
  valueKey?: string;
  values?: any;
}

const ErrorItem: React.FC<IErrorItem> = ({ valueKey, values }) => {
  let errorStr;
  if (values) {
    if (Array.isArray(values)) {
      errorStr = values?.map((val, idx) => (
        <div key={idx} className="pb-0.5">
          {idx !== 0 && ", "}
          {typeof val === "string" ? val : val?.message || ""}
        </div>
      ));
    } else if (typeof values === "string") {
      errorStr = values;
    }
  }

  return (
    <div className="py-2 flex flex-row">
      <div className="text-jll-color-coldGray-5 font-semibold whitespace-nowrap mr-2">
        {`${capitalizeWords(valueKey, "_")}: `}
      </div>
      <div className="text-jll-color-text-base-subdued text-sm pt-0.5 flex flex-row">
        {errorStr || ""}
      </div>
    </div>
  );
};

const ErrorModal = () => {
  const { state, dispatch } = useContext(GlobalContext);
  const { errorModalContent, errorModalOpen } = state;

  const handleOpenClick = () => {
    dispatch({
      type: SET_ERROR_MODAL,
      payload: { open: false, content: null },
    });
  };

  return (
    <Modal isOpen={errorModalOpen} setIsOpen={handleOpenClick} size="large">
      <Modal.Header>
        <div className="flex justify-center items-center flex-col">
          <FiXCircle className="h-8 w-8 text-jll-color-coldGray-4" />
          <p className="font-black text-jll-color-coldGray-3 text-[76px]">
            {errorModalContent?.code || HTTP_ERROR_BAD_REQUEST}
          </p>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="divide-y divide-jll-color-stroke-base-default mb-8">
          {!!errorModalContent?.values &&
            Object.keys(errorModalContent?.values)?.map((key, idx1) => (
              <ErrorItem
                key={idx1}
                valueKey={key}
                values={errorModalContent?.values?.[key]}
              />
            ))}
        </div>
      </Modal.Body>
      <Modal.Footer className="justify-center !mt-0">
        <Button
          variant="primary"
          leadingIcon={FiChevronLeft}
          leadingIconClass="w-4 h-4 mr-2"
          onClick={handleOpenClick}
        >
          Go Back
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ErrorModal;
