import { AvailabilityProps } from "types";
import { getDecimalFormating } from "utils";
import Image from "assets/pdf/image.png";
import PlanImg from "assets/pdf/plan.png";
import FileImg from "assets/pdf/file.png";
import VideoImg from "assets/pdf/video.png";
import VirtualTourImg from "assets/pdf/virtual_tour.png";

export const getOptionValue = (value?: string | number) => {
  if (!value) {
    return undefined;
  }
  return {
    value: value,
    label: value + "",
  };
};

export const getAvailabilityItem = (
  availability: AvailabilityProps | any,
  key: string,
  meta?: any
) => {
  switch (key) {
    case "name":
      return `${availability?.name || ""}`;

    case "size":
      if (!availability?.min_floor_area && !availability?.max_floor_area) {
        return "N/A";
      }
      if (availability?.min_floor_area && !availability?.max_floor_area) {
        return `${getDecimalFormating(availability?.min_floor_area)} sqft`;
      }
      if (!availability?.min_floor_area && availability?.max_floor_area) {
        return `${getDecimalFormating(availability?.max_floor_area)} sqft`;
      }

      if (availability?.min_floor_area === availability?.max_floor_area) {
        return `${getDecimalFormating(availability?.max_floor_area)} sqft`;
      }

      if (availability?.min_floor_area && availability?.max_floor_area) {
        return `${getDecimalFormating(availability?.min_floor_area)}-
            ${getDecimalFormating(availability?.max_floor_area)} sqft`;
      }
      return "N/A";

    case "max_price":
      if (!availability?.min_price && !availability?.max_price) {
        return "Negotiable";
      }
      if (availability?.min_price && !availability?.max_price) {
        return `$${Number(
          getDecimalFormating(Number(availability?.min_price))
        )?.toFixed(2)}`;
      }
      if (!availability?.min_price && availability?.max_price) {
        return `$${Number(
          getDecimalFormating(Number(availability?.max_price)?.toFixed(2))
        )?.toFixed(2)}`;
      }

      if (availability?.min_price === availability?.max_price) {
        return `$${Number(
          getDecimalFormating(Number(availability?.max_price)?.toFixed(2))
        )?.toFixed(2)}`;
      }

      if (availability?.min_price && availability?.max_price) {
        return `$${Number(
          getDecimalFormating(Number(availability?.min_price)?.toFixed(2))
        )?.toFixed(2)}-
            $${Number(
              getDecimalFormating(Number(availability?.max_price)?.toFixed(2))
            )?.toFixed(2)}`;
      }
      return "N/A";

    case "tenure_types":
      if (!availability?.tenure_types?.length) return "N/A";
      let str = "";
      availability?.tenure_types?.forEach((type: string, index: number) => {
        if (index >= 1) str += ", ";
        str += type?.toLowerCase() === "rent" ? "Lease" : type;
      });
      return str;

    case "rent_type":
      if (!availability?.rent_type) return "N/A";
      return meta?.suite?.rent_type?.[availability?.["rent_type"]]?.value;

    case "media":
      let htmlTag = `<div>`;
      if (!!availability?.images?.length) {
        htmlTag += `<a target="_blank" href="${availability?.images[0]}" style="margin-right: 10px"><img src=${Image} style="width: 16px; height: 16px"/></a>`;
      }
      if (!!availability?.floorplans?.length) {
        htmlTag += `<a target="_blank" href="${availability?.floorplans[0]}" style="margin-right: 10px"><img src=${PlanImg} style="width: 16px; height: 16px"/></a>`;
      }

      if (!!availability?.brochures?.length) {
        htmlTag += `<a target="_blank" href="${availability?.brochures[0]}" style="margin-right: 10px"><img src=${FileImg} style="width: 16px; height: 16px"/>
        </a>`;
      }
      if (!!availability?.videos?.length) {
        htmlTag += `<a target="_blank" href="${availability?.videos[0]}" style="margin-right: 10px"><img src=${VideoImg} style="width: 16px; height: 16px"/>
        </a>`;
      }

      if (!!availability?.virtual_tours?.length) {
        htmlTag += `<a target="_blank" href="${availability?.virtual_tours[0]}" style="margin-right: 10px"><img src=${VirtualTourImg} style="width: 16px; height: 16px"/>
        </a>`;
      }
      htmlTag += "</div>";
      return htmlTag;

    default:
      if (availability?.hasOwnProperty(key)) {
        return availability?.[key] || "N/A";
      }
      return "N/A";
  }
};
