import {
  EDITOR_JS_TOOLS,
  SET_LOADING_MODAL,
  TASK_NOT_NEEDED,
  TASK_NOT_STARTED,
} from "constant";
import { GlobalContext } from "context";
import { useApiCall } from "hooks";
import { useContext, useEffect, useState } from "react";
import { FiX } from "react-icons/fi";
import {
  getPropertiesAPI,
  getUsersAPI,
  patchTaskAPI,
  postTaskAPI,
} from "services";
import { UserProps } from "types";
import { Button, Editor, Input, SelectAutoComplete, Toast } from "ui-atoms";
import { Modal } from "ui-molecules";
import { getMetaOptions, joinTwoStr } from "utils";
import { useFormik } from "formik";
import * as Yup from "yup";

interface ITaskEditModal {
  isOpen: boolean;
  setIsOpen?: any;
  selectedTask?: any;
  updateData?: any;
  isProperty?: boolean;
}

const validationSchema = Yup.object().shape({
  building: Yup.string().nullable().required("This field is required"),
  assignee: Yup.string().nullable().required("This field is required"),
  title: Yup.string().nullable().required("This field is required"),
  body: Yup.array().nullable().min(1, "This field is required"),
  status: Yup.string().nullable().required("This field is required"),
});

const INITIAL_VALUES = {
  building: "",
  assignee: [],
  title: "",
  body: [],
  status: "",
};

const INITIAL_OPTIONS = {
  building: null,
  assignee: null,
  status: null,
};

const TaskEditModal: React.FC<ITaskEditModal> = ({
  isOpen,
  setIsOpen,
  selectedTask,
  updateData,
  isProperty = false,
}) => {
  const session = JSON.parse(sessionStorage.getItem("session") || "null");
  const { state, dispatch } = useContext(GlobalContext);
  const { meta, property } = state;
  const [getUsers] = useApiCall(getUsersAPI);
  const [getProperties] = useApiCall(getPropertiesAPI);
  const [postTask] = useApiCall(postTaskAPI);
  const [patchTask] = useApiCall(patchTaskAPI);
  const [defaultValues, setDefaultValues] = useState<any>(INITIAL_OPTIONS);

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    values,
    errors,
    touched,
    setValues,
    isValid,
    dirty,
  } = useFormik({
    initialValues: INITIAL_VALUES,
    validationSchema,
    onSubmit: async (values) => {
      dispatch({
        type: SET_LOADING_MODAL,
        payload: {
          open: true,
          label: `${!selectedTask ? "Adding New" : "Updating"} Task`,
        },
      });
      try {
        let response;
        if (!selectedTask)
          response = await postTask({
            ...values,
            body:
              typeof values?.body === "string"
                ? JSON.parse(values?.body)
                : values?.body,
          });
        else
          response = await patchTask({
            id: selectedTask?.pk,
            payload: {
              ...values,
              body:
                typeof values?.body === "string"
                  ? JSON.parse(values?.body)
                  : values?.body,
            },
          });
        setTimeout(() => {
          dispatch({
            type: SET_LOADING_MODAL,
            payload: {
              open: false,
              label: null,
            },
          });
        }, 100);
        if (!response) return;
        Toast.success("Changes saved with success");
        setIsOpen(false);
        updateData(response);
      } catch (err) {
        setTimeout(() => {
          dispatch({
            type: SET_LOADING_MODAL,
            payload: {
              open: false,
              label: null,
            },
          });
        }, 100);
      }
    },
  });

  useEffect(() => {
    if (!selectedTask) {
      let defaultValue = {
        ...INITIAL_VALUES,
        assignee: session?.id,
        status: TASK_NOT_STARTED.toString(),
      };
      let defaultOption = {
        ...INITIAL_OPTIONS,
        assignee: {
          label: session?.get_full_name || session?.email,
          value: session?.id,
        },
        status: [...getMetaOptions(meta?.task?.status)].find(
          (item) => item.value.toString() === TASK_NOT_STARTED.toString()
        ),
      };
      if (isProperty) {
        defaultValue = {
          ...defaultValue,
          building: property?.pk || property?.id,
        };
        const defaultBuilding: any = {
          value: property?.pk || property?.id,
          label: joinTwoStr(property?.title, property?.address),
        };
        defaultOption = {
          ...defaultOption,
          building: defaultBuilding,
        };
      }
      setValues(defaultValue);
      setDefaultValues(defaultOption);
      return;
    }
    let formValues = {};
    Object.keys(INITIAL_VALUES).forEach((key: string) => {
      formValues = {
        ...formValues,
        [key]: selectedTask?.[key] || "",
      };
    });
    setValues({
      ...INITIAL_VALUES,
      ...formValues,
      building: selectedTask?.building_id,
      assignee: selectedTask?.assignee_id,
    });
    const defaultBuilding = {
      value: selectedTask?.building_id,
      label: joinTwoStr(
        selectedTask?.building_title,
        selectedTask?.building_address
      ),
    };
    const defaultAssignee = {
      value: selectedTask?.assignee_id,
      label:
        selectedTask?.assignee_first_name || selectedTask?.assignee_last_name
          ? [
              selectedTask?.assignee_first_name,
              selectedTask?.assignee_last_name,
            ].join(" ")
          : selectedTask?.assignee_email,
    };
    const status: any = Object.keys(meta?.task?.status)
      .map((key) => {
        return { ...meta?.task?.status[key], key };
      })
      ?.find(
        (item: any) =>
          item?.key?.toString() === selectedTask?.status?.toString()
      );
    const defaultStatus = {
      label: status?.value,
      value: Number(status?.key),
    };
    setDefaultValues({
      building: defaultBuilding,
      assignee: defaultAssignee,
      status: defaultStatus,
    });
  }, [selectedTask, isProperty]);

  const loadCoordinatorOptions = async (keyword: string) => {
    return await getUsers({ keyword }).then((res: any) => {
      return res.docs.map((user: UserProps) => ({
        value: user.pk,
        label:
          user?.first_name || user?.last_name
            ? [user?.first_name, user?.last_name].join(" ")
            : user?.email,
      }));
    });
  };

  const loadPropertyOptions = async (keyword: string) => {
    let payload: any = { lite: true };
    if (keyword) {
      payload = { ...payload, keyword };
    }
    return getProperties(payload).then((res: any) => {
      return res.docs.map((building: any) => {
        return {
          value: building.pk,
          label: joinTwoStr(building?.title, building?.address),
        };
      });
    });
  };

  const handleOpen = () => {
    setIsOpen(false);
  };

  return (
    <Modal isOpen={isOpen} setIsOpen={handleOpen} size="default">
      <Modal.Header>
        <div className="flex flex-row items-center justify-between pb-4">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            {`${!selectedTask ? "Add New" : "Edit"} Task`}
          </h3>
          <Button
            variant="neutral"
            className="!p-3"
            leadingIcon={FiX}
            leadingIconClass="!w-5 !h-5 font-semibold text-xl"
            onClick={() => setIsOpen(false)}
          />
        </div>
      </Modal.Header>
      <form onSubmit={handleSubmit}>
        <Modal.Body>
          <div className="flex flex-col">
            <SelectAutoComplete
              label="Building"
              name="building"
              placeholder="Choose building"
              labelClassName="text-jll-color-text-base-default text-sm font-medium"
              onBlur={handleBlur}
              onChange={(option) => {
                if (!option) setFieldValue("building", null);
                else setFieldValue("building", option.value);
              }}
              value={defaultValues?.building}
              loadOptions={loadPropertyOptions}
              error={touched.building ? errors.building : ""}
              isDisabled={isProperty}
            />
            <SelectAutoComplete
              label="Assignee"
              name="assignee"
              placeholder="Choose assignee"
              labelClassName="text-jll-color-text-base-default text-sm font-medium"
              onBlur={handleBlur}
              onChange={(option) => {
                if (!option) setFieldValue("assignee", null);
                else setFieldValue("assignee", option.value);
              }}
              value={defaultValues?.assignee}
              loadOptions={loadCoordinatorOptions}
              error={touched.assignee ? errors.assignee : ""}
            />
            <SelectAutoComplete
              label="Status"
              name="status"
              placeholder="Choose status"
              labelClassName="text-jll-color-text-base-default text-sm font-medium"
              onChange={(option) => {
                if (!option) setFieldValue("status", null);
                else setFieldValue("status", option.value);
              }}
              value={defaultValues?.status}
              options={[...getMetaOptions(meta?.task?.status)]}
              error={touched.status ? errors.status : ""}
            />
            <Input
              name="title"
              label="Title"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values?.title}
              error={touched.title ? errors.title : ""}
            />
            <Editor
              tools={EDITOR_JS_TOOLS}
              label="Body"
              blocks={values?.body}
              onChange={(value: any) => {
                setFieldValue("body", value?.blocks);
              }}
              error={touched.body ? errors.body : ""}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            className="w-full flex items-center justify-center"
            type="submit"
            disabled={!isValid || !dirty}
          >
            {`${!selectedTask ? "Add" : "Update"} Task`}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default TaskEditModal;
