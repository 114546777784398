export const META_TAGS = {
  default: { title: "MyListings -", descriptions: [] },
  home: { title: "MyListings", descriptions: [] },
  reports: { title: "Reports", descriptions: [] },
  properties: { title: "MyListings - Properties", descriptions: [] },
  availabilities: { title: "MyListings - Availabilities", descriptions: [] },
  brokers: { title: "MyListings - Brokers", descriptions: [] },
  comments: { title: "MyListings - Comments", descriptions: [] },
  activity: { title: "MyListings - Activity", descriptions: [] },
  admin: { title: "MyListings - User Management", descriptions: [] },
  team_listings: { title: "MyListings - Team Listings", descriptions: [] },
  ui: { title: "MyListings - UI", descriptions: [] },
  deliverable: { title: "MyListings - Deliverable", descriptions: [] },
};
