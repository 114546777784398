import { STATUS_CONDITIONS, URLS } from "constant";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";
import { convertImageUrl } from "services";
import { PropertyProps } from "types";
import { Chip } from "ui-atoms";
import {
  convertExtension,
  getDecimalFormating,
  getFormatedDateTime,
  lazyLoadImageOnError,
  noPropagation,
} from "utils";
import { EditableSingleField } from "ui-molecules";
import EditFileField from "./EditFileField";

export interface StyledContractValueProps {
  property: PropertyProps;
  valueKey: keyof PropertyProps | "info" | "building_size";
  dataType?: "number" | "date" | "text" | "file";
  updateItem?: any;
}

const StyledContractValue: React.FC<StyledContractValueProps> = ({
  property,
  valueKey,
  dataType,
  updateItem,
}) => {

  if (!property?.id?.toString()?.toLowerCase().includes("building"))
    return <></>;
  
  return (
    <>
      {(() => {
        switch (valueKey) {
          case "info":
            return (
              <div className="flex space-x-2">
                <LazyLoadImage
                  className="w-10 h-10 rounded"
                  src={convertImageUrl(
                    convertExtension(property?.images?.[0] || "")
                  )}
                  onError={lazyLoadImageOnError}
                />
                <div className="flex flex-col truncate">
                  {property?.title && (
                    <Link
                      to={`/property/${property?.pk}/${URLS.PROPERTY.FORM}`}
                      className="font-bold text-jll-color-text-base-default text-ellipsis overflow-hidden"
                      onClick={noPropagation}
                    >
                      {property?.title}
                    </Link>
                  )}
                  <p>
                    <Link
                      to={`/property/${property?.pk}/${URLS.PROPERTY.FORM}`}
                      className="text-jll-color-icon-info text-ellipsis overflow-hidden"
                      onClick={noPropagation}
                    >
                      {property?.address}
                    </Link>
                  </p>
                </div>
              </div>
            );
          case "property_type":
            if (!property?.property_type) {
              return <>-</>;
            }
            return (
              <Chip size="small" variant="secondary">
                {property?.property_type}
              </Chip>
            );
          case "tenure_types":
            if (!property?.tenure_types?.length) {
              return <>-</>;
            }
            return property?.tenure_types?.map((type, index) => (
              <Chip size="small" key={index} variant="secondary">
                {type?.toLowerCase() === "rent" ? "Lease" : type}
              </Chip>
            ));
          case "building_size":
            if (!property?.min_surface_area && !property?.max_surface_area) {
              return <>-</>;
            }
            if (property?.min_surface_area && !property?.max_surface_area) {
              return (
                <>
                  {getDecimalFormating(property?.min_surface_area)}{" "}
                  <span className="text-xs text-jll-color-coldGray-6">
                    {property?.surface_area_unit}
                  </span>
                </>
              );
            }
            if (!property?.min_surface_area && property?.max_surface_area) {
              return (
                <>
                  {getDecimalFormating(property?.max_surface_area)}{" "}
                  <span className="text-xs text-jll-color-coldGray-6">
                    {property?.surface_area_unit}
                  </span>
                </>
              );
            }
            if (property?.min_surface_area === property?.max_surface_area) {
              return (
                <>
                  {getDecimalFormating(property?.max_surface_area)}{" "}
                  <span className="text-xs text-jll-color-coldGray-6">
                    {property?.surface_area_unit}
                  </span>
                </>
              );
            }
            return (
              <>
                {getDecimalFormating(property?.min_surface_area)}-
                {getDecimalFormating(property?.max_surface_area)}{" "}
                <span className="text-xs text-jll-color-coldGray-6">
                  {property?.surface_area_unit}
                </span>
              </>
            );
          case "suites":
            if (!property?.suite?.length) {
              return <>-</>;
            }
            return (
              <Link
                onClick={noPropagation}
                className="text-jll-color-icon-info mb-1 flex flex-row items-center"
                to={`/property/${property?.pk}/availabilities`}
              >
                {property?.suite.length} Availabilit
                {property?.suite.length > 1 ? "ies" : "y"}
              </Link>
            );
          case "publish_status":
            if (!property?.publish_status) {
              return <>-</>;
            }
            return (
              <Chip
                size="small"
                variant={
                  STATUS_CONDITIONS?.[property?.publish_status]?.variant as
                    | "secondary"
                    | "success"
                    | "error"
                }
              >
                {STATUS_CONDITIONS?.[property?.publish_status]?.label}
              </Chip>
            );
          case "dealio":
          case "costar_id":
          case "contract_start":
          case "contract_end":
            return (
              <EditableSingleField
                initValues={property}
                valueKeys={[valueKey]}
                type={dataType}
                pk={property?.pk || property?.id}
                updateItem={updateItem}
                kind="property"
                isEditMode
              >
                <span className="text-jll-color-text-base-default mr-2">
                  {dataType === "date"
                    ? property?.[valueKey]
                      ? getFormatedDateTime(property?.[valueKey]?.toString(), {
                          dateStyle: "long",
                        })
                      : ""
                    : property?.[valueKey]}
                </span>
              </EditableSingleField>
            );
          case "listing_contracts":
          case "signage":
            return (
              <EditFileField
                value={property?.[valueKey]}
                valueKey={valueKey}
                pk={property?.pk || property?.id}
              />
            );
          default:
            if (property?.hasOwnProperty(valueKey)) {
              return <>{property[valueKey]}</>;
            }
            return <>-</>;
        }
      })()}
    </>
  );
};

export default StyledContractValue;
