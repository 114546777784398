import React from "react";
import { Page, Text, View, Image, Link } from "@react-pdf/renderer";
import { PAGE_ORIENTATION, PAGE_SIZE } from "constant";
import ContactUSImg from "assets/pdf/contact-us.png";
import styles from "./styles";
import { BrokerProps } from "types";
import { formatPhoneNumber, getImageFromURL, removeNewlines } from "utils";

interface IPageDisclamer {
  brokers?: any[];
}

const PageDisclamer: React.FC<IPageDisclamer> = ({ brokers }) => {
  return (
    <Page
      size={PAGE_SIZE}
      orientation={PAGE_ORIENTATION.LANDSCAPE}
      style={styles.page}
      wrap={false}
    >
      <View style={styles.contactBack}>
        <Image src={ContactUSImg} style={styles.contactBackImg} fixed />
      </View>
      <View style={styles.contactInfoView}>
        {brokers?.map((broker: BrokerProps, idx: number) => {
          return (
            <View key={idx} style={styles.contactDetailView}>
              <Image
                src={getImageFromURL(broker?.photo)}
                style={styles.brokerImg}
              />
              <View style={styles.brokerView}>
                {broker?.name && (
                  <Text style={styles.brokerName}>
                    {removeNewlines(broker?.name)}
                  </Text>
                )}
                {broker?.email && (
                  <Link
                    src={`mailto:${broker?.email}`}
                    style={styles.brokerLink}
                  >
                    <Text style={styles.brokerTxt}>{broker?.email}</Text>
                  </Link>
                )}
                {broker?.telephone && (
                  <Link
                    src={`tel:${broker?.telephone}`}
                    style={styles.brokerLink}
                  >
                    <Text style={styles.brokerTxt}>
                      {formatPhoneNumber(broker?.telephone)}
                    </Text>
                  </Link>
                )}
              </View>
            </View>
          );
        })}
      </View>
      <View style={styles.contactTxtView}>
        <Text style={styles.contactTxt}>
          Although information has been obtained from sources deemed reliable,
          JLL does not make any guarantees, warranties or representations,
          express or implied, as to the completeness or accuracy as to the
          information contained herein. Any projections, opinions, assumptions
          or estimates used are for example only. There may be differences
          between projected and actual results, and those differences may be
          material. JLL does not accept any liability for any loss or damage
          suffered by any party resulting from reliance on this information. If
          the recipient of this information has signed a confidentiality
          agreement with JLL regarding this matter, this information is subject
          to the terms of that agreement. ©2022 Jones Lang LaSalle IP, Inc. All
          rights reserved.
        </Text>
        <Text style={styles.contactTxt}>
          The outbreak of the COVID-19 virus (novel coronavirus) since the end
          of January 2021 has resulted in market uncertainty and volatility
          While the economic impact of a contagion disease generally arises from
          the uncertainty and loss of consumer confidence, its impact on real
          estate values is unknown at this stage. Given the prevailing domestic
          and global uncertainty arising from the Coronavirus, we recommend that
          the intended users of this report regularly seek our guidance.
        </Text>
      </View>
    </Page>
  );
};

export default PageDisclamer;
