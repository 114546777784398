import type { StyledUserValueProps } from "ui-molecules";

interface TdProps {
  className: string;
}

interface TableColumnProps {
  id: StyledUserValueProps["valueKey"];
  label: string;
  sort?: string;
  td?: TdProps;
}

export const USERS_TABLE_COLUMNS: TableColumnProps[] = [
  {
    id: "name",
    label: "Name",
  },
  {
    id: "email",
    label: "Email",
  },
  {
    id: "group",
    label: "Group",
  },
  {
    id: "is_active",
    label: "Status",
  },
];
