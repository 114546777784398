/**
 * Get Coordinator IDs
 * @param value Ex: [{id: **, get_full_name: **}, {id: **, get_full_name: **}]
 * @returns Ex: [**, **, **]
 */
export const getCoordinatorIds = (coordinators: any) => {
  if (!coordinators || !coordinators.length) return [];
  let ids: any[] = [];
  coordinators?.forEach((coordinator: any) => {
    if (typeof coordinator === "object") ids.push(coordinator?.id);
    else ids.push(coordinator);
  });
  return ids;
};
